import React, { Fragment } from 'react';
import {
    InputAdornment,
    TextField,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { rgba } from 'polished';
import classnames from 'classnames';
import { colors } from './colors';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import { observer } from 'mobx-react';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const useStyles = makeStyles((theme) => ({
    inputStyles: {
        '& .MuiOutlinedInput-root': {
            color: colors.heavyMetal,
            fontSize: '14px',
            fontWeight: '400 !important',
            border: '1px solid #F7F7F7',
            '&.Mui-focused fieldset, &.Mui-error fieldset, &.MuiOutlinedInput-root fieldset':
                {
                    borderColor: 'transparent !important',
                },
            '&.Mui-focused': {
                border: `1px solid ${theme.palette.primary.main}`,
            },
            '&.Mui-error': {
                border: `1px solid ${colors.error}`,
            },
            '&.Mui-disabled': {
                color: rgba(colors.heavyMetal, 0.6),
                backgroundColor: rgba(colors.heavyMetal, 0.02),
            },
        },
        '& .MuiFormLabel-shrink, .MuiFormLabel-root': {
            fontSize: '13px',
            color: rgba(colors.heavyMetal, 0.6),
            marginLeft: '2px',
            transform: 'none',
            fontWeight: 700,
            marginBottom: '4px',

            '&.Mui-error': {
                color: colors.error,
            },
        },
        '& .MuiInputBase-root': {
            height: '48px',
            background: 'transparent',
            minWidth: '240px',
            '& input': { paddingLeft: '12px' },
        },
        '& .MuiInputLabel-formControl': {
            position: 'static',
        },
        '& .MuiFormHelperText-root': {
            color: colors.error,
            margin: '-6px 0 8px 2px',
            fontSize: '13px',
        },
    },
    sizeSmall: {
        '& .MuiFormLabel-shrink': {
            marginTop: '2px',
        },
        '& .MuiInputBase-root': {
            height: '40px',
        },
    },
    sizeLarge: {
        '& .MuiFormLabel-shrink': {
            marginTop: '10px',
        },
        '& .MuiInputBase-root': {
            height: '56px',
        },
    },
    inLine: {
        '& .MuiInputBase-root': {
            marginTop: '0px',
            marginBottom: '0px',
        },
    },
    calendarStyles: {
        '& .MuiSvgIcon-root': {
            fill: '#C4C4C4',
        },
    },
    checkboxStyles: {
        '& .MuiFormControlLabel-label': {
            fontSize: '13px',
            fontFamily: 'Nunito Sans, sans-serif',
            color: rgba(colors.heavyMetal, 0.6),
            fontWeight: 700,
        },
    },
    airy: {
        '& .MuiOutlinedInput-root': {
            marginLeft: '16px',
        },
    },
    limitWidth: {
        '& .MuiOutlinedInput-root': {
            '&.MuiOutlinedInput-root.MuiOutlinedInput-root .MuiOutlinedInput-input':
                {
                    maxWidth: '192px',
                },
        },
    },
}));

export function TextInput({
    stretched,
    sizeSmall,
    sizeLarge,
    select,
    children,
    inline,
    airy,
    limitWidth,
    ...props
}) {
    const classes = useStyles();
    return (
        <TextField
            className={classnames(classes.inputStyles, {
                [classes.sizeSmall]: sizeSmall,
                [classes.sizeLarge]: sizeLarge,
                [classes.inLine]: inline,
                [classes.airy]: airy,
                [classes.limitWidth]: limitWidth,
            })}
            select={select}
            margin={!inline ? 'dense' : undefined}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            {...props}
            fullWidth={stretched}
            SelectProps={{ MenuProps: { disableScrollLock: true } }}
        >
            {children}
        </TextField>
    );
}

export const SearchInput = observer(
    ({ children, searchValue, onClear, ...props }) => {
        const classes = useStyles();

        return (
            <TextField
                className={classnames(classes.inputStyles, classes.inLine)}
                variant="outlined"
                style={{ cursor: 'pointer' }}
                value={searchValue}
                {...props}
                InputProps={{
                    endAdornment: (
                        <Fragment>
                            {searchValue.length > 0 && (
                                <InputAdornment
                                    position="start"
                                    onClick={onClear}
                                >
                                    <CancelIcon
                                        fontSize="small"
                                        style={{ cursor: 'pointer' }}
                                    />
                                </InputAdornment>
                            )}
                            <InputAdornment position="start">
                                <SearchIcon
                                    fontSize="small"
                                    style={{
                                        cursor: 'default',
                                    }}
                                />
                            </InputAdornment>
                        </Fragment>
                    ),
                }}
            >
                {children}
            </TextField>
        );
    }
);

export function CalendarInput({ ...props }) {
    const classes = useStyles();

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
                className={classnames(
                    classes.inputStyles,
                    classes.calendarStyles
                )}
                color="primary"
                {...props}
                format="L"
                variant="dialog"
                inputVariant="outlined"
                autoOk
                showTodayButton
                cancelLabel=""
                disablePast
                DialogProps={{ disableScrollLock: true }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <CalendarTodayIcon />
                        </InputAdornment>
                    ),
                }}
            />
        </MuiPickersUtilsProvider>
    );
}
export function CheckboxInput({ label, value, ...props }) {
    const classes = useStyles();

    return (
        <FormControlLabel
            className={classes.checkboxStyles}
            control={<Checkbox checked={value} color="primary" {...props} />}
            label={label}
        />
    );
}
