import React, { useRef, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer, useLocalStore } from 'mobx-react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import { useStores } from '../../hooks/use-stores';
import { Block, FlexDiv } from '../../components/design-system/containers';
import { Heading1 } from '../../components/design-system/headings';
import { TextDefault } from '../../components/design-system/texts';
import { TextInput } from '../../components/design-system/inputs';
import ErrorMessage from '../../components/errormessage';
import SignUpFormDef from '../../forms/auth';
import TermsOfService from '../../components/TermsOfService';

var Recaptcha = require('react-recaptcha');

const { REACT_APP_RECAPTCHA_KEY } = process.env;

function SignUp() {
    const { authStore, brandingStore, commonStore } = useStores();
    const recaptchaRef = useRef();

    const { account_name, termsUrl } = brandingStore;

    const { trialError, creatingTrial } = authStore;

    let history = useHistory();

    const localStore = useLocalStore(() => {
        let signInForm = new SignUpFormDef(
            {},
            {
                hooks: {
                    onSuccess() {
                        continueToNextStep();
                    },
                },
            }
        );
        signInForm.observe({
            path: 'managerEmail',
            key: 'value',
            call: ({ form }) => {
                form.validate();
            },
        });

        return { signInForm };
    });

    useEffect(() => {
        // google tracker
        ReactGA.pageview('/trial/');
        // facebook pixel
        const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
        const options = {
            autoConfig: true, // set pixel's autoConfig
            debug: false, // enable logs
        };
        ReactPixel.init('742074739469756', advancedMatching, options);
        ReactPixel.pageView();
        commonStore.postAffTrackerClick();
        return () => {};
    }, []);

    const verifyCallback = (recaptchaToken) => {
        register(recaptchaToken);
    };

    const continueToNextStep = () => {
        recaptchaRef.current.execute();
    };

    // const callCapterra = () => {
    //     var capterra_vkey = 'fdcc0cfdacfc5e2c58304433a7cf696d',
    //         capterra_vid = '2137634',
    //         capterra_prefix =
    //             'https:' === document.location.protocol
    //                 ? 'https://ct.capterra.com'
    //                 : 'http://ct.capterra.com';
    //     var ct = document.createElement('script');
    //     ct.type = 'text/javascript';
    //     ct.async = true;
    //     ct.src =
    //         capterra_prefix +
    //         '/capterra_tracker.js?vid=' +
    //         capterra_vid +
    //         '&vkey=' +
    //         capterra_vkey;
    //     var s = document.getElementsByTagName('script')[0];
    //     s.parentNode.insertBefore(ct, s);
    // };

    const register = async (recaptchaToken) => {
        const data = {};
        data.recaptchaToken = recaptchaToken;
        data.managerEmail = localStore.signInForm.$('managerEmail').value;
        let response = await authStore.registerTrial(data);

        // if (response === 'signedin') {
        //     // user already signed in
        //     ReactPixel.trackCustom('trialSignup', { signup: true });
        //     ReactGA.set({ page: '/trial/thank-you/' });
        //     // ReactGA.event({category: 'User',action: 'trial-signup-page-was-automatically-signed-in',label: data.managerEmail});
        //     ReactGA.pageview('/trial/signedin/');
        //     ReactGA.event({
        //         category: 'User',
        //         action: 'trialpage-signedin',
        //         label: 'email:' + data.managerEmail.replace('@', '-'),
        //     });

        //     callCapterra();
        //     history.push('/');

        authStore.setIsSignUp(true);
        authStore.setEmail(localStore.signInForm.$('managerEmail').value);

        if (response === 'email') {
            // user already signed up with this email before
            ReactGA.pageview('/free/login/');
            authStore.setExtraMessage('You have already signed up');
            history.push('/auth/check-email');
            //ReactGA.event('trial','trial-signup-was-sent-loggin-mail',managerEmail);
            ReactGA.event({
                category: 'User',
                action: 'free-signup-was-sent-login-emails',
                label: 'email:' + data.managerEmail.replace('@', '-'),
            });
        } else if (response) {
            ReactPixel.trackCustom('freeSignup', { signup: true });
            ReactGA.set({ page: '/free-signup/thank-you/' });
            ReactGA.pageview('/free-signup/thank-you/');
            authStore.setExtraMessage('Please verify your email.');
            history.push('/auth/check-email');
            ReactGA.event({
                category: 'User',
                action: 'free-signup',
                label: 'email:' + data.managerEmail.replace('@', '-'),
            });
        } else {
            recaptchaRef.current.reset();
        }
    };

    const inputHasError =
        localStore.signInForm.$('managerEmail').error &&
        localStore.signInForm.$('managerEmail').error.length > 0;

    return (
        <FlexDiv justifyCenter alignCenter wrapper column>
            {trialError && trialError.length > 0 && (
                <ErrorMessage error={trialError} />
            )}
            {creatingTrial ? (
                <ThemedSpinner />
            ) : (
                <Block width={570}>
                    <Heading1>First, enter your work email</Heading1>
                    <FlexDiv mb={24}>
                        <TextDefault lighter mr={4}>
                            Already have an account?
                        </TextDefault>
                        <TextDefault themed bold>
                            <Link to={`/auth/signin`}>Sign in</Link>
                        </TextDefault>
                    </FlexDiv>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            localStore.signInForm.submit();
                        }}
                    >
                        <FlexDiv column>
                            <TextInput
                                error={inputHasError}
                                helperText={
                                    inputHasError
                                        ? 'Please enter your work email'
                                        : undefined
                                }
                                stretched
                                {...localStore.signInForm
                                    .$('managerEmail')
                                    .bind()}
                            />
                            <ThemedButton primary fullWidth type="submit">
                                Continue
                            </ThemedButton>
                            <Recaptcha
                                ref={recaptchaRef}
                                sitekey={REACT_APP_RECAPTCHA_KEY}
                                size="invisible"
                                verifyCallback={verifyCallback}
                            />
                            <TermsOfService
                                accountName={account_name}
                                termsUrl={termsUrl}
                            />
                        </FlexDiv>
                    </form>
                </Block>
            )}
        </FlexDiv>
    );
}
export default observer(SignUp);
