import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ReportsList from './ReportsList';

export default ({ companyId }) => {
    return (
        <Switch>
            <Route
                path={`/reports`}
                render={(props) => (
                    <ReportsList props={props} companyId={companyId} />
                )}
            />
        </Switch>
    );
};
