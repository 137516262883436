import React, { useMemo, Fragment } from 'react';
import SimpleTable from '../../components/SimpleTable';
import { Heading2, Heading4 } from '../../components/design-system/headings';
import {
    Block,
    FlexDiv,
    SpaceBetweenDiv,
} from '../../components/design-system/containers';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import { useStores } from '../../hooks/use-stores';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import moment from 'moment';
import { colors } from '../../components/design-system/colors';
import { TextDefault } from '../../components/design-system/texts';
import { observer } from 'mobx-react';

function SubscriptionOverview({
    partnerId,
    partnerSubscriptionsOverview,
    loadingPartnerSubscriptionsOverview,
}) {
    const { superStore } = useStores();

    const payNow = async () => {
        await superStore.collectPartnerPayment(partnerId);
    };

    const openCBportal = async () => {
        if (!partnerSubscriptionsOverview) return;

        window.Chargebee.init({
            site: partnerSubscriptionsOverview.chargebeeSite,
            publishableKey: partnerSubscriptionsOverview.chargebeeClientKey,
        });

        var chargebeeInstance = Chargebee.getInstance();

        let portalSession = await superStore.openManagePartnerSession(
            partnerId
        );

        chargebeeInstance.setPortalSession(portalSession);

        let cbPortal = chargebeeInstance.createChargebeePortal();
        cbPortal.open({
            close() {
                chargebeeInstance.logout();
            },
        });
    };

    const overviewColumns = useMemo(
        () => [
            {
                Header: 'Subscription',
                accessor: 'planName',
                Cell: (x) => {
                    return <Heading4>{x.value}</Heading4>;
                },
            },
            {
                Header: 'Total companies',
                accessor: 'totalCompanies',
            },
            {
                Header: 'Total seats',
                accessor: 'totalSeats',
            },
            {
                Header: 'Monthly cost',
                accessor: 'totalMonthlyCost',
                Cell: (x) => `$${x.value.toFixed(2)}`,
                tooltip:
                    'Your monthly cost will vary depending on the total number of assigned seats across all companies.',
            },
        ],
        []
    );

    return (
        <Block>
            {partnerSubscriptionsOverview &&
            partnerSubscriptionsOverview.totalDues &&
            !partnerSubscriptionsOverview.collected ? (
                <Fragment>
                    {superStore.collectingPayment ? (
                        <ThemedSpinner />
                    ) : (
                        <FlexDiv ignoreParentPadding={32}>
                            <SpaceBetweenDiv
                                backgroundColor={colors.error}
                                fullWidth
                                px={32}
                                py={16}
                                mb={54}
                            >
                                <FlexDiv column>
                                    <Heading4 bold color={'white'}>
                                        Payment{' '}
                                        {partnerSubscriptionsOverview.hasValidCard
                                            ? 'due'
                                            : 'failed'}
                                    </Heading4>
                                    <TextDefault color={'white'}>
                                        You have due amount of{' '}
                                        {partnerSubscriptionsOverview.totalDues.toFixed(
                                            2
                                        )}{' '}
                                        {
                                            partnerSubscriptionsOverview.currencyCode
                                        }{' '}
                                        since{' '}
                                        {moment
                                            .unix(
                                                partnerSubscriptionsOverview.dueSince
                                            )
                                            .format('LL')}
                                        .{' '}
                                    </TextDefault>
                                </FlexDiv>
                                {partnerSubscriptionsOverview.hasValidCard ? (
                                    <ThemedButton
                                        black
                                        onClick={() => {
                                            payNow();
                                        }}
                                        noTextTransform
                                    >
                                        Pay now
                                    </ThemedButton>
                                ) : (
                                    <ThemedButton
                                        black
                                        onClick={() => {
                                            openCBportal();
                                        }}
                                        noTextTransform
                                    >
                                        Update your payment method
                                    </ThemedButton>
                                )}
                            </SpaceBetweenDiv>
                        </FlexDiv>
                    )}
                </Fragment>
            ) : null}
            <SpaceBetweenDiv>
                <FlexDiv column>
                    <Heading2>Account overview</Heading2>
                </FlexDiv>
                <FlexDiv alignRight>
                    <ThemedButton secondary onClick={openCBportal}>
                        Manage account
                    </ThemedButton>
                </FlexDiv>
            </SpaceBetweenDiv>
            {loadingPartnerSubscriptionsOverview ? (
                <ThemedSpinner />
            ) : (
                <FlexDiv mt={48}>
                    {partnerSubscriptionsOverview && (
                        <SimpleTable
                            data={partnerSubscriptionsOverview.rows}
                            columns={overviewColumns}
                            hidePagination={true}
                            sum={`$${partnerSubscriptionsOverview.sumMonthlyCost.toFixed(
                                2
                            )}`}
                            indexOfSumColumn={3}
                        />
                    )}
                </FlexDiv>
            )}
        </Block>
    );
}

export default observer(SubscriptionOverview);
