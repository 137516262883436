import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import ErrorMessage from '../../components/errormessage';
import { formatDate } from '../../utils/helpers';
import { Table, Tr, Td, Th, Thead } from 'reactable';
import { enforceSlash } from '../../utils/helpers';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

@inject('superStore')
@observer
class CompaniesListSuperContainer extends React.Component {
    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        this.props.superStore.loadCompanies();
    }

    render() {
        const { loadingCompanies, companies } = this.props.superStore;
        const { match } = this.props;
        const now = new Date();

        return loadingCompanies ? (
            <ThemedSpinner />
        ) : (
            <Fragment>
                <div className="block">
                    <Row>
                        <Col md="9" xs="9" className="leftMenu">
                            <h2>Companies</h2>
                        </Col>
                        <Col md="3" xs="3">
                            <div className="pull-right">
                                <Link to={`${enforceSlash(match.url)}create`}>
                                    <div className="btn btn-primary ">
                                        Create Company
                                    </div>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {companies && companies.length > 0 && (
                            <Col md="12" xs="12" className="leftMenu">
                                <Table
                                    className="table"
                                    id="table"
                                    itemsPerPage={500}
                                    pageButtonLimit={5}
                                    sortable={[
                                        {
                                            column: 'Company',
                                            sortFunction: function (a, b) {
                                                try {
                                                    return (a || '')
                                                        .toLowerCase()
                                                        .localeCompare(
                                                            (
                                                                b || ''
                                                            ).toLowerCase()
                                                        );
                                                } catch (e) {
                                                    debugger;
                                                    console.log(e);
                                                }
                                            },
                                        },
                                        'Created',
                                        'ValidTo',
                                        'Users',
                                        'Trainings',
                                    ]}
                                    defaultSort="Company"
                                    filterable={['Company']}
                                    filterPlaceholder="Search company"
                                >
                                    <Thead>
                                        <Th column="Company" className="">
                                            Company
                                        </Th>
                                        <Th column="Created" className="">
                                            Created
                                        </Th>
                                        <Th column="ValidTo" className="">
                                            Active until
                                        </Th>
                                        <Th column="Users" className="">
                                            Users
                                        </Th>
                                        <Th column="Trainings" className="">
                                            Trainings
                                        </Th>
                                    </Thead>
                                    {companies.map((company, i) => (
                                        <Tr key={i}>
                                            <Td
                                                column={'Company'}
                                                value={company.name}
                                                className=""
                                            >
                                                <Link
                                                    to={`${enforceSlash(
                                                        match.url
                                                    )}${company.id}`}
                                                >
                                                    {company.name}
                                                </Link>
                                            </Td>
                                            <Td
                                                column={'Created'}
                                                value={company.created}
                                            >
                                                {formatDate(company.created)}
                                            </Td>
                                            <Td
                                                column={'ValidTo'}
                                                value={company.valid_to}
                                                className={
                                                    new Date(company.valid_to) <
                                                    now
                                                        ? 'red'
                                                        : 'undefined'
                                                }
                                            >
                                                {formatDate(company.valid_to)}
                                            </Td>
                                            <Td
                                                column={'Users'}
                                                value={company.user_count}
                                            >
                                                {company.user_count}
                                            </Td>
                                            <Td
                                                column={'Trainings'}
                                                value={company.training_count}
                                            >
                                                {company.training_count}
                                            </Td>
                                        </Tr>
                                    ))}
                                </Table>
                            </Col>
                        )}
                        {companies && companies.length === 0 && (
                            <Col md="12" xs="12" className="leftMenu">
                                <ErrorMessage
                                    error="No companies found"
                                    errorType="info"
                                />
                                <Link to={`${enforceSlash(match.url)}create`}>
                                    <div className="btn btn-primary ">
                                        Create Company
                                    </div>
                                </Link>
                            </Col>
                        )}
                    </Row>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(CompaniesListSuperContainer);
