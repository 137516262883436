import React from 'react';
import { Block, FlexDiv } from '../design-system/containers';
import { TextTiny } from '../design-system/texts';
import { Heading3, Heading2 } from '../design-system/headings';

function NoItemsYet({
    heading,
    img,
    imgAlt,
    title,
    text,
    buttons,
    extraContent,
}) {
    return (
        <Block>
            <Heading2>{heading}</Heading2>
            <FlexDiv justifyCenter alignCenter column mt={32}>
                <img src={img} alt={imgAlt} />
                <Heading3 my={24}>{title}</Heading3>
                <TextTiny lighter mb={24}>
                    {text}
                </TextTiny>
                {buttons}
                {extraContent}
            </FlexDiv>
        </Block>
    );
}

export default NoItemsYet;
