import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { observer } from 'mobx-react';
import SimpleTable from '../../components/SimpleTable';
import { useStores } from '../../hooks/use-stores';
import IconButton from '@material-ui/core/IconButton';
import { Clipboard } from '../../components/design-system/clipboard';
import ErrorMessage from '../../components/errormessage';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import { Heading3 } from '../../components/design-system/headings';
import { Block, FlexDiv } from '../../components/design-system/containers';
import { TextDefault } from '../../components/design-system/texts';
import { colors } from '../../components/design-system/colors';
import { TextInput } from '../../components/design-system/inputs';
import {
    SmallFileCopy,
    SmallEdit,
    SmallDelete,
    PrimaryOpenInNew,
} from '../../components/design-system/icons';

function APIKeys() {
    const [modalId, setModalId] = useState(0);
    const [modalName, setModalName] = useState('');
    const [modalShowLoader, setModalShowLoader] = useState(false);
    const [isModalOpen, setModalIsOpen] = useState(false);

    const { commonStore, companyStore, store } = useStores();
    const { currentCompany } = store;
    const { apiKeys, error, deletingApiKeys, loadingApiKeys } = companyStore;

    useEffect(() => {
        if (currentCompany) companyStore.loadApiKeys(currentCompany.company_id);
    }, [currentCompany, companyStore]);

    const createNew = () => {
        companyStore.createNewApiKey(currentCompany.company_id);
    };

    const saveName = async (id, name) => {
        await handleChangeName(id, name);
        setModalIsOpen(false);
    };

    const handleChangeName = React.useCallback(
        async (id, value) => {
            companyStore.setApiKeyName(currentCompany.company_id, id, value);
        },
        [companyStore, currentCompany]
    );

    const columns = useMemo(() => {
        const openModal = (id, name) => {
            setModalId(id);
            setModalName(name);
            setModalIsOpen(true);
        };

        const deleteKey = (id) => {
            commonStore.showConfirm(
                'Do you really want to delete this API key?',
                'Delete',
                'Delete API key?',
                () => {
                    companyStore.deleteApiKey(id, null, {
                        companyId: currentCompany.company_id,
                    });
                }
            );
        };

        const onSuccessCopy = () => {
            commonStore.success('Copied');
        };

        return [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: observer((x) => {
                    return (
                        <FlexDiv>
                            <TextDefault
                                link
                                onClick={() => {
                                    openModal(
                                        x.row.original.id,
                                        x.row.original.name
                                    );
                                }}
                            >
                                <SmallEdit fontSize="small" />
                                &nbsp;
                                {x.row.original.name}
                            </TextDefault>
                        </FlexDiv>
                    );
                }),
            },
            {
                Header: 'Access Key',
                accessor: 'value',
                Cell: observer((x) => (
                    <FlexDiv>
                        <Clipboard
                            onSuccess={onSuccessCopy}
                            data-clipboard-text={x.value}
                        >
                            {x.value}{' '}
                            <SmallFileCopy color="primary" fontSize="small" />
                        </Clipboard>
                    </FlexDiv>
                )),
                disableGlobalFilter: true,
            },
            {
                Header: 'Delete',
                accessor: 'id',
                Cell: observer((x) => (
                    <FlexDiv>
                        {deletingApiKeys.includes(x.value) ? (
                            <CircularProgress />
                        ) : (
                            <IconButton
                                onClick={() => {
                                    deleteKey(x.value);
                                }}
                            >
                                <SmallDelete fontSize="small" />
                            </IconButton>
                        )}
                    </FlexDiv>
                )),
                disableGlobalFilter: true,
            },
        ];
    }, [commonStore, companyStore, currentCompany.company_id, deletingApiKeys]);

    return (
        <FlexDiv>
            <Block flexGrow="9" mr="20">
                <ErrorMessage error={error} />
                <FlexDiv column>
                    <Heading3 mb={24}>API Keys</Heading3>
                    <TextDefault>
                        This API is designed to allow access, creation and
                        modifications of trainings and assessments.
                    </TextDefault>
                    <TextDefault>
                        For information on how to use this API, refer to our{' '}
                        <Link
                            href="https://api.awarego.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            online documentation
                        </Link>
                        .
                    </TextDefault>
                </FlexDiv>
                <FlexDiv pt="32" pb="32">
                    <ThemedButton onClick={createNew} primary>
                        CREATE API KEY
                    </ThemedButton>
                </FlexDiv>
                {loadingApiKeys && <CircularProgress />}
                <SimpleTable
                    columns={columns}
                    initialyLoaded={loadingApiKeys === false}
                    data={[...apiKeys]}
                />
            </Block>
            <Block column flexGrow="3">
                <Heading3 color={colors.success} mb={24}>
                    Online documentation
                </Heading3>
                <TextDefault>
                    <Link
                        href="https://api.awarego.com"
                        target="_blank"
                        rel="noreferrer"
                        color="inherit"
                    >
                        Learn more on how to use AwareGO API{' '}
                        <PrimaryOpenInNew />
                    </Link>
                </TextDefault>
            </Block>

            {isModalOpen && (
                <Modal isOpen={isModalOpen} className="Modal auto-size-modal">
                    <FlexDiv className={'modalContent'} padding="20" mb="20">
                        {!modalShowLoader && (
                            <TextInput
                                type="text"
                                className="form-control"
                                value={modalName}
                                onChange={(e) => setModalName(e.target.value)}
                            />
                        )}
                        {modalShowLoader && <CircularProgress />}
                    </FlexDiv>
                    <FlexDiv
                        className={'modalFooter'}
                        padding="20"
                        margin="0"
                        noMinHeight
                    >
                        <ThemedButton
                            primary
                            onClick={async () => {
                                setModalShowLoader(true);
                                await saveName(modalId, modalName);
                                setModalShowLoader(false);
                            }}
                        >
                            Save
                        </ThemedButton>
                        &nbsp;&nbsp;
                        <ThemedButton
                            secondary
                            onClick={() => {
                                setModalIsOpen(false);
                            }}
                        >
                            Close
                        </ThemedButton>
                    </FlexDiv>
                </Modal>
            )}
        </FlexDiv>
    );
}

export default observer(APIKeys);
