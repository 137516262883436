import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

@inject('superStore')
@observer
class MailHooksContainer extends React.Component {
    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        await this.props.superStore.loadMailHooks();
    }

    createHooks = async () => {
        await this.props.superStore.createMailHooks();
    };

    render() {
        const { loadingMailHooks, mailHooks } = this.props.superStore;

        return loadingMailHooks ? (
            <ThemedSpinner />
        ) : (
            <Fragment>
                <Row>
                    {mailHooks && mailHooks.length > 0 && (
                        <Col md="12" xs="12" className="leftMenu">
                            <h3> Hooks is up</h3>
                        </Col>
                    )}
                    {mailHooks && mailHooks.length === 0 && (
                        <Col md="12" xs="12" className="leftMenu">
                            <ErrorMessage
                                error="No hooks found"
                                errorType="info"
                            />
                            <Button
                                onClick={this.createHooks}
                                className="btn btn-primary "
                            >
                                Create Hooks
                            </Button>
                        </Col>
                    )}
                </Row>
            </Fragment>
        );
    }
}

export default withRouter(MailHooksContainer);
