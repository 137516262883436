import Form from 'mobx-react-form';
import * as validatorjs from 'validatorjs';

const plugins = {
    dvr: {
        package: validatorjs,
        extend: ($validator) => {
            var messages = $validator.getMessages('en');
            messages.required = 'You need to enter some information to save';
            $validator.setMessages('en', messages);
        },
    },
};

const fields = {
    name: {
        label: 'Name',
        rules: 'string|required|between:4,150',
        placeholder: 'native name',
    },
    english_name: {
        label: 'English Name',
        rules: 'string|required|between:4,150',
        placeholder: 'English name',
    },
    code: {
        label: 'Code',
        rules: 'string|required',
        placeholder: 'Language code',
    },
    icon: {
        label: 'Icon (css class)',
        rules: 'string',
        placeholder: 'Language icon (css)',
    },
    flag_image: {
        label: 'flag image',
        rules: 'string',
        placeholder: 'Flag Image',
    },
    version: {
        label: 'Version',
        rules: 'required',
        value: 'latest',
        placeholder: 'version',
    },
};

class CompanyForm extends Form {
    constructor(setup, options) {
        super({ fields, ...setup }, { plugins, ...options });
    }
}

export default CompanyForm;
