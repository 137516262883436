import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { ThemedDropdownItem } from '../../components/themed/ThemedComponents';
import Setting from './Setting';
import SettingSelect from './SettingSelect';
import CompanyEditContainer from '../companies/CompanyEditor';
import Modal from 'react-modal';

@inject('commonStore', 'store', 'companyStore')
@observer
class CompanySettingsContainer extends React.Component {
    state = {
        dropdownOpen: false,
        showEditCompanyModal: false,
    };

    toggleCompanyDropdown = () => {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    };

    openEditModal = (companyId) => {
        this.setState({ editCompanyId: companyId, showEditCompanyModal: true });
    };

    closeEditModal = () => {
        this.setState({ editCompanyId: null, showEditCompanyModal: false });
    };

    onCustomizeAppearance = () => {
        let path = '/settings/company/appearance';
        this.props.history.push(path);
    };

    onChangeCertificates = () => {
        alert('onChangeCertificates');
    };

    onChangeLanguage = async (languageKey) => {
        this.props.store.currentCompany.defaultLanguage = languageKey;
        await this.props.store.saveCurrentCompany({
            ...{ defaultLanguage: languageKey },
        });
    };

    onMakeAnonymous = () => {
        this.props.commonStore.showConfirm(
            "Please note that once user logging has been disabled, you can't enable it again later. All previously collected data will be made anonymous.",
            'Disable user logging',
            'Are you sure you want to proceed?',
            async () => {
                await this.props.companyStore.switchTrackingAnonymous(
                    this.props.store.currentCompany.company_id
                );
                await this.props.store.saveCurrentCompany({});
            },
            true
        );
    };

    onChangeNewEmployeeEnrolment = async () => {
        let value =
            this.props.store.currentCompany.settings['ui.sendEnrolment'] === '1'
                ? '0'
                : '1';
        await this.props.companyStore.saveEnrolmentSetting(
            this.props.store.currentCompany.company_id,
            value
        );
    };

    createOptionsArray = (languages) => {
        let optionsArray = [];
        for (let i = 0; i < languages.length; i++) {
            optionsArray.push({
                key: languages[i].code,
                value: languages[i].code,
                name: languages[i].english_name,
            });
        }
        return optionsArray;
    };

    onChangeCompany = async (id) => {
        await this.props.store.setCurrentCompany(id);
    };

    render() {
        const { serverData, currentCompany } = this.props.store;
        const { languages } = this.props.store;
        const hasAnonymousLogging = currentCompany.anonymousTracking;
        console.log(currentCompany);

        return (
            <div className="top-guide">
                <div className="block new-settings-block">
                    <div className="row">
                        <div className="col-md-8 ">
                            {currentCompany && (
                                <Dropdown
                                    className="company-dropdown"
                                    isOpen={this.state.dropdownOpen}
                                    toggle={this.toggleCompanyDropdown}
                                >
                                    <DropdownToggle tag="h3">
                                        {currentCompany &&
                                            currentCompany.company_name}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {serverData.map((x) => {
                                            return (
                                                <ThemedDropdownItem
                                                    key={x.company_id}
                                                    selected={
                                                        x.company_id ===
                                                        currentCompany.company_id
                                                            ? 1
                                                            : 0
                                                    }
                                                    onClick={(e) =>
                                                        this.onChangeCompany(
                                                            x.company_id
                                                        )
                                                    }
                                                >
                                                    {x.company_name}
                                                </ThemedDropdownItem>
                                            );
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            )}
                            <p className="text-thin">Manage company settings</p>
                        </div>
                        <div className="col-md-3 offset-md-1">
                            {currentCompany.logo_name ? (
                                <img
                                    className="image  company-logo"
                                    src={
                                        process.env.REACT_APP_RESOURCES_URL +
                                        '/' +
                                        process.env
                                            .REACT_APP_COMPANY_RESOURCES_DIR +
                                        '/' +
                                        currentCompany.logo_name
                                    }
                                    alt="Your logo"
                                    onClick={this.onCustomizeAppearance}
                                />
                            ) : (
                                <img
                                    className="image  company-logo"
                                    src="/static/img/logo-image.svg"
                                    alt="Add your logo"
                                    onClick={this.onCustomizeAppearance}
                                />
                            )}
                        </div>
                    </div>

                    <Setting
                        name="Name"
                        value={currentCompany.company_name}
                        actionName="Change"
                        action={this.openEditModal}
                    />
                    <Setting
                        name="Appearance"
                        actionName="Customize"
                        action={this.onCustomizeAppearance}
                    />
                    {/* TODO: enable certificate setting https://app.asana.com/0/1145541225990481/1171578736734705

                <Setting name='Certificates' value={(currentCompany.settings && currentCompany.settings['ui.certificates_enabled'] === 'false') ? 'Disabled' : 'Enabled'} actionName='Change' action={this.onChangeCertificates}/>
                <hr/>*/}
                    <SettingSelect
                        name="Default language for employees"
                        options={this.createOptionsArray(languages)}
                        defaultValue={currentCompany.defaultLanguage || 'en'}
                        action={this.onChangeLanguage}
                    />
                    <Setting
                        name="User logging"
                        value={hasAnonymousLogging ? 'Disabled' : 'Enabled'}
                        actionName={hasAnonymousLogging ? '' : 'Disable'}
                        action={this.onMakeAnonymous}
                        helpText="PLEASE NOTE: Once user logging is disabled, you cannot enable it again."
                        helpId={
                            'PopoverUserLogging' + currentCompany.company_id
                        }
                    />
                    <Setting
                        name="Send enrolment email to new employees"
                        value={
                            currentCompany.settings['ui.sendEnrolment'] === '1'
                                ? 'Enabled'
                                : 'Disabled'
                        }
                        actionName={
                            currentCompany.settings['ui.sendEnrolment'] === '1'
                                ? 'Disable'
                                : 'Enable'
                        }
                        action={this.onChangeNewEmployeeEnrolment}
                        subtext="When Enabled, if a new employee is assigned to a list with a training, they get sent a welcome email with a sign in link"
                        isLast={true}
                    />
                </div>
                <Modal
                    isOpen={this.state.showEditCompanyModal}
                    onRequestClose={this.closeEditModal}
                    className={'Modal auto-size-modal'}
                    ariaHideApp={false}
                >
                    <CompanyEditContainer
                        company={currentCompany}
                        companyId={currentCompany.company_id}
                        fromNewSettings={true}
                        onSaved={this.closeEditModal}
                        onRequestClose={this.closeEditModal}
                    />
                </Modal>
            </div>
        );
    }
}

export default withRouter(CompanySettingsContainer);
