import React from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'react-modal';
import VideoPreview from '../components/videoPreview';
import CallToActionBanner from '../components/CallToActionBanner';
import Subject from './Subject';
import { ThemedButton } from '../components/themed/ThemedComponents';

@inject('store', 'catalogStore', 'brandingStore')
@observer
class VideoPreviewContainer extends React.Component {
    state = { error: '', loadedOrError: null };

    constructor(props) {
        super(props);
        reaction(
            () => this.props.store.tokens,
            (tokens) => {
                console.log(
                    this.props.store.tokenIndex[this.state.previewVideoLocation]
                );
            }
        );
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState) return; // not yet initialized
        if (nextProps.previewVideoLocation !== prevState.previewVideoLocation) {
            return {
                previewVideoLocation: nextProps.previewVideoLocation,
                loadedOrError: null,
            };
        }

        // No state update necessary
        return null;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        // At this point, we're in the "commit" phase, so it's safe to load the new data.
        this.loadData();
    }

    async loadData() {
        if (!this.props.store.videoPlayerUrl)
            await this.props.store.loadVideoPlayerUrl();
        if (
            this.state.previewVideoLocation &&
            !this.props.store.tokenIndex[this.state.previewVideoLocation]
        )
            await this.props.store.videoToken(this.state.previewVideoLocation);
    }

    videoTokenRequested = async (videoId) => {
        this.props.store.videoToken(videoId);
    };

    //

    render() {
        const { isOpen, onClose, previewVideoLocation, modal, catalogStore } =
            this.props;
        const {
            videoPlayerUrl,
            tokenIndex,
            currentCompany,
            havePartnerSubscription,
        } = this.props.store;
        const { supportEmail } = this.props.brandingStore;
        let token = tokenIndex[previewVideoLocation];
        let subject = catalogStore.allSubjectsIndex[previewVideoLocation];

        if (modal && subject)
            return (
                <Modal
                    isOpen={isOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={onClose}
                    className="Modal schedule"
                >
                    <div className={'modalHeader'}>
                        <h3>Preview lesson: {subject.title}</h3>
                        <img
                            className={'modal-close-right'}
                            src="/static/img/close.svg"
                            alt="Close"
                            onClick={onClose}
                        />
                    </div>
                    {subject.disabled && (
                        <CallToActionBanner
                            isPartnerCompany={havePartnerSubscription}
                            supportEmail={supportEmail}
                        />
                    )}
                    <div className={'modalContent'}>
                        <Subject
                            subject={subject}
                            showBackLink={false}
                            companyId={
                                currentCompany && currentCompany.company_id
                            }
                        />
                    </div>
                    <div className={'modalFooter'}>
                        <ThemedButton primary onClick={onClose}>
                            Back to add subjects
                        </ThemedButton>
                    </div>
                </Modal>
            );

        return (
            <VideoPreview
                videoTokenRequested={this.videoTokenRequested}
                token={token && token.token}
                videoPlayerUrl={videoPlayerUrl}
                modal={false}
                subject={subject}
                previewVideoLocation={previewVideoLocation}
                isOpen={isOpen}
                onClose={onClose}
            />
        );
    }
}

export default VideoPreviewContainer;
