import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import UserCampaignDetails from '../../components/manager/users/UserCampaignDetails';
import BaseCompanyDependentChildContainer from '../common/BaseCompanyDependentChildContainer';
import MailLog from './MailLog';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

@inject('automationStore', 'commonStore', 'authStore')
@observer
class AutomationEventUserDetailsContainer extends BaseCompanyDependentChildContainer {
    async doLoadData() {
        return await this.props.automationStore.loadEventUserDetails(
            this.props.companyId,
            this.props.automationId,
            this.props.eventId,
            this.props.email
        );
    }

    render() {
        const { automationUserStatus, loadingAutomationUserDetails } =
            this.props.automationStore;
        const { anonymousTracking } = this.props.authStore;

        return !loadingAutomationUserDetails && automationUserStatus ? (
            <Fragment>
                <UserCampaignDetails
                    userDetails={automationUserStatus}
                    anonymousTracking={anonymousTracking}
                    onSendReminder={this.onSendReminder}
                    email={this.props.email}
                />
                {automationUserStatus.log && (
                    <MailLog records={automationUserStatus.log} />
                )}
            </Fragment>
        ) : (
            <ThemedSpinner />
        );
    }
}

export default withRouter(AutomationEventUserDetailsContainer);
