import React from 'react';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import styled, { withTheme } from 'styled-components';

const ThemedNewsList = styled.div`
    width: 32px;
    height: 32px;
    position: absolute;
    right: -3px;
    top: -3px;
`;

class ThemedNewsListSVG extends React.Component {
    render() {
        return (
            <ThemedNewsList>
                <SvgLoader path="/static/img/icon-news-users.svg">
                    <SvgProxy selector="path" fill={this.props.theme.primary} />
                    <SvgProxy
                        selector="circle"
                        fill={this.props.theme.primary}
                    />
                </SvgLoader>
            </ThemedNewsList>
        );
    }
}

export default withTheme(ThemedNewsListSVG);
