import React from 'react';
import { Link } from 'react-router-dom';
import { ThemedText } from '../themed/ThemedComponents';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import { FlexDiv } from '../design-system/containers';
import { TextDefault } from '../design-system/texts';

export default observer(({ disabled, currentCompany, centered }) => {
    const { brandingStore } = useStores();
    const { supportEmail } = brandingStore;

    return (
        disabled && (
            <FlexDiv
                column
                mt={centered ? 24 : 10}
                alignEnd={!centered}
                alignCenter={centered}
            >
                <TextDefault lighter>Employees limit reached.</TextDefault>
                {currentCompany.chargebeeEnabled ? (
                    <Link to={`/subscriptions/upgrade/`}>
                        <ThemedText>Add more</ThemedText>
                    </Link>
                ) : (
                    <TextDefault lighter>
                        Please contact{' '}
                        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                    </TextDefault>
                )}
            </FlexDiv>
        )
    );
});
