import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { ThemedButton } from '../../themed/ThemedComponents';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
    createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ListSubheader from '@material-ui/core/ListSubheader';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';
import services from '../../../services';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';

const LISTBOX_PADDING = 8; // px
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 12,
        fontFamily: 'Nunito Sans, sans-serif',
        '& > span': {
            marginRight: 10,
            fontSize: 12,
        },
    },

    input: {
        //When inputting text
        fontFamily: 'Nunito Sans, sans-serif',
        '& input': {
            fontSize: 12,
        },
        '& .Mui-focused  fieldset': {
            borderColor: 'rgba(52, 53, 52, 0.2) !important',
        },
    },
    tag: {
        //The user 'Chips'
        fontFamily: 'Nunito Sans, sans-serif',
    },
    root: {
        '& .MuiFormLabel-root': {
            //Root of FormLabel within AutoComplete root (Actual label)
            fontSize: 14,
            fontFamily: 'Nunito Sans, sans-serif',
        },

        '& .MuiInputLabel-formControl': {
            //Label small in upper left corner of input
            top: '-5px',
        },

        '& .Mui-focused': {
            //Label small in upper left corner of input
            color: theme.primary || 'black',
        },
    },
}));
const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const ListboxComponent = React.forwardRef(function ListboxComponent(
    props,
    ref
) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (React.isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

export default observer(
    ({ form, companyId, showHeader = false, onSelectExistingUsers }) => {
        const classes = useStyles();
        const [open, setOpen] = React.useState(false);
        const [lastLoaded, setLastLoaded] = React.useState(null);
        const [options, setOptions] = React.useState([]);
        const loading = open && options.length === 0;

        React.useEffect(() => {
            if (!loading) {
                return undefined;
            }

            (async () => {
                const response = await services.Companies.usersService(
                    companyId
                ).list();

                setOptions(response);
                setLastLoaded(new Date());
            })();
        }, [loading]);

        React.useEffect(() => {
            //
            if (
                !open &&
                (!lastLoaded || new Date() - lastLoaded > 5 * 60 * 1000)
            ) {
                setOptions([]);
            }
        }, [open]);

        const handleSelectUser = (e, value) => {
            onSelectExistingUsers(value);
        };

        //We filter by any string match, in either email or username
        const filterOptions = createFilterOptions({
            matchFrom: 'any',
            stringify: (option) => {
                return option.user_name + option.email;
            },
        });

        return (
            <Fragment>
                {onSelectExistingUsers && (
                    <>
                        <div className="d-flex justify-content-end">
                            <Autocomplete
                                disableCloseOnSelect
                                onChange={handleSelectUser}
                                open={open}
                                multiple
                                onOpen={() => {
                                    setOpen(true);
                                }}
                                onClose={() => {
                                    setOpen(false);
                                }}
                                getOptionSelected={(option, value) => {
                                    return (
                                        option &&
                                        value &&
                                        option.email === value.email
                                    );
                                }}
                                getOptionLabel={(option) => {
                                    //What we display after selection, the 'chip'
                                    return `${option.email}`;
                                }}
                                filterOptions={filterOptions}
                                ListboxComponent={ListboxComponent}
                                disableListWrap
                                options={options}
                                loading={loading}
                                noOptionsText="No match"
                                size="medium"
                                limitTags={1}
                                id="multiple-limit-tags"
                                classes={classes}
                                style={{ width: '350px', marginRight: '24px' }}
                                renderOption={(option, { selected }) => (
                                    <React.Fragment>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.email} ({option.user_name})
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    ) : null}
                                                    {
                                                        params.InputProps
                                                            .endAdornment
                                                    }
                                                </React.Fragment>
                                            ),
                                        }}
                                        {...params}
                                        label="Search existing users"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </div>
                        <hr style={{ marginBottom: '10px' }} />
                    </>
                )}
                {showHeader && (
                    <div className={'d-flex flex-row'}>
                        <div className="form-holder">
                            <p>Email address</p>
                        </div>
                        <div className="form-holder">
                            <p>Name (optional)</p>
                        </div>
                    </div>
                )}
                {form.$('users').map((user) => {
                    return (
                        <div key={user.key}>
                            <div className="form-holder">
                                <div className={'d-flex flex-row'}>
                                    <div className="form-holder">
                                        <input
                                            type="email"
                                            name="recipientEmail"
                                            className={
                                                user.$('email').isValid
                                                    ? 'bigInput dark'
                                                    : 'bigInput '
                                            }
                                            {...user.$('email').bind()}
                                        />
                                        <div className="error">
                                            {user.$('email').error}
                                        </div>
                                    </div>
                                    <div className="form-holder">
                                        <input
                                            type="text"
                                            name="recipientName"
                                            className={
                                                user.$('name').isValid
                                                    ? 'bigInput dark'
                                                    : 'bigInput '
                                            }
                                            {...user.$('name').bind()}
                                        />
                                    </div>
                                    <IconButton
                                        aria-label="delete"
                                        onClick={user.onDel}
                                        color="secondary"
                                    >
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div>
                    <ThemedButton
                        secondary
                        className="mt20 plus"
                        onClick={form.$('users').onAdd}
                    >
                        +
                    </ThemedButton>
                </div>
            </Fragment>
        );
    }
);
