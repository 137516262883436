import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { lighten } from 'polished';
import { withRouter } from 'react-router';
import React from 'react';

const colors = {
    heavyMetal: '#343534',
};

const LinkStep = styled(Link)`
    white-space: nowrap;
    line-height: 42px;
    padding-left: 30px;
    padding-right: 10px;
    font-size: 16px;
    color: ${lighten(0.1, colors.heavyMetal)};
    font-weight: 600;
    border-left: 4px solid white;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        color: ${(props) => props.color || props.theme.primary};
    }
    ${(props) =>
        props.active &&
        css`
            border-left: 4px solid
                ${(props) => props.color || props.theme.primary};
            color: ${(props) => props.color || props.theme.primary};
        `};
`;

const StepName = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StepCount = styled.span`
    padding: 0 10px;
    text-transform: none;
    text-transform: initial;
    font-size: 12px;
    text-align: right;
`;

const ThemedLinkStep = (props) => {
    const { text, count, staticContext, ...rest } = props;
    return (
        <LinkStep {...rest}>
            <StepName>{text}</StepName>
            <StepCount>{count}</StepCount>
        </LinkStep>
    );
};

export default withRouter(ThemedLinkStep);
