import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Container, Row, Col, Spinner } from 'reactstrap';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import ACLEditor from './ACLEditor';
import { useHistory } from 'react-router-dom';
import { ThemedButton } from '../../components/themed/ThemedComponents';

export default observer(({ questionId }) => {
    const { superStore } = useStores();
    const [nameError, setNameError] = useState(false);
    const [definitionError, setDefinitionError] = useState(false);
    let history = useHistory();
    useEffect(() => {
        (async () => {
            if (questionId) await superStore.loadQuestion(questionId);
            else {
                superStore.editingQuestion = { name: '', definition: '' };
            }
        })();
    }, [questionId]);

    const handleSave = async () => {
        if (!superStore.editingQuestion.name) {
            setNameError(true);
            return;
        }
        setNameError(false);
        try {
            JSON.parse(superStore.editingQuestion.name);
            setDefinitionError(false);
        } catch (e) {
            setDefinitionError(true);
        }

        let result;
        if (questionId) {
            result = await superStore.updateQuestion(
                superStore.editingQuestion
            );
        } else
            result = await superStore.createQuestion(
                superStore.editingQuestion
            );

        if (result) {
            if (questionId)
                return history.push(
                    `/super/content/questions/${questionId}/edit`
                );
            else return history.push('/super/content/questions');
        }
    };

    const {
        loadingQuestion,
        editingQuestion,
        questionsError,
        updatingQuestions,
        creatingQuestion,
    } = superStore;

    return loadingQuestion || !editingQuestion ? (
        <ThemedSpinner />
    ) : (
        <Container>
            <ErrorMessage error={questionsError} />
            {editingQuestion && (
                <Fragment>
                    <Row>
                        <Col md="12" xs="12" className="leftMenu">
                            <div className="form-holder">
                                <p>Question name</p>
                                <input
                                    type="text"
                                    name="listName"
                                    value={
                                        superStore.editingQuestion.name || ''
                                    }
                                    onChange={(e) => {
                                        superStore.editingQuestion.name =
                                            e.target.value;
                                    }}
                                    className={
                                        nameError
                                            ? 'bigInput invalid'
                                            : 'bigInput'
                                    }
                                />
                                {nameError && (
                                    <div className="error">
                                        Name is required
                                    </div>
                                )}
                            </div>

                            <div className="form-holder">
                                <p>Definition</p>
                                <textarea
                                    rows={15}
                                    cols={70}
                                    name="listName"
                                    value={editingQuestion.definition || ''}
                                    onChange={(e) => {
                                        editingQuestion.definition =
                                            e.target.value;
                                    }}
                                    className={
                                        definitionError
                                            ? 'bigInput invalid'
                                            : 'bigInput'
                                    }
                                />
                                {definitionError && (
                                    <div className="error">
                                        Should be valid JSON
                                    </div>
                                )}
                            </div>
                            <div>
                                {creatingQuestion ||
                                (questionId &&
                                    updatingQuestions.includes(questionId)) ? (
                                    <Spinner />
                                ) : (
                                    <ThemedButton
                                        secondary
                                        onClick={handleSave}
                                        airy
                                    >
                                        Save
                                    </ThemedButton>
                                )}
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col md="12" xs="12" className="leftMenu">
                            <ACLEditor
                                acl={editingQuestion.acl}
                                unique_id={editingQuestion.unique_id}
                            />
                        </Col>
                    </Row>
                </Fragment>
            )}
        </Container>
    );
});
