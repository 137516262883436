import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Row, Col, Badge } from 'reactstrap';
import { Table, Tr, Td, Th, Thead } from 'reactable';
import ErrorMessage from '../../errormessage';
import { enforceSlash } from '../../../utils/helpers';
import ThemedSpinner from '../../themed/ThemedSpinner';
import { ThemedLinkLink } from '../../themed/ThemedComponents';

@observer
class ListUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: '',
        };
    }

    render() {
        const { deleteUser, deletingItems, parentUrl } = this.props;

        if (this.props.users && this.props.users.length > 0) {
            const users = this.props.users.slice(0);
            return (
                <div>
                    <ErrorMessage
                        error={this.state.error}
                        errorType="warning"
                    />

                    <Row>
                        <Col md="12" xs="12" className="content">
                            <div>
                                <Table
                                    className="table"
                                    id="table"
                                    itemsPerPage={20}
                                    pageButtonLimit={5}
                                    sortable={['Email', 'Name']}
                                    defaultSort
                                    filterable={['Email', 'Name']}
                                    filterPlaceholder="Search Employees"
                                >
                                    <Thead>
                                        <Th column="Email" className="title" />
                                        <Th column="Name" className="title" />
                                        <Th column="Tags" className="title" />
                                        <Th column="delete" className="">
                                            {' '}
                                        </Th>
                                    </Thead>

                                    {users.length > 0 &&
                                        users.map((user, i) => {
                                            return (
                                                <Tr key={i}>
                                                    <Td
                                                        column="Email"
                                                        value={user.email}
                                                        className="user-title"
                                                    >
                                                        {/* FIXME: set correct path */}
                                                        <ThemedLinkLink
                                                            to={`${enforceSlash(
                                                                parentUrl
                                                            )}user/${
                                                                user.email
                                                            }`}
                                                        >
                                                            {' '}
                                                            {user.email}
                                                        </ThemedLinkLink>
                                                    </Td>
                                                    <Td
                                                        column="Name"
                                                        value={user.user_name}
                                                        className="user-title"
                                                    >
                                                        {user.user_name}
                                                    </Td>
                                                    <Td
                                                        column="Tags"
                                                        value={user.user_name}
                                                        className="user-title"
                                                    >
                                                        <Fragment>
                                                            {user.source ===
                                                            'azure' ? (
                                                                <Badge
                                                                    className="badge"
                                                                    color="light"
                                                                >
                                                                    AD IMPORTED
                                                                </Badge>
                                                            ) : (
                                                                ''
                                                            )}
                                                            {user.source ===
                                                            'google' ? (
                                                                <Badge
                                                                    className="badge"
                                                                    color="light"
                                                                >
                                                                    GOOGLE
                                                                    IMPORTED
                                                                </Badge>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </Fragment>
                                                    </Td>

                                                    <Td
                                                        column="delete"
                                                        className="user-title"
                                                    >
                                                        {deletingItems.includes(
                                                            user.email
                                                        ) ? (
                                                            <ThemedSpinner
                                                                size={35}
                                                            />
                                                        ) : (
                                                            <div
                                                                className="btn delete-bin"
                                                                onClick={() => {
                                                                    deleteUser(
                                                                        user.email
                                                                    );
                                                                }}
                                                            ></div>
                                                        )}
                                                    </Td>
                                                </Tr>
                                            );
                                        })}
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return (
                <div className="text-center">
                    <br />
                    <img src="/static/img/icon-users.svg" alt="No users" />
                    <br />
                    <br />
                    <p style={{ fontSize: '16px', fontWeight: 600 }}>
                        No employees yet
                    </p>
                    <br />
                    <br />
                </div>
            );
        }
    }
}

ListUsers.propTypes = {
    deleteUser: PropTypes.func,
};

export default ListUsers;
