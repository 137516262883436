import React from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import pluralize from 'pluralize';
import ErrorMessage from '../../errormessage';
import {
    ThemedButton,
    ThemedRadiobutton,
    ThemedCheckmarkInputContainer,
} from '../../themed/ThemedComponents';

@observer
class PreviewEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            targetEmail: this.props.ownEmail,
            previewType: props.scheduleType === 1 ? 1 : 0,
            selectedEvents: [],
        };
    }

    onChangeEmail = (e) => {
        this.setState({ targetEmail: e.target.value });
    };

    onChangePreviewType = (value) => {
        this.setState({ previewType: value });
    };

    toggleEvent = (value) => {
        var events = this.state.selectedEvents.slice(0);
        if (events.includes(value)) events = events.filter((x) => x !== value);
        else events.push(value);

        this.setState({ selectedEvents: events });
    };

    onPreviewSend = () => {
        this.props.onPreviewSend(
            this.state.targetEmail,
            this.state.previewType,
            this.state.selectedEvents
        );
    };

    render() {
        const { result, onRequestClose, interval, introductory, scheduleType } =
            this.props;
        let period = interval === 'M' ? 'Month' : 'Week';

        if (result) {
            return (
                <div className="modalWindow simple">
                    <div className={'modalHeader plain'}>
                        <h3>Send a preview</h3>
                        <img
                            className={'modal-close-right'}
                            src="/static/img/close.svg"
                            alt="Close"
                            onClick={onRequestClose}
                        />
                    </div>
                    <div className={'modalContent'}>
                        <div className={'d-flex bd-highlight mb-3 '}>
                            <div>
                                <label
                                    htmlFor="email"
                                    className={
                                        this.state.previewType === 1
                                            ? 'container-radiobutton checked'
                                            : 'container-radiobutton'
                                    }
                                    onClick={(e) => {
                                        this.onChangePreviewType(1);
                                    }}
                                >
                                    All training emails
                                    <input
                                        type="radio"
                                        name="active"
                                        id="typeNow"
                                        className="styled-radiobutton"
                                        value={1}
                                        checked={this.state.previewType === 1}
                                        onChange={(e) => {
                                            this.onChangePreviewType(1);
                                        }}
                                    />
                                    <ThemedRadiobutton
                                        {...{
                                            checked:
                                                this.state.previewType === 1,
                                        }}
                                    />
                                </label>
                            </div>
                            {scheduleType !== 1 && (
                                <div>
                                    <label
                                        htmlFor="email"
                                        className={
                                            this.state.previewType === 2
                                                ? 'ml20 container-radiobutton checked'
                                                : ' ml20 container-radiobutton'
                                        }
                                        onClick={(e) => {
                                            this.onChangePreviewType(2);
                                        }}
                                    >
                                        Specific training emails
                                        <input
                                            type="radio"
                                            disabled={scheduleType === 1}
                                            name="active"
                                            id="typeSchedule"
                                            className="styled-radiobutton"
                                            value={2}
                                            checked={
                                                this.state.previewType === 2
                                            }
                                            onChange={(e) => {
                                                this.onChangePreviewType(2);
                                            }}
                                        />
                                        <ThemedRadiobutton
                                            {...{
                                                checked:
                                                    this.state.previewType ===
                                                    2,
                                            }}
                                        />
                                    </label>
                                </div>
                            )}
                        </div>
                        <hr />

                        {introductory && (
                            <label
                                htmlFor="email"
                                className={
                                    this.state.previewType === 3
                                        ? 'container-radiobutton checked'
                                        : 'container-radiobutton'
                                }
                                onClick={(e) => {
                                    this.onChangePreviewType(3);
                                }}
                            >
                                Intro message
                                <input
                                    type="radio"
                                    name="active"
                                    id="typeSchedule"
                                    className="styled-radiobutton"
                                    value={3}
                                    checked={this.state.previewType === 3}
                                    onChange={(e) => {
                                        this.onChangePreviewType(3);
                                    }}
                                />
                                <ThemedRadiobutton
                                    {...{
                                        checked: this.state.previewType === 3,
                                    }}
                                />
                            </label>
                        )}

                        {this.state.previewType === 2 && (
                            <table className="simple-clear-table">
                                <tbody>
                                    {result.map((event, i) => (
                                        <tr key={i}>
                                            <td>
                                                <ThemedCheckmarkInputContainer
                                                    className={
                                                        'container-checkbox small'
                                                    }
                                                >
                                                    <label
                                                        htmlFor={
                                                            'introMessage' + i
                                                        }
                                                    >
                                                        <input
                                                            type="checkbox"
                                                            className="checkmark"
                                                            id={
                                                                'introMessage' +
                                                                i
                                                            }
                                                            checked={this.state.selectedEvents.includes(
                                                                i
                                                            )}
                                                            onChange={(e) => {
                                                                this.toggleEvent(
                                                                    i
                                                                );
                                                            }}
                                                        />
                                                        Include Introductory
                                                        email
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </ThemedCheckmarkInputContainer>
                                            </td>
                                            <td>
                                                {period}{' '}
                                                {i === 0
                                                    ? i + 1
                                                    : interval === 'B'
                                                    ? 2 * i + 1
                                                    : i + 1}
                                            </td>
                                            <td>
                                                {event.subjects.length}{' '}
                                                {pluralize(
                                                    'video',
                                                    event.subjects.length
                                                )}
                                            </td>
                                            <td className="last-column">
                                                {event.subjects
                                                    .map((s) => s.title)
                                                    .join(', ')}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}

                        <ErrorMessage error={this.props.sendError} />
                        <br />
                        <div className="form-holder">
                            <p>Recipients email address</p>
                            <input
                                type="email"
                                name="campaignName"
                                required
                                className={'bigInput'}
                                value={this.state.targetEmail}
                                onChange={this.onChangeEmail}
                            />
                            <p className="mt10">Use commas to add recipients</p>
                        </div>
                    </div>
                    <div className={'modalFooter'}>
                        <ThemedButton
                            primary
                            onClick={this.onPreviewSend}
                            disabled={
                                !(
                                    this.state.targetEmail !== '' &&
                                    (this.state.previewType === 1 ||
                                        this.state.previewType === 3 ||
                                        this.state.selectedEvents.length > 0)
                                )
                            }
                        >
                            Send
                        </ThemedButton>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default withRouter(PreviewEmail);
