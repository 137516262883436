import React from 'react';
import { ThemedRadiobutton } from './themed/ThemedComponents';
import { observer } from 'mobx-react';

export default observer(({ id, label, value, groupValue, onClick }) => (
    <label
        htmlFor={id}
        className={
            groupValue === value
                ? 'container-radiobutton checked'
                : 'container-radiobutton'
        }
        onClick={(e) => {
            onClick(value);
        }}
    >
        {label}
        <input
            type="radio"
            name="active"
            id={id}
            className="styled-radiobutton"
            value={value}
            checked={groupValue === 3}
            onChange={(e) => {
                onClick(value);
            }}
        />
        <ThemedRadiobutton {...{ checked: groupValue === value }} />
    </label>
));
