import React, { Fragment } from 'react';

export default ({
    price,
    futureCredit,
    nextPlanPrice,
    nextBilling,
    my,
    planPeriod,
}) => (
    <Fragment>
        {my ? (
            <div>
                Current payment:&nbsp;
                <span className="currency">$</span>
                <span className="price">{price}</span>
                {futureCredit ? (
                    <Fragment>
                        &nbsp;&nbsp;
                        <span className="price-description ">
                            Credited amount:&nbsp;
                            <span className="currency">$</span>
                            <span className="price">
                                {(futureCredit / 100).toFixed(0)}
                            </span>
                            .
                        </span>
                    </Fragment>
                ) : (
                    '.'
                )}
                &nbsp;&nbsp;
                {nextBilling && (
                    <Fragment>
                        <span className="price-description ">
                            Next billing at: {nextBilling} &nbsp;&nbsp;
                            <span className="currency">$</span>
                            <span className="price">{nextPlanPrice}</span>
                        </span>
                    </Fragment>
                )}
            </div>
        ) : (
            <div>
                <span className="currency">$</span>
                <span className="price">{price}</span>
                <span className="period">/{planPeriod}</span>
            </div>
        )}
    </Fragment>
);
