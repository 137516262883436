import React from 'react';
import PreviewQuestion from '../../components/manager/assessment/preview-question';
import Modal from 'react-modal';
import { observer } from 'mobx-react';

function TestPreview() {
    return (
        <Modal
            isOpen={true}
            onRequestClose={() => {}}
            className="Modal subjects"
        >
            <PreviewQuestion
                questionId={7}
                onRequestClose={() => {}}
                type={'questionPreview'}
                companyId={2}
                showPreviewQuestionsModal={true}
                questionsSelected={[7, 8]}
            />
        </Modal>
    );
}

export default observer(TestPreview);
