import React from 'react';
import Autosuggest from 'react-autosuggest';
import services from '../../services';
import { inject, observer } from 'mobx-react';

function getSuggestionValue(suggestion) {
    return suggestion.name;
}

function renderSuggestion(suggestion) {
    return (
        <span>
            {' '}
            <span className="acl-target-prefix">
                {suggestion.type === 'company' && 'company: '}
                {suggestion.type === 'partner' && 'partner: '}
            </span>
            [{suggestion.id}] {suggestion.name}
        </span>
    );
}

@inject('superStore')
@observer
class PrincipalChooser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            suggestions: [],
            isLoading: false,
        };
    }

    loadSuggestions = async (value) => {
        let values = [];
        if (value.length > 2) values = await services.ACL.findPrincipal(value);

        this.setState({
            suggestions: values,
        });
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.loadSuggestions(value);
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    render() {
        const { suggestions } = this.state;
        const inputProps = {
            placeholder: "Type 'c'",
            value: this.props.value || '',
            onChange: this.props.onChange,
        };
        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                onSuggestionSelected={this.props.onSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
            />
        );
    }
}

export default PrincipalChooser;
