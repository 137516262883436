import styled, { css } from 'styled-components';
import { withRouter } from 'react-router';
import React from 'react';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import { FlexDiv } from '../design-system/containers';
import { TextDefault } from '../design-system/texts';
import LogoutIcon from '@material-ui/icons/ExitToApp';

const CheckIcon = styled(Check)`
    opacity: 0;
    li:hover && {
        opacity: 0.4;
    }
    ${props =>
        props.selected &&
        css`
            opacity: 1;
        `}
`;

const ThemedDropdownNavItem = ({
    text,
    selected,
    isSettings,
    staticContext,
    isSignOut,
    ...props
}) => (
    <FlexDiv py={2} {...props} alignCenter>
        <FlexDiv width={24}>
            {isSettings ? (
                <SettingsIcon
                    color={selected ? 'primary' : undefined}
                    fontSize="small"
                />
            ) : isSignOut ? (
                <TextDefault error>
                    <LogoutIcon fontSize="small" />
                </TextDefault>
            ) :
            (
                <CheckIcon color="primary" selected={selected} />
            )}
        </FlexDiv>
        <TextDefault bold ml={8}>
            {text}
        </TextDefault>
    </FlexDiv>
);

export default withRouter(ThemedDropdownNavItem);
