import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CompanyAppearance from './CompanyAppearance';
import SettingsContainer from './Settings';

export default ({ companyId }) => {
    return (
        <Switch>
            <Route
                path={`/settings/company/appearance`}
                exact
                render={(props) => <CompanyAppearance props={props} />}
            />

            <Route
                path={`/settings`}
                render={(props) => (
                    <SettingsContainer props={props} companyId={companyId} />
                )}
            />
        </Switch>
    );
};
