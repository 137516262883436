import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { enforceSlash } from '../../utils/helpers';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import { Table, Tr, Td, Th, Thead } from 'reactable';
import ACLEditor from './ACLEditor';

@inject('superStore', 'commonStore')
@observer
class LanguageViewSuperContainer extends React.Component {
    state = {
        error: '',
        loadedOrError: null,
        activeResourcesTab: 1,
        activeSubjectsTab: 1,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState) return; // not yet initialized
        if (nextProps.languageId !== prevState.languageId) {
            if (prevState.languageId)
                return {
                    languageChanged: true,
                    loadedOrError: null,
                };
            else {
                return {
                    languageId: nextProps.languageId,
                    loadedOrError: null,
                };
            }
        }

        // No state update necessary
        return null;
    }

    componentDidMount() {
        // It's preferable in most cases to wait until after mounting to load data.
        // See below for a bit more context...
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loadedOrError === null) {
            // At this point, we're in the "commit" phase, so it's safe to load the new data.
            this.loadData();
        }
    }

    toggleResourcesTab(value) {
        this.setState({ activeResourcesTab: value });
    }

    toggleSubjectsTab(value) {
        this.setState({ activeSubjectsTab: value });
    }

    async loadData() {
        if (this.state.languageId) {
            await this.props.superStore.loadLanguage(this.state.languageId);
        }
    }

    unpublish = async () => {
        const { editingLanguage } = this.props.superStore;

        this.props.commonStore.showConfirm(
            'Un-Publish Language. It  will disappear for  all users',
            'Un-Publish',
            'Un-Publish Language',
            async () => {
                await this.props.superStore.updateLanguage(editingLanguage.id, {
                    published: 0,
                });
            },
            true,
            true,
            'Confirm this unusual action',
        );
    };

    publish = async () => {
        const {
            editingLanguage,
            emptyLanguageResources,
            emptyLanguageSubjects,
        } = this.props.superStore;

        let confirm = null;
        if (
            editingLanguage.englishResourcesCount !==
                editingLanguage.resourcesCount ||
            emptyLanguageResources.length > 0 ||
            emptyLanguageSubjects.length > 0
        )
            confirm =
                'This Language have configuration errors. check this to confirm publish anyway';

        this.props.commonStore.showConfirm(
            'Publish Language ' + editingLanguage.name,
            'Publish',
            'Publish Language',
            async () => {
                await this.props.superStore.updateLanguage(editingLanguage.id, {
                    published: 1,
                });
            },
            false,
            true,
            confirm, 
        );
    };

    async preview(subject) {}

    render() {
        const {
            loadingCompany,
            editingLanguage,
            languageResources,
            emptyLanguageResources,
            languageSubjects,
            emptyLanguageSubjects,
            error,
        } = this.props.superStore;
        const { match } = this.props;
        return loadingCompany ? (
            <ThemedSpinner />
        ) : (
            <Fragment>
                <div className="block">
                    <ErrorMessage error={error} />
                    <Link to={`/super/languages`}>
                        <div className={'navigation back-button'}>
                            Back to Languages
                        </div>
                    </Link>
                    <Row>
                        <Col md="9" xs="9" className="leftMenu">
                            <h2>Languages</h2>
                        </Col>
                        <Col md="3" xs="3">
                            <div className="pull-right">
                                <Link to={`${enforceSlash(match.url)}edit`}>
                                    <div className="btn btn-primary ">Edit</div>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    {editingLanguage && (
                        <Fragment>
                            <Row>
                                <Col md="12" xs="12" className="leftMenu">
                                    <h3>Access control</h3>
                                    <div className="padded-container">
                                        <ACLEditor
                                            acl={editingLanguage.acl}
                                            unique_id={
                                                editingLanguage.unique_id
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="9" xs="9" className="leftMenu">
                                    {!!editingLanguage.published && (
                                        <h3>Published</h3>
                                    )}
                                    {!editingLanguage.published && (
                                        <h3>Not-Published</h3>
                                    )}
                                </Col>
                                <Col md="3" xs="3">
                                    <div className="pull-right">
                                        {!!editingLanguage.published && (
                                            <ThemedButton
                                                onClick={this.unpublish}
                                                className="btn btn-primary "
                                            >
                                                UnPublish
                                            </ThemedButton>
                                        )}
                                        {!editingLanguage.published && (
                                            <ThemedButton
                                                onClick={this.publish}
                                                className="btn btn-primary "
                                            >
                                                Publish
                                            </ThemedButton>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12" xs="12">
                                    <h3>Resources</h3>
                                    {editingLanguage.englishResourcesCount !==
                                        editingLanguage.resourcesCount && (
                                        <Fragment>
                                            <div className="alert alert-warning">
                                                {editingLanguage.englishResourcesCount -
                                                    editingLanguage.resourcesCount}{' '}
                                                resources missed
                                            </div>
                                        </Fragment>
                                    )}
                                    {emptyLanguageResources.length > 0 && (
                                        <Fragment>
                                            <div className="alert alert-warning">
                                                {emptyLanguageResources.length}{' '}
                                                resources with empty values
                                            </div>
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({
                                                            active:
                                                                this.state
                                                                    .activeResourcesTab ===
                                                                1,
                                                        })}
                                                        onClick={() => {
                                                            this.toggleResourcesTab(
                                                                1
                                                            );
                                                        }}
                                                    >
                                                        Missed
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({
                                                            active:
                                                                this.state
                                                                    .activeResourcesTab ===
                                                                2,
                                                        })}
                                                        onClick={() => {
                                                            this.toggleResourcesTab(
                                                                2
                                                            );
                                                        }}
                                                    >
                                                        All
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>{' '}
                                        </Fragment>
                                    )}

                                    <Table
                                        className="table slim"
                                        id="resourcesTable"
                                        itemsPerPage={20}
                                        pageButtonLimit={5}
                                    >
                                        <Thead>
                                            <Th column="Key" className="">
                                                Key
                                            </Th>
                                            <Th column="Value" className="">
                                                Value
                                            </Th>
                                        </Thead>
                                        {(emptyLanguageResources.length > 0 &&
                                        this.state.activeResourcesTab === 1
                                            ? emptyLanguageResources
                                            : languageResources
                                        ).map((resource, i) => (
                                            <Tr key={i}>
                                                <Td
                                                    column={'Key'}
                                                    value={resource.key}
                                                    className={
                                                        resource.value
                                                            ? ''
                                                            : 'alert alert-warning'
                                                    }
                                                >
                                                    {resource.key}
                                                </Td>
                                                <Td
                                                    column={'Value'}
                                                    value={resource.value}
                                                >
                                                    {resource.value}
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Table>
                                </Col>
                            </Row>

                            <Row>
                                {emptyLanguageSubjects.length > 0 && (
                                    <Fragment>
                                        <Col md="12" xs="12">
                                            <h3>Subjects</h3>
                                            <div className="alert alert-warning">
                                                {emptyLanguageSubjects.length}{' '}
                                                Subjects without translation
                                            </div>
                                            <Nav tabs>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({
                                                            active:
                                                                this.state
                                                                    .activeSubjectsTab ===
                                                                1,
                                                        })}
                                                        onClick={() => {
                                                            this.toggleResourcesTab(
                                                                1
                                                            );
                                                        }}
                                                    >
                                                        Missed
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({
                                                            active:
                                                                this.state
                                                                    .activeSubjectsTab ===
                                                                2,
                                                        })}
                                                        onClick={() => {
                                                            this.toggleSubjectsTab(
                                                                2
                                                            );
                                                        }}
                                                    >
                                                        All
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </Col>
                                    </Fragment>
                                )}

                                <Table
                                    className="table slim"
                                    id="subjectsTable"
                                    itemsPerPage={20}
                                    pageButtonLimit={5}
                                >
                                    <Thead>
                                        <Th column="title" className="">
                                            title
                                        </Th>
                                        <Th column="description" className="">
                                            description
                                        </Th>
                                        <Th column="video_id_jw" className="">
                                            video_id_jw
                                        </Th>
                                        <Th column="enTitle" className="">
                                            en title
                                        </Th>
                                        <Th column="enDescription" className="">
                                            en description
                                        </Th>
                                    </Thead>
                                    {(emptyLanguageSubjects.length > 0 &&
                                    this.state.activeSubjectsTab === 1
                                        ? emptyLanguageSubjects
                                        : languageSubjects
                                    ).map((subject, i) => (
                                        <Tr key={i}>
                                            <Td
                                                column={'title'}
                                                value={subject.title}
                                                className={
                                                    subject.title
                                                        ? ''
                                                        : 'alert alert-warning'
                                                }
                                            >
                                                {subject.title}
                                            </Td>
                                            <Td
                                                column={'description'}
                                                value={subject.description}
                                            >
                                                <div
                                                    style={{
                                                        maxWidth: '200px',
                                                        whiteSpace: 'normal',
                                                    }}
                                                >
                                                    {subject.description}
                                                </div>
                                            </Td>
                                            <Td
                                                column={'video_id_jw'}
                                                value={subject.video_id_jw}
                                            >
                                                <ThemedButton
                                                    onClick={(e) => {
                                                        this.preview(subject);
                                                    }}
                                                >
                                                    {subject.video_id_jw}
                                                </ThemedButton>
                                            </Td>
                                            <Td
                                                column={'enTitle'}
                                                value={subject.enTitle}
                                            >
                                                {subject.enTitle}
                                            </Td>
                                            <Td
                                                column={'enDescription'}
                                                value={subject.enDescription}
                                            >
                                                <div
                                                    style={{
                                                        maxWidth: '200px',
                                                        whiteSpace: 'normal',
                                                    }}
                                                >
                                                    {subject.enDescription}
                                                </div>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Table>
                            </Row>
                        </Fragment>
                    )}
                </div>
            </Fragment>
        );
    }
}

export default withRouter(LanguageViewSuperContainer);
