import React, { useMemo } from 'react';
import SimpleTable from '../../SimpleTable';
import { Tooltip } from '@material-ui/core';
import { defaultZero, calculateColor } from '../../../utils/helpers';
import Summary from './summary';
import { Heading2, Heading3 } from '../../design-system/headings';
import { FlexDiv } from '../../design-system/containers';
import StatusBadge from '../../StatusBadge';
import { TextDefault } from '../../design-system/texts';
import { ThemedButton } from '../../themed/ThemedComponents';
import SemiCircleProgress from '../assessment/semi-circle-progress';
import { Block } from '../../design-system/containers';
import { FullWidthLink } from '../../design-system/miscellaneous';
import { Link } from 'react-router-dom';
import NoItemsYet from '../no-items-yet';
import AssessmentUpgrade from '../assessment/AssessmentUpgrade';

function AssessmentDashboard({
    dashboardData,
    assessments,
    assessmentsUnavailable,
    upgradeAllowed,
    supportEmail,
}) {
    const columns = useMemo(
        () => [
            {
                Header: 'Assessment',
                accessor: 'name',
                Cell: (x) => (
                    <Link to={'/assessment/' + x.row.original.id + '/view'}>
                        <div>
                            {(x.value || '').length > 35 ? (
                                <Tooltip title={x.value || ''}>
                                    <span>
                                        <TextDefault bold>
                                            {(x.value || '').substring(0, 35)}
                                            ...
                                        </TextDefault>
                                    </span>
                                </Tooltip>
                            ) : (
                                <TextDefault bold>{x.value}</TextDefault>
                            )}
                        </div>
                        <StatusBadge
                            color={x.row.original.statusInfo.color}
                            text={x.row.original.statusInfo.label}
                        />
                    </Link>
                ),
            },
            {
                Header: '# Employees',
                accessor: '_rawData.totalEmployees',
                Cell: (x) => {
                    return <TextDefault>{x.value}</TextDefault>;
                },
            },
            {
                Header: 'Completion',
                accessor: 'attendance',
                Cell: (x) => {
                    return (
                        <TextDefault bold color={calculateColor(x.value)}>
                            {defaultZero(x.value)}%
                        </TextDefault>
                    );
                },
            },
            {
                Header: 'Resilience score',
                accessor: 'resilienceScore',
                Cell: (x) => (
                    <SemiCircleProgress
                        percentage={x.value}
                        size="small"
                        smallFont={true}
                        inTable={true}
                    />
                ),
                tooltip:
                    'Average employee score across all assessed threat areas.',
            },
        ],
        []
    );
    if (assessmentsUnavailable) {
        return (
            <AssessmentUpgrade
                upgradeAllowed={upgradeAllowed}
                supportEmail={supportEmail}
            />
        );
    }

    return assessments.length > 0 ? (
        <Block>
            <Heading2 mb={16}>Assessments</Heading2>
            <FlexDiv justifyCenter alignCenter noWrap>
                <Summary
                    mainNumberText={'Active'}
                    mainNumber={dashboardData.active.value}
                    secondaryNumberText={'Currently assessing'}
                    secondaryNumber={dashboardData.active.users}
                    iconUrl={'/static/img/icon-dashboard-repeat.svg'}
                    iconAlt={'arrow in circle'}
                    item="assessment"
                />
                <Summary
                    mainNumberText={'Completed'}
                    mainNumber={dashboardData.completed.value}
                    secondaryNumberText={'Completed assessment'}
                    secondaryNumber={dashboardData.completed.users}
                    iconUrl={'/static/img/icon-dashboard-completed.svg'}
                    iconAlt={'circle with tick inside'}
                    item="assessment"
                />
            </FlexDiv>
            <Heading3 my={24}>Assessment performance</Heading3>
            <SimpleTable
                columns={columns}
                data={assessments}
                hidePagination
                useLink
                disableSort
            />
            <FullWidthLink to={`/assessment`}>
                <ThemedButton secondary fullWidth>
                    All assessments
                </ThemedButton>
            </FullWidthLink>
        </Block>
    ) : (
        <NoItemsYet
            img={'/static/img/assessment-empty.svg'}
            imgAlt={'sneak peak into assessment'}
            title={'No assessments yet'}
            text={
                'Create a new assessment to measure and track employees’ cybersecurity knowledge and behavior.'
            }
            heading={'Assessments'}
            buttons={
                <FullWidthLink to={`/assessment/create`}>
                    <ThemedButton primary fullWidth>
                        Create assessment
                    </ThemedButton>
                </FullWidthLink>
            }
        />
    );
}

export default AssessmentDashboard;
