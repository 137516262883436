import React from 'react';
import Upgrade from '../dashboard/upgrade';
import NoItemsYet from '../no-items-yet';
import { ThemedButton } from '../../themed/ThemedComponents';

function AssessmentUpgrade({ upgradeAllowed, supportEmail }) {
    return upgradeAllowed ? (
        <Upgrade
            heading={'Human Risk Assessment'}
            text={
                'Measure and track employees’ cybersecurity knowledge and behavior'
            }
            img={'/static/img/assessment-upgrade.svg'}
            imgAlt={'sneak peak into assessment'}
            upgradeLink={'/subscriptions/upgrade'}
            learnMoreLink={'https://awarego.com/human-risk-assessment/'}
        />
    ) : (
        <NoItemsYet
            img={'/static/img/assessment-locked.svg'}
            imgAlt={'assessments are unavailable'}
            title={'Assessments are unavailable in your current plan'}
            text={'Please upgrade your subscription to access the assessments.'}
            heading={'Assessments'}
            buttons={
                <a href={`mailto:${supportEmail}`}>
                    <ThemedButton primary fullWidth>
                        Contact to upgrade
                    </ThemedButton>
                </a>
            }
        />
    );
}

export default AssessmentUpgrade;
