import React from 'react';
import { withTheme } from 'styled-components';

const ThemedProgressPeople = ({ progress }) => {

	return (
		<>
			<svg width="100" height="35" viewBox="0 0 100 35" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M5.79157 6.97096C7.62832 6.97096 9.10103 5.41992 9.10103 3.48548C9.10103 1.55104 7.62832 0 5.79157 0C3.95481 0 2.4821 1.55104 2.4821 3.48548C2.4821 5.41992 3.95481 6.97096 5.79157 6.97096Z" fill="#EBEEF1"/>
				<path d="M25.3707 24.3994V34.8558H20.4065V24.3994H15.4423L19.6453 11.1022C20.0921 9.6732 21.3662 8.71469 22.7893 8.71469H22.9879C24.411 8.71469 25.6686 9.6732 26.1319 11.1022L30.3349 24.3994H25.3707Z" fill="#EBEEF1"/>
				<path d="M2.4821 21.7856V34.8561H9.10103V21.7856H11.5831V12.2005C11.5831 10.2835 10.0939 8.71503 8.27366 8.71503H3.30947C1.48926 8.71503 0 10.2835 0 12.2005V21.7856H2.4821Z" fill="#EBEEF1"/>
				<path d="M26.1981 3.48548C26.1981 5.41992 24.7254 6.97096 22.8886 6.97096C21.0519 6.97096 19.5792 5.41992 19.5792 3.48548C19.5792 1.55104 21.0519 0 22.8886 0C24.7254 0 26.1981 1.55104 26.1981 3.48548Z" fill="#EBEEF1"/>
				<path d="M40.4149 6.97096C42.2517 6.97096 43.7244 5.41992 43.7244 3.48548C43.7244 1.55104 42.2517 0 40.4149 0C38.5782 0 37.1055 1.55104 37.1055 3.48548C37.1055 5.41992 38.5782 6.97096 40.4149 6.97096Z" fill="#EBEEF1"/>
				<path d="M59.9941 24.3994V34.8558H55.0299V24.3994H50.0657L54.2687 11.1022C54.7155 9.6732 55.9896 8.71469 57.4127 8.71469H57.6113C59.0343 8.71469 60.2919 9.6732 60.7553 11.1022L64.9583 24.3994H59.9941Z" fill="#EBEEF1"/>
				<path d="M37.1055 21.7856V34.8561H43.7244V21.7856H46.2065V12.2005C46.2065 10.2835 44.7172 8.71503 42.897 8.71503H37.9328C36.1126 8.71503 34.6234 10.2835 34.6234 12.2005V21.7856H37.1055Z" fill="#EBEEF1"/>
				<path d="M60.8215 3.48548C60.8215 5.41992 59.3487 6.97096 57.512 6.97096C55.6752 6.97096 54.2025 5.41992 54.2025 3.48548C54.2025 1.55104 55.6752 0 57.512 0C59.3487 0 60.8215 1.55104 60.8215 3.48548Z" fill="#EBEEF1"/>
				<path d="M78.3478 3.48548C78.3478 5.41992 76.8751 6.97096 75.0383 6.97096C73.2016 6.97096 71.7288 5.41992 71.7288 3.48548C71.7288 1.55104 73.2016 0 75.0383 0C76.8751 0 78.3478 1.55104 78.3478 3.48548Z" fill="#EBEEF1"/>
				<path d="M94.6175 34.8558V24.3994H99.5816L95.3786 11.1022C94.9153 9.6732 93.6577 8.71469 92.2346 8.71469H92.0361C90.613 8.71469 89.3389 9.6732 88.8921 11.1022L84.6891 24.3994H89.6533V34.8558H94.6175Z" fill="#EBEEF1"/>
				<path d="M71.7288 34.8561V21.7856H69.2468V12.2005C69.2468 10.2835 70.736 8.71503 72.5562 8.71503H77.5204C79.3406 8.71503 80.8299 10.2835 80.8299 12.2005V21.7856H78.3478V34.8561H71.7288Z" fill="#EBEEF1"/>
				<path d="M92.1354 6.97096C93.9721 6.97096 95.4448 5.41992 95.4448 3.48548C95.4448 1.55104 93.9721 0 92.1354 0C90.2986 0 88.8259 1.55104 88.8259 3.48548C88.8259 5.41992 90.2986 6.97096 92.1354 6.97096Z" fill="#EBEEF1"/>
				<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="100" height="35">
				<path d="M5.8159 6.97959C7.66037 6.97959 9.13927 5.42663 9.13927 3.48979C9.13927 1.55296 7.66037 0 5.8159 0C3.97143 0 2.49253 1.55296 2.49253 3.48979C2.49253 5.42663 3.97143 6.97959 5.8159 6.97959Z" fill="#EBEEF1"/>
				<path d="M25.4773 24.4295V34.8989H20.4922V24.4295H15.5072L19.7279 11.116C20.1765 9.68517 21.456 8.72547 22.8851 8.72547H23.0845C24.5135 8.72547 25.7764 9.68517 26.2417 11.116L30.4623 24.4295H25.4773Z" fill="#EBEEF1"/>
				<path d="M2.49253 21.8125V34.8993H9.13926V21.8125H11.6318V12.2156C11.6318 10.2962 10.1363 8.72582 8.30842 8.72582H3.32337C1.49552 8.72582 0 10.2962 0 12.2156V21.8125H2.49253Z" fill="#EBEEF1"/>
				<path d="M26.3081 3.48979C26.3081 5.42663 24.8292 6.97959 22.9848 6.97959C21.1403 6.97959 19.6614 5.42663 19.6614 3.48979C19.6614 1.55296 21.1403 0 22.9848 0C24.8292 0 26.3081 1.55296 26.3081 3.48979Z" fill="#EBEEF1"/>
				<path d="M40.5847 6.97959C42.4292 6.97959 43.9081 5.42663 43.9081 3.48979C43.9081 1.55296 42.4292 0 40.5847 0C38.7403 0 37.2614 1.55296 37.2614 3.48979C37.2614 5.42663 38.7403 6.97959 40.5847 6.97959Z" fill="#EBEEF1"/>
				<path d="M60.2461 24.4295V34.8989H55.2611V24.4295H50.276L54.4967 11.116C54.9453 9.68517 56.2248 8.72547 57.6539 8.72547H57.8533C59.2823 8.72547 60.5452 9.68517 61.0105 11.116L65.2312 24.4295H60.2461Z" fill="#EBEEF1"/>
				<path d="M37.2614 21.8125V34.8993H43.9081V21.8125H46.4006V12.2156C46.4006 10.2962 44.9051 8.72582 43.0773 8.72582H38.0922C36.2643 8.72582 34.7688 10.2962 34.7688 12.2156V21.8125H37.2614Z" fill="#EBEEF1"/>
				<path d="M61.077 3.48979C61.077 5.42663 59.5981 6.97959 57.7536 6.97959C55.9091 6.97959 54.4302 5.42663 54.4302 3.48979C54.4302 1.55296 55.9091 0 57.7536 0C59.5981 0 61.077 1.55296 61.077 3.48979Z" fill="#EBEEF1"/>
				<path d="M78.6769 3.48979C78.6769 5.42663 77.198 6.97959 75.3536 6.97959C73.5091 6.97959 72.0302 5.42663 72.0302 3.48979C72.0302 1.55296 73.5091 0 75.3536 0C77.198 0 78.6769 1.55296 78.6769 3.48979Z" fill="#EBEEF1"/>
				<path d="M95.0149 34.8989V24.4295H100L95.7793 11.116C95.314 9.68517 94.0512 8.72547 92.6221 8.72547H92.4227C90.9937 8.72547 89.7142 9.68517 89.2655 11.116L85.0448 24.4295H90.0299V34.8989H95.0149Z" fill="#EBEEF1"/>
				<path d="M72.0302 34.8993V21.8125H69.5377V12.2156C69.5377 10.2962 71.0332 8.72582 72.861 8.72582H77.8461C79.6739 8.72582 81.1695 10.2962 81.1695 12.2156V21.8125H78.6769V34.8993H72.0302Z" fill="#EBEEF1"/>
				<path d="M92.5224 6.97959C94.3669 6.97959 95.8458 5.42663 95.8458 3.48979C95.8458 1.55296 94.3669 0 92.5224 0C90.678 0 89.1991 1.55296 89.1991 3.48979C89.1991 5.42663 90.678 6.97959 92.5224 6.97959Z" fill="#EBEEF1"/>
				</mask>
				<g mask="url(#mask0)">
					<rect x="0" y="-5.81055" width={progress} height="45.7166" fill="#6FCF97"/>
				</g>
			</svg>
		</>
	);
};

export default withTheme(ThemedProgressPeople);

