import React, { createRef, useEffect, useMemo } from 'react';
import styles from './calendar.module.scss';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import StatusBadge from '../../StatusBadge';
import { capitalize } from '@material-ui/core';
import moment from 'moment-timezone';
import { Tooltip } from '@material-ui/core';
import { Fragment } from 'react';
import ThemedSpinner from '../../themed/ThemedSpinner';
import { useHistory } from 'react-router';
import groupBy from 'lodash/groupBy';
import classnames from 'classnames';
import { Block } from '../../design-system/containers';
import { colors } from '../../design-system/colors';

const useStyles = makeStyles((theme) => ({
    dayWithDotContainer: {
        position: 'relative',
    },
    dayWithDot: {
        position: 'absolute',
        height: 0,
        width: 0,
        border: '2px solid',
        borderRadius: 4,
        borderColor: colors.heavyMetal,
        right: '47%',
        transform: 'translateX(1px)',
        top: '73%',
    },
    dayWithSelectedDot: {
        borderColor: 'white',
    },
    dayWithTodayDot: {
        borderColor: theme.palette.primary.main,
    },
    dayWithPastDot: {
        opacity: 0.5,
    },
}));

// making sure week on calendar starts on Monday
moment.updateLocale('en', {
    week: {
        dow: 1,
    },
});

function DashboardCalendar({
    displayedEvents,
    onDayChange,
    setSelectedMonth,
    selectedDate,
    daysWithDot,
    isLoading,
}) {
    const classes = useStyles();
    const history = useHistory();

    let refs = useMemo(
        () =>
            displayedEvents.reduce((acc, value) => {
                acc[value.id] = createRef();
                return acc;
            }, {}),
        [displayedEvents]
    );

    let groupedEventsByDay = useMemo(
        () =>
            groupBy(displayedEvents, (e) =>
                moment(e.date).format('YYYY-MM-DD')
            ),
        [displayedEvents]
    );

    useEffect(() => {
        !Object.prototype.hasOwnProperty.call(
            refs,
            moment(selectedDate).format('YYYY-MM-DD')
        ) && (refs[moment(selectedDate).format('YYYY-MM-DD')] = createRef());

        let current =
            refs[`${moment(selectedDate).format('YYYY-MM-DD')}`].current;
        if (current)
            current.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
            });
    }, [refs, selectedDate]);

    return (
        <Block paddingTopOnly alignCenter>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <div className={styles['dashboard-calendar']}>
                    <DatePicker
                        variant="static"
                        openTo="date"
                        value={selectedDate}
                        onChange={(date) => {
                            onDayChange(date);
                        }}
                        onMonthChange={(date) => {
                            setSelectedMonth(moment(date).format('YYYY-MM'));
                            onDayChange(date);
                        }}
                        disableToolbar
                        disablePast
                        loading={isLoading}
                        loadingIndicator={<ThemedSpinner />}
                        allowKeyboardControl
                        renderDay={(
                            date,
                            selectedDate,
                            dayInCurrentMonth,
                            dayComponent
                        ) => {
                            if (dayInCurrentMonth) {
                                if (
                                    daysWithDot.includes(
                                        date.format('YYYY-MM-DD')
                                    )
                                ) {
                                    return (
                                        <div
                                            className={
                                                classes.dayWithDotContainer
                                            }
                                        >
                                            {dayComponent}
                                            <div
                                                className={classnames(
                                                    classes.dayWithDot,
                                                    {
                                                        [classes.dayWithPastDot]:
                                                            moment(
                                                                date
                                                            ).isBefore(
                                                                moment(),
                                                                'day'
                                                            ),
                                                        [classes.dayWithSelectedDot]:
                                                            moment(date).isSame(
                                                                moment(
                                                                    selectedDate
                                                                ),
                                                                'day'
                                                            ),
                                                        [classes.dayWithTodayDot]:
                                                            !moment(
                                                                date
                                                            ).isSame(
                                                                moment(
                                                                    selectedDate
                                                                ),
                                                                'day'
                                                            ) &&
                                                            moment(date).isSame(
                                                                moment(),
                                                                'day'
                                                            ),
                                                    }
                                                )}
                                            />
                                        </div>
                                    );
                                }
                            }
                            return dayComponent;
                        }}
                    />
                </div>
            </MuiPickersUtilsProvider>
            {daysWithDot.length > 0 && (
                <h2 className={styles['dashboard-calendar-heading']}>
                    Schedule
                </h2>
            )}
            {isLoading ? (
                <ThemedSpinner />
            ) : daysWithDot.length > 0 ? (
                <Fragment>
                    <div className={styles['dashboard-schedule']}>
                        {Object.entries(groupedEventsByDay).map(
                            ([key, value]) => (
                                <div
                                    key={key}
                                    ref={refs[key]}
                                    className={
                                        styles['dashboard-schedule-group']
                                    }
                                >
                                    {value.map((e, j) => (
                                        <div
                                            className={classnames(
                                                styles['event'],
                                                {
                                                    [styles['event-disabled']]:
                                                        moment(key).isBefore(
                                                            moment(),
                                                            'day'
                                                        ),
                                                }
                                            )}
                                            key={j}
                                        >
                                            {j !== 0 ? (
                                                <div
                                                    className={
                                                        styles['event-empty']
                                                    }
                                                ></div>
                                            ) : (
                                                <div
                                                    className={classnames(
                                                        styles['event-date'],
                                                        {
                                                            [styles[
                                                                'event-date-today'
                                                            ]]: moment(
                                                                e.date
                                                            ).isSame(
                                                                moment(),
                                                                'day'
                                                            ),
                                                            [styles[
                                                                'event-date-selected'
                                                            ]]: moment(
                                                                e.date
                                                            ).isSame(
                                                                moment(
                                                                    selectedDate
                                                                ),
                                                                'day'
                                                            ),
                                                        }
                                                    )}
                                                >
                                                    <span
                                                        className={
                                                            styles[
                                                                'event-date-day-of-week'
                                                            ]
                                                        }
                                                    >
                                                        {moment(e.date)
                                                            .format('ddd')
                                                            .toUpperCase()}
                                                    </span>
                                                    <span>
                                                        {moment(e.date).format(
                                                            'D'
                                                        )}
                                                    </span>
                                                </div>
                                            )}
                                            <div
                                                className={
                                                    styles['event-wrapper']
                                                }
                                            >
                                                {e.name !==
                                                'No events scheduled' ? (
                                                    <div
                                                        className={
                                                            styles[
                                                                'event-details'
                                                            ]
                                                        }
                                                    >
                                                        <h3
                                                            onClick={() => {
                                                                history.push(
                                                                    `/${
                                                                        e.type ===
                                                                        'training'
                                                                            ? 'trainings'
                                                                            : 'assessment'
                                                                    }/${
                                                                        e.eventId
                                                                    }/view`
                                                                );
                                                            }}
                                                        >
                                                            {e.name.length >
                                                            28 ? (
                                                                <Tooltip
                                                                    title={
                                                                        e.name
                                                                    }
                                                                >
                                                                    <span>
                                                                        {e.name.substring(
                                                                            0,
                                                                            28
                                                                        )}
                                                                        ...
                                                                    </span>
                                                                </Tooltip>
                                                            ) : (
                                                                e.name
                                                            )}
                                                        </h3>
                                                        <StatusBadge
                                                            color={'tag'}
                                                            text={capitalize(
                                                                e.type
                                                            )}
                                                        />
                                                        {moment(e.date).format(
                                                            'h:mma'
                                                        )}{' '}
                                                        {/*moment
                                                            .tz(
                                                                moment.tz.guess()
                                                            )
                                                            .format(' z')*/}
                                                        ({e.status})
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={
                                                            styles[
                                                                'event-unscheduled'
                                                            ]
                                                        }
                                                    >
                                                        <h3>{e.name}</h3>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )
                        )}
                    </div>
                </Fragment>
            ) : (
                <div className={styles['dashboard-calendar-empty']}>
                    You have nothing scheduled this month
                </div>
            )}
        </Block>
    );
}

export default DashboardCalendar;
