import React, { Fragment, useState } from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'reactstrap';
import ListsOverviewWithCheck from './select-users-table';
import { ThemedButton } from '../../themed/ThemedComponents';
import pluralize from 'pluralize';
import { Typography, TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import './select-users-step.scss';
import ModalListEditor from '../../../containers/users/ModalListEditor';
import Modal from 'react-modal';
import isEqual from 'lodash/isEqual';
import EmployeesLimitReachedAction from '../employees-limit-reached-action';

function SelectUsers({
    hideAddEmployeeModal,
    lists,
    currentCompany,
    item,
    itemString,
    recipientsModal,
    error,
    hideRecipientsModal,
    createRecipientsList,
    disabled,
    showRecipientsModal,
    loadingUsers,
    requestUsersForList,
    selectedLists,
    selectedUserCount,
    onUserListSelected,
    ongoing,
}) {
    // const [activeTab, setActiveTab] = useState("0")
    const [searchValue, setSearchValue] = useState('');

    // const tabsChange = (tab) => {
    // 	if (activeTab !== tab) {
    // 		setActiveTab(tab)
    // 	}
    // }

    const previousEmployees =
        ongoing && itemString === 'assessment'
            ? item._rawData && item._rawData.definition.userLists
            : undefined;

    return (
        <Fragment>
            <Modal
                isOpen={recipientsModal}
                onRequestClose={hideRecipientsModal}
                className="Modal schedule"
            >
                <ModalListEditor
                    result={item}
                    error={error}
                    onRequestClose={hideRecipientsModal}
                    createRecipientsList={createRecipientsList}
                />
            </Modal>
            <div className={'modalHeader'}>
                <h1>Select employees</h1>
                <img
                    className={'modal-close-right'}
                    src="/static/img/close.svg"
                    alt="Close"
                    onClick={hideAddEmployeeModal}
                />
            </div>
            <div className={'modalContent do-not-shrink'}>
                <div className="modalSearch">
                    <TextField
                        label="Search by list"
                        color="primary"
                        variant="outlined"
                        value={searchValue}
                        onChange={(e) => {
                            setSearchValue(e.target.value);
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <div className="action">
                        <span className="alignRight">
                            <ThemedButton
                                disabled={disabled}
                                onClick={showRecipientsModal}
                                secondary
                            >
                                Create new list
                            </ThemedButton>
                            <EmployeesLimitReachedAction
                                disabled={disabled}
                                currentCompany={currentCompany}
                            />
                        </span>
                    </div>
                </div>
                {/* {!searchValue.length &&
					<ThemedNav tabs training>
						<ThemedNavItem active={activeTab === '0'}>
							<ThemedNavLink onClick={() => { tabsChange('0'); }}>
								Lists ({lists.length})
							</ThemedNavLink>
						</ThemedNavItem>
						<ThemedNavItem active={activeTab === '1'}>
							<ThemedNavLink onClick={() => { tabsChange('1'); }}>
								Tags
							</ThemedNavLink>
						</ThemedNavItem>
					</ThemedNav>
					} */}
                {/* <TabContent activeTab={activeTab}>
						<TabPane tabId="0"> */}
                <div className="select-users">
                    <Row>
                        <Col md="12" xs="12" className="content">
                            <ListsOverviewWithCheck
                                lists={lists}
                                loadingUsers={loadingUsers}
                                requestUsersForList={requestUsersForList}
                                selectedLists={selectedLists}
                                selectedUserCount={selectedUserCount}
                                onUserListSelected={onUserListSelected}
                                searchValue={searchValue}
                                ongoing={ongoing}
                                assessment={item}
                                previousEmployees={previousEmployees}
                            />
                        </Col>
                    </Row>
                </div>
                {/* </TabPane>
					<TabPane tabId="1">
						This is just a placeholder for the tags :)
					</TabPane>
				</TabContent> */}
            </div>
            <div className={'modalFooter'}>
                <div className="d-flex flex-fill flex-column">
                    <Typography>
                        {selectedLists.length > 0
                            ? `${selectedLists.length} ${pluralize(
                                  'list',
                                  selectedLists.length
                              )} selected`
                            : ''}
                    </Typography>
                    <Typography
                        color={selectedUserCount > 0 ? 'primary' : undefined}
                    >
                        {selectedUserCount}{' '}
                        {pluralize('employee', selectedUserCount)} selected
                    </Typography>
                </div>
                <ThemedButton
                    primary
                    disabled={
                        ongoing &&
                        isEqual(
                            previousEmployees.sort(),
                            item.selectedUserListsIds.sort()
                        )
                    }
                    onClick={hideAddEmployeeModal}
                >
                    Confirm
                </ThemedButton>
            </div>
        </Fragment>
    );
}

export default observer(SelectUsers);
