import { rgba } from 'polished';

export const colors = {
    heavyMetal: '#343534',
    aspargus: '#20A26B',

    contentYellow: '#FFD860',

    error: '#EB5757',
    success: '#20A26B',
    delete: '#CC213D',
};

/** variations of the basic colors */
colors.errorLight = rgba(colors.error, 0.1);
colors.successLight = rgba(colors.success, 0.1);
colors.heavyMetalLight = rgba(colors.heavyMetal, 0.1);
