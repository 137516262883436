import React from 'react';
import { observer } from 'mobx-react';
import RichTextEditor from 'react-rte';
import { fixHtml } from '../../../utils/helpers';

@observer
class SubjectNoteEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value:
                RichTextEditor.createValueFromString(
                    props.initialValue || '',
                    'html'
                ) || RichTextEditor.createEmptyValue(),
        };
    }

    state = {
        value: RichTextEditor.createEmptyValue(),
    };

    getValue = () => {
        return fixHtml(this.state.value.toString('html'));
    };

    onChange = (value) => {
        this.setState({ value });
    };

    render() {
        return (
            <RichTextEditor value={this.state.value} onChange={this.onChange} />
        );
    }
}

export default SubjectNoteEditor;
