import React, { Fragment, useEffect, useState } from 'react';
import AssessmentQuestionPreview from '../../../containers/assessment/AssessmentQuestionPreview';
import { useStores } from '../../../hooks/use-stores';
import { observer } from 'mobx-react';
import { Select, MenuItem, FormControl } from '@material-ui/core';
import pluralize from 'pluralize';
import './preview-question.scss';
import SemiCircleProgress from './semi-circle-progress';
import { calculateColor, calculateScoreTitle } from '../../../utils/helpers';
import CallToActionBanner from '../../CallToActionBanner';
import { Image } from '../../design-system/containers';
import { SpaceBetweenDiv } from '../../design-system/containers';
import { Heading1 } from '../../design-system/headings';

function PreviewQuestion({
    questionId,
    onRequestClose,
    type,
    companyId,
    assessmentId,
    questionsSelected,
    hasMoreQuestions,
    questionsReportList,
}) {
    const { questionStore, assessmentStore, store, brandingStore } =
        useStores();
    const { question, loadingData } = questionStore;
    const { platform } = assessmentStore;
    const { havePartnerSubscription } = store;
    const { supportEmail } = brandingStore;
    const [selectedQuestionId, setSelectedQuestionId] = useState(questionId);
    const [isMovingBack, setIsMovingBack] = useState(false);

    let averageQuestionScore = 0;

    if (
        questionsReportList &&
        questionsReportList.length > 0 &&
        question &&
        questionsSelected &&
        questionsSelected.indexOf(question.id) > -1 &&
        questionsReportList[questionsSelected.indexOf(question.id)]
    ) {
        averageQuestionScore =
            questionsReportList[questionsSelected.indexOf(question.id)]
                .average_score_percentage;
    }

    useEffect(() => {
        setSelectedQuestionId(questionId);
    }, [questionId]);

    useEffect(() => {
        if (type === 'questionReview')
            questionStore.loadQuestionReview(
                companyId,
                selectedQuestionId,
                assessmentId
            );
        else questionStore.loadQuestionPreview(companyId, selectedQuestionId);
    }, [companyId, selectedQuestionId, type]);

    if (!question) return null;

    return (
        <Fragment>
            {type === 'questionReview' ? (
                <div className={'modalHeader'}>
                    <div className="close-btn-with-text">
                        <Image
                            src="/static/img/close.svg"
                            alt="Close"
                            onClick={onRequestClose}
                            link
                        />
                        {questionsSelected.indexOf(selectedQuestionId) + 1}/
                        {questionsSelected.length}{' '}
                        {pluralize('question', questionsSelected.length)}
                    </div>
                    {!loadingData && (
                        <div className="heading-with-score">
                            <h3>{question.name}</h3> -
                            <div className="score-group">
                                <span
                                    style={{
                                        color: calculateColor(
                                            averageQuestionScore
                                        ),
                                    }}
                                >
                                    {calculateScoreTitle(averageQuestionScore)}
                                </span>
                                <SemiCircleProgress
                                    percentage={averageQuestionScore}
                                    size="small"
                                    smallFont={true}
                                    inTable={true}
                                />
                            </div>
                        </div>
                    )}
                    {/* <div className="assessment-show-results-for">
                        <p>Show results for</p>
                        <FormControl variant="outlined">
                            <Select
                                name="platform"
                                color="primary"
                                value={platform}
                                onChange={(e) => {
                                    assessmentStore.setPlatform(e.target.value);
                                }}
                            >
                                <MenuItem value="all_platforms">
                                    All platforms
                                </MenuItem>
                                <MenuItem value="mobile">Mobile</MenuItem>
                                <MenuItem value="desktop">Desktop</MenuItem>
                            </Select>
                        </FormControl>
                    </div> */}
                </div>
            ) : (
                <div className={'modalHeader'}>
                    {!hasMoreQuestions ? (
                        <SpaceBetweenDiv alignCenter px={48}>
                            <Heading1>
                                Preview: {!loadingData && question.name}
                            </Heading1>
                            <Image
                                src="/static/img/close.svg"
                                alt="Close"
                                onClick={onRequestClose}
                                link
                            />
                        </SpaceBetweenDiv>
                    ) : (
                        <Fragment>
                            <Heading1>
                                Preview: {!loadingData && question.name}
                            </Heading1>
                            <div className="close-btn-with-text">
                                <Image
                                    src="/static/img/close.svg"
                                    alt="Close"
                                    onClick={onRequestClose}
                                />
                                {questionsSelected.indexOf(selectedQuestionId) +
                                    1}
                                /{questionsSelected.length}{' '}
                                {pluralize(
                                    'question',
                                    questionsSelected.length
                                )}
                            </div>
                        </Fragment>
                    )}
                </div>
            )}
            {question.disabled && (
                <CallToActionBanner
                    isPartnerCompany={havePartnerSubscription}
                    supportEmail={supportEmail}
                    contentType={'question'}
                />
            )}
            <AssessmentQuestionPreview
                questionsSelected={questionsSelected}
                selectedQuestionId={selectedQuestionId}
                setSelectedQuestionId={setSelectedQuestionId}
                hasMoreQuestions={hasMoreQuestions}
                isMovingBack={isMovingBack}
                setIsMovingBack={setIsMovingBack}
                onRequestClose={onRequestClose}
                question={question}
                companyId={companyId}
                isReview={type === 'questionReview'}
                loadingData={loadingData}
            />
        </Fragment>
    );
}

export default observer(PreviewQuestion);
