import React from 'react';

export default ({ size = 86 }) => (
    <div className="loading">
        <div className="loading__dots">
            <div className="loading__dots__dot"></div>
            <div className="loading__dots__dot"></div>
            <div className="loading__dots__dot"></div>
        </div>
    </div>
);
