import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import Spinner from '../../components/Spinner';
import { formatDate } from '../../utils/helpers';
import { Table, Tr, Td, Th, Thead } from 'reactable';
import { enforceSlash } from '../../utils/helpers';

@inject('superStore')
@observer
class PartnersListSuperContainer extends React.Component {
    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        this.props.superStore.loadPartners();
    }

    render() {
        const { loadingPartners, partners } = this.props.superStore;
        const { match } = this.props;

        return loadingPartners ? (
            <Spinner />
        ) : (
            <Fragment>
                <div className="block">
                    <Row>
                        <Col md="9" xs="9" className="leftMenu">
                            <h2>Partners</h2>
                        </Col>
                        <Col md="3" xs="3">
                            <div className="pull-right">
                                <Link to={`${enforceSlash(match.url)}create`}>
                                    <div className="btn btn-primary ">
                                        Create Partner
                                    </div>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {partners && partners.length > 0 && (
                            <Col md="12" xs="12" className="leftMenu">
                                <Table
                                    className="table"
                                    id="table"
                                    itemsPerPage={500}
                                    pageButtonLimit={5}
                                >
                                    <Thead>
                                        <Th column="Partner" className="">
                                            Partner
                                        </Th>
                                        <Th column="Created" className="">
                                            Created
                                        </Th>
                                        <Th column="Managers" className="">
                                            Managers
                                        </Th>
                                        <Th column="lms_domain" className="">
                                            LMS domain
                                        </Th>
                                        <Th column="my_domain" className="">
                                            My domain
                                        </Th>
                                        <Th
                                            column="mailgun_domain"
                                            className=""
                                        >
                                            Mail domain
                                        </Th>
                                        <Th column="Companies" className="">
                                            Companies
                                        </Th>
                                    </Thead>
                                    {partners.map((partner, i) => (
                                        <Tr key={i}>
                                            <Td
                                                column={'Partner'}
                                                value={partner.name}
                                                className=""
                                            >
                                                <Link
                                                    to={`${enforceSlash(
                                                        match.url
                                                    )}${partner.id}`}
                                                >
                                                    {partner.name}
                                                </Link>
                                            </Td>
                                            <Td
                                                column={'Created'}
                                                value={partner.created}
                                            >
                                                {formatDate(partner.created)}
                                            </Td>
                                            <Td
                                                column={'Managers'}
                                                value={partner.managers}
                                            >
                                                <Fragment>
                                                    {partner.managers.map(
                                                        (x) => (
                                                            <Fragment>
                                                                {x}
                                                                <br />
                                                            </Fragment>
                                                        )
                                                    )}
                                                </Fragment>
                                            </Td>
                                            <Td
                                                column={'lms_domain'}
                                                value={partner.lms_domain}
                                            >
                                                <Fragment>
                                                    {partner.lms_domain.map(
                                                        (x) => (
                                                            <Fragment>
                                                                {x}
                                                                <br />
                                                            </Fragment>
                                                        )
                                                    )}
                                                </Fragment>
                                            </Td>
                                            <Td
                                                column={'my_domain'}
                                                value={partner.my_domain}
                                            >
                                                <Fragment>
                                                    {partner.my_domain.map(
                                                        (x) => (
                                                            <Fragment>
                                                                {x}
                                                                <br />
                                                            </Fragment>
                                                        )
                                                    )}
                                                </Fragment>
                                            </Td>
                                            <Td
                                                column={'mailgun_domain'}
                                                value={partner.mailgun_domain}
                                            >
                                                <Fragment>
                                                    {partner.mailgun_domain.map(
                                                        (x) => (
                                                            <Fragment>
                                                                {x}
                                                                <br />
                                                            </Fragment>
                                                        )
                                                    )}
                                                </Fragment>
                                            </Td>
                                            <Td
                                                column={'Companies'}
                                                value={partner.company_count}
                                            >
                                                {partner.company_count}
                                            </Td>
                                        </Tr>
                                    ))}
                                </Table>
                            </Col>
                        )}
                    </Row>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(PartnersListSuperContainer);
