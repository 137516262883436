import React, { Fragment, useState, useMemo } from 'react';
import StatusBadge from '../../StatusBadge';
import moment from 'moment';
import './assessment-list-table.scss';
import LightTooltip from '../../LightTooltip';
import SemiCircleProgress from './semi-circle-progress';
import { MenuItem, IconButton, Menu, Tooltip } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import { STATUS } from '../../../utils/constants';
import { useHistory } from 'react-router';
import { calculateColor } from '../../../utils/helpers';
import { TextTiny, TextDefault } from '../../design-system/texts';
import SimpleTable from '../../SimpleTable';
import { FlexDiv } from '../../design-system/containers';

function AssessmentListTable({
    assessments,
    onDelete,
    pauseAssessment,
    // completeAssessment,
    lists,
}) {
    let history = useHistory();

    const dateFormat = (date) => (
        <Fragment>
            <div>
                <TextTiny bold lighter>
                    {moment(date).format('ll')}
                </TextTiny>
            </div>
            <TextTiny lighter>{moment(date).format('LT')}</TextTiny>
        </Fragment>
    );

    const [anchorEl, setAnchorEl] = useState(null);
    const [assessment, setOpenedAssessment] = useState(null);

    const handleMenuOpen = (e, assessment) => {
        setOpenedAssessment(assessment);
        setAnchorEl(e.currentTarget);
        e.stopPropagation();
    };

    const handleMenuClose = (e) => {
        setOpenedAssessment(null);
        setAnchorEl(null);
        e.stopPropagation();
    };

    const moreActionsMenu = (assessment) => (
        <IconButton
            color="primary"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(e) => {
                handleMenuOpen(e, assessment);
                e.preventDefault();
            }}
            id={assessment.id}
        >
            <MoreIcon />
        </IconButton>
    );

    const maxVisibleLength = 30;

    const columns = useMemo(
        () => [
            {
                Header: 'Assessment',
                accessor: 'name',
                Cell: (x) => {
                    const areas = x.row.original._rawData.definition.areas;
                    return (
                        <Fragment>
                            <div>
                                {(x.value || '').length > maxVisibleLength ? (
                                    <Tooltip title={x.value || ''}>
                                        <span>
                                            <TextDefault bold>
                                                {(x.value || '').substring(
                                                    0,
                                                    maxVisibleLength
                                                )}
                                                ...
                                            </TextDefault>
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <TextDefault bold>{x.value}</TextDefault>
                                )}
                            </div>
                            {areas.length ? (
                                <TextTiny lighter>
                                    {areas.slice(0, 2).map((area, i, arr) => (
                                        <span key={area}>
                                            {/* don't put comma after last area  */}
                                            {area}
                                            {i !== arr.length - 1 ? ', ' : ''}
                                        </span>
                                    ))}
                                    &nbsp;
                                    {areas.length > 2 && (
                                        <LightTooltip
                                            title={areas
                                                .slice(2, areas.length)
                                                .map((area) => (
                                                    <div key={area}>{area}</div>
                                                ))}
                                        >
                                            <TextTiny underline>
                                                +
                                                {
                                                    areas.slice(2, areas.length)
                                                        .length
                                                }{' '}
                                                more
                                            </TextTiny>
                                        </LightTooltip>
                                    )}
                                </TextTiny>
                            ) : (
                                ''
                            )}
                        </Fragment>
                    );
                },
            },
            {
                Header: 'Status',
                accessor: '_rawData.status',
                Cell: (x) => (
                    <StatusBadge
                        color={x.row.original.statusInfo.color}
                        text={x.row.original.statusInfo.label}
                    />
                ),
            },
            {
                Header: '# Employees',
                accessor: '_rawData.totalEmployees',
                Cell: (x) => {
                    const listNames = lists.filter((list) =>
                        x.row.original._rawData.definition.userLists.includes(
                            list.id
                        )
                    );
                    return listNames.length > 0 ? (
                        <LightTooltip
                            title={listNames.map((list) => (
                                <div key={list.id}>{list.name}</div>
                            ))}
                        >
                            <TextTiny underline lighter>
                                {x.value}
                            </TextTiny>
                        </LightTooltip>
                    ) : (
                        <TextTiny lighter>0</TextTiny>
                    );
                },
            },
            {
                Header: 'Delivery date',
                accessor: 'deliveryDate',
                Cell: (x) => (x.value ? dateFormat(x.value) : '-'),
                minWidth: 112,
            },
            {
                Header: 'Due date',
                accessor: '_rawData.due',
                Cell: (x) => (x.value ? dateFormat(x.value) : '-'),
                minWidth: 112,
            },
            {
                Header: 'Completion',
                accessor: 'attendance',
                Cell: (x) =>
                    x.row.original.status === STATUS.ONGOING ||
                    x.row.original.status === STATUS.COMPLETED ||
                    x.row.original._rawData.employeesFinished === 1 ? (
                        <Fragment>
                            <div>
                                <TextTiny bold color={calculateColor(x.value)}>
                                    {x.value}%
                                </TextTiny>
                            </div>
                            <TextTiny lighter>
                                {x.row.original._rawData.employeesFinished}/
                                {x.row.original._rawData.totalEmployees}
                            </TextTiny>
                        </Fragment>
                    ) : (
                        ''
                    ),
            },
            {
                Header: 'Resilience score',
                accessor: 'resilienceScore',
                Cell: (x) =>
                    x.row.original.status === STATUS.ONGOING ||
                    x.row.original.status === STATUS.COMPLETED ||
                    x.row.original._rawData.employeesFinished === 1 ? (
                        <SemiCircleProgress
                            percentage={x.value}
                            size="small"
                            smallFont={true}
                            inTable={true}
                        />
                    ) : (
                        ''
                    ),
                tooltip:
                    'Average employee score across all assessed threat areas.',
            },
            {
                Header: ' ',
                Cell: (x) => moreActionsMenu(x.row.original),
            },
        ],
        []
    );

    return (
        <FlexDiv mt={32}>
            <SimpleTable data={assessments} columns={columns} useLink />
            {assessment && (
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={assessment != null}
                    onClose={handleMenuClose}
                    elevation={1}
                >
                    <MenuItem
                        onClick={(e) => {
                            history.push(assessment.link);
                            handleMenuClose(e);
                        }}
                    >
                        Open
                    </MenuItem>
                    {/* {assessment.status === STATUS.REVIEW
                    && <MenuItem
                        onClick={(e) => {
                            completeAssessment(assessment.id)
                            handleMenuClose()
                        }}
                    >
                        Complete
                    </MenuItem>
                } */}
                    <MenuItem
                        onClick={(e) => {
                            history.push(
                                `/assessment/create/copy/${assessment.id}`
                            );
                            handleMenuClose(e);
                        }}
                    >
                        Duplicate
                    </MenuItem>
                    {(assessment.status === STATUS.DRAFT ||
                        assessment.status === STATUS.SCHEDULED ||
                        assessment.status === STATUS.ONGOING) && (
                        <MenuItem
                            onClick={(e) => {
                                history.push(
                                    `/assessment/edit/${assessment.id}`
                                );
                                handleMenuClose(e);
                            }}
                        >
                            Edit
                        </MenuItem>
                    )}
                    {assessment.status === STATUS.SCHEDULED && (
                        <MenuItem
                            onClick={(e) => {
                                pauseAssessment(assessment.id);
                                handleMenuClose(e);
                            }}
                        >
                            Stop
                        </MenuItem>
                    )}
                    {(assessment.status === STATUS.DRAFT ||
                        assessment.status === STATUS.SCHEDULED) && (
                        <MenuItem
                            onClick={(e) => {
                                onDelete(assessment.id);
                                handleMenuClose(e);
                            }}
                        >
                            <span style={{ color: 'red' }}>Delete</span>
                        </MenuItem>
                    )}
                </Menu>
            )}
        </FlexDiv>
    );
}

export default AssessmentListTable;
