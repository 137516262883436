import React from 'react';
//import { observer} from 'mobx-react';

// Use this if need  go to parent   if company change since current page is not have  any sense in current context
//@observer
class BaseCompanyDependentChildContainer extends React.Component {
    state = { error: '', loadedOrError: null };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState) return; // not yet initialized
        if (nextProps.companyId !== prevState.companyId) {
            if (prevState.companyId)
                return {
                    companyChanged: true,
                    loadedOrError: null,
                };
            else {
                return {
                    companyId: nextProps.companyId,
                    loadedOrError: null,
                };
            }
        }

        // No state update necessary
        return null;
    }

    componentDidMount() {
        // It's preferable in most cases to wait until after mounting to load data.
        // See below for a bit more context...
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loadedOrError === null) {
            // At this point, we're in the "commit" phase, so it's safe to load the new data.
            this.loadData();
        }
    }

    async doLoadData() {
        throw new Error('Not Implemented');
    }

    getRootPath(pathname) {
        if (pathname.toLowerCase().startsWith('/ppv')) return '/ppv';
        else if (pathname.toLowerCase().startsWith('/automations'))
            return '/automations';
        else if (pathname.toLowerCase().startsWith('/trainings'))
            return '/trainings';

        return '/';
    }

    companyChanged() {
        let path =
            (this.props.parentMatch && this.props.parentMatch.url) ||
            this.getRootPath(this.props.history.location.pathname);
        if (
            path.trimEnd('/') !==
            this.props.history.location.pathname.trimEnd('/')
        )
            return this.props.history.push(path);
    }

    async loadData() {
        if (this.props.companyId) {
            try {
                await this.doLoadData();
                this.setState({ ...this.state, loadedOrError: true });
            } catch (e) {
                this.setState({ ...this.state, loadedOrError: e.message });
            }
        }
    }

    render() {
        return null;
    }
}

export default BaseCompanyDependentChildContainer;
