import React from 'react'
import {TextTiny} from "./design-system/texts"

function TermsOfService({ termsUrl , accountName}) {
    return (
        <TextTiny lighter mt={16}>
            By clicking Continue, you agree to {accountName}
            's{' '}
            <a href={termsUrl} rel="noopener noreferrer" target="_blank">
                <u>Terms of service</u>
            </a>
        </TextTiny>
    );
}

export default TermsOfService
