import React from 'react';
import { observer } from 'mobx-react';
import { ThemedButton } from '../../themed/ThemedComponents';
import { TextField } from '@material-ui/core';
import capitalize from 'lodash/capitalize';

function ChangeName({
    onRequestClose,
    name,
    nextStep,
    onChangeName,
    saveDraft,
    isDraft,
    itemString,
    isNameUntitled,
}) {
    return (
        <div className="modalWindow simple">
            <div className={'modalHeader plain'}>
                <h3>Name this {itemString}</h3>
                <img
                    className={'modal-close-right'}
                    src="/static/img/close.svg"
                    alt="Close"
                    onClick={onRequestClose}
                />
            </div>
            <div className={'modalContent'} style={{ minHeight: '100px' }}>
                <div className="form-holder">
                    <TextField
                        type="text"
                        name="name"
                        className={'bigInput'}
                        value={name}
                        label={`${capitalize(itemString)} name`}
                        onChange={onChangeName}
                        autoFocus
                        placeholder={`e.g. ${capitalize(itemString)} 1`}
                    />
                </div>
            </div>
            <div className={'modalFooter'}>
                <ThemedButton
                    primary
                    onClick={() => {
                        isDraft ? saveDraft() : nextStep();
                        onRequestClose();
                    }}
                    disabled={isNameUntitled}
                >
                    {isDraft ? 'Save draft' : 'Save and complete'}
                </ThemedButton>
            </div>
        </div>
    );
}

export default observer(ChangeName);
