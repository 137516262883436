/**
 * This is a very simple class that returns data asynchronously.
 *
 * This code runs on both the server and in the browser.
 *
 * You could also put the logic to detect if code is being run on
 * the server or in the browser inside the page template.
 */

import fetch from 'isomorphic-fetch';

const Utils = require('../utils/helpers');

export default class {
    static async getMyData(req, language) {
        const options = {
            method: 'GET',
            credentials: 'include', // for client side requests
        };
        if (req) {
            options.headers = { cookie: req.headers.cookie }; // for server side request
        }
        let url = Utils.host() + '/data/subjects/my-data';
        const parameters = {
            language: language,
        };
        url = Utils.buildUrl(url, parameters);
        let res = await fetch(url, options);
        let data = await res.json();
        return data;
    }

    static async getTextContent(id) {
        // isomorphic-fetch runs in browsers and server-side
        const url = 'https://www.awarego.com/wp-json/wp/v2/pages/' + id;
        let res = await fetch(url);
        let data = await res.json();
        return data;
    }

    static async createEvent(req, event_type_id, company_id, subject_id) {
        const options = {
            method: 'GET',
            credentials: 'include', // for client side requests
        };
        if (req) {
            options.headers = { cookie: req.headers.cookie }; // for server side request
        }
        let url = Utils.host() + '/data/event/insert';
        const parameters = {
            event_type_id: event_type_id,
            company_id: company_id,
            subject_id: subject_id,
        };
        url = Utils.buildUrl(url, parameters);
        let res = await fetch(url, options);
        let data = await res.json();
        return data;
    }

    static async switchLanguage(req, email, language) {
        const options = {
            method: 'GET',
            credentials: 'include', // for client side requests
        };
        if (req) {
            options.headers = { cookie: req.headers.cookie }; // for server side request
        }
        let url = Utils.host() + '/data/user/setlanguage';
        const parameters = {
            language: language,
            email: email,
        };
        url = Utils.buildUrl(url, parameters);
        let res = await fetch(url, options);
        let data = await res.json();
        return data;
    }

    static async saveConfirmation(req, body, csrfToken) {
        body._csrf = csrfToken;
        const bodyJson = JSON.stringify(body);
        const options = {
            method: 'POST',
            credentials: 'include', // for client side requests
            headers: new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                cookie: req ? req.headers.cookie : '',
            }),
            body: bodyJson,
        };
        let url = Utils.host() + '/data/user/confirm';
        let res = await fetch(url, options);
        let data = await res.json();
        return data;
    }
}
