import React from 'react';
import KeyBehaviourChart from './key-behaviour-chart';
import SemiCircleProgress from './semi-circle-progress';
import { observer } from 'mobx-react';
import { Row } from 'reactstrap';
import { ThemedButton } from '../../themed/ThemedComponents';

function ReportThreatAreas({
    areaData,
    threatAreasRef,
    showReviewQuestionsModal,
    firstQuestionId,
}) {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        areaData && (
            <div>
                <div
                    className="d-flex horizontal-scroll-bar"
                    ref={threatAreasRef}
                >
                    {areaData.map((area, i) => (
                        <div
                            key={i}
                            onClick={(e) => handleChange(e, i)}
                            className={
                                value === i
                                    ? 'btn-with-data active'
                                    : 'btn-with-data'
                            }
                        >
                            <span>{area.name}</span>
                            <div className="progress-semicircle">
                                <SemiCircleProgress
                                    percentage={area.score}
                                    size="small"
                                    smallFont={true}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                {areaData.map((area, i) => {
                    let areaComponents = area.components;

                    return (
                        <div hidden={value !== i} key={i}>
                            <div className="section-header">
                                <h4 className="assessment-view-subheader">
                                    Key behaviours<span>{area.name}</span>
                                </h4>
                                <ThemedButton
                                    noTextTransform
                                    secondary
                                    onClick={() =>
                                        showReviewQuestionsModal(
                                            firstQuestionId
                                        )
                                    }
                                >
                                    Review answers
                                </ThemedButton>
                            </div>
                            <Row>
                                {areaComponents.map((component, i) => (
                                    <KeyBehaviourChart
                                        key={i}
                                        componentData={component}
                                        index={i}
                                    />
                                ))}
                            </Row>
                        </div>
                    );
                })}
            </div>
        )
    );
}

export default observer(ReportThreatAreas);
