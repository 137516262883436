import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import FormDef from '../../forms/list';
import ListEdit from '../../components/manager/users/list-edit';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

@inject('store', 'listStore')
@observer
class UsersListEditContainer extends React.Component {
    constructor(props) {
        super(props);
        this._form = new FormDef(
            {},
            {
                hooks: {
                    onSuccess: this.onSuccess,
                },
            }
        );
        this._form.update(
            this.props.list ? { name: this.props.list.name } : {}
        );
    }

    onSuccess = async () => {
        let result = await this.props.listStore.save(
            this.props.companyId,
            this.props.list && this.props.list.id,
            { active: 1, ...this._form.values() }
        );
        if (result) {
            if (this.props.list)
                this.props.history.push(`/users/list/${this.props.list.id}`);
            else this.props.history.push(`/users/add/${result}`);
        }
    };

    render() {
        const { error, loadingList, savingList } = this.props.listStore;
        const { onRequestClose } = this.props;
        const isNew = !this.props.list;
        return (
            <Fragment>
                <div className={'modalHeader'}>
                    {isNew ? <h3>Create Employee List</h3> : <h3>Edit list</h3>}
                    <img
                        className={'modal-close-right'}
                        src="/static/img/close.svg"
                        alt="Close"
                        onClick={onRequestClose}
                    />
                </div>
                <div className={'modalContent'}>
                    {!loadingList && (
                        <ListEdit
                            form={this._form}
                            error={error}
                            listId={this.props.listId}
                            isNew={isNew}
                            savingList={savingList}
                        />
                    )}
                </div>
                <div className={'modalFooter'}>
                    {savingList ? (
                        <ThemedSpinner size={50} />
                    ) : (
                        <Fragment>
                            <ThemedButton primary onClick={this._form.onSubmit}>
                                {isNew ? 'Create' : 'Update'} list
                            </ThemedButton>
                            &nbsp;
                            <Link
                                to={
                                    isNew
                                        ? '/users'
                                        : `/users/list/${this.props.list.id}`
                                }
                            >
                                <ThemedButton secondary>Back</ThemedButton>
                            </Link>
                        </Fragment>
                    )}
                </div>
            </Fragment>
        );
    }
}

export default withRouter(UsersListEditContainer);
