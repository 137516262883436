import React, { Fragment } from 'react';
import { isEnumSet } from './../../../utils/helpers';
import { TRANSPORTS } from './../../../utils/constants';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import LightTooltip from '../../LightTooltip';

function SelectChannel({
    channel,
    slackAvailable,
    slackConnected,
    teamsAvailable,
    teamsConnected,
    onChangeChannel,
    isReminders,
}) {
    const channels = [
        {
            label: 'Email',
            available: true,
            connected: true,
            value: TRANSPORTS.EMAIL,
            checked: isEnumSet(channel, TRANSPORTS.EMAIL),
        },
        {
            label: 'Slack',
            available: slackAvailable,
            connected: slackConnected,
            value: TRANSPORTS.SLACK,
            checked:
                isEnumSet(channel, TRANSPORTS.SLACK) &&
                slackAvailable &&
                slackConnected,
        },
        {
            label: 'MS Teams',
            available: teamsAvailable,
            connected: teamsConnected,
            value: TRANSPORTS.TEAMS,
            checked:
                isEnumSet(channel, TRANSPORTS.TEAMS) &&
                teamsAvailable &&
                teamsConnected,
        },
    ];
    return (
        <Fragment>
            <h5 style={{ marginTop: '20px' }}>
                Choose delivery channels {isReminders ? 'for reminders' : ''}
            </h5>
            <div className={'training-schedule-options'}>
                {channels.map((channel) => (
                    <div className="checkbox-with-tooltip" key={channel.label}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={channel.checked}
                                    value={channel.value}
                                    onChange={onChangeChannel}
                                    name={channel.label}
                                    color="primary"
                                    disabled={
                                        !channel.available || !channel.connected
                                    }
                                />
                            }
                            label={channel.label}
                        />
                        {(!channel.available || !channel.connected) && (
                            <>
                                <LightTooltip
                                    title={
                                        channel.available
                                            ? `Upgrade is needed for ${channel.label} integration.`
                                            : `Please configure ${channel.label} under Settings/Integrations.`
                                    }
                                    placement="bottom"
                                >
                                    <InfoOutlinedIcon
                                        fontSize="small"
                                        color="action"
                                    />
                                </LightTooltip>
                                <span>{channel.available}</span>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </Fragment>
    );
}

export default SelectChannel;
