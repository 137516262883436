import { action, observable, isObservableArray } from 'mobx';
import { extractErrorMessage } from './helpers';
function isArray(value) {
    return Array.isArray(value) || isObservableArray(value);
}

export function generateLoadList(name, store, loadFlag, loaderFn, targetProp) {
    if (store[loadFlag] === undefined) {
        store[loadFlag] = observable(observable.box(false));
        store[loadFlag] = false;
    }
    if (store[targetProp] === undefined) store[targetProp] = observable([]);

    return action(async (options) => {
        if (store[loadFlag]) return;
        store[loadFlag] = true;
        try {
            const result = await loaderFn(options);
            store[targetProp].replace(result);
            return true;
        } catch (e) {
            if (store.setError) store.setError(extractErrorMessage(e), name);
        } finally {
            store[loadFlag] = false;
        }
    });
}

export function generateLoadEntity(
    name,
    store,
    loadFlag,
    loaderFn,
    targetProp
) {
    return action(async (options) => {
        if (store[loadFlag]) return;
        store[loadFlag] = true;
        try {
            const result = await loaderFn(options);
            store[targetProp] = result;
            return true;
        } catch (e) {
            if (store.setError) store.setError(extractErrorMessage(e), name);
        } finally {
            store[loadFlag] = false;
        }
    });
}

export function generateCreateEntity(
    name,
    store,
    creatingFlag,
    createFn,
    targetProp
) {
    return action(async function () {
        if (store[creatingFlag]) return;
        store[creatingFlag] = true;
        try {
            const result = await createFn.apply(store, arguments);
            if (targetProp) {
                let target = store[targetProp];

                if (target && isArray(target)) target.push(result);
                else store[targetProp] = result;
            }
            return result;
        } catch (e) {
            if (store.setError) store.setError(extractErrorMessage(e), name);
        } finally {
            store[creatingFlag] = false;
        }
    });
}

export function generateUpdateEntity(
    name,
    store,
    updatingArray,
    updateFn,
    onUpdated
) {
    if (store[updatingArray] === undefined)
        store[updatingArray] = observable([]);

    return action(async function (key) {
        if (store.setError) store.setError(null, name);
        if (isArray(store[updatingArray])) {
            if (store[updatingArray].includes(key)) return;
            store[updatingArray].push(key);
        } else {
            if (store[updatingArray]) return;
            store[updatingArray] = true;
        }
        try {
            const result = await updateFn.apply(store, arguments);
            if (onUpdated) onUpdated(result);
            return result;
        } catch (e) {
            console.error(e);
            if (store.setError) store.setError(extractErrorMessage(e), name);
        } finally {
            if (isArray(store[updatingArray])) {
                store[updatingArray].remove(key);
            } else store[updatingArray] = false;
        }
    });
}

export function generateDeleteEntity(
    name,
    store,
    removingArray,
    deleteFn,
    targetProp,
    filterFn
) {
    if (store[removingArray] === undefined)
        store[removingArray] = observable([]);

    return action(async function (key, onDeleted, options) {
        if (store[removingArray].includes(key)) return;
        store[removingArray].push(key);
        try {
            const result = await deleteFn.apply(store, arguments);

            if (onDeleted) {
                onDeleted(result);
            }

            if (targetProp && isArray(store[targetProp])) {
                let targetObj = filterFn(store[targetProp], key);
                store[targetProp].remove(targetObj);
            }
            return result;
        } catch (e) {
            console.error(e);
            if (store.setError) store.setError(extractErrorMessage(e), name);
        } finally {
            store[removingArray].remove(key);
        }
    });
}
