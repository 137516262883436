import React from 'react';
import { observer } from 'mobx-react';
import {
    CircularProgressbarWithChildren,
    buildStyles,
} from 'react-circular-progressbar';
import { calculateColor, defaultZero } from '../../../utils/helpers';

export default observer(
    ({ percentage, size, smallFont = false, inTable = false }) => {
        const setBarWidth = () => {
            if (size === 'medium') return 14;
            if (size === 'large') return 14;
            return 14;
        };
        const setcircleRatio = () => {
            if (size === 'medium') return 0.62;
            if (size === 'large') return 0.58;
            return 0.48;
        };
        const setRotation = () => {
            if (size === 'medium') return 0.69;
            if (size === 'large') return 0.7;
            return 0.76;
        };

        percentage = percentage || 0;

        return (
            <div className={inTable ? 'circle-progress-cropped' : ''}>
                <CircularProgressbarWithChildren
                    circleRatio={setcircleRatio()}
                    value={defaultZero(percentage)}
                    strokeWidth={setBarWidth()}
                    className="semicircle"
                    styles={buildStyles({
                        rotation: setRotation(),
                        strokeLinecap: 'round',
                        textSize: '20px',
                        pathTransitionDuration: 1,
                        textAlign: 'center',
                        fontWeight: 'bold',
                        pathColor: calculateColor(percentage),
                        textColor: '#000',
                        trailColor: '#EBEEF1',
                        backgroundColor: '#F2C94C',
                    })}
                >
                    {size === 'large' && (
                        <div className="text">
                            <div className="score">{percentage}%</div>
                            <div className="description">of 100</div>
                        </div>
                    )}
                    {size === 'medium' && (
                        <div className="text medium">
                            <div className="score">{percentage}</div>
                        </div>
                    )}
                    {size === 'small' && (
                        <div className="text small">
                            <div
                                className={`score ${
                                    smallFont
                                        ? 'circle-progress-small-font'
                                        : ''
                                }`}
                                style={{ color: calculateColor(percentage) }}
                            >
                                {' '}
                                {percentage}
                            </div>
                        </div>
                    )}
                </CircularProgressbarWithChildren>
            </div>
        );
    }
);
