import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import ErrorMessage from '../../errormessage';
import { observer } from 'mobx-react';
import { enforceSlash } from '../../../utils/helpers';
import pluralize from 'pluralize';
import { ThemedText } from '../../themed/ThemedComponents';
import ThemedLinkStep from '../../themed/ThemedLinkStep';
import ThemedSpinner from '../../themed/ThemedSpinner';
import classes from 'classnames';

function canonizeCategoryName(name) {
    if (!name) return name;
    return name.toLowerCase().replace(/\s+/g, '_');
}

@observer
class SubjectList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            previewId: 1,
            previewVideoLocation: '',
            filterOpen: false,
        };
    }

    toggleFilters() {
        this.setState({ filterOpen: !this.state.filterOpen });
    }

    render() {
        const { categories, match, parentMatch } = this.props;
        const selectedFilter = canonizeCategoryName(match.params.category);
        if (categories != null && categories.length > 0) {
            const filteredCategories = selectedFilter
                ? categories.filter(
                      (x) => canonizeCategoryName(x.name) === selectedFilter
                  )
                : categories;
            return (
                <div className="d-flex responsive-filters">
                    <div className="section-header mobile desktop-hide">
                        <div className={'left padded'}>
                            <h3>Subjects</h3>
                        </div>
                        <div
                            className={classes('icon-filters', {
                                open: !this.state.filterOpen,
                            })}
                            onClick={() => this.toggleFilters()}
                        ></div>
                    </div>
                    <div
                        className={classes('block filters ', {
                            open: !this.state.filterOpen,
                        })}
                    >
                        <ThemedLinkStep
                            active={
                                selectedFilter === 'all' || !selectedFilter
                                    ? 'active'
                                    : 0
                            }
                            to={`${enforceSlash(parentMatch.url)}`}
                            text={'All'}
                        />
                        {categories.map((subjects, i) => (
                            <ThemedLinkStep
                                key={`step-${i}`}
                                active={
                                    selectedFilter ===
                                    canonizeCategoryName(subjects.name)
                                        ? 'active'
                                        : 0
                                }
                                to={`${enforceSlash(
                                    parentMatch.url
                                )}${canonizeCategoryName(subjects.name)}`}
                                text={subjects.name}
                                count={
                                    subjects.subjects.length +
                                    ' ' +
                                    pluralize(
                                        'videos',
                                        subjects.subjects.length
                                    )
                                }
                            />
                        ))}
                    </div>
                    <div className="videos block">
                        <h1 className="mobile-hide ">Subjects</h1>
                        {filteredCategories.map((subjects, i) => (
                            <div key={i}>
                                <Row>
                                    <Col>
                                        <div className="listHeading">
                                            <h4>{subjects.name}</h4>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    {subjects.subjects.map((subject, ii) => (
                                        <Col md="12" xs="12" key={ii}>
                                            <div className="listItem">
                                                <Link
                                                    to={`${enforceSlash(
                                                        parentMatch.url
                                                    )}subject/${subject.id}`}
                                                >
                                                    <Row>
                                                        <Col md="3" xs="12">
                                                            <div className="img">
                                                                <img
                                                                    src={
                                                                        subject.thumb
                                                                    }
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md="9" xs="12">
                                                            <h3>
                                                                <ThemedText>
                                                                    {
                                                                        subject.title
                                                                    }
                                                                </ThemedText>
                                                            </h3>
                                                            <p>
                                                                {
                                                                    subject.description
                                                                }
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </div>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        ))}
                        <Row>
                            <Col md="12" xs="12" className="leftMenu">
                                <ErrorMessage error={this.state.error} />
                            </Col>
                        </Row>
                    </div>
                </div>
            );
        } else {
            return <ThemedSpinner />;
        }
    }
}

export default SubjectList;
