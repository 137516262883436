import React, { Fragment, useState, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import AssignCredit from '../components/manager/dashboard/assign-credit';
import NewsFeed from '../components/manager/dashboard/newsfeed';
import NewSubjects from '../components/manager/dashboard/newsubjects';
import NewCourse from '../components/manager/dashboard/newcourse';
import { Row, Col } from 'reactstrap';
import ThemedSpinner from '../components/themed/ThemedSpinner';
import { Helmet } from 'react-helmet';
import { useStores } from '../hooks/use-stores';
import DashboardCalendar from '../components/manager/dashboard/calendar';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import styles from '../components/manager/dashboard/calendar.module.scss';
import { computed, observable, action } from 'mobx';
import AutomationDashboard from '../components/manager/dashboard/automation-dashboard';
import AssessmentDashboard from '../components/manager/dashboard/assessment-dashboard';
import { FlexDiv } from '../components/design-system/containers';

function Home({ companyId }) {
    const { store, assessmentStore, automationStore, brandingStore } =
        useStores();
    const [selectedMonth, setSelectedMonth] = useState(
        moment().format('YYYY-MM')
    );

    const {
        serverData,
        currentCompany,
        loadingCalendarData,
        loadingDashboardData,
        dashboardData,
        events,
        daysWithDot,
        trainingEnabled,
        assessmentEnabled,
        assessmentsUnavailable,
        assessmentsUpgradeAllowed,
        trainingUpgradeAllowed,
        trainingProgramsUnavailable,
    } = store;

    useEffect(() => {
        doLoadCalendarData();
    }, [selectedMonth]);

    useEffect(() => {
        doLoadDashboardData();
        doLoadAutomations();
        doLoadAssessments();
    }, [companyId]);

    const doLoadDashboardData = async () => {
        await store.loadDashboardData(companyId);
    };

    const doLoadCalendarData = async () => {
        await store.loadCalendarData(companyId, selectedMonth);
    };

    const doLoadAutomations = async () => {
        await automationStore.loadAutomations(companyId, false, 0, 3);
    };

    const doLoadAssessments = async () => {
        await assessmentStore.loadAssessments(companyId, false, 0, 3);
    };

    const localStore = useLocalStore(() => ({
        /*observables here*/
        @observable selectedDate: new Date(),
        /*actions here*/
        @action
        setSelectedDate(value) {
            this.selectedDate = value;
        },
        /*computed values i.e. derived state here*/
        @computed
        get displayedEvents() {
            if (!store.loadingCalendarData) {
                const result = [...events];
                if (
                    this.selectedDate &&
                    !daysWithDot.includes(
                        moment(this.selectedDate).format('YYYY-MM-DD')
                    )
                ) {
                    result.push({
                        name: 'No events scheduled',
                        date: moment(this.selectedDate).format('YYYY-MM-DD'),
                    });
                }
                return orderBy(
                    result.map((event) => ({
                        ...event,
                        id: `${moment(event.date).format('YYYY-MM-DD')}`,
                    })),
                    'date'
                );
            } else {
                return [];
            }
        },
    }));

    const onDayChange = (date) => {
        localStore.setSelectedDate(date);
    };

    const assessmentFirst =
        automationStore.automations.length === 0 &&
        assessmentStore.assessments.length > 0;

    return (
        <Fragment>
            <Helmet>
                <title>Dashboard</title>
            </Helmet>
            <Row>
                <Col lg="8" xs="12">
                    {/*TODO: load it personally*/}
                    {serverData &&
                        serverData.unusedPackages &&
                        serverData.unusedPackages.length > 0 && (
                            <div>
                                {serverData.unusedPackages.map((pack, i) => (
                                    <AssignCredit
                                        key={i}
                                        unusedPackage={pack}
                                        companies={serverData.companies}
                                    />
                                ))}
                            </div>
                        )}
                    {loadingDashboardData ? (
                        <ThemedSpinner />
                    ) : (
                        <FlexDiv
                            column={!assessmentFirst}
                            flipColumn={assessmentFirst}
                        >
                            {trainingEnabled && (
                                <AutomationDashboard
                                    dashboardData={dashboardData.trainings}
                                    trainings={[...automationStore.automations]}
                                    trainingProgramsUnavailable={
                                        trainingProgramsUnavailable
                                    }
                                    upgradeAllowed={trainingUpgradeAllowed}
                                    supportEmail={brandingStore.supportEmail}
                                />
                            )}

                            {assessmentEnabled && (
                                <AssessmentDashboard
                                    dashboardData={dashboardData.assessments}
                                    assessments={[
                                        ...assessmentStore.assessments,
                                    ]}
                                    assessmentsUnavailable={
                                        assessmentsUnavailable
                                    }
                                    upgradeAllowed={assessmentsUpgradeAllowed}
                                    supportEmail={brandingStore.supportEmail}
                                />
                            )}
                        </FlexDiv>
                    )}
                </Col>
                <Col className={styles['dashboard-sidebar']}>
                    <DashboardCalendar
                        displayedEvents={localStore.displayedEvents}
                        onDayChange={onDayChange}
                        setSelectedMonth={setSelectedMonth}
                        selectedDate={localStore.selectedDate}
                        daysWithDot={daysWithDot}
                        isLoading={loadingCalendarData}
                    />
                    <NewCourse />
                    {trainingEnabled && <NewSubjects />}
                    {!!currentCompany &&
                        currentCompany.awarego_company === 1 && <NewsFeed />}
                </Col>
            </Row>
        </Fragment>
    );
}

export default observer(Home);
