import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import FormDef from '../../forms/partner';
import NameForm from '../../components/super/partnerEdit';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

@inject('superStore')
@observer
class CompaniesViewSuperContainer extends React.Component {
    state = { error: '', loadedOrError: null };

    constructor(props) {
        super(props);
        this._form = new FormDef(
            {},
            {
                hooks: {
                    onSuccess: this.onSuccess,
                },
            }
        );
    }

    componentDidMount() {
        // It's preferable in most cases to wait until after mounting to load data.
        // See below for a bit more context...
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loadedOrError === null) {
            // At this point, we're in the "commit" phase, so it's safe to load the new data.
            this.loadData();
        }
    }

    async loadData() {
        if (this.props.partnerId) {
            await this.props.superStore.loadPartner(this.props.partnerId);
            this._form.update(
                this.props.superStore.editingPartner
                    ? {
                          name: this.props.superStore.editingPartner.name,
                      }
                    : {}
            );
            this.setState({ ...this.state, loadedOrError: true });
        }
    }

    onSuccess = async () => {
        let result = await this.props.superStore.updatePartner(
            this.props.partnerId,
            this._form.values()
        );
        if (result) {
            if (result.id || this.props.partnerId)
                return this.props.history.push(
                    `/super/partners/${result.id || this.props.partnerId}`
                );
            else return this.props.history.push('/super/partners');
        }
    };

    render() {
        const { savingPartner, error } = this.props.superStore;

        return (
            <Fragment>
                <div className="steps">
                    {
                        <Fragment>
                            <NameForm form={this._form} />
                            {savingPartner ? (
                                <ThemedSpinner size={45} />
                            ) : (
                                <div
                                    className="btn btn-primary"
                                    onClick={(e) => this._form.onSubmit(e)}
                                >
                                    Save
                                </div>
                            )}
                        </Fragment>
                    }
                    <ErrorMessage error={error} />
                </div>
            </Fragment>
        );
    }
}

export default withRouter(CompaniesViewSuperContainer);
