import React from 'react';
import { Switch, Route, useRouteMatch, Link } from 'react-router-dom';
import TestPage from './testpage';
import TestPreview from './TestPreview';

export default () => {
    const match = useRouteMatch();
    return (
        <Switch>
            <Route
                path={`${match.url}`}
                exact
                render={() => (
                    <>
                        <Link to={`${match.url}/shared-components`}>
                            Shared components
                        </Link>
                        <br />
                    </>
                )}
            />

            <Route
                path={`${match.url}/shared-components`}
                exact
                render={TestPage}
            />
            <Route
                path={`${match.url}/preview`}
                exact
                component={TestPreview}
            />
        </Switch>
    );
};
