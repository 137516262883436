/* eslint-disable no-undef */
import { observable, action, computed } from 'mobx';
import ReactGA from 'react-ga';
import services from '../services';
import { backOffDelay } from '../utils/helpers';
import i18n from '../tools/i18n';
import keyBy from 'lodash/keyBy';
import { version } from '@awarego/awarego-components';

class CommonStore {
    @observable message;
    @observable appLoaded;
    @observable messageType;
    @observable lastPage;
    @observable messageShown = false;
    @observable i18n = null;
    @observable confirmationRequired = false;
    @observable confirmationDescription = '';
    @observable confirmationAction = '';
    @observable confirmationOnConfirmAction;
    @observable confirmationShowCancel = true;
    @observable confirmationTitle = '';
    @observable isConfirmationDelete = false;
    @observable additionalCheckbox = null;
    @observable languagesLoaded = false;
    @observable languages = [];

    messageTimeout;

    @action hideMessage() {
        this.message = null;
        this.messageShown = false;
        this.messageShown = false;
        try {
            clearTimeout(this.messageTimeout);
        } catch (e) {}
        this.messageTimeout = null;
    }

    @action
    async setAppLoaded() {
        this.appLoaded = true;
    }

    @action success(message) {
        this.showMessage(message, 'success');
    }

    @action warn(message) {
        this.showMessage(message, 'warning');
    }

    @action error(message) {
        this.showMessage(message, 'error');
    }

    @action showMessage(message, type = 'info', delay = 5000) {
        this.hideMessage();
        this.message = message;
        this.messageShown = true;
        this.messageType = type;
        this.messageTimeout = setTimeout(() => {
            this.hideMessage();
        }, delay);
    }

    getEntityName(entityType, entity) {
        switch (entityType) {
            case 'Request':
                return entity.location;
            case 'User':
                return entity.name || entity.email;
            case 'Customer':
                return entity.name;
            default:
                return entityType;
        }
    }

    @action showDeleteConfirm(entityType, entity, callback) {
        this.showConfirm(
            `Do you really want to delete ${this.getEntityName(
                entityType,
                entity
            )}`,
            'Delete',
            `Delete ${entityType}`,
            true,
            callback,
        );
    }

    @action showConfirm(
        confirmationDescription,
        confirmationAction,
        confirmationTitle,
        confirmationOnConfirmAction,
        isConfirmationDelete,
        confirmationShowCancel = true,
        additionalCheckbox,
    ) {
        this.confirmationDescription = confirmationDescription;
        this.confirmationAction = confirmationAction;
        this.confirmationTitle = confirmationTitle;
        this.confirmationOnConfirmAction = confirmationOnConfirmAction;
        this.confirmationShowCancel = confirmationShowCancel;
        this.confirmationRequired = true;
        this.isConfirmationDelete = isConfirmationDelete;
        this.additionalCheckbox = additionalCheckbox;
    }

    @action cancelConfirm() {
        this.confirmationDescription = null;
        this.confirmationAction = null;
        this.confirmationTitle = null;
        this.confirmationOnConfirmAction = null;
        this.confirmationRequired = false;
        this.additionalCheckbox = null;
        this.isConfirmationDelete = false;
    }

    @action doConfirm() {
        if (this.confirmationOnConfirmAction)
            this.confirmationOnConfirmAction();

        this.cancelConfirm();
    }

    @action
    async loadLanguages(counter = 0) {
        this.languagesLoaded = false;

        try {
            let result = await services.Languages.list();
            this.languages.replace(result);
            this.i18n = i18n.init(this);
            this.languagesLoaded = true;
        } catch (e) {
            if (counter < 10)
                setTimeout(async () => {
                    await this.loadLanguages(counter + 1);
                }, backOffDelay(1500, counter));
        }
    }

    @computed get languagesIndex() {
        return keyBy(this.languages, 'code');
    }

    @action analyticsEvent(category, action, label) {
        ReactGA.event(
            {
                category,
                action,
                label,
            },
            ['main']
        );
    }

    @action analyticsPageView(pagePath) {
        //console.log("analyticsPageView", pagePath)
        ReactGA.pageview(pagePath);
    }

    s;

    @action postAffTrackerClick() {
        window.PostAffTracker && window.PostAffTracker.track();
    }

    @action postAffTrackerSale(amount, subscriptionId, planId) {
        if (!window.PostAffTracker) return;
        let sale = PostAffTracker.createSale();
        sale.setTotalCost(amount);
        sale.setOrderID(subscriptionId);
        sale.setProductID(planId);

        PostAffTracker.register();
    }

    @computed get componentsVersion() {
        return version;
    }
}

export default CommonStore;
