import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { observer } from 'mobx-react';
import { ThemedButton, ThemedText } from '../../themed/ThemedComponents';
import { formatTime } from '../../../utils/helpers';
import ThemedCalendarSVG from '../../themed/ThemedCalendarSVG';
import ThemedReminderSVG from '../../themed/ThemedReminderSVG';
import moment from 'moment';

const Utils = require('../../../utils/helpers');

function Result({
    result,
    onRequestClose,
    hideFooter = false,
    hideHeader = false,
    reminders_steps,
    reminders_enabled,
    scheduleType,
}) {
    let date = new Date();

    if (result) {
        return (
            <div>
                {!hideHeader && (
                    <div className={'modalHeader'}>
                        <h3>Delivery schedule</h3>
                        <img
                            className={'modal-close-right'}
                            src="/static/img/close.svg"
                            alt="Close"
                            onClick={onRequestClose}
                        />
                    </div>
                )}
                <div className={'modalContent'}>
                    {scheduleType === 0 ? (
                        result.map((event, i) => (
                            <div className="block schedule-preview-row" key={i}>
                                <h3>
                                    <ThemedCalendarSVG />
                                    <ThemedText
                                        style={{
                                            paddingLeft: '10px',
                                            fontSize: '22px',
                                        }}
                                    >
                                        <strong>
                                            {Utils.formatDate(event.date)}
                                        </strong>
                                    </ThemedText>
                                </h3>
                                <Row>
                                    <Col>
                                        {event.subjects.map((subject, a) => {
                                            return (
                                                <div
                                                    className="scheduleResult results"
                                                    key={a}
                                                >
                                                    <div className="title">
                                                        <span>
                                                            {subject.title}
                                                        </span>
                                                        {subject.duration !=
                                                            null && (
                                                            <span
                                                                style={{
                                                                    marginLeft:
                                                                        '10px',
                                                                    fontSize:
                                                                        '12px',
                                                                }}
                                                            >
                                                                (
                                                                {formatTime(
                                                                    subject.duration
                                                                )}
                                                                )
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </Col>
                                </Row>
                                {reminders_enabled &&
                                reminders_steps.length > 0 ? (
                                    <Fragment>
                                        <h5
                                            style={{
                                                paddingLeft: '0px',
                                                margin: '10px 0',
                                            }}
                                        >
                                            <ThemedReminderSVG />
                                            <ThemedText
                                                style={{
                                                    paddingLeft: '10px',
                                                    fontSize: '18px',
                                                }}
                                            >
                                                <strong>Reminders:</strong>
                                            </ThemedText>
                                        </h5>
                                        <Row>
                                            <Col>
                                                <div className="title">
                                                    {reminders_steps.map(
                                                        (reminder, j) => (
                                                            <div
                                                                className="scheduleResult results"
                                                                key={j}
                                                            >
                                                                {moment(
                                                                    event.date
                                                                )
                                                                    // if time delayed by days and set specifically, set sendTime as hours, else, reminder will be sent at same time of day as trainings are
                                                                    .set(
                                                                        'hour',
                                                                        reminder.delayType ===
                                                                            3 &&
                                                                            reminder.timeType ===
                                                                                1
                                                                            ? reminder.sendTime
                                                                            : moment(
                                                                                  event.date
                                                                              ).hours()
                                                                    )
                                                                    // add specific units to time of training
                                                                    .add(
                                                                        reminder.delay,
                                                                        reminder.delayType ===
                                                                            3
                                                                            ? 'days'
                                                                            : reminder.delayType ===
                                                                              2
                                                                            ? 'hours'
                                                                            : 'minutes'
                                                                    )
                                                                    .format(
                                                                        'MMMM D, H:mm [GMT]'
                                                                    )}
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                ) : (
                                    ''
                                )}
                            </div>
                        ))
                    ) : (
                        <div className="block schedule-preview-row">
                            <h3>
                                <ThemedCalendarSVG />
                                <ThemedText
                                    style={{
                                        paddingLeft: '10px',
                                        fontSize: '22px',
                                    }}
                                >
                                    <strong>{Utils.formatDate(date)}</strong>
                                </ThemedText>
                            </h3>
                            {reminders_enabled && reminders_steps.length > 0 ? (
                                <Fragment>
                                    <h5
                                        style={{
                                            paddingLeft: '0px',
                                            margin: '10px 0',
                                        }}
                                    >
                                        <ThemedReminderSVG />
                                        <ThemedText
                                            style={{
                                                paddingLeft: '10px',
                                                fontSize: '18px',
                                            }}
                                        >
                                            <strong>Reminders:</strong>
                                        </ThemedText>
                                    </h5>
                                    <Row>
                                        <Col>
                                            <div className="title">
                                                {reminders_steps.map(
                                                    (reminder, j) => (
                                                        <div
                                                            className="scheduleResult results"
                                                            key={j}
                                                        >
                                                            {moment(date)
                                                                .add(
                                                                    reminder.delay,
                                                                    reminder.delayType ===
                                                                        3
                                                                        ? 'days'
                                                                        : reminder.delayType ===
                                                                          2
                                                                        ? 'hours'
                                                                        : 'minutes'
                                                                )
                                                                .format(
                                                                    'MMMM D'
                                                                )}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                </Fragment>
                            ) : (
                                ''
                            )}
                        </div>
                    )}
                </div>
                {!hideFooter && (
                    <div className="modalFooter">
                        <ThemedButton secondary onClick={onRequestClose}>
                            OK
                        </ThemedButton>
                    </div>
                )}
            </div>
        );
    } else {
        return null;
    }
}

export default observer(Result);
