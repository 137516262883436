import React from 'react';
import './StatusBadge.scss';
import { Badge } from 'reactstrap';

function StatusBadge({ color, text }) {
    return (
        <Badge color={color} pill className="status-badge">
            {text}
        </Badge>
    );
}

export default StatusBadge;
