import { action, computed, observable } from 'mobx';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import keyBy from 'lodash/keyBy';

import services from '../services';
import { extractErrorMessage } from '../utils/helpers';

class CatalogStore {
    @observable catalog = observable([]);
    @observable saving = false;
    @observable removingNotes = observable([]);
    @observable loadingCatalog = false;
    @observable error = null;

    @action
    async loadCatalog(companyId) {
        if (this.loadingCatalog) return;
        this.loadingCatalog = true;
        this.error = null;
        try {
            const result = await services.Companies.categoryService(
                companyId
            ).list();
            this.catalog.replace(result);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingCatalog = false;
        }
    }

    @action
    async saveNote(companyId, subjectId, editNoteId, content, subject) {
        this.saving = true;

        try {
            let result;
            if (editNoteId > -1) {
                await services.Companies.subjectsService(companyId)
                    .notesService(subjectId)
                    .update({ content }, editNoteId);
                if (subject) {
                    let note = subject.notes.find((x) => x.id === editNoteId);
                    if (note) note.content = content;
                }
                return true;
            } else {
                result = await services.Companies.subjectsService(companyId)
                    .notesService(subjectId)
                    .create({ content });
                if (subject) {
                    subject.notes.push(result);
                }
                return true;
            }
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.saving = false;
        }
    }

    @action
    async deleteNote(companyId, subjectId, id, subject) {
        this.removingNotes.push(id);
        this.error = null;
        try {
            await services.Companies.subjectsService(companyId)
                .notesService(subjectId)
                .delete(id);
            if (subject) {
                subject.notes = subject.notes.filter((x) => x.id !== id);
            }
            return true;
        } catch (e) {
            console.error(e.stack);
            this.error = extractErrorMessage(e);
        } finally {
            this.removingNotes.remove(id);
        }
    }

    @computed get allSubjectsIndex() {
        return keyBy(
            uniqBy(
                flatten(
                    this.catalog.map((c) => {
                        return c.subjects.map((s) => {
                            return Object.assign({ categoryId: c.id }, s);
                        });
                    })
                ),
                (s) => s.id
            ),
            'id'
        );
    }
}

export default CatalogStore;
