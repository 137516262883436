import React from 'react';
import { Form, FormGroup, Label, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ThemedSpinner from '../themed/ThemedSpinner';

@observer
class AddFreemium extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: '',
            codeError: '',
            validity: 18250,
            users: 10000,
            error: '',
            limit: null,
        };
    }

    changeCode(e) {
        this.setState({ code: e.target.value, error: '' });

        if (this.state.code.length < 3) {
            // too short
            this.setState({
                codeError: 'Name needs to be at least 4 characters.',
            });
        } else {
            this.setState({ codeError: '' });
        }
    }

    changeNumber(field, e) {
        let value = Number(e.target.value);
        if (isNaN(value)) return;
        this.setState({ [field]: value });
    }

    save = () => {
        if (!this.state.codeError)
            this.props.addFreemium(
                this.state.code,
                this.state.validity,
                this.state.limit,
                this.state.users
            );
    };

    render() {
        return (
            <div>
                <h4>Add new Freemium</h4>
                <Form className="form-inline">
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label for="code" className="mr-sm-2">
                            Name
                        </Label>
                        <input
                            type="text"
                            className="bigInput"
                            name="code"
                            value={this.state.code}
                            onChange={(e) => this.changeCode(e)}
                            placeholder="Freemium code"
                        />
                        <div className="error">{this.state.codeError}</div>
                    </FormGroup>

                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label for="code" className="mr-sm-2">
                            Registrations Limit
                        </Label>
                        <input
                            type="number"
                            className="bigInput"
                            name="code"
                            value={this.state.limit}
                            onChange={(e) => this.changeNumber('limit', e)}
                            placeholder="Registrations Limit"
                        />
                        <p>Leave empty or use 0 for unlimited </p>
                    </FormGroup>
                    <br />

                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label for="code" className="mr-sm-2">
                            Validity ( days )
                        </Label>
                        <input
                            type="number"
                            className="bigInput"
                            name="code"
                            value={this.state.validity}
                            onChange={(e) => this.changeNumber('validity', e)}
                            placeholder="days"
                        />
                    </FormGroup>

                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label for="code" className="mr-sm-2">
                            User licenses in freemium account
                        </Label>
                        <input
                            type="number"
                            className="bigInput"
                            name="code"
                            value={this.state.users}
                            onChange={(e) => this.changeNumber('users', e)}
                            placeholder="users"
                        />
                    </FormGroup>
                    {this.props.addingFreemium ? (
                        <ThemedSpinner size={45} />
                    ) : (
                        <Button className="btn btn-primary" onClick={this.save}>
                            Add
                        </Button>
                    )}
                </Form>

                {this.state.error && (
                    <div className="danger">
                        <strong>{this.state.error}</strong>
                    </div>
                )}
            </div>
        );
    }
}

AddFreemium.propTypes = {
    addFreemium: PropTypes.func,
};

export default AddFreemium;
