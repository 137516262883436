import { action, computed, observable } from 'mobx';
import keyBy from 'lodash/keyBy';
import services from '../services';

class PPVStore {
    @observable catalog = [];
    @observable stat = [];
    @observable loadingCatalog = false;
    @observable loadingPPVStats = false;
    @observable categoryFilter = 'all';
    @observable nameFilter = '';

    @action
    setCategoryFilter(value) {
        this.categoryFilter = value;
    }

    @action
    setNameFilter(value) {
        this.nameFilter = value;
    }

    @action
    async loadCatalog(companyId) {
        if (this.loadingCatalog) return;

        this.loadingCatalog = true;
        try {
            let res = await services.Companies.subjectsService(companyId).list({
                ppv: true,
            });
            this.catalog.replace(res);
        } finally {
            this.loadingCatalog = false;
        }
    }

    @action
    async loadPPVStats(companyId) {
        if (this.loadingPPVStats) return;

        this.loadingPPVStats = true;
        try {
            let res = await services.Companies.PPVService(companyId).status();
            this.stat.replace(res);
        } finally {
            this.loadingPPVStats = false;
        }
    }

    @action
    async toggleSnippet(companyId, subjectId, state) {
        if (this.togglingSnippet) return;
        this.togglingSnippet = true;
        try {
            let res = await services.Companies.PPVService(companyId).toggle(
                subjectId,
                state
            );
            let subject = this.stat.find(
                (x) => x.subject_id === Number(res.subject_id)
            );

            if (subject) {
                subject.enabled = res.enabled;
            } else {
            }
        } finally {
            this.togglingSnippet = false;
        }
    }

    @computed get categories() {
        if (this.loadingCatalog || this.loadingPPVStats) return [];
        return Object.keys(keyBy(this.catalog, 'category'));
    }

    @computed get summary() {
        if (this.loadingCatalog || this.loadingPPVStats) return [];
        let result = [];

        try {
            this.catalog.forEach((s) => {
                if (
                    this.categoryFilter !== 'all' &&
                    s.category !== this.categoryFilter
                )
                    return;
                if (
                    this.nameFilter &&
                    s.title.indexOf(this.nameFilter) === -1 &&
                    s.description.indexOf(this.nameFilter) === -1
                )
                    return;

                let r = this.stat.find((x) => x.subject_id === s.subject_id);
                let existing = result.find(
                    (x) => x.subject_id === s.subject_id
                );
                if (existing) {
                    existing.categories.push(s.category);
                } else
                    result.push({
                        ...s,
                        views: r && r.views,
                        status: r && r.enabled,
                        categories: [s.category],
                    });
            });
        } catch (e) {}

        return result;
    }
}

export default PPVStore;
