import React from 'react';
import { Table, Tr, Td, Th, Thead } from 'reactable';
import { ThemedLinkLink } from '../../themed/ThemedComponents';
import ProgressWithPercentage from '../../ProgressWithPercentage';

export default ({ lists }) => (
    <div className="manager-user-table">
        <h3>Userlist progress</h3>
        <Table
            className="table"
            id="table"
            sortable={[
                'list',
                'Users',
                {
                    column: 'Progress',
                    sortFunction: function (a, b) {
                        //Get percentage value from react element
                        const aValue = a.props.value;
                        const bValue = b.props.value;

                        return aValue - bValue;
                    },
                },
            ]}
        >
            <Thead>
                <Th column="list" className="">
                    {' '}
                    List name
                </Th>
                <Th column="Users" className="">
                    No. of employees
                </Th>
                <Th column="Progress" className="width50p">
                    Average score
                </Th>
            </Thead>
            {lists.length > 0 &&
                lists.map((list, i) => (
                    <Tr key={i}>
                        <Td
                            column={'list'}
                            value={list.name}
                            className="list-name-column"
                        >
                            {list && list.list_user_id ? (
                                <ThemedLinkLink
                                    to={`/users/list/${list.list_user_id}`}
                                >
                                    {list.name}
                                </ThemedLinkLink>
                            ) : (
                                list.name
                            )}
                        </Td>
                        <Td
                            column={'Users'}
                            value={list.campaign_list_user_count}
                            className="subject-title"
                        >
                            {list.list_user_count}
                        </Td>
                        <Td
                            column="Progress"
                            value={list.score}
                            data={
                                <ProgressWithPercentage
                                    value={
                                        list.total
                                            ? Math.round(
                                                  (100 * list.score) /
                                                      list.total
                                              )
                                            : 0
                                    }
                                    big="true"
                                    thin="true"
                                />
                            }
                        ></Td>
                    </Tr>
                ))}
        </Table>
    </div>
);
