import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import CustomizeAppearance from '../../components/company/customize-appearance';

function CompanyAppearance() {
    const { store } = useStores();

    const { currentCompany } = store;

    const saveColor = async (primaryColor) => {
        await store.saveCurrentCompany({
            ...{ primaryColor },
        });
    };

    const resetToDefaults = async () => {
        await store.saveCurrentCompany({
            ...{
                primaryColor: null,
                logoName: null,
            },
        });
    };

    const importLogo = async formData => {
        await store.uploadCurrentCompanyLogo(formData);
    };

    const removeLogo = async () => {
        await store.removeCurrentCompanyLogo();
    };

    return (
        <CustomizeAppearance
            company={currentCompany}
            saveColor={saveColor}
            resetToDefaults={resetToDefaults}
            importLogo={importLogo}
            removeLogo={removeLogo}
            uploadingLogo={store.uploadingCurrentCompanyLogo}
        />
    );
}

export default observer(CompanyAppearance);
