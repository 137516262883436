import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import ThemedSpinner from '../themed/ThemedSpinner';
import { observer } from 'mobx-react';

@observer
class SettingsTable extends React.Component {
    render() {
        const { removingSettings, settings, onChangeSettingValue } = this.props;

        if (!settings) {
            return null;
        } else {
            if (settings.length > 0) {
                return (
                    <table className="simple-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {settings.map((s) => (
                                <tr key={s.id}>
                                    <td>{s.name} </td>
                                    <td>
                                        <input
                                            value={s.value}
                                            onChange={(e) => {
                                                onChangeSettingValue(
                                                    s.id,
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </td>

                                    <td className="text-center">
                                        {removingSettings.includes(s.id) ? (
                                            <ThemedSpinner size={25} />
                                        ) : (
                                            <span
                                                className="delete-link"
                                                onClick={() =>
                                                    this.props.removeSetting(
                                                        s.id
                                                    )
                                                }
                                            >
                                                <Button
                                                    color="danger"
                                                    size="sm"
                                                >
                                                    x
                                                </Button>
                                            </span>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                );
            } else {
                return null;
            }
        }
    }
}

SettingsTable.propTypes = {
    removeSetting: PropTypes.func,
};

export default SettingsTable;
