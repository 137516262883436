import styled, { css } from 'styled-components';
import { colors } from './colors';

const Text = styled.span`
    font-weight: 400;
    color: ${colors.heavyMetal};
    padding: 0;
    margin: 0;

    ${(props) =>
        props.bold &&
        css`
            font-weight: 700;
        `}
    ${(props) =>
        props.extraBold &&
        css`
            font-weight: 800;
        `}

    ${(props) =>
        props.underline &&
        css`
            text-decoration: underline;
        `}
    
    ${(props) =>
        props.lighter &&
        css`
            opacity: 0.6;
        `}

    ${(props) =>
        props.color &&
        css`
            color: ${(props) => props.color};
        `}
    
    ${(props) =>
        props.themed &&
        css`
            color: ${(props) => props.theme.primary};
        `}
    
    ${(props) =>
        props.error &&
        css`
            color: ${colors.error};
        `}
    
    ${(props) =>
        props.flex &&
        css`
            display: flex;
        `}
    ${(props) =>
        props.margin &&
        css`
            margin: ${props.margin}px;
        `}
    ${(props) =>
        props.my &&
        css`
            margin-top: ${props.my}px;
            margin-bottom: ${props.my}px;
        `}
    ${(props) =>
        props.mx &&
        css`
            margin-left: ${props.mx}px;
            margin-right: ${props.mx}px;
        `}
    ${(props) =>
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}

    ${(props) =>
        props.mb &&
        css`
            margin-bottom: ${props.mb}px;
        `}
    ${(props) =>
        props.ml &&
        css`
            margin-left: ${props.ml}px;
        `}
    ${(props) =>
        props.mr &&
        css`
            margin-right: ${props.mr}px;
        `}
    
    ${(props) =>
        props.airy &&
        css`
            margin-right: 4px;
        `}

    ${(props) =>
        props.link &&
        css`
            cursor: pointer;
        `}
    ${(props) =>
        props.uppercase &&
        css`
            text-transform: uppercase;
        `}
    ${(props) =>
        props.cutLongText &&
        css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        `}
    ${(props) =>
        props.maxWidth &&
        css`
            max-width: ${props.maxWidth}px;
        `}
    ${(props) =>
        props.wrap &&
        css`
            word-wrap: break-word;
        `}
`;

// Text / Lead
export const TextLead = styled(Text)`
    font-size: 16px;
`;

// Text / Default
export const TextDefault = styled(Text)`
    font-size: 14px;
`;

// Text / Tiny (2nd line, or small widgets)
export const TextTiny = styled(Text)`
    font-size: 13px;
`;

// Text / Label
export const TextLabel = styled(Text)`
    font-size: 11px;
`;
