import { action, observable, computed, reaction } from 'mobx';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import intersection from 'lodash/intersection';
import pick from 'lodash/pick';
import services from '../services';
import Assessment from './models/assessment';
import { extractErrorMessage } from '../utils/helpers';
import { ASSESSMENT_WIZARD_STEPS, STATUS } from '../utils/constants';
import moment from 'moment';
import PersonalizeFormDef from '../forms/personalize';

const activeStatuses = { [STATUS.SCHEDULED]: 1, [STATUS.ONGOING]: 1 };
const completedStatuses = {
    // [STATUS.REVIEW]:1,
    [STATUS.COMPLETED]: 1,
};

class AssessmentStore {
    @observable assessments = observable([]);
    @observable loadingAssessments = false;
    @observable loadingAssessmentsInBackground = false;
    @observable loadingAssessment = false;
    @observable loadingQuestionsReportList = false;
    @observable loadingEmployeesReportList = false;

    @observable slackAvailable = false;
    @observable slackConnected = false;
    @observable teamsAvailable = false;
    @observable teamsConnected = false;
    @observable loadingCompanyData = false;
    @observable templates = observable([]);
    @observable questions = observable([]);
    @observable employeesReportList = observable([]);
    @observable questionsReportList = observable([]);
    @observable allowedSteps = observable([]);
    @observable confirmedSteps = observable([]);
    @observable creatingAssessment = false;
    @observable updatingAssessment = false;
    @observable deletingAssessment = false;
    @observable loadingReminderCounter = false;
    @observable sendingPreview = false;
    @observable reminderCounter = 0;
    @observable lastReminders = observable([]);
    @observable sendError = null;
    @observable assessment = undefined;
    @observable editingAssessment = undefined;
    @observable selectedTemplates = observable([]);
    @observable templateCustomized = false;
    @observable error = '';
    @observable step = ASSESSMENT_WIZARD_STEPS.GOAL;
    @observable searchValue = '';
    @observable areaFilter = [];
    @observable employeeFilter = [];
    @observable startDueDateFilter = null;
    @observable endDueDateFilter = null;
    @observable endDeliveryDateFilter = null;
    @observable startDeliveryDateFilter = null;
    @observable sortBy = 'updated';
    @observable platform = 'all_platforms';

    constructor(listStore, mainStore, commonStore, companyStore) {
        this.listStore = listStore;
        this.mainStore = mainStore;
        this.commonStore = commonStore;
        this.companyStore = companyStore;
    }

    @computed
    get filteredAssessments() {
        return orderBy(this.assessments, [this.sortBy], ['desc']).filter(
            (x) => {
                if (
                    this.areaFilter.length > 0 &&
                    intersection(x._rawData.definition.areas, this.areaFilter)
                        .length === 0
                )
                    return false;
                if (
                    this.employeeFilter.length > 0 &&
                    intersection(
                        x._rawData.definition.userLists,
                        this.employeeFilter
                    ).length === 0
                )
                    return false;
                if (
                    this.searchValue.length > 0 &&
                    x.name !== null &&
                    !x.name
                        .toLowerCase()
                        .includes(this.searchValue.toLowerCase())
                )
                    return false;
                if (
                    this.startDueDateFilter !== null &&
                    this.endDueDateFilter !== null &&
                    !moment(x.due).isBetween(
                        this.startDueDateFilter,
                        this.endDueDateFilter
                    )
                )
                    return false;
                if (
                    this.startDeliveryDateFilter !== null &&
                    this.endDeliveryDateFilter !== null &&
                    !moment(x.deliveryDate).isBetween(
                        this.startDeliveryDateFilter,
                        this.endDeliveryDateFilter
                    )
                )
                    return false;
                return true;
            }
        );
    }

    @computed get allTab() {
        return this.filteredAssessments;
    }

    @computed get activeTab() {
        return this.filteredAssessments.filter((x) => {
            return activeStatuses[x.status];
        });
    }

    @computed get completedTab() {
        return this.filteredAssessments.filter((x) => {
            return completedStatuses[x.status];
        });
    }

    @computed get draftTab() {
        return this.filteredAssessments.filter((x) => {
            return x.status === STATUS.DRAFT;
        });
    }

    @computed get steps() {
        return [
            {
                number: ASSESSMENT_WIZARD_STEPS.GOAL,
                title:
                    this.assessment && this.assessment.status === STATUS.ONGOING
                        ? 'Template'
                        : 'Choose template',
            },
            {
                number: ASSESSMENT_WIZARD_STEPS.CONTENT,
                title: 'Review Questions',
            },
            {
                number: ASSESSMENT_WIZARD_STEPS.PERSONALIZE,
                title: 'Personalize',
            },
            {
                number: ASSESSMENT_WIZARD_STEPS.EMPLOYEES,
                title: 'Select Employees',
            },
            { number: ASSESSMENT_WIZARD_STEPS.SCHEDULE, title: 'Schedule' },
            { number: ASSESSMENT_WIZARD_STEPS.REMINDERS, title: 'Reminders' },
            // , {number: ASSESSMENT_WIZARD_STEPS.INTROMESSAGE, title: "Intro message"}
            { number: ASSESSMENT_WIZARD_STEPS.FINALIZE, title: 'Summary' },
        ];
    }

    @action setAreaFilter(areaFilter) {
        this.areaFilter = areaFilter;
    }

    @action setSearchValue(searchValue) {
        this.searchValue = searchValue;
    }

    @action setEmployeeFilter(employeeFilter) {
        this.employeeFilter = employeeFilter;
    }

    @action setStartDueDateFilter(startDueDateFilter) {
        this.startDueDateFilter = startDueDateFilter;
    }

    @action setEndDueDateFilter(endDueDateFilter) {
        this.endDueDateFilter = endDueDateFilter;
    }

    @action setStartDeliveryDateFilter(startDeliveryDateFilter) {
        this.startDeliveryDateFilter = startDeliveryDateFilter;
    }

    @action setEndDeliveryDateFilter(endDeliveryDateFilter) {
        this.endDeliveryDateFilter = endDeliveryDateFilter;
    }

    @action setSortBy(sortBy) {
        this.sortBy = sortBy;
    }

    // @action setPlatform(platform) {
    //     this.platform = platform;
    // }

    @action setStep(step) {
        this.error = '';
        this.step = step;
        this.commonStore.analyticsEvent(
            'Assessment-wizard',
            'step',
            `step-${step}`
        );
    }

    @action setTemplate(templateId) {
        this.selectedTemplates = [templateId];

        let template = this.templates.find((x) => x.id === templateId);

        if (template) {
            // in case we ever decide to enable selecting multiple templates at the same time
            // if (this.selectedTemplates.includes(templateId)) {
            //     this.selectedTemplates.splice(this.selectedTemplates.indexOf(templateId), 1)
            //     this.assessment.selectedQuestionsIds.replace(difference(this.assessment.selectedQuestionsIds, template.questions))
            // } else {
            this.assessment.templateCustomized = false;
            this.assessment.selectedQuestionsIds.replace(
                template.questions.concat(template.disabledQuestions)
            );
            // }
        }

        if (this.selectedTemplates.length > 0) {
            if (!this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.CONTENT)) {
                this.allowedSteps.push(ASSESSMENT_WIZARD_STEPS.CONTENT);
            }
        } else {
            this.allowedSteps.remove(ASSESSMENT_WIZARD_STEPS.CONTENT);
        }
    }

    @action toggleFinalStep(/*form*/) {
        // if (!this.assessment.introMessageEnabled || (form && !(form.$('message').$value === "<p><br></p>" || form.$('message').$value === ""))) {
        //     if ( !this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.FINALIZE) )
        //         this.allowedSteps.push(ASSESSMENT_WIZARD_STEPS.FINALIZE)
        // } else {
        //     this.allowedSteps.remove(ASSESSMENT_WIZARD_STEPS.FINALIZE)
        // }

        if (
            this.reminderStepValid &&
            this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.REMINDERS)
        ) {
            if (!this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.FINALIZE)) {
                this.allowedSteps.push(ASSESSMENT_WIZARD_STEPS.FINALIZE);
            }
        } else {
            this.allowedSteps.remove(ASSESSMENT_WIZARD_STEPS.FINALIZE);
        }
    }

    @action toggleRemindersStep() {
        if (
            this.assessment.due !== null &&
            (this.assessment.scheduleType === 1 ||
                (this.assessment.scheduleType === 0 &&
                    this.assessment.date !== null &&
                    moment(this.assessment.due).isAfter(
                        this.assessment.date
                    ))) &&
            this.assessment.channel !== 0
        ) {
            if (
                !this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.REMINDERS)
            ) {
                this.allowedSteps.push(ASSESSMENT_WIZARD_STEPS.REMINDERS);
            }
        } else {
            this.allowedSteps.remove(ASSESSMENT_WIZARD_STEPS.REMINDERS);
        }
    }

    @computed get reminderStepValid() {
        if (!this.assessment) return false;

        if (!this.assessment.reminders_enabled) return true;

        let stepsValid = this.assessment.reminders_steps.map(
            (reminder) =>
                reminder.send !== null &&
                (!reminder.alreadySet
                    ? moment(reminder.send).isAfter(
                          this.assessment.scheduleType === 0
                              ? this.assessment.date
                              : moment(),
                          'minute'
                      )
                    : true) &&
                moment(reminder.send).isBefore(this.assessment.due)
        );
        return (
            this.assessment.reminders_steps.length > 0 &&
            stepsValid.every((x) => x) &&
            this.assessment.reminders_channel !== 0
        );
    }

    @action toggleIntroMessageStep() {
        if (this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.REMINDERS)) {
            return this.toggleFinalStep();
        }
        // if (this.reminderStepValid) {
        //     if ( !this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.INTROMESSAGE) ) {
        //         this.allowedSteps.push(ASSESSMENT_WIZARD_STEPS.INTROMESSAGE)
        //     }
        // } else {
        //     this.allowedSteps.remove(ASSESSMENT_WIZARD_STEPS.INTROMESSAGE)
        // }
    }

    @action toggleRecipientsStep(form) {
        if (
            (!this.assessment.personalizeEnabled || (form && form.isValid)) &&
            this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.PERSONALIZE)
        ) {
            if (!this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.EMPLOYEES))
                this.allowedSteps.push(ASSESSMENT_WIZARD_STEPS.EMPLOYEES);
        } else {
            this.assessment.status !== STATUS.ONGOING &&
                this.allowedSteps.remove(ASSESSMENT_WIZARD_STEPS.EMPLOYEES);
        }
    }

    @action togglePersonalizeStep() {
        if (
            this.assessment &&
            this.assessment.selectedQuestionsIds.length > 0
        ) {
            if (
                !this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.PERSONALIZE)
            ) {
                this.allowedSteps.push(ASSESSMENT_WIZARD_STEPS.PERSONALIZE);
                this.personalizeForm = null;
            }
            this.updatePersonalizeForm();
        }

        if (
            !this.assessment ||
            this.assessment.selectedQuestionsIds.length === 0
        ) {
            this.allowedSteps.remove(ASSESSMENT_WIZARD_STEPS.PERSONALIZE);
        }

        if (!this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.EMPLOYEES))
            this.allowedSteps.push(ASSESSMENT_WIZARD_STEPS.EMPLOYEES);
    }

    @action toggleScheduleStep() {
        if (
            this.assessment &&
            this.assessment.selectedUserCount > 0 &&
            !this.allowedSteps.includes(ASSESSMENT_WIZARD_STEPS.SCHEDULE)
        )
            this.allowedSteps.push(ASSESSMENT_WIZARD_STEPS.SCHEDULE);

        if (!this.assessment || this.assessment.selectedUserCount === 0) {
            this.allowedSteps.remove(ASSESSMENT_WIZARD_STEPS.SCHEDULE);
        }
    }

    @action
    async sendPreview(companyId, targetEmail, intro) {
        this.sendingPreview = true;
        this.sendError = '';
        try {
            const data = { ...intro, targetEmail };

            data.previewQuestions = this.assessment.selectedQuestionsIds;
            data.name = this.assessment.name;
            data.due = this.assessment.due;
            data.duration = this.assessment.selectedQuestionsDuration;

            await services.Companies.assessmentsService(companyId).sendPreview(
                data
            );
            this.commonStore.analyticsEvent(
                'Assessment-wizard',
                'preview-sent',
                `company-${companyId}`
            );
            return true;
        } catch (e) {
            this.sendError = extractErrorMessage(e);
        } finally {
            this.sendingPreview = false;
        }
    }

    @action
    async loadRemindersCounter(companyId, assessmentId, reminderOption) {
        if (this.loadingReminderCounter) return;
        this.loadingReminderCounter = true;
        this.reminderCounter = null;
        this.lastReminders.replace([]);
        try {
            let result = await services.Companies.assessmentsService(
                companyId
            ).loadingRemindersCounter(assessmentId, reminderOption);
            this.reminderCounter = result.remindersCount;
            this.lastReminders.replace(result.lastReminders);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingReminderCounter = false;
        }
    }

    @action
    async sendReminders(companyId, assessmentId, reminderOption) {
        try {
            return await services.Companies.assessmentsService(
                companyId
            ).sendReminders(assessmentId, reminderOption);
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    @action
    async prepareWizard(companyId, copyFrom, editId) {
        if (this.loadingCompanyData) return;
        this.loadingCompanyData = true;
        this.error = null;
        this.assessments.clear();
        this.assessment = null;
        this.selectedTemplates = [];
        try {
            let questions = await services.Companies.questionsService(
                companyId
            ).list();
            let templates = await services.Companies.templatesService(
                companyId
            ).list({ type: 'assessment' });
            this.templates.replace(templates);
            this.questions.replace(questions);

            await this.listStore.loadCompanyLists(companyId);
            await this.companyStore.loadIntegrationData(companyId, 'slack');
            await this.companyStore.loadIntegrationData(companyId, 'teams');
            let fromData;

            this.slackConnected =
                this.companyStore.slackDataIndex[companyId] &&
                this.companyStore.slackDataIndex[companyId].connected;
            this.teamsConnected =
                this.companyStore.teamsDataIndex[companyId] &&
                this.companyStore.teamsDataIndex[companyId].connected &&
                this.companyStore.teamsDataIndex[companyId].enabled === '1';

            if (copyFrom) {
                let fromAssessment =
                    await services.Companies.assessmentsService(
                        companyId
                    ).fetch(copyFrom);

                fromData = {
                    ...pick(
                        fromAssessment,
                        'templateIds',
                        'template_customized',
                        'sendOptions.channel',
                        'definition',
                        'sendOptions.scheduleType'
                    ),
                    name: fromAssessment.name + ' Copy',
                    due: null,
                    id: null,
                };

                this.setStep(ASSESSMENT_WIZARD_STEPS.GOAL);
                this.allowedSteps.replace([
                    ASSESSMENT_WIZARD_STEPS.GOAL,
                    ASSESSMENT_WIZARD_STEPS.CONTENT,
                    ASSESSMENT_WIZARD_STEPS.PERSONALIZE,
                    ASSESSMENT_WIZARD_STEPS.EMPLOYEES,
                    ASSESSMENT_WIZARD_STEPS.SCHEDULE,
                    ASSESSMENT_WIZARD_STEPS.REMINDERS,
                    // ASSESSMENT_WIZARD_STEPS.INTROMESSAGE,
                    ASSESSMENT_WIZARD_STEPS.FINALIZE,
                ]);
            } else if (editId) {
                fromData = await services.Companies.assessmentsService(
                    companyId
                ).fetch(editId);

                this.setStep(ASSESSMENT_WIZARD_STEPS.GOAL);
                this.allowedSteps.replace([
                    ASSESSMENT_WIZARD_STEPS.GOAL,
                    ASSESSMENT_WIZARD_STEPS.CONTENT,
                    ASSESSMENT_WIZARD_STEPS.PERSONALIZE,
                    ASSESSMENT_WIZARD_STEPS.EMPLOYEES,
                    ASSESSMENT_WIZARD_STEPS.SCHEDULE,
                    ASSESSMENT_WIZARD_STEPS.REMINDERS,
                    // ASSESSMENT_WIZARD_STEPS.INTROMESSAGE,
                    ASSESSMENT_WIZARD_STEPS.FINALIZE,
                ]);
            } else {
                this.setStep(ASSESSMENT_WIZARD_STEPS.GOAL);
                this.allowedSteps.replace([ASSESSMENT_WIZARD_STEPS.GOAL]);
            }

            this.assessment = new Assessment(
                this.listStore,
                this.questionsIndex,
                fromData,
                companyId
            );
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingCompanyData = false;
        }
    }

    // d = show drafts (boolean), p = page number, n = number of rows per page
    @action
    async loadAssessments(companyId, d, p, n) {
        if (this.loadingAssessments || this.loadingAssessmentsInBackground)
            return;
        if (this.assessments.length > 0)
            this.loadingAssessmentsInBackground = true;
        else this.loadingAssessments = true;
        //this.assessments.clear();
        try {
            //const result = assessmentsJSON
            const result = await services.Companies.assessmentsService(
                companyId
            ).list({ d, p, n });

            this.assessments.replace(
                result.map((x) => new Assessment(null, null, x, companyId))
            );
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingAssessments = false;
            this.loadingAssessmentsInBackground = false;
        }
    }

    @action
    async loadAssessment(companyId, assessmentId) {
        if (this.loadingAssessment) return;
        this.loadingAssessment = true;
        this.error = null;
        try {
            await this.listStore.loadCompanyLists(companyId);

            const result = await services.Companies.assessmentsService(
                companyId
            ).fetch(assessmentId);

            this.commonStore.analyticsEvent(
                'Assessment',
                'load',
                `company-${companyId}-automation-${assessmentId}`
            );
            this.editingAssessment = new Assessment(
                this.listStore,
                this.questionsIndex,
                result,
                companyId
            );
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingAssessment = false;
        }
    }

    @action
    async loadAssessmentEmployeesReport(companyId, assessmentId) {
        if (this.loadingEmployeesReportList) return;
        this.loadingEmployeesReportList = true;
        this.employeesReportList.clear();
        try {
            await this.listStore.loadCompanyLists(companyId);
            const result = await services.Companies.assessmentsService(
                companyId
            ).employeesReportList(assessmentId);
            this.employeesReportList = result;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingEmployeesReportList = false;
        }
    }

    @action
    async loadAssessmentQuestionsReport(companyId, assessmentId) {
        if (this.loadingQuestionsReportList) return;
        this.loadingQuestionsReportList = true;
        this.questionsReportList.clear();
        try {
            await this.listStore.loadCompanyLists(companyId);
            const result = await services.Companies.assessmentsService(
                companyId
            ).questionsReportList(assessmentId);
            this.questionsReportList = result;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingQuestionsReportList = false;
        }
    }

    @action
    async delete(companyId, assessmentId) {
        if (this.deletingAssessment) return;
        this.deletingAssessment = true;
        try {
            await services.Companies.assessmentsService(companyId).delete(
                assessmentId
            );
            this.editingAssessment = null;
            this.assessment = null;
            this.assessments.replace(
                this.assessments.filter((x) => x.id !== assessmentId)
            );
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.deletingAssessment = false;
        }
    }

    @action
    async startAssessment(companyId, extraData) {
        if (this.creatingAssessment) return;
        this.creatingAssessment = true;

        try {
            let result;
            if (this.assessment.id) {
                result = await services.Companies.assessmentsService(
                    companyId
                ).update(
                    this.assessment.toDTO({
                        ...extraData,
                        templateIds: this.selectedTemplates,
                    })
                );
            } else {
                result = await services.Companies.assessmentsService(
                    companyId
                ).create(
                    this.assessment.toDTO({
                        ...extraData,
                        templateIds: this.selectedTemplates,
                    })
                );
            }
            this.commonStore.analyticsEvent(
                'assessment',
                'create',
                `company-${companyId}`
            );
            return result.assessment;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.creatingAssessment = false;
        }
    }

    @action
    async saveDraft(companyId, extraData) {
        if (this.savingDraft) return;
        this.savingDraft = true;

        try {
            if (this.assessment.id) {
                await services.Companies.assessmentsService(
                    companyId
                ).updateDraft(
                    this.assessment.toDTO({
                        ...extraData,
                        templateIds: this.selectedTemplates,
                    })
                );
            } else {
                let result = await services.Companies.assessmentsService(
                    companyId
                ).saveDraft(
                    this.assessment.toDTO({
                        ...extraData,
                        templateIds: this.selectedTemplates,
                    })
                );
                if (result.success) this.assessment.setId(result.assessment.id);
            }

            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingDraft = false;
        }
    }

    @action
    async pauseAssessment(companyId, assessmentId) {
        if (this.updatingAssessment) return;
        this.updatingAssessment = true;

        try {
            await services.Companies.assessmentsService(
                companyId
            ).pauseAssessment(assessmentId);
            let respectiveAssessment = this.assessments.find(
                (x) => x.id === assessmentId
            );
            respectiveAssessment.status = STATUS.DRAFT;
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.updatingAssessment = false;
        }
    }

    // @action
    // async completeAssessment(companyId, assessmentId){
    //     console.log(` completed Assessment`, assessmentId)
    // }

    @action
    updatePersonalizeForm() {
        if (!this.personalizeForm) this.initPersonalizeForm();
        else
            this.personalizeForm.updateFields(
                this.assessment.personalizeData,
                this.assessment.personalizedKeys
            );
    }

    @action
    initPersonalizeForm() {
        let personalizeForm = new PersonalizeFormDef(
            this.assessment.personalizeData,
            this.assessment.personalizedKeys,
            {}
        );

        reaction(
            () => {
                return this.assessment;
            },
            (assessment) => {
                if (assessment) {
                    let data = this.assessment.personalizeData || {};
                    personalizeForm.update(data);
                    personalizeForm.validate();
                }
            }
        );

        this.assessment.personalizedKeys.map((pk) =>
            personalizeForm.observe({
                path: pk,
                key: 'isValid',
                call: () => {
                    this.toggleRecipientsStep(personalizeForm);
                },
            })
        );

        this.personalizeForm = personalizeForm;
    }

    @computed get questionsIndex() {
        return keyBy(this.questions, 'question_id');
    }

    @computed get maxByAreaAndBehaviourForAllQuestions() {
        let maxByAreaAndBehaviour = {};
        this.questions.forEach((q) => {
            if (q.maxScoresByBehaviour) {
                Object.keys(q.maxScoresByBehaviour).forEach((b) => {
                    if (!maxByAreaAndBehaviour[`${q.area}-${b}`])
                        maxByAreaAndBehaviour[`${q.area}-${b}`] = 0;
                    maxByAreaAndBehaviour[`${q.area}-${b}`] +=
                        q.maxScoresByBehaviour[b];
                });
            }
        });
        return maxByAreaAndBehaviour;
    }
}

export default AssessmentStore;
