import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Row } from 'reactstrap';
import { useStores } from '../../hooks/use-stores';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import IntegrationElement from './IntegrationElement';

function IntegrationsSettings({ companyId }) {
    const { companyStore, brandingStore } = useStores();
    const {
        loadingIntegrations,
        connectedIntegrations,
        availableIntegrations,
    } = companyStore;

    useEffect(() => {
        loadData();
    }, [companyId]);

    const loadData = async () => {
        await companyStore.loadIntegrations(companyId);
    };

    return loadingIntegrations ? (
        <ThemedSpinner />
    ) : (
        <div className="top-guide">
            <div className="block new-settings-block">
                <div className="row">
                    <div className="col-md-8 ">
                        <h1>Integrations</h1>
                        <p className="text-thin">
                            Manage integrations with external services and
                            applications
                        </p>
                    </div>
                </div>
                <br />
                {connectedIntegrations.length > 0 && (
                    <Fragment>
                        <h2>Connected apps</h2>
                        <Row>
                            {connectedIntegrations.map((integration) => (
                                <IntegrationElement
                                    integration={integration}
                                    key={integration.id}
                                />
                            ))}
                        </Row>
                        <br />
                        <hr />
                        <br />
                    </Fragment>
                )}
                {availableIntegrations.length > 0 && (
                    <Fragment>
                        <h2>{brandingStore.account_name} Integrations</h2>
                        <Row>
                            {availableIntegrations.map((integration) => (
                                <IntegrationElement
                                    integration={integration}
                                    key={integration.id}
                                    supportEmail={brandingStore.supportEmail}
                                />
                            ))}
                        </Row>
                    </Fragment>
                )}
            </div>
        </div>
    );
}

export default observer(IntegrationsSettings);
