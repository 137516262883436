import { observable, action, reaction } from 'mobx';
import { computed } from 'mobx';
import { backOffDelay, extractErrorMessage } from '../utils/helpers';
import * as Sentry from '@sentry/react';
import services from '../services';

class AuthStore {
    constructor(commonStore, viewerStore, brandingStore) {
        this.viewerStore = viewerStore;
        this.commonStore = commonStore;
        this.brandingStore = brandingStore;
        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem('jwt', token);
                    this.viewerStore.loadServerData();
                } else {
                    window.localStorage.removeItem('jwt');
                    this.viewerStore.logout();
                }
            }
        );

        reaction(
            () => this.currentUser,
            user => {
                if (user) {
                    Sentry.setUser(user);
                    Sentry.addBreadcrumb({
                        category: 'auth',
                        message: 'Authenticated user ' + user.email,
                        level: Sentry.Severity.Info,
                    });
                    this.viewerStore.loadServerData();
                } else {
                    this.viewerStore.logout();
                    this.brandingStore.loadBranding();
                }
            }
        );
    }

    @observable token = window.localStorage.getItem('jwt');
    @observable inProgress = false;
    @observable creatingTrial = false;
    @observable currentUser;

    @observable loginError = undefined;
    @observable emailLoginError = undefined;
    @observable trialError = undefined;
    @observable signupError = undefined;
    @observable profileError = undefined;
    @observable companyError = undefined;
    @observable company_id = undefined;
    @observable campaign_id = undefined;
    @observable subject_id = undefined;
    @observable email = undefined;
    @observable suggestTrial = false;
    @observable extraMessage = false;
    @observable isSignUp = false;

    @computed
    get anonymousTracking() {
        // if ( this.currentUser.super_admin) return false
        return this.viewerStore.currentCompany.anonymousTracking === 1;
    }

    @action setError(error, type = 'login') {
        if (error instanceof Error) {
            error = extractErrorMessage(error);
        }
        this.loginError = type === 'login' ? error : null;
        this.emailLoginError = type === 'emailLogin' ? error : null;
        this.trialError = type === 'trial' ? error : null;
        this.signupError = type === 'signup' ? error : null;
        this.profileError = type === 'profile' ? error : null;
    }

    @action
    async logout() {
        this.inProgress = false;
        this.forgetUser();
        this.setToken(null);
    }

    @action setToken(token) {
        this.token = token;
    }

    @action setEmail(email) {
        this.email = email;
    }

    @computed get displayName() {
        if (!this.currentUser) return '';
        return this.currentUser.name || this.currentUser.email;
    }

    @computed get isAuthenticated() {
        return this.currentUser != null;
    }

    @computed get isPartnerAdmin() {
        return (
            this.currentUser &&
            this.currentUser.superForAccounts &&
            this.currentUser.superForAccounts.length > 0
        );
    }

    /*@action isPartnerAdminOfCompany(companyId) {
    this.currentUser.superForAccounts;
}*/

    @computed get isTeamManager() {
        if (!this.currentUser) return false;
        return this.currentUser.permissions.indexOf('manageUsers') > -1;
    }

    @action setUser(user) {
        this.currentUser = user;
    }

    @action setExtraMessage(message) {
        this.extraMessage = message;
    }

    @action setIsSignUp(boolean) {
        this.isSignUp = boolean;
    }

    @action forgetUser() {
        this.currentUser = undefined;
    }

    @action
    async signin(email, company_id, campaign_id, subject_id, redirect, scope) {
        this.suggestTrial = false;
        this.setIsSignUp(false);
        this.setError(null);
        try {
            let result = await services.Auth.signin(
                email,
                company_id,
                campaign_id,
                subject_id,
                redirect,
                scope
            );
            if (result.url) {
                window.location = result.url;
                return 'redirect';
            }
            if (result.status) return true;
            else {
                this.suggestTrial = true;
            }
        } catch (e) {
            this.setError(e);
        }
    }

    @action
    async resendEmail(email, isSignUp) {
        let result = await this.signin(email);
        if (result) this.commonStore.success('Email was re-sent');
        if (isSignUp) this.setIsSignUp(true)
    }

    @action
    async signinFromEmail(token) {
        this.setError(null);
        try {
            let jwtToken = await services.Auth.emailsignin(token);
            this.setToken(jwtToken);
            this.pullUser();
            return true;
        } catch (e) {
            this.setError(e, 'emailLogin');
        }
    }

    @action
    async registerTrial(data, simple) {
        this.creatingTrial = true;
        try {
            let result = await services.Auth.registerTrial(data, simple);
            // if (result && result.success && result.token) {
            //     this.setToken(result.token);
            //     this.pullUser();
            //     return 'signedin';
            // }
            if (result.login) return 'email';
            return 'registered';
        } catch (ex) {
            this.setError(ex, 'trial');
        } finally {
            this.creatingTrial = false;
        }
    }

    @action
    async registerGdrp(data) {
        this.creatingTrial = true;
        //TODO: FIGURE OUT  error handling in this 2 steps operation. Should it be single request and make both on server?
        // in original version it was hiding error from  registerHubSpot call if registerDB goes good
        try {
            await services.Auth.registerFreemium(data);
            return true;
        } catch (ex) {
            this.setError(ex, 'trial');
        } finally {
            this.creatingTrial = false;
        }
    }

    @action
    async registerPPVTrial(data) {
        this.creatingTrial = true;
        data.ppv = true;
        //TODO: FIGURE OUT  error handling in this 2 steps operation. Should it be single request and make both on server?
        // in original version it was hiding error from  registerHubSpot call if registerDB goes good
        try {
            await services.Auth.registerTrial(data);
            return true;
        } catch (ex) {
            this.setError(ex, 'trial');
        } finally {
            this.creatingTrial = false;
        }
    }

    @action
    async profileUpdate(values) {
        this.setError(null, 'profile');
        try {
            let user = await services.Users.update({
                _id: this.currentUser._id,
                ...values,
            });
            Object.assign(this.currentUser, user);
            this.commonStore.success('Your profile successful updated');
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message,
                'profile'
            );
        }
    }

    @action
    async pullUser(counter = 0) {
        this.inProgress = true;
        try {
            if (this.token) {
                let user = await services.Auth.current();
                if (user) this.setUser(user);
            } else {
                this.logout();
            }
        } catch (e) {
            this.inProgress = false;
            if (
                counter < 10 &&
                e &&
                (!e.response ||
                    e.response.status !== 403 ||
                    e.response.status !== 401)
            ) {
                setTimeout(async () => {
                    await this.pullUser(counter + 1);
                }, backOffDelay(1500, counter));
            } else {
                return this.logout();
            }
        } finally {
            // console.log("done")
            this.inProgress = false;
        }
    }
}

export default AuthStore;
