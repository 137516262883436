import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import { extractErrorMessage, formatDate } from '../../utils/helpers';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

@inject('superStore')
@observer
class CompanyPPVTransactionsView extends React.Component {
    state = { companyId: null, loadedOrError: null };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState) return; // not yet initialized
        if (nextProps.companyId !== prevState.companyId) {
            return {
                companyId: nextProps.companyId,
                loadedOrError: null,
            };
        }

        // No state update necessary
        return null;
    }

    componentDidMount() {
        // It's preferable in most cases to wait until after mounting to load data.
        // See below for a bit more context...
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loadedOrError === null) {
            // At this point, we're in the "commit" phase, so it's safe to load the new data.
            this.loadData();
        }
    }

    async loadData() {
        try {
            this.props.superStore.loadPPVTransactions(this.state.companyId);
            this.setState({ loadedOrError: true });
        } catch (e) {
            this.setState({ loadedOrError: extractErrorMessage(e) });
        }
    }

    render() {
        const { loadingPPVTransactions, ppvTransactions } =
            this.props.superStore;

        return loadingPPVTransactions ? (
            <ThemedSpinner />
        ) : (
            <Fragment>
                <table className="simple-table">
                    <thead>
                        <tr>
                            <th>Created</th>
                            <th>Amount</th>
                            <th>Source</th>
                            <th>Transaction Id</th>
                            <th>Notes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ppvTransactions.map((r) => (
                            <tr key={r.id}>
                                <td>{formatDate(r.created_at)} </td>
                                <td>{r.amount}</td>
                                <td>{r.source}</td>
                                <td>{r.transaction_id}</td>
                                <td>{r.notes}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Fragment>
        );
    }
}

export default withRouter(CompanyPPVTransactionsView);
