import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import moment from 'moment';
import Spinner from '../../components/Spinner';
import ErrorMessage from '../../components/errormessage';
import { extractErrorMessage, formatDate } from '../../utils/helpers';
import SettingsTable from '../../components/super/settings';
import AddSetting from '../../components/super/add-setting';
import AddManager from '../../components/super/add-manager';
import { enforceSlash } from '../../utils/helpers';

@inject('superStore', 'catalogStore', 'commonStore')
@observer
class CompaniesViewSuperContainer extends React.Component {
    state = {
        partnerId: null,
        loadedOrError: null,
        views: 100,
        notes: '',
        subjectsModal: false,
        selectedVideosIds: [],
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState) return; // not yet initialized
        if (nextProps.partnerId !== prevState.partnerId) {
            return {
                partnerId: nextProps.partnerId,
                loadedOrError: null,
            };
        }

        // No state update necessary
        return null;
    }

    constructor(props) {
        super(props);
        this.props.superStore.clearEditingPartner();
    }

    componentDidMount() {
        // It's preferable in most cases to wait until after mounting to load data.
        // See below for a bit more context...
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loadedOrError === null) {
            // At this point, we're in the "commit" phase, so it's safe to load the new data.
            this.loadData();
        }
    }

    async loadData() {
        this.setState({ loadedOrError: false });
        try {
            this.props.superStore.loadPartner(this.state.partnerId);
            this.setState({ loadedOrError: true });
        } catch (e) {
            this.setState({ loadedOrError: extractErrorMessage(e) });
        }
    }

    addSetting = (name, value) => {
        const data = { name, value };
        this.props.superStore.addPartnerSetting(this.state.partnerId, data);
    };

    onChangeSetting = (id, value) => {
        this.props.superStore.updatePartnerSetting(
            this.state.partnerId,
            id,
            value
        );
    };

    removeSetting = (settingId) => {
        this.props.superStore.removePartnerSetting(
            this.state.partnerId,
            settingId
        );
    };

    addManager = (name, email) => {
        this.props.superStore.addPartnerManager(this.state.partnerId, {
            name,
            email,
        });
    };

    onChangeBillingPerson = (user_id) => {
        this.props.superStore.setBillingPerson(this.state.partnerId, {
            user_id,
        });
    };

    removeManager = (email) => {
        this.props.superStore.removePartnerManager(this.state.partnerId, email);
    };

    deactivate = () => {
        this.props.commonStore.showConfirm(
            'By doing that, partner will be DISABLED and Chargebee subscriptions will be CANCELLED',
            'Deactivate',
            'Deactivate',
            async () => {
                this.props.superStore.togglePartner(
                    this.state.partnerId,
                    false
                );
            },
            true
        );
    };

    activate = () => {
        this.props.commonStore.showConfirm(
            'By doing that, partner will be ENABLED and Chargebee subscriptions will be RECREATED.Immediate invoice can be issued also',
            'Activate',
            'Activate',
            async () => {
                this.props.superStore.togglePartner(this.state.partnerId, true);
            },
            false
        );
        
    };

    render() {
        const {
            loadingPartner,
            addingPartnerManager,
            removingPartnerManagers,
            removingPartnerSettings,
            addingPartnerSetting,
            editingPartner: partner,
            error,
        } = this.props.superStore;
        const { match } = this.props;

        const billingManager =
            partner &&
            partner.managers &&
            partner.managers.find((x) => x.billing);

        return loadingPartner ? (
            <Spinner />
        ) : (
            <Fragment>
                <div className="block">
                    <Row className={'super-company-root'}>
                        <Col md="12" xs="12">
                            {partner && (
                                <Fragment>
                                    <div key={partner.id}>
                                        <h2>{partner.name}</h2>
                                        <span
                                            className={
                                                partner.enabled
                                                    ? 'status'
                                                    : 'status expired'
                                            }
                                        >
                                            {partner.enabled
                                                ? 'Active'
                                                : 'Disabled'}
                                        </span>

                                        <div className="d-flex flex-justify-between">
                                            <div className=" flex-column">
                                                {partner.subscription && (
                                                    <div>
                                                        Chargebee Subscription:{' '}
                                                        {
                                                            partner.subscription
                                                                .id
                                                        }{' '}
                                                    </div>
                                                )}
                                                {partner.subscription &&
                                                    !partner.subscription
                                                        .custom && (
                                                        <div>
                                                            Chargebee Customer:{' '}
                                                            {
                                                                partner
                                                                    .subscription
                                                                    .customer_id
                                                            }{' '}
                                                        </div>
                                                    )}
                                                {partner.subscription &&
                                                    !partner.subscription
                                                        .custom && (
                                                        <Fragment>
                                                            {partner
                                                                .subscription
                                                                .haveValidCard
                                                                ? 'Billing info is OK'
                                                                : 'No Billing info'}
                                                            {partner.subscription &&
                                                                partner.enabled && (
                                                                    <div>
                                                                        Next
                                                                        Payment:{' '}
                                                                        {moment
                                                                            .unix(
                                                                                partner
                                                                                    .subscription
                                                                                    .next_billing_at
                                                                            )
                                                                            .format(
                                                                                'LLL'
                                                                            )}
                                                                        ,{' '}
                                                                        {(
                                                                            Math.max(
                                                                                partner
                                                                                    .subscription
                                                                                    .plan_amount +
                                                                                    partner
                                                                                        .subscription
                                                                                        .unbilled_charges -
                                                                                    partner
                                                                                        .subscription
                                                                                        .promotional_credits -
                                                                                    partner
                                                                                        .subscription
                                                                                        .refundable_credits,
                                                                                0
                                                                            ) /
                                                                            100
                                                                        ).toFixed(
                                                                            2
                                                                        ) +
                                                                            'USD'}{' '}
                                                                    </div>
                                                                )}
                                                        </Fragment>
                                                    )}
                                            </div>
                                            {partner.subscription &&
                                                !partner.subscription
                                                    .custom && (
                                                    <div className=" flex-column">
                                                        <div>
                                                            Plan amount(users):{' '}
                                                            {
                                                                partner
                                                                    .subscription
                                                                    .plan_quantity
                                                            }{' '}
                                                        </div>
                                                        <div>
                                                            Plan amount(USD):{' '}
                                                            {(
                                                                partner
                                                                    .subscription
                                                                    .plan_amount /
                                                                100
                                                            ).toFixed(2)}{' '}
                                                        </div>
                                                        <div>
                                                            Unbilled Charges:{' '}
                                                            {(
                                                                partner
                                                                    .subscription
                                                                    .unbilled_charges /
                                                                100
                                                            ).toFixed(2)}{' '}
                                                        </div>
                                                        <div>
                                                            Promotional Credits:{' '}
                                                            {(
                                                                partner
                                                                    .subscription
                                                                    .promotional_credits /
                                                                100
                                                            ).toFixed(2)}{' '}
                                                        </div>
                                                        <div>
                                                            Refundable Credits:{' '}
                                                            {(
                                                                partner
                                                                    .subscription
                                                                    .refundable_credits /
                                                                100
                                                            ).toFixed(2)}{' '}
                                                        </div>
                                                    </div>
                                                )}
                                            <div className="pull-right align-items-start">
                                                {!!partner.enabled && (
                                                    <Button
                                                        onClick={
                                                            this.deactivate
                                                        }
                                                        className="btn"
                                                        color="danger"
                                                    >
                                                        Deactivate
                                                    </Button>
                                                )}
                                                {!partner.enabled && (
                                                    <Button
                                                        onClick={this.activate}
                                                        className="btn"
                                                        color="danger"
                                                    >
                                                        Activate
                                                    </Button>
                                                )}
                                                <Link
                                                    to={`${enforceSlash(
                                                        match.url
                                                    )}companies`}
                                                    className="airy-button"
                                                >
                                                    <div className="btn btn-primary">
                                                        View Companies
                                                    </div>
                                                </Link>
                                                <Link
                                                    to={`${enforceSlash(
                                                        match.url
                                                    )}edit`}
                                                    className="airy-button"
                                                >
                                                    <div className="btn btn-primary">
                                                        Edit
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <h3>Settings</h3>
                                    <div className="table-white-bg">
                                        {partner && partner.settings && (
                                            <SettingsTable
                                                settings={partner.settings}
                                                removeSetting={
                                                    this.removeSetting
                                                }
                                                removingSettings={
                                                    removingPartnerSettings
                                                }
                                                onChangeSettingValue={
                                                    this.onChangeSetting
                                                }
                                            />
                                        )}
                                    </div>
                                    <div className="padded-container">
                                        <AddSetting
                                            saveSetting={this.addSetting}
                                            addingSetting={addingPartnerSetting}
                                        />
                                    </div>

                                    <h3>Super Admins</h3>
                                    <div className="table-white-bg">
                                        <table className="table2">
                                            <thead>
                                                <tr>
                                                    <th>Created</th>
                                                    <th>Email</th>
                                                    <th>Name</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {partner &&
                                                    partner.managers &&
                                                    partner.managers.map(
                                                        (manager) => (
                                                            <tr
                                                                key={
                                                                    manager.rowId
                                                                }
                                                            >
                                                                <td>
                                                                    {formatDate(
                                                                        manager.created
                                                                    )}{' '}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        manager.email
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        manager.name
                                                                    }
                                                                </td>
                                                                <td className="text-center">
                                                                    {removingPartnerManagers.includes(
                                                                        manager.email
                                                                    ) ? (
                                                                        <Spinner
                                                                            size={
                                                                                45
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <span
                                                                            className="delete-link"
                                                                            onClick={() =>
                                                                                this.removeManager(
                                                                                    manager.rowId
                                                                                )
                                                                            }
                                                                        >
                                                                            <Button
                                                                                color="danger"
                                                                                size="sm"
                                                                            >
                                                                                x
                                                                            </Button>
                                                                        </span>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="padded-container">
                                        Billing Contact:
                                        <select
                                            name="packageType"
                                            className="bigInput"
                                            value={
                                                billingManager &&
                                                billingManager.user_id
                                            }
                                            onChange={(e) =>
                                                this.onChangeBillingPerson(
                                                    Number(e.target.value)
                                                )
                                            }
                                        >
                                            {!billingManager && (
                                                <option></option>
                                            )}
                                            {partner &&
                                                partner.managers &&
                                                partner.managers.map(
                                                    (manager) => (
                                                        <option
                                                            key={
                                                                manager.user_id
                                                            }
                                                            value={
                                                                manager.user_id
                                                            }
                                                        >
                                                            {manager.name} &lt;
                                                            {manager.email}&gt;
                                                        </option>
                                                    )
                                                )}
                                        </select>
                                        <AddManager
                                            addingManager={addingPartnerManager}
                                            addManager={this.addManager}
                                        />
                                    </div>
                                </Fragment>
                            )}
                            <ErrorMessage error={error} />
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(CompaniesViewSuperContainer);
