import React, { Fragment } from 'react';
import { Table, Tr, Td, Th, Thead } from 'reactable';
import Clipboard from 'react-clipboard.js';
import { ThemedButton, ThemedLinkLink } from '../../themed/ThemedComponents';
import ProgressWithPercentage from '../../ProgressWithPercentage';

function tableProps(anonymousTracking, searchable, paginated) {
    let result;
    if (anonymousTracking) {
        result = {
            sortable: ['Name', 'Userlist'],
        };
        if (searchable) result.filterable = ['Name'];
        return result;
    } else {
        result = {
            sortable: [
                {
                    column: 'Employee',
                    sortFunction: function (a, b) {
                        // Sort by last name or first name/email if there is no last name
                        const nameSplitA = a.split(' ');
                        const nameSplitB = b.split(' ');
                        // Find the very last name or use the one if there only is one
                        const nameA =
                            nameSplitA.length > 1
                                ? nameSplitA[nameSplitA.length - 1]
                                : a;
                        const nameB =
                            nameSplitB.length > 1
                                ? nameSplitB[nameSplitB.length - 1]
                                : b;

                        return nameA.localeCompare(nameB);
                    },
                },
                {
                    column: 'Userlist',
                    sortFunction: function (a, b) {
                        //Find the list name inside the React element in the td
                        const aListName = a.props.children.props.children;
                        const bListName = b.props.children.props.children;

                        return aListName.localeCompare(bListName);
                    },
                },
                'Progress',
            ],
        };
        if (searchable) result.filterable = ['Employee'];
    }
    if (paginated) {
        result.itemsPerPage = 50;
        result.pageButtonLimit = 5;
    }
    return result;
}

export default ({
    anonymousTracking,
    users,
    training,
    detailsRootUrl,
    mailStatus = false,
    perUserDetails,
    onToggleDetails,
    title = 'Users progress',
    onDeleteUser,
    actionsEnabled = true,
    ssoEnabled = false,
    searchable = true,
    paginated = true /*, reportUsersUrl, perUserDetailsCsvUrl, perUserDetailedDetailsCsvUrl */,
}) => {
    let clipboardEnabled = actionsEnabled && !anonymousTracking;
    let deleteEnabled = actionsEnabled && !anonymousTracking && !ssoEnabled;
    return (
        <div className="manager-user-table ">
            <h3>{title}</h3>
            {actionsEnabled && !anonymousTracking && (
                <div className="section-header">
                    <div className={'left padded'}>
                        <ThemedButton secondary onClick={onToggleDetails}>
                            {perUserDetails ? 'Hide Details' : 'Show Details'}
                        </ThemedButton>
                    </div>
                    {/*<div className="btns-right-align mr10" style={{height: "fit-content"}}>
                            {perUserDetails && reportUsersUrl &&
                            <a href={reportUsersUrl} rel="noopener noreferrer" target="_blank">
                                <ThemedPdfIconSVG/>
                            </a>
                            }
                            {perUserDetails && perUserDetailsCsvUrl &&
                            <a href={perUserDetailsCsvUrl} rel="noopener noreferrer" target="_blank">
                                <ThemedCsvIconSVG/>
                            </a>
                            }
                            {perUserDetails && perUserDetailedDetailsCsvUrl &&
                            <a href={perUserDetailedDetailsCsvUrl} rel="noopener noreferrer" target="_blank">
                                <ThemedCsvIconSVG/>
                            </a>
                            }
                        </div>*/}
                </div>
            )}

            <Table
                className="table"
                id="table"
                {...tableProps(anonymousTracking, searchable, paginated)}
                defaultSort
                filterPlaceholder={searchable && 'Search Employees'}
            >
                <Thead>
                    <Th
                        column={anonymousTracking ? 'Name' : 'Employee'}
                        className="title"
                    />
                    {!anonymousTracking && (
                        <Th column="Userlist" className="">
                            List
                        </Th>
                    )}
                    {perUserDetails &&
                        training._rawData.subjects.map((s) => {
                            return (
                                <Th
                                    key={s.id}
                                    column={`Subject ${s.id}`}
                                    className="center"
                                >
                                    <span className="subject-name">
                                        {s.name}
                                    </span>
                                </Th>
                            );
                        })}

                    <Th column="Progress" className="">
                        Average score
                    </Th>
                    {/* TODO: Remove Copy sign in link and Delete */}
                    {mailStatus && <Th column="Opened" className="" />}
                    {mailStatus && <Th column="Clicked" className="" />}

                    {clipboardEnabled && (
                        <Th column="clipboard" className="">
                            {' '}
                        </Th>
                    )}
                    {deleteEnabled && (
                        <Th column="delete" className="">
                            {' '}
                        </Th>
                    )}
                </Thead>
                {users.length > 0 &&
                    users.map((user, i) => (
                        <Tr key={`user-${user.email}-${user.name}-${i}`}>
                            <Td
                                column={anonymousTracking ? 'Name' : 'Employee'}
                                value={user.email || user.name}
                                className="user-title"
                            >
                                <div>
                                    {user.email ? (
                                        <ThemedLinkLink
                                            to={`${detailsRootUrl}${user.email}`}
                                        >
                                            {user.email}
                                        </ThemedLinkLink>
                                    ) : (
                                        <span>{user.name}</span>
                                    )}
                                </div>
                            </Td>
                            {!anonymousTracking && (
                                <Td column="Userlist">
                                    <div>
                                        {user && user.list_id ? (
                                            <ThemedLinkLink
                                                to={`/users/list/${user.list_id}`}
                                            >
                                                {user.list_name}
                                            </ThemedLinkLink>
                                        ) : (
                                            <span></span>
                                        )}
                                    </div>
                                </Td>
                            )}

                            {perUserDetails &&
                                training._rawData.subjects.map((s) => {
                                    let userData = perUserDetails[user.email];

                                    let viewed =
                                        userData &&
                                        userData.viewed_subject_ids &&
                                        userData.viewed_subject_ids.indexOf(
                                            s.subject_id
                                        ) > -1;
                                    let answered =
                                        userData &&
                                        userData.answered_subject_ids &&
                                        userData.answered_subject_ids.indexOf(
                                            s.subject_id
                                        ) > -1;
                                    return (
                                        <Td
                                            key={s.id}
                                            column={`Subject ${s.id}`}
                                            className="center"
                                        >
                                            <Fragment>
                                                {viewed && !answered && (
                                                    <img
                                                        className="view-mark"
                                                        title={'Viewed'}
                                                        alt="icon"
                                                        src="/static/img/icon-eye.svg"
                                                    />
                                                )}
                                                {viewed && answered && (
                                                    <img
                                                        className="check-mark"
                                                        alt="icon"
                                                        title={
                                                            'Viewed and Answered correctly'
                                                        }
                                                        src="/static/img/icon-checked.svg"
                                                    />
                                                )}
                                                {!viewed && answered && (
                                                    <img
                                                        className="check-mark"
                                                        alt="icon"
                                                        title={
                                                            'Answered correctly'
                                                        }
                                                        src="/static/img/icon-checked-yellow.svg"
                                                    />
                                                )}
                                                {!viewed && !answered && (
                                                    <img
                                                        className="check-mark not-checked"
                                                        alt="icon"
                                                        src="/static/img/icon-not-checked.svg"
                                                    />
                                                )}
                                            </Fragment>
                                        </Td>
                                    );
                                })}

                            <Td
                                column="Progress"
                                value={Math.round(
                                    (100 * user.score) / user.total
                                )}
                            >
                                <ProgressWithPercentage
                                    value={Math.round(
                                        (100 * user.score) / user.total
                                    )}
                                    medium="true"
                                    thin="true"
                                />
                            </Td>
                            {mailStatus && (
                                <Td
                                    column="Opened"
                                    data={
                                        <div className="col">
                                            {user.opened > 0 && (
                                                <img
                                                    alt="checked"
                                                    src="/static/img/icon-checked.svg"
                                                />
                                            )}
                                            {user.opened === 0 && (
                                                <img
                                                    alt="not-checked"
                                                    src="/static/img/icon-not-checked.svg"
                                                    className="not-checked"
                                                />
                                            )}
                                        </div>
                                    }
                                ></Td>
                            )}
                            {mailStatus && (
                                <Td
                                    column="Clicked"
                                    data={
                                        <div className="col">
                                            {user.clicked > 0 && (
                                                <img
                                                    alt="checked"
                                                    src="/static/img/icon-checked.svg"
                                                />
                                            )}
                                            {user.clicked === 0 && (
                                                <img
                                                    alt="not-checked"
                                                    src="/static/img/icon-not-checked.svg"
                                                    className="not-checked"
                                                />
                                            )}
                                        </div>
                                    }
                                ></Td>
                            )}
                            {clipboardEnabled && (
                                <Td
                                    column="clipboard"
                                    value={user.email}
                                    className=""
                                >
                                    {user.email && (
                                        <Clipboard
                                            className="copy-to-clipboard-btn"
                                            data-clipboard-text={
                                                user.access_link
                                            }
                                        >
                                            Copy sign in link
                                        </Clipboard>
                                    )}
                                </Td>
                            )}

                            {deleteEnabled && (
                                <Td column="delete" className="delete">
                                    <div
                                        className=" btn delete-bin"
                                        onClick={(e) => onDeleteUser(user)}
                                    ></div>
                                </Td>
                            )}
                        </Tr>
                    ))}
            </Table>
        </div>
    );
};
