import React from 'react';
import CrossIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    crossIcon: {
        position: 'absolute',
        left: '-3px',
        width: '19px',
        height: '21px',
    },
}));

function CrossIconComponent({ color = 'error' }) {
    const classes = useStyles();

    return <CrossIcon color={color} className={classes.crossIcon} />;
}

export default CrossIconComponent;
