import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import { FlexDiv } from '../../components/design-system/containers';
import { Heading1 } from '../../components/design-system/headings';
import { TextDefault, TextLead } from '../../components/design-system/texts';
import ThemedEmailSentSVG from '../../components/themed/ThemedEmailSentSVG';

function CheckEmail() {
    const { authStore, brandingStore } = useStores();
    const { extraMessage, email, isSignUp } = authStore;
    const { checkEmailUrl } = brandingStore;
    return (
        <FlexDiv justifyCenter alignCenter wrapper>
            <FlexDiv justifyCenter alignCenter column width={464}>
                <FlexDiv mb={48}>
                    <ThemedEmailSentSVG />
                </FlexDiv>
                <Heading1 mb={8}>
                    {extraMessage.length > 0
                        ? extraMessage
                        : 'Email is on its way!'}
                </Heading1>
                <TextLead mb={16}>
                    {email && email.length > 0 && (
                        <Fragment>
                            An email was sent to{' '}
                            <TextLead bold>{email}</TextLead>.{' '}
                        </Fragment>
                    )}
                    Follow the link in the email to access your account.
                </TextLead>
                <FlexDiv mb={16}>
                    <TextDefault mr={4}>Didn’t receive an email? </TextDefault>
                    <TextDefault
                        themed
                        bold
                        link
                        onClick={() => authStore.resendEmail(email, isSignUp)}
                    >
                        Resend it
                    </TextDefault>
                </FlexDiv>
                {isSignUp && (
                    <TextDefault>
                        Still not receiving our emails? Please check your spam
                        folder and{' '}
                        <a
                            href={checkEmailUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <u>read our instructions</u>
                        </a>{' '}
                        on how to prevent this.
                    </TextDefault>
                )}
            </FlexDiv>
        </FlexDiv>
    );
}

export default observer(CheckEmail);
