import { createMuiTheme } from '@material-ui/core/styles';

export const AwareGOTheme = createMuiTheme({
    palette: {
        primary: { main: '#20A26B' },
        secondary: { main: '#CC213D' },
        aspargus: { main: '#20A26B' },
        heavymetal: { main: '#343534' },
    },
    typography: {
        fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    },
    overrides: {
        MuiFormControlLabel: {
            label: {
                fontWeight: '600',
                letterSpacing: '0.04em',
            },
        },
        MuiOutlinedInput: {
            root: {
                marginTop: '4px',
                '&&& $input': {
                    margin: 0,
                    // color: '#828282',
                    paddingLeft: '10px',
                    fontWeight: 'bold',
                    fontSize: '13px',
                    lineHeight: '18px',
                    paddingTop: '10px',
                    paddingBottom: '8px',
                },
            },
        },
        MuiFormHelperText: {
            root: {
                color: '#c4c4c4',
                '&$error': {
                    color: '#BE2024',
                },
            },
        },
        MuiTabs: {
            indicator: {
                backgroundColor: '#c4c4c4',
            },
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: '#20A26B !important',
                color: '#FFFFFF',
            },
            current: {
                backgroundColor: `rgba(32, 162, 107, 0.1) !important`,
                color: '#20A26B',
            },
        },
    },
});
