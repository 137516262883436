import React from 'react';
import { inject, observer } from 'mobx-react';
import { Spinner } from 'reactstrap';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import BaseCompanyDependentContainer from '../../containers/common/BaseCompanyDependentContainer';
import HtmlField from '../../components/inputs/htmlField';
import EmailFormDef from '../../forms/email-settings';
import { fixHtml } from '../../utils/helpers';
import {
    ThemedNav,
    ThemedNavItem,
    ThemedNavLink,
} from '../../components/themed/ThemedComponents';
import { TabContent, TabPane } from 'reactstrap';

@inject('store', 'commonStore', 'companyStore')
@observer
class EmailSettingsContainer extends BaseCompanyDependentContainer {
    constructor(props) {
        super(props);
        this._form = new EmailFormDef(
            {},
            {
                hooks: {
                    onSuccess: async () => {
                        const values = this._form.values();
                        await this.props.companyStore.saveEmailSettings(
                            this.props.companyId,
                            {
                                ...values,
                                trainingHeader: fixHtml(values.trainingHeader),
                                trainingFooter: fixHtml(values.trainingFooter),
                                reminderHeader: fixHtml(values.reminderHeader),
                                reminderFooter: fixHtml(values.reminderFooter),
                                certificate: fixHtml(values.certificate),
                                assessmentHeader: fixHtml(
                                    values.assessmentHeader
                                ),
                                assessmentFooter: fixHtml(
                                    values.assessmentFooter
                                ),
                                mrkdwn_trainingHeader: fixHtml(
                                    values.mrkdwn_trainingHeader
                                ),
                                mrkdwn_trainingFooter: fixHtml(
                                    values.mrkdwn_trainingFooter
                                ),
                                mrkdwn_reminderHeader: fixHtml(
                                    values.mrkdwn_reminderHeader
                                ),
                                mrkdwn_reminderFooter: fixHtml(
                                    values.mrkdwn_reminderFooter
                                ),
                                mrkdwn_certificate: fixHtml(
                                    values.mrkdwn_certificate
                                ),
                                mrkdwn_assessmentHeader: fixHtml(
                                    values.mrkdwn_assessmentHeader
                                ),
                                mrkdwn_assessmentFooter: fixHtml(
                                    values.mrkdwn_assessmentFooter
                                ),
                            }
                        );
                    },
                },
            }
        );
        this.state = {
            activeTab: '1',
        };
    }

    async doLoadData() {
        this._form.update({
            trainingHeader:
                this.props.store.currentCompany.settings[
                    'ui.email.trainingHeader'
                ],
            trainingFooter:
                this.props.store.currentCompany.settings[
                    'ui.email.trainingFooter'
                ],
            reminderHeader:
                this.props.store.currentCompany.settings[
                    'ui.email.reminderHeader'
                ],
            reminderFooter:
                this.props.store.currentCompany.settings[
                    'ui.email.reminderFooter'
                ],
            certificate:
                this.props.store.currentCompany.settings[
                    'ui.email.certificate'
                ],
            assessmentHeader:
                this.props.store.currentCompany.settings[
                    'ui.email.assessmentHeader'
                ],
            assessmentFooter:
                this.props.store.currentCompany.settings[
                    'ui.email.assessmentFooter'
                ],
            mrkdwn_certificate:
                this.props.store.currentCompany.settings[
                    'ui.email.mrkdwn_certificate'
                ],
            mrkdwn_trainingHeader:
                this.props.store.currentCompany.settings[
                    'ui.email.mrkdwn_trainingHeader'
                ],
            mrkdwn_trainingFooter:
                this.props.store.currentCompany.settings[
                    'ui.email.mrkdwn_trainingFooter'
                ],
            mrkdwn_reminderHeader:
                this.props.store.currentCompany.settings[
                    'ui.email.mrkdwn_reminderHeader'
                ],
            mrkdwn_reminderFooter:
                this.props.store.currentCompany.settings[
                    'ui.email.mrkdwn_reminderFooter'
                ],
            mrkdwn_assessmentHeader:
                this.props.store.currentCompany.settings[
                    'ui.email.mrkdwn_assessmentHeader'
                ],
            mrkdwn_assessmentFooter:
                this.props.store.currentCompany.settings[
                    'ui.email.mrkdwn_assessmentFooter'
                ],
        });
    }

    tabsChange(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    render() {
        const { companyId } = this.props;
        const { savingEmailSettings } = this.props.companyStore;
        const { assessmentEnabled } = this.props.store;

        return (
            <div className="top-guide">
                <div className="block new-settings-block">
                    <div className="row">
                        <div className="col-md-8 ">
                            <h3>Email Settings</h3>
                            <p className="text-thin">
                                Set up texts, which should display in training,
                                reminder and certificate e-mails
                            </p>
                        </div>
                    </div>
                    <ThemedNav dashboard>
                        <ThemedNavItem
                            active={
                                this.state.activeTab === '1' ? 'active' : ''
                            }
                        >
                            <ThemedNavLink
                                onClick={() => {
                                    this.tabsChange('1');
                                }}
                            >
                                Trainings
                            </ThemedNavLink>
                        </ThemedNavItem>
                        <ThemedNavItem
                            active={
                                this.state.activeTab === '2' ? 'active' : ''
                            }
                        >
                            <ThemedNavLink
                                onClick={() => {
                                    this.tabsChange('2');
                                }}
                            >
                                Reminders
                            </ThemedNavLink>
                        </ThemedNavItem>
                        <ThemedNavItem
                            active={
                                this.state.activeTab === '3' ? 'active' : ''
                            }
                        >
                            <ThemedNavLink
                                onClick={() => {
                                    this.tabsChange('3');
                                }}
                            >
                                Certificates
                            </ThemedNavLink>
                        </ThemedNavItem>
                        {assessmentEnabled && (
                            <ThemedNavItem
                                active={
                                    this.state.activeTab === '4' ? 'active' : ''
                                }
                            >
                                <ThemedNavLink
                                    onClick={() => {
                                        this.tabsChange('4');
                                    }}
                                >
                                    Assessments
                                </ThemedNavLink>
                            </ThemedNavItem>
                        )}
                    </ThemedNav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <br />
                            <div className="form-group col-md-8">
                                <HtmlField
                                    field={this._form.$('trainingHeader')}
                                    mrkdownField={this._form.$(
                                        'mrkdwn_trainingHeader'
                                    )}
                                    showLabel={true}
                                />
                            </div>
                            <div className="form-group col-md-8">
                                <HtmlField
                                    field={this._form.$('trainingFooter')}
                                    mrkdownField={this._form.$(
                                        'mrkdwn_trainingFooter'
                                    )}
                                    showLabel={true}
                                />
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <br />
                            <div className="form-group col-md-8">
                                <HtmlField
                                    field={this._form.$('reminderHeader')}
                                    mrkdownField={this._form.$(
                                        'mrkdwn_reminderHeader'
                                    )}
                                    showLabel={true}
                                />
                            </div>
                            <div className="form-group col-md-8">
                                <HtmlField
                                    field={this._form.$('reminderFooter')}
                                    mrkdownField={this._form.$(
                                        'mrkdwn_reminderFooter'
                                    )}
                                    showLabel={true}
                                />
                            </div>
                        </TabPane>
                        <TabPane tabId="3">
                            <br />
                            <div className="form-group col-md-8">
                                <HtmlField
                                    field={this._form.$('certificate')}
                                    mrkdownField={this._form.$(
                                        'mrkdwn_certificate'
                                    )}
                                    showLabel={true}
                                />
                            </div>
                        </TabPane>
                        <TabPane tabId="4">
                            <br />
                            <div className="form-group col-md-8">
                                <HtmlField
                                    field={this._form.$('assessmentHeader')}
                                    mrkdownField={this._form.$(
                                        'mrkdwn_assessmentHeader'
                                    )}
                                    showLabel={true}
                                />
                            </div>
                            <div className="form-group col-md-8">
                                <HtmlField
                                    field={this._form.$('assessmentFooter')}
                                    mrkdownField={this._form.$(
                                        'mrkdwn_assessmentFooter'
                                    )}
                                    showLabel={true}
                                />
                            </div>
                        </TabPane>
                    </TabContent>
                    <div className="row">
                        {savingEmailSettings.includes(companyId) ? (
                            <Spinner />
                        ) : (
                            <ThemedButton
                                secondary
                                onClick={this._form.onSubmit}
                                airy
                            >
                                Save
                            </ThemedButton>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default EmailSettingsContainer;
