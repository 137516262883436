import React, { Fragment } from 'react';
import without from 'lodash/without';

import { Link, withRouter } from 'react-router-dom';
import { ButtonGroup, Input } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import BaseCompanyDependentChildContainer from './common/BaseCompanyDependentChildContainer';
import Clipboard from 'react-clipboard.js';
import {
    ThemedButton,
    ThemedRadiobutton,
    ThemedInputLabel,
    ThemedCheckmarkInputContainer,
} from '../components/themed/ThemedComponents';
import sanitizeHtml from 'sanitize-html';

const { REACT_APP_API_URL } = process.env;

// const languages = {
//     'is': { name : "Icelandic", icon:'icelandic'},
//     'es': { name : "Español", icon:'spanish'},
//     'it': { name : "Italiano", icon:'italian'},
//     'fr': { name : "Français", icon:'french'},
//     'de': { name : "Deutsch", icon:'deutsch'},
//     'da': { name : "Dansk", icon:'danish'},
//     'sw': { name : "Svenska", icon:'swedish'},
//     'nn': { name : "Norsk", icon:'norwegian'},
//     'en': { name : "English", icon:'english'},
//     'en-gb': { name : "English - UK", icon:'english-gb'},
// }

@inject('store', 'companyStore', 'ppvStore', 'commonStore')
@observer
class PPVEmbed extends BaseCompanyDependentChildContainer {
    constructor(props) {
        super(props);
        this.state = {
            size: 2,
            location: '',
            lang: 'en',
            showUID: false,
            showLogo: true,
            languagesSelection: this.props.store.languagesCodes,
        };
    }

    async doLoadData() {
        return await Promise.all([
            this.props.ppvStore.loadCatalog(this.props.companyId),
            this.props.ppvStore.loadPPVStats(this.props.companyId),
        ]);
    }

    toggleShowUID = () => {
        this.setState({ showUID: !this.state.showUID });
    };

    toggleShowLogo = () => {
        this.setState({ showLogo: !this.state.showLogo });
    };

    onSizeChange = (value) => {
        this.setState({ size: value });
    };

    setLocation = (e) => {
        this.setState({ location: e.target.value });
    };

    selectLang = (value) => {
        if (this.state.languagesSelection.includes(value))
            this.setState({
                languagesSelection: without(
                    this.state.languagesSelection,
                    value
                ),
            });
        else
            this.setState({
                languagesSelection: [...this.state.languagesSelection, value],
            });
    };

    disableSnippet = () => {
        this.props.ppvStore.toggleSnippet(
            this.props.companyId,
            this.props.match.params.subjectId,
            false
        );
    };

    enableSnippet = () => {
        this.props.ppvStore.toggleSnippet(
            this.props.companyId,
            this.props.match.params.subjectId,
            true
        );
    };

    render() {
        const { languagesSelection } = this.state;
        const languages = this.props.store.languagesIndex;
        const { currentCompany } = this.props.store;
        const { subjectId } = this.props.match.params;
        const { summary } = this.props.ppvStore;
        const subject =
            summary && summary.find((x) => x.subject_id === Number(subjectId));

        let w, h;
        switch (this.state.size) {
            case 1:
                w = 375;
                h = 260;
                break;
            case 3:
                w = 720;
                h = 480;
                break;
            default:
                w = 560;
                h = 375;
                break;
        }
        let embedUrl =
            currentCompany.ppv &&
            `${REACT_APP_API_URL}/embed?lang=${languagesSelection[0] || 'en'}` +
                `&key=${currentCompany.ppv.apiKey}&s=${subjectId}&c=${currentCompany.ppv.company_id}` +
                `&langs=${languagesSelection.join(',')}` +
                `&hideLogo=${this.state.showLogo ? '0' : '1'}` +
                `&location=${sanitizeHtml(this.state.location)}&uid=${
                    sanitizeHtml(this.state.showUID) && 'PUT_YOUR_UID_HERE'
                }`;
        // Send relevant company settings as well
        if (
            currentCompany.settings &&
            currentCompany.settings['ui.preset_caption_language']
        ) {
            embedUrl = `${embedUrl}&presetCaption=${currentCompany.settings['ui.preset_caption_language']}`;
        }

        if (
            currentCompany.settings &&
            currentCompany.settings['ui.captions_settings']
        ) {
            let captionSettingsString = encodeURIComponent(
                currentCompany.settings['ui.captions_settings']
            );
            embedUrl = `${embedUrl}&captionSettings=${captionSettingsString}`;
        }

        const previewEmbedUrl = `${embedUrl}&preview=1`;

        const script =
            `<iframe width="${w}" height="${h}" src="${embedUrl}"` +
            ` frameborder="0"` +
            ` allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"` +
            ` allowfullscreen></iframe>`;

        const previewScript =
            `<iframe width="${w}" height="${h}" src="${previewEmbedUrl}"` +
            ` frameborder="0"` +
            ` allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"` +
            ` allowfullscreen></iframe>`;

        if (!(subject && currentCompany && currentCompany.ppv)) {
            return null;
        }
        return (
            <Fragment>
                <Link to={`/ppv`}>
                    <div className={'navigation back-button'}>
                        Back to videos
                    </div>
                </Link>
                <div className="d-flex">
                    <div className="block block-margin">
                        <div className="flex flex-column settings-block">
                            <h5>Select Player Size</h5>

                            <ButtonGroup className="d-flex flex-column">
                                <label
                                    htmlFor="email"
                                    className="container-radiobutton"
                                    onClick={(e) => {
                                        this.onSizeChange(1);
                                    }}
                                >
                                    <ThemedInputLabel
                                        checked={this.state.size === 1}
                                        className="d-flex flex-fill align-items-center justify-content-between"
                                    >
                                        Small<span></span>320x240
                                    </ThemedInputLabel>
                                    <input
                                        type="radio"
                                        name="active"
                                        id="typeSchedule"
                                        className="styled-radiobutton"
                                        value={1}
                                        checked={this.state.size === 1}
                                        onChange={(e) => {
                                            this.onSizeChange(1);
                                        }}
                                    />
                                    <ThemedRadiobutton
                                        checked={this.state.size === 1}
                                    />
                                </label>
                                <label
                                    htmlFor="email"
                                    className="container-radiobutton"
                                    onClick={(e) => {
                                        this.onSizeChange(2);
                                    }}
                                >
                                    <ThemedInputLabel
                                        checked={this.state.size === 2}
                                        className="d-flex flex-fill align-items-center justify-content-between"
                                    >
                                        Medium<span></span>480x360
                                    </ThemedInputLabel>
                                    <input
                                        type="radio"
                                        name="active"
                                        id="typeSchedule"
                                        className="styled-radiobutton"
                                        value={2}
                                        checked={this.state.size === 2}
                                        onChange={(e) => {
                                            this.onSizeChange(2);
                                        }}
                                    />
                                    <ThemedRadiobutton
                                        checked={this.state.size === 2}
                                    />
                                </label>
                                <label
                                    htmlFor="email"
                                    className="container-radiobutton"
                                    onClick={(e) => {
                                        this.onSizeChange(3);
                                    }}
                                >
                                    <ThemedInputLabel
                                        checked={this.state.size === 3}
                                        className="d-flex flex-fill align-items-center justify-content-between"
                                    >
                                        Big<span></span>720x480
                                    </ThemedInputLabel>
                                    <input
                                        type="radio"
                                        name="active"
                                        id="typeSchedule"
                                        className="styled-radiobutton"
                                        value={3}
                                        checked={this.state.size === 3}
                                        onChange={(e) => {
                                            this.onSizeChange(3);
                                        }}
                                    />
                                    <ThemedRadiobutton
                                        checked={this.state.size === 3}
                                    />
                                </label>
                            </ButtonGroup>
                            <h5>Location</h5>
                            <Input
                                type={'text'}
                                value={this.state.location}
                                onChange={this.setLocation}
                            />
                            <div className={'settings-block'}>
                                <ThemedCheckmarkInputContainer
                                    className={'container-checkbox small'}
                                >
                                    <label htmlFor={'show_uid'}>
                                        <input
                                            type="checkbox"
                                            id={'show_uid'}
                                            checked={this.state.showUID}
                                            onChange={this.toggleShowUID}
                                        />
                                        Add UID param
                                        <span className="checkmark"></span>
                                    </label>
                                </ThemedCheckmarkInputContainer>
                            </div>
                            <div className={'settings-block'}>
                                <ThemedCheckmarkInputContainer
                                    className={'container-checkbox small'}
                                >
                                    <label htmlFor={'show_logo'}>
                                        <input
                                            type="checkbox"
                                            id={'show_logo'}
                                            checked={this.state.showLogo}
                                            onChange={this.toggleShowLogo}
                                        />
                                        Show Logo
                                        <span className="checkmark"></span>
                                    </label>
                                </ThemedCheckmarkInputContainer>
                            </div>

                            <h5> Languages</h5>

                            <div className={'languages settings-block'}>
                                <ul>
                                    {subject.languages
                                        .filter((lang) => languages[lang])
                                        .map((lang, i) => {
                                            return (
                                                <li key={i}>
                                                    <ThemedCheckmarkInputContainer
                                                        className={
                                                            'container-checkbox small'
                                                        }
                                                    >
                                                        <label
                                                            htmlFor={[
                                                                'checkbox-' +
                                                                    i +
                                                                    '-' +
                                                                    lang,
                                                            ]}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                id={[
                                                                    'checkbox-' +
                                                                        i +
                                                                        '-' +
                                                                        lang,
                                                                ]}
                                                                className="checkmark"
                                                                checked={languagesSelection.includes(
                                                                    lang
                                                                )}
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    this.selectLang(
                                                                        lang
                                                                    );
                                                                }}
                                                            />

                                                            <ThemedInputLabel
                                                                checked={languagesSelection.includes(
                                                                    lang
                                                                )}
                                                            >
                                                                {
                                                                    languages[
                                                                        lang
                                                                    ].name
                                                                }
                                                            </ThemedInputLabel>
                                                            <span
                                                                className={
                                                                    'flag ' +
                                                                    languages[
                                                                        lang
                                                                    ].icon
                                                                }
                                                                style={{
                                                                    backgroundImage: `url(${languages[lang].flag_image})`,
                                                                }}
                                                            >
                                                                &nbsp;
                                                            </span>
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </ThemedCheckmarkInputContainer>
                                                </li>
                                            );
                                        })}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="block d-flex flex-fill flex-column">
                        {subject.disabled ? (
                            <Fragment>
                                <h3>This video </h3>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className="d-flex justify-content-end">
                                    {subject.status ? (
                                        <ThemedButton
                                            primary
                                            onClick={this.disableSnippet}
                                        >
                                            Disable Snippet
                                        </ThemedButton>
                                    ) : (
                                        <ThemedButton
                                            primary
                                            onClick={this.enableSnippet}
                                        >
                                            Generate Snippet
                                        </ThemedButton>
                                    )}
                                </div>
                            </Fragment>
                        )}

                        {!subject.disabled && subject.status && (
                            <Fragment>
                                <h3 className="d-flex">iFrame</h3>
                                <div className="embed-code">
                                    <xmp>{script}</xmp>
                                    <Clipboard
                                        className="copy-to-clipboard-link"
                                        data-clipboard-text={script}
                                    >
                                        Copy to clipboard
                                    </Clipboard>
                                </div>
                                <h3 className="d-flex">Link to embed</h3>
                                <div className="embed-code">
                                    <xmp>{embedUrl}</xmp>
                                    <Clipboard
                                        className="copy-to-clipboard-link"
                                        data-clipboard-text={embedUrl}
                                    >
                                        Copy to clipboard
                                    </Clipboard>
                                </div>
                            </Fragment>
                        )}
                        <div
                            className="embed-holder"
                            style={{
                                width: `${w + 40}px`,
                                height: `${h + 40}px`,
                            }}
                        >
                            <div
                                className="embed"
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(previewScript, {
                                        allowedTags:
                                            sanitizeHtml.defaults.allowedTags.concat(
                                                ['iframe']
                                            ),
                                        allowedAttributes: Object.assign(
                                            sanitizeHtml.defaults
                                                .allowedAttributes,
                                            {
                                                iframe: [
                                                    'width',
                                                    'height',
                                                    'src',
                                                    'frameborder',
                                                    'allow',
                                                    'allowfullscreen',
                                                ],
                                            }
                                        ),
                                    }),
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(PPVEmbed);
