import React from 'react';
import { observer } from 'mobx-react';
import { ThemedButton } from '../../themed/ThemedComponents';
import { Link } from 'react-router-dom';

function SaveChanges({
    onRequestClose,
    saveDraft,
    back,
    parentMatch,
    openNameChangeModal,
    isNameUntitled,
    editing,
}) {
    return (
        <div className="modalWindow simple">
            <div className={'modal__header'}>
                <h3 className="modal__heading">
                    Discard without saving changes?
                </h3>
                <img
                    src="/static/img/close.svg"
                    alt="Close"
                    onClick={onRequestClose}
                />
            </div>
            <div className="modal__text">
                You will lose all {editing ? 'changes' : 'entered data'} and we
                will not be able to recover it.
            </div>
            <div className={'modalFooter'}>
                <span
                    className="previous-btn"
                    style={{ marginRight: '20px' }}
                    onClick={() =>
                        isNameUntitled ? openNameChangeModal(true) : saveDraft()
                    }
                >
                    {editing ? 'Save' : 'Save draft'}
                </span>
                <Link to={back || (parentMatch && parentMatch.url)}>
                    <ThemedButton gray>Yes, discard</ThemedButton>
                </Link>
            </div>
        </div>
    );
}

export default observer(SaveChanges);
