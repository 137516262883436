import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { SpaceBetweenDiv, FlexDiv, Image } from './containers';
import { Heading1 } from './headings';

const StyledModal = styled(Modal)`
    background-color: white;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    width: 900px;
    max-width: 90%;
    max-height: 70vh;
`;

export function BigModal({
    heading,
    modalContent,
    modalFooterContent,
    isOpen,
    onRequestClose,
    extraHeaderContent,
    filterContent,
}) {
    return (
        <StyledModal isOpen={isOpen} onRequestClose={onRequestClose}>
            <SpaceBetweenDiv px={48} pt={24} alignStart>
                {filterContent ? (
                    <FlexDiv alignCenter>
                        <Heading1 mr={24}>{heading}</Heading1>
                        {filterContent}
                    </FlexDiv>
                ) : (
                    <FlexDiv column>
                        <Heading1>{heading}</Heading1>
                        {extraHeaderContent}
                    </FlexDiv>
                )}

                <Image
                    src="/static/img/close.svg"
                    alt="Close"
                    onClick={onRequestClose}
                    link
                />
            </SpaceBetweenDiv>
            <FlexDiv column py={32} px={48} overflowYauto noWrap>
                {modalContent}
            </FlexDiv>
            <FlexDiv
                maxHeight={96}
                px={48}
                py={24}
                dropShadowAbove
                justifyEnd
                alignCenter
            >
                {modalFooterContent}
            </FlexDiv>
        </StyledModal>
    );
}
