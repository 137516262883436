import RestService from './RestService';

export default class Integrations extends RestService {
    constructor(parentService, companyId) {
        if (parentService && companyId)
            super(`${parentService.baseUrl}/${companyId}/integration`);
        else throw new Error('company is required');
    }

    integrationGroups(integration) {
        return this.get(`${this.baseUrl}/${integration}/groups`);
    }

    pullStatus(integration, latestRunningSyncId) {
        return this.get(
            `${this.baseUrl}/${integration}/status/${latestRunningSyncId}`
        );
    }

    saveIntegrationRule(integration, data) {
        return this.post(`${this.baseUrl}/${integration}/rule`, data);
    }

    saveIntegrationRules(integration, data) {
        return this.post(`${this.baseUrl}/${integration}/rules`, data);
    }

    testIntegrationData(integration, data) {
        return this.post(`${this.baseUrl}/${integration}/test`, data);
    }

    syncIntegration(integration, data) {
        return this.post(`${this.baseUrl}/${integration}/sync`, data);
    }
}
