import React, { useEffect, useState, Fragment } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import { Row, Col } from 'reactstrap';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import AutomationListTable from '../../components/manager/automation/automation-list-table';
import FilterIcon from '@material-ui/icons/FilterList';
import Modal from 'react-modal';
import FilterModal from '../../components/manager/filter-modal';
import { Link, useHistory } from 'react-router-dom';
import {
    ThemedButton,
    ThemedNav,
    ThemedNavItem,
    ThemedNavLink,
} from '../../components/themed/ThemedComponents';
import { MenuItem } from '@material-ui/core';
import pluralize from 'pluralize';
import CancelIcon from '@material-ui/icons/Cancel';
import Snackbar from '../../components/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import './AutomationList.scss';
import ErrorMessage from '../../components/errormessage';
import NoItemsYet from '../../components/manager/no-items-yet';
import {
    Block,
    FlexDiv,
    SpaceBetweenDiv,
} from '../../components/design-system/containers';
import { Heading2 } from '../../components/design-system/headings';
import { TextTiny, TextDefault } from '../../components/design-system/texts';
import { SearchInput, TextInput } from '../../components/design-system/inputs';
import { FullWidthLink } from '../../components/design-system/miscellaneous';

function AutomationList({ companyId, parentMatch, tab = 'all' }) {
    const {
        automationStore,
        listStore,
        commonStore,
        catalogStore,
        store,
        brandingStore,
    } = useStores();
    const {
        loadingAutomations,
        automations,
        dismissedFirstTraining,
        loadingAutomationsInBackground,
        searchValue,
        subjectFilter,
        employeeFilter,
        sortBy,
        filteredAutomations,
        fromStartDateFilter,
        toStartDateFilter,
        fromEndDateFilter,
        toEndDateFilter,
        error,
    } = automationStore;
    const { allSubjectsIndex } = catalogStore;
    const { trainingProgramsUnavailable, trainingUpgradeAllowed } = store;
    const { supportEmail } = brandingStore;
    const [filterModal, setFilterModal] = useState(false);
    const { listsWithUsers: lists } = listStore;

    const [message, setMesage] = useState(null);
    const [messageShown, setMessageShown] = useState(false);
    const [messageType, setMessageType] = useState(null);
    let history = useHistory();

    useEffect(() => {
        automationStore.loadAutomations(companyId);
        listStore.loadCompanyLists(companyId);
        catalogStore.loadCatalog(companyId);
    }, [companyId]);

    const filterCount = [
        subjectFilter.length ? 1 : 0,
        employeeFilter.length ? 1 : 0,
        fromStartDateFilter !== null && toStartDateFilter !== null ? 1 : 0,
        fromEndDateFilter !== null && toEndDateFilter !== null ? 1 : 0,
    ].reduce((a, b) => a + b);

    const clearFilters = () => {
        automationStore.setSubjectFilter([]);
        automationStore.setEmployeeFilter([]);
        automationStore.setFromEndDateFilter(null);
        automationStore.setToEndDateFilter(null);
        automationStore.setFromStartDateFilter(null);
        automationStore.setToStartDateFilter(null);
    };

    const hideFilterModal = () => {
        setFilterModal(false);
    };

    const showFilterModal = () => {
        setFilterModal(true);
    };

    const showSnackBar = (message, messageType = 'success') => {
        setMesage(message);
        setMessageType(messageType);
        setMessageShown(true);
    };

    const closeSnackbar = () => {
        setMessageShown(false);
        setMesage(null);
        setMessageType(null);
    };

    const onDelete = (id) => {
        commonStore.showConfirm(
            'Training will be deleted forever.',
            'Yes, delete',
            'Delete this training?',
            async () => {
                await automationStore.delete(companyId, id);
                showSnackBar('Training deleted!');
            },
            true
        );
    };

    const onPause = (id) => {
        commonStore.showConfirm(
            'Are you sure you want to stop this training?',
            'Stop',
            'Stop training',
            async () => {
                await automationStore.pauseAutomation(companyId, id);
                showSnackBar('Training paused!');
            },
            true
        );
    };

    const tabs = [
        {
            code: 'all',
            title: 'All',
        },
        {
            code: 'active',
            title: 'Active',
        },
        {
            code: 'completed',
            title: 'Completed',
        },
        {
            code: 'draft',
            title: 'Draft',
        },
    ];

    const sortOptions = [
        {
            value: 'updated',
            text: 'Last updated',
        },
        {
            value: '_rawData.firstBatch',
            text: 'Start date',
        },
        {
            value: '_rawData.lastBatch',
            text: 'End date',
        },
    ];

    const needsUpgrade = trainingUpgradeAllowed;

    if (trainingProgramsUnavailable) {
        return (
            <NoItemsYet
                img={'/static/img/training-locked.svg'}
                imgAlt={'training programs are unavailable'}
                title={'Training programs are unavailable in your current plan'}
                text={
                    'Please upgrade your subscription to access the training programs.'
                }
                heading={'Training programs'}
                buttons={
                    needsUpgrade ? (
                        <FlexDiv>
                            <a
                                href={
                                    'https://awarego.com/security-awareness-training/'
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                <ThemedButton secondary airy>
                                    Learn more
                                </ThemedButton>
                            </a>
                            <Link to={'/subscriptions/upgrade'}>
                                <ThemedButton primary airy>
                                    Upgrade now
                                </ThemedButton>
                            </Link>
                        </FlexDiv>
                    ) : (
                        <a href={`mailto:${supportEmail}`}>
                            <ThemedButton primary fullWidth>
                                Contact to upgrade
                            </ThemedButton>
                        </a>
                    )
                }
            />
        );
    } else
        return loadingAutomations ? (
            <ThemedSpinner />
        ) : (
            <Fragment>
                <ErrorMessage error={error} />
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    autoHideDuration={5000}
                    variant={messageType}
                    message={message || ''}
                    open={messageShown}
                    onClose={closeSnackbar}
                />
                {automations.length === 1 && !dismissedFirstTraining && (
                    <Row>
                        <Col md="12" xs="12">
                            <div className={'top-guide'}>
                                <div className={'block alert success'}>
                                    <div className="close">
                                        <img
                                            src="/static/img/close.svg"
                                            alt="Close the alert"
                                            onClick={() => {
                                                automationStore.dismissFirstTraining();
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <div className="alert-heading">
                                            Your first training program has been
                                            created.
                                        </div>
                                        <p>
                                            Once employees start their training
                                            the stats will increase, helping you
                                            monitor their progress.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
                {automations.length > 0 && (
                    <Fragment>
                        <Fragment>
                            <Block>
                                <SpaceBetweenDiv>
                                    <Heading2>Training programs</Heading2>
                                    <FlexDiv alignCenter>
                                        <SearchInput
                                            searchValue={searchValue || ''}
                                            placeholder="Search by name"
                                            onChange={(e) =>
                                                automationStore.setSearchValue(
                                                    e.target.value
                                                )
                                            }
                                            onClear={() =>
                                                automationStore.setSearchValue(
                                                    ''
                                                )
                                            }
                                        />
                                        <div
                                            className="assessment-filter-input"
                                            onClick={showFilterModal}
                                        >
                                            <TextTiny
                                                bold
                                                lighter={filterCount === 0}
                                                link
                                            >
                                                {filterCount > 0
                                                    ? `${filterCount} ${pluralize(
                                                          'Filter',
                                                          filterCount
                                                      )}`
                                                    : 'Filters'}
                                            </TextTiny>
                                            <div>
                                                {filterCount > 0 && (
                                                    <CancelIcon
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            clearFilters();
                                                        }}
                                                        fontSize="small"
                                                        style={{
                                                            cursor: 'pointer ',
                                                        }}
                                                    />
                                                )}
                                                <FilterIcon fontSize="small" />
                                            </div>
                                        </div>
                                        <Link to="/trainings/create">
                                            <ThemedButton primary airy>
                                                Create new
                                            </ThemedButton>
                                        </Link>
                                    </FlexDiv>
                                </SpaceBetweenDiv>
                            </Block>
                            <Block>
                                <SpaceBetweenDiv positionRelative>
                                    {loadingAutomationsInBackground && (
                                        <CircularProgress
                                            size={15}
                                            className={'background-loader'}
                                            color="primary"
                                        />
                                    )}
                                    <ThemedNav clean>
                                        {tabs.map((t) => (
                                            <ThemedNavItem
                                                active={tab === t.code}
                                                key={t.code}
                                            >
                                                <ThemedNavLink
                                                    onClick={() => {
                                                        history.push(
                                                            `${parentMatch.path}/${t.code}`
                                                        );
                                                    }}
                                                >
                                                    {t.title} (
                                                    {
                                                        automationStore[
                                                            `${t.code}Tab`
                                                        ].length
                                                    }
                                                    )
                                                </ThemedNavLink>
                                            </ThemedNavItem>
                                        ))}
                                    </ThemedNav>
                                    {filteredAutomations.length > 0 && (
                                        <FlexDiv alignCenter>
                                            <TextDefault lighter mr={8}>
                                                Sort by:
                                            </TextDefault>
                                            <TextInput
                                                select
                                                inline
                                                value={sortBy}
                                                onChange={(e) => {
                                                    automationStore.setSortBy(
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                {sortOptions.map((p) => (
                                                    <MenuItem
                                                        key={p.value}
                                                        value={p.value}
                                                    >
                                                        {p.text}
                                                    </MenuItem>
                                                ))}
                                            </TextInput>
                                        </FlexDiv>
                                    )}
                                </SpaceBetweenDiv>
                                {automationStore[`${tab}Tab`].length > 0 && (
                                    <AutomationListTable
                                        automations={[
                                            ...automationStore[`${tab}Tab`],
                                        ]}
                                        parentUrl={parentMatch.url}
                                        onDelete={onDelete}
                                        pauseAutomation={onPause}
                                        lists={lists}
                                    />
                                )}
                                {automationStore[`${tab}Tab`].length === 0 && (
                                    <div className="no-assessments-found">
                                        <img
                                            src="/static/img/icon-booklist.svg"
                                            alt="No training programs found"
                                        />
                                        <div className="stats">
                                            <h5>No training programs found</h5>
                                            <p>
                                                Try adjusting your search or
                                                filter to find what you were
                                                looking for.
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </Block>
                        </Fragment>
                    </Fragment>
                )}
                {automations.length === 0 && (
                    <NoItemsYet
                        img={'/static/img/training-empty.svg'}
                        imgAlt={'sneak peak into a training program'}
                        title={'No training programs yet'}
                        text={
                            'Build security culture and keep security awareness high with our behavior-changing training programs.'
                        }
                        heading={'Training programs'}
                        buttons={
                            needsUpgrade ? (
                                <FlexDiv>
                                    <ThemedButton secondary airy>
                                        Learn more
                                    </ThemedButton>
                                    <ThemedButton primary airy>
                                        Upgrade now
                                    </ThemedButton>
                                </FlexDiv>
                            ) : (
                                <FullWidthLink to={`/trainings/create`}>
                                    <ThemedButton primary fullWidth>
                                        Create training program
                                    </ThemedButton>
                                </FullWidthLink>
                            )
                        }
                    />
                )}
                {lists && lists.length > 0 && (
                    <Modal
                        isOpen={filterModal}
                        onRequestClose={hideFilterModal}
                        className="filter-modal"
                        ariaHideApp={false}
                    >
                        <FilterModal
                            onRequestClose={hideFilterModal}
                            items={automationStore[`${tab}Tab`]}
                            // filter1
                            nameFilter1="Subjects"
                            array1={Object.values(allSubjectsIndex)}
                            filter1={subjectFilter.slice(0)}
                            setFilter1={(value) => {
                                automationStore.setSubjectFilter(value);
                            }}
                            // filter2
                            nameFilter2="Employee lists"
                            array2={lists}
                            filter2={employeeFilter.slice(0)}
                            setFilter2={(value) => {
                                automationStore.setEmployeeFilter(value);
                            }}
                            nameDateFilter1={'Start date'}
                            startDateFilter1={fromStartDateFilter}
                            setStartDateFilter1={(value) =>
                                automationStore.setFromStartDateFilter(value)
                            }
                            endDateFilter1={toStartDateFilter}
                            setEndDateFilter1={(value) =>
                                automationStore.setToStartDateFilter(value)
                            }
                            nameDateFilter2={'End date'}
                            startDateFilter2={fromEndDateFilter}
                            setStartDateFilter2={(value) =>
                                automationStore.setFromEndDateFilter(value)
                            }
                            endDateFilter2={toEndDateFilter}
                            setEndDateFilter2={(value) =>
                                automationStore.setToEndDateFilter(value)
                            }
                            filterCount={filterCount}
                            clearFilters={clearFilters}
                        />
                    </Modal>
                )}
            </Fragment>
        );
}

export default observer(AutomationList);
