import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { ThemedButton } from '../../themed/ThemedComponents';
import { TextField } from '@material-ui/core';
import { Fragment } from 'react';
import ErrorMessage from '../../errormessage';
import ThemedEmailSentSVG from '../../themed/ThemedEmailSentSVG';

function PreviewEmail({ ownEmail, onRequestClose, sendPreview, sendError }) {
    const [targetEmail, settargetEmail] = useState(ownEmail);
    const [previewSent, setpreviewSent] = useState(false);

    // should be deleted once backend works better..
    const previewType = 0;
    const selectedEvents = [];

    const onPreviewSend = () => {
        sendPreview(targetEmail, previewType, selectedEvents);
        setpreviewSent(true);
    };

    return (
        <div className="modalWindow simple">
            <div className={'modalHeader plain'}>
                {!previewSent && <h3>Send a preview</h3>}
                <img
                    className={'modal-close-right'}
                    src="/static/img/close.svg"
                    alt="Close"
                    onClick={onRequestClose}
                />
            </div>
            <ErrorMessage error={sendError} />
            {!previewSent ? (
                <Fragment>
                    <div
                        className={'modalContent'}
                        style={{ minHeight: '120px' }}
                    >
                        <div className="form-holder">
                            <TextField
                                type="email"
                                name="recipientsEmail"
                                required
                                className={'bigInput'}
                                value={targetEmail}
                                label="Recipient's email address"
                                onChange={(e) => {
                                    settargetEmail(e.target.value);
                                }}
                                helperText="Use commas(,) to add recipients"
                            />
                        </div>
                    </div>
                    <div className={'modalFooter'}>
                        <ThemedButton
                            primary
                            onClick={onPreviewSend}
                            disabled={!(targetEmail !== '')}
                        >
                            Send Preview
                        </ThemedButton>
                    </div>
                </Fragment>
            ) : (
                <div className={'modalContent'}>
                    <div className="no-assessments-found">
                        <ThemedEmailSentSVG />
                        <div className="stats">
                            <h5>Preview sent successfully</h5>
                            <p>
                                The recipients you’ve entered received the
                                preview of this assessment
                            </p>
                        </div>
                        <ThemedButton primary onClick={onRequestClose}>
                            OK
                        </ThemedButton>
                    </div>
                </div>
            )}
        </div>
    );
}

export default observer(PreviewEmail);
