import React from "react";
import { SvgLoader, SvgProxy } from "react-svgmt";
import { withTheme } from 'styled-components'

class ThemedLessonsCompleted extends React.Component {

    render() {
        return (
            <div style={{ paddingRight: '8px', paddingBottom: '2px'}}>
                <SvgLoader width="16" height="16" path="/static/img/arrow-back.svg">
                    <SvgProxy selector="path" fill={this.props.color || this.props.theme.primary} />
                </SvgLoader>
            </div>
        )
    }
}

export default withTheme(ThemedLessonsCompleted);
