import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { observer } from 'mobx-react';
import RadioButton from '../../RadioButton';
import SelectChannel from '../wizard/select-channel';
import { ThemedButton } from '../../themed/ThemedComponents';

export default observer(
    ({
        scheduleType,
        weekday,
        timeOfDay,
        interval,
        subjectsPerInterval,
        channel,
        slackAvailable,
        slackConnected,
        teamsAvailable,
        teamsConnected,
        onChangeScheduleType,
        onChangeChannel,
        onChangeWeekday,
        onChangeTimeOfDay,
        onChangeSubjectsPerInterval,
        onChangeInterval,
        showScheduleModal,
        ongoing,
    }) => {
        return (
            <Fragment>
                <div className={'training-schedule'}>
                    <br />
                    <h4 className={'subheader'}>Set delivery</h4>
                    <h5>Type of delivery</h5>
                    <div className={'training-schedule-options'}>
                        <RadioButton
                            id="typeSend1"
                            label="Schedule delivery"
                            value={0}
                            groupValue={scheduleType}
                            onClick={onChangeScheduleType}
                        />
                        <RadioButton
                            id="typeSend2"
                            label="Send everything now"
                            value={1}
                            groupValue={scheduleType}
                            onClick={onChangeScheduleType}
                        />
                        <RadioButton
                            id="typeSend3"
                            label="Download sign in links"
                            value={2}
                            groupValue={scheduleType}
                            onClick={onChangeScheduleType}
                        />
                    </div>
                    {scheduleType === 0 && (
                        <Fragment>
                            <Row className={'training-schedule-frequency'}>
                                <Col xs="4">
                                    <div className="form-holder">
                                        <h5>Repeat</h5>
                                        <select
                                            name="interval"
                                            className="bigInput"
                                            value={interval}
                                            onChange={(e) =>
                                                onChangeInterval(e)
                                            }
                                        >
                                            <option className="tl" value="W">
                                                Weekly
                                            </option>
                                            <option className="tl" value="B">
                                                Biweekly
                                            </option>
                                            <option className="tl" value="M">
                                                Monthly
                                            </option>
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="4">
                                    <div className="form-holder">
                                        <h5>Subjects per interval</h5>
                                        <input
                                            type="number"
                                            name="subjectsPerInterval"
                                            className="bigInput"
                                            value={subjectsPerInterval}
                                            onChange={(e) =>
                                                onChangeSubjectsPerInterval(e)
                                            }
                                            min="1"
                                            max="9"
                                        />
                                    </div>
                                </Col>
                                <Col xs="4">
                                    <div className="form-holder">
                                        <h5>Select day</h5>
                                        <select
                                            placeholder="Source Type"
                                            className="bigInput"
                                            name="companySize"
                                            value={weekday}
                                            onChange={(e) => onChangeWeekday(e)}
                                        >
                                            <option value="1">Monday</option>
                                            <option value="2">Tuesday</option>
                                            <option value="3">Wednesday</option>
                                            <option value="4">Thursday</option>
                                            <option value="5">Friday</option>
                                            <option value="6">Saturday</option>
                                            <option value="0">Sunday</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col xs="4">
                                    <div className="form-holder">
                                        <h5>Select time</h5>
                                        <select
                                            placeholder="Source Type"
                                            className="bigInput"
                                            name="companySize"
                                            value={timeOfDay}
                                            onChange={(e) =>
                                                onChangeTimeOfDay(e)
                                            }
                                        >
                                            <option value="">
                                                Select time
                                            </option>
                                            <option value="0">0:00 GMT</option>
                                            <option value="1">1:00 GMT</option>
                                            <option value="2">2:00 GMT</option>
                                            <option value="3">3:00 GMT</option>
                                            <option value="4">4:00 GMT</option>
                                            <option value="5">5:00 GMT</option>
                                            <option value="6">6:00 GMT</option>
                                            <option value="7">7:00 GMT</option>
                                            <option value="8">8:00 GMT</option>
                                            <option value="9">9:00 GMT</option>
                                            <option value="10">
                                                10:00 GMT
                                            </option>
                                            <option value="11">
                                                11:00 GMT
                                            </option>
                                            <option value="12">
                                                12:00 GMT
                                            </option>
                                            <option value="13">
                                                13:00 GMT
                                            </option>
                                            <option value="14">
                                                14:00 GMT
                                            </option>
                                            <option value="15">
                                                15:00 GMT
                                            </option>
                                            <option value="16">
                                                16:00 GMT
                                            </option>
                                            <option value="17">
                                                17:00 GMT
                                            </option>
                                            <option value="18">
                                                18:00 GMT
                                            </option>
                                            <option value="19">
                                                19:00 GMT
                                            </option>
                                            <option value="20">
                                                20:00 GMT
                                            </option>
                                            <option value="21">
                                                21:00 GMT
                                            </option>
                                            <option value="22">
                                                22:00 GMT
                                            </option>
                                            <option value="23">
                                                23:00 GMT
                                            </option>
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <ThemedButton
                                        secondary
                                        onClick={showScheduleModal}
                                    >
                                        See Schedule
                                    </ThemedButton>
                                </Col>
                            </Row>
                        </Fragment>
                    )}
                    <br />
                    {(scheduleType === 0 || scheduleType === 1) && (
                        <SelectChannel
                            channel={channel}
                            slackAvailable={slackAvailable}
                            slackConnected={slackConnected}
                            teamsAvailable={teamsAvailable}
                            teamsConnected={teamsConnected}
                            onChangeChannel={onChangeChannel}
                        />
                    )}
                </div>
            </Fragment>
        );
    }
);
