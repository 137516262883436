import React, { Fragment, useState } from 'react';
import { Row } from 'reactstrap';
import { observer } from 'mobx-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { formatTime } from '../../../utils/helpers';
import VideoPreview from '../../../containers/videoPreview';
import ExclamationIcon from '@material-ui/icons/ReportProblemOutlined';
import { Tooltip } from '@material-ui/core';
import LockOnCircle from '../../LockOnCircle';
import { Heading3 } from '../../design-system/headings';
import { TextDefault, TextTiny } from '../../design-system/texts';
import { FlexDiv, SpaceBetweenDiv } from '../../design-system/containers';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    margin: `0 0 ${grid}px 0`,
    borderRadius: 10,
    width: '100%',
    padding: 0,

    // change background colour if dragging
    background: isDragging ? '#fff' : '#fff',
    boxShadow: isDragging
        ? '0px 4px 14px rgba(29, 30, 29, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.18)'
        : '',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '#FFF' : '#FFF',
    padding: grid,
    width: '100%',
});

function SubjectsOrder({ videos, onReorderList, onRemoveSubject, isTrial }) {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [previewVideoLocation, setPreviewVideoLocation] = useState(null);
    const count = videos.length || 0;

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        onReorderList(result.source.index, result.destination.index);
    };

    const previewVideo = (video_id) => {
        setModalIsOpen(true);
        setPreviewVideoLocation(video_id);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className="dragZone">
            {previewVideoLocation && (
                <VideoPreview
                    previewVideoLocation={previewVideoLocation}
                    modal={true}
                    isOpen={modalIsOpen}
                    onClose={closeModal}
                />
            )}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {videos.map((item, index) => {
                                return (
                                    <Draggable
                                        className="draggable"
                                        key={item.id + '-' + index}
                                        draggableId={item.id + '-' + index}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className="dragItem"
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps
                                                        .style
                                                )}
                                            >
                                                <div className="dragLeft">
                                                    <img
                                                        alt="dragholder"
                                                        src="/static/img/drag.svg"
                                                    />
                                                </div>
                                                {/* TODO: Change previewVideo - to item.video_id_jw  */}
                                                <div
                                                    className="image with_play_btn"
                                                    onClick={() =>
                                                        previewVideo(item.id)
                                                    }
                                                >
                                                    <img
                                                        alt="thumb"
                                                        src={item.thumb}
                                                    />
                                                    {(item.disabled ||
                                                        (!item.availableForBeta &&
                                                            isTrial)) && (
                                                        <LockOnCircle
                                                            outline
                                                            small
                                                            topRight
                                                        />
                                                    )}
                                                </div>
                                                <SpaceBetweenDiv>
                                                    <FlexDiv
                                                        column
                                                        width={500}
                                                        mx={16}
                                                        link
                                                        onClick={() => {
                                                            previewVideo(
                                                                item.id
                                                            );
                                                        }}
                                                    >
                                                        <FlexDiv alignCenter>
                                                            <Heading3 mr={4}>
                                                                {item.title}
                                                            </Heading3>
                                                            {((item.notes &&
                                                                item.notes
                                                                    .length >
                                                                    0) ||
                                                                (item.trainings &&
                                                                    item
                                                                        .trainings
                                                                        .length >
                                                                        0)) && (
                                                                <Tooltip
                                                                    title={
                                                                        <Fragment>
                                                                            {item.notes &&
                                                                            item
                                                                                .notes
                                                                                .length >
                                                                                0 ? (
                                                                                <div>
                                                                                    Modified
                                                                                </div>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                            {item.trainings &&
                                                                            item
                                                                                .trainings
                                                                                .length >
                                                                                0 ? (
                                                                                <div>
                                                                                    Included
                                                                                    in
                                                                                    another
                                                                                    training
                                                                                </div>
                                                                            ) : (
                                                                                ''
                                                                            )}
                                                                        </Fragment>
                                                                    }
                                                                >
                                                                    <span>
                                                                        <ExclamationIcon
                                                                            style={{
                                                                                color: '#D5801C',
                                                                            }}
                                                                            fontSize="small"
                                                                        />
                                                                    </span>
                                                                </Tooltip>
                                                            )}
                                                        </FlexDiv>
                                                        <TextDefault>
                                                            {item.description}
                                                        </TextDefault>
                                                    </FlexDiv>
                                                    <FlexDiv>
                                                        {item.duration !=
                                                            null &&
                                                            item.type !== 3 && (
                                                                <TextTiny bold>
                                                                    {formatTime(
                                                                        item.duration
                                                                    )}
                                                                </TextTiny>
                                                            )}
                                                        {item.type === 3 && (
                                                            <TextTiny bold>
                                                                {
                                                                    item.questions_count
                                                                }{' '}
                                                                questions
                                                                <br />
                                                                {formatTime(
                                                                    item.duration
                                                                )}
                                                            </TextTiny>
                                                        )}
                                                        <div className="remove-subject">
                                                            <img
                                                                alt="dragholder"
                                                                src="/static/img/icon-close.png"
                                                                onClick={() => {
                                                                    onRemoveSubject(
                                                                        item.id
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                    </FlexDiv>
                                                </SpaceBetweenDiv>
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                            {count === 0 && (
                                <Row className="right-banner-placeholder">
                                    <div className="loading-group-automation"></div>
                                    <div className="loading-group-automation"></div>
                                    <div className="loading-group-automation"></div>
                                </Row>
                            )}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
}

export default observer(SubjectsOrder);
