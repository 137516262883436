import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { validateEmail } from '../utils/helpers';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

@inject('authStore')
@observer
class TrialPPV extends React.Component {
    state = {
        companyName: '',
        managerEmail: '',
        managerName: '',
        companyNameError: '',
        managerEmailError: '',
        managerNameError: '',
        error: '',
        step: 1,
    };

    componentDidMount() {
        // google tracker
        ReactGA.pageview('/trial-ppv/');
        // facebook pixel
        const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
        const options = {
            autoConfig: true, // set pixel's autoConfig
            debug: false, // enable logs
        };
        ReactPixel.init('742074739469756', advancedMatching, options);
        ReactPixel.pageView();
    }

    changeCompanyName(e) {
        this.setState({ companyName: e.target.value, error: '' });

        if (this.state.companyName.length < 3) {
            // too short
            this.setState({
                companyNameError: 'Name needs to be at least 4 characters.',
            });
        } else {
            this.setState({ companyNameError: '' });
        }
    }

    changeManagerName(e) {
        this.setState({ managerName: e.target.value, error: '' });

        if (this.state.managerName.length < 3) {
            // too short
            this.setState({
                managerNameError: 'Name needs to be at least 4 characters.',
            });
        } else {
            this.setState({ managerNameError: '' });
        }
    }

    changeEmail(e) {
        const newEmail = e.target.value;
        this.setState({ managerEmail: newEmail, error: '' });

        if (!validateEmail(newEmail)) {
            // too short
            this.setState({ managerEmailError: 'Email is invalid' });
        } else {
            this.setState({ managerEmailError: '' });
        }
    }

    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!
        this.setState({ recaptchaToken });
    };

    register = async () => {
        const data = {};

        data.recaptchaToken = this.state.recaptchaToken;
        data.companyName = this.state.companyName;
        data.managerEmail = this.state.managerEmail;
        data.managerName = this.state.managerName;
        if (await this.props.authStore.registerPPVTrial(data)) {
            this.setState({
                managerEmail: '',
                managerName: '',
                companyName: '',
                companyNameError: '',
                managerEmailError: '',
                managerNameError: '',
                step: 2,
            });
        }
        ReactGA.pageview('/trial-ppv/thank-you');
        ReactPixel.trackCustom('trial-ppv-signup', { signup: true });
    };

    render() {
        // const isInputOk = this.state.companyNameError === '' && this.state.managerNameError === '' && this.state.managerEmailError === '' && this.state.managerEmail !== '';
        // const { trialError, creatingTrial } = this.props.authStore;
        return (
            <Fragment>
                {this.state.step === 1 && (
                    <div className="steps step1">
                        <div className="forms">
                            <div className="form-header">
                                <h2>
                                    Request access to our Embed content platform
                                </h2>
                                <p>
                                    Our Embed solution is by request only.
                                    Please send us an email at{' '}
                                    <strong className="green">
                                        <a href="mailto:sales@awarego.com">
                                            sales@awarego.com
                                        </a>
                                    </strong>
                                    to get access.
                                </p>
                                <p>
                                    If you need further assistance or have any
                                    questions please contact us at{' '}
                                    <strong className="green">
                                        <a href="mailto:awarego@awarego.com">
                                            awarego@awarego.com
                                        </a>
                                    </strong>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                {this.state.step === 2 && (
                    <div className="steps step1">
                        <div className="forms">
                            <div className="form-header">
                                <h2>
                                    Thank you for registering for a free trial
                                </h2>
                                <p>
                                    We have now sent you an email with further
                                    instructions. Please check your inbox.
                                </p>
                                <p>
                                    If you need further assistance or have any
                                    questions please contact us at{' '}
                                    <a href="mailto:awarego@awarego.com">
                                        awarego@awarego.com
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>
        );
    }
}

export default withRouter(TrialPPV);
