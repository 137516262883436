import { observer } from 'mobx-react';
import { TextLead } from '../../components/design-system/texts';
import React from 'react';

function StopCampaignsConfirm({ action, canBeRemovedResult }) {
    return (
        <TextLead>
            In order to {action}, please{' '}
            <TextLead bold>stop the client’s active campaigns</TextLead> through
            the Company {canBeRemovedResult.activeTrainings > 0 && 'Training'}{' '}
            {canBeRemovedResult.activeTrainings > 0 &&
                canBeRemovedResult.activeAssessments > 0 &&
                'and '}
            {canBeRemovedResult.activeAssessments > 0 && 'Assessment'} pages.
        </TextLead>
    );
}

export default observer(StopCampaignsConfirm);
