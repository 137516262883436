import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { FlexDiv } from '../design-system/containers';
import { TextLead } from '../design-system/texts';
import moment from 'moment';

function EditSubscriptionConfirm({
    partnerEstimate,
    companyForm,
    validTo,
    seats,
    isInactive,
}) {
    const validityChanged = !moment(validTo).isSame(
        companyForm.values().validTill,
        'day'
    );
    const seatsChanged = seats !== companyForm.values().seats;

    return (
        <FlexDiv column>
            {(seatsChanged || isInactive) && (
                <Fragment>
                    <TextLead mb={16}>
                        You will be charged $
                        {partnerEstimate && partnerEstimate.newCompanyTotal} per
                        month for a total of{' '}
                        {companyForm && companyForm.values().seats} seats for{' '}
                        {companyForm && companyForm.values().companyName}.
                    </TextLead>
                    <TextLead>
                        By updating this subscription, your total cost including
                        all companies will be updated to $
                        {partnerEstimate &&
                            partnerEstimate.newPartnerTotal &&
                            partnerEstimate.newPartnerTotal}
                        .
                    </TextLead>
                </Fragment>
            )}
            {validityChanged && (
                <TextLead mt={seatsChanged && 16}>
                    The subscription will be newly valid until{' '}
                    {moment(companyForm.values().validTill).format('LL')}.
                </TextLead>
            )}
        </FlexDiv>
    );
}

export default observer(EditSubscriptionConfirm);
