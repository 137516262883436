import React, { Fragment, useEffect, useRef } from 'react';
import { useLocalStore, observer } from 'mobx-react';
import pick from 'lodash/pick';
import { Link } from 'react-router-dom';
import AzureFormDef from '../../forms/azure-sso';
import { Spinner } from 'reactstrap';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import {
    ThemedButton,
    ThemedLink,
} from '../../components/themed/ThemedComponents';
import TextField from '../../components/inputs/textField';
import ErrorMessage from '../../components/errormessage';
import { Row, Col } from 'reactstrap';
import { useStores } from '../../hooks/use-stores';
import { FormControlLabel, Switch } from '@material-ui/core';
import IntegrationDetailSidebar from '../../components/company/integration-detail-sidebar';

function CompanyAzureSSO({ companyId, parentUrl }) {
    const { companyStore, brandingStore } = useStores();
    const fileRef = useRef(null);

    const store = useLocalStore(() => ({
        _form: new AzureFormDef(
            {},
            {
                hooks: {
                    onSuccess: async () => {
                        await companyStore.saveIntegrationData(
                            companyId,
                            'azuresso',
                            store._form.values()
                        );
                        await companyStore.saveIntegrationData(
                            companyId,
                            'azuresso',
                            { enabled: '1' }
                        );
                    },
                },
            }
        ),
        update(values) {
            store._form.update(values);
        },
    }));

    const doLoadData = async () => {
        await companyStore.loadIntegrationData(companyId, 'azuresso');

        let obj = companyStore.azuressoDataIndex[companyId];
        if (obj) {
            console.log('got data');
            store.update(pick(obj, 'tenant', 'cert', 'appName', 'domain'));
        }
    };

    useEffect(() => {
        doLoadData();
    }, [companyId]);

    const loadAsPromise = (f) => {
        return new Promise(async (resolve, reject) => {
            let reader = new FileReader();

            reader.onload = function (e) {
                let text = reader.result;
                resolve(text);
            };
            reader.onabort = function () {
                reject();
            };

            reader.readAsText(f);
        });
    };

    const handleFileSelect = async (evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        let files = evt.target.files || evt.dataTransfer.files;
        for (let i = 0; i < files.length; i++) {
            let f = files[i];

            if (
                f.type &&
                f.size > 0 &&
                !(f.size < 5000 && f.name.endsWith('cer'))
            ) {
                continue;
            }

            let text = await loadAsPromise(f);
            store._form.update({ cert: text });
            store._form.$('cert').validate();
            break;
        }
    };

    const handleDragOver = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        evt.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
    };

    const chooseFile = (e) => {
        fileRef.current.click();
    };

    const onToggleEnabled = async () => {
        if (companyStore.azuressoDataIndex[companyId].enabled === '1') {
            await companyStore.saveIntegrationData(companyId, 'azuresso', {
                enabled: '0',
            });
        } else {
            await companyStore.saveIntegrationData(companyId, 'azuresso', {
                enabled: '1',
            });
        }
    };

    const form = (
        <Fragment>
            <TextField field={store._form.$('tenant')} minWidth={'350px'} />
            <input
                className="hidden"
                type="file"
                multiple={false}
                accept=".cer"
                onChange={handleFileSelect}
                ref={fileRef}
            />
            <TextField
                component={'textarea'}
                rows={8}
                suffix={
                    <ThemedLink onClick={chooseFile}> &nbsp; browse</ThemedLink>
                }
                field={store._form.$('cert')}
            />
            <TextField field={store._form.$('appName')} />
            <TextField field={store._form.$('domain')} />
        </Fragment>
    );

    const { savingAzuressoData, loadingAzuressoDatas, azuressoError } =
        companyStore;
    const { account_name, brandingLoaded } = brandingStore;
    let data = companyStore.azuressoDataIndex[companyId];

    return loadingAzuressoDatas.includes(companyId) || !data ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <Link to={parentUrl}>
                <div className={'navigation back-button'}>
                    Back to Integration overview
                </div>
            </Link>
            <Row>
                <Col
                    lg="8"
                    xs="12"
                    onDragOver={handleDragOver}
                    onDrop={handleFileSelect}
                >
                    <div className="integration-detail-block">
                        {azuressoError && (
                            <ErrorMessage
                                error={azuressoError}
                                marginBottom={true}
                            />
                        )}
                        <img
                            src={`/static/img/logo-azure-ad.svg`}
                            alt="Azure logo"
                        />
                        <h2>
                            {(brandingLoaded && account_name) || 'AwareGO'} +
                            Azure Single Sign-On (SSO)
                        </h2>
                        <p
                            className={
                                data.enabled === '0' ? 'margin-bottom' : ''
                            }
                        >
                            Reduce sign-in friction and give your users
                            one-click access to the app.
                        </p>
                        {data.enabled === '0' ? (
                            <Fragment>
                                {form}
                                {savingAzuressoData.includes(companyId) ? (
                                    <Spinner />
                                ) : (
                                    <ThemedButton
                                        primary
                                        onClick={store._form.onSubmit}
                                    >
                                        Connect Azure SSO
                                    </ThemedButton>
                                )}
                            </Fragment>
                        ) : (
                            ''
                        )}
                    </div>
                    {data.enabled === '1' ? (
                        <Fragment>
                            <div className="integration-detail-block">
                                <h1>Manage connection</h1>
                                {form}
                                {savingAzuressoData.includes(companyId) ? (
                                    <Spinner />
                                ) : (
                                    <ThemedButton
                                        primary
                                        onClick={store._form.onSubmit}
                                    >
                                        Save changes
                                    </ThemedButton>
                                )}
                                <h3>Enable SSO</h3>
                                <div className="button-with-description">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={
                                                    Number(
                                                        data && data.enabled
                                                    ) === 1
                                                }
                                                onChange={onToggleEnabled}
                                                name="ssoEnabled"
                                                color="primary"
                                            />
                                        }
                                        label="Enable Single Sign-On to all users"
                                    />
                                </div>
                            </div>
                        </Fragment>
                    ) : (
                        ''
                    )}
                </Col>
                <Col lg="4" xs="12">
                    <IntegrationDetailSidebar
                        href="https://help.awarego.com/azure-sso"
                        product="Azure SSO"
                    />
                </Col>
            </Row>
        </Fragment>
    );
}

export default observer(CompanyAzureSSO);
