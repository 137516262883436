import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { keyBy } from 'lodash';
import {
    RadioButtons,
    CheckBoxes,
    NumericalScale,
    OrderableList,
    YesNoList,
    Questionwithbuttons,
    Questionwithimage,
    Questionwithsocialpost,
    PasswordTester,
    EmailQuizGeneral,
    EmailQuizMdlzNewsletter,
    Illustration,
    InteractiveImg,
    Message,
    Password,
    Question,
    Decision,
    Video,
    CustomPresentation,
    Conversation,
    GroupOfBlocks,
} from '@awarego/awarego-components';
import { useStores } from '../../hooks/use-stores';
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import './Question.scss';
import SimpleReport from '../../components/manager/assessment/review/simple-report';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

const { REACT_APP_STATIC_PREFIX = '/static' } = process.env;

export default observer(
    ({
        question,
        onQuestionAction,
        userName,
        userEmail,
        completedBlocks,
        isReview,
        visibleBlocks,
        blockNum,
        blockValues,
        handleBlockChange,
        onNextBlock,
        onPrevBlock,
        blockFadeOut,
        blockFadeIn,
    }) => {
        const onAction = async (actionId, value, immediate) => {
            onQuestionAction(question.id, actionId, value, immediate);
        };

        const [token, setToken] = React.useState(null);
        const { store } = useStores();
        const {
            videoPlayerUrl,
            tokenIndex,
            tokens,
            playerPresetCaptionsLanguage,
            playerCaptionsSettings,
        } = store;

        const videoTokenRequested = async (videoId) => {
            await store.videoToken(videoId);
        };

        React.useEffect(() => {
            const loadVideoData = async (subject) => {
                if (!videoPlayerUrl) await store.loadVideoPlayerUrl();
                if (
                    subject &&
                    (subject.id || subject.subject_id) &&
                    !tokenIndex[subject.id || subject.subject_id]
                ) {
                    await store.videoToken(subject.id || subject.subject_id);
                }
                //TODO: Why isn't tokenIndex from store working?
                let tokenArray = keyBy(tokens, 'id');
                setToken(tokenArray[subject.id || subject.subject_id]);
            };

            if (
                question &&
                question.ui.presentation &&
                question.ui.presentation.type === 'video'
            )
                loadVideoData(question.ui.presentation.subject);
        }, [question, store, tokenIndex, tokens, videoPlayerUrl]);

        const ui = useMemo(() => {
            if (!question) return null;
            if (question.definition) return question.definition.ui;
            if (question.ui) return question.ui;
        }, [question]);

        const enableAssessmentFinish = async () => {
            //Placeholder sent to Decision component, doesn't need functionality
            //console.log('Calling enableAssessmentFinish')
        };

        const renderBlock = (block) => {
            if (!block || !blockValues) return;
            let blockActionId = visibleBlocks[blockNum].actionId;

            //console.log('visibleBlocks: ', visibleBlocks);

            if (isReview) {
                const reviewData = question.review[blockNum];
                if (reviewData)
                    return (
                        <SimpleReport
                            reviewData={reviewData}
                            totalParticipants={question.totalParticipants}
                        />
                    );
            }
            switch (block.type) {
                case 'scale':
                    return 'scale';
                case 'checkbox':
                    return (
                        <CheckBoxes
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'conversation':
                    return (
                        <Conversation
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'radio':
                    return (
                        <RadioButtons
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'password-tester':
                    return (
                        <PasswordTester
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'numericalscale':
                    return (
                        <NumericalScale
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'orderablelist':
                    return (
                        <OrderableList
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'yes-no-list':
                    return (
                        <YesNoList
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'questionwithbuttons':
                    return (
                        <Questionwithbuttons
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'questionwithimage':
                    return (
                        <Questionwithimage
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'questionwithsocialpost':
                    return (
                        <Questionwithsocialpost
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'groupOfBlocks':
                    return (
                        <GroupOfBlocks
                            ui={block}
                            handleChange={handleBlockChange}
                            value={blockValues[blockActionId]}
                        />
                    );
                case 'decision':
                    if (!token) return <ThemedSpinner />;
                    else
                        return (
                            <Decision
                                ui={block}
                                handleChange={handleBlockChange}
                                enableAssessmentFinish={enableAssessmentFinish}
                                value={blockValues[blockActionId]}
                                videoTokenRequested={videoTokenRequested}
                                videoPlayerUrl={videoPlayerUrl}
                                token={token}
                                playerPresetCaptionsLanguage={
                                    playerPresetCaptionsLanguage
                                }
                                playerCaptionsSettings={playerCaptionsSettings}
                            />
                        );
                default:
                    return 'Unknown block type: ' + block.type;
            }
        };

        const renderPresentation = (presentation) => {
            switch (presentation.type) {
                case 'illustration':
                    return (
                        <Illustration ui={presentation} onAction={onAction} />
                    );
                case 'interactive-img':
                    return (
                        <InteractiveImg ui={presentation} onAction={onAction} />
                    );
                case 'email':
                    return (
                        <EmailQuizGeneral
                            ui={presentation}
                            onAction={onAction}
                            userEmail={userEmail}
                            userName={userName}
                        />
                    );
                case 'custom':
                    return (
                        <CustomPresentation
                            ui={presentation}
                            onAction={onAction}
                        />
                    );
                case 'email-mdlz-newsletter':
                    return (
                        <EmailQuizMdlzNewsletter
                            ui={presentation}
                            onAction={onAction}
                            userEmail={userEmail}
                            userName={userName}
                        />
                    );
                case 'message':
                    return <Message ui={presentation} onAction={onAction} />;
                case 'password':
                    return <Password ui={presentation} onAction={onAction} />;
                case 'question':
                    return <Question ui={presentation} />;
                case 'video':
                    //return <DecisionAnswers ui={presentation}/>
                    return (
                        isReview && (
                            <Video
                                ui={presentation}
                                videoTokenRequested={videoTokenRequested}
                                videoPlayerUrl={videoPlayerUrl}
                                token={token}
                                playerPresetCaptionsLanguage={
                                    playerPresetCaptionsLanguage
                                }
                                playerCaptionsSettings={playerCaptionsSettings}
                            />
                        )
                    );
                default:
                    return 'Unknown presentation type: ' + presentation.type;
            }
        };

        /** Returns an array of the length of the actual block count */
        const getBlockCountAsArray = () => {
            return Array(actualBlockCount).fill({});
        };

        /** Get the actual block count, taking into account number of visible blocks and defined minimum count */
        const actualBlockCount = useMemo(() => {
            return Math.max(visibleBlocks.length, ui.minimumBlockCount || 0);
        }, [visibleBlocks, ui]);

        return (
            <div className="assessment-preview-question">
                {question &&
                    question.ui.presentation &&
                    (isReview ||
                        !question.ui.presentation.show_only_as_answer) && (
                        <div
                            className={classnames('assessment-block', {
                                'full-screen':
                                    question.ui.presentation.fullScreen,
                            })}
                        >
                            {ui && renderPresentation(ui.presentation)}
                        </div>
                    )}
                {!question.ui.presentation.fullScreen && (
                    <div
                        className={classnames('assessment-block-type-2', {
                            'full-screen':
                                !isReview &&
                                question.ui.presentation.show_only_as_answer,
                        })}
                    >
                        {
                            <div className="d-flex justify-content-between">
                                {actualBlockCount > 1 ? (
                                    <div className="assessment-carousel">
                                        <div className="assessment-carousel-progress">
                                            {getBlockCountAsArray().map(
                                                (x, i) => {
                                                    if (blockNum === i) {
                                                        return (
                                                            <Icon
                                                                key={
                                                                    'icon-' + i
                                                                }
                                                                color="primary"
                                                                className={classnames(
                                                                    {
                                                                        completed:
                                                                            completedBlocks[
                                                                                i
                                                                            ],
                                                                    }
                                                                )}
                                                            >
                                                                <img
                                                                    src={`${REACT_APP_STATIC_PREFIX}/img/icon-step-active.svg`}
                                                                    alt="Active step"
                                                                />
                                                            </Icon>
                                                        );
                                                    } else if (
                                                        i < blockNum ||
                                                        completedBlocks[i]
                                                    ) {
                                                        return (
                                                            <Icon
                                                                key={
                                                                    'icon-' + i
                                                                }
                                                                color="primary"
                                                            >
                                                                <img
                                                                    src={`${REACT_APP_STATIC_PREFIX}/img/icon-step.svg`}
                                                                    alt="Completed step"
                                                                />
                                                            </Icon>
                                                        );
                                                    } else {
                                                        return (
                                                            <Icon
                                                                key={
                                                                    'icon-' + i
                                                                }
                                                            >
                                                                <img
                                                                    src={`${REACT_APP_STATIC_PREFIX}/img/icon-step-following.svg`}
                                                                    alt="Next step"
                                                                />
                                                            </Icon>
                                                        );
                                                    }
                                                }
                                            )}
                                        </div>

                                        <div className="assessment-carousel-buttons">
                                            <Fab
                                                size={'small'}
                                                disabled={blockNum === 0}
                                                onClick={onPrevBlock}
                                            >
                                                <NavigateBeforeIcon />
                                            </Fab>
                                            <Fab
                                                size={'small'}
                                                disabled={
                                                    blockNum ===
                                                        visibleBlocks.length -
                                                            1 ||
                                                    !visibleBlocks[blockNum]
                                                }
                                                onClick={onNextBlock}
                                            >
                                                <NavigateNextIcon />
                                            </Fab>
                                        </div>
                                    </div>
                                ) : isReview ? (
                                    ''
                                ) : (
                                    <div className="assessment-padding" />
                                )}
                            </div>
                        }
                        <div
                            className={classnames(
                                '',
                                { 'fade-out': blockFadeOut },
                                { 'fade-in': blockFadeIn }
                            )}
                        >
                            {renderBlock(visibleBlocks[blockNum])}
                        </div>
                    </div>
                )}
            </div>
        );
    }
);
