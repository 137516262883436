import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Progress } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import BaseCompanyDependentChildContainer from './common/BaseCompanyDependentChildContainer';
import { Table, Tr, Td, Th, Thead } from 'reactable';
import classnames from 'classnames';
import {
    ThemedButton,
    ThemedLinkLink,
    ThemedText,
} from '../components/themed/ThemedComponents';
import InlineProgressBar from '../components/InlineProgressBar';
import { calculateColor } from '../utils/helpers';
import { TextDefault } from '../components/design-system/texts';

@inject('store', 'commonStore', 'companyStore', 'ppvStore')
@observer
class PPVHome extends BaseCompanyDependentChildContainer {
    showPopup = () => {
        this.props.commonStore.showConfirm(
            <Fragment>
                To Purchase more views please contact{' '}
                <a href="mailto:sales@awarego.com?subject=Purchase+more+views">
                    sales@awarego.com
                </a>
            </Fragment>,
            'Close',
            'Purchase views',
            () => {},
            false,
            false
        );
    };

    onCategoryFilterChange = (e) => {
        this.props.ppvStore.setCategoryFilter(e.target.value);
    };
    onNameFilterChange = (e) => {
        this.props.ppvStore.setNameFilter(e.target.value);
        this.setState({ nameFilter: e.target.value });
    };

    async doLoadData() {
        return await Promise.all([
            this.props.ppvStore.loadCatalog(this.props.companyId),
            this.props.ppvStore.loadPPVStats(this.props.companyId),
        ]);
    }

    render() {
        const { currentCompany } = this.props.store;
        const { summary, categories, categoryFilter, nameFilter } =
            this.props.ppvStore;
        let purchased =
            (currentCompany &&
                currentCompany.ppv &&
                currentCompany.ppv.viewsPurchased) ||
            0;
        //let remaining = (currentCompany && currentCompany.ppv && currentCompany.ppv.viewsRemaining)||0
        let used =
            (currentCompany &&
                currentCompany.ppv &&
                currentCompany.ppv.viewsUsed) ||
            0;
        let usage = purchased === 0 ? 100 : (100 * used) / purchased;

        if (currentCompany && !currentCompany.ppv)
            return (
                <Fragment>
                    <Row>
                        <Col lg="12" md="12">
                            <div className="step-info">
                                <h4>
                                    You have not access to the Embed content
                                    part of the system. Please contact support
                                    for access.
                                </h4>
                                {/*<Link to="/ppv-trial" >*/}
                                {/*<div className="btn btn-primary ">Create Pay Per view account</div>*/}
                                {/*</Link>*/}
                            </div>
                        </Col>
                    </Row>
                </Fragment>
            );

        return (
            <Fragment>
                <div className="block">
                    <div className="section-header ">
                        <div className="left padded">
                            <h1>Your views</h1>
                        </div>
                        <div className="d-flex flex-column align-items-center justify-content-center">
                            <ThemedButton primary onClick={this.showPopup}>
                                Purchase more views
                            </ThemedButton>
                        </div>
                    </div>
                    <InlineProgressBar
                        value={usage}
                        customcolor={calculateColor(usage)}
                        big="true"
                    />
                    <TextDefault bold>
                        {used} / {purchased}{' '}
                    </TextDefault>
                    <Link to={`/ppv/usage`}>
                        <ThemedText> Views Usage</ThemedText>
                    </Link>
                </div>
                <div className="block">
                    <div className="d-flex align-items-center">
                        <h3 className="flex-fill max-width">Videos</h3>
                        <select
                            className="bigInput "
                            onChange={(e) => this.onCategoryFilterChange(e)}
                            value={categoryFilter}
                        >
                            <option value="all">All videos</option>
                            {categories.map((c) => (
                                <option key={c} value={c}>
                                    {c}
                                </option>
                            ))}
                        </select>

                        <input
                            className="bigInput ml20"
                            placeholder={'Search video'}
                            onChange={(e) => this.onNameFilterChange(e)}
                            value={nameFilter}
                        ></input>
                    </div>

                    <Table
                        className="table vertical-center"
                        id="table"
                        itemsPerPage={20}
                        pageButtonLimit={5}
                        sortable={['Subject', 'Category', 'Views', 'Status']}
                        defaultSort
                    >
                        <Thead>
                            <Th column="Subject" className="title">
                                Title
                            </Th>
                            <Th column="Category" className="">
                                Category
                            </Th>
                            <Th column="Views" className="center">
                                {' '}
                                #Views
                            </Th>
                            <Th column="Status" className="center">
                                {' '}
                                Status
                            </Th>
                        </Thead>
                        {summary.length > 0 &&
                            summary.map((subject, i) => {
                                let Tag = subject.disabled
                                    ? 'span'
                                    : ThemedLinkLink;

                                return (
                                    <Tr
                                        key={`user-${subject.subject_id}-${i}`}
                                        className={classnames('ppv-status', {
                                            active: subject.status,
                                            disabled: subject.disabled,
                                        })}
                                    >
                                        <Td
                                            column="Subject"
                                            value={subject.title}
                                            className="user-title"
                                        >
                                            <Tag
                                                to={`/ppv/embed/${subject.subject_id}`}
                                                className="d-flex align-items-center"
                                            >
                                                <img
                                                    alt={subject.title}
                                                    className="ppv-thumb"
                                                    src={subject.thumb}
                                                />
                                                <span>{subject.title}</span>
                                            </Tag>
                                        </Td>

                                        <Td
                                            column="Category"
                                            value={subject.category}
                                            className="user-title "
                                        >
                                            {subject.categories.join(', ')}
                                        </Td>
                                        <Td
                                            column="Views"
                                            value={subject.views}
                                            className="user-title"
                                        >
                                            <span>{subject.views || 0}</span>
                                        </Td>
                                        <Td
                                            column="Status"
                                            value={
                                                subject.status
                                                    ? 'Snippet Active'
                                                    : '-'
                                            }
                                            className="user-title"
                                        >
                                            <span>
                                                {subject.status
                                                    ? 'Snippet Active'
                                                    : '-'}
                                            </span>
                                        </Td>
                                    </Tr>
                                );
                            })}
                    </Table>
                </div>

                <div className="block">
                    <div className="step-info">
                        <h4>Your API KEY</h4>
                        <h3>
                            {currentCompany &&
                                currentCompany.ppv &&
                                currentCompany.ppv.apiKey}
                        </h3>
                        <ThemedButton secondary>Regenerate</ThemedButton>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(PPVHome);
