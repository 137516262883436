import React, { Fragment, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Row, Col } from 'reactstrap';
import { Table, Tr, Td, Th, Thead } from 'reactable';
import pluralize from 'pluralize';
import { ThemedLinkLink } from '../../themed/ThemedComponents';
import ThemedLinkButton from '../../themed/ThemedLinkButton';
import EmployeesLimitReachedAction from '../employees-limit-reached-action';
import {
    Block,
    FlexDiv,
    SpaceBetweenDiv,
} from '../../design-system/containers';
import { Heading1 } from '../../design-system/headings';
import { TextDefault } from '../../design-system/texts';
import StatusBadge from '../../StatusBadge';
import SimpleTable from '../../SimpleTable';
import NoItemsYet from '../no-items-yet';

const { enforceSlash, formatDate } = require('../../../utils/helpers');

function EmployeeList({ lists, readOnly = false, currentCompany }) {
    const createDisabled = !currentCompany || !currentCompany.canAddUsers;
    const activePackage = currentCompany.subscription;

    let match = useRouteMatch();

    const buttons = useMemo(
        () => (
            <FlexDiv>
                <ThemedLinkButton
                    to={`/users/create-list`}
                    primary
                    disabled={createDisabled}
                >
                    Create
                </ThemedLinkButton>
                <ThemedLinkButton
                    to={`/users/import`}
                    secondary
                    airy
                    disabled={createDisabled}
                >
                    Import
                </ThemedLinkButton>
            </FlexDiv>
        ),
        []
    );

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (x) => (
                    <Fragment>
                        <TextDefault bold mr={4}>
                            {x.value}
                        </TextDefault>
                        {x.row.original.source === 'azure' ? (
                            <StatusBadge
                                color={'success'}
                                text={'AD imported'}
                            />
                        ) : (
                            x.row.original.source === 'google' && (
                                <StatusBadge
                                    color={'success'}
                                    text={'Google imported'}
                                />
                            )
                        )}
                    </Fragment>
                ),
            },
            {
                Header: 'Created on',
                accessor: 'created',
                Cell: (x) => formatDate(x.value),
                minWidth: 144,
            },
            {
                Header: 'Employees',
                accessor: 'usersCount',
            },
        ],
        []
    );

    return lists && lists.length > 0 ? (
        <Block>
            <SpaceBetweenDiv>
                <FlexDiv column>
                    <Heading1>Employee lists</Heading1>
                    <TextDefault lighter>
                        {lists ? lists.length : 0}{' '}
                        {pluralize('list', lists.length)},{' '}
                        {currentCompany ? currentCompany.users_total : 0}{' '}
                        {pluralize('employee', currentCompany.users_total)}{' '}
                        {activePackage &&
                            '(out of ' +
                                activePackage.package_users +
                                ' licensed)'}
                    </TextDefault>
                </FlexDiv>
                {buttons}
            </SpaceBetweenDiv>
            <EmployeesLimitReachedAction
                disabled={createDisabled}
                currentCompany={currentCompany}
            />
            <FlexDiv column mt={24}>
                <SimpleTable
                    data={lists}
                    columns={columns}
                    useLink={!readOnly}
                    needsFilter
                />
            </FlexDiv>
        </Block>
    ) : (
        <NoItemsYet
            img={'/static/img/icon-user-list.svg'}
            imgAlt={'no lists available'}
            title={'No list yet'}
            text={
                'Lists can help you plan out different training strategies for different departments/groups.'
            }
            heading="Employee Lists"
            buttons={buttons}
            extraContent={
                <EmployeesLimitReachedAction
                    disabled={createDisabled}
                    currentCompany={currentCompany}
                    centered
                />
            }
        />
    );
}

export default observer(EmployeeList);
