import React from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import Modal from 'react-modal';
import Subject from './Subject';
import { ThemedButton } from '../components/themed/ThemedComponents';

const errorString = {
    0: 'No error',
    101: 'General exception',
    201: 'Invalid argument error',
    202: 'Element cannot have children',
    203: 'Element not an array - cannot have count',
    301: 'Not initialized',
    401: 'Not implemented error',
    402: 'Invalid set value, element is a keyword',
    403: 'Element is read only',
    404: 'Element is write only',
    405: 'Incorrect data type',
};

class Scorm2004API {
    constructor(commitHandler, finishHandler) {
        this.cmi = {
            'cmi.learner_id': 'mogadanez@gmail.com',
            'cmi.learner_name': 'mogadanez@gmail.com',
        };
        if (window.localStorage.cmi) {
            try {
                this.cmi = JSON.parse(window.localStorage.cmi);
            } catch (e) {}
        }

        if (!this.cmi)
            this.cmi = {
                'cmi.learner_id': 'mogadanez@gmail.com',
                'cmi.learner_name': 'mogadanez@gmail.com',
            };
        this.errorCode = '0';
        this.commitHandler = commitHandler;
        this.finishHandler = finishHandler;
    }

    Initialize() {
        // debugger
        console.log('LMSInitialize', arguments);
        this.errorCode = '0';
        return 'true';
    }

    Terminate() {
        console.log('LMSFinish', arguments);
        this.errorCode = '0';
        this.finishHandler();
        return 'true';
    }

    GetValue(element) {
        console.log('LMSGetValue', element);

        let value = this.cmi[element];

        return value ? value : '';
    }

    SetValue(element, value) {
        // debugger
        console.log('LMSSetValue', element, value);
        this.cmi[element] = value;
        return 'true';
    }

    Commit() {
        console.log('LMSCommit', arguments);
        this.commitHandler(this.cmi);
        return 'true';
    }

    GetLastError() {
        console.log('LMSGetLastError', arguments);
        return this.errorCode;
    }

    GetErrorString(param) {
        console.log('LMSGetErrorString');
        return param !== '' ? errorString[param] : '';
    }

    GetDiagnostic() {
        console.log('LMSGetDiagnostic', arguments);
        return '';
    }

    init(cmi) {
        console.log('init', arguments);
        this.cmi = cmi;
    }
}

window.API_1484_11 = new Scorm2004API(
    (data) => {
        window.localStorage.cmi = JSON.stringify(data);
        console.log('commit');
    },
    () => {
        console.log('finish');
    }
);

@inject('authStore', 'store', 'catalogStore')
@observer
class SCORMPreviewContainer extends React.Component {
    state = { error: '', loadedOrError: null };

    constructor(props) {
        super(props);
        this.api = window.API_1484_11;
        reaction(
            () => this.props.authStore.currentUser,
            (user) => {
                this.api.SetValue('cmi.learner_id', 'mogadanez@gmail.com');
                this.api.SetValue(
                    'cmi.learner_name',
                    this.props.authStore.currentUser.name
                );
            }
        );

        if (this.props.authStore.currentUser) {
            this.api.SetValue('cmi.learner_id', 'mogadanez@gmail.com');
            this.api.SetValue(
                'cmi.learner_name',
                this.props.authStore.currentUser.name
            );
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState) return; // not yet initialized
        if (nextProps.previewScormLocation !== prevState.previewScormLocation) {
            return {
                previewScormLocation: nextProps.previewScormLocation,
                loadedOrError: null,
            };
        }

        // No state update necessary
        return null;
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        // At this point, we're in the "commit" phase, so it's safe to load the new data.
        this.loadData();
    }

    async loadData() {
        if (
            this.state.previewScormLocation &&
            !this.props.store.scormIndex[this.state.previewScormLocation]
        ) {
            await this.props.store.scormInfo(this.state.previewScormLocation);
        }
    }

    //

    render() {
        const { isOpen, onClose, previewScormLocation, modal, catalogStore } =
            this.props;
        const { scormIndex, currentCompany } = this.props.store;
        let scormData = scormIndex[previewScormLocation];
        let subject = catalogStore.allSubjectsIndex[previewScormLocation];

        let actor = {
            name: [
                this.props.authStore.currentUser.name ||
                    this.props.authStore.currentUser.email,
            ],
            account: [{ accountName: this.props.authStore.currentUser.email }],
            objectType: 'Agent',
        };

        let endpoint = `${process.env.REACT_APP_API_URL}/xapi/preview`;
        let auth = `JWT ${this.props.authStore.token}`;
        let width = 800;
        let height = 600;

        let url =
            scormData &&
            `${scormData.url}?actor=${JSON.stringify(
                actor
            )}&endpoint=${endpoint}&auth=${auth}&width=${width}&height=${height}`;

        if (modal && subject)
            return (
                <Modal
                    isOpen={isOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={onClose}
                    className="Modal schedule"
                >
                    <div>
                        <div className={'modalHeader'}>
                            <h3>Preview lesson: {subject.title}</h3>
                            <img
                                className={'modal-close-right'}
                                src="/static/img/close.svg"
                                alt="Close"
                                onClick={onClose}
                            />
                        </div>
                        <div className={'modalContent'}>
                            <Subject
                                subject={subject}
                                showBackLink={false}
                                companyId={
                                    currentCompany && currentCompany.company_id
                                }
                            />
                        </div>
                        <div className={'modalFooter'}>
                            <ThemedButton primary onClick={onClose}>
                                Back to add subjects
                            </ThemedButton>
                        </div>
                    </div>
                </Modal>
            );

        return (
            <div>
                {scormData && (
                    <iframe
                        frameBorder={0}
                        title={'SCORM'}
                        width="800"
                        height="600"
                        src={url}
                    ></iframe>
                )}
            </div>
        );
    }
}

export default SCORMPreviewContainer;
