import React, { Fragment, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import ManagersTable from '../super/managers-table';
import AddManager from '../../components/super/add-manager';
import { useStores } from '../../hooks/use-stores';
import { Heading2 } from '../../components/design-system/headings';
import {
    Block,
    SpaceBetweenDiv,
    FlexDiv,
} from '../../components/design-system/containers';
import { BigModal } from '../../components/design-system/modals';
import AddManagerDef from '../../forms/add-manager';

function CompanyManagers({
    company,
    removingManagers,
    addingManager,
    addManager,
    removeManager,
    sendLoginInfo,
    addManagerError,
}) {
    const [showAddManagerModal, setShowAddManagerModal] = useState(false);

    const { commonStore } = useStores();

    const localStore = useLocalStore(() => {
        let managerForm = new AddManagerDef(
            {},
            {
                onSuccess() {
                    doAddManager(this);
                },
            }
        );
        return { managerForm };
    });

    const doAddManager = (form) => {
        const result = addManager(form);
        if (result) {
            setShowAddManagerModal(false);
            commonStore.success('Admin addded!');
        }
    };

    const doRemoveManager = (companyId, name, email) => {
        commonStore.showConfirm(
            `Admin ${name ? name : email} will be removed from this company`,
            'Remove',
            `Remove ${name ? name : email}?`,
            () => {
                const result = removeManager(companyId, email);
                if (result) commonStore.success('Admin removed!');
            },
            true
        );
    };

    const doSendLoginInfo = (managerEmail, companyId) => {
        commonStore.showConfirm(
            `Do you want to send an email to ${managerEmail} with a login link for the LMS?`,
            'Send email',
            'Send login email',
            () => {
                const result = sendLoginInfo(managerEmail, companyId);
                if (result) commonStore.success('Login email sent!');
            },
            false
        );
    };

    return !company ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <Block>
                <SpaceBetweenDiv>
                    <Heading2>Admins</Heading2>
                    <ThemedButton
                        secondary
                        onClick={() => {
                            localStore.managerForm.reset();
                            setShowAddManagerModal(true);
                        }}
                    >
                        Add admin
                    </ThemedButton>
                </SpaceBetweenDiv>
                {company && (
                    <FlexDiv pt={32}>
                        <ManagersTable
                            company={company}
                            removeManager={doRemoveManager}
                            removingManagers={removingManagers}
                            sendLoginInfo={doSendLoginInfo}
                        />
                    </FlexDiv>
                )}
            </Block>
            <BigModal
                isOpen={showAddManagerModal}
                onRequestClose={() => setShowAddManagerModal(false)}
                heading="Add new admin"
                modalContent={
                    <AddManager
                        error={addManagerError}
                        managerForm={localStore.managerForm}
                        addManagerError={addManagerError}
                    />
                }
                modalFooterContent={
                    addingManager ? (
                        <ThemedSpinner />
                    ) : (
                        <Fragment>
                            <ThemedButton
                                secondary
                                onClick={() => setShowAddManagerModal(false)}
                            >
                                Cancel
                            </ThemedButton>
                            <ThemedButton
                                primary
                                airy
                                onClick={() => {
                                    localStore.managerForm.submit();
                                }}
                            >
                                Add
                            </ThemedButton>
                        </Fragment>
                    )
                }
            />
        </Fragment>
    );
}

export default observer(CompanyManagers);
