import React, { useState, Fragment } from 'react';
import {
    ThemedNav,
    ThemedNavItem,
    ThemedNavLink,
} from '../themed/ThemedComponents';
import { TabContent, TabPane } from 'reactstrap';
import { Table, Tr, Td, Th, Thead } from 'reactable';
import Modal from 'react-modal';

function IntegrationDetailPreviewModal({ previewData, closePreviewModal }) {
    const [activeTab, setActiveTab] = useState('1');

    const tabsChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (
        previewData && (
            <Modal
                isOpen={previewData}
                onRequestClose={closePreviewModal}
                className={'Modal'}
            >
                <div className={'modalHeader'}>
                    <h3>Preview Data</h3>
                    <img
                        className="modal-close-right"
                        src="/static/img/close.svg"
                        alt="Close"
                        onClick={closePreviewModal}
                    />
                </div>
                <div className={'modalContent'}>
                    <ThemedNav>
                        <ThemedNavItem
                            active={activeTab === '1' ? 'active' : ''}
                        >
                            <ThemedNavLink
                                onClick={() => {
                                    tabsChange('1');
                                }}
                            >
                                Lists
                            </ThemedNavLink>
                        </ThemedNavItem>
                        <ThemedNavItem
                            active={activeTab === '2' ? 'active' : ''}
                        >
                            <ThemedNavLink
                                onClick={() => {
                                    tabsChange('2');
                                }}
                            >
                                Users
                            </ThemedNavLink>
                        </ThemedNavItem>
                    </ThemedNav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            <br />
                            <Table className="table" id="table2">
                                <Thead>
                                    <Th column="displayName">Name</Th>
                                    <Th column="Users" className="number">
                                        Users
                                    </Th>
                                    <Th column="Actions">Action</Th>
                                </Thead>
                                {previewData.affectedGroups.map((x, i) => {
                                    return (
                                        <Tr key={i}>
                                            <Td
                                                className="date"
                                                column="displayName"
                                            >
                                                {x.displayName}
                                            </Td>
                                            <Td
                                                className="center"
                                                column="Users"
                                            >
                                                {x.usersCount}
                                            </Td>
                                            <Td
                                                className={`center sync-preview-action-${x.action} `}
                                                column="Actions"
                                            >
                                                {x.action}
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </Table>
                        </TabPane>
                        <TabPane tabId="2">
                            <br />
                            <Table className="table" id="table3">
                                <Thead>
                                    <Th column="displayName">Name</Th>
                                    <Th column="Mail" className="number">
                                        Mail
                                    </Th>
                                    <Th column="Lists">Lists</Th>
                                    <Th column="Actions">Action</Th>
                                </Thead>
                                {previewData.affectedUsers.map((x, i) => {
                                    return (
                                        <Tr key={i}>
                                            <Td
                                                className="date"
                                                column="displayName"
                                            >
                                                {x.displayName}
                                            </Td>
                                            <Td
                                                className="center"
                                                column="Mail"
                                            >
                                                {x.mail}
                                            </Td>
                                            <Td
                                                className="center"
                                                column="Lists"
                                            >
                                                <Fragment>
                                                    {x.lists.map((l) => (
                                                        <Fragment>
                                                            <div
                                                                className={`sync-preview-action-${
                                                                    l.skip
                                                                        ? 'skip'
                                                                        : 'add'
                                                                } `}
                                                            >
                                                                {l.name}
                                                            </div>
                                                        </Fragment>
                                                    ))}
                                                </Fragment>
                                            </Td>
                                            <Td
                                                className={`center sync-preview-action-${x.action} `}
                                                column="Actions"
                                            >
                                                {x.action}
                                            </Td>
                                        </Tr>
                                    );
                                })}
                            </Table>
                        </TabPane>
                    </TabContent>
                </div>
            </Modal>
        )
    );
}

export default IntegrationDetailPreviewModal;
