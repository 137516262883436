import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'reactstrap';
import braintree from 'braintree-web';
import classes from 'classnames';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import Summary from './summary';
import ReactGA from 'react-ga';
import DropIn from '../../components/DropIn';

var modal = document.getElementById('modal');
var bankFrame = document.getElementById('bt-modal-body');
var closeFrame = document.getElementById('text-close');

function addFrame(err, iframe) {
    bankFrame.append(iframe);
    modal.removeClass('hidden');
}

function removeFrame() {
    var iframe = bankFrame.find('iframe');
    modal.addClass('hidden');
    iframe.remove();
}

@inject('subscriptionStore')
@observer
class PaymentForm extends React.Component {
    state = { clientToken: null, waiting: false };

    async componentDidMount() {
        // Get a client token for authorization from your server

        const clientToken = await this.props.subscriptionStore.loadToken(
            this.props.companyId
        );

        this.setState({
            clientToken,
        });
    }

    buy = async () => {
        ReactGA.event({
            category: 'User',
            action: 'Purchase',
            label: 'Button clicked',
        });

        // Send the nonce to your server
        let payload;
        this.setState({ waiting: true });
        try {
            payload = await this.instance.requestPaymentMethod({
                threeDSecure: this.instance.threeDSecure,
            });
        } catch (e) {
            this.setState({ waiting: false });
            console.error(e);
        }

        try {
            payload = await this.instance.threeDSecure.verifyCard({
                amount: this.props.subscriptionStore.price,
                nonce: payload.nonce,
                addFrame: addFrame,
                removeFrame: removeFrame,
                onLookupComplete: (data, next) => {
                    this.setState({ waiting: false });
                    next();
                },
            });

            ReactGA.event({
                category: 'User',
                action: 'Purchase',
                label: 'Success',
            });

            this.props.onComplete(payload.nonce);
        } catch (e) {
            this.setState({ waiting: false });
            console.error(e);
            ReactGA.event({
                category: 'User',
                action: 'Purchase',
                label: 'Failed',
            });
        }
    };

    instanceCreated = async (instance) => {
        try {
            instance.threeDSecure = await braintree.threeDSecure.create({
                version: 2, // Will use 3DS 2 whenever possible
                client: instance._client,
            });
            closeFrame.click(function () {
                instance.threeDSecure.cancelVerifyCard(removeFrame());
                //enablePayNow();
            });
            this.instance = instance;
        } catch (e) {
            console.error(e);
        }
    };

    render() {
        const {
            price,
            futureCredit,
            nextPlanPrice,
            nextBilling,
            my,
            planPeriod,
            payInProcess,
            selectedTier,
            error,
        } = this.props.subscriptionStore;

        if (!this.state.clientToken) {
            return (
                <div className={'wizard-fullscreen lightbox-like'}>
                    <div className={'wizard-fullscreen-header'}>
                        <h3>Payment Details</h3>
                    </div>
                    <div className={'wizard-fullscreen-body'}>
                        <div className="d-flex align-items-center justify-content-center flex-fill flex-column">
                            <h4 className="green">One moment...</h4>
                            <ThemedSpinner
                                className="align-self-center"
                                size={35}
                            />
                        </div>
                    </div>
                    <div className={'wizard-fullscreen-footer'}>
                        <div className={'actions'}></div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className={'wizard-fullscreen lightbox-like'}>
                    <div className={'wizard-fullscreen-header'}>
                        <h3>Payment Details</h3>
                    </div>
                    <div
                        className={classes(
                            'wizard-fullscreen-body align-items-center justify-content-center flex-column',
                            { loading: this.state.waiting || payInProcess }
                        )}
                    >
                        <div className="paymentError">
                            <ErrorMessage error={error} />
                        </div>

                        <DropIn
                            options={{
                                authorization: this.state.clientToken,
                                card: { cardholderName: true },
                            }}
                            onInstance={this.instanceCreated}
                        />

                        {(this.state.waiting || payInProcess) && (
                            <div className="wait-overlay d-flex align-items-center justify-content-center flex-fill flex-column">
                                <h4 className="green">One moment...</h4>
                                <ThemedSpinner />
                            </div>
                        )}
                    </div>

                    <div className={'wizard-fullscreen-footer'}>
                        <div className={'actions center '}>
                            <Button
                                color={'link'}
                                size={'lg'}
                                onClick={this.props.prevStep}
                            >
                                Previous
                            </Button>

                            <div className="d-flex flex-row align-items-center price">
                                <strong>Summary:</strong>
                                <div className="d-flex flex-column">
                                    <Summary
                                        {...{
                                            price,
                                            futureCredit,
                                            nextPlanPrice,
                                            nextBilling,
                                            my,
                                            planPeriod,
                                        }}
                                    />

                                    {selectedTier && (
                                        <div className="price-description">
                                            {selectedTier.starting_unit}-
                                            {selectedTier.ending_unit || 500}{' '}
                                            employees
                                        </div>
                                    )}
                                </div>
                            </div>
                            <Button color={'primary'} onClick={this.buy}>
                                Purchase
                            </Button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default PaymentForm;
