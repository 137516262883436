import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, Switch, Route } from 'react-router-dom';
import ListView from '../../components/manager/users/list_view';
import Waiting from '../../components/waiting';
import ErrorMessage from '../../components/errormessage';
import BaseCompanyDependentChildContainer from '../common/BaseCompanyDependentChildContainer';
import { enforceSlash } from '../../utils/helpers';
import Modal from 'react-modal';
import FormAdd from './UsersAdd';
import FormEditList from './ListEditor';

@inject('store', 'listStore', 'userStore', 'commonStore')
@observer
class ListViewContainer extends BaseCompanyDependentChildContainer {
    doDelete = async () => {
        await this.props.listStore.delete(
            this.props.companyId,
            this.props.listId
        );
    };

    doDeleteUserFromList = async (email) => {
        await this.props.userStore.delete(
            this.props.companyId,
            this.props.listId,
            email
        );
    };

    onDelete = () => {
        this.props.commonStore.showConfirm(
            'Are you sure you want to delete this list?',
            'Delete',
            'Delete List',
            async () => {
                await this.doDelete();
                this.props.history.push('/users');
            },
            true
        );
    };

    onDeleteUserFromList = (email) => {
        this.props.commonStore.showConfirm(
            'Are you sure you want to delete this user from list?',
            'Delete',
            'Delete User',
            () => {
                this.doDeleteUserFromList(email);
            },
            true
        );
    };

    async doLoadData() {
        this.props.listStore.loadList(this.props.companyId, this.props.listId);
        await this.props.userStore.loadUsers(
            this.props.companyId,
            this.props.listId
        );
    }

    handleEditorClose = () => {
        this.props.history.push(this.props.match.url);
    };

    render() {
        const { match } = this.props;
        const { editingList: list, loadingList } = this.props.listStore;
        const { users, loadingUsers, deletingItems } = this.props.userStore;
        const { currentCompany } = this.props.store;
        if (loadingList || loadingUsers.includes(this.props.listId))
            return <Waiting waitingActive={true} fullScreen={true} />;

        if (!list)
            return (
                <ErrorMessage error="No list selected" errorType="warning" />
            );

        return (
            <Fragment>
                <ListView
                    parentUrl={match.url}
                    users={users}
                    parentMatch={match}
                    list={list}
                    deletingItems={deletingItems}
                    currentCompany={currentCompany}
                    onDelete={this.onDelete}
                    onDeleteUserFromList={this.onDeleteUserFromList}
                />

                <Switch>
                    <Route
                        path={`${enforceSlash(match.url)}edit`}
                        render={(props) => (
                            <Modal
                                isOpen={true}
                                onRequestClose={this.handleEditorClose}
                                className={'Modal auto-size-modal'}
                            >
                                <FormEditList
                                    parentMatch={match}
                                    list={list}
                                    companyId={this.props.companyId}
                                    onRequestClose={this.handleEditorClose}
                                />
                            </Modal>
                        )}
                    />

                    <Route
                        path={`${enforceSlash(match.url)}add-users`}
                        render={(props) => (
                            <Modal
                                isOpen={true}
                                onRequestClose={this.handleEditorClose}
                                className={'Modal wide-modal min-height '}
                            >
                                <FormAdd
                                    parentMatch={match}
                                    list={list}
                                    users={users}
                                    companyId={this.props.companyId}
                                    onRequestClose={this.handleEditorClose}
                                    isImportingLists={false}
                                />
                            </Modal>
                        )}
                    />
                </Switch>
            </Fragment>
        );
    }
}

export default withRouter(ListViewContainer);
