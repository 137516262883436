import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Row, Col } from 'reactstrap';
import ListsOverview from '../../components/manager/users/employee-list';
import BaseCompanyDependentContainer from '../common/BaseCompanyDependentContainer';
import FormEditList from './ListEditor';
import Modal from 'react-modal';
import { enforceSlash } from '../../utils/helpers';
import FormAdd from './UsersAdd';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

@inject('listStore', 'store')
@observer
class ListOfLists extends BaseCompanyDependentContainer {
    async doLoadData() {
        return await this.props.listStore.loadCompanyLists(
            this.props.companyId
        );
    }

    handleEditorClose = () => {
        this.props.history.push(this.props.match.url);
    };

    render() {
        const { match } = this.props;
        const { loadingLists, lists } = this.props.listStore;
        const { currentCompany } = this.props.store;
        return loadingLists ? (
            <ThemedSpinner />
        ) : (
            <div>
                <Row>
                    <Col md="12" xs="12" className="content">
                        <ListsOverview
                            currentCompany={currentCompany}
                            lists={lists}
                            match={match}
                        />
                    </Col>
                </Row>

                <Switch>
                    <Route
                        path={`${enforceSlash(match.url)}import`}
                        render={(props) => (
                            <Modal
                                isOpen={true}
                                onRequestClose={this.handleEditorClose}
                                className={'Modal auto-size-modal'}
                            >
                                <FormAdd
                                    parentMatch={match}
                                    companyId={this.props.companyId}
                                    onRequestClose={this.handleEditorClose}
                                    isImportingLists={true}
                                />
                            </Modal>
                        )}
                    />

                    <Route
                        path={`${enforceSlash(match.url)}create-list`}
                        render={(props) => (
                            <Modal
                                isOpen={true}
                                onRequestClose={this.handleEditorClose}
                                className={'Modal auto-size-modal'}
                            >
                                <FormEditList
                                    parentMatch={match}
                                    companyId={this.props.companyId}
                                    onRequestClose={this.handleEditorClose}
                                />
                            </Modal>
                        )}
                    />
                </Switch>
            </div>
        );
    }
}

export default withRouter(ListOfLists);
