import React from 'react';
import { inject, observer } from 'mobx-react';
import { Modal } from 'reactstrap';
import BaseCompanyDependentContainer from '../common/BaseCompanyDependentContainer';
import ErrorMessage from '../../components/errormessage';
import PaymentForm from './PaymentForm';
import BillingForm from './BillingForm';
import { MenuItem, Switch } from '@material-ui/core';
import PaymentConfirm from './PaymentConfirm';
import { Redirect, withRouter } from 'react-router-dom';
import {
    ThemedText,
    ThemedSubscriptionButton,
    ThemedDiscountBanner,
    ThemedSelectionText,
} from '../../components/themed/ThemedComponents';
import ThemedCheckmarkSVG from '../../components/themed/ThemedCheckmarkSVG';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import Grid from '@material-ui/core/Grid';
import { substituteWithComponent } from '../../utils/helpers';
import { TextInput } from '../../components/design-system/inputs';
import CrossIcon from '../../components/CrossIcon';
import { FlexDiv } from '../../components/design-system/containers';
import { TextTiny } from '../../components/design-system/texts';

@inject('subscriptionStore', 'store', 'commonStore')
@observer
class ChoosePlan extends BaseCompanyDependentContainer {
    goToDashboard = async () => {
        await this.props.store.loadServerData();
        this.props.history.push('/');
    };

    state = { paymentModalMode: 0 };

    async doLoadData() {
        return await this.props.subscriptionStore.loadPlans(
            this.props.companyId
        );
    }

    openPaymentModal = (plan) => {
        if (plan) {
            this.props.subscriptionStore.setPlan(plan.selected);
            this.props.subscriptionStore.setTier(plan.selectedSeats);
        }
        this.props.subscriptionStore.estimate(
            this.props.companyId,
            this.props.subscriptionStore.selectedPlan.id,
            this.props.subscriptionStore.selectedSeats
        );
        this.setState({ paymentModalMode: 1 });
    };

    openPaymentDetails = (billingInfo) => {
        this.props.subscriptionStore.setBillingInfo(billingInfo);
        this.setState({ paymentModalMode: 2 });
    };

    onCouponEnetered = (coupon) => {
        this.props.subscriptionStore.setCoupon(coupon);
        this.props.subscriptionStore.estimateDebounced(
            this.props.companyId,
            this.props.subscriptionStore.selectedPlan.id,
            this.props.subscriptionStore.selectedSeats
        );
    };

    doPayment = async (paymentToken) => {
        let result = await this.props.subscriptionStore.create(
            this.props.companyId,
            this.props.subscriptionStore.selectedPlan.id,
            this.props.subscriptionStore.selectedSeats,
            paymentToken
        );
        if (result) {
            this.setState({ paymentModalMode: 3 });
        }
    };

    onPeriodChange = (event, baseId) => {
        this.props.subscriptionStore.selectPlanDuration(
            baseId,
            event.target.checked
        );
    };
    onSeatsChange = (event, baseId) => {
        this.props.subscriptionStore.selectSeats(baseId, event.target.value);
    };

    closePaymentModal = () => {
        this.props.subscriptionStore.setCoupon(null);
        this.setState({ paymentModalMode: 0 });
    };

    doDelete = async () => {
        return this.props.subscriptionStore.cancelSubscription(
            this.props.companyId
        );
    };

    render() {
        const {
            legacyKeyFacts,
            loadingPlans,
            enterprise_units,
            basePlans,
            my,
            error,
        } = this.props.subscriptionStore;
        const { companyId } = this.props;
        const { havePartnerSubscription } = this.props.store;
        if (havePartnerSubscription)
            return <Redirect to="/settings/subscriptions" />;

        return loadingPlans ? (
            <ThemedSpinner />
        ) : (
            <Grid className="">
                <ErrorMessage error={error} />

                <div className="section-title full-width ">
                    <h1>Choose your plan</h1>
                    <p>
                        Start your employee awareness training and become better
                        equipped to handle security threats today
                    </p>
                </div>
                <Grid container justify="center" alignItems="center">
                    <Grid container item xs={12} spacing={4} justify={'center'}>
                        {basePlans.map((x) => {
                            console.log(x);
                            return (
                                <Grid item xs={12} md={5} lg={4} key={x.baseId}>
                                    <div
                                        className="subscription-plan
                                        subscription-plan-slider block
                                        block-center block-shadow "
                                    >
                                        <h2>{x.name}</h2>
                                        <div className="price">
                                            <span className="currency">
                                                <ThemedText faded>$</ThemedText>
                                            </span>
                                            <span className="price">
                                                {x.selectedTier &&
                                                    x.selectedTier.price / 100}
                                            </span>
                                            <span className="period">
                                                <ThemedText faded>
                                                    /{x.selected.period_unit}
                                                </ThemedText>
                                            </span>
                                        </div>
                                        <p className="plan-description">
                                            {x.selected.description}
                                        </p>

                                        <div className="switch">
                                            <ThemedSelectionText
                                                {...{
                                                    selected:
                                                        x.selected.id ===
                                                        x.monthly.id,
                                                }}
                                            >
                                                Monthly{' '}
                                            </ThemedSelectionText>
                                            <Switch
                                                checked={
                                                    x.selected.id ===
                                                    x.yearly.id
                                                }
                                                onChange={(event) => {
                                                    this.onPeriodChange(
                                                        event,
                                                        x.baseId
                                                    );
                                                }}
                                            />
                                            <ThemedSelectionText
                                                {...{
                                                    selected:
                                                        x.selected.id ===
                                                        x.yearly.id,
                                                }}
                                            >
                                                Yearly{' '}
                                                <ThemedDiscountBanner>
                                                    2 mo. free
                                                </ThemedDiscountBanner>
                                            </ThemedSelectionText>
                                        </div>
                                        <div>
                                            <TextInput
                                                select
                                                margin={'dense'}
                                                stretched
                                                variant={'outlined'}
                                                value={x.selectedSeats}
                                                onChange={(event) => {
                                                    this.onSeatsChange(
                                                        event,
                                                        x.baseId
                                                    );
                                                }}
                                            >
                                                {x.selected.tiers
                                                    .filter(
                                                        (x) => x.ending_unit
                                                    )
                                                    .map((t) => {
                                                        return (
                                                            <MenuItem
                                                                key={`${t.ending_unit}:${t.starting_unit}`}
                                                                value={
                                                                    t.ending_unit ||
                                                                    enterprise_units
                                                                }
                                                            >
                                                                {t.ending_unit ||
                                                                    `${t.starting_unit}+`}{' '}
                                                                seats
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </TextInput>
                                        </div>
                                        {(my && my.planId) === x.selected.id &&
                                            my.seats === x.selectedSeats && (
                                                <div className="current-plan orange ">
                                                    This is your current plan
                                                </div>
                                            )}
                                        <ul>
                                            {(
                                                x.selected.definition
                                                    .keyFacts || legacyKeyFacts
                                            ).map((fact, i) => {
                                                return (
                                                    <li key={i}>
                                                        {typeof fact ===
                                                        'string' ? (
                                                            <>
                                                                <ThemedCheckmarkSVG />

                                                                {substituteWithComponent(
                                                                    fact,
                                                                    '{user_price}',
                                                                    <ThemedText>
                                                                        $
                                                                        {
                                                                            x.perUserPrice
                                                                        }
                                                                    </ThemedText>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {fact.excluded ? (
                                                                    <CrossIcon />
                                                                ) : (
                                                                    <ThemedCheckmarkSVG />
                                                                )}

                                                                {substituteWithComponent(
                                                                    fact.text,
                                                                    '{user_price}',
                                                                    <ThemedText>
                                                                        $
                                                                        {
                                                                            x.perUserPrice
                                                                        }
                                                                    </ThemedText>
                                                                )}
                                                            </>
                                                        )}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                        <FlexDiv alignEnd flexGrow={1}>
                                            <ThemedSubscriptionButton
                                                primary
                                                disabled={
                                                    (my && my.planId) ===
                                                        x.selected.id &&
                                                    (my && my.seats) ===
                                                        x.selectedSeats
                                                }
                                                onClick={() => {
                                                    this.openPaymentModal(x);
                                                }}
                                            >
                                                Continue
                                            </ThemedSubscriptionButton>
                                        </FlexDiv>
                                    </div>
                                </Grid>
                            );
                        })}
                        <Grid item xs={12} md={4}>
                            <div className="subscription-plan subscription-plan-slider block block-center block-shadow ">
                                <h2>Enterprise</h2>
                                <div className="price"></div>
                                <p className="plan-description">
                                    For large businesses looking for the best
                                    support and greatest flexibility
                                </p>
                                <div className="switch"></div>
                                <div>
                                    <TextInput
                                        margin={'none'}
                                        stretched
                                        variant={'outlined'}
                                        value={`${enterprise_units}+ seats`}
                                        disabled
                                    />
                                </div>
                                <ul>
                                    <li>
                                        <ThemedCheckmarkSVG />
                                        Full access to all training and
                                        assessment materials
                                    </li>
                                    <li>
                                        <ThemedCheckmarkSVG />
                                        Regular platform and content updates
                                    </li>
                                    <li>
                                        <ThemedCheckmarkSVG />
                                        Integrations: Active Directory, Google
                                        Workspace, Teams, Slack
                                    </li>
                                    <li>
                                        <ThemedCheckmarkSVG />
                                        Single sign-on (SSO)
                                    </li>
                                    <li>
                                        <ThemedCheckmarkSVG />
                                        Custom branding
                                    </li>
                                    <li>
                                        <ThemedCheckmarkSVG />
                                        Content available as SCORM
                                    </li>

                                    <li>
                                        <ThemedCheckmarkSVG />
                                        Mobile friendly
                                    </li>
                                    <li>
                                        <ThemedCheckmarkSVG />
                                        <strong>Priority support </strong>
                                    </li>
                                    <li>
                                        <ThemedCheckmarkSVG />
                                        <strong>1000+ seats</strong>
                                    </li>
                                </ul>
                                <FlexDiv alignEnd flexGrow={1}>
                                    <ThemedSubscriptionButton
                                        as="a"
                                        primary
                                        href="https://www.awarego.com/contact/"
                                        target="_blank"
                                    >
                                        Contact us
                                    </ThemedSubscriptionButton>
                                </FlexDiv>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        item
                        justify="flex-start"
                        xs={12}
                        spacing={4}
                        className="description-text"
                    >
                        <Grid item>
                            <TextTiny>
                                {' '}
                                Pricing per user decreases as more employees are
                                added{' '}
                            </TextTiny>
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <br />
                <br />

                <Modal fade={false} isOpen={this.state.paymentModalMode === 1}>
                    <BillingForm
                        onComplete={this.openPaymentDetails}
                        onCancel={this.closePaymentModal}
                        onCouponEnetered={this.onCouponEnetered}
                    />
                </Modal>

                <Modal fade={false} isOpen={this.state.paymentModalMode === 2}>
                    <PaymentForm
                        companyId={companyId}
                        onComplete={this.doPayment}
                        prevStep={() => {
                            this.openPaymentModal();
                        }}
                        onCancel={this.closePaymentModal}
                    />
                </Modal>
                <Modal fade={false} isOpen={this.state.paymentModalMode === 3}>
                    <PaymentConfirm onComplete={this.goToDashboard} />
                </Modal>
                <ErrorMessage error={error} />
            </Grid>
        );
    }
}

export default withRouter(ChoosePlan);
