import {ThemedButton} from "../../components/themed/ThemedComponents";
import React from "react";
import {observer} from "mobx-react";
import ThemedBackArrow from "../../components/themed/ThemedBackArrow";
import ThemedForwardArrow from "../../components/themed/ThemedForwardArrow";


function AssessmentFooter({hasMoreQuestions, onRequestClose, questionIndex, questionCount, hasPreviousBlock, hasNextBlock,  onPreviousLesson, onNextLesson, onPrevBlock, onNextBlock}) {
  return <div className={"modalFooter"}>
    {!hasMoreQuestions
      ? <ThemedButton primary onClick={onRequestClose}>Back to add questions</ThemedButton>
      : <div className="footer-buttons">
          <span className="previous-btn" style={{opacity: questionIndex === 0 ? 0 : 1}} onClick={onPreviousLesson}>
            <ThemedBackArrow/>Previous question
          </span>
          <ThemedButton
            secondary
            disabled={questionIndex === 0 && !hasPreviousBlock}
            style={{marginLeft: 'auto'}}
            onClick={onPrevBlock}
          >
            Back
          </ThemedButton>
          {(questionIndex === questionCount - 1 && !hasNextBlock)
            ? <ThemedButton primary continue style={{marginLeft: '20px'}} onClick={onRequestClose}>Close</ThemedButton>
            : <ThemedButton primary style={{marginLeft: '20px'}} onClick={onNextBlock}>Next</ThemedButton>
          }
          <span className="previous-btn"
                style={{opacity: (questionIndex === questionCount - 1) ? 0 : 1,
                        marginLeft: 'auto'}}
                onClick={onNextLesson}>
            Skip to next question<ThemedForwardArrow/>
          </span>
        </div>
    }
  </div>;
}

export default observer(AssessmentFooter)