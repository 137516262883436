import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import uniq from 'lodash/uniq';
import {
    CardDeck,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Alert,
} from 'reactstrap';
import Sidebar from '../../components/manager/assessment/sidebar';
import KeyBehaviourCoverage from '../../components/manager/assessment/key-behaviour-coverage';
import Summary from '../../components/manager/wizard/summary';
import QuestionSelect from '../../components/manager/assessment/question-select';
import QuestionsOrder from '../../components/manager/assessment/questions-order';
import AssessmentDistribution from '../../components/manager/assessment/distribution';
import AssessmentReminders from '../../components/manager/assessment/reminders';
import UsersSelectForm from '../../components/manager/users/select-users-step';
import PersonalizeForm from '../../components/manager/assessment/personalize-form';
import PreviewEmail from '../../components/manager/assessment/preview-email';
import IntroductoryForm from '../../components/manager/wizard/intro-message';
import IntroductoryFormDef from '../../forms/introductoryForm';
import ErrorMessage from '../../components/errormessage';
import Modal from 'react-modal';
import Snackbar from '../../components/Snackbar';
import { Checkbox, FormControlLabel, MenuItem } from '@material-ui/core';
import {
    formatDate,
    querystring,
    isNameUntitled,
    stripStringOfHtml,
} from '../../utils/helpers';
import {
    ThemedCard,
    ThemedButton,
    ThemedText,
} from '../../components/themed/ThemedComponents';
import ThemedLinkButton from '../../components/themed/ThemedLinkButton';
import ThemedSuccessSVG from '../../components/themed/ThemedSuccessSVG';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import pluralize from 'pluralize';
import { ASSESSMENT_WIZARD_STEPS, STATUS } from '../../utils/constants';
import './AssessmentCreate.scss';
import ModalListEditor from '../users/ModalListEditor';
import ChangeName from '../../components/manager/wizard/change-name';
import PreviewQuestion from '../../components/manager/assessment/preview-question';
import SaveChanges from '../../components/manager/wizard/save-changes';
import moment from 'moment';

import WizardHeader from '../../components/manager/wizard/WizardHeader';
import SelectEmployees from '../../components/manager/wizard/select-employees';
import LockIcon from '@material-ui/icons/Lock';
import AlertWithIcon from '../../components/Alert';
import { TextTiny } from '../../components/design-system/texts';
import { BigModal } from '../../components/design-system/modals';
import { TextInput } from '../../components/design-system/inputs';
import {
    FlexDiv,
    SpaceBetweenDiv,
} from '../../components/design-system/containers';
import behaviours from '../../data/behaviours.json';

function AssessmentCreate({ companyId, copyFrom, editId, parentMatch }) {
    const {
        assessmentStore,
        commonStore,
        listStore,
        userStore,
        store,
        authStore,
        brandingStore,
    } = useStores();
    const [questionsModal, setQuestionsModal] = useState(false);
    const [recipientsModal, setRecipientsModal] = useState(false);
    const [addEmployeeModal, setAddEmployeeModal] = useState(false);
    const [previewQuestionsModal, setPreviewQuestionsModal] = useState(false);
    const [previewedQuestion, setPreviewedQuestion] = useState(false);
    const [createdAssessment, setCreatedAssessment] = useState(false);
    const [message, setMessage] = useState(null);
    const [messageShown, setMessageShown] = useState(false);
    const [messageType, setMessageType] = useState(null);
    const [nameChangeModal, setNameChangeModal] = useState(false);
    const [isDraft, setIsDraft] = useState(false);
    const [hasMoreQuestions, setHasMoreQuestions] = useState(false);
    const [saveChangesModal, setSaveChangesModal] = useState(false);
    const [emailPreview, setEmailPreview] = useState(false);
    const [area, setArea] = useState('all');
    const [behaviour, setBehaviour] = useState('all');

    const history = useHistory();
    const back = querystring('back');

    const {
        assessment,
        loadingCompanyData,
        step,
        steps,
        allowedSteps,
        questions,
        slackConnected,
        teamsConnected,
        error,
        templates,
        selectedTemplates,
        sendError,
        maxByAreaAndBehaviourForAllQuestions,
        personalizeForm,
    } = assessmentStore;

    const { currentUser } = authStore;
    const { listsWithUsers: lists, error: listError } = listStore;
    const { loadingUsers } = userStore;
    const {
        currentCompany,
        havePartnerSubscription,
        slackAvailable,
        teamsAvailable,
    } = store;
    const { supportEmail } = brandingStore;
    let createListDisabled = !currentCompany || !currentCompany.canAddUsers;
    const ongoing = assessment && assessment.status === STATUS.ONGOING;

    const localStore = useLocalStore(() => {
        let introductoryForm = new IntroductoryFormDef({}, {});

        introductoryForm.observe({
            path: 'message',
            key: 'value',
            call: ({ form }) => {
                form.submit();
                assessmentStore.toggleFinalStep(introductoryForm);
            },
        });

        return { introductoryForm };
    });

    useEffect(() => {
        loadData();
    }, [loadData]);

    const loadData = useCallback(async () => {
        await assessmentStore.prepareWizard(companyId, copyFrom, editId);
        localStore.introductoryForm.reset();
        localStore.introductoryForm.showErrors(false);
    }, [
        assessmentStore,
        companyId,
        copyFrom,
        editId,
        localStore.introductoryForm,
    ]);

    const toggleQuestionSelected = (questionId) => {
        assessment.toggleQuestionSelected(questionId);
        assessmentStore.togglePersonalizeStep();
    };

    const sendPreview = async (targetEmail) => {
        let result = await assessmentStore.sendPreview(
            companyId,
            targetEmail,
            localStore.introductoryForm.values()
        );
        if (result) {
            hideEmailPreview();
            showSnackBar('Preview sent successfully.');
        }
    };

    const openEmailPreview = () => {
        setEmailPreview(true);
    };

    const hideEmailPreview = () => {
        setEmailPreview(false);
    };

    const openNameChangeModal = (isDraft) => {
        assessment.setName('');
        setSaveChangesModal(false);
        setNameChangeModal(true);
        setIsDraft(isDraft);
    };

    const hideNameChangeModal = () => {
        setNameChangeModal(false);
    };

    const openSaveChangesModal = () => {
        setSaveChangesModal(true);
    };

    const hideSaveChangesModal = () => {
        setSaveChangesModal(false);
    };

    const toggleUserListSelected = (listId) => {
        assessment.toggleUserListSelected(listId);
        assessmentStore.toggleScheduleStep();
    };

    const reorderList = (sourceIndex, targetIndex) => {
        assessment.reorderQuestionsList(sourceIndex, targetIndex);
    };

    const onChangeScheduleType = (value) => {
        assessment.setScheduleType(value);
        assessmentStore.toggleRemindersStep();
    };

    const onChangeDate = (value) => {
        assessment.setDate(value);
        assessmentStore.toggleRemindersStep();
    };

    const onChangeReminder = (value, i, reminder) => {
        if (
            assessment.status === STATUS.ONGOING &&
            moment(value).isBefore(moment())
        ) {
            showSnackBar("Can't set a reminder to earlier than now!", 'error');
        } else {
            assessment.setReminder(value, i, reminder);
            assessmentStore.toggleIntroMessageStep();
        }
    };

    const onChangeRemindersEnabled = () => {
        assessment.setRemindersEnabled();
        assessmentStore.toggleIntroMessageStep();
    };

    const onDeleteReminderStep = (item) => {
        assessment.deleteReminderStep(item);
        assessmentStore.toggleIntroMessageStep();
    };

    const onAddReminderStep = () => {
        assessment.addReminderStep();
        assessmentStore.toggleIntroMessageStep();
    };

    const onChangeChannel = (event) => {
        if (
            !assessment.setChannel(
                Number(event.target.value),
                event.target.checked
            )
        ) {
            commonStore.warn('At least one channel needs to be selected');
        }
        assessmentStore.toggleRemindersStep();
    };

    const onChangeRemindersChannel = (event) => {
        if (
            !assessment.setRemindersChannel(
                Number(event.target.value),
                event.target.checked
            )
        ) {
            commonStore.warn('At least one channel needs to be selected');
        }
        assessmentStore.toggleIntroMessageStep();
    };

    const startAssessment = async () => {
        await localStore.introductoryForm.validate();
        await localStore.introductoryForm.showErrors(true);

        if (
            !assessment.introMessageEnabled ||
            localStore.introductoryForm.isValid
        ) {
            let result = await assessmentStore.startAssessment(companyId, {
                introductoryData: localStore.introductoryForm.values(),
                personalizeData: assessment.personalizeEnabled
                    ? personalizeForm.values()
                    : undefined,
            });
            if (result) {
                assessmentStore.setStep(ASSESSMENT_WIZARD_STEPS.START);
                localStore.introductoryForm.reset();
                setCreatedAssessment(result);
            } else {
                if (assessmentStore.error) {
                    showSnackBar(assessmentStore.error, 'error');
                }
            }
        }
    };

    const saveDraft = async () => {
        // let redirect = ( assessment.id == null )
        // if ( redirect )
        //     return history.push( `/assessment/edit/${assessment.id}`)
        const isNew = assessment.id === null;
        let result = await assessmentStore.saveDraft(companyId, {
            introductoryData: localStore.introductoryForm.values(),
            personalizeData: personalizeForm.values(),
        });
        if (result) {
            !isNew
                ? commonStore.success('Assessment updated!')
                : commonStore.success('Draft saved!');
            history.push('/assessment');
        } else {
            if (assessmentStore.error) {
                showSnackBar(assessmentStore.error, 'error');
            }
        }
    };

    const stepChange = (step) => {
        assessmentStore.setStep(step);
    };

    const onChangeName = (e) => {
        const result = stripStringOfHtml(e.target.value);
        assessment.setName(result);
    };

    const onChangeDueDate = (value) => {
        assessment.setDueDate(value);
        assessmentStore.toggleRemindersStep();
    };

    const toggleIntroMessage = () => {
        assessment.toggleIntroMessage();
        assessmentStore.toggleFinalStep(localStore.introductoryForm);
    };

    const selectTemplate = (template) => {
        assessmentStore.setTemplate(template.id);
    };

    const nextStep = () => {
        if (assessmentStore.step === ASSESSMENT_WIZARD_STEPS.PERSONALIZE) {
            assessment.personalizedKeys.map((pk) =>
                window.localStorage.setItem(
                    `${pk}-${store.currentCompany.company_id}`,
                    personalizeForm.$(pk).value
                )
            );
        }

        if (assessmentStore.step === ASSESSMENT_WIZARD_STEPS.FINALIZE)
            if (
                assessment.name === 'Untitled Assessment' ||
                assessment.name === ''
            ) {
                openNameChangeModal(false);
            } else {
                startAssessment();
            }
        else {
            let index = assessmentStore.steps.findIndex(
                (x) => x.number === assessmentStore.step
            );
            let nextStep = assessmentStore.steps[index + 1]
                ? assessmentStore.steps[index + 1].number
                : assessmentStore.step + 1;
            assessmentStore.setStep(nextStep);
            assessmentStore.togglePersonalizeStep();
            assessmentStore.toggleRecipientsStep(personalizeForm);
            assessmentStore.toggleRemindersStep();
            assessmentStore.toggleIntroMessageStep();
            assessmentStore.toggleFinalStep(localStore.introductoryForm);
        }
    };

    const prevStep = () => {
        assessmentStore.setStep(assessmentStore.step - 1);
    };

    const showQuestionsModal = () => {
        setQuestionsModal(true);
    };

    const hideQuestionsModal = () => {
        setQuestionsModal(false);
    };

    const showRecipientsModal = () => {
        setRecipientsModal(true);
    };

    const hideRecipientsModal = () => {
        setRecipientsModal(false);
    };

    const showPreviewQuestionsModal = (questionId, type) => {
        setPreviewQuestionsModal(true);
        setPreviewedQuestion(questionId);
        setHasMoreQuestions(type);
    };

    const hidePreviewQuestionsModal = () => {
        setPreviewQuestionsModal(false);
    };

    const showAddEmployeeModal = () => {
        setAddEmployeeModal(true);
    };

    const hideAddEmployeeModal = () => {
        setAddEmployeeModal(false);
    };

    const createRecipientsList = async (list) => {
        list.users = list.users.filter((x) => x.email);
        let result = await listStore.save(companyId, null, list);
        if (result) {
            hideRecipientsModal();
            toggleUserListSelected(result);
            if (!addEmployeeModal) showAddEmployeeModal();
        }
    };

    const requestUsersForList = (listId) => {
        userStore.loadUsers(companyId, listId, true);
    };

    const showSnackBar = (message, messageType = 'success') => {
        setMessage(message);
        setMessageShown(true);
        setMessageType(messageType);
    };

    const closeSnackbar = () => {
        setMessage(null);
        setMessageShown(false);
        setMessageType(null);
    };

    const dateFormat = 'L (LT) [GMT]';

    const getTemplateQuestionsStats = (template) => {
        if (!template) return null;

        const questionCount =
            template.questions.length + template.disabledQuestions.length;
        if (questionCount === 0) return null;

        const text = `${questionCount} questions · ${
            template.verboseDuration
        } ${pluralize('minute', template.verboseDuration)}`;

        return text;
    };

    const getTemplateQuestionsAvailabilityElement = (template) => {
        if (!template) return null;
        if (
            !template.disabledQuestions ||
            template.disabledQuestions.length === 0
        ) {
            return null;
        }

        const text = `${template.disabledQuestions.length}/${
            template.questions.length + template.disabledQuestions.length
        } questions unavailable in your plan`;

        return (
            <TextTiny bold error>
                <LockIcon fontSize={'inherit'} /> {text}
            </TextTiny>
        );
    };

    const getReviewQuestionsAvailabilityElement = () => {
        const selectedQuestions = assessment.questionsSelected;

        if (!selectedQuestions) return null;

        const disabledQuestionsCount =
            assessment.unavailableQuestionsSelected &&
            assessment.unavailableQuestionsSelected.length;

        if (disabledQuestionsCount === 0) return null;

        const count = `${disabledQuestionsCount}/${selectedQuestions.length}`;

        return havePartnerSubscription ? (
            <AlertWithIcon severity="error">
                {count} selected questions are unavailable in your plan. Please{' '}
                contact <a href={`mailto:${supportEmail}`}>{supportEmail}</a> to
                upgrade or remove unavailable.
            </AlertWithIcon>
        ) : (
            <AlertWithIcon severity="error">
                {count} selected questions are unavailable in your plan. Please{' '}
                <a href="/subscriptions/upgrade">upgrade</a>
                {/* or <a href="#">remove unavailable</a> */}.
            </AlertWithIcon>
        );
    };

    const summaryOutput = assessment && [
        {
            name: 'Name',
            detail: assessment.name,
        },
        {
            name: 'Threat areas',
            detail: (
                <div>
                    {/* if more questions have the same area, show this area only once */}
                    {[
                        ...new Set(
                            assessment.questionsSelected.map((q) => q.area)
                        ),
                    ].map((area, j, arr) => (
                        <span key={j}>
                            {/* don't put comma after last area */}
                            {area}
                            {j !== arr.length - 1 ? ', ' : ''}
                        </span>
                    ))}
                </div>
            ),
        },
        {
            name: 'Delivery date',
            detail:
                assessment.date !== null
                    ? moment(assessment.date).format(dateFormat)
                    : 'Immediately',
        },
        {
            name: 'Due date',
            detail:
                assessment.due !== null
                    ? moment(assessment.due).format(dateFormat)
                    : '—',
        },
        {
            name: 'Reminders',
            detail: assessment.reminders_enabled
                ? assessment.reminders_steps.filter(
                      (reminder) => reminder !== null
                  ).length
                : '—',
        },
        {
            name: `Employees (${assessment.selectedUserCount})`,
            detail: (
                <div>
                    {assessment.userListsSelected &&
                        assessment.userListsSelected.map((list, j, arr) => (
                            <span key={j}>
                                {/* don't put comma after last list name */}
                                {list && list.name}
                                {j !== arr.length - 1 ? ', ' : ''}
                            </span>
                        ))}
                </div>
            ),
        },
    ];

    return (
        <Fragment>
            <ErrorMessage error={error} />
            {loadingCompanyData || !assessment ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    {assessment.status === STATUS.COMPLETED ? (
                        "You can't edit an already completed assessment"
                    ) : currentCompany && currentCompany.canCreateTraining ? (
                        <div className={'training-wizard'}>
                            <WizardHeader
                                selectedTemplate={selectedTemplates}
                                isStartStep={
                                    step === ASSESSMENT_WIZARD_STEPS.START
                                }
                                openNameChangeModal={openNameChangeModal}
                                openSaveChangesModal={openSaveChangesModal}
                                item={assessment}
                                onChangeName={onChangeName}
                                itemString="assessment"
                                saveDraft={saveDraft}
                                isNameUntitled={isNameUntitled(
                                    assessment.name,
                                    'assessment'
                                )}
                            />
                            {assessment.id !== null &&
                                (assessment.status === STATUS.SCHEDULED ||
                                    ongoing) && (
                                    <div className="assessment-wizard-info-message">
                                        <span style={{ fontWeight: 700 }}>
                                            Edit assessment&nbsp;
                                        </span>{' '}
                                        {ongoing &&
                                            '– Some parts of the assessment can not be modified as employees have already received questions.'}
                                    </div>
                                )}
                            <div className={'training-wizard-body'}>
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    autoHideDuration={15000}
                                    variant={messageType}
                                    message={message || ''}
                                    open={messageShown}
                                    onClose={closeSnackbar}
                                />
                                {step !== ASSESSMENT_WIZARD_STEPS.START && (
                                    <Sidebar
                                        steps={steps}
                                        activeStep={step}
                                        allowedSteps={allowedSteps}
                                        onStepChange={stepChange}
                                        warningSteps={[
                                            assessment.hasUnavailableQuestions
                                                ? ASSESSMENT_WIZARD_STEPS.CONTENT
                                                : null,
                                        ]}
                                        // extraInfo={assessment.id && (ongoing)}
                                    />
                                )}
                                <div
                                    className={classnames(
                                        'training-wizard-content',
                                        {
                                            'full-width':
                                                step ===
                                                ASSESSMENT_WIZARD_STEPS.START,
                                        }
                                    )}
                                >
                                    {step === ASSESSMENT_WIZARD_STEPS.GOAL && (
                                        <Fragment>
                                            <div className="training-wizard-body-header">
                                                {assessment.id !== null &&
                                                ongoing ? (
                                                    <div>
                                                        <h3>
                                                            Selected template
                                                            for your assessment
                                                        </h3>
                                                        <p>
                                                            This is a template
                                                            set for this
                                                            assessment
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <h3>
                                                            Set a template for
                                                            your assessment
                                                        </h3>
                                                        <p>
                                                            Use one of our
                                                            ready-made set of
                                                            questions. You can
                                                            always add
                                                            additional
                                                            questions.
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                            <br />
                                            <CardDeck
                                                className={'training-templates'}
                                            >
                                                {(ongoing
                                                    ? templates.filter((t) =>
                                                          selectedTemplates.includes(
                                                              t.id
                                                          )
                                                      )
                                                    : templates
                                                ).map((t) =>
                                                    t.hidden ||
                                                    !t.enabled ? null : (
                                                        <ThemedCard
                                                            key={t.id}
                                                            outline
                                                            disabled
                                                            {...{
                                                                selected:
                                                                    selectedTemplates.includes(
                                                                        t.id
                                                                    ),
                                                            }}
                                                            className={classnames(
                                                                'training-template',
                                                                {
                                                                    disabled:
                                                                        !t.enabled,
                                                                },
                                                                `training-template-${t.id}`
                                                            )}
                                                            onClick={() => {
                                                                if (
                                                                    t.enabled &&
                                                                    !ongoing
                                                                )
                                                                    selectTemplate(
                                                                        t
                                                                    );
                                                            }}
                                                        >
                                                            <CardBody>
                                                                <CardTitle>
                                                                    <h3>
                                                                        {
                                                                            t.title
                                                                        }
                                                                    </h3>
                                                                </CardTitle>
                                                                <CardText>
                                                                    {
                                                                        t.description
                                                                    }
                                                                </CardText>
                                                                <CardSubtitle>
                                                                    <span>
                                                                        {getTemplateQuestionsStats(
                                                                            t
                                                                        )}
                                                                    </span>
                                                                </CardSubtitle>
                                                                {getTemplateQuestionsAvailabilityElement(
                                                                    t
                                                                )}
                                                            </CardBody>
                                                        </ThemedCard>
                                                    )
                                                )}
                                            </CardDeck>
                                        </Fragment>
                                    )}
                                    {step ===
                                        ASSESSMENT_WIZARD_STEPS.CONTENT && (
                                        <Fragment>
                                            <div className="training-wizard-body-header">
                                                <div>
                                                    <h3>Review questions</h3>
                                                    {ongoing ? (
                                                        <p>
                                                            You cannot modify
                                                            questions since
                                                            those were already
                                                            sent to people.
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            You can edit or add
                                                            more questions to
                                                            this set of
                                                            questions.
                                                        </p>
                                                    )}
                                                    <ThemedText
                                                        bold
                                                        style={{
                                                            fontSize: '16px',
                                                        }}
                                                    >
                                                        {
                                                            assessment
                                                                .questionsSelected
                                                                .length
                                                        }{' '}
                                                        {pluralize(
                                                            'question',
                                                            assessment
                                                                .questionsSelected
                                                                .length
                                                        )}{' '}
                                                        ·{' '}
                                                        {
                                                            assessment.selectedQuestionsDuration
                                                        }{' '}
                                                        {pluralize(
                                                            'minute',
                                                            assessment.selectedQuestionsDuration
                                                        )}
                                                    </ThemedText>
                                                </div>
                                                {!ongoing && (
                                                    <div className="action">
                                                        <ThemedButton
                                                            secondary
                                                            onClick={
                                                                showQuestionsModal
                                                            }
                                                        >
                                                            Add Questions
                                                        </ThemedButton>
                                                    </div>
                                                )}
                                            </div>

                                            {getReviewQuestionsAvailabilityElement()}

                                            <QuestionsOrder
                                                questions={
                                                    assessment.questionsSelected
                                                }
                                                onRemoveQuestion={
                                                    toggleQuestionSelected
                                                }
                                                onReorderList={reorderList}
                                                showPreviewQuestionsModal={
                                                    showPreviewQuestionsModal
                                                }
                                                ongoing={ongoing}
                                            />
                                        </Fragment>
                                    )}
                                    {step ===
                                        ASSESSMENT_WIZARD_STEPS.PERSONALIZE && (
                                        <Fragment>
                                            <div className="training-wizard-body-header">
                                                <div>
                                                    <h3>
                                                        Personalize to make it
                                                        relateable
                                                    </h3>
                                                    <p>
                                                        We use this information
                                                        to make the assessment
                                                        questions more realistic
                                                        and relateable.
                                                    </p>
                                                </div>
                                            </div>
                                            <PersonalizeForm
                                                form={personalizeForm}
                                                editable={
                                                    assessment.personalizeEnabled
                                                }
                                                ongoing={ongoing}
                                                personalizedKeys={
                                                    assessment.personalizedKeys
                                                }
                                            />
                                        </Fragment>
                                    )}

                                    {
                                        step ===
                                            ASSESSMENT_WIZARD_STEPS.EMPLOYEES && (
                                            <SelectEmployees
                                                itemString="assessment"
                                                item={assessment}
                                                showRecipientsModal={
                                                    showRecipientsModal
                                                }
                                                showAddEmployeeModal={
                                                    showAddEmployeeModal
                                                }
                                                lists={lists}
                                                toggleUserListSelected={
                                                    toggleUserListSelected
                                                }
                                                ongoing={ongoing}
                                            />
                                        )
                                        //     <Fragment>
                                        //     <div className="training-wizard-body-header">
                                        //         <div>
                                        //             <h3>Select employees for this assessment</h3>
                                        //             <p> {assessment.selectedUserCount} {pluralize("employee", assessment.selectedUserCount)} selected</p>
                                        //         </div>
                                        //         {assessment.selectedUserCount > 0
                                        //             && <ThemedButton
                                        //                 onClick={showAddEmployeeModal}
                                        //                 secondary
                                        //             >
                                        //                 Select employees
                                        //             </ThemedButton>
                                        //         }
                                        //     </div>
                                        //     <div className="table">
                                        //         {lists.length > 0
                                        //             ? assessment.selectedUserCount > 0
                                        //                 ? <UsersListsOverviewWithoutCheck
                                        //                     lists={assessment.userListsSelected}
                                        //                     onRemoveList={toggleUserListSelected}
                                        //                     assessment={assessment}
                                        //                     ongoing={ongoing}
                                        //                 />
                                        //                 : <div className="training-wizard-content center">
                                        //                     <img src="/static/img/icon-user-list.svg" alt="No employees selected yet"/>
                                        //                     <div className="stats">
                                        //                         <h5>No employees selected yet</h5>
                                        //                         <p>Select employees who you want to send this assessment to.</p>
                                        //                     </div>
                                        //                     <ThemedButton
                                        //                         onClick={showAddEmployeeModal}
                                        //                         secondary
                                        //                     >
                                        //                         Select employees
                                        //                     </ThemedButton>
                                        //                 </div>
                                        //             : <div className="training-wizard-content center">
                                        //                     <img src="/static/img/icon-user-list.svg" alt="No employee lists yet"/>
                                        //                     <div className="stats">
                                        //                         <h5>No employee lists yet</h5>
                                        //                         <p>Please create a list of employees who you want to send this assessment to.</p>
                                        //                     </div>
                                        //                     <ThemedButton
                                        //                         onClick={showRecipientsModal}
                                        //                         secondary
                                        //                     >
                                        //                         Create new list
                                        //                     </ThemedButton>
                                        //                 </div>
                                        //         }
                                        //     </div>
                                        // </Fragment>
                                    }

                                    {step ===
                                        ASSESSMENT_WIZARD_STEPS.SCHEDULE && (
                                        <Fragment>
                                            <h3>Schedule</h3>
                                            {ongoing ? (
                                                <p>
                                                    Modify delivery channels and
                                                    due date for this
                                                    assessment.
                                                </p>
                                            ) : (
                                                <p>
                                                    Set the time of delivery and
                                                    due date for this assessment
                                                </p>
                                            )}
                                            <AssessmentDistribution
                                                ongoing={ongoing}
                                                scheduledDate={assessment.date}
                                                scheduleType={
                                                    assessment.scheduleType
                                                }
                                                channel={assessment.channel}
                                                slackAvailable={slackAvailable}
                                                slackConnected={slackConnected}
                                                teamsAvailable={teamsAvailable}
                                                teamsConnected={teamsConnected}
                                                dueDate={assessment.due}
                                                onChangeScheduleType={
                                                    onChangeScheduleType
                                                }
                                                onChangeChannel={
                                                    onChangeChannel
                                                }
                                                onChangeDate={onChangeDate}
                                                onChangeDueDate={
                                                    onChangeDueDate
                                                }
                                            />
                                            <br />
                                        </Fragment>
                                    )}

                                    {step ===
                                        ASSESSMENT_WIZARD_STEPS.REMINDERS && (
                                        <Fragment>
                                            <h3>Reminders (optional)</h3>
                                            <p>
                                                Send a reminder to employees to
                                                not forget the assessment
                                            </p>

                                            <AssessmentReminders
                                                reminders_enabled={
                                                    assessment.reminders_enabled
                                                }
                                                reminders_steps={
                                                    assessment.reminders_steps
                                                }
                                                channel={
                                                    assessment.reminders_channel
                                                }
                                                slackAvailable={
                                                    currentCompany.slackAvailable
                                                }
                                                slackConnected={slackConnected}
                                                teamsAvailable={
                                                    currentCompany.teamsAvailable
                                                }
                                                teamsConnected={teamsConnected}
                                                onChangeRemindersEnabled={
                                                    onChangeRemindersEnabled
                                                }
                                                onChangeReminder={
                                                    onChangeReminder
                                                }
                                                onChangeChannel={
                                                    onChangeRemindersChannel
                                                }
                                                onAddReminderStep={
                                                    onAddReminderStep
                                                }
                                                onDeleteReminderStep={
                                                    onDeleteReminderStep
                                                }
                                                scheduledDate={assessment.date}
                                                dueDate={assessment.due}
                                            />
                                            <br />
                                        </Fragment>
                                    )}

                                    {step ===
                                        ASSESSMENT_WIZARD_STEPS.INTROMESSAGE && (
                                        <Fragment>
                                            <h3>Intro message (optional)</h3>
                                            <p>
                                                Add a personal message and
                                                introduce this assessment to
                                                your recipients.
                                            </p>

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            assessment.introMessageEnabled
                                                        }
                                                        onChange={
                                                            toggleIntroMessage
                                                        }
                                                        name="introMessage"
                                                        color="primary"
                                                    />
                                                }
                                                label="Include introductory message"
                                            />
                                            <div>
                                                {assessment.introMessageEnabled && (
                                                    <IntroductoryForm
                                                        form={
                                                            localStore.introductoryForm
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <br />
                                        </Fragment>
                                    )}

                                    {step ===
                                        ASSESSMENT_WIZARD_STEPS.FINALIZE && (
                                        <Fragment>
                                            <h3>Finalize Assessment</h3>
                                            {ongoing ? (
                                                <p>
                                                    Check out the summary before
                                                    applying changes
                                                </p>
                                            ) : (
                                                <p>
                                                    Check out the summary before
                                                    you create the assessment
                                                </p>
                                            )}

                                            {getReviewQuestionsAvailabilityElement()}

                                            <Summary
                                                summaryOutput={summaryOutput}
                                            />
                                            <br />
                                        </Fragment>
                                    )}

                                    {step === ASSESSMENT_WIZARD_STEPS.START && (
                                        <div className="block wide steps step1 ">
                                            <div className="forms">
                                                <div className="form-header ">
                                                    <h3 className="flex-center">
                                                        Success!
                                                    </h3>
                                                    <div className="hide-mobile">
                                                        <ThemedSuccessSVG />
                                                    </div>
                                                    <div className="hide-desktop">
                                                        <img
                                                            src="/static/img/success.svg"
                                                            alt="Email sent"
                                                        />
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <p>
                                                        {createdAssessment &&
                                                            createdAssessment.name}{' '}
                                                        assessment has{' '}
                                                        {ongoing ||
                                                        assessment.status ===
                                                            STATUS.SCHEDULED
                                                            ? 'been successfully updated!'
                                                            : 'succesfully started!'}
                                                        {assessment.scheduleType ===
                                                            0 &&
                                                            !ongoing &&
                                                            ` Assessment invitation will be sent on ${
                                                                assessment.date &&
                                                                formatDate(
                                                                    assessment.date
                                                                )
                                                            }`}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {step !== ASSESSMENT_WIZARD_STEPS.START && (
                                    <KeyBehaviourCoverage
                                        questionsSelected={
                                            assessment.questionsSelected
                                        }
                                        maxByAreaAndBehaviourForSelectedQuestions={
                                            assessment.maxByAreaAndBehaviourForSelectedQuestions
                                        }
                                        maxByAreaAndBehaviourForAllQuestions={
                                            maxByAreaAndBehaviourForAllQuestions
                                        }
                                        visibility={
                                            step ===
                                            ASSESSMENT_WIZARD_STEPS.CONTENT
                                        }
                                    />
                                )}
                            </div>
                            <div className={'training-wizard-footer'}>
                                <div className={'actions'}>
                                    {step !== ASSESSMENT_WIZARD_STEPS.GOAL &&
                                        step !==
                                            ASSESSMENT_WIZARD_STEPS.START && (
                                            <span
                                                className="previous-btn"
                                                onClick={prevStep}
                                            >
                                                Previous
                                            </span>
                                        )}
                                    <div className="actions-align-right">
                                        {step ===
                                            ASSESSMENT_WIZARD_STEPS.FINALIZE && (
                                            <ThemedLinkButton
                                                secondary
                                                footer
                                                onClick={openEmailPreview}
                                                disabled={
                                                    assessment.hasUnavailableQuestions
                                                }
                                            >
                                                Send a Preview
                                            </ThemedLinkButton>
                                        )}
                                        {step ===
                                        ASSESSMENT_WIZARD_STEPS.START ? (
                                            <ThemedLinkButton
                                                primary
                                                footer
                                                to={
                                                    (parentMatch &&
                                                        parentMatch.url) ||
                                                    '/'
                                                }
                                            >
                                                Close
                                            </ThemedLinkButton>
                                        ) : (
                                            <ThemedLinkButton
                                                primary
                                                continue
                                                disabled={
                                                    (!allowedSteps.includes(
                                                        step + 1
                                                    ) &&
                                                        step !==
                                                            ASSESSMENT_WIZARD_STEPS.FINALIZE) ||
                                                    (assessment.hasUnavailableQuestions &&
                                                        step ===
                                                            ASSESSMENT_WIZARD_STEPS.FINALIZE)
                                                }
                                                size={'lg'}
                                                onClick={nextStep}
                                            >
                                                {step ===
                                                ASSESSMENT_WIZARD_STEPS.FINALIZE
                                                    ? assessment.id
                                                        ? assessment.status ===
                                                          STATUS.DRAFT
                                                            ? 'Activate'
                                                            : 'Update'
                                                        : 'Create'
                                                    : 'Continue'}
                                            </ThemedLinkButton>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <BigModal
                                isOpen={questionsModal}
                                onRequestClose={hideQuestionsModal}
                                heading="Add questions"
                                filterContent={
                                    <FlexDiv alignCenter>
                                        <TextInput
                                            select
                                            value={area || 'all'}
                                            onChange={(e) => {
                                                setArea(e.target.value);
                                                if (behaviour !== 'all') {
                                                    setBehaviour('all');
                                                }
                                            }}
                                            inline
                                        >
                                            <MenuItem value="all">
                                                All Threat Areas
                                            </MenuItem>
                                            {uniq(
                                                questions.map((q) => {
                                                    return q.area;
                                                })
                                            ).map((a) => (
                                                <MenuItem value={a} key={a}>
                                                    {a}
                                                </MenuItem>
                                            ))}
                                        </TextInput>
                                        <TextInput
                                            select
                                            value={behaviour || 'all'}
                                            onChange={(e) => {
                                                setBehaviour(e.target.value);
                                            }}
                                            disabled={area === 'all'}
                                            inline
                                            limitWidth
                                            airy
                                        >
                                            <MenuItem value="all">
                                                All Key Behaviours
                                            </MenuItem>
                                            {behaviours
                                                .filter((a) => a.name === area)
                                                .map((a) =>
                                                    a.behaviours.map((b) => (
                                                        <MenuItem
                                                            value={b.code}
                                                            key={b.code}
                                                        >
                                                            {b.name}
                                                        </MenuItem>
                                                    ))
                                                )}
                                        </TextInput>
                                        {(area !== 'all' ||
                                            behaviour !== 'all') && (
                                            <ThemedButton
                                                secondary
                                                noTextTransform
                                                airy
                                                tiny
                                                onClick={() => {
                                                    setArea('all');
                                                    setBehaviour('all');
                                                }}
                                            >
                                                Clear
                                            </ThemedButton>
                                        )}
                                    </FlexDiv>
                                }
                                modalContent={
                                    <QuestionSelect
                                        selection={
                                            assessment.selectedQuestionsIds
                                        }
                                        questions={questions}
                                        onQuestionSelected={
                                            toggleQuestionSelected
                                        }
                                        showPreviewQuestionsModal={
                                            showPreviewQuestionsModal
                                        }
                                        area={area}
                                        behaviour={behaviour}
                                    />
                                }
                                modalFooterContent={
                                    <SpaceBetweenDiv>
                                        <TextTiny themed bold>
                                            {
                                                assessment.selectedQuestionsIds
                                                    .length
                                            }{' '}
                                            {pluralize(
                                                'question',
                                                assessment.selectedQuestionsIds
                                                    .length
                                            )}{' '}
                                            added
                                        </TextTiny>
                                        <ThemedButton
                                            primary
                                            onClick={hideQuestionsModal}
                                        >
                                            Done
                                        </ThemedButton>
                                    </SpaceBetweenDiv>
                                }
                            />
                            <Modal
                                isOpen={previewQuestionsModal}
                                onRequestClose={hidePreviewQuestionsModal}
                                className="preview-questions-modal"
                            >
                                <PreviewQuestion
                                    questionId={previewedQuestion}
                                    onRequestClose={hidePreviewQuestionsModal}
                                    hasMoreQuestions={hasMoreQuestions}
                                    companyId={companyId}
                                    questionsSelected={assessment.questionsSelected.map(
                                        (x) => x.id
                                    )}
                                />
                            </Modal>

                            <Modal
                                isOpen={addEmployeeModal}
                                onRequestClose={hideAddEmployeeModal}
                                className="Modal schedule"
                            >
                                <UsersSelectForm
                                    lists={lists}
                                    currentCompany={currentCompany}
                                    showRecipientsModal={showRecipientsModal}
                                    recipientsModal={recipientsModal}
                                    hideRecipientsModal={hideRecipientsModal}
                                    createRecipientsList={createRecipientsList}
                                    item={assessment}
                                    error={listError}
                                    loadingUsers={loadingUsers}
                                    requestUsersForList={requestUsersForList}
                                    selectedLists={
                                        assessment.selectedUserListsIds
                                    }
                                    selectedUserCount={
                                        assessment.selectedUserCount
                                    }
                                    onUserListSelected={toggleUserListSelected}
                                    hideAddEmployeeModal={hideAddEmployeeModal}
                                    disabled={createListDisabled}
                                    ongoing={ongoing}
                                    itemString="assessment"
                                />
                            </Modal>
                            <Modal
                                isOpen={recipientsModal}
                                onRequestClose={hideRecipientsModal}
                                className="Modal schedule"
                            >
                                <ModalListEditor
                                    result={assessment}
                                    error={listError}
                                    onRequestClose={hideRecipientsModal}
                                    createRecipientsList={createRecipientsList}
                                />
                            </Modal>
                            <Modal
                                isOpen={emailPreview}
                                onRequestClose={hideEmailPreview}
                                className="Modal schedule not-wide"
                            >
                                <PreviewEmail
                                    ownEmail={currentUser.email}
                                    onRequestClose={hideEmailPreview}
                                    sendPreview={sendPreview}
                                    sendError={sendError}
                                />
                            </Modal>
                            <Modal
                                isOpen={nameChangeModal}
                                onRequestClose={hideNameChangeModal}
                                className="Modal schedule not-wide"
                            >
                                <ChangeName
                                    name={assessment.name}
                                    onRequestClose={hideNameChangeModal}
                                    nextStep={nextStep}
                                    onChangeName={onChangeName}
                                    isDraft={isDraft}
                                    saveDraft={saveDraft}
                                    itemString="assessment"
                                    isNameUntitled={isNameUntitled(
                                        assessment.name,
                                        'assessment'
                                    )}
                                />
                            </Modal>
                            <Modal
                                isOpen={saveChangesModal}
                                onRequestClose={hideSaveChangesModal}
                                className="Modal schedule not-wide"
                            >
                                <SaveChanges
                                    onRequestClose={hideSaveChangesModal}
                                    isNameUntitled={isNameUntitled(
                                        assessment.name,
                                        'assessment'
                                    )}
                                    saveDraft={saveDraft}
                                    openNameChangeModal={openNameChangeModal}
                                    back={back}
                                    parentMatch={parentMatch}
                                    editing={
                                        assessment.status === STATUS.ONGOING ||
                                        assessment.status ===
                                            STATUS.SCHEDULED ||
                                        assessment.status === STATUS.DRAFT
                                    }
                                />
                            </Modal>
                        </div>
                    ) : (
                        <Fragment>
                            <br />
                            <br />
                            <br />
                            <ErrorMessage>
                                <Alert
                                    color="warning"
                                    className="header"
                                    isOpen={true}
                                >
                                    No license available
                                </Alert>
                            </ErrorMessage>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
}

export default observer(AssessmentCreate);
