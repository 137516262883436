import React, { Fragment } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import AssignCredit from '../components/manager/dashboard/assign-credit';
import NewsFeed from '../components/manager/dashboard/newsfeed';
import NewSubjects from '../components/manager/dashboard/newsubjects';
import NewCourse from '../components/manager/dashboard/newcourse';
import {
    Row,
    Col,
    TabContent,
    TabPane,
    Popover,
    PopoverBody,
} from 'reactstrap';
import BaseCompanyDependentContainer from './common/BaseCompanyDependentContainer';
import {
    ThemedText,
    ThemedNav,
    ThemedNavItem,
    ThemedNavLink,
} from '../components/themed/ThemedComponents';
import ThemedSpinner from '../components/themed/ThemedSpinner';
import ThemedNewsListSVG from '../components/themed/ThemedNewsListSVG';
import ThemedUsersSVG from '../components/themed/ThemedUsersSVG';
import { Helmet } from 'react-helmet';
import RadarChart from 'react-svg-radar-chart';
import SemiCircleProgress from '../components/manager/assessment/semi-circle-progress';
import 'react-svg-radar-chart/build/css/index.css';
import NoStats from '../components/manager/assessment/no-stats';

const data = [
    {
        data: {
            battery: 0.7,
            design: 0.8,
            useful: 0.9,
            speed: 0.67,
            weight: 0.8,
        },
        meta: { color: 'blue' },
    },
    {
        data: {
            battery: 0.6,
            design: 0.85,
            useful: 0.5,
            speed: 0.6,
            weight: 0.7,
        },
        meta: { color: 'red' },
    },
];

const captions = {
    // columns
    battery: 'General',
    design: 'GDPR',
    useful: 'At Home',
    speed: 'Passwords',
    weight: 'In Office',
};

@inject('store', 'automationStore')
@observer
class Home extends BaseCompanyDependentContainer {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: '1',
        };
    }

    async doLoadData() {
        return await this.props.store.loadSummary(this.props.companyId);
    }

    tabsChange(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    render() {
        const {
            serverData,
            currentCompany,
            activeCount,
            completedCount,
            noStats,
            loadingSummary,
        } = this.props.store;

        const isLoading = loadingSummary.includes(this.props.companyId);
        // console.log("render home")
        return (
            <Fragment>
                <Helmet>
                    <title>Report for Training Assessment</title>
                </Helmet>
                <Row>
                    <Col lg="8" xs="12">
                        {/*TODO: load it personally*/}
                        {serverData &&
                            serverData.unusedPackages &&
                            serverData.unusedPackages.length > 0 && (
                                <div>
                                    {serverData.unusedPackages.map(
                                        (pack, i) => (
                                            <AssignCredit
                                                key={i}
                                                unusedPackage={pack}
                                                companies={serverData.companies}
                                            />
                                        )
                                    )}
                                </div>
                            )}

                        {!!currentCompany && isLoading ? (
                            <ThemedSpinner />
                        ) : noStats ? (
                            <NoStats url={this.props.match.url} />
                        ) : (
                            <div className="lists">
                                {/*<AutomationList
                                hideFilter={true}
                                companyId={currentCompany.company_id}
                                parentMatch={this.props.match}
                            hideEmptyListCreateButton={true} />*/}
                                <Row>
                                    <Col lg="5" xs="12">
                                        <div
                                            className={
                                                'block stub block-header'
                                            }
                                        >
                                            <h3>General overview</h3>
                                            <SemiCircleProgress
                                                percentage={65}
                                            />
                                            <div className={'level red'}>
                                                Level 0
                                            </div>
                                            <p>
                                                No security awareness assessment
                                                data yet. Start running
                                                assessments to get your company
                                                Resilience score.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col lg="7" xs="12">
                                        <div
                                            className={
                                                'block stub block-header'
                                            }
                                        >
                                            <h3>Assessment Scores:</h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col></Col>
                                    <Col>
                                        <h4>Your resilience score </h4>
                                        <p>
                                            The Resilience score is a measure of
                                            how resilient your company is to
                                            attempts of a human engineered cyber
                                            security attack.
                                        </p>
                                        <p>
                                            After you start running awareness
                                            assessment tests, your company score
                                            will be updated and you’ll be able
                                            to track the value of AwareGO
                                            security awareness training.
                                        </p>
                                        <Row>
                                            <Col>
                                                <Link>Create assessment</Link>
                                            </Col>
                                            <Col>
                                                <Link>Learn more</Link>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className={'block stats center stub'}>
                                    <Col>
                                        <div>
                                            <div
                                                className={'question-mark'}
                                                id={'Popover1'}
                                                onClick={() => {
                                                    this.toggleHelp('avgscore');
                                                }}
                                            />
                                            <h3>Experience it in action</h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div
                                            className={
                                                'block stats center stub'
                                            }
                                        >
                                            {/* TODO: Show only arrow when numbers are up or down */}
                                            {/* <div className={"progress-arrow up"}></div> */}
                                            <div
                                                className={'question-mark'}
                                                id={'Popover1'}
                                                onClick={() => {
                                                    this.toggleHelp('avgscore');
                                                }}
                                            />
                                            <h3>Assessment Score</h3>
                                            <Popover
                                                placement="bottom"
                                                isOpen={this.state.popoverOpen}
                                                target={'Popover1'}
                                                toggle={this.toggleHelp}
                                            >
                                                <PopoverBody>
                                                    Scores get improved once
                                                    your employees watch the
                                                    assigned training videos and
                                                    successfully complete the
                                                    training quizzes.
                                                </PopoverBody>
                                            </Popover>
                                            <div className={'score'}>
                                                {this.props.scoreSent}
                                                <span>%</span>
                                                {this.props.scoreSent !==
                                                    this.props.score && (
                                                    <p>
                                                        Total of scheduled goals{' '}
                                                        {this.props.score}%
                                                    </p>
                                                )}
                                                {this.props.scoreSent ===
                                                    this.props.score && (
                                                    <p>&npsp;</p>
                                                )}
                                            </div>
                                            <Link to={'/trainings/'}>
                                                <div className="cta">
                                                    <ThemedText>
                                                        Manage Training
                                                    </ThemedText>
                                                </div>
                                            </Link>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div
                                            className={
                                                'block stats center stub'
                                            }
                                        >
                                            <h3>Spider chart</h3>
                                            <RadarChart
                                                captions={captions}
                                                data={data}
                                                size={250}
                                            />
                                        </div>
                                    </Col>
                                </Row>

                                <div className={'top-guide '}>
                                    <div className={'block center stub'}>
                                        <div className={'header'}>
                                            <h1>Training Programs</h1>
                                            <Link to="/trainings/">
                                                <div className="btn-action top-right">
                                                    <ThemedText>
                                                        See all
                                                    </ThemedText>
                                                </div>
                                            </Link>
                                        </div>
                                        <ThemedNav dashboard>
                                            <ThemedNavItem
                                                active={
                                                    this.state.activeTab === '1'
                                                        ? 'active'
                                                        : ''
                                                }
                                            >
                                                <ThemedNavLink
                                                    onClick={() => {
                                                        this.tabsChange('1');
                                                    }}
                                                >
                                                    Overview
                                                </ThemedNavLink>
                                            </ThemedNavItem>
                                            <ThemedNavItem
                                                active={
                                                    this.state.activeTab === '2'
                                                        ? 'active'
                                                        : ''
                                                }
                                            >
                                                <ThemedNavLink
                                                    onClick={() => {
                                                        this.tabsChange('2');
                                                    }}
                                                >
                                                    Schedule
                                                </ThemedNavLink>
                                            </ThemedNavItem>
                                        </ThemedNav>
                                        <TabContent
                                            activeTab={this.state.activeTab}
                                        >
                                            <TabPane tabId="1">
                                                <br />
                                                <Row>
                                                    <Col>
                                                        <div
                                                            className={
                                                                'number-field'
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    'left'
                                                                }
                                                            >
                                                                Active Training
                                                                Programs
                                                            </div>
                                                            <div
                                                                className={
                                                                    'right'
                                                                }
                                                            >
                                                                <ThemedText>
                                                                    {
                                                                        activeCount
                                                                    }
                                                                </ThemedText>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div
                                                            className={
                                                                'number-field'
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    'left'
                                                                }
                                                            >
                                                                Completed
                                                                Training
                                                                Programs
                                                            </div>
                                                            <div
                                                                className={
                                                                    'right'
                                                                }
                                                            >
                                                                <ThemedText>
                                                                    {
                                                                        completedCount
                                                                    }
                                                                </ThemedText>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </TabPane>
                                            <TabPane tabId="2"></TabPane>
                                        </TabContent>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Col>
                    <Col lg="4" xs="12">
                        <div className="block">
                            <h3>Recommendation</h3>
                            {currentCompany && !currentCompany.lists_count && (
                                <div className="item users">
                                    <h5>Getting started</h5>
                                    <p>
                                        You can set up different employee lists
                                        so they receive different programs.
                                    </p>
                                    <Link to="/users/create-list">
                                        <div className="cta">
                                            <ThemedText>
                                                Create a list
                                            </ThemedText>
                                        </div>
                                        <ThemedUsersSVG />
                                    </Link>
                                </div>
                            )}
                            <div className="item training">
                                <h5>Plan training</h5>
                                <p>
                                    Continuous awareness training helps you
                                    create and maintain a good security culture.{' '}
                                </p>
                                <Link to="/trainings/create/?back=/">
                                    <div className="cta">
                                        <ThemedText>
                                            Create a Training program
                                        </ThemedText>
                                    </div>
                                    <ThemedNewsListSVG />
                                </Link>
                            </div>
                        </div>
                        <NewCourse />
                        <NewSubjects />
                        {!!currentCompany &&
                            currentCompany.awarego_company === 1 && (
                                <NewsFeed />
                            )}
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default withRouter(Home);
