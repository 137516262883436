import { action, observable, computed, reaction } from 'mobx';
import { extractErrorMessage } from '../utils/helpers';
import keyBy from 'lodash/keyBy';
import services from '../services';
import i18n from 'i18next';
import moment from 'moment';

class MainStore {
    @observable currentSubjectTextContent = '';
    @observable campaign_id = null;
    @observable showSubjectDoneAnimation = false;
    @observable managerCompanies = null;
    @observable hasConfirmed = false;
    @observable loadingData = false;
    @observable loadingTokens = [];
    @observable loadingScorms = [];
    @observable loadingDashboardData = observable([]);
    @observable loadingCalendarData = false;
    @observable events = observable([]);
    @observable dashboardData = observable([]);
    @observable daysWithDot = observable([]);
    @observable loadingVideoUrl = false;
    @observable error = undefined;
    @observable trialMessageBar = undefined;
    @observable currentCompany = undefined;
    @observable serverData = undefined;
    @observable companyId = parseInt(window.localStorage.getItem('companyId'));
    @observable language = window.localStorage.getItem('language');
    @observable player = null;
    @observable tokens = [];
    @observable scorms = [];
    @observable uploadingCurrentCompanyLogo = false;
    @observable savingCurrentCompany = false;
    @observable addingManager = false;
    @observable addManagerError = null;
    @observable removeManagerError = null;
    @observable removingManagers = observable([]);

    constructor(commonStore) {
        this.commonStore = commonStore;
        reaction(
            () => this.currentCompany,
            (company) => {
                if (company && company.chargebeeEnabled) {
                    window.Chargebee.init({
                        site: company.chargebeeSite,
                        publishableKey: company.chargebeeClientKey,
                    });
                }
                if (company && company.defaultLanguage) {
                    this.setLanguage(company.defaultLanguage || 'en');
                }
            }
        );

        reaction(
            () => this.player,
            (player) => {
                //console.log("player updated", player)
            }
        );
    }

    @action setAuthStore(authStore) {
        this.authStore = authStore;
    }

    @action setSuperStore(superStore) {
        this.superStore = superStore;
    }

    @action logout = () => {
        this.companies = null;
        this.company = null;
        this.subject = null;
        this.campaign_id = null;
        this.subject = null;
        this.managerCompanies = null;
        this.currentCompany = null;
        this.serverData = null;
        window.localStorage.removeItem('companyId');
    };

    @action setI18n(i18n) {
        this.i18n = i18n;
    }

    @action
    async setLanguage(language_two_letter_code) {
        this.language = language_two_letter_code;
        i18n.changeLanguage(language_two_letter_code);
    }

    @action setHasConfirmed = (hasConfirmed) => {
        this.hasConfirmed = hasConfirmed;
    };

    @action setError = (error) => {
        this.error = error;
    };

    @action setCurrentSubjectTextContent = (content) => {
        this.currentSubjectTextContent = content;
    };

    @action setShowSubjectDoneAnimation = (showAnimation) => {
        this.showSubjectDoneAnimation = showAnimation;
    };

    @action setCampaignById = (campaign_id) => {
        this.campaign_id = parseInt(campaign_id);
    };

    @action
    async setCurrentCompany(company_id) {
        company_id = parseInt(company_id);
        let company = this.serverData.find((x) => x.company_id === company_id);
        if (company) {
            this.currentCompany = company;
            window.localStorage.setItem('companyId', company_id);
            this.companyId = company_id;
        } else {
            if (this.authStore.isPartnerAdmin) {
                await this.superStore.loadCompany(company_id);
                company = this.superStore.editingCompany;

                if (company) {
                    this.currentCompany = company;
                    window.localStorage.setItem('companyId', company_id);
                    this.companyId = company_id;
                }
            }
        }
    }

    @action
    async saveCurrentCompany(values) {
        this.savingCurrentCompany = true;
        try {
            let result = await services.Companies.update({
                id: this.currentCompany.company_id,
                ...values,
            });
            Object.assign(this.currentCompany, result, {
                company_name: result.name,
            });
        } catch (e) {
            console.error('Save failed: ', e);
            this.error = extractErrorMessage(e);
        } finally {
            this.savingCurrentCompany = false;
        }
    }

    @action
    async addManagerToCurrentCompany(data) {
        this.addingManager = true;
        this.addManagerError = null;
        try {
            let result = await services.Companies.managersService(
                this.currentCompany.company_id
            ).create(data);
            if (!this.currentCompany.managers) {
                this.currentCompany.managers = [];
            }
            this.currentCompany.managers.push(result);
            return result;
        } catch (e) {
            this.addManagerError = extractErrorMessage(e);
        } finally {
            this.addingManager = false;
        }
    }

    @action
    async removeManagerFromCurrentCompany(email) {
        this.removingManagers.push(email);
        this.removeManagerError = null;
        try {
            await services.Companies.managersService(
                this.currentCompany.company_id
            ).delete(email);

            if (this.currentCompany.managers) {
                let manager = this.currentCompany.managers.find(
                    (x) => x.email === email
                );
                if (manager) {
                    this.currentCompany.managers.remove(manager);
                }
            }
            return true;
        } catch (e) {
            this.removeManagerError = extractErrorMessage(e);
        } finally {
            this.removingManagers.remove(email);
        }
    }

    @action
    async uploadCurrentCompanyLogo(formData) {
        this.uploadingCurrentCompanyLogo = true;
        try {
            let result = await services.Resources.addLogo(
                this.currentCompany.company_id,
                formData
            );
            Object.assign(this.currentCompany, result, {
                logo_name: result.logo_name,
            });
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.uploadingCurrentCompanyLogo = false;
        }
    }

    @action
    async removeCurrentCompanyLogo() {
        try {
            let result = await services.Resources.removeLogo(
                this.currentCompany.company_id
            );
            Object.assign(this.currentCompany, result, { logo_name: null });
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    @computed get isTrial() {
        return this.currentCompany && this.currentCompany.trial_ends;
    }

    @computed get haveValidPackage() {
        return (
            this.currentCompany &&
            ((this.currentCompany.subscription &&
                this.currentCompany.subscription.package_valid) ||
                (this.currentCompany.packages &&
                    this.currentCompany.packages.some((p) => p.valid)))
        );
    }

    /** Is the current company a partner company? */
    @computed get havePartnerSubscription() {
        return (
            this.currentCompany &&
            this.currentCompany.subscription &&
            this.currentCompany.subscription.subscription_type === 'partner'
        );
    }
    /** Is the current company a partner company? */
    @computed get haveSubscriptionDefinition() {
        return (
            this.currentCompany &&
            this.currentCompany.subscription &&
            this.currentCompany.subscription.subscription_definition !== null
        );
    }

    @computed get subscriptionBillingPeriod() {
        return this.currentCompany &&
            this.currentCompany.subscription &&
            this.currentCompany.subscription.billingPeriod === 'year'
            ? 'Yearly'
            : 'Monthly';
    }

    @computed get isTrialOnly() {
        return this.isTrial;
    }

    @action
    async loadDashboardData(companyId) {
        this.loadingDashboardData = true;
        try {
            let result = await services.Companies.dashboardData(companyId);
            if (result) this.dashboardData = result;
        } catch (e) {
            console.error(e.stack);
            this.setError(extractErrorMessage(e));
        } finally {
            this.loadingDashboardData = false;
        }
    }

    @action
    async loadCalendarData(companyId, selectedMonth) {
        this.loadingCalendarData = true;
        try {
            let result = await services.Companies.dashboardCalendarData(
                companyId,
                selectedMonth
            );
            if (result) {
                this.events.replace(result);
                this.daysWithDot.replace(
                    this.events.map((e) => moment(e.date).format('YYYY-MM-DD'))
                );
            }
        } catch (e) {
            console.error(e.stack);
            this.setError(extractErrorMessage(e));
        } finally {
            this.loadingCalendarData = false;
        }
    }

    @action
    async loadServerData() {
        this.loadingData = true;
        this.setError(null);
        try {
            let companies = await services.Companies.myCompanies();
            this.setServerData(companies);

            if (companies.length > 0) {
                //switching context, so local storage companyId no more valid

                if (
                    this.companyId &&
                    !companies.find((x) => x.company_id === this.companyId)
                ) {
                    //Remove companyId from localStorage if the user is not a partner admin
                    if (!this.authStore.isPartnerAdmin) {
                        window.localStorage.removeItem('companyId');
                        this.companyId = null;
                    } else {
                        //Remove companyId from localStorage if the partner admin does not 'own' the current company (split from the check above for performance reasons)
                        await this.superStore.loadCompany(this.companyId);
                        if (!this.superStore.editingCompany) {
                            window.localStorage.removeItem('companyId');
                            this.companyId = null;
                        }
                    }
                }

                await this.setCurrentCompany(
                    this.companyId || companies[0].company_id
                );
            } else {
                // User doesn't own any companies, but if he is partner admin he might be viewing one if its companies
                if (this.companyId && this.authStore.isPartnerAdmin) {
                    //Remove companyId from localStorage if the partner admin does not 'own' the current company (split from the check above for performance reasons)
                    await this.superStore.loadCompany(this.companyId);
                    if (!this.superStore.editingCompany) {
                        window.localStorage.removeItem('companyId');
                        this.companyId = null;
                    } else {
                        await this.setCurrentCompany(this.companyId);
                    }
                }
            }
        } catch (e) {
            console.error(e);
            this.setError(extractErrorMessage(e));
        } finally {
            this.loadingData = false;
        }
    }

    @action setServerData = (data) => {
        //for backward compatibility right now
        this.serverData = data;
    };

    @action
    async videoToken(videoId) {
        if (this.loadingTokens.includes(videoId)) return false;

        this.loadingTokens.push(videoId);
        try {
            let token = await services.Companies.subjectsService(
                this.currentCompany.company_id
            ).getVideoToken(videoId); //videoId

            let t = this.tokens.find((x) => x.id === videoId);
            if (t) this.tokens.remove(t);
            this.tokens.push({ id: videoId, token });
        } finally {
            this.loadingTokens.remove(videoId);
        }
    }

    @action
    async scormInfo(subjectId) {
        if (this.loadingScorms.includes(subjectId)) return false;

        this.loadingScorms.push(subjectId);
        try {
            let url = await services.Companies.subjectsService(
                this.currentCompany.company_id
            ).getScormInfo(subjectId); //videoId

            let t = this.scorms.find((x) => x.id === subjectId);
            if (t) this.scorms.remove(t);
            this.scorms.push({ id: subjectId, url });
        } finally {
            this.loadingScorms.remove(subjectId);
        }
    }

    @action
    async loadVideoPlayerUrl() {
        if (this.loadingVideoUrl) return false;
        this.player = null;
        this.loadingVideoUrl = true;
        try {
            this.player = await services.Companies.subjectsService(
                this.currentCompany.company_id
            ).getVideoPlayerUrl();
        } catch (e) {
        } finally {
            this.loadingVideoUrl = false;
        }
    }

    @computed get videoPlayerUrl() {
        if (
            this.player &&
            this.player.url &&
            this.player.exp > new Date().getTime()
        )
            return this.player.url;
        return null;
    }

    @computed get tokenIndex() {
        return keyBy(this.tokens, 'id');
    }

    @computed get scormIndex() {
        return keyBy(this.scorms, 'id');
    }

    @computed get assessmentDashboardEnabled() {
        return (
            this.currentCompany &&
            this.currentCompany.settings['ui.assessment_dashboard'] === '1'
        );
    }

    @computed get assessmentEnabled() {
        if (!this.currentCompany) return false;
        //Enable for all non partner companies with assessment enabled through settings
        if (this.currentCompany.settings['ui.assessments'] === '0')
            return false;
        //For partners show/hide assements tab.
        if (this.havePartnerSubscription)
            return (
                this.haveSubscriptionDefinition && // Is a new subsription type, nevermind partner or standalone company that...
                (this.hasLegacyAccess('assessmentQuestions') || // ...was once enabled through subscription
                    this.hasAccess('assessmentQuestions')) // ...is currently enabled through subscription
            );
        //for regular companies always show assements tab, and show upsell there.
        return true;
    }

    @computed get trainingEnabled() {
        if (!this.currentCompany) return false;
        return (
            this.haveSubscriptionDefinition && // Is a partner company that...
            (this.hasLegacyAccess('trainingSubjects') || // ...was once enabled through subscription
                this.hasAccess('trainingSubjects')) // ...is currently enabled through subscription
        );
    }

    /** Is the assessment feature unavailable to this partner company? */
    @computed get assessmentsUnavailable() {
        if (!this.currentCompany) return true;
        if (this.havePartnerSubscription && !this.haveValidPackage) return true; //Is partner company with invalid subscription
        return !(
            (
                this.haveSubscriptionDefinition && // Is a partner company that...
                (this.hasLegacyAccess('assessmentQuestions') || // ...was once enabled through subscription
                    this.hasAccess('assessmentQuestions'))
            ) // ...is currently enabled through subscription
        );
    }

    /** upgrade allowed only for standalone company && if not yet purchased */
    @computed get assessmentsUpgradeAllowed() {
        return this.assessmentsUnavailable && !this.havePartnerSubscription;
    }

    /** upgrade allowed only for standalone company && if not yet purchased */
    @computed get trainingUpgradeAllowed() {
        return (
            this.trainingProgramsUnavailable && !this.havePartnerSubscription
        );
    }

    /** Is the training program feature unavailable to this partner company? */
    @computed get trainingProgramsUnavailable() {
        if (!this.currentCompany) return true;
        if (!this.haveValidPackage) return true; //Is partner company with invalid subscription
        return !(
            (
                this.haveSubscriptionDefinition && // Is a partner company that...
                (this.hasAccess('trainingSubjects') || // ...does not have access enabled through subscription
                    this.hasLegacyAccess('trainingSubjects'))
            ) // ...was once enabled through subscription
        );
    }

    hasAccess(arrayPropertyName) {
        return (
            this.currentCompany.subscription &&
            this.currentCompany.subscription.subscription_definition &&
            this.currentCompany.subscription.subscription_definition[
                arrayPropertyName
            ] &&
            this.currentCompany.subscription.subscription_definition[
                arrayPropertyName
            ].length > 0
        );
    }

    hasLegacyAccess(arrayPropertyName) {
        return (
            this.currentCompany.subscription.allowedItems &&
            this.currentCompany.subscription.allowedItems[arrayPropertyName] &&
            this.currentCompany.subscription.allowedItems[arrayPropertyName]
                .length > 0
        );
    }

    @computed get slackAvailable() {
        return this.isIntegrationAvailable('slack');
    }

    @computed get teamsAvailable() {
        return this.isIntegrationAvailable('teams');
    }

    isIntegrationAvailable(integrationName) {
        //Available for all non partner companies
        if (!this.havePartnerSubscription) return true;
        return (
            this.havePartnerSubscription &&
            this.currentCompany.subscription.subscription_definition &&
            this.currentCompany.subscription.subscription_definition.integrations.indexOf(
                integrationName
            ) > -1
        );
    }

    @computed get languagesIndex() {
        return keyBy(this.languages, 'code');
    }

    @computed get languages() {
        return this.commonStore.languages.filter(
            (x) =>
                this.currentCompany.languages &&
                this.currentCompany.languages.find((l) => l.code === x.code)
        );
    }

    @computed get languagesCodes() {
        return this.languages.map((x) => x.code);
    }
}

export default MainStore;
