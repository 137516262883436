import RestService from './RestService';
import stores from '../stores';

export default class Resources extends RestService {
    constructor() {
        super(process.env.REACT_APP_RESOURCES_URL);
    }

    posters() {
        if (
            stores.brandingStore.branding &&
            stores.brandingStore.branding['ui.posters_url']
        )
            return this.get(stores.brandingStore.branding['ui.posters_url']);
        return this.get(`${this.baseUrl}/posters.json`);
    }

    addLogo(companyId, formData) {
        const result = this.put('companies/' + companyId + '/logo', formData, {
            headers: {
                ...this.commonHeaders().headers,
                'Content-Type': 'multipart/form-data',
            },
        });
        return result;
    }

    removeLogo(companyId) {
        console.log('ResourceService removeLogo: ', companyId);
        return this.post('companies/' + companyId + '/remove_logo');
    }
}
