import React from 'react';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { rgba } from 'polished';

// to use this component, specify:
// - value
// - customcolor
// by default the progressbar will have width 56px, if wider needed use
// - medium
// - big
// by default the progressbar will have height 16px, if wider needed use
// - thin
// to make it fill available width use (instead of big or medium)
// - fill

const useStyles = makeStyles({
    colorPrimary: {
        backgroundColor: (props) =>
            props.value === 0 ? '#EDEDED' : rgba(props.customcolor, 0.15),
    },
    barColorPrimary: {
        backgroundColor: (props) => props.customcolor,
    },
    root: {
        width: (props) =>
            props.fill ? '100%' : props.big ? 330 : props.medium ? 150 : 56,
        height: (props) => (props.thin ? 6 : 16),
        display: 'inline-flex',
        marginRight: 10,
        borderRadius: 8,
    },
});

const InlineProgressBar = ({ value = 0, ...props }) => {
    const { colorPrimary, barColorPrimary, root } = useStyles(props);
    return (
        <LinearProgress
            classes={{
                colorPrimary: colorPrimary,
                barColorPrimary: barColorPrimary,
                root: root,
            }}
            color="primary"
            variant="determinate"
            value={value}
            {...props}
        />
    );
};

export default InlineProgressBar;
