import React from 'react';
import { inject } from 'mobx-react';
import sanitizeHtml from 'sanitize-html';

@inject('catalogStore')
class SubjectDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            CurrentSubjectTextContent: null,
        };
    }

    async componentDidMount() {
        const subject = this.props.subject;
        if (subject && subject.content) {
            this.setState({ CurrentSubjectTextContent: subject.content });
            /*let contentObject = await this.getTextContent(subject.id, subject.language);
if(contentObject && contentObject.content)
this.setState({CurrentSubjectTextContent:contentObject.content});*/
        }
    }

    /*async getTextContent(api_id_content){
    const url = 'https://www.awarego.com/wp-json/wp/v2/pages/'+api_id_content;
    let res = await fetch(url);
    return await res.json();
}*/

    render() {
        if (this.state.CurrentSubjectTextContent) {
            return (
                <div className="section-text">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                                this.state.CurrentSubjectTextContent
                            ),
                        }}
                    ></div>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default SubjectDetails;
