import React, { useEffect, useMemo, useState, Fragment } from 'react';
import { observer } from 'mobx-react';
import { find, map } from 'lodash';
import { useStores } from '../../hooks/use-stores';
import Question from './Question';
import '@awarego/awarego-components/lib/index.css';
import AssessmentFooter from './AssessmentFooter';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

function filterBlockValues(blockValues) {
    let result = {};
    map(blockValues, (v, k) => {
        if (v != null && k !== null && k !== 'undefined') result[k] = v;
    });
    return result;
}

function AssessmentQuestionPreview({
    companyId,
    question,
    isReview,
    questionsSelected,
    selectedQuestionId,
    setSelectedQuestionId,
    hasMoreQuestions,
    isMovingBack,
    setIsMovingBack,
    onRequestClose,
    loadingData,
}) {
    const { authStore } = useStores();
    const { currentUser } = authStore;
    const [questionActions, setQuestionActions] = useState(null);
    const [questionId, setQuestionId] = useState(null);
    const [blockValues, setBlockValues] = useState(null);
    const [completedBlocks, setCompletedBlocks] = useState([]);
    const [blockTransition, setBlockTransition] = useState(false);
    const [blockFadeIn, setBlockFadeIn] = useState(false);
    const [blockFadeOut, setBlockFadeOut] = useState(false);
    const [transitionTimer, setTransitionTimer] = useState(null);
    const [visibleBlocks, setVisibleBlocks] = useState([]);
    const [blockNum, setBlockNum] = useState(0);

    useEffect(() => {
        (async () => {
            if (blockTransition) {
                await onNext(null, true);
            }
            setBlockFadeIn(true);
            setBlockFadeOut(false);
        })();
    }, [blockTransition]);

    useEffect(() => {}, [blockFadeIn]);

    useEffect(() => {}, [blockFadeOut]);

    const ui = useMemo(() => {
        if (!question) return null;
        if (question.definition) return question.definition.ui;
        if (question.ui) return question.ui;
    }, [question]);

    useEffect(() => {
        if (blockValues) {
            let filteredValues = filterBlockValues(blockValues);

            let visibleBlocks = ui && ui.blocks;
            setVisibleBlocks(visibleBlocks);

            let completedBlocks = visibleBlocks.map((block) => {
                let actionId = block.actionId;
                let x = filteredValues[actionId];

                if (Array.isArray(x) || typeof x === 'object') {
                    if (block.type === 'yes-no-list') {
                        if (
                            typeof x === 'object' &&
                            Object.keys(x).length === block.questions.length
                        ) {
                            return true;
                        }
                    } else if (x.length > 0) {
                        return true;
                    }
                } else if (typeof x === 'string') {
                    return true;
                }
                return false;
            });
            setCompletedBlocks(completedBlocks);
        } else {
            setCompletedBlocks([]);
            setVisibleBlocks([]);
        }
    }, [blockValues, questionId]);

    useEffect(() => {
        loadData();
        setBlockNum(isMovingBack ? question.ui.blocks.length - 1 : 0);
    }, [question]);

    useEffect(() => {
        (async () => {
            if (companyId && questionId) {
                let _questionActions = [];
                let _blockValues = {};
                let _blockNum = 0;
                _questionActions = [];
                _blockValues = {};
                setBlockNum(
                    isMovingBack ? question.ui.blocks.length - 1 : _blockNum
                );
                setBlockValues(filterBlockValues(_blockValues));
                setQuestionActions(_questionActions);
            }
        })();
    }, [questionId, companyId]);

    const handleBlockChange = (value, actionId, omitTransition = false) => {
        if (!actionId && !visibleBlocks[blockNum]) return;
        let blockActionId = actionId || visibleBlocks[blockNum].actionId;
        let updatedBlockValues = { ...blockValues };
        let newVal = { ...updatedBlockValues, ...{ [blockActionId]: value } };

        setBlockValues(filterBlockValues(newVal));

        if (
            !omitTransition &&
            visibleBlocks.length > 1 &&
            blockNum < visibleBlocks.length - 1
        ) {
            setBlockFadeIn(false);
            setBlockFadeOut(true);
            if (transitionTimer) {
                try {
                    clearTimeout(transitionTimer);
                } catch (e) {}
            }
            setTransitionTimer(
                setTimeout(() => {
                    setTransitionTimer(null);
                    setBlockTransition(false);
                    setBlockTransition(true);
                }, 500)
            );
        }
    };

    const loadData = async () => {
        setQuestionId(question.id);
    };

    const onQuestionAction = async (questionId, action, value) => {
        //Keeping questionActions up-to-date, but maybe we don't need this...just POST every action
        let existingQuestionValue = find(questionActions, {
            questionId,
            action,
        });
        if (existingQuestionValue) {
            existingQuestionValue.value = value;
            setQuestionActions([...questionActions]);
        } else {
            existingQuestionValue = { questionId, action, value };
            setQuestionActions([...questionActions, existingQuestionValue]);
        }
    };

    const onNextBlock = async () => {
        let newBlockNum = Math.min(blockNum + 1, visibleBlocks.length - 1);
        setBlockNum(newBlockNum);
        let block = visibleBlocks[newBlockNum];
        let blockActionId = block.actionId;
        handleBlockChange(blockValues[blockActionId], blockActionId, true);
    };

    const onPrevBlock = async () => {
        let newBlockNum = Math.max(blockNum - 1, 0);
        setBlockNum(newBlockNum);
        let block = visibleBlocks[newBlockNum];
        let blockActionId = block.actionId;
        handleBlockChange(blockValues[blockActionId], blockActionId, true);
    };

    const onPreviousLesson = async (e, startAtLastBlock = false) => {
        setIsMovingBack(startAtLastBlock);
        questionsSelected.indexOf(selectedQuestionId) > 0 &&
            setSelectedQuestionId(
                questionsSelected[
                    questionsSelected.indexOf(selectedQuestionId) - 1
                ]
            );
    };

    const onNextLesson = async () => {
        setIsMovingBack(false);
        questionsSelected.indexOf(selectedQuestionId) !==
            questionsSelected.length - 1 &&
            setSelectedQuestionId(
                questionsSelected[
                    questionsSelected.indexOf(selectedQuestionId) + 1
                ]
            );
    };

    const onNext = async () => {
        if (!question) return;
        if (hasNextBlock()) return onNextBlock();
        return onNextLesson();
    };

    const onPrevious = async () => {
        if (!question) return;
        if (hasPreviousBlock()) return onPrevBlock();
        return onPreviousLesson(null, true);
    };

    const hasNextBlock = function () {
        return (
            visibleBlocks &&
            blockNum < visibleBlocks.length - 1 &&
            visibleBlocks[blockNum]
        );
    };

    const hasPreviousBlock = function () {
        return blockNum !== 0;
    };

    return (
        <Fragment>
            <div
                className={`modalContent ${
                    loadingData ? 'loading-centered' : ''
                }`}
            >
                {loadingData ? (
                    <ThemedSpinner />
                ) : (
                    <Question
                        handleBlockChange={handleBlockChange}
                        question={question}
                        blockNum={blockNum}
                        onPrevBlock={onPrevBlock}
                        onNextBlock={onNextBlock}
                        blockValues={blockValues}
                        completedBlocks={completedBlocks}
                        userName={currentUser.name}
                        userEmail={currentUser.email}
                        onQuestionAction={onQuestionAction}
                        visibleBlocks={ui.blocks}
                        isReview={isReview}
                    />
                )}
            </div>
            <AssessmentFooter
                hasMoreQuestions={hasMoreQuestions}
                onRequestClose={onRequestClose}
                questionIndex={questionsSelected.indexOf(question.id)}
                onPreviousLesson={onPreviousLesson}
                blockNum={blockNum}
                onPrevBlock={onPrevious}
                visibleBlocks={ui.blocks}
                hasPreviousBlock={hasPreviousBlock()}
                hasNextBlock={hasNextBlock()}
                onNextBlock={onNext}
                questionCount={questionsSelected.length}
                onNextLesson={onNextLesson}
            />
        </Fragment>
    );
}

export default observer(AssessmentQuestionPreview);
