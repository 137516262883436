import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import {
    CardDeck,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Alert,
} from 'reactstrap';

import StepsSelect from '../../components/manager/assessment/sidebar';
import SubjectSelect from '../../components/manager/automation/subject-select2';
import SubjectsOrder from '../../components/manager/automation/subjects-order';
import AutomationDistribution from '../../components/manager/automation/distribution';
import AutomationReminders from '../../components/manager/automation/reminders';
import Result from '../../components/manager/automation/result-2';
import PreviewEmail from '../../components/manager/automation/preview-email';
import ErrorMessage from '../../components/errormessage';
import Modal from 'react-modal';
import ModalListEditor from '../users/ModalListEditor';
import Snackbar from '../../components/Snackbar';
import IntroductoryForm from '../../components/manager/wizard/intro-message';
import IntroductoryFormDef from '../../forms/introductoryForm';
import {
    formatDate,
    formatTime,
    isNameUntitled,
    querystring,
    fixHtml,
    stripStringOfHtml,
} from '../../utils/helpers';
import classes from 'classnames';
import {
    ThemedCard,
    ThemedButton,
    ThemedDownloadLinksButton,
    ThemedText,
} from '../../components/themed/ThemedComponents';
import ThemedLinkButton from '../../components/themed/ThemedLinkButton';
import ThemedSuccessSVG from '../../components/themed/ThemedSuccessSVG';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import pluralize from 'pluralize';
import { AUTOMATION_WIZARD_STEPS, STATUS } from '../../utils/constants';
import sanitizeHtml from 'sanitize-html';
import { useStores } from '../../hooks/use-stores';
import SaveChanges from '../../components/manager/wizard/save-changes';
import SelectEmployees from '../../components/manager/wizard/select-employees';
import ChangeName from '../../components/manager/wizard/change-name';
import WizardHeader from '../../components/manager/wizard/WizardHeader';
import { Checkbox, FormControlLabel, MenuItem } from '@material-ui/core';
import { TextTiny } from '../../components/design-system/texts';
import { TextInput } from '../../components/design-system/inputs';
import { BigModal } from '../../components/design-system/modals';
import {
    SpaceBetweenDiv,
    FlexDiv,
} from '../../components/design-system/containers';
import AlertWithIcon from '../../components/Alert';
import LockIcon from '@material-ui/icons/Lock';
import Summary from '../../components/manager/wizard/summary';
import moment from 'moment';
import { reaction } from 'mobx';
import VideoPreview from '../videoPreview';
import SCORMPreview from '../scormPreview';

function AutomationCreate({ companyId, copyFrom, editId, parentMatch }) {
    const {
        automationStore,
        commonStore,
        listStore,
        userStore,
        store,
        authStore,
        catalogStore,
        brandingStore,
    } = useStores();

    const [subjectsModal, setSubjectsModal] = useState(false);
    const [previewSubjectModal, setPreviewSubjectModal] = useState(false);
    const [previewScormModal, setPreviewScormModal] = useState(false);
    const [previewLocation, setPreviewLocation] = useState(null);
    const [scheduleModal, setScheduleModal] = useState(false);
    const [recipientsModal, setRecipientsModal] = useState(false);
    const [addEmployeeModal, setAddEmployeeModal] = useState(false);
    const [createdAutomation, setCreatedAutomation] = useState(false);
    const [message, setMessage] = useState(null);
    const [messageShown, setMessageShown] = useState(false);
    const [messageType, setMessageType] = useState(null);
    const [nameChangeModal, setNameChangeModal] = useState(false);
    const [isDraft, setIsDraft] = useState(false);
    const [saveChangesModal, setSaveChangesModal] = useState(false);
    const [emailPreview, setEmailPreview] = useState(false);
    const [category, setCategory] = useState('all');

    const history = useHistory();
    const back = querystring('back');

    const {
        automation,
        loadingCompanyData,
        step,
        steps,
        allowedSteps,
        slackConnected,
        teamsConnected,
        error,
        templates,
        selectedTemplates,
        sendError,
    } = automationStore;
    const { catalog } = catalogStore;
    const { currentUser, token } = authStore;
    const { listsWithUsers: lists, error: listError } = listStore;
    const { loadingUsers } = userStore;
    const {
        currentCompany,
        havePartnerSubscription,
        slackAvailable,
        teamsAvailable,
    } = store;
    const { supportEmail } = brandingStore;
    let createListDisabled = !currentCompany || !currentCompany.canAddUsers;
    let signInLinksUrl;
    if (step === AUTOMATION_WIZARD_STEPS.START && createdAutomation) {
        signInLinksUrl = `${process.env.REACT_APP_API_URL}/companies/${companyId}/trainings/${createdAutomation.id}/users/csv?token=${token}`;
    }
    const ongoing = automation && automation.status === STATUS.ONGOING;

    const localStore = useLocalStore(() => {
        let introductoryForm = new IntroductoryFormDef(
            automation && automation.introductoryData,
            {}
        );

        reaction(
            () => {
                return automationStore.automation;
            },
            (automation) => {
                if (automation) {
                    let data = automation.introductoryData || {};
                    introductoryForm.update(data);
                    introductoryForm.validate();
                }
            }
        );

        introductoryForm.observe({
            path: 'message',
            key: 'value',
            call: ({ form }) => {
                form.submit();
                automationStore.toggleFinalStep(introductoryForm);
            },
        });

        introductoryForm.observe({
            path: 'subject',
            key: 'isValid',
            call: () => {
                automationStore.toggleFinalStep(introductoryForm);
            },
        });

        return { introductoryForm };
    });

    useEffect(() => {
        doLoadData();
    }, [doLoadData]);

    const doLoadData = useCallback(async () => {
        localStore.introductoryForm.reset();
        await automationStore.prepareWizard(companyId, copyFrom, editId);
    }, [
        localStore.introductoryForm,
        automationStore,
        companyId,
        copyFrom,
        editId,
    ]);

    const toggleVideoSelected = (videoId) => {
        automation.toggleVideoSelected(videoId);
        automationStore.toggleRecipientsStep();
    };

    const sendPreview = async (targetEmail, previewType, selectedEvents) => {
        let result = await automationStore.sendPreview(
            companyId,
            targetEmail,
            previewType,
            selectedEvents,
            {
                ...localStore.introductoryForm.values(),
                message: fixHtml(localStore.introductoryForm.values().message),
            }
        );
        if (result) {
            hideEmailPreview();
            showSnackBar('Test email sent successfully');
        }
    };

    const openEmailPreview = () => {
        setEmailPreview(true);
    };

    const hideEmailPreview = () => {
        setEmailPreview(false);
    };

    const openNameChangeModal = (isDraft) => {
        automation.setName('');
        setSaveChangesModal(false);
        setNameChangeModal(true);
        setIsDraft(isDraft);
    };

    const hideNameChangeModal = () => {
        setNameChangeModal(false);
    };

    const openSaveChangesModal = () => {
        setSaveChangesModal(true);
    };

    const hideSaveChangesModal = () => {
        setSaveChangesModal(false);
    };

    const toggleUserListSelected = (listId) => {
        automation.toggleUserListSelected(listId);
        automationStore.toggleScheduleStep();
    };

    const reorderSubjectList = (sourceIndex, targetIndex) => {
        automation.reorderSubjectList(sourceIndex, targetIndex);
    };

    const onChangeScheduleType = (value) => {
        automation.setScheduleType(value);
        automationStore.toggleRemindersStep();
    };

    const onChangeWeekday = (event) => {
        automation.setWeekday(event.target.value);
    };

    const onChangeInterval = (event) => {
        automation.setInterval(event.target.value);
    };

    const onChangeSubjectsPerInterval = (event) => {
        automation.setSubjectsPerInterval(event.target.value);
    };

    const onChangeTimeOfDay = (event) => {
        automation.setTimeOfDay(event.target.value);
    };

    const onChangeRemindersEnabled = () => {
        automation.setRemindersEnabled();
        automationStore.toggleIntroMessageStep();
    };

    const onChangeChannel = (event) => {
        if (
            !automation.setChannel(
                Number(event.target.value),
                event.target.checked
            )
        ) {
            commonStore.warn('At least one channel needs to be selected');
        }
    };

    const onChangeRemindersChannel = (event) => {
        if (
            !automation.setRemindersChannel(
                Number(event.target.value),
                event.target.checked
            )
        ) {
            commonStore.warn('At least one channel needs to be selected');
        }
        automationStore.toggleIntroMessageStep();
    };

    const onDeleteReminderStep = (item) => {
        automation.deleteReminderStep(item);
        automationStore.toggleIntroMessageStep();
    };

    const onAddReminderStep = () => {
        automation.addReminderStep();
        automationStore.toggleIntroMessageStep();
    };

    const startAutomation = async () => {
        await localStore.introductoryForm.validate();
        await localStore.introductoryForm.showErrors(true);

        if (
            !automation.introMessageEnabled ||
            localStore.introductoryForm.isValid
        ) {
            let result = await automationStore.startAutomation(companyId, {
                introductoryData: localStore.introductoryForm.values(),
            });
            if (result) {
                automationStore.setStep(AUTOMATION_WIZARD_STEPS.START);
                localStore.introductoryForm.reset();
                setCreatedAutomation(result);
            } else {
                if (automationStore.error) {
                    showSnackBar(automationStore.error, 'error');
                }
            }
        }
    };

    const saveDraft = async () => {
        const isNew = automation.id === null;
        let result = await automationStore.saveDraft(companyId, {
            introductoryData: localStore.introductoryForm.values(),
        });
        if (result) {
            !isNew
                ? commonStore.success('Training updated!')
                : commonStore.success('Draft saved!');
            history.push('/trainings');
        } else {
            if (automationStore.error) {
                showSnackBar(automationStore.error, 'error');
            }
        }
    };

    const stepChange = (step) => {
        automationStore.setStep(step);
    };

    const onChangeName = (e) => {
        const result = stripStringOfHtml(e.target.value);
        automation.setName(result);
    };

    const toggleIntroMessage = () => {
        automation.toggleIntroMessage();
        automationStore.toggleFinalStep(localStore.introductoryForm);
    };

    const selectTemplate = (template) => {
        automationStore.setTemplate(template.id);
    };

    const nextStep = () => {
        if (automationStore.step === AUTOMATION_WIZARD_STEPS.FINALIZE)
            if (
                automation.name === 'Untitled Training' ||
                automation.name === ''
            ) {
                openNameChangeModal(false);
            } else {
                startAutomation();
            }
        else {
            let index = automationStore.steps.findIndex(
                (x) => x.number === automationStore.step
            );
            let nextStep = automationStore.steps[index + 1]
                ? automationStore.steps[index + 1].number
                : automationStore.step + 1;
            automationStore.setStep(nextStep);
            automationStore.toggleRecipientsStep();
            automationStore.toggleRemindersStep();
            automationStore.toggleIntroMessageStep();
            automationStore.toggleFinalStep(localStore.introductoryForm);
        }
    };

    const prevStep = () => {
        automationStore.setStep(automationStore.step - 1);
    };

    const showSubjectsModal = () => {
        setSubjectsModal(true);
    };

    const hideSubjectsModal = () => {
        setSubjectsModal(false);
    };

    const showPreviewSubjectModal = (subjectId) => {
        setPreviewLocation(subjectId);
        setPreviewSubjectModal(true);
    };

    const hidePreviewSubjectModal = () => {
        setPreviewSubjectModal(false);
    };

    const showPreviewScormModal = (subjectId) => {
        setPreviewLocation(subjectId);
        setPreviewScormModal(true);
    };

    const hidePreviewScormModal = () => {
        setPreviewScormModal(false);
    };

    const showScheduleModal = () => {
        setScheduleModal(true);
    };

    const hideScheduleModal = () => {
        setScheduleModal(false);
    };

    const showRecipientsModal = () => {
        setRecipientsModal(true);
    };

    const hideRecipientsModal = () => {
        setRecipientsModal(false);
    };

    const showAddEmployeeModal = () => {
        setAddEmployeeModal(true);
    };

    const hideAddEmployeeModal = () => {
        setAddEmployeeModal(false);
    };

    const createRecipientsList = async (list) => {
        list.users = list.users.filter((x) => x.email);
        let result = await listStore.save(companyId, null, list);
        if (result) hideRecipientsModal();
        toggleUserListSelected(result);
        if (!addEmployeeModal) showAddEmployeeModal();
    };

    const requestUsersForList = (listId) => {
        userStore.loadUsers(companyId, listId, true);
    };

    const showSnackBar = (message, messageType = 'success') => {
        setMessage(message);
        setMessageShown(true);
        setMessageType(messageType);
    };

    const closeSnackbar = () => {
        setMessage(null);
        setMessageShown(false);
        setMessageType(null);
    };

    const getTemplateSubjectAvailabilityElement = (template) => {
        if (!template) return null;
        if (
            !template.disabledSubjects ||
            template.disabledSubjects.length === 0
        ) {
            return null;
        }

        const text = `${template.disabledSubjects.length}/${
            template.subjects.length + template.disabledSubjects.length
        } subjects unavailable in your plan`;

        return (
            <TextTiny bold error>
                <LockIcon fontSize={'inherit'} /> {text}
            </TextTiny>
        );
    };

    const getReviewSubjectAvailabilityElement = () => {
        const selectedSubjects = automation.videosSelected;

        if (!selectedSubjects) return null;

        const disabledSubjectsCount =
            automation.unavailableSubjectsSelected &&
            automation.unavailableSubjectsSelected.length;

        if (disabledSubjectsCount === 0) return null;

        const count = `${disabledSubjectsCount}/${selectedSubjects.length}`;

        return havePartnerSubscription ? (
            <AlertWithIcon severity="error">
                {count} of selected subjects are unavailable in your
                subscription. Please remove unavailable subjects or contact{' '}
                <a href={`mailto:${supportEmail}`}>{supportEmail}</a> to upgrade
                your subscription.
            </AlertWithIcon>
        ) : (
            <AlertWithIcon severity="error">
                {count} of selected subjects are unavailable in your
                subscription. Please remove unavailable subjects or{' '}
                <a href="/subscriptions/upgrade">upgrade</a> your subscription.
            </AlertWithIcon>
        );
    };

    const summaryOutput = automation && [
        {
            name: 'Name',
            detail: automation.name,
        },
        {
            name: 'Subjects',
            detail: (
                <div>
                    {[...new Set(automation.videosSelected)].map(
                        (subject, j, arr) => (
                            <span key={j}>
                                {subject.english_title}
                                {j !== arr.length - 1 ? (
                                    j >= 4 && j % 4 === 0 ? (
                                        <Fragment>
                                            , <br />
                                        </Fragment>
                                    ) : (
                                        ', '
                                    )
                                ) : (
                                    ''
                                )}
                            </span>
                        )
                    )}
                </div>
            ),
        },
        {
            name: 'Delivery',
            detail:
                automation.scheduleType === 0 ? (
                    <div>
                        {[...new Set(automation.result)].map(
                            (batch, j, arr) => (
                                <span key={j}>
                                    {moment(batch.date).format('L')}{' '}
                                    <span className="time-of-delivery">
                                        {moment(batch.date).format(
                                            '(LT) [GMT]'
                                        )}
                                    </span>
                                    {j !== arr.length - 1 ? (
                                        j >= 3 && j % 3 === 0 ? (
                                            <Fragment>
                                                , <br />
                                            </Fragment>
                                        ) : (
                                            ', '
                                        )
                                    ) : (
                                        ''
                                    )}
                                </span>
                            )
                        )}
                    </div>
                ) : (
                    'Immediately'
                ),
        },
        {
            name: 'Reminders',
            detail: automation.reminders_enabled
                ? automation.reminders_steps.length
                : '—',
        },
        {
            name: `Employees (${automation.selectedUserCount})`,
            detail: (
                <div>
                    {automation.userListsSelected &&
                        automation.userListsSelected.length > 0 &&
                        automation.userListsSelected[0] !== undefined &&
                        automation.userListsSelected.map((list, j, arr) => (
                            <span key={j}>
                                {/* don't put comma after last list name */}
                                {list.name}
                                {j !== arr.length - 1 ? (
                                    j >= 4 && j % 4 === 0 ? (
                                        <Fragment>
                                            , <br />
                                        </Fragment>
                                    ) : (
                                        ', '
                                    )
                                ) : (
                                    ''
                                )}
                            </span>
                        ))}
                </div>
            ),
        },
        {
            name: 'Message',
            detail: !automation.introMessageEnabled ? (
                '—'
            ) : (
                <Fragment>
                    <div>{localStore.introductoryForm.$('subject').value}</div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitizeHtml(
                                localStore.introductoryForm.$('message').value
                            ),
                        }}
                        className="summary-introMessage"
                    />
                </Fragment>
            ),
        },
    ];

    return (
        <Fragment>
            <ErrorMessage error={error} />
            {loadingCompanyData || !automation || !catalog ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    {ongoing || automation.status === STATUS.COMPLETED ? (
                        "You can't edit an already ongoing or completed training"
                    ) : currentCompany && currentCompany.canCreateTraining ? (
                        <div className={'training-wizard'}>
                            <WizardHeader
                                selectedTemplate={selectedTemplates}
                                isStartStep={
                                    step === AUTOMATION_WIZARD_STEPS.START
                                }
                                openNameChangeModal={openNameChangeModal}
                                openSaveChangesModal={openSaveChangesModal}
                                item={automation}
                                onChangeName={onChangeName}
                                itemString="training"
                                saveDraft={saveDraft}
                                isNameUntitled={isNameUntitled(
                                    automation.name,
                                    'training'
                                )}
                            />
                            {automation.id !== null &&
                                automation.status === STATUS.SCHEDULED && (
                                    <div className="assessment-wizard-info-message">
                                        <span style={{ fontWeight: 700 }}>
                                            Edit training
                                        </span>
                                    </div>
                                )}
                            <div className={'training-wizard-body'}>
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    autoHideDuration={15000}
                                    variant={messageType}
                                    message={message || ''}
                                    open={messageShown}
                                    onClose={closeSnackbar}
                                />
                                {step !== AUTOMATION_WIZARD_STEPS.START && (
                                    <StepsSelect
                                        steps={steps}
                                        activeStep={step}
                                        allowedSteps={allowedSteps}
                                        onStepChange={stepChange}
                                        warningSteps={[
                                            automation.hasUnavailableSubjects
                                                ? AUTOMATION_WIZARD_STEPS.CONTENT
                                                : null,
                                        ]}
                                    />
                                )}
                                <div
                                    className={classes(
                                        'training-wizard-content',
                                        {
                                            'full-width':
                                                step ===
                                                AUTOMATION_WIZARD_STEPS.START,
                                        }
                                    )}
                                >
                                    {step === AUTOMATION_WIZARD_STEPS.GOAL && (
                                        <Fragment>
                                            <h3>Set a training goal</h3>
                                            <p>
                                                Use one of our ready-made
                                                templates or create a fully
                                                customized training program
                                            </p>
                                            <br />
                                            {store.isTrialOnly && (
                                                <h4>
                                                    Available training programs{' '}
                                                </h4>
                                            )}
                                            <CardDeck
                                                className={'training-templates'}
                                            >
                                                {templates.map((t) => {
                                                    if (t.hidden || !t.enabled)
                                                        return null;
                                                    return (
                                                        <ThemedCard
                                                            key={t.id}
                                                            outline
                                                            disabled
                                                            {...{
                                                                selected:
                                                                    selectedTemplates.includes(
                                                                        t.id
                                                                    ),
                                                            }}
                                                            className={classnames(
                                                                'training-template',
                                                                {
                                                                    disabled:
                                                                        !t.enabled,
                                                                },
                                                                `training-template-${t.id}`
                                                            )}
                                                            onClick={() => {
                                                                if (t.enabled)
                                                                    selectTemplate(
                                                                        t
                                                                    );
                                                            }}
                                                        >
                                                            <CardBody>
                                                                <CardTitle>
                                                                    <h3>
                                                                        {
                                                                            t.title
                                                                        }
                                                                    </h3>
                                                                </CardTitle>
                                                                <CardText>
                                                                    {
                                                                        t.description
                                                                    }
                                                                </CardText>
                                                                <CardSubtitle>
                                                                    {t.subjects
                                                                        .length >
                                                                        0 && (
                                                                        <span>
                                                                            {
                                                                                t.verboseDuration
                                                                            }{' '}
                                                                        </span>
                                                                    )}
                                                                </CardSubtitle>
                                                                {getTemplateSubjectAvailabilityElement(
                                                                    t
                                                                )}
                                                            </CardBody>
                                                        </ThemedCard>
                                                    );
                                                })}
                                            </CardDeck>
                                            {store.isTrialOnly && (
                                                <Fragment>
                                                    <h4>
                                                        All training programs -{' '}
                                                        <a
                                                            href="/subscriptions/upgrade"
                                                            className="yellow ml5"
                                                        >
                                                            Upgrade to get
                                                            access
                                                        </a>
                                                    </h4>
                                                    <CardDeck
                                                        className={
                                                            'training-templates'
                                                        }
                                                    >
                                                        {templates.map((t) => {
                                                            if (
                                                                t.hidden ||
                                                                t.enabled
                                                            )
                                                                return null;
                                                            return (
                                                                <ThemedCard
                                                                    key={t.id}
                                                                    outline
                                                                    disabled
                                                                    {...{
                                                                        selected:
                                                                            selectedTemplates.includes(
                                                                                t.id
                                                                            ),
                                                                    }}
                                                                    className={classnames(
                                                                        'training-template',
                                                                        {
                                                                            disabled:
                                                                                !t.enabled,
                                                                        }
                                                                    )}
                                                                    onClick={() => {
                                                                        if (
                                                                            t.enabled
                                                                        )
                                                                            selectTemplate(
                                                                                t
                                                                            );
                                                                    }}
                                                                >
                                                                    <CardBody>
                                                                        <CardTitle>
                                                                            <h3>
                                                                                {
                                                                                    t.title
                                                                                }
                                                                            </h3>
                                                                        </CardTitle>
                                                                        <CardText>
                                                                            {
                                                                                t.description
                                                                            }
                                                                        </CardText>
                                                                        <CardSubtitle>
                                                                            {t
                                                                                .subjects
                                                                                .length >
                                                                                0 && (
                                                                                <span>
                                                                                    {
                                                                                        t.verboseDuration
                                                                                    }{' '}
                                                                                </span>
                                                                            )}
                                                                        </CardSubtitle>
                                                                    </CardBody>
                                                                </ThemedCard>
                                                            );
                                                        })}
                                                    </CardDeck>
                                                </Fragment>
                                            )}
                                        </Fragment>
                                    )}
                                    {step ===
                                        AUTOMATION_WIZARD_STEPS.CONTENT && (
                                        <Fragment>
                                            <div className="training-wizard-body-header">
                                                <div>
                                                    <h3>Review subjects</h3>
                                                    {ongoing ? (
                                                        <p>
                                                            You cannot modify
                                                            subjects since at
                                                            least some were
                                                            already sent to
                                                            people.
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            You can edit or add
                                                            more subjects to
                                                            this set of
                                                            subjects.
                                                        </p>
                                                    )}
                                                    <ThemedText
                                                        bold
                                                        style={{
                                                            fontSize: '16px',
                                                        }}
                                                    >
                                                        {
                                                            automation
                                                                .videosSelected
                                                                .length
                                                        }{' '}
                                                        {pluralize(
                                                            'subject',
                                                            automation
                                                                .videosSelected
                                                                .length
                                                        )}{' '}
                                                        ·{' '}
                                                        {formatTime(
                                                            automation.videosSelectedDuration,
                                                            true
                                                        )}{' '}
                                                        {pluralize(
                                                            'minute',
                                                            formatTime(
                                                                automation.videosSelectedDuration,
                                                                true
                                                            )
                                                        )}
                                                    </ThemedText>
                                                </div>
                                                {!ongoing && (
                                                    <div className="action">
                                                        <ThemedButton
                                                            secondary
                                                            onClick={
                                                                showSubjectsModal
                                                            }
                                                        >
                                                            Add subjects
                                                        </ThemedButton>
                                                    </div>
                                                )}
                                            </div>
                                            {getReviewSubjectAvailabilityElement()}

                                            <SubjectsOrder
                                                videos={
                                                    automation.videosSelected
                                                }
                                                onRemoveSubject={
                                                    toggleVideoSelected
                                                }
                                                onReorderList={
                                                    reorderSubjectList
                                                }
                                                isTrial={store.isTrial}
                                            />
                                        </Fragment>
                                    )}
                                    {step ===
                                        AUTOMATION_WIZARD_STEPS.EMPLOYEES && (
                                        <SelectEmployees
                                            itemString="training"
                                            item={automation}
                                            lists={lists}
                                            toggleUserListSelected={
                                                toggleUserListSelected
                                            }
                                            ongoing={ongoing}
                                            addEmployeeModal={addEmployeeModal}
                                            showAddEmployeeModal={
                                                showAddEmployeeModal
                                            }
                                            hideAddEmployeeModal={
                                                hideAddEmployeeModal
                                            }
                                            recipientsModal={recipientsModal}
                                            showRecipientsModal={
                                                showRecipientsModal
                                            }
                                            hideRecipientsModal={
                                                hideAddEmployeeModal
                                            }
                                            error={listError}
                                            loadingUsers={loadingUsers}
                                            requestUsersForList={
                                                requestUsersForList
                                            }
                                            selectedLists={
                                                automation.selectedUserListsIds
                                            }
                                            selectedUserCount={
                                                automation.selectedUserCount
                                            }
                                            disabled={createListDisabled}
                                            createRecipientsList={
                                                createRecipientsList
                                            }
                                            currentCompany={currentCompany}
                                        />
                                    )}
                                    {step ===
                                        AUTOMATION_WIZARD_STEPS.SCHEDULE && (
                                        <Fragment>
                                            <h3>Schedule delivery </h3>
                                            {ongoing ? (
                                                <p>
                                                    Modify delivery channel for
                                                    this training.
                                                </p>
                                            ) : (
                                                <p>
                                                    Set delivery for this
                                                    training. The best
                                                    performing training programs
                                                    send 1-4 videos, once a
                                                    week.
                                                </p>
                                            )}
                                            <AutomationDistribution
                                                ongoing={ongoing}
                                                interval={automation.interval}
                                                subjectsPerInterval={
                                                    automation.subjectsPerInterval
                                                }
                                                weekday={automation.weekday}
                                                timeOfDay={automation.timeOfDay}
                                                scheduleType={
                                                    automation.scheduleType
                                                }
                                                channel={automation.channel}
                                                slackAvailable={slackAvailable}
                                                slackConnected={slackConnected}
                                                teamsAvailable={teamsAvailable}
                                                teamsConnected={teamsConnected}
                                                onChangeScheduleType={
                                                    onChangeScheduleType
                                                }
                                                onChangeChannel={
                                                    onChangeChannel
                                                }
                                                onChangeInterval={
                                                    onChangeInterval
                                                }
                                                onChangeSubjectsPerInterval={
                                                    onChangeSubjectsPerInterval
                                                }
                                                onChangeWeekday={
                                                    onChangeWeekday
                                                }
                                                onChangeTimeOfDay={
                                                    onChangeTimeOfDay
                                                }
                                                showScheduleModal={
                                                    showScheduleModal
                                                }
                                            />
                                        </Fragment>
                                    )}
                                    {step ===
                                        AUTOMATION_WIZARD_STEPS.REMINDERS && (
                                        <Fragment>
                                            <h3>Reminders (optional)</h3>
                                            <p>
                                                Send a reminder to employees who
                                                forget to take their lessons on
                                                time
                                            </p>

                                            <AutomationReminders
                                                reminders_enabled={
                                                    automation.reminders_enabled
                                                }
                                                reminders_channel={
                                                    automation.reminders_channel
                                                }
                                                reminders_steps={
                                                    automation.reminders_steps
                                                }
                                                slackAvailable={slackAvailable}
                                                slackConnected={slackConnected}
                                                teamsAvailable={teamsAvailable}
                                                teamsConnected={teamsConnected}
                                                onChangeRemindersEnabled={
                                                    onChangeRemindersEnabled
                                                }
                                                onChangeRemindersChannel={
                                                    onChangeRemindersChannel
                                                }
                                                onAddReminderStep={
                                                    onAddReminderStep
                                                }
                                                onDeleteReminderStep={
                                                    onDeleteReminderStep
                                                }
                                                scheduleType={
                                                    automation.scheduleType
                                                }
                                                showScheduleModal={
                                                    showScheduleModal
                                                }
                                            />
                                        </Fragment>
                                    )}
                                    {step ===
                                        AUTOMATION_WIZARD_STEPS.INTROMESSAGE && (
                                        <Fragment>
                                            <h3>Intro message (optional)</h3>
                                            <p>
                                                Add a personal message and
                                                introduce this training to your
                                                recipients.
                                            </p>

                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            automation.introMessageEnabled
                                                        }
                                                        onChange={
                                                            toggleIntroMessage
                                                        }
                                                        name="introMessage"
                                                        color="primary"
                                                    />
                                                }
                                                label="Include introductory message"
                                            />
                                            <div>
                                                {automation.introMessageEnabled && (
                                                    <IntroductoryForm
                                                        form={
                                                            localStore.introductoryForm
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <br />
                                        </Fragment>
                                    )}
                                    {step ===
                                        AUTOMATION_WIZARD_STEPS.FINALIZE && (
                                        <Fragment>
                                            <h3>Finalize Training</h3>
                                            {ongoing ? (
                                                <p>
                                                    Check out the summary before
                                                    applying changes
                                                </p>
                                            ) : (
                                                <p>
                                                    Check out the summary before
                                                    you create the training
                                                </p>
                                            )}

                                            {getReviewSubjectAvailabilityElement()}

                                            <Summary
                                                summaryOutput={summaryOutput}
                                            />
                                            <br />
                                        </Fragment>
                                    )}
                                    {step === AUTOMATION_WIZARD_STEPS.START && (
                                        <div className="block wide steps step1">
                                            <div className="forms">
                                                <div className="form-header">
                                                    <h3 className="flex-center">
                                                        Success!
                                                    </h3>
                                                    <div className="hide-mobile">
                                                        <ThemedSuccessSVG />
                                                    </div>
                                                    <div className="hide-desktop">
                                                        <img
                                                            src="/static/img/success.svg"
                                                            alt="Email sent"
                                                        />
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <p>
                                                        {createdAutomation &&
                                                            createdAutomation.name}{' '}
                                                        training has{' '}
                                                        {ongoing ||
                                                        automation.status ===
                                                            STATUS.SCHEDULED
                                                            ? 'been successfully updated!'
                                                            : 'succesfully started!'}
                                                        {automation.scheduleType ===
                                                            0 &&
                                                            !ongoing &&
                                                            ` Training invitation will be sent on ${
                                                                automation.result &&
                                                                formatDate(
                                                                    automation
                                                                        .result[0]
                                                                        .date
                                                                )
                                                            }`}
                                                        {automation.scheduleType ===
                                                            2 && (
                                                            <ThemedDownloadLinksButton
                                                                primary
                                                                as="a"
                                                                href={
                                                                    signInLinksUrl
                                                                }
                                                                rel="noopener noreferrer"
                                                                target="_blank"
                                                            >
                                                                Download sign in
                                                                links
                                                            </ThemedDownloadLinksButton>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {step !== AUTOMATION_WIZARD_STEPS.START && (
                                    <div className="right-sidebar">
                                        {step ===
                                            AUTOMATION_WIZARD_STEPS.GOAL &&
                                            automation.templateTip && (
                                                <div className={'pro-tip'}>
                                                    <div
                                                        className={'star'}
                                                    ></div>
                                                    <h3>Pro tip</h3>
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: sanitizeHtml(
                                                                automation.templateTip
                                                            ),
                                                        }}
                                                    ></p>
                                                </div>
                                            )}
                                        {step ===
                                            AUTOMATION_WIZARD_STEPS.SCHEDULE && (
                                            <div className={'pro-tip'}>
                                                <div className={'star'}></div>
                                                <h3>Pro tip</h3>
                                                <p>
                                                    Have your training videos
                                                    arrive early in the day and
                                                    early in the week.{' '}
                                                </p>
                                                <p>
                                                    Studies have shown that most
                                                    people are most alert and
                                                    retain more new information
                                                    in the morning when they're
                                                    refreshed after a good
                                                    night's sleep and a
                                                    nourishing breakfast.{' '}
                                                </p>
                                            </div>
                                        )}
                                        {step ===
                                            AUTOMATION_WIZARD_STEPS.INTROMESSAGE && (
                                            <div className={'pro-tip'}>
                                                <div className={'star'}></div>
                                                <h3>Pro tip</h3>
                                                <p>
                                                    Before you launch your first
                                                    training program, send an
                                                    email from your work email
                                                    to everyone involved and
                                                    tell them that they will
                                                    soon receive security
                                                    awareness training from
                                                    AwareGO in their inbox. Your
                                                    security awareness score
                                                    will thank you.{' '}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className={'training-wizard-footer'}>
                                <div className={'actions'}>
                                    {step !== AUTOMATION_WIZARD_STEPS.GOAL &&
                                        step !==
                                            AUTOMATION_WIZARD_STEPS.START && (
                                            <span
                                                className="previous-btn"
                                                onClick={prevStep}
                                            >
                                                Previous
                                            </span>
                                        )}
                                    <div className="actions-align-right">
                                        {step ===
                                            AUTOMATION_WIZARD_STEPS.FINALIZE &&
                                            automation.scheduleType !== 2 && (
                                                <ThemedLinkButton
                                                    secondary
                                                    footer
                                                    onClick={openEmailPreview}
                                                    disabled={
                                                        automation.hasUnavailableSubjects
                                                    }
                                                >
                                                    Send a Preview
                                                </ThemedLinkButton>
                                            )}
                                        {step ===
                                        AUTOMATION_WIZARD_STEPS.START ? (
                                            <ThemedLinkButton
                                                primary
                                                footer
                                                to={
                                                    (parentMatch &&
                                                        parentMatch.url) ||
                                                    '/'
                                                }
                                            >
                                                Close
                                            </ThemedLinkButton>
                                        ) : (
                                            <ThemedLinkButton
                                                primary
                                                continue
                                                disabled={
                                                    (!allowedSteps.includes(
                                                        step + 1
                                                    ) &&
                                                        step !==
                                                            AUTOMATION_WIZARD_STEPS.FINALIZE) ||
                                                    (automation.hasUnavailableSubjects &&
                                                        step ===
                                                            AUTOMATION_WIZARD_STEPS.FINALIZE)
                                                }
                                                size={'lg'}
                                                onClick={nextStep}
                                            >
                                                {step ===
                                                AUTOMATION_WIZARD_STEPS.FINALIZE
                                                    ? automation.id
                                                        ? automation.status ===
                                                          STATUS.DRAFT
                                                            ? 'Activate'
                                                            : 'Update'
                                                        : 'Create'
                                                    : 'Continue'}
                                            </ThemedLinkButton>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <BigModal
                                isOpen={subjectsModal}
                                onRequestClose={hideSubjectsModal}
                                heading="Add subjects"
                                filterContent={
                                    <FlexDiv alignCenter>
                                        <TextInput
                                            select
                                            value={category || 'all'}
                                            onChange={(e) =>
                                                setCategory(e.target.value)
                                            }
                                            inline
                                        >
                                            <MenuItem value={'all'}>
                                                All categories
                                            </MenuItem>
                                            {catalog.map((category) => (
                                                <MenuItem
                                                    value={category.name}
                                                    key={category.id}
                                                >
                                                    {category.name}
                                                </MenuItem>
                                            ))}
                                        </TextInput>
                                        {category !== 'all' && (
                                            <ThemedButton
                                                secondary
                                                noTextTransform
                                                airy
                                                tiny
                                                onClick={() => {
                                                    setCategory('all');
                                                }}
                                            >
                                                Clear
                                            </ThemedButton>
                                        )}
                                    </FlexDiv>
                                }
                                modalContent={
                                    <SubjectSelect
                                        selection={automation.selectedVideosIds}
                                        catalog={catalog}
                                        category={category}
                                        onRequestClose={hideSubjectsModal}
                                        isTrial={store.isTrial}
                                        onVideoSelected={toggleVideoSelected}
                                        showPreviewSubjectModal={
                                            showPreviewSubjectModal
                                        }
                                        showPreviewScormModal={
                                            showPreviewScormModal
                                        }
                                    />
                                }
                                modalFooterContent={
                                    <SpaceBetweenDiv>
                                        <TextTiny themed bold>
                                            {
                                                (
                                                    automation.selectedVideosIds ||
                                                    []
                                                ).length
                                            }{' '}
                                            {pluralize(
                                                'subject',
                                                (
                                                    automation.selectedVideosIds ||
                                                    []
                                                ).length
                                            )}{' '}
                                            selected
                                        </TextTiny>
                                        <ThemedButton
                                            primary
                                            onClick={hideSubjectsModal}
                                        >
                                            Done
                                        </ThemedButton>
                                    </SpaceBetweenDiv>
                                }
                            />
                            <VideoPreview
                                modal={true}
                                isOpen={previewSubjectModal}
                                onClose={hidePreviewSubjectModal}
                                previewVideoLocation={previewLocation}
                            />
                            <SCORMPreview
                                modal={true}
                                isOpen={previewScormModal}
                                onClose={hidePreviewScormModal}
                                previewScormLocation={previewLocation}
                            />
                            <Modal
                                isOpen={scheduleModal}
                                onRequestClose={hideScheduleModal}
                                className="Modal schedule slim"
                            >
                                <Result
                                    result={automation.result}
                                    onRequestClose={hideScheduleModal}
                                    reminders_enabled={
                                        automation.reminders_enabled
                                    }
                                    reminders_steps={automation.reminders_steps}
                                    scheduleType={automation.scheduleType}
                                />
                            </Modal>
                            <Modal
                                isOpen={recipientsModal}
                                onRequestClose={hideRecipientsModal}
                                className="Modal schedule"
                            >
                                <ModalListEditor
                                    result={automation.result}
                                    error={listError}
                                    onRequestClose={hideRecipientsModal}
                                    createRecipientsList={createRecipientsList}
                                />
                            </Modal>
                            <Modal
                                isOpen={emailPreview}
                                onRequestClose={hideEmailPreview}
                                className="Modal schedule"
                            >
                                <PreviewEmail
                                    result={automation.result}
                                    scheduleType={automation.scheduleType}
                                    ownEmail={currentUser.email}
                                    sendError={sendError}
                                    interval={automation.interval}
                                    onRequestClose={hideEmailPreview}
                                    introductory={
                                        automation.introMessageEnabled
                                    }
                                    onPreviewSend={sendPreview}
                                />
                            </Modal>
                            <Modal
                                isOpen={nameChangeModal}
                                onRequestClose={hideNameChangeModal}
                                className="Modal schedule not-wide"
                            >
                                <ChangeName
                                    name={automation.name}
                                    onRequestClose={hideNameChangeModal}
                                    nextStep={nextStep}
                                    onChangeName={onChangeName}
                                    isDraft={isDraft}
                                    saveDraft={saveDraft}
                                    itemString="training"
                                    isNameUntitled={isNameUntitled(
                                        automation.name,
                                        'training'
                                    )}
                                />
                            </Modal>
                            <Modal
                                isOpen={saveChangesModal}
                                onRequestClose={hideSaveChangesModal}
                                className="Modal schedule not-wide"
                            >
                                <SaveChanges
                                    onRequestClose={hideSaveChangesModal}
                                    isNameUntitled={isNameUntitled(
                                        automation.name,
                                        'training'
                                    )}
                                    saveDraft={saveDraft}
                                    openNameChangeModal={openNameChangeModal}
                                    back={back}
                                    parentMatch={parentMatch}
                                    editing={
                                        automation.status === STATUS.ONGOING ||
                                        automation.status ===
                                            STATUS.SCHEDULED ||
                                        automation.status === STATUS.DRAFT
                                    }
                                />
                            </Modal>
                        </div>
                    ) : (
                        <Fragment>
                            <br />
                            <br />
                            <br />
                            <ErrorMessage>
                                <Alert
                                    color="warning"
                                    className="header"
                                    isOpen={true}
                                >
                                    No license available
                                </Alert>
                            </ErrorMessage>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
}

export default observer(AutomationCreate);
