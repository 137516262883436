import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Switch, Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import Layout from './layouts/layout';
import { Container } from 'reactstrap';
import NotFound from './components/NotFound';
import PrivateRoute from './hocs/PrivateRoute';
import AuthRoute from './hocs/AuthRoute';
import Waiting from './components/waiting';
import AsyncData from './components/async-data';
import startI18n from './tools/startI18n';
import { getTranslation } from './tools/translationHelpers';
import Home from './containers/Home';
import AssessmentHome from './containers/AssessmentHome';
import Catalog from './containers/Catalog';
import Embed from './containers/Embed';
import SignUp from './containers/auth/SignUp';
import SignIn from './containers/auth/SignIn';
import CheckEmail from './containers/auth/CheckEmail';
import EmailSignIn from './containers/auth/EmailSignIn';
import TokenSignIn from './containers/auth/TokenSignIn';
import EmailSignInError from './containers/auth/EmailSignInError';
import Users from './containers/users/Users';
import Subscriptions from './containers/subscriptions';
import Automations from './containers/automations';
import Assessment from './containers/assessment';
import Super from './containers/super';
import Settings from './containers/settings';
import Reports from './containers/reports';
import Partner from './containers/partner';
import ConfirmationModal from './containers/ConfirmationModal';
import TrialPPV from './containers/TrialPPV';
import PPVHome from './containers/PPVHome';
import PPVEmbed from './containers/PPVEmbed';
import Snackbar from './components/Snackbar';
import CompanyPPVLogView from './containers/super/CompanyPPVLogView';
import TrainingReport from './containers/automations/TrainingReport';
import UsersTrainingReport from './containers/automations/UsersTrainingReport';
import Resources from './containers/Resources';
import { enforceSlash, querystring } from './utils/helpers';
import { ThemeProvider } from 'styled-components';
import TestPage from './containers/testing';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { I18nextProvider } from 'react-i18next';

@inject('store', 'authStore', 'commonStore', 'brandingStore')
@observer
class App extends Component {
    state = {};

    componentDidMount = async () => {
        if (this.props.authStore.token) {
            try {
                await Promise.all([
                    this.props.authStore.pullUser(),
                    this.props.commonStore.loadLanguages(),
                ]);
            } finally {
                await this.props.commonStore.setAppLoaded();
            }
        } else {
            await this.props.commonStore.loadLanguages();
            await this.props.brandingStore.loadBranding();
            await this.props.commonStore.setAppLoaded();
        }
    };

    closeSnackbar = () => {
        this.props.commonStore.hideMessage();
    };

    handleSignout = () => {
        this.props.authStore.logout();
    };

    switchLanguage = async (language_two_letter_code) => {
        if (
            this.props.session &&
            this.props.session.user &&
            this.props.session.user.email
        ) {
            this.props.session.user.language = language_two_letter_code;

            let lang =
                this.props.store.languagesIndex[language_two_letter_code];
            if (!lang) {
                language_two_letter_code = 'en';
                lang =
                    this.props.store.languagesIndex[language_two_letter_code];
            }

            if (!lang) return;

            const translations = await getTranslation(
                language_two_letter_code,
                lang.file
            );
            //TODO:
            // redone i18n via store
            this.i18n = startI18n(translations, language_two_letter_code);
            this.store.setI18n(this.i18n);
            // update the user info on server so that the languge sticks when he logs in the next time

            try {
                const serverData = await AsyncData.switchLanguage(
                    null,
                    this.props.session.user.email,
                    language_two_letter_code
                );
                if (!serverData) {
                    console.log('failed to save language to database');
                }
            } catch (e) {
                console.log(
                    'Unable to save language to database. Reason: ' +
                        JSON.stringify(e)
                );
            }

            // force refresh for new language to appier
            this.getData();
            //this.forceUpdate();
        }
    };

    render() {
        const { theme, muiTheme, brandingLoaded } = this.props.brandingStore;
        const { appLoaded, messageShown, languagesLoaded, i18n } =
            this.props.commonStore;
        const { isAuthenticated } = this.props.authStore;
        const {
            trialMessageBar,
            currentCompany,
            loadingData,
            assessmentDashboardEnabled,
            assessmentEnabled,
            trainingEnabled,
        } = this.props.store;
        return (
            <MuiThemeProvider theme={muiTheme}>
                <ThemeProvider theme={theme}>
                    {languagesLoaded && (
                        <I18nextProvider i18n={i18n}>
                            <Fragment>
                                <Route
                                    path="/"
                                    render={(props) => {
                                        let normalizedPath = enforceSlash(
                                            props.location.pathname.replace(
                                                /\/\//g,
                                                '/'
                                            )
                                        );
                                        if (
                                            this.props.commonStore.lastPage !==
                                            normalizedPath
                                        ) {
                                            this.props.commonStore.analyticsPageView(
                                                `${normalizedPath}`
                                            );
                                        }
                                        this.props.commonStore.lastPage =
                                            normalizedPath;
                                        return null;
                                    }}
                                />

                                <Switch>
                                    {brandingLoaded && (
                                        <Route
                                            path="/testing2"
                                            component={TestPage}
                                        />
                                    )}
                                    <Route
                                        path="/:companyId/training-report/:trainingId"
                                        component={TrainingReport}
                                    />
                                    <Route
                                        path="/:companyId/training-users-report/:trainingId"
                                        component={UsersTrainingReport}
                                    />
                                    <Route
                                        path="/auth/email/signin/:token"
                                        exact
                                        component={EmailSignIn}
                                    />
                                    <Route
                                        path="/auth/jwt/signin/:token"
                                        exact
                                        component={TokenSignIn}
                                    />
                                    <Route
                                        render={() => {
                                            return (
                                                <Fragment>
                                                    {appLoaded &&
                                                    brandingLoaded &&
                                                    !loadingData ? (
                                                        <Layout
                                                            handleSignout={
                                                                this
                                                                    .handleSignout
                                                            }
                                                            isAuthenticated={
                                                                isAuthenticated
                                                            }
                                                            switchLanguage={
                                                                this
                                                                    .switchLanguage
                                                            }
                                                            trialMessageBar={
                                                                isAuthenticated &&
                                                                trialMessageBar
                                                            }
                                                        >
                                                            <Switch>
                                                                <Route
                                                                    exact
                                                                    strict
                                                                    path="/:url*"
                                                                    render={(
                                                                        props
                                                                    ) => {
                                                                        let message =
                                                                            querystring(
                                                                                'message'
                                                                            );
                                                                        let messageType =
                                                                            querystring(
                                                                                'messageType'
                                                                            );
                                                                        if (
                                                                            message
                                                                        )
                                                                            this.props.commonStore.showMessage(
                                                                                message,
                                                                                messageType,
                                                                                7000
                                                                            );

                                                                        return (
                                                                            <Redirect
                                                                                to={`${props.location.pathname}/`}
                                                                            />
                                                                        );
                                                                    }}
                                                                />

                                                                {assessmentDashboardEnabled ? (
                                                                    <PrivateRoute
                                                                        path="/"
                                                                        exact
                                                                        component={
                                                                            AssessmentHome
                                                                        }
                                                                        companyId={
                                                                            currentCompany &&
                                                                            currentCompany.company_id
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <PrivateRoute
                                                                        path="/"
                                                                        exact
                                                                        component={
                                                                            Home
                                                                        }
                                                                        companyId={
                                                                            currentCompany &&
                                                                            currentCompany.company_id
                                                                        }
                                                                    />
                                                                )}

                                                                <PrivateRoute
                                                                    path="/old_home"
                                                                    exact
                                                                    component={
                                                                        Home
                                                                    }
                                                                    companyId={
                                                                        currentCompany &&
                                                                        currentCompany.company_id
                                                                    }
                                                                />
                                                                <AuthRoute
                                                                    path="/auth/signup"
                                                                    exact
                                                                    component={
                                                                        SignUp
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/trial"
                                                                    exact
                                                                >
                                                                    <Redirect
                                                                        to={
                                                                            '/auth/signup'
                                                                        }
                                                                    />
                                                                </Route>
                                                                <Route
                                                                    path="/dummies"
                                                                    exact
                                                                >
                                                                    <Redirect
                                                                        to={
                                                                            '/auth/signup'
                                                                        }
                                                                    />
                                                                </Route>
                                                                <Route
                                                                    path="/ppv-trial"
                                                                    exact
                                                                    component={
                                                                        TrialPPV
                                                                    }
                                                                />
                                                                <PrivateRoute
                                                                    path="/ppv"
                                                                    exact
                                                                    component={
                                                                        PPVHome
                                                                    }
                                                                    companyId={
                                                                        currentCompany &&
                                                                        currentCompany.company_id
                                                                    }
                                                                />
                                                                <PrivateRoute
                                                                    path="/ppv/usage"
                                                                    exact
                                                                    component={
                                                                        CompanyPPVLogView
                                                                    }
                                                                    companyId={
                                                                        currentCompany &&
                                                                        currentCompany.company_id
                                                                    }
                                                                />
                                                                <PrivateRoute
                                                                    path="/ppv/embed/:subjectId"
                                                                    exact
                                                                    component={
                                                                        PPVEmbed
                                                                    }
                                                                    companyId={
                                                                        currentCompany &&
                                                                        currentCompany.company_id
                                                                    }
                                                                />
                                                                <PrivateRoute
                                                                    path="/super"
                                                                    component={
                                                                        Super
                                                                    }
                                                                />
                                                                <PrivateRoute
                                                                    path="/settings"
                                                                    component={
                                                                        Settings
                                                                    }
                                                                    companyId={
                                                                        currentCompany &&
                                                                        currentCompany.company_id
                                                                    }
                                                                />
                                                                <PrivateRoute
                                                                    path="/partner"
                                                                    component={
                                                                        Partner
                                                                    }
                                                                />

                                                                {trainingEnabled ? (
                                                                    <PrivateRoute
                                                                        path="/catalog"
                                                                        component={
                                                                            Catalog
                                                                        }
                                                                        companyId={
                                                                            currentCompany &&
                                                                            currentCompany.company_id
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <PrivateRoute
                                                                        path="/catalog"
                                                                        exact
                                                                    >
                                                                        <Redirect
                                                                            to={
                                                                                '/'
                                                                            }
                                                                        />
                                                                    </PrivateRoute>
                                                                )}
                                                                <PrivateRoute
                                                                    path="/embed"
                                                                    exact
                                                                    component={
                                                                        Embed
                                                                    }
                                                                />
                                                                <PrivateRoute
                                                                    path="/users"
                                                                    component={
                                                                        Users
                                                                    }
                                                                    companyId={
                                                                        currentCompany &&
                                                                        currentCompany.company_id
                                                                    }
                                                                />
                                                                <PrivateRoute
                                                                    path="/subscriptions"
                                                                    component={
                                                                        Subscriptions
                                                                    }
                                                                    companyId={
                                                                        currentCompany &&
                                                                        currentCompany.company_id
                                                                    }
                                                                />
                                                                <PrivateRoute
                                                                    path="/automations"
                                                                    component={
                                                                        Automations
                                                                    }
                                                                    companyId={
                                                                        currentCompany &&
                                                                        currentCompany.company_id
                                                                    }
                                                                />
                                                                {trainingEnabled ? (
                                                                    <PrivateRoute
                                                                        path="/trainings"
                                                                        component={
                                                                            Automations
                                                                        }
                                                                        companyId={
                                                                            currentCompany &&
                                                                            currentCompany.company_id
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <PrivateRoute path="/trainings">
                                                                        <Redirect
                                                                            to={
                                                                                '/'
                                                                            }
                                                                        />
                                                                    </PrivateRoute>
                                                                )}
                                                                {assessmentEnabled ? (
                                                                    <PrivateRoute
                                                                        path="/assessment"
                                                                        component={
                                                                            Assessment
                                                                        }
                                                                        companyId={
                                                                            currentCompany &&
                                                                            currentCompany.company_id
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <PrivateRoute path="/assessment">
                                                                        <Redirect
                                                                            to={
                                                                                '/'
                                                                            }
                                                                        />
                                                                    </PrivateRoute>
                                                                )}

                                                                <PrivateRoute
                                                                    path="/resources"
                                                                    component={
                                                                        Resources
                                                                    }
                                                                />
                                                                <PrivateRoute
                                                                    path="/reports"
                                                                    component={
                                                                        Reports
                                                                    }
                                                                    companyId={
                                                                        currentCompany &&
                                                                        currentCompany.company_id
                                                                    }
                                                                />
                                                                <AuthRoute
                                                                    path="/auth/signin"
                                                                    exact
                                                                    component={
                                                                        SignIn
                                                                    }
                                                                />
                                                                <AuthRoute
                                                                    path="/auth/check-email"
                                                                    exact
                                                                    component={
                                                                        CheckEmail
                                                                    }
                                                                />
                                                                <Route
                                                                    path="/auth/error/email"
                                                                    exact
                                                                    component={
                                                                        EmailSignInError
                                                                    }
                                                                />

                                                                {/* Finally, catch all unmatched routes */}
                                                                <Route
                                                                    component={
                                                                        NotFound
                                                                    }
                                                                />
                                                            </Switch>
                                                        </Layout>
                                                    ) : (
                                                        <Container className="main-content">
                                                            {' '}
                                                            <Waiting
                                                                waitingActive={
                                                                    appLoaded
                                                                }
                                                                fullScreen={
                                                                    true
                                                                }
                                                            />
                                                        </Container>
                                                    )}

                                                    <Snackbar
                                                        anchorOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                        autoHideDuration={6000}
                                                        variant={
                                                            this.props
                                                                .commonStore
                                                                .messageType
                                                        }
                                                        message={
                                                            this.props
                                                                .commonStore
                                                                .message || ''
                                                        }
                                                        open={messageShown}
                                                        onClose={
                                                            this.closeSnackbar
                                                        }
                                                    ></Snackbar>
                                                    <ConfirmationModal />
                                                </Fragment>
                                            );
                                        }}
                                    />
                                </Switch>
                            </Fragment>
                        </I18nextProvider>
                    )}
                </ThemeProvider>
            </MuiThemeProvider>
        );
    }
}

export default withRouter(App);
