import Form from 'mobx-react-form';
import * as validatorjs from 'validatorjs';

const plugins = { dvr: validatorjs };

const fields = {
    name: {
        name: 'name',
        label: 'Name',
        rules: 'required|string',
        placeholder: 'Enter name of admin',
    },
    email: {
        name: 'email',
        label: 'Work Email Address',
        rules: 'required|email|string',
        placeholder: 'name@company.com',
        type: 'email',
    },
    sendWelcome: {
        name: 'sendWelcome',
        label: 'Send a welcome email',
        rules: 'boolean',
        type: 'checkbox',
        value: false,
    },
};

const options = {
    showErrorsOnReset: false,
};

class AddManager extends Form {
    constructor(setup, hooks) {
        super({ fields, ...setup }, { plugins, hooks, options });
    }
}

export default AddManager;
