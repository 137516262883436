import React from 'react';
import { Button, Alert, Container } from 'reactstrap';
import PropTypes from 'prop-types';
import ErrorMessage from '../../errormessage';
import AsyncData from '../manager-data';

class AssignCredit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            company_id: -1,
            new_company_name: props.unusedPackage
                ? props.unusedPackage.company_name
                : '',
            error: '',
        };
    }

    async onAssign() {
        try {
            const data = {};
            data.company_id = this.state.company_id;
            data.new_company_name = this.state.new_company_name;
            data.package_id = this.props.unusedPackage.id;
            const token = this.props.csrfToken;
            const serverData = await AsyncData.assignPackageToCompany(
                null,
                data,
                token
            );
            if (serverData) {
                const objServerData = JSON.parse(serverData);
                if (
                    objServerData.hasOwnProperty('error') &&
                    objServerData['error']
                ) {
                    this.setState({ error: objServerData.error });
                    console.log('Error: ' + objServerData.error);
                } else {
                    // force refresh to show changes
                    window.location.href = '/manager';
                }
            } else {
                console.log('no data returned');
            }
        } catch (e) {
            this.setState({ error: 'Credit assign failed. ' });
            console.log('Package save failed: ' + e.message);
        }
    }

    onChangeCompany(event) {
        const company_id = event.target.value;
        this.setState({
            company_id: company_id,
        });
        this.errorCheckNewCompanyName(company_id, this.state.new_company_name);
    }

    errorCheckNewCompanyName(company_id, newName) {
        let errorMessage = '';
        if (company_id === 0) {
            if (newName.length < 3) {
                errorMessage = 'Company name must be at least three characters';
            } else {
                if (this.props.companies && this.props.companies.length > 0) {
                    for (let i = 0; i < this.props.companies.length; i++) {
                        if (
                            this.props.companies[i] &&
                            this.props.companies[i].company_name &&
                            this.props.companies[
                                i
                            ].company_name.toUpperCase() ===
                                newName.toUpperCase()
                        ) {
                            errorMessage =
                                'You already have a company with this name';
                        }
                    }
                }
            }
        }
        this.setState({ error: errorMessage });
    }

    onChangeCompanyName(event) {
        const companyName = event.target.value;
        this.setState({ new_company_name: companyName });
        this.errorCheckNewCompanyName(this.state.company_id, companyName);
    }

    render() {
        const isInputOk =
            this.state.error === '' && this.state.company_id !== -1;

        if (this.props.unusedPackage) {
            return (
                <div>
                    <Alert color="success" className="header">
                        <Container>
                            <h4>
                                You currently have unassigned credits for{' '}
                                <strong>
                                    {this.props.unusedPackage.users}{' '}
                                </strong>{' '}
                                users
                            </h4>
                            <div className="title">
                                Select a company from the list or create a new
                                one
                            </div>
                            <select
                                name="company"
                                className="select-form"
                                value={this.state.company_id}
                                onChange={(e) => this.onChangeCompany(e)}
                            >
                                <option className="tl" value="-1" />
                                <option className="tl" value="0">
                                    ( Create new company )
                                </option>
                                {this.props.companies.map((comp, i) => (
                                    <option
                                        key={i}
                                        className="tl"
                                        value={comp.company_id}
                                    >
                                        {comp.company_name}
                                    </option>
                                ))}
                            </select>
                            &nbsp;&nbsp;&nbsp;
                            {this.state.company_id === 0 && (
                                <input
                                    type="input"
                                    id="companyName"
                                    name="companyName"
                                    value={this.state.new_company_name}
                                    onChange={(e) =>
                                        this.onChangeCompanyName(e)
                                    }
                                />
                            )}
                            &nbsp;&nbsp;&nbsp;
                            <Button
                                className="btn btn-primary"
                                disabled={!isInputOk}
                                onClick={() => this.onAssign()}
                            >
                                Assign to company
                            </Button>
                            <ErrorMessage error={this.state.error} />
                        </Container>
                    </Alert>
                </div>
            );
        } else {
            return null;
        }
    }
}

AssignCredit.propTypes = {
    unusedPackage: PropTypes.object,
    companies: PropTypes.array,
};

export default AssignCredit;
