import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { colors } from './design-system/colors';

const Alert = withStyles(() => ({
    root: {
        borderWidth: '1px',
        borderStyle: 'solid',
        fontWeight: '600',
        fontSize: '14px',
        marginTop: '16px',
        marginBottom: '16px',
    },

    standardSuccess: {
        borderColor: colors.successLight,
        color: colors.success,
    },

    standardError: {
        borderColor: colors.errorLight,
        color: colors.error,
    },

    message: {
        '& a': {
            textDecoration: 'underline',
        },
    },
}))(MuiAlert);

export default Alert;
