import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container } from 'reactstrap';
import AssessmentList from './AssessmentList';
import AssessmentView from './AssessmentView';
import { Helmet } from 'react-helmet';
import AssessmentCreate from './AssessmentCreate';
import { useStores } from '../../hooks/use-stores';
import AssessmentUpgrade from '../../components/manager/assessment/AssessmentUpgrade';
import NoItemsYet from '../../components/manager/no-items-yet';
import { FlexDiv } from '../../components/design-system/containers';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import { FullWidthLink } from '../../components/design-system/miscellaneous';
import LinkIfNeed from '../../components/LinkIfNeed';

export default observer(({ companyId }) => {
    const match = useRouteMatch();
    const { brandingStore, store } = useStores();
    const { supportEmail } = brandingStore;
    const { assessmentsUnavailable, assessmentsUpgradeAllowed } = store;

    return (
        <Container className="manager-content">
            <Helmet>
                <title>Assessment</title>
            </Helmet>
            {assessmentsUnavailable && (
                <NoItemsYet
                    img={'/static/img/assessment-locked.svg'}
                    imgAlt={'assessments are unavailable'}
                    title={'Assessments are unavailable in your current plan'}
                    text={
                        'Please upgrade your subscription to access the assessments.'
                    }
                    heading={'Assessments'}
                    buttons={
                        assessmentsUpgradeAllowed ? (
                            <FlexDiv>
                                <LinkIfNeed
                                    link={
                                        'https://awarego.com/human-risk-assessment/'
                                    }
                                >
                                    <ThemedButton secondary airy>
                                        Learn more
                                    </ThemedButton>
                                </LinkIfNeed>
                                <LinkIfNeed link={'/subscriptions/upgrade'}>
                                    <ThemedButton primary airy>
                                        Upgrade now
                                    </ThemedButton>
                                </LinkIfNeed>
                            </FlexDiv>
                        ) : (
                            <a href={`mailto:${supportEmail}`}>
                                <ThemedButton primary fullWidth>
                                    Contact to upgrade
                                </ThemedButton>
                            </a>
                        )
                    }
                />
            )}
            {!assessmentsUnavailable && (
                <Switch>
                    <Route
                        path={`${match.url}/edit/:id`}
                        render={(props) => (
                            <AssessmentCreate
                                parentMatch={match}
                                companyId={companyId}
                                editId={props.match.params.id}
                            />
                        )}
                    />
                    <Route
                        path={`${match.url}/create/copy/:fromId`}
                        render={(props) => (
                            <AssessmentCreate
                                parentMatch={match}
                                companyId={companyId}
                                copyFrom={props.match.params.fromId}
                            />
                        )}
                    />
                    <Route
                        path={`${match.url}/create`}
                        render={() => (
                            <AssessmentCreate
                                parentMatch={match}
                                companyId={companyId}
                            />
                        )}
                    />

                    <Route
                        path={`${match.url}/:id/view`}
                        render={(props) => (
                            <AssessmentView
                                match={match}
                                companyId={companyId}
                                assessmentId={props.match.params.id}
                            />
                        )}
                    />
                    <Route
                        path={`${match.url}/active`}
                        exact
                        render={() => (
                            <AssessmentList
                                tab="active"
                                parentMatch={match}
                                companyId={companyId}
                            />
                        )}
                    />

                    <Route
                        path={`${match.url}/completed`}
                        exact
                        render={() => (
                            <AssessmentList
                                tab="completed"
                                parentMatch={match}
                                companyId={companyId}
                            />
                        )}
                    />
                    <Route
                        path={`${match.url}/draft`}
                        exact
                        render={() => (
                            <AssessmentList
                                tab="draft"
                                parentMatch={match}
                                companyId={companyId}
                            />
                        )}
                    />
                    <Route
                        path={`${match.url}`}
                        render={() => (
                            <AssessmentList
                                tab="all"
                                parentMatch={match}
                                companyId={companyId}
                            />
                        )}
                    />
                </Switch>
            )}
        </Container>
    );
});
