import React from 'react';
import { observer } from 'mobx-react';
import ReactJWPlayer from 'react-jw-player';
import Modal from 'react-modal';
import { ThemedButton } from './themed/ThemedComponents';
import SubjectDetails from './manager/catalog/details';

@observer
class VideoPreview extends React.Component {
    afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        //this.subtitle.style.color = '#f00';
    };

    onError = (ev, data) => {
        if (ev.code === 232403) {
            this.props.videoTokenRequested(this.props.previewVideoLocation);
        }
    };

    onSetupError = (ev) => {
        if (ev.code === 102403) {
            this.props.videoTokenRequested(this.props.previewVideoLocation);
        }
    };

    onFle = (ev) => {
        this.props.onClose();
    };

    renderPlayer = (videoPlayerUrl, token, playlist) => {
        return this.props.isOpen ? (
            <div id="video" className="video dark">
                <img
                    className="modal-close-right"
                    src="/static/img/close-white.svg"
                    alt="Close"
                    onClick={this.onFle}
                />
                {videoPlayerUrl && token && (
                    <ReactJWPlayer
                        onSetupError={this.onSetupError}
                        onError={this.onError}
                        className="video-item"
                        playerId="Awarego"
                        playerScript={videoPlayerUrl}
                        licenseKey="tbMPLx6pAOjLHsSbrqGJdPVygT90S8O71WG28+3dDJA="
                        playlist={playlist}
                        width="100%"
                        aspectratio="16:9"
                        isAutoPlay={false}
                        customProps={{ skin: { name: 'AwareGO' } }}
                    />
                )}
            </div>
        ) : (
            <div />
        );
    };

    render() {
        const {
            isOpen,
            onClose,
            videoPlayerUrl,
            token,
            modal = true,
            subject,
        } = this.props;

        // const playlist = "https://cdn.jwplayer.com/v2/media/rcHJRYaG" + (token? `?token=${token}`:"")
        //const playlist =  'https://cdn.jwplayer.com/v2/media/'+previewVideoLocation+ (token? `?token=${token}`:"")
        const playlist = token && [
            {
                file:
                    'https://content.jwplatform.com/manifests/' +
                    token.videoId +
                    '.m3u8' +
                    (token ? token.v1 : ''),
                image:
                    '//content.jwplatform.com/thumbs/' +
                    token.videoId +
                    '-1920.jpg',
            },
        ];
        if (modal && subject) {
            return (
                <Modal
                    isOpen={isOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={onClose}
                    className="Modal schedule"
                >
                    <div className={'modalHeader'}>
                        <h3>Preview lesson: {subject.title}</h3>
                        <img
                            className={'modal-close-right'}
                            src="/static/img/close.svg"
                            alt="Close"
                            onClick={onClose}
                        />
                    </div>
                    <div className={'modalContent'}>
                        <h5>Step 1: Watch the video</h5>
                        <div className="preview-video">
                            {this.renderPlayer(
                                videoPlayerUrl,
                                token,
                                playlist,
                                true
                            )}
                        </div>
                        <br />
                        <h5>Step 2: Complete the quiz</h5>
                        {(subject.questions || []).map((x, i) => {
                            return (
                                <div key={i}>
                                    <div className={'label'}>
                                        Question {i + 1}/
                                        {subject.questions.length}
                                    </div>
                                    {x.question}
                                </div>
                            );
                        })}
                        <br />
                        <h5>Step 3: Read the text</h5>
                        <SubjectDetails subject={subject} />
                        <br />
                        <br />
                    </div>
                    <div className={'modalFooter'}>
                        <ThemedButton primary onClick={onClose}>
                            Back to add subjects
                        </ThemedButton>
                    </div>
                </Modal>
            );
        } else {
            return this.renderPlayer(videoPlayerUrl, token, playlist);
        }
    }
}

export default VideoPreview;
