import React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Container } from 'reactstrap';
import CompaniesList from './CompaniesList';
import CompanyDetailsContainer from './CompanyDetails';
import PartnerResourcesContainer from './PartnerResources';
import { enforceSlash } from '../../utils/helpers';

@inject('authStore')
@observer
class Partner extends React.Component {
    render() {
        const { match, authStore } = this.props;

        // This is here only to fix the reroute to '/partner/companies/' from '/partner/'.
        // It removes the trailing slash that caused the path to be '/partner//companies/'
        const RemoveTrailingSlashAndRouteToCompanies = ({ location }) => {
            const { pathname } = location;
            if (pathname.substr(-1) === '/') {
                return (
                    <Redirect
                        to={
                            pathname.substr(0, pathname.length - 1) +
                            '/companies'
                        }
                    />
                );
            } else {
                return null;
            }
        };

        const RemoveTrailingSlashAndRouteToFirstPartner = ({ location }) => {
            let partner = authStore.currentUser.superForAccounts[0];

            if (partner) {
                return (
                    <Redirect to={`${enforceSlash(match.url)}${partner.id}`} />
                );
            } else {
                return <Redirect to={`/`} />;
            }
        };

        return (
            <Switch>
                <Route
                    path={`${match.url}/`}
                    exact
                    render={RemoveTrailingSlashAndRouteToFirstPartner}
                />
                <Route
                    path={`${match.url}/:partnerId/`}
                    exact
                    render={RemoveTrailingSlashAndRouteToCompanies}
                />

                <Route
                    path={`${match.url}/:partnerId/companies/:company_id`}
                    exact
                    render={(props) => (
                        <Container className="manager-content content  wide user-table  campaigns">
                            <CompanyDetailsContainer parentMatch={match} />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/:partnerId/companies`}
                    render={(props) => (
                        <Container className="wide campaigns">
                            <CompaniesList
                                parentMatch={match}
                                partnerId={props.match.params.partnerId}
                            />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/:partnerId/resources`}
                    render={(props) => (
                        <Container className="manager-content campaigns wide">
                            <PartnerResourcesContainer parentMatch={match} />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/:partnerId/payments`}
                    exact
                    render={(props) => (
                        <Container className="manager-content list-table">
                            <h2>Payments</h2>
                        </Container>
                    )}
                />
            </Switch>
        );
    }
}

export default withRouter(Partner);
