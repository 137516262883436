import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import { Link, withRouter } from 'react-router-dom';
import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input,
    Spinner as CircularSpinner,
} from 'reactstrap';
import ErrorMessage from '../../components/errormessage';
import { extractErrorMessage, formatDate } from '../../utils/helpers';
import PackageTable from '../../components/super/packages';
import AddPackage from '../../components/super/add-package';
import AddManager from '../../components/super/add-manager';
import moment from 'moment/moment';
import Clipboard from 'react-clipboard.js';
import { enforceSlash } from '../../utils/helpers';
import SelectSubjects from '../../components/manager/automation/subject-select2';
import Modal from 'react-modal';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import Countdown from 'react-countdown';
import SettingsTable from '../../components/super/settings';
import AddSetting from '../../components/super/add-setting';

const TOKEN_TIMER = 1 * 60 * 1000;

@inject('superStore', 'catalogStore')
@observer
class CompaniesViewSuperContainer extends React.Component {
    state = {
        companyId: null,
        loadedOrError: null,
        views: 100,
        notes: '',
        subjectsModal: false,
        selectedVideosIds: [],
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState) return; // not yet initialized
        if (nextProps.companyId !== prevState.companyId) {
            return {
                companyId: nextProps.companyId,
                loadedOrError: null,
            };
        }

        // No state update necessary
        return null;
    }

    constructor(props) {
        super(props);
        this.props.superStore.clearEditingCompany();
    }

    componentDidMount() {
        // It's preferable in most cases to wait until after mounting to load data.
        // See below for a bit more context...
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loadedOrError === null) {
            // At this point, we're in the "commit" phase, so it's safe to load the new data.
            this.loadData();
        }
    }

    async loadData() {
        this.setState({ loadedOrError: false });
        try {
            await this.props.catalogStore.loadCatalog(this.state.companyId);
            await this.props.superStore.listFreemiums();
            this.props.superStore.loadCompany(this.state.companyId);
            this.setState({ loadedOrError: true });
        } catch (e) {
            this.setState({ loadedOrError: extractErrorMessage(e) });
        }
    }

    removeSetting = (settingId) => {
        this.props.superStore.removeCompanySetting(
            this.state.companyId,
            settingId
        );
    };

    addSetting = (name, value) => {
        const data = { name, value };
        this.props.superStore.addCompanySetting(this.state.companyId, data);
    };

    onChangeSetting = (id, value) => {
        this.props.superStore.updateCompanySetting(
            this.state.companyId,
            id,
            value
        );
    };

    addPackage = (
        packageUsers,
        valid_from,
        valid_to,
        packageTrial,
        freemiumCode
    ) => {
        const data = {};
        data.users = packageUsers;
        data.validFrom = valid_from;
        data.validTo = valid_to;
        data.trial = packageTrial;
        data.company_id = this.state.companyId;
        data.freemiumCode = freemiumCode;
        this.props.superStore.addPackage(this.state.companyId, data);
    };

    removePackage = (packageId) => {
        this.props.superStore.removePackage(this.state.companyId, packageId);
    };

    onChangePackageType = (packageId, type) => {
        this.props.superStore.updatePackage(this.state.companyId, packageId, {
            full_package: type,
        });
    };

    addManager = (name, email) => {
        this.props.superStore.addManager(this.state.companyId, { name, email });
    };

    removeManager = (email) => {
        this.props.superStore.removeManager(this.state.companyId, email);
    };

    createPPV = (email) => {
        this.props.superStore.createPPV(this.state.companyId);
    };

    newPPVApiKey = (email) => {
        this.props.superStore.newPPVApiKey(this.state.companyId);
    };

    impersonate = (manager) => {
        if (manager.timer) {
            clearTimeout(manager.timer);
            manager.timer = null;
        }
        let link = this.props.superStore.impersonate(manager.email);
        if (link) {
            manager.timer = setTimeout(() => {
                manager.timer = null;
                this.props.superStore.removeToken(manager.email);
            }, TOKEN_TIMER);
        }
    };

    addViews = (email) => {
        this.props.superStore.addViews(
            this.state.companyId,
            Number(this.state.views) || 0,
            this.state.notes
        );
    };

    removeViews = (email) => {
        this.props.superStore.addViews(
            this.state.companyId,
            -1 * (Number(this.state.views) || 0),
            this.state.notes
        );
    };

    setViews = (e) => {
        this.setState({ views: e.target.value });
    };

    setNotes = (e) => {
        this.setState({ notes: e.target.value });
    };

    hideSubjectsModal = () => {
        this.setState({
            subjectsModal: false,
            selectedVideosIds: [],
            packageId: null,
        });
    };

    applyVideos = (packageId) => {
        this.props.superStore.updatePackageVideos(
            this.state.companyId,
            this.state.packageId,
            this.state.selectedVideosIds
        );
        this.hideSubjectsModal();
    };

    showSubjectsModal = (pack) => {
        this.setState({
            subjectsModal: true,
            packageId: pack.package_id,
            selectedVideosIds: observable((pack.videos || []).slice(0)),
        });
    };

    toggleVideoSelected = (videoId) => {
        if (this.state.selectedVideosIds.includes(videoId))
            this.state.selectedVideosIds.remove(videoId);
        else this.state.selectedVideosIds.push(videoId);
    };

    render() {
        const {
            loadingCompany,
            addingPackage,
            addingManager,
            addingCompanySetting,
            removingPackages,
            removingManagers,
            removingCompanySettings,
            savingPPV,
            savingPPVApiKey,
            freemiums,
            managerTokensIndex,
            editingCompany: company,
            error,
        } = this.props.superStore;
        const { catalog, allSubjectsIndex } = this.props.catalogStore;
        const { match } = this.props;
        company && console.log(company.settings);

        return loadingCompany ? (
            <ThemedSpinner />
        ) : (
            <Fragment>
                <Row className={'super-company-root'}>
                    <Col md="12" xs="12">
                        <div className="block">
                            {company && (
                                <Fragment>
                                    <div key={company.id}>
                                        <h2>{company.company_name}</h2>
                                        <div className="pull-right">
                                            <Link
                                                to={`${enforceSlash(
                                                    match.url
                                                )}edit`}
                                                className="airy-button"
                                            >
                                                <div className="btn btn-primary">
                                                    Edit
                                                </div>
                                            </Link>
                                            <Link
                                                to={`${enforceSlash(
                                                    match.url
                                                )}automations`}
                                                className="airy-button"
                                            >
                                                <div className="btn btn-primary ">
                                                    View Automations
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <h3>Settings</h3>
                                    <div className="table-white-bg">
                                        <SettingsTable
                                            settings={company.settings}
                                            removeSetting={this.removeSetting}
                                            removingSettings={
                                                removingCompanySettings
                                            }
                                            onChangeSettingValue={
                                                this.onChangeSetting
                                            }
                                        />
                                    </div>
                                    <div className="padded-container">
                                        <AddSetting
                                            saveSetting={this.addSetting}
                                            addingSetting={addingCompanySetting}
                                        />
                                    </div>
                                    <div className="main-content">
                                        <h3>PPV package</h3>
                                        <div className="table-white-bg">
                                            {company && company.ppv_package && (
                                                <Fragment>
                                                    <div className="flex flex-column">
                                                        <p>
                                                            <span className="green">
                                                                {' '}
                                                                Created{' '}
                                                                {moment(
                                                                    company
                                                                        .ppv_package
                                                                        .created
                                                                ).format('LL')}
                                                            </span>
                                                            ,{' '}
                                                            {moment(
                                                                company
                                                                    .ppv_package
                                                                    .created
                                                            ).fromNow()}
                                                        </p>
                                                        <p>
                                                            ApiKey:
                                                            <Clipboard
                                                                className="copy-to-clipboard-link"
                                                                data-clipboard-text={
                                                                    company
                                                                        .ppv_package
                                                                        .apiKey
                                                                }
                                                            >
                                                                {
                                                                    company
                                                                        .ppv_package
                                                                        .apiKey
                                                                }
                                                            </Clipboard>
                                                            {savingPPVApiKey ? (
                                                                <CircularSpinner />
                                                            ) : (
                                                                <Button
                                                                    onClick={
                                                                        this
                                                                            .newPPVApiKey
                                                                    }
                                                                    className="airy-button"
                                                                >
                                                                    Regenerate
                                                                </Button>
                                                            )}
                                                        </p>
                                                        <p>
                                                            Views Purchased:{' '}
                                                            {
                                                                company
                                                                    .ppv_package
                                                                    .viewsPurchased
                                                            }
                                                            <Row
                                                                form
                                                                tag={'span'}
                                                            >
                                                                <Col
                                                                    md={3}
                                                                    tag={'span'}
                                                                >
                                                                    <FormGroup
                                                                        tag={
                                                                            'span'
                                                                        }
                                                                    >
                                                                        <Label for="views">
                                                                            Views
                                                                        </Label>
                                                                        <Input
                                                                            type="number"
                                                                            name="views"
                                                                            id="views"
                                                                            onChange={
                                                                                this
                                                                                    .setViews
                                                                            }
                                                                            value={
                                                                                this
                                                                                    .state
                                                                                    .views
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col
                                                                    md={3}
                                                                    tag={'span'}
                                                                >
                                                                    <FormGroup
                                                                        tag={
                                                                            'span'
                                                                        }
                                                                    >
                                                                        <Label for="notes">
                                                                            Notes
                                                                        </Label>
                                                                        <Input
                                                                            type="text"
                                                                            name="views"
                                                                            id="notes"
                                                                            onChange={
                                                                                this
                                                                                    .setNotes
                                                                            }
                                                                            value={
                                                                                this
                                                                                    .state
                                                                                    .notes
                                                                            }
                                                                            placeholder={
                                                                                'Notes'
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row
                                                                form
                                                                tag={'span'}
                                                            >
                                                                <Col
                                                                    md={3}
                                                                    tag={'span'}
                                                                >
                                                                    {savingPPV ? (
                                                                        <CircularSpinner
                                                                            tag={
                                                                                'span'
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <Fragment>
                                                                            <Button
                                                                                onClick={
                                                                                    this
                                                                                        .addViews
                                                                                }
                                                                                className="airy-button"
                                                                            >
                                                                                Add
                                                                            </Button>
                                                                            <Button
                                                                                onClick={
                                                                                    this
                                                                                        .removeViews
                                                                                }
                                                                                className="airy-button"
                                                                            >
                                                                                Remove
                                                                            </Button>
                                                                        </Fragment>
                                                                    )}
                                                                </Col>
                                                            </Row>
                                                        </p>
                                                        <p>
                                                            Views Used:{' '}
                                                            {
                                                                company
                                                                    .ppv_package
                                                                    .viewsUsed
                                                            }
                                                        </p>
                                                        <p>
                                                            <Link
                                                                to={`${enforceSlash(
                                                                    match.url
                                                                )}ppv_transactions`}
                                                            >
                                                                Transactions
                                                            </Link>
                                                        </p>

                                                        <p>
                                                            <Link
                                                                to={`${enforceSlash(
                                                                    match.url
                                                                )}ppv_log`}
                                                            >
                                                                Views Usage
                                                            </Link>
                                                        </p>
                                                    </div>
                                                </Fragment>
                                            )}
                                            {company &&
                                                !company.ppv_package && (
                                                    <Fragment>
                                                        {savingPPV ? (
                                                            <CircularSpinner />
                                                        ) : (
                                                            <Button
                                                                onClick={
                                                                    this
                                                                        .createPPV
                                                                }
                                                            >
                                                                Create PPV
                                                                package
                                                            </Button>
                                                        )}
                                                    </Fragment>
                                                )}
                                        </div>
                                    </div>
                                    <div className="main-content">
                                        <h3>Subscription packages</h3>
                                        {company && company.packages && (
                                            <PackageTable
                                                packages={company.packages}
                                                removePackage={
                                                    this.removePackage
                                                }
                                                removingPackages={
                                                    removingPackages
                                                }
                                                onEditVideos={
                                                    this.showSubjectsModal
                                                }
                                                onChangePackageType={
                                                    this.onChangePackageType
                                                }
                                            />
                                        )}
                                        <div className="padded-container">
                                            <AddPackage
                                                savePackage={this.addPackage}
                                                addingPackage={addingPackage}
                                                freemiums={freemiums}
                                            />
                                        </div>
                                    </div>
                                    <h3>Managers</h3>
                                    <div className="table-white-bg">
                                        <table className="table2">
                                            <thead>
                                                <tr>
                                                    <th>Created</th>
                                                    <th>Email</th>
                                                    <th>Name</th>
                                                    <th>Role</th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {company &&
                                                    company.managers &&
                                                    company.managers.map(
                                                        (manager) => (
                                                            <tr
                                                                key={manager.id}
                                                            >
                                                                <td>
                                                                    {formatDate(
                                                                        manager.created
                                                                    )}{' '}
                                                                </td>
                                                                <td>
                                                                    {
                                                                        manager.email
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        manager.name
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        manager.role_name
                                                                    }
                                                                </td>

                                                                <td className="text-center">
                                                                    <Fragment>
                                                                        {managerTokensIndex[
                                                                            manager
                                                                                .email
                                                                        ] &&
                                                                            managerTokensIndex[
                                                                                manager
                                                                                    .email
                                                                            ]
                                                                                .access_link && (
                                                                                <Fragment>
                                                                                    <Clipboard
                                                                                        className="copy-to-clipboard-btn"
                                                                                        data-clipboard-text={
                                                                                            managerTokensIndex[
                                                                                                manager
                                                                                                    .email
                                                                                            ]
                                                                                                .access_link
                                                                                        }
                                                                                    >
                                                                                        Copy
                                                                                        sign
                                                                                        in
                                                                                        link
                                                                                    </Clipboard>
                                                                                    <Countdown
                                                                                        date={
                                                                                            Date.now() +
                                                                                            TOKEN_TIMER
                                                                                        }
                                                                                        renderer={(
                                                                                            props
                                                                                        ) => (
                                                                                            <div>
                                                                                                {props.minutes
                                                                                                    .toString()
                                                                                                    .padStart(
                                                                                                        2,
                                                                                                        '0'
                                                                                                    ) +
                                                                                                    ':' +
                                                                                                    props.seconds
                                                                                                        .toString()
                                                                                                        .padStart(
                                                                                                            2,
                                                                                                            '0'
                                                                                                        )}
                                                                                            </div>
                                                                                        )}
                                                                                    />
                                                                                </Fragment>
                                                                            )}
                                                                        <Button
                                                                            onClick={(
                                                                                e
                                                                            ) => {
                                                                                this.impersonate(
                                                                                    manager
                                                                                );
                                                                            }}
                                                                            size="sm"
                                                                        >
                                                                            Impersonate
                                                                        </Button>
                                                                    </Fragment>
                                                                </td>
                                                                <td className="text-center">
                                                                    {removingManagers.includes(
                                                                        manager.email
                                                                    ) ? (
                                                                        <ThemedSpinner
                                                                            size={
                                                                                45
                                                                            }
                                                                        />
                                                                    ) : (
                                                                        <span
                                                                            className="delete-link"
                                                                            onClick={() =>
                                                                                this.removeManager(
                                                                                    manager.email
                                                                                )
                                                                            }
                                                                        >
                                                                            <Button
                                                                                color="danger"
                                                                                size="sm"
                                                                            >
                                                                                x
                                                                            </Button>
                                                                        </span>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="padded-container">
                                        <AddManager
                                            addingManager={addingManager}
                                            addManager={this.addManager}
                                        />
                                    </div>
                                </Fragment>
                            )}
                            <ErrorMessage error={error} />
                        </div>
                    </Col>

                    <Modal
                        isOpen={this.state.subjectsModal}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.hideSubjectsModal}
                        className="Modal subjects"
                        overlayClassName={'super-modal-root'}
                    >
                        <SelectSubjects
                            selection={this.state.selectedVideosIds}
                            catalog={catalog}
                            catalogIndex={allSubjectsIndex}
                            onRequestClose={this.hideSubjectsModal}
                            onVideoSelected={this.toggleVideoSelected}
                            onConfirm={this.applyVideos}
                        />
                    </Modal>
                </Row>
            </Fragment>
        );
    }
}

export default withRouter(CompaniesViewSuperContainer);
