import { action, computed, observable } from 'mobx';
import services from '../services';
import { extractErrorMessage, validateEmail } from '../utils/helpers';
import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';

class UsersStore {
    @observable campaignUserDetail = null;
    @observable loadingCampaignUserDetail = null;
    @observable users = observable([]);
    @observable deletingItems = observable([]);
    @observable loadingUsers = observable([]);
    @observable savingUsers = false;
    @observable processedRecords = undefined;
    @observable accumulatedUsers = observable([]);
    @observable loadingAutomationUserDetails = false;
    @observable automationUserStatus = undefined;
    @observable importError = null;

    @action
    async loadUserDetails(companyId, email) {
        if (this.loadingAutomationUserDetails) return;
        this.loadingAutomationUserDetails = true;
        try {
            this.automationUserStatus = await services.Companies.usersService(
                companyId
            ).userStatus(email);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingAutomationUserDetails = false;
        }
    }

    @action
    async loadCampaignDetails(companyId, campaignUID, email) {
        if (this.loadingCampaignUserDetail === `${campaignUID}-${email}`)
            return;
        this.loadingCampaignUserDetail = `${campaignUID}-${email}`;
        this.campaignUserDetail = null;
        try {
            this.campaignUserDetail = await services.Companies.campaignsService(
                companyId
            )
                .usersService(campaignUID)
                .userDetails(email);
        } catch (e) {
        } finally {
            this.loadingCampaignUserDetail = false;
        }
    }

    @action
    async loadUsers(companyId, listId, accumulate) {
        if (this.loadingUsers.includes(listId)) return;
        this.loadingUsers.push(listId);
        if (!accumulate) this.users.clear();
        try {
            let result;
            if (listId)
                result = await services.Companies.listsService(companyId)
                    .usersService(listId)
                    .list();
            else
                result = await services.Companies.usersService(
                    companyId
                ).list();
            if (accumulate) {
                this.accumulatedUsers.push({ listId, users: result });
            } else this.users.replace(result);
        } catch (e) {
        } finally {
            this.loadingUsers.remove(listId);
        }
    }

    @computed get accumulatedIndex() {
        return keyBy(this.accumulatedUsers, 'listId');
    }

    @action
    async delete(companyId, listId, email) {
        this.deletingItems.push(listId);
        try {
            if (Array.isArray(listId))
                await services.Companies.usersService(companyId).delete(email, {
                    lists: listId,
                });
            else
                await services.Companies.listsService(companyId)
                    .usersService(listId)
                    .delete(email);
            let user = this.users.find((x) => x.email === email);
            if (user) this.users.remove(user);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.deletingItems.remove(listId);
        }
    }

    @action
    parse(data) {
        let result = {};
        result.data = [];
        result.newUsers = [];
        result.error = '';
        result.count_total = 0;
        result.count_invalid = 0;

        let lines = data.split('\n');
        if (lines) {
            if (lines.length > 0) {
                for (let i = 0; i < lines.length; i++) {
                    let line = lines[i];
                    if (line) {
                        line = line.replace(/["']/g, '');
                        line = line.trim();
                    }
                    // discard empty lines
                    if (line.length > 0) {
                        const lineArray = line.split(/[,;]/);
                        let lineObject = {};
                        lineObject.name = '';
                        lineObject.unit = '';
                        lineObject.metadata = {};
                        lineObject.original = line;
                        lineObject.lineNumber = i + 1;
                        lineObject.error = '';
                        result.count_total++;
                        if (lineArray.length > 0) {
                            lineObject.email = lineArray[0]
                                .trim()
                                .toLowerCase();
                            if (!validateEmail(lineObject.email)) {
                                lineObject.error = 'Email not valid';
                            }
                            if (lineObject.email.length > 100) {
                                if (lineObject.error.length === 0) {
                                    lineObject.error =
                                        'Email can not be more than 100 characters';
                                }
                            }
                            if (lineArray.length > 1) {
                                lineObject.name = lineArray[1].trim();
                                if (lineObject.name.length > 100) {
                                    if (lineObject.error.length === 0) {
                                        lineObject.error =
                                            'Name can not be more than 100 characters';
                                    }
                                }
                                if (lineArray.length > 2) {
                                    lineObject.unit = lineArray[2].trim();
                                    if (lineObject.unit.length > 50) {
                                        if (lineObject.error.length === 0) {
                                            lineObject.error =
                                                'Unit can not be more than 50 characters';
                                        }
                                    }
                                    if (lineArray.length > 3) {
                                        let tagsArray = lineArray.slice(3);
                                        lineObject.metadata.tags =
                                            tagsArray.map((tag) => tag.trim());
                                    }
                                }
                            }
                        } else {
                            lineObject.error = 'No data found in line';
                        }
                        if (lineObject.error.length > 0) {
                            result.count_invalid++;
                        }
                        result.data.push(lineObject);
                    }
                }
            } else {
                result.error = 'No rows found';
            }
        } else {
            result.error = 'No data entered';
        }
        return result;
    }

    async resetUploadData() {
        this.processedRecords = null;
    }

    async saveUsers(
        users,
        companyId,
        listId,
        clientOnly = false,
        preview = false,
        removeObsolete = false
    ) {
        this.savingUsers = true;
        console.log('Users: ', users);
        try {
            let result,
                records = [];
            if (typeof users === 'string') result = this.parse(users);
            else {
                records = users;
            }
            if (
                result &&
                result.count_total /* && result.count_invalid != null && result.count_total > result.count_invalid*/
            ) {
                // create array of campaignSubjects to insert bulk
                for (let i = 0; result.data.length > i; i++) {
                    if (!result.data[i].error) {
                        records.push({
                            email: result.data[i].email,
                            name: result.data[i].name,
                            listName: result.data[i].unit,
                            metadata: result.data[i].metadata,
                        });
                    }
                }
            }
            if (clientOnly) {
                this.processedRecords = result || {};
                this.processedRecords.newUsers = records;
            } else {
                if (records.length > 0 || removeObsolete) {
                    const serverData = await services.Companies.listsService(
                        companyId
                    ).createBatch(listId, {
                        records,
                        preview,
                        removeObsolete,
                    });
                    if (result && preview) {
                        this.processedRecords = {
                            ...pick(result, [
                                'count_invalid',
                                'count_total',
                                'data',
                            ]),
                            ...serverData,
                            records,
                        };
                    } else this.processedRecords = null;
                    return true;
                } else {
                    this.processedRecords = result;
                }
            }
        } catch (e) {
            console.error(e.stack);
            this.importError = extractErrorMessage(e);
        } finally {
            this.savingUsers = false;
        }
    }

    copyErrors() {
        if (
            this.processedRecords &&
            this.processedRecords.data &&
            this.processedRecords.data.length > 0
        ) {
            let errorList = '';
            for (let i = 0; i < this.processedRecords.data.length; i++) {
                if (
                    this.processedRecords.data[i].error &&
                    this.processedRecords.data[i].error.length > 0
                ) {
                    errorList +=
                        this.processedRecords.data[i].email +
                        ', ' +
                        this.processedRecords.data[i].name +
                        ', ' +
                        this.processedRecords.data[i].unit +
                        '\n';
                }
            }
            this.processedRecords = null;
            return errorList;
        } else {
            alert('No errors available to copy');
        }
    }

    cleanEditor() {
        this.processedRecords = null;
    }
}

export default UsersStore;
