import React, { Fragment } from 'react';
import { observer } from 'mobx-react';

function TextField({
    field,
    error,
    Component = 'input',
    minWidth = '150px',
    ...rest
}) {
    return (
        <Fragment>
            <label htmlFor={field.id}>
                {field.label}
                {rest.suffix ? rest.suffix : ''}{' '}
                {field.rules.indexOf('required') > -1 && (
                    <span className="red">*</span>
                )}
            </label>
            <Component
                type="text"
                className="form-control"
                id={field.id}
                {...field.bind()}
                {...rest}
                style={{ minWidth: minWidth }}
            />
            <div className="error">{field.error || error}</div>
        </Fragment>
    );
}

export default observer(TextField);
