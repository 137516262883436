import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ErrorMessage from '../../errormessage';

@observer
class UsersListEdit extends React.Component {
    render() {
        const { form, error } = this.props;

        return (
            <div className="create-campaign">
                <div className="form-holder">
                    <p>List name</p>
                    <input
                        type="text"
                        name="listName"
                        className={
                            form.$('name').isValid
                                ? 'bigInput valid'
                                : 'bigInput'
                        }
                        {...form.$('name').bind()}
                    />
                    <div className="error">{form.$('name').error}</div>
                </div>
                <ErrorMessage error={error} errorType="warning" />
            </div>
        );
    }
}

UsersListEdit.propTypes = {
    listId: PropTypes.number,
};

export default UsersListEdit;
