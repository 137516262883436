import Form from 'mobx-react-form';
import * as validatorjs from 'validatorjs';

const plugins = { dvr: validatorjs };

const fields = {
    subject: {
        label: 'Subject',
        placeholder: 'Subject',
        default: 'Security Awareness Training',
        rules: 'string|required',
        name: 'subject',
        key: 'subject',
    },
    message: {
        label: 'Message',
        placeholder: 'Enter a message',
        rules: 'string|required',
        key: 'message',
        name: 'message',
        default: '',
    },
    mrkdwn: {},
};

class IntroductoryForm extends Form {
    constructor(initials, hooks) {
        let f = { ...fields };
        if (initials) {
            Object.keys(initials).forEach((k) => {
                if (f[k]) f[k].value = initials[k];
            });
        }
        super({ fields: f }, { plugins, hooks });
    }
}

export default IntroductoryForm;
