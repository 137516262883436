import React, { Fragment, useMemo } from 'react';
import groupBy from 'lodash/groupBy';
import { observer } from 'mobx-react';
import ThemedSpinner from '../../themed/ThemedSpinner';
import EyeIcon from '@material-ui/icons/Visibility';
import './question-select.scss';
import AddRemoveButton from '../../AddRemoveButton';
import { FlexDiv, SpaceBetweenDiv } from '../../design-system/containers';
import LockOnCircle from '../../LockOnCircle';
import { TextDefault, TextTiny } from '../../design-system/texts';
import { Heading2 } from '../../design-system/headings';

function QuestionSelect({
    questions,
    onQuestionSelected,
    selection = [],
    showPreviewQuestionsModal,
    area,
    behaviour,
}) {
    const displayedQuestions = useMemo(
        () =>
            questions.filter((q) => {
                return (
                    (area === 'all' || q.area === area) &&
                    (behaviour === 'all' ||
                        q.behaviours.some((b) => b.code === behaviour))
                );
            }),
        [questions, area, behaviour]
    );

    const questionsByArea = useMemo(
        () => groupBy(displayedQuestions, 'area'),
        [displayedQuestions]
    );
    return (
        <Fragment>
            {!questionsByArea ? (
                <ThemedSpinner />
            ) : Object.keys(questionsByArea).length === 0 ? (
                <div className="no-assessments-found">
                    <img
                        src="/static/img/icon-booklist.svg"
                        alt="No questions found"
                    />
                    <div className="stats">
                        <h5>No questions found</h5>
                        <p>
                            Try adjusting the filters to find what you were
                            looking for.
                        </p>
                    </div>
                </div>
            ) : (
                Object.keys(questionsByArea).map((a) => (
                    <Fragment key={a}>
                        <Heading2 my={14}>{a}</Heading2>
                        {questionsByArea[a].map((question, j) => (
                            <SpaceBetweenDiv py={10} key={j}>
                                {/* add/remove button */}
                                <FlexDiv alignCenter mr={8}>
                                    <AddRemoveButton
                                        selection={selection}
                                        itemId={question.id}
                                        onClick={() => {
                                            onQuestionSelected(question.id);
                                        }}
                                    />
                                </FlexDiv>
                                {/* question description */}
                                <FlexDiv
                                    column
                                    fullWidth
                                    maxWidth={500}
                                    mr={40}
                                    link
                                    onClick={() => {
                                        onQuestionSelected(question.id);
                                    }}
                                >
                                    <TextDefault
                                        bold
                                        lighter={question.disabled}
                                    >
                                        {question.name}
                                    </TextDefault>
                                    <TextTiny lighter={question.disabled}>
                                        {question.behaviours.map(
                                            (behaviour, k, arr) => (
                                                <span key={k}>
                                                    {/* don't put comma after last behaviour  */}
                                                    {behaviour.name}
                                                    {k !== arr.length - 1
                                                        ? ', '
                                                        : ''}
                                                </span>
                                            )
                                        )}
                                    </TextTiny>
                                </FlexDiv>
                                <FlexDiv width={30}>
                                    <LockOnCircle hidden={!question.disabled} />
                                </FlexDiv>

                                {/* question preview */}
                                <TextDefault
                                    themed
                                    bold
                                    link
                                    onClick={() => {
                                        showPreviewQuestionsModal(
                                            question.id,
                                            false
                                        );
                                    }}
                                    mr={8}
                                >
                                    <EyeIcon
                                        alt="preview video"
                                        color="primary"
                                        fontSize="small"
                                    />{' '}
                                    Preview
                                </TextDefault>
                            </SpaceBetweenDiv>
                        ))}
                    </Fragment>
                ))
            )}
        </Fragment>
    );
}

export default observer(QuestionSelect);
