import styled, { css } from 'styled-components';
import { withRouter } from 'react-router';
import React from 'react';
import { rgba } from 'polished';
import { colors } from './design-system/colors';

const ButtonDiv = styled.div`
    min-width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${rgba(colors.success, 0.1)};
    border-radius: 4px;
    height: 48px;
    transition: 0.2s ease all;
    font-size: 150%;
    transition: ease 0.2s background-color;
    color: ${colors.success};
    &:hover {
        background-color: ${rgba(colors.success, 0.3)};
        cursor: pointer;
    }

    ${(props) =>
        props.selected &&
        css`
            background-color: ${rgba(colors.heavyMetal, 0.05)};
            color: ${colors.heavyMetal};

            &:hover {
                background: ${rgba(colors.error, 0.3)};
                cursor: pointer;
                color: ${colors.error};
            }
        `};
`;

const AddRemoveButton = ({ selection = [], itemId, onClick }) => {
    return (
        <ButtonDiv selected={selection.includes(itemId)} onClick={onClick}>
            {selection.includes(itemId) ? '-' : '+'}
        </ButtonDiv>
    );
};

export default withRouter(AddRemoveButton);
