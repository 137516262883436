import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-modal';
import { ThemedButton } from '../components/themed/ThemedComponents';
import { useStores } from '../hooks/use-stores';

import {
    SpaceBetweenDiv,
    FlexDiv,
} from '../components/design-system/containers';
import { Heading1 } from '../components/design-system/headings';
import { TextLead, TextDefault } from '../components/design-system/texts';
import styled from 'styled-components';

const ImageAsLink = styled.img`
    cursor: pointer;
`;

const StyledConfirmationModal = styled(Modal)`
    inset: auto;
    padding: 24px 0;
    width: 672px;
    background-color: white;
    border-radius: 4px;
    max-height: 70vh;
`;

function ConfirmationModal() {
    const { commonStore } = useStores();

    const [additionalCheckboxChecked, setAdditionalCheckboxChecked] =
        useState(false);

    const {
        confirmationRequired,
        confirmationDescription,
        confirmationAction,
        confirmationTitle,
        confirmationShowCancel,
        additionalCheckbox,
        isConfirmationDelete,
    } = commonStore;

    const handleClose = () => {
        commonStore.cancelConfirm();
    };

    const onConfirm = async (arg) => {
        if (!commonStore.additionalCheckbox || additionalCheckboxChecked)
            return commonStore.doConfirm(arg);
    };

    const renderAction = (confirmationAction) => {
        if (Array.isArray(confirmationAction)) {
            return confirmationAction.map((action) => {
                return (
                    <ThemedButton
                        primary={!isConfirmationDelete}
                        key={action.key}
                        onClick={() => onConfirm(action.key)}
                        red={isConfirmationDelete}
                    >
                        {action.value}
                    </ThemedButton>
                );
            });
        } else {
            return (
                <ThemedButton
                    primary={!isConfirmationDelete}
                    onClick={onConfirm}
                    red={isConfirmationDelete}
                >
                    {confirmationAction}
                </ThemedButton>
            );
        }
    };

    const toggleAdditionalCheckboxChecked = () => {
        setAdditionalCheckboxChecked(!additionalCheckboxChecked);
    };

    return confirmationRequired ? (
        <StyledConfirmationModal isOpen={true} onRequestClose={handleClose}>
            <SpaceBetweenDiv maxHeight={88} px={48} py={24}>
                <Heading1>{confirmationTitle}</Heading1>
                <ImageAsLink
                    src="/static/img/close.svg"
                    alt="Close"
                    onClick={handleClose}
                />
            </SpaceBetweenDiv>
            <FlexDiv column py={8} px={48} overflowYauto>
                <TextLead>{confirmationDescription}</TextLead>
                {additionalCheckbox && (
                    <label htmlFor={'additionalCheckbox'}>
                        <input
                            type="checkbox"
                            className="checkmark"
                            autoComplete={false}
                            id={'additionalCheckbox'}
                            checked={additionalCheckboxChecked}
                            onChange={toggleAdditionalCheckboxChecked}
                        />
                        {additionalCheckbox}
                        <span className="checkmark"></span>
                    </label>
                )}
            </FlexDiv>
            <FlexDiv justifyEnd maxHeight={96} px={48} py={24} alignCenter>
                {confirmationShowCancel && (
                    <FlexDiv pr={40}>
                        <TextDefault
                            uppercase
                            extraBold
                            link
                            onClick={handleClose}
                        >
                            Cancel
                        </TextDefault>
                    </FlexDiv>
                )}
                {renderAction(confirmationAction)}{' '}
            </FlexDiv>
        </StyledConfirmationModal>
    ) : null;
}

export default observer(ConfirmationModal);
