import React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container } from 'reactstrap';
import CompaniesList from './CompaniesList';
import PartnerCompaniesList from '../partner/CompaniesList';
import VideosList from './VideosList';
import PartnersList from './PartnersList';
import CompanyView from './CompanyView';
import PartnerView from './PartnerView';
import CompanyEdit from './CompanyEdit';
import MailHooks from './MailHooks';
import Automations from '../automations';
import CompanyPPVLogView from './CompanyPPVLogView';
import CompanyPPVTransactionsView from './CompanyPPVTransactionsView';
import FreemiumList from './FreemiumList';
import PartnerEdit from './PartnerEdit';
import Languages from './Languages';
import LanguageEdit from './LanguageEdit';
import LanguageView from './LanguageView';
import CompanyDetailsContainer from '../partner/CompanyDetails';
import Content from './Content';
import Notifications from './Notifications';
import NotificationTargets from './NotificationTargets';

@observer
class Super extends React.Component {
    render() {
        const { match } = this.props;

        // This is here only to fix the reroute to '/super/companies/' from '/super/'.
        // It removes the trailing slash that caused the path to be '/super//companies/'
        const RemoveTrailingSlashAndRouteToCompanies = ({ location }) => {
            const { pathname } = location;
            if (pathname.substr(-1) === '/') {
                return (
                    <Redirect
                        to={
                            pathname.substr(0, pathname.length - 1) +
                            '/companies'
                        }
                    />
                );
            } else {
                return null;
            }
        };

        return (
            <Switch>
                <Route
                    path={`${match.url}/`}
                    exact
                    render={RemoveTrailingSlashAndRouteToCompanies}
                />

                <Route
                    path={`${match.url}/companies/create`}
                    exact
                    render={(props) => (
                        <Container className="manager-content content  user-table  campaigns">
                            <CompanyEdit parentMatch={match} />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/mailhooks`}
                    exact
                    render={(props) => (
                        <Container className="manager-content content  user-table  campaigns">
                            <MailHooks parentMatch={match} />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/content`}
                    render={(props) => (
                        <Container className="top-guide">
                            <Container className="block super">
                                <Content parentMatch={match} />
                            </Container>
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/languages/create`}
                    exact
                    render={(props) => (
                        <Container className="manager-content content  user-table  campaigns">
                            <LanguageEdit parentMatch={match} />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/notifications`}
                    exact
                    render={(props) => (
                        <Container className="manager-content content  user-table  campaigns super">
                            <Notifications parentMatch={match} />
                        </Container>
                    )}
                />
                <Route
                    path={`${match.url}/notifications/:reasonId`}
                    exact
                    render={(props) => (
                        <Container className="manager-content content  user-table  campaigns">
                            <NotificationTargets
                                parentMatch={match}
                                reasonId={props.match.params.reasonId}
                            />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/languages`}
                    exact
                    render={(props) => (
                        <Container className="manager-content content  user-table  campaigns super">
                            <Languages parentMatch={match} />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/languages/:id`}
                    exact
                    render={(props) => (
                        <Container className="manager-content content  user-table  campaigns super">
                            <LanguageView
                                parentMatch={match}
                                languageId={props.match.params.id}
                            />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/languages/:id/edit`}
                    exact
                    render={(props) => (
                        <Container className="manager-content content  user-table  campaigns">
                            <LanguageEdit
                                parentMatch={match}
                                languageId={props.match.params.id}
                            />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/companies/:id`}
                    exact
                    render={(props) => (
                        <Container className="manager-content content  user-table  campaigns super">
                            <CompanyView
                                parentMatch={match}
                                companyId={props.match.params.id}
                            />
                        </Container>
                    )}
                />
                <Route
                    path={`${match.url}/companies/:id/ppv_log`}
                    exact
                    render={(props) => (
                        <Container className="manager-content content  user-table  campaigns">
                            <CompanyPPVLogView
                                parentMatch={match}
                                companyId={props.match.params.id}
                            />
                        </Container>
                    )}
                />
                <Route
                    path={`${match.url}/companies/:id/ppv_transactions`}
                    exact
                    render={(props) => (
                        <Container className="manager-content content  user-table  campaigns">
                            <CompanyPPVTransactionsView
                                parentMatch={match}
                                companyId={props.match.params.id}
                            />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/companies/:id/automations`}
                    render={(props) => (
                        <Container className="manager-content super-content content">
                            <Automations
                                companyId={props.match.params.id}
                            />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/companies/:id/edit`}
                    render={(props) => (
                        <Container className="manager-content campaigns super">
                            <CompanyEdit
                                parentMatch={match}
                                companyId={props.match.params.id}
                                backUrl={`${match.url}/companies/${props.match.params.id}`}
                            />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/companies`}
                    render={(props) => (
                        <Container className="manager-content campaigns super">
                            <CompaniesList parentMatch={match} />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/partners/create`}
                    exact
                    render={(props) => (
                        <Container className="manager-content content  user-table  campaigns">
                            <PartnerEdit parentMatch={match} />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/partners/:id/edit`}
                    render={(props) => (
                        <Container className="manager-content wide campaigns super">
                            <PartnerEdit
                                parentMatch={match}
                                partnerId={props.match.params.id}
                                backUrl={`${match.url}/partners/${props.match.params.id}`}
                            />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/partners/:id/companies`}
                    exact
                    render={(props) => (
                        <Container className="manager-content wide content  user-table  campaigns">
                            <PartnerCompaniesList
                                parentMatch={match}
                                showBilling={false}
                                partnerId={props.match.params.id}
                            />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/partners/:partnerId/companies/:company_id`}
                    exact
                    render={(props) => (
                        <Container className="manager-content wide content  user-table  campaigns">
                            <CompanyDetailsContainer parentMatch={match} />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/partners/:id`}
                    exact
                    render={(props) => (
                        <Container className="manager-content wide content  user-table  campaigns super">
                            <PartnerView
                                parentMatch={match}
                                partnerId={props.match.params.id}
                            />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/partners`}
                    render={(props) => (
                        <Container className="manager-content wide campaigns super">
                            <PartnersList parentMatch={match} />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/freemium`}
                    render={(props) => (
                        <Container className="manager-content campaigns freemiums super">
                            <FreemiumList parentMatch={match} />
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/payments`}
                    exact
                    render={(props) => (
                        <Container className="manager-content list-table">
                            <h2>Payments</h2>
                        </Container>
                    )}
                />

                <Route
                    path={`${match.url}/videos/:tags`}
                    render={(props) => (
                        <Container className="manager-content campaigns">
                            <VideosList
                                parentMatch={match}
                                tags={props.match.params.tags}
                            />
                        </Container>
                    )}
                />
            </Switch>
        );
    }
}

export default withRouter(Super);
