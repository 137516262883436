import React, { Fragment } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import { useStores } from '../../hooks/use-stores';
import { FlexDiv } from '../../components/design-system/containers';
import { Heading2 } from '../../components/design-system/headings';
import { TextDefault } from '../../components/design-system/texts';

function EmailSignInError() {
    const { authStore } = useStores();
    const { isAuthenticated, currentUser } = authStore;

    let history = useHistory();

    const handleSignout = () => {
        authStore.logout();
        history.push('/');
    };
    return (
        <FlexDiv justifyCenter alignCenter wrapper column>
            {isAuthenticated ? (
                <Fragment>
                    <Heading2 mb={16}>
                        You are signed in as {currentUser.email}
                    </Heading2>
                    <Link to="/">
                        <ThemedButton primary>Go to homepage</ThemedButton>
                    </Link>
                    <TextDefault
                        onClick={() => handleSignout()}
                        link
                        lighter
                        underline
                    >
                        Sign in as another user
                    </TextDefault>
                </Fragment>
            ) : (
                <Fragment>
                    <Heading2 mb={16}>Unable to sign in</Heading2>
                    <TextDefault mb={16}>
                        The link you tried to sign in with is not valid.
                    </TextDefault>
                    <Link to="/auth/signin">
                        <ThemedButton secondary>
                            Request a new link
                        </ThemedButton>
                    </Link>
                </Fragment>
            )}
        </FlexDiv>
    );
}

export default observer(EmailSignInError);
