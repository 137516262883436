import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import UserCampaignDetails from '../../components/manager/users/UserCampaignDetails';
import BaseCompanyDependentChildContainer from '../common/BaseCompanyDependentChildContainer';
import MailLog from './MailLog';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

@inject('automationStore', 'authStore', 'commonStore')
@observer
class AutomationEventUserDetailsContainer extends BaseCompanyDependentChildContainer {
    async doLoadData() {
        return await this.props.automationStore.loadUserDetails(
            this.props.companyId,
            this.props.automationId,
            this.props.email
        );
    }

    onSendReminder = async (subject) => {
        this.props.commonStore.showConfirm(
            `Remind user to view ${subject.title}`,
            'Proceed',
            'Remind',
            async () => {
                return this.props.automationStore.resendEventMail(
                    this.props.companyId,
                    this.props.automationId,
                    subject.eventId,
                    this.props.email
                );
            },
            false
        );
    };

    render() {
        const { automationUserStatus, loadingAutomationUserDetails } =
            this.props.automationStore;
        const { currentUser, anonymousTracking } = this.props.authStore;

        return !loadingAutomationUserDetails && automationUserStatus ? (
            <Fragment>
                <Link to={`/trainings/${this.props.automationId}/view`}>
                    <div className={'navigation back-button'}>
                        Back to training
                    </div>
                </Link>
                <UserCampaignDetails
                    userDetails={automationUserStatus}
                    anonymousTracking={anonymousTracking}
                    onSendReminder={this.onSendReminder}
                    email={this.props.email}
                />
                {currentUser.super_admin && automationUserStatus.log && (
                    <MailLog records={automationUserStatus.log} />
                )}
            </Fragment>
        ) : (
            <ThemedSpinner />
        );
    }
}

export default withRouter(AutomationEventUserDetailsContainer);
