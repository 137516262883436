import React, {useMemo} from 'react';
import ThemedSpinner from '../themed/ThemedSpinner';
import { observer } from 'mobx-react';
import {
    IconButton,
} from '@material-ui/core';
import moment from 'moment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SimpleTable from '../SimpleTable';
import {TextDefault} from "../design-system/texts"

function ManagersTable({
    sendLoginInfo,
    company,
    removeManager,
    removingManagers,
}) {
    const columns = useMemo(
        () => [
            {
                Header: 'Created',
                accessor: 'created',
                Cell: x => moment(x.value).format('ll'),
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                id: 'login',
                accessor: 'email',
                Cell: x => (
                    <TextDefault
                        themed
                        bold
                        link
                        onClick={() =>
                            sendLoginInfo(x.value, company.company_id)
                        }
                    >
                        Send login link
                    </TextDefault>
                ),
            },
            {
                id: 'delete',
                accessor: 'email',
                Cell: x => (
                    removingManagers.includes(x.value) ? (
                        <ThemedSpinner size={45} />
                    ) : (
                        <IconButton
                            aria-label="delete"
                            onClick={() =>
                                removeManager(
                                    company.company_id,
                                    x.row.original.name,
                                    x.value
                                )
                            }
                            color="secondary"
                        >
                            <DeleteForeverIcon fontSize="small" />
                        </IconButton>
                    )
                ),
            },
        ],
        []
    );
    return (
        <SimpleTable columns={columns} data={company.managers} hidePagination />
    );
}

export default observer(ManagersTable);