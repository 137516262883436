import React from 'react';
import { observer } from 'mobx-react';
import ErrorMessage from '../errormessage';

@observer
class CompanyEdit extends React.Component {
    render() {
        const { form, error, email } = this.props;

        return (
            <div className="create-campaign">
                {email && (
                    <div className="form-holder">
                        <p>Email</p>
                        <input
                            type="text"
                            name="email"
                            className={'bigInput valid'}
                            value={email}
                            readOnly={true}
                        />
                    </div>
                )}

                <div className="form-holder">
                    <p>Company name</p>
                    <input
                        type="text"
                        name="companyName"
                        className={
                            form.$('name').isValid
                                ? 'bigInput valid'
                                : 'bigInput'
                        }
                        {...form.$('name').bind()}
                    />
                    <div className="error">{form.$('name').error}</div>
                </div>
                {email && (
                    <p>
                        By clicking "Continue" I agree to AwareGO's{' '}
                        <a
                            href="https://awarego.com/terms"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Terms of service
                        </a>
                        .
                    </p>
                )}

                <ErrorMessage error={error} errorType="warning" />
            </div>
        );
    }
}

export default CompanyEdit;
