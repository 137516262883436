import styled from 'styled-components';
import { rgba } from 'polished';
import { withRouter } from 'react-router';
import React from 'react';

const NotePlaceholder = styled.div`
    border: 2px dashed ${(props) => rgba(props.theme.primary, 0.4)};
    box-sizing: border-box;
    border-radius: 5px;
    width: 100%;
    min-height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: ease 0.2s all;

    &:hover {
        border: 2px dashed ${(props) => rgba(props.theme.primary, 0.9)};
        background: ${(props) => rgba(props.theme.primary, 0.06)};
    }
`;

const Plus = styled.div`
    &:after {
        content: '+';
        position: relative;
        font-size: 40px;
        width: 50px;
        height: 50px;
        color: ${(props) => props.theme.primary};
    }
`;

const Title = styled.div`
    font-weight: bold;
`;

const Description = styled.div`
    font-weight: 300;
    font-size: 0.9em;
    padding-bottom: 15px;
`;

const ThemedNotePlaceholder = (props) => {
    const { title, description, onClick } = props;
    return (
        <NotePlaceholder
            onClick={(event) => {
                onClick && onClick(event);
            }}
        >
            <Plus />
            <Title>{title}</Title>
            <Description>{description}</Description>
        </NotePlaceholder>
    );
};

export default withRouter(ThemedNotePlaceholder);
