import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { CheckboxInput, TextInput } from '../design-system/inputs';

function AddManager({managerForm}) {

    const inputHasError = input =>
        managerForm.$(input).error && managerForm.$(input).error.length > 0;

    return (
        <Fragment>
            <TextInput
                error={inputHasError('name')}
                helperText={
                    inputHasError('name')
                        ? 'Please enter name of the admin'
                        : undefined
                }
                stretched
                {...managerForm.$('name').bind()}
            />
            <TextInput
                error={inputHasError('email')}
                helperText={
                    inputHasError('email')
                        ? 'Please enter a correct work email'
                        : undefined
                }
                stretched
                {...managerForm.$('email').bind()}
            />
            <CheckboxInput
                {...managerForm.$('sendWelcome').bind()}
            />
        </Fragment>
    );
}

export default observer(AddManager)