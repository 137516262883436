import React from 'react';
import { TextDefault } from './design-system/texts';
import { colors } from './design-system/colors';
import { ThemedButton } from './themed/ThemedComponents';
import { FlexDiv } from './design-system/containers';

const CallToActionBanner = ({
    isPartnerCompany,
    supportEmail,
    contentType,
}) => {
    return (
        <FlexDiv padding={16} backgroundColor={colors.contentYellow}>
            <FlexDiv mx={32} justifyCenter alignCenter>
                <img
                    src="/static/img/lock-on-circle-icon.svg"
                    alt="Unavailable"
                />
            </FlexDiv>
            <FlexDiv column flexGrow={1}>
                <TextDefault bold>
                    This content is unavailable in your plan
                </TextDefault>
                {contentType === 'question' ? (
                    <TextDefault>
                        Please upgrade so you can add this question to your
                        assessment.
                    </TextDefault>
                ) : (
                    <TextDefault>
                        Please upgrade so you can add this video to your
                        training.
                    </TextDefault>
                )}
            </FlexDiv>
            <FlexDiv justifyCenter alignCenter mr={32}>
                {isPartnerCompany ? (
                    <a href={`mailto:${supportEmail}`}>
                        <ThemedButton small black noTextTransform>
                            Contact to upgrade
                        </ThemedButton>
                    </a>
                ) : (
                    <ThemedButton small black noTextTransform>
                        Upgrade now
                    </ThemedButton>
                )}
            </FlexDiv>
        </FlexDiv>
    );
};

export default CallToActionBanner;
