import { action, computed, observable } from 'mobx';
import services from '../services';
import { extractErrorMessage } from '../utils/helpers';
import {
    generateLoadList,
    generateCreateEntity,
    generateDeleteEntity,
    generateUpdateEntity,
} from '../utils/mobx';
import keyBy from 'lodash/keyBy';
import debounce from 'lodash/debounce';

const upper = (lower) => lower.replace(/^\w/, (c) => c.toUpperCase());

class CompanyStore {
    @observable companies = observable([]);
    @observable removingManagers = observable([]);
    @observable savingEmailSettings = observable([]);
    @observable savingEnrolmentSetting = observable([]);
    @observable addingManager = false;
    @observable loadingCompanies = false;
    @observable creatingCompany = false;
    @observable loadingIntegrations = false;
    @observable savingLanguage = false;
    @observable savingCompany = false;
    @observable syncRunning = false;
    @observable uploadingLogo = false;
    @observable switchingTrackingAnonymous = false;
    @observable company = null;
    @observable error = null;
    @observable integrationError = null;
    @observable googleError = null;
    @observable azureError = null;
    @observable azuressoError = null;
    @observable slackError = null;
    @observable addManagerError = null;
    @observable loadingApiKeys = null;
    @observable createApiKey = false;
    @observable apiKeys = observable([]);
    @observable deletingApiKeys = observable([]);
    @observable updatingApiKeys = observable([]);

    constructor(mainStore, subscriptionStore, commonStore) {
        this.mainStore = mainStore;
        this.subscriptionStore = subscriptionStore;
        this.commonStore = commonStore;
        ['azure', 'google', 'slack', 'azuresso', 'teams'].forEach((name) => {
            this[`${name}Datas`] = observable([]);
            this[`loading${upper(name)}Datas`] = observable([]);
            this[`loading${upper(name)}Groups`] = observable([]);
            this[`saving${upper(name)}Data`] = observable([]);
            this[`testing${upper(name)}Data`] = observable([]);
            this[`${name}Groups`] = observable([]);
            //TODO: need figure out how to create it  dynamically:
            //this[`${name}Error`] = observable.box(null)
        });

        this.loadIntegrations = generateLoadList(
            'integrations',
            this,
            'loadingIntegrations',
            async (companyId, options) => {
                return services.Companies.integrationsService(companyId).list(
                    options
                );
            },
            'integrations'
        );
    }

    loadApiKeys = generateLoadList(
        'apikeys',
        this,
        'loadingApiKeys',
        async (companyId) => {
            return services.Companies.apiKeysService(companyId).list();
        },
        'apiKeys'
    );

    createNewApiKey = generateCreateEntity(
        'apikeys',
        this,
        'createApiKey',
        async (companyId) => {
            return services.Companies.apiKeysService(companyId).create({});
        },
        'apiKeys'
    );

    deleteApiKey = generateDeleteEntity(
        'apikeys',
        this,
        'deletingApiKeys',
        async (key, __, options) => {
            return services.Companies.apiKeysService(options.companyId).delete({
                id: key,
            });
        },
        'apiKeys',
        (apiKeys, key) => {
            return apiKeys.find((x) => x.id === key);
        }
    );

    @action setApiKeyName(companyId, id, value) {
        let apiKey = this.apiKeys.find((x) => x.id === id);
        if (apiKey) {
            apiKey.name = value;
            this.updateApiKeyName(companyId, id, value);
        }
    }

    updateApiKeyName = debounce(
        generateUpdateEntity(
            'apikeys',
            this,

            'updatingApiKeys',
            async (companyId, id, name) => {
                return services.Companies.apiKeysService(companyId).update(
                    { name },
                    id
                );
            }
        ),
        300
    );

    @action setError(error, name) {
        switch (name) {
            case 'integrations':
                this.integrationError = error;
                break;
            default:
                this.error = error;
        }
    }

    @action
    async loadCompanies(background = false) {
        if (!background) {
            this.loadingCompanies = true;
            this.error = null;
            this.companies.clear();
        }

        try {
            const result = await services.Companies.myCompanies();
            this.companies.replace(result);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingCompanies = false;
        }
    }

    @action
    async switchTrackingAnonymous(companyId) {
        this.switchingTrackingAnonymous = true;
        this.error = null;
        try {
            await services.Companies.switchTrackingAnonymous(companyId);
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company) company.anonymousTracking = true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.switchingTrackingAnonymous = false;
        }
    }

    @action
    async changeLanguage(companyId, value) {
        this.savingLanguage = true;
        this.error = null;
        try {
            await services.Companies.update(
                { defaultLanguage: value },
                companyId
            );
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company) company.defaultLanguage = value;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingLanguage = false;
        }
    }

    @action
    async openManageSession(companyId) {
        return services.Companies.openManageSession(companyId);
    }

    @action
    async handleCreateCompany(partnerId, onFinish) {
        this.creatingCompany = true;
        let result = await this.create(
            {
                partnerId,
                companyName:
                    this.subscriptionStore.companyForm.values().companyName,
                subscriptionCode:
                    this.subscriptionStore.companyForm.values().subscription,
                seats: this.subscriptionStore.companyForm.values().seats,
                validTo: this.subscriptionStore.companyForm.values().valid,
                validTill:
                    this.subscriptionStore.companyForm.values().validTill,
            },
            true
        );

        if (result) {
            this.creatingCompany = false;
            if (onFinish) onFinish();
            this.commonStore.success('Company created.');
        } else {
            this.creatingCompany = false;
            if (this.error) {
                this.commonStore.error(this.error);
            }
        }
    }

    @action
    async create(values, loadCompany) {
        this.savingCompany = true;
        try {
            const newCompanyId = values.partnerId
                ? await services.Partners.companiesService(
                      values.partnerId
                  ).create(values)
                : await services.Companies.create(values);
            if (newCompanyId && loadCompany) {
                this.loadCompanies(true);
                const result = await services.Companies.myCompanies();
                this.companies.replace(result);
                let company = this.companies.find(
                    (x) => x.company_id === newCompanyId
                );
                if (company) {
                    this.setCompany(company);
                }
            }

            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingCompany = false;
        }
    }

    @action
    async save(companyId, values) {
        this.savingCompany = true;
        try {
            let result = await services.Companies.update({
                id: companyId,
                ...values,
            });
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company)
                Object.assign(company, result, { company_name: result.name });
            return true;
        } catch (e) {
            console.error('Save failed: ', e);
            this.error = extractErrorMessage(e);
        } finally {
            this.savingCompany = false;
        }
    }

    @action
    async saveEmailSettings(companyId, values) {
        if (this.savingEmailSettings.includes(companyId)) return false;
        this.savingEmailSettings.push(companyId);
        try {
            let body = {
                'ui.email.trainingHeader': values.trainingHeader,
                'ui.email.trainingFooter': values.trainingFooter,
                'ui.email.reminderHeader': values.reminderHeader,
                'ui.email.reminderFooter': values.reminderFooter,
                'ui.email.certificate': values.certificate,
                'ui.email.assessmentHeader': values.assessmentHeader,
                'ui.email.assessmentFooter': values.assessmentFooter,
                'ui.email.mrkdwn_trainingHeader': values.mrkdwn_trainingHeader,
                'ui.email.mrkdwn_trainingFooter': values.mrkdwn_trainingFooter,
                'ui.email.mrkdwn_reminderHeader': values.mrkdwn_reminderHeader,
                'ui.email.mrkdwn_reminderFooter': values.mrkdwn_reminderFooter,
                'ui.email.mrkdwn_certificate': values.mrkdwn_certificate,
                'ui.email.mrkdwn_assessmentHeader':
                    values.mrkdwn_assessmentHeader,
                'ui.email.mrkdwn_assessmentFooter':
                    values.mrkdwn_assessmentFooter,
            };
            let result = await services.Companies.update({
                id: companyId,
                ...body,
            });
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company) {
                Object.assign(company, result);
            }
            if (this.mainStore.currentCompany) {
                this.mainStore.currentCompany.settings[
                    'ui.email.trainingHeader'
                ] = values.trainingHeader;
                this.mainStore.currentCompany.settings[
                    'ui.email.trainingFooter'
                ] = values.trainingFooter;
                this.mainStore.currentCompany.settings[
                    'ui.email.reminderHeader'
                ] = values.reminderHeader;
                this.mainStore.currentCompany.settings[
                    'ui.email.reminderFooter'
                ] = values.reminderFooter;
                this.mainStore.currentCompany.settings['ui.email.certificate'] =
                    values.certificate;
                this.mainStore.currentCompany.settings[
                    'ui.email.assessmentHeader'
                ] = values.assessmentHeader;
                this.mainStore.currentCompany.settings[
                    'ui.email.assessmentFooter'
                ] = values.assessmentFooter;
                this.mainStore.currentCompany.settings[
                    'ui.email.mrkdwn_trainingHeader'
                ] = values.mrkdwn_trainingHeader;
                this.mainStore.currentCompany.settings[
                    'ui.email.mrkdwn_trainingFooter'
                ] = values.mrkdwn_trainingFooter;
                this.mainStore.currentCompany.settings[
                    'ui.email.mrkdwn_reminderHeader'
                ] = values.mrkdwn_reminderHeader;
                this.mainStore.currentCompany.settings[
                    'ui.email.mrkdwn_reminderHeader'
                ] = values.mrkdwn_reminderHeader;
                this.mainStore.currentCompany.settings[
                    'ui.email.mrkdwn_certificate'
                ] = values.mrkdwn_certificate;
                this.mainStore.currentCompany.settings[
                    'ui.email.mrkdwn_assessmentHeader'
                ] = values.mrkdwn_assessmentHeader;
                this.mainStore.currentCompany.settings[
                    'ui.email.mrkdwn_assessmentFooter'
                ] = values.mrkdwn_assessmentFooter;
            }
        } catch (e) {
            console.error('Save failed: ', e);
            this.error = extractErrorMessage(e);
        } finally {
            this.savingEmailSettings.remove(companyId);
        }
    }

    @action
    async saveEnrolmentSetting(companyId, value) {
        if (this.savingEnrolmentSetting.includes(companyId)) return false;
        this.savingEnrolmentSetting.push(companyId);
        try {
            let body = { 'ui.sendEnrolment': value };
            let result = await services.Companies.update({
                id: companyId,
                ...body,
            });
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company) {
                Object.assign(company, result);
            }
            if (this.mainStore.currentCompany) {
                this.mainStore.currentCompany.settings['ui.sendEnrolment'] =
                    value;
            }
        } catch (e) {
            console.error('Save failed: ', e);
            this.error = extractErrorMessage(e);
        } finally {
            this.savingEnrolmentSetting.remove(companyId);
        }
    }

    @action
    async uploadLogo(companyId, formData) {
        this.uploadingLogo = true;
        try {
            let result = await services.Resources.addLogo(companyId, formData);
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company) {
                Object.assign(company, result, { logo_name: result.logo_name });
            }
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.uploadingLogo = false;
        }
    }

    @action
    async uploadLogo2(company, formData) {
        this.uploadingLogo = true;
        try {
            let result = await services.Resources.addLogo(company.id, formData);
            //let company = this.companies.find(x => x.company_id === companyId);
            if (company) {
                Object.assign(company, result, { logo_name: result.logo_name });
            }
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.uploadingLogo = false;
        }
    }

    @action
    async removeLogo(companyId) {
        try {
            let result = await services.Resources.removeLogo(companyId);
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company) {
                Object.assign(company, result, { logo_name: null });
            }
            //await services.Resources.removeLogo(companyId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    @action
    async addManager(companyId, data) {
        this.addingManager = true;
        this.addManagerError = null;
        try {
            let result = await services.Companies.managersService(
                companyId
            ).create(data);
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company) {
                if (!company.managers) company.managers = [];

                company.managers.push(result);
            }
            await this.loadCompanies(true);
            return true;
        } catch (e) {
            this.addManagerError = extractErrorMessage(e);
        } finally {
            this.addingManager = false;
        }
    }

    @action
    async removeManager(companyId, email) {
        this.removingManagers.push(email);
        this.error = null;
        try {
            await services.Companies.managersService(companyId).delete(email);
            let company = this.companies.find(
                (x) => x.company_id === companyId
            );
            if (company) {
                if (company.managers) {
                    let m = company.managers.find((x) => x.email === email);
                    if (m) company.managers.remove(m);
                }
            }
            return this.loadCompanies(true);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.removingManagers.remove(email);
        }
    }

    @action setCompany = (company) => {
        this.company = company;
    };

    @action
    async startPullingIntegrationStatus(
        companyId,
        integration,
        latestRunningSyncId
    ) {
        if (this.pullingIntegrationTimer) {
            try {
                clearTimeout(this.pullingIntegrationTimer);
            } catch (e) {
                // Intentionally suppressed
            } finally {
                this.pullingIntegrationTimer = null;
            }
        }
        this.pullingIntegrationTimer = setTimeout(async () => {
            let result;
            try {
                result = await services.Companies.integrationsService(
                    companyId
                ).pullStatus(integration, latestRunningSyncId);
            } catch (e) {
                if (e.response && e.response.status === 404) {
                    result = { status: 2 };
                }
            }
            if (result && result.status > 1) {
                let existing = this[`${integration}DataIndex`][companyId];
                if (existing) {
                    existing.latestRunningSyncId = null;
                }
            } else
                this.startPullingIntegrationStatus(
                    companyId,
                    integration,
                    latestRunningSyncId
                );
        }, 5000);
    }

    @action
    async loadIntegrationData(companyId, integration) {
        let loadingKey = `loading${upper(integration)}Datas`;
        let dataKey = `${integration}Datas`;
        if (this[loadingKey].includes(companyId)) return false;
        this[loadingKey].push(companyId);
        try {
            let data = await services.Companies.integrationsService(
                companyId
            ).fetch(integration); //videoId
            if (data.latestRunningSyncId) {
                this.startPullingIntegrationStatus(
                    companyId,
                    integration,
                    data.latestRunningSyncId
                );
            }
            let t = this[dataKey].find((x) => x.id === companyId);
            if (t) this[dataKey].remove(t);
            this[dataKey].push({ id: companyId, ...data });
        } finally {
            this[loadingKey].remove(companyId);
        }
    }

    @action
    async loadIntegrationGroups(companyId, integration) {
        let key = `loading${upper(integration)}Groups`;
        let errorKey = `${integration}Error`;

        if (this[key].includes(companyId)) return false;
        this[errorKey] = '';

        this[key].push(companyId);
        try {
            let data = await services.Companies.integrationsService(
                companyId
            ).integrationGroups(integration); //videoId
            this[`${integration}Groups`].replace(data);
            return data;
        } catch (e) {
            this[errorKey] = extractErrorMessage(e);
        } finally {
            this[key].remove(companyId);
        }
    }

    @action
    async saveIntegrationData(companyId, integration, data) {
        let key = `saving${upper(integration)}Data`;
        let dataKey = `${integration}Datas`;
        if (this[key].includes(companyId)) return false;

        this[key].push(companyId);
        try {
            let response = await services.Companies.integrationsService(
                companyId
            ).update(data, integration); //videoId

            let t = this[dataKey].find((x) => x.id === companyId);
            if (t) this[dataKey].remove(t);
            this[dataKey].push({ id: companyId, ...response });
            if (response.latestRunningSyncId) {
                this.startPullingIntegrationStatus(
                    companyId,
                    integration,
                    response.latestRunningSyncId
                );
            }
        } finally {
            this[key].remove(companyId);
        }
    }

    @action
    async saveIntegrationRule(
        companyId,
        integration,
        entityType,
        externalId,
        rule
    ) {
        try {
            await services.Companies.integrationsService(
                companyId
            ).saveIntegrationRule(integration, {
                entityType,
                externalId,
                rule,
            });

            if (entityType === 1) {
                let g = this[`${integration}Groups`].find(
                    (x) => x.id === externalId
                );
                if (g) g.rule = rule;
            }
        } catch (e) {
            // Intentionally suppressed
        }
    }

    @action
    async saveIntegrationRules(companyId, integration, entityType, values) {
        try {
            await services.Companies.integrationsService(
                companyId
            ).saveIntegrationRules(integration, { entityType, values });
        } catch (e) {
            // Intentionally suppressed
        }
    }

    @action
    async testIntegration(companyId, integration, data) {
        let key = `testing${upper(integration)}Data`;

        if (this[key].includes(companyId)) return false;

        this[key].push(companyId);
        try {
            let response = await services.Companies.integrationsService(
                companyId
            ).testIntegrationData(integration, data);
            return response;
        } finally {
            this[key].remove(companyId);
        }
    }

    @action
    async syncIntegration(companyId, integration, data) {
        if (this.syncRunning) return;
        this.syncRunning = true;
        try {
            let response = await services.Companies.integrationsService(
                companyId
            ).syncIntegration(integration, data);
            if (response.lastSync || response.latestRunningSyncId) {
                let existing = this[`${integration}DataIndex`][companyId];
                if (existing) {
                    existing.lastSync = response.lastSync;
                    existing.latestRunningSyncId = response.latestRunningSyncId;
                    existing.lastSyncId = response.lastSyncId;
                    if (existing.latestRunningSyncId) {
                        this.startPullingIntegrationStatus(
                            companyId,
                            integration,
                            existing.latestRunningSyncId
                        );
                    }
                }
            }

            return response;
        } finally {
            this.syncRunning = false;
        }
    }

    @computed get azureDataIndex() {
        return keyBy(this.azureDatas, 'id');
    }

    @computed get teamsDataIndex() {
        return keyBy(this.teamsDatas, 'id');
    }

    @computed get azuressoDataIndex() {
        return keyBy(this.azuressoDatas, 'id');
    }

    @computed get googleDataIndex() {
        return keyBy(this.googleDatas, 'id');
    }

    @computed get slackDataIndex() {
        return keyBy(this.slackDatas, 'id');
    }

    @computed get connectedIntegrations() {
        if (this.integrations.length === 0) return [];
        return this.integrations.filter((integration) => {
            if (integration.id === 'slack' || integration.id === 'azure') {
                return integration.connected;
            } else {
                return integration.enabled === '1';
            }
        });
    }

    @computed get availableIntegrations() {
        if (this.integrations.length === 0) return [];
        let result = this.integrations.filter((integration) => {
            if (integration.id === 'slack' || integration.id === 'azure') {
                return !integration.connected;
            } else {
                return integration.enabled === '0';
            }
        });
        this.mainStore.havePartnerSubscription &&
            result.forEach(
                (integration) =>
                    (integration.needsUpgrade = !(
                        this.mainStore.currentCompany.subscription
                            .allowedItems ||
                        this.mainStore.currentCompany.subscription
                            .subscription_definition
                    ).integrations.includes(integration.id))
            );
        return result;
    }
}

export default CompanyStore;
