import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import ListUsers from './list_users';
import { enforceSlash } from '../../../utils/helpers';
import SectionHeader from '../../../components/SectionHeader';
import { ThemedDropdownItem } from '../../themed/ThemedComponents';
import ThemedLinkButton from '../../themed/ThemedLinkButton';
import pluralize from 'pluralize';
import EmployeesLimitReachedAction from '../employees-limit-reached-action';

@observer
class UsersListView extends React.Component {
    state = {
        dropdownOpen: false,
    };
    toggleExtraActions = () => {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    };

    render() {
        const {
            match,
            list,
            users,
            deletingItems,
            onDeleteUserFromList,
            onDelete,
            parentUrl,
            currentCompany,
        } = this.props;
        let createDisabled = !currentCompany || !currentCompany.canAddUsers;

        return (
            <Fragment>
                <Link to={`/users/`}>
                    <div className={'navigation back-button'}>
                        Back to lists
                    </div>
                </Link>
                <div className="block">
                    <SectionHeader>
                        <br />
                        <div className=" d-flex flex-row">
                            <div className={'section-header'}>
                                <div className={'left padded'}>
                                    <h3>{list.name}</h3>
                                    <p>
                                        {users ? users.length : 0}{' '}
                                        {pluralize('employee', users.length)}
                                    </p>
                                </div>
                                <div className={'btns-right-align'}>
                                    <span>
                                        <ThemedLinkButton
                                            disabled={createDisabled}
                                            to={`${enforceSlash(
                                                match.url
                                            )}add-users`}
                                            secondary
                                            airy
                                        >
                                            Add employees
                                        </ThemedLinkButton>
                                        <EmployeesLimitReachedAction
                                            disabled={createDisabled}
                                            currentCompany={currentCompany}
                                        />
                                    </span>
                                    <Dropdown
                                        isOpen={this.state.dropdownOpen}
                                        toggle={this.toggleExtraActions}
                                    >
                                        <DropdownToggle
                                            onClick={() =>
                                                this.toggleExtraActions
                                            }
                                            className="btn btn-three-dots"
                                        />
                                        <DropdownMenu>
                                            <ThemedDropdownItem>
                                                <Link
                                                    to={`${enforceSlash(
                                                        match.url
                                                    )}edit`}
                                                >
                                                    Rename List
                                                </Link>
                                            </ThemedDropdownItem>
                                            <ThemedDropdownItem
                                                onClick={onDelete}
                                            >
                                                Delete List
                                            </ThemedDropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </SectionHeader>
                    <ListUsers
                        users={users}
                        deleteUser={onDeleteUserFromList}
                        parentUrl={parentUrl}
                        canDelete={true}
                        deletingItems={deletingItems}
                        listName={list.name}
                    />
                </div>
            </Fragment>
        );
    }
}

export default withRouter(UsersListView);
