import React, { Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import { CalendarInput, TextInput } from '../design-system/inputs';
import { TextTiny } from '../design-system/texts';
import { MenuItem } from '@material-ui/core';
import { Spinner } from 'reactstrap';

function PackageForm({
    companyForm,
    isEdit,
    subscription,
    subscriptionPlans,
    loadingSubscriptionsPlans,
    partnerEstimate,
    estimationLoading,
}) {
    const inputHasError = (input) =>
        companyForm.$(input).error && companyForm.$(input).error.length > 0;

    const companyFormSeats = parseInt(companyForm.values().seats);

    const subscriptionCode = subscription
        ? subscription.subscription_code
        : companyForm.values().subscription;

    const isFree =
        subscriptionCode &&
        subscriptionPlans.filter((s) => s.code === subscriptionCode)[0].isFree;

    const seatsChanged =
        isEdit &&
        subscription &&
        companyFormSeats !== subscription.package_users;

    const moreCompaniesWithSubscription =
        partnerEstimate &&
        partnerEstimate.newPartnerSubscriptionSeats > companyFormSeats;

    const isInactive = subscription && subscription.package_valid === 0;

    const seatsIncreased =
        subscription && companyFormSeats > subscription.package_users;

    const validOptions = useMemo(
        () => [
            {
                value: '',
                name: 'Select timespan',
                disabled: true,
            },
            {
                value: '1',
                name: 'Month',
            },
            {
                value: '3',
                name: '3 months',
            },
            {
                value: '6',
                name: '6 months',
            },
            {
                value: '12',
                name: 'Year',
            },
            {
                value: '18',
                name: '18 months',
            },
            {
                value: '24',
                name: '2 years',
            },
            {
                value: '60',
                name: '5 years',
            },
            {
                value: '120',
                name: '10 years',
            },
            {
                value: '1200',
                name: 'Eternal',
            },
            {
                value: '-1',
                name: 'Custom',
            },
        ],
        []
    );
    return (
        <Fragment>
            {loadingSubscriptionsPlans ? (
                <Spinner />
            ) : (
                <Fragment>
                    <TextInput
                        error={inputHasError('subscription')}
                        helperText={
                            isEdit ? (
                                <TextTiny bold error>
                                    Changing subscription is not available at
                                    the moment. Please contact Partner Support{' '}
                                    <TextTiny underline error bold>
                                        <a href="mailto:partnersupport@awarego.com">
                                            partnersupport@awarego.com
                                        </a>
                                    </TextTiny>{' '}
                                    to change the subscription.
                                </TextTiny>
                            ) : inputHasError('subscription') ? (
                                'Please choose a tier'
                            ) : undefined
                        }
                        stretched
                        {...companyForm.$('subscription').bind()}
                        select
                        disabled={isEdit}
                    >
                        <MenuItem value={''} disabled={true}>
                            Choose tier
                        </MenuItem>
                        {subscriptionPlans.map((p) => (
                            <MenuItem key={p.code} value={p.code}>
                                {p.name}
                            </MenuItem>
                        ))}
                    </TextInput>
                </Fragment>
            )}
            {!isFree ? (
                <Fragment>
                    <TextInput
                        error={inputHasError('seats')}
                        helperText={
                            inputHasError('seats')
                                ? 'Please enter number of seats'
                                : !estimationLoading
                                ? (seatsChanged || !isEdit || isInactive) &&
                                  !isFree &&
                                  partnerEstimate &&
                                  moreCompaniesWithSubscription && (
                                      <TextTiny themed bold>
                                          The new,{' '}
                                          {!isEdit || seatsIncreased
                                              ? 'decreased'
                                              : 'increased'}{' '}
                                          price $
                                          {(
                                              partnerEstimate.newPartnerTotalForPlan /
                                              partnerEstimate.newPartnerSubscriptionSeats
                                          ).toFixed(2)}{' '}
                                          per seat will be applied to all active
                                          companies in the{' '}
                                          {(subscription &&
                                              subscription.package_name) ||
                                              subscriptionPlans.filter(
                                                  (p) =>
                                                      p.code ===
                                                      companyForm.values()
                                                          .subscription
                                              )[0].name}{' '}
                                          subscription
                                      </TextTiny>
                                  )
                                : null
                        }
                        stretched
                        {...companyForm.$('seats').bind()}
                    />
                    {(!isEdit || (isEdit && isInactive)) && (
                        <TextInput
                            error={inputHasError('valid')}
                            helperText={
                                inputHasError('valid')
                                    ? 'Please select a timespan'
                                    : undefined
                            }
                            stretched
                            select
                            {...companyForm.$('valid').bind()}
                        >
                            {validOptions.map((p) => (
                                <MenuItem
                                    key={p.value}
                                    value={p.value}
                                    disabled={p.disabled}
                                >
                                    {p.name}
                                </MenuItem>
                            ))}
                        </TextInput>
                    )}
                    {(companyForm.$('valid').value === '-1' ||
                        (isEdit && !isInactive)) && (
                        <CalendarInput
                            {...companyForm.$('validTill').bind()}
                            maxDate={'2200-01-02'}
                        />
                    )}
                </Fragment>
            ) : null}
        </Fragment>
    );
}

export default observer(PackageForm);
