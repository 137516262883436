import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import ExclamationIcon from '@material-ui/icons/ReportProblemOutlined';
import { Tooltip } from '@material-ui/core';
import LockOnCircle from '../../LockOnCircle';
import { FlexDiv, SpaceBetweenDiv } from '../../design-system/containers';
import { TextDefault, TextTiny } from '../../design-system/texts';
import { Heading3 } from '../../design-system/headings';
import AddRemoveButton from '../../AddRemoveButton';
import { formatTime } from '../../../utils/helpers';

function SubjectRow({
    subject,
    previewVideo,
    onVideoSelected,
    selection,
    isTrial,
}) {
    return (
        <FlexDiv className={classnames('subjectItem')} mb={8}>
            <div
                className="image with_play_btn"
                onClick={() => {
                    previewVideo(subject.id, subject.type);
                }}
            >
                <img alt="thumb" src={subject.thumb} />
                {(subject.disabled ||
                    (isTrial && !subject.availableForBeta)) && (
                    <LockOnCircle outline small topRight />
                )}
            </div>
            <FlexDiv
                column
                width={512}
                ml={16}
                mr={32}
                mb={8}
                mt={4}
                link
                onClick={() => {
                    previewVideo(subject.id, subject.type);
                }}
            >
                <SpaceBetweenDiv alignCenter>
                    <FlexDiv alignCenter>
                        <Heading3 mr={4}>{subject.title}</Heading3>
                        {((subject.notes && subject.notes.length > 0) ||
                            (subject.trainings &&
                                subject.trainings.length > 0)) && (
                            <Tooltip
                                title={
                                    <Fragment>
                                        {subject.notes &&
                                        subject.notes.length > 0 ? (
                                            <div>Modified</div>
                                        ) : (
                                            ''
                                        )}
                                        {subject.trainings &&
                                        subject.trainings.length > 0 ? (
                                            <div>
                                                Included in another training
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </Fragment>
                                }
                            >
                                <span>
                                    <ExclamationIcon
                                        style={{
                                            color: '#D5801C',
                                        }}
                                        fontSize="small"
                                    />
                                </span>
                            </Tooltip>
                        )}
                    </FlexDiv>
                    {subject.duration != null && subject.type !== 3 && (
                        <TextTiny bold>{formatTime(subject.duration)}</TextTiny>
                    )}
                </SpaceBetweenDiv>
                <TextDefault my={4}>{subject.description}</TextDefault>
            </FlexDiv>
            <AddRemoveButton
                selection={selection}
                itemId={subject.id}
                onClick={() => {
                    onVideoSelected(subject.id);
                }}
                disabled={
                    subject.disabled || (isTrial && !subject.availableForBeta)
                }
            />
        </FlexDiv>
    );
}

export default observer(SubjectRow);
