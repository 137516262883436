import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import Subjects from './Subjects';
import Templates from './Templates';
import Questions from './Questions';
import Categories from './Categories';
import { enforceSlash } from '../../utils/helpers';
import Subject from './Subject';
import Template from './Template';
import Question from './Question';
import Category from './Category';
import {
    ThemedNav,
    ThemedNavItem,
} from '../../components/themed/ThemedComponents';

@inject('superStore')
@observer
class MailHooksContainer extends React.Component {
    render() {
        const { match } = this.props;
        console.log(match.url);
        return (
            <Fragment>
                <ThemedNav dashboard>
                    <ThemedNavItem active="">
                        <NavLink
                            tag={Link}
                            to={`${enforceSlash(match.url)}subjects`}
                        >
                            Videos
                        </NavLink>
                    </ThemedNavItem>
                    <ThemedNavItem active="">
                        <NavLink
                            tag={Link}
                            to={`${enforceSlash(match.url)}templates`}
                        >
                            Templates
                        </NavLink>
                    </ThemedNavItem>
                    <ThemedNavItem active="">
                        <NavLink
                            tag={Link}
                            to={`${enforceSlash(match.url)}categories`}
                        >
                            Categories
                        </NavLink>
                    </ThemedNavItem>
                    <ThemedNavItem active="">
                        <NavLink
                            tag={Link}
                            to={`${enforceSlash(match.url)}questions`}
                        >
                            Questions
                        </NavLink>
                    </ThemedNavItem>
                </ThemedNav>

                {/*<Nav className="admin_sub_submenu">
                <NavItem><NavLink tag={Link} to={`${enforceSlash(match.url)}subjects`}>Videos</NavLink></NavItem>
                <NavItem><NavLink tag={Link} to={`${enforceSlash(match.url)}templates`}>Templates</NavLink></NavItem>
                <NavItem><NavLink tag={Link} to={`${enforceSlash(match.url)}categories`}>Categories</NavLink></NavItem>
                <NavItem><NavLink tag={Link} to={`${enforceSlash(match.url)}questions`}>Questions</NavLink></NavItem>
    </Nav>*/}

                <Switch>
                    <Route
                        path={`${enforceSlash(match.url)}subjects/:id/:lang?`}
                        exact
                        render={(props) => (
                            <Subject
                                parentMatch={match}
                                subjectId={props.match.params.id}
                                language={props.match.params.lang}
                            />
                        )}
                    />

                    <Route
                        path={`${enforceSlash(match.url)}templates/:id`}
                        render={(props) => (
                            <Fragment>
                                <Template
                                    parentMatch={match}
                                    templateId={props.match.params.id}
                                />
                            </Fragment>
                        )}
                    />

                    <Route
                        path={`${enforceSlash(match.url)}questions/create`}
                        render={(props) => (
                            <Fragment>
                                <Question parentMatch={match} />
                            </Fragment>
                        )}
                    />

                    <Route
                        path={`${enforceSlash(match.url)}questions/:id/edit`}
                        render={(props) => (
                            <Fragment>
                                <Question
                                    parentMatch={match}
                                    questionId={props.match.params.id}
                                />
                            </Fragment>
                        )}
                    />

                    <Route
                        path={`${enforceSlash(match.url)}categories/:id`}
                        render={(props) => (
                            <Fragment>
                                <Category
                                    parentMatch={match}
                                    categoryId={props.match.params.id}
                                />
                            </Fragment>
                        )}
                    />

                    <Route
                        path={`${enforceSlash(match.url)}subjects`}
                        exact
                        render={(props) => <Subjects parentMatch={match} />}
                    />

                    <Route
                        path={`${enforceSlash(match.url)}templates`}
                        exact
                        render={(props) => <Templates parentMatch={match} />}
                    />

                    <Route
                        path={`${enforceSlash(match.url)}questions`}
                        exact
                        render={(props) => <Questions parentMatch={match} />}
                    />

                    <Route
                        path={`${enforceSlash(match.url)}categories`}
                        exact
                        render={(props) => <Categories parentMatch={match} />}
                    />
                </Switch>
            </Fragment>
        );
    }
}

export default withRouter(MailHooksContainer);
