import styled, { css } from 'styled-components';
import { lighten, darken, rgba } from 'polished';
import { Card } from 'reactstrap';
import { Slider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { colors } from '../design-system/colors';

export const ThemedText = styled.span`
    color: ${(props) => props.color || props.theme.primary};
    ${(props) =>
        props.secondary &&
        css`
            color: ${props.theme.secondary};
        `}

    ${(props) =>
        props.faded &&
        css`
            color: ${(props) => rgba(props.color || props.theme.primary, 0.8)};
        `}

    ${(props) =>
        props.disabled &&
        css`
            color: ${(props) => rgba(props.color || props.theme.primary, 0.2)};
        `}

    ${(props) =>
        props.bold &&
        css`
            font-weight: 700;
        `}
`;

export const ThemedSelectionText = styled.span`
    font-weight: 600;
    position: relative;
    ${(props) =>
        props.selected &&
        css`
            color: ${(props) => props.color || props.theme.primary};
        `}
`;

export const ThemedLink = styled.span`
    color: ${(props) => props.color || props.theme.primary};
    cursor: pointer;

    ${(props) =>
        props.bold &&
        css`
            font-weight: 700;
        `}
`;

export const ThemedLinkLink = styled(Link)`
    &&:hover {
        color: ${(props) => props.theme.primary};
    }
`;

export const ThemedDiscountBanner = styled.div`
    background-color: ${(props) => props.color || props.theme.primary};
    position: absolute;
    color: white;
    padding: 2px 10px;
    font-size: 0.8em;
    left: 66px;
    top: 0;
    width: 88px;
    border-radius: 3px;
    font-weight: 300;
    &:after {
        width: 0;
        height: 0;
        position: absolute;
        left: -6px;
        top: 0;
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
        border-right: 12px solid
            ${(props) => props.color || props.theme.primary};
        content: '';
    }
`;

export const ThemedButton = styled.button`
    border: none;
    border-radius: 0.25rem;
    padding: 0.7rem 2.4rem;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    cursor: pointer;
    &:focus {
        box-shadow: 0 0 0 3px
            ${(props) => rgba(props.color || props.theme.primary, 0.25)};
        outline: none;
    }

    ${(props) =>
        props.primary &&
        css`
            color: white;
            background-color: ${(props) => props.color || props.theme.primary};
            transition: ease 0.2s all;
            &:hover {
                background-color: ${(props) =>
                    darken(0.1, props.color || props.theme.primary)} !important;
                border-color: ${(props) =>
                    darken(
                        0.05,
                        props.color || props.theme.primary
                    )} !important;
                color: white;
            }

            &:active {
                background-color: ${(props) =>
                    darken(0.2, props.color || props.theme.primary)} !important;
                border-color: ${(props) =>
                    darken(
                        0.15,
                        props.color || props.theme.primary
                    )} !important;
            }

            &:focus {
                box-shadow: 0 0 0 0.2rem
                    ${(props) =>
                        lighten(
                            0.05,
                            props.color || props.theme.primary
                        )} !important;
                background-color: ${(props) =>
                    darken(0.1, props.color || props.theme.primary)};
            }
        `};
    ${(props) =>
        props.gray &&
        css`
            color: black;
            background-color: rgba(${colors.heavyMetal}, 0.6);
            transition: ease 0.2s all;
            &:hover {
                background-color: ${darken(
                    0.1,
                    rgba(colors.heavyMetal, 0.6)
                )} !important;
                border-color: ${darken(
                    0.05,
                    rgba(colors.heavyMetal, 0.6)
                )} !important;
                color: white;
            }

            &:active {
                background-color: ${darken(
                    0.2,
                    rgba(colors.heavyMetal, 0.6)
                )} !important;
                border-color: ${darken(
                    0.15,
                    rgba(colors.heavyMetal, 0.6)
                )} !important;
                color: white;
            }

            &:focus {
                box-shadow: 0 0 0 0.2rem
                    ${lighten(0.05, rgba(colors.heavyMetal, 0.6))} !important;
                background-color: ${darken(0.1, rgba(colors.heavyMetal, 0.6))};
                color: white;
            }
        `};

    ${(props) =>
        props.red &&
        css`
            color: white;
            background-color: ${colors.delete};
            transition: ease 0.2s all;
            &:hover {
                background-color: ${darken(0.1, colors.delete)} !important;
                border-color: ${darken(0.05, colors.delete)} !important;
                color: white;
            }

            &:active {
                background-color: ${darken(0.2, colors.delete)} !important;
                border-color: ${darken(0.15, colors.delete)} !important;
                color: white;
            }

            &:focus {
                box-shadow: 0 0 0 0.2rem ${lighten(0.05, colors.delete)} !important;
                background-color: ${darken(0.1, colors.delete)};
                color: white;
            }
        `};

    ${(props) =>
        props.black &&
        css`
            color: white;
            background-color: #1d1e1d;
            transition: ease 0.2s all;
            &:hover {
                background-color: black !important;
                border-color: ${darken(0.05, '#1D1E1D')} !important;
                color: white;
            }

            &:active {
                background-color: black !important;
                border-color: ${darken(0.15, '#1D1E1D')} !important;
                color: white;
            }

            &:focus {
                box-shadow: 0 0 0 0.2rem ${lighten(0.05, '#1D1E1D')} !important;
                background-color: black;
                color: white;
            }
        `};

    ${(props) =>
        props.secondary &&
        css`
            color: ${(props) => props.color || props.theme.primary};
            background-color: ${(props) =>
                rgba(props.color || props.theme.primary, 0.1)};
            transition: ease 0.2s all;
            &:hover {
                background-color: ${(props) =>
                    rgba(props.color || props.theme.primary, 0.2)};
            }
            &:focus {
                box-shadow: 0 0 0 0.2rem ${(props) =>
                    rgba(props.color || props.theme.primary, 0.1)}; !important;
                background-color: ${(props) =>
                    rgba(props.color || props.theme.primary, 0.2)};
            }
        `};

    ${(props) =>
        props.text &&
        css`
            background: none !important;
            border: none;
            color: ${colors.heavyMetal};
            font-weight: 100;
            -webkit-transition: ease 0.2s all;
            transition: ease 0.2s all;
            width: none;
            box-shadow: none !important;
            &:hover {
                color: ${props.color || props.theme.primary};
                background: none !important;
                border: none;
                text-decoration: underline;
            }
        `};
    ${(props) =>
        props.disabled &&
        css`
            color: white;
            opacity: 0.65;
            background-color: ${(props) => props.color || props.theme.primary};
            pointer-events: none;
        `};

    ${(props) =>
        props.tiny &&
        css`
            padding: 0.25rem 0.5rem;
            line-height: 1.5;
            border-radius: 0.2rem;
            font-weight: 700;
        `};

    ${(props) =>
        props.small &&
        css`
            padding: 0.3rem 1.3rem;
            line-height: 1.5;
            border-radius: 0.2rem;
            font-weight: 700;
        `};

    ${(props) =>
        props.noTextTransform &&
        css`
            text-transform: unset;
        `};

    ${(props) =>
        props.airy &&
        css`
            margin-left: 16px;
        `};

    ${(props) =>
        props.fullWidth &&
        css`
            width: 100%;
            margin-top: 8px;
        `};
`;

export const ThemedSubscriptionButton = styled(ThemedButton)`
    margin: 10px auto 10px auto;
    display: block;
    padding: 11px 32px;
`;

export const ThemedDownloadLinksButton = styled(ThemedButton)`
    margin-top: 20px;
    width: 100%;
    display: block;
`;

export const ThemedStep = styled.div`
    white-space: nowrap;
    line-height: 40px;
    padding-left: 48px;
    font-size: 14px;
    color: ${rgba(colors.heavyMetal, 0.2)};
    font-weight: 800;
    text-transform: uppercase;
    border-left: 4px solid transparent;
    min-width: 268px;
    padding-bottom: 8px;

    ${(props) =>
        props.active &&
        css`
            cursor: pointer;
            color: ${(props) => props.color || props.theme.primary};
            border-left: 4px solid
                ${(props) => props.color || props.theme.primary};
        `};

    ${(props) =>
        props.enabled &&
        !props.active &&
        css`
            cursor: pointer;
            color: ${colors.heavyMetal};
            border-left: 4px solid transparent;
            &:hover {
                color: ${(props) => props.color || props.theme.primary};
            }
        `};
`;

export const ThemedCard = styled(Card)`
    ${(props) =>
        props.selected &&
        css`
            color: ${(props) => props.color || props.theme.primary};
            background-color: ${(props) =>
                rgba(props.color || props.theme.primary, 0.05)};
            border-color: ${(props) => props.color || props.theme.primary};
        `}
`;

export const ThemedInputLabel = styled.span`
    ${(props) =>
        props.checked &&
        css`
            &&& {
                color: ${props.theme.primary};
                font-weight: bold;
            }
        `}
`;

export const ThemedRadiobutton = styled.span`
    position: absolute;
    top: 1px;
    left: 0px;
    height: 22px;
    width: 22px;
    border: 2px solid ${(props) => props.color || props.theme.primary};
    border-radius: 50%;
    &:hover {
        background-color: ${(props) =>
            rgba(props.color || props.theme.primary, 0.05)};
    }
    &:after {
        left: 2px;
        top: 2px;
        width: 14px;
        height: 14px;
        content: '';
        position: absolute;
        border-radius: 50%;

        ${(props) =>
            props.checked &&
            css`
                display: block;
                background-color: ${(props) =>
                    props.color || props.theme.primary};
            `}
    }
`;

export const ThemedCheckmarkInputContainer = styled.div`

    &&& {
        input[type=checkbox] {
            ~.checkmark {
                border: 1px solid ${(props) => rgba(props.theme.primary, 0.5)};
            }
        }
    
        input[type=checkbox]:checked { 
            ~ span {
                color: ${(props) => props.theme.primary};
            }
            ~.checkmark {
                background: ${(props) => props.theme.primary};
                
                &:after {
                display: block;
                border: solid white;
                border-width: 0 3px 3px 0 !important;
            }
        }
    }
`;

export const ThemedSlider = styled(Slider)`
    color: ${(props) => props.color || props.theme.primary};
`;

export const ThemedNav = styled.ul`
    display: flex;
    border-bottom: 1px solid #f5f3f3;
    align-items: center;
    padding-left: 40px;
    font-size: 16px;
    flex-wrap: wrap;
    margin-bottom: 0;
    list-style: none;

    ${(props) =>
        props.dashboard &&
        css`
            margin-left: -40px;
            margin-right: -40px;
            border-top: 1px solid #f5f3f3;
            background: #faf9f9;
        `}
    ${(props) =>
        props.training &&
        css`
            margin-left: -30px;
            margin-right: -30px;
        `}
    ${(props) =>
        props.clean &&
        css`
            border-bottom: 0;
            padding: 0;
        `}
    ${(props) =>
        props.table &&
        css`
            margin-left: 0;
            padding-left: 0;
        `}

    ${(props) =>
        props.mobileMenu &&
        css`
            flex-direction: column;
            width: 200px;
        `}
`;

export const ThemedNavItem = styled.li`
    -webkit-transition: ease 0.2s all;
    transition: ease 0.2s all;
    border-bottom: 4px solid transparent;
    margin-right: 16px;
    cursor: pointer;
    list-style: none;

    &&&:hover,
    &&&:focus {
        color: ${(props) => lighten(0.1, props.theme.secondary)};
        border-bottom: 4px solid
            ${(props) => lighten(0.4, props.theme.secondary)};
    }

    ${(props) =>
        props.active &&
        css`
            &&& {
                color: ${props.theme.secondary};
                border-bottom: 4px solid ${props.theme.secondary};

                a {
                    color: ${props.theme.secondary};
                }
                &&&:hover {
                    color: ${props.theme.secondary};
                    border-bottom: 4px solid ${(props) => props.theme.secondary};
                }
            }
        `}

    ${(props) =>
        props.mobileMenu &&
        css`
            width: 100%;
            margin: 0;
            border-bottom: 1px solid lightgray;
        `}
`;

export const ThemedNavLink = styled.a`
    display: block;
    padding: 0.5rem 1rem;
    font-size: 16px;
    color: ${colors.heavyMetal};
    font-weight: 700;

    ${(props) =>
        props.top &&
        css`
            font-weight: 700;
            padding: 22px 0px;
            font-size: 14px;
        `}

    ${(props) =>
        props.table &&
        css`
            font-size: 20px;
            font-weight: 800;
        `}

    ${(props) =>
        props.mobileMenu &&
        css`
            margin-left: 16px;
            padding: 16px;
        `}
`;

export const ThemedDropdownItem = styled.button`
    background: none;
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: normal;
    color: black;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    &:hover {
        a {
            color: ${(props) => props.theme.primary};
        }
        color: ${(props) => props.theme.primary};
    }

    selected-item {
        color: ${(props) => props.theme.primary};
    }

    ${(props) =>
        props.selected &&
        css`
            color: ${(props) => props.theme.primary};
        `}
`;
