import React, { useState, Fragment } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Close';
import VirtualListBox from '../inputs/VirtualListBox';
import VirtualAutocomplete from '../inputs/VirtualAutocomplete';
import {
    CircularProgress,
    InputAdornment,
    IconButton,
    Checkbox,
    TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ThemedButton } from '../themed/ThemedComponents';
import { Spinner } from 'reactstrap';
import { useStores } from '../../hooks/use-stores';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    option: {
        fontSize: 12,
        '& > span': {
            marginRight: 10,
            fontSize: 12,
        },
    },
    listOption: {
        fontSize: 12,
        minHeight: 48,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
        paddingTop: 6,
        boxSizing: 'border-box',
        outline: '0',
        WebkitTapHighlightColor: 'transparent',
        paddingBottom: 6,
        paddingLeft: 16,
        paddingRight: 16,
        [theme.breakpoints.up('sm')]: {
            minHeight: 'auto',
        },
        '&[aria-selected="true"]': {
            backgroundColor: theme.palette.action.selected,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:active': {
            backgroundColor: theme.palette.action.selected,
        },
        '&[aria-disabled="true"]': {
            opacity: theme.palette.action.disabledOpacity,
            pointerEvents: 'none',
        },
    },
    /* Styles applied to the group's label elements. */
    groupLabel: {
        backgroundColor: theme.palette.background.paper,
        top: -8,
    },
    /* Styles applied to the group's ul elements. */
    groupUl: {
        padding: 0,
        '& $option': {
            paddingLeft: 24,
        },
    },

    input: {
        '& input': {
            fontSize: 12,
        },
        '& .Mui-focused  fieldset': {
            borderColor: 'rgba(52, 53, 52, 0.2) !important',
        },
    },
    list: {
        minWidth: 200,
    },
}));

function IntegrationDetailGroupSettings({
    data,
    loadingGroups,
    companyId,
    product,
    savingData,
    options,
    filteredIncludedOptions,
    excludedOptions,
    included,
    setIncluded,
    excluded,
    setExcluded,
    filter,
    setFilter,
}) {
    const classes = useStyles();
    const { companyStore } = useStores();
    const [openExcluded, setExcludedOpen] = useState(false);
    const loading = options && openExcluded && options.length === 0;
    const [includedRulesChanged, setIncludedRulesChanged] = useState(false);
    const [excludedRulesChanged, setExcludedRulesChanged] = useState(false);
    const [saveMark, setSaveMark] = useState(false);

    const onGroupBehaviourChange = async (event) => {
        const target = event.target;
        setSaveMark(false);
        await companyStore.saveIntegrationData(companyId, product, {
            groupBehaviour: target.value,
        });
        setSaveMark(true);
    };

    const handleSelectIncludedGroup = (e, value) => {
        if (data) {
            setIncluded(value);
            setIncludedRulesChanged(true);
        }
    };

    const handleCheckBox = (e, value) => {
        e.stopPropagation();
        if (included.includes(value))
            handleSelectIncludedGroup(
                e,
                included.filter((x) => x !== value)
            );
        else handleSelectIncludedGroup(e, [...included, value]);
    };

    const handleSelectExcludedGroup = (e, value) => {
        if (data) {
            setExcluded(value);
            setExcludedRulesChanged(true);
        }
    };

    const saveRules = async () => {
        await companyStore.saveIntegrationRules(companyId, product, 1, {
            included,
            excluded,
        });
    };

    return (
        <div className="integration-detail-block align-left">
            <h1>Groups</h1>
            <h3>Default groups behaviour</h3>
            <div className="button-with-description">
                <select
                    className="bigInput"
                    onChange={(e) => onGroupBehaviourChange(e)}
                    value={(data && data.groupBehaviour) || 'exclude'}
                >
                    <option className="tl" value={`exclude`}>
                        Exclude
                    </option>
                    <option className="tl" value={`include`}>
                        Include
                    </option>
                </select>
                {saveMark && (
                    <CheckCircleIcon
                        color="primary"
                        fontSize="small"
                        style={{ marginLeft: '20px' }}
                    />
                )}
            </div>
            {loadingGroups.includes(companyId) && (
                <h3>
                    <Spinner /> Loading groups...
                </h3>
            )}
            {!loadingGroups.includes(companyId) && (
                <Fragment>
                    <h3>Included groups</h3>
                    <TextField
                        className={'form-control'}
                        value={filter}
                        onChange={(e) => {
                            setFilter(e.target.value);
                        }}
                        placeholder={'Type to search...'}
                        InputProps={{
                            endAdornment: filter && (
                                <InputAdornment position="end">
                                    <IconButton onClick={(e) => setFilter('')}>
                                        <CancelIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <VirtualListBox className={classes.list}>
                        {filteredIncludedOptions.map((option) => {
                            return (
                                <li
                                    className={classes.listOption}
                                    onClick={(e) => {
                                        handleCheckBox(e, option);
                                    }}
                                    key={option.id}
                                >
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        checked={included.includes(option)}
                                        onChange={(e) => {
                                            handleCheckBox(e, option);
                                        }}
                                        style={{ marginRight: 8 }}
                                    />
                                    {option.displayName}
                                </li>
                            );
                        })}
                    </VirtualListBox>
                    <h3>Excluded groups</h3>
                    <div className="d-flex justify-content-end">
                        <VirtualAutocomplete
                            disableCloseOnSelect
                            onChange={handleSelectExcludedGroup}
                            value={excluded}
                            open={openExcluded}
                            multiple
                            onOpen={() => {
                                setExcludedOpen(true);
                            }}
                            onClose={() => {
                                setExcludedOpen(false);
                            }}
                            getOptionSelected={(option, value) => {
                                return (
                                    option && value && option.id === value.id
                                );
                            }}
                            getOptionLabel={(option) => {
                                return `${option.displayName}`;
                            }}
                            disableListWrap
                            options={excludedOptions}
                            loading={loading}
                            size="small"
                            classes={{
                                option: classes.option,
                            }}
                            style={{ width: '100%' }}
                            renderOption={(option, { selected }) => (
                                <Fragment>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.displayName}
                                </Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <Fragment>
                                                {loading ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={20}
                                                    />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </Fragment>
                                        ),
                                    }}
                                    className={classes.input}
                                    {...params}
                                    placeholder="Search users"
                                    variant="outlined"
                                />
                            )}
                        />
                    </div>
                    {savingData.includes(companyId) ? (
                        <Spinner />
                    ) : (
                        <ThemedButton
                            disabled={
                                !excludedRulesChanged && !includedRulesChanged
                            }
                            secondary
                            onClick={saveRules}
                            airy
                            style={{ alignSelf: 'center' }}
                        >
                            Save group changes
                        </ThemedButton>
                    )}
                </Fragment>
            )}
        </div>
    );
}

export default IntegrationDetailGroupSettings;
