import React from 'react';
import { observer } from 'mobx-react';
import { Table, Tr, Td, Th, Thead } from 'reactable';
import './summary.scss';

function Summary({ summaryOutput }) {
    return (
        <Table className="table" id="table">
            <Thead>
                <Th column="Name"> </Th>
                <Th column="Detail"> </Th>
            </Thead>
            {summaryOutput.map((x, i) => (
                <Tr key={i}>
                    <Td column="Name">{x.name}</Td>
                    <Td column="Detail">{x.detail}</Td>
                </Tr>
            ))}
        </Table>
    );
}

export default observer(Summary);
