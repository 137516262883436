import { action, computed, observable } from 'mobx';
import sumBy from 'lodash/sumBy';
import flatten from 'lodash/flatten';
import { TRANSPORTS, STATUS } from '../../utils/constants';
import { defaultZero } from '../../utils/helpers';

const statuses = {
    [STATUS.DRAFT]: {
        label: 'Draft',
        icon: false,
        value: STATUS.DRAFT,
        color: 'dark',
    },
    [STATUS.SCHEDULED]: {
        label: 'Scheduled',
        icon: false,
        value: STATUS.SCHEDULED,
        color: 'primary',
    },
    [STATUS.ONGOING]: {
        label: 'Active',
        icon: false,
        value: STATUS.ONGOING,
        color: 'warning',
    },
    [STATUS.COMPLETED]: {
        label: '✓ Completed',
        icon: true,
        value: STATUS.COMPLETED,
        color: 'success',
    },
};

class Automation {
    // @observable selectedQuestionsIds = observable([]);
    @observable selectedVideosIds = observable([]);
    @observable selectedUserListsIds = observable([]);
    @observable weekday = '';
    @observable timeOfDay = '';
    @observable interval = 'W';
    @observable subjectsPerInterval = 3;
    @observable name = 'Untitled Training';
    @observable scheduleType = 0;
    @observable channel;
    @observable reminders_enabled = false;
    @observable reminders_channel;
    @observable reminders_steps = [
        { delay: 1, delayType: 3, timeType: 1, sendTime: 9 },
    ];
    @observable active = false;
    @observable status;
    @observable showRightAnswers = false;
    @observable templateCustomized = false;
    @observable id = null;
    @observable templateTip = '';
    @observable introMessage = '';
    @observable introSubject = 'Security Awareness Training';
    @observable introMessageEnabled = false;

    constructor(
        listStore,
        catalogStore,
        questionsIndex,
        automationData,
        isTrial,
        havePartnerSubscription,
        companyId
    ) {
        this.listStore = listStore;
        this.catalogStore = catalogStore;
        this.companyId = companyId;
        this.questionsIndex = questionsIndex;
        this.havePartnerSubscription = havePartnerSubscription;
        this.isTrial = isTrial;
        this.channel =
            window.localStorage.getItem(`automationChannel-${companyId}`) ||
            TRANSPORTS.EMAIL;
        this.reminders_channel =
            window.localStorage.getItem(
                `automationRemindersChannel-${companyId}`
            ) || TRANSPORTS.EMAIL;
        if (automationData) {
            this._rawData = automationData.id ? automationData : null;
            this.name = automationData.name;
            this.id = automationData.id;
            if (automationData.frequency) {
                this.setFrequency(automationData.frequency);
            }
            if (automationData.active !== undefined)
                this.active = automationData.active === 1;
            if (automationData.weekday) this.weekday = automationData.weekday;
            if (automationData.timeOfDay)
                this.timeOfDay = automationData.timeOfDay;
            else {
                let hour = new Date().getHours() + 1;
                this.timeOfDay = hour < 23 ? hour : 0;
            }
            if (automationData.reminders_enabled)
                this.reminders_enabled = automationData.reminders_enabled === 1;
            if (automationData.reminders_steps)
                automationData.reminders_steps.length > 0
                    ? this.reminders_steps.replace(
                          automationData.reminders_steps.map((x) => ({
                              ...x,
                              sendTime: Number(x.sendTime.split(':')[0]),
                          }))
                      )
                    : this.reminders_steps.replace([
                          { delay: 1, delayType: 3, timeType: 1, sendTime: 9 },
                      ]);
            if (automationData.reminders_settings)
                this.reminders_channel =
                    automationData.reminders_settings.channel;
            if (automationData.sendOptions)
                this.channel = automationData.sendOptions.channel;
            else if (
                automationData.reminders_settings &&
                automationData.reminders_settings.deliveryChannel
            )
                this.channel =
                    automationData.reminders_settings.deliveryChannel;
            if (
                automationData.definition &&
                automationData.definition.introMessageEnabled
            ) {
                this.introMessageEnabled =
                    automationData.definition.introMessageEnabled;
            }
            if (
                automationData.definition &&
                automationData.definition.introductoryData
            ) {
                this.introMessage =
                    automationData.definition.introductoryData.message;
                this.introSubject =
                    automationData.definition.introductoryData.subject;
            }
            if (automationData.events)
                this.selectedVideosIds.replace(
                    flatten(
                        automationData.events.map((x) =>
                            x.subjects.map((s) => s.subject_id)
                        )
                    )
                );
            if (automationData.lists)
                this.selectedUserListsIds.replace(
                    automationData.lists.map((x) => x.list_user_id)
                );
            this.scheduleType = automationData.scheduleType;
            this.status = automationData.status;
            this.subjectsNames = automationData.subjectsNames;
        } else {
            let hour = new Date().getHours() + 1;
            this.timeOfDay = hour < 23 ? hour : 0;
            let currentDayOfWeek = new Date().getDay();
            this.weekday = currentDayOfWeek;
        }
    }

    @action setName = (value) => {
        this.name = value;
    };

    @action setId = (value) => {
        this.id = value;
    };

    @action toggleIntroMessage = () => {
        this.introMessageEnabled = !this.introMessageEnabled;
    };

    @action toggleShowRightAnswers = () => {
        this.showRightAnswers = !this.showRightAnswers;
    };

    @action setScheduleType = (type) => {
        this.scheduleType = type;
    };

    @action setRemindersEnabled = () => {
        this.reminders_enabled = !this.reminders_enabled;
    };

    @action setChannel = (value, checked) => {
        let newChannel = this.channel;
        if (checked) {
            // add checked value to channel, this is called bitwise OR
            newChannel |= value;
        } else {
            // remove unchecked value from channel, this is called bitwise NOT
            newChannel &= ~value;
        }
        if (newChannel !== 0) {
            this.channel = newChannel;
            window.localStorage.setItem(
                `automationChannel-${this.companyId}`,
                this.channel
            );
            return true;
        } else {
            return false;
        }
    };

    @action setRemindersChannel = (value, checked) => {
        let newChannel = this.reminders_channel;
        if (checked) {
            // add checked value to channel, this is called bitwise OR
            newChannel |= value;
        } else {
            // remove unchecked value from channel, this is called bitwise NOT
            newChannel &= ~value;
        }
        if (newChannel !== 0) {
            this.reminders_channel = newChannel;
            window.localStorage.setItem(
                `automationRemindersChannel-${this.companyId}`,
                this.reminders_channel
            );
            return true;
        } else {
            return false;
        }
    };

    @action addReminderStep = () => {
        this.reminders_steps.push({
            delay: Math.ceil(0.4 * Math.exp(this.reminders_steps.length + 1)),
            delayType: 3,
            timeType: 1,
            sendTime: 9,
        });
    };

    @action deleteReminderStep = (value) => {
        this.reminders_steps.remove(value);
    };

    @action setWeekday = (weekday) => {
        this.weekday = weekday;
    };

    @action setTimeOfDay = (timeOfDay) => {
        this.timeOfDay = timeOfDay;
    };

    @action setFrequency = (frequency) => {
        this.interval = frequency && frequency.substr(0, 1);
        this.subjectsPerInterval = frequency && frequency.substr(1, 2);
    };

    @action setInterval = (interval) => {
        this.interval = interval;
    };

    @action setSubjectsPerInterval = (subjectsPerInterval) => {
        this.subjectsPerInterval = subjectsPerInterval;
    };

    @action toggleVideoSelected(videoId) {
        if (this.selectedVideosIds.includes(videoId))
            this.selectedVideosIds.remove(videoId);
        else this.selectedVideosIds.push(videoId);
        this.templateCustomized = true;
    }

    @action toggleUserListSelected(listId) {
        if (this.selectedUserListsIds.includes(listId))
            this.selectedUserListsIds.remove(listId);
        else this.selectedUserListsIds.push(listId);
    }

    @action
    reorderSubjectList(startIndex, endIndex) {
        if (endIndex < 0) {
            endIndex = 0;
        }
        const [removed] = this.selectedVideosIds.splice(startIndex, 1);
        this.selectedVideosIds.splice(endIndex, 0, removed);
    }

    // TODO: Move to API loading  company info, should be available from anyway via companyStore.currentCompany
    @computed get isUserCountOk() {
        let usersAvailable = 0;
        let usersRegistered = 0;

        if (this.company) {
            if (this.company.users_available) {
                usersAvailable = this.company.users_available;
            }
            usersRegistered = this.getCurrentCompanyUserCount;
        }
        return usersAvailable >= usersRegistered;
    }

    @computed get statusInfo() {
        return statuses[this.status] || {};
    }

    @computed get unavailableSubjectsSelected() {
        if (
            this.catalogStore.loadingCatalog ||
            this.selectedVideosIds.length === 0
        ) {
            return [];
        } else {
            return Object.keys(this.catalogStore.allSubjectsIndex)
                .filter((v) => this.selectedVideosIds.includes(parseInt(v)))
                .filter((k) => {
                    return (
                        this.catalogStore.allSubjectsIndex[k].disabled ||
                        (this.isTrial &&
                            !this.catalogStore.allSubjectsIndex[k]
                                .availableForBeta)
                    );
                });
        }
    }

    @computed get hasUnavailableSubjects() {
        return (
            this.unavailableSubjectsSelected &&
            this.unavailableSubjectsSelected.length > 0
        );
    }

    @computed get link() {
        return `/trainings/${this.id}/view`;
    }

    @computed get attendance() {
        return defaultZero(
            Math.round(
                (100 * (this._rawData.opened || 0)) / this._rawData.totalUsers
            )
        );
    }

    @computed get awarenessScore() {
        return defaultZero(
            Math.round(
                (100 * (this._rawData.score || 0)) / this._rawData.totalForView
            )
        );
    }

    @computed get videosSelected() {
        if (this.catalogStore.loadingCatalog) return [];
        return this.selectedVideosIds
            .map((x) => this.catalogStore.allSubjectsIndex[x])
            .filter((x) => x != null);
    }

    @computed get videosSelectedDuration() {
        return this.videosSelected.reduce((a, x) => {
            return a + x.duration || 0;
        }, 0);
    }

    @computed get userListsSuffix() {
        if (this.catalogStore.loadingCatalog) return '';
        return this.selectedUserListsIds.length === 1
            ? ` - ${this.userListsSelected[0].name}`
            : '';
    }

    @computed get userListsSelected() {
        if (this.catalogStore.loadingCatalog) return [];
        return this.selectedUserListsIds.map(
            (x) => this.listStore.allListsIndex[x]
        );
    }

    @computed get selectedUserCount() {
        return sumBy(
            this.selectedUserListsIds.map(
                (x) => this.listStore.allListsIndex[x]
            ),
            'usersCount'
        );
    }

    @computed get canDelete() {
        return true;
        //
        // if (! this._rawData )
        //     return false
        // if (! this.active )
        //     return false
        // if (this._rawData.events.some( x=> x.status >0 ) ) return false
        //
        // return true
    }

    @computed get canCopy() {
        if (!this._rawData) return false;
        if (this.active) return false;
        if (this._rawData.events.every((x) => x.status > 0)) return false;

        return true;
    }

    @computed get canStop() {
        if (!this._rawData) return false;
        if (!this.active) return false;
        if (this._rawData.events.every((x) => x.status === STATUS.ONGOING))
            return false;
        if (this._rawData.events.some((x) => x.status === STATUS.ONGOING))
            return true;

        return false;
    }

    @computed get canRemind() {
        if (!this._rawData) return false;
        if (!this.active) return false;

        return this._rawData.score < this._rawData.total;
    }

    @computed get introductoryData() {
        let data = {};
        if (this.introMessage) {
            data.message = this.introMessage;
        }
        if (this.introSubject) {
            data.subject = this.introSubject;
        }
        return data;
    }

    @computed get result() {
        let result = [];
        if (this._rawData && this.status > 1) {
            this._rawData.events.forEach((x) => {
                let event = {};
                event.id = x.id;
                event.date = new Date(x.send);
                event.interval = this.interval;
                event.status = x.status;
                event.subjectsPerInterval = x.subjects.length;

                event.subjects = x.subjects.map(
                    (s) => this.catalogStore.allSubjectsIndex[s.subject_id]
                );
                result.push(event);
            });
            return result;
        }

        // NOTE!
        // This code below is mirrored in the api file: \awarego-api\src\services\automation.service.js
        // in the function: prepareEvents(...)
        // If this code is changed, that code will likely need to be updated as well

        let count = this.videosSelected.length;
        let idx = 0;
        let theDate = new Date();

        let currentDayOfWeek = theDate.getDay();
        if (this.weekday && this.weekday !== currentDayOfWeek) {
            let diff = 0;
            if (currentDayOfWeek <= this.weekday) {
                diff = this.weekday - currentDayOfWeek;
            } else {
                diff = this.weekday - currentDayOfWeek + 7;
            }
            theDate.setDate(theDate.getDate() + diff);
        }

        theDate.setHours(theDate.getHours() + 1);
        theDate.setMinutes(0);
        theDate.setSeconds(0);
        theDate.setMilliseconds(0);
        if (this.timeOfDay && this.timeOfDay >= 0) {
            theDate.setHours(this.timeOfDay);
            if (theDate - Date.now() < 0)
                theDate.setDate(theDate.getDate() + 7);
        }

        if (count > 0) {
            let loopCounter = 0; // added just in case if infinite loop
            while (idx < count && loopCounter < 200) {
                // max 200 loops (just in case)
                let event = {};
                event.date = new Date(theDate.getTime());
                event.interval = this.interval;
                event.status = 0;
                event.subjectsPerInterval = this.subjectsPerInterval;
                event.subjects = [];
                for (let i = 0; i < this.subjectsPerInterval; i++) {
                    if (this.videosSelected[idx]) {
                        event.subjects.push(this.videosSelected[idx]);
                    }
                    idx++;
                }

                result.push(event);
                if (this.interval === 'M') {
                    theDate.setMonth(theDate.getMonth() + 1);
                    currentDayOfWeek = theDate.getDay();
                    if (this.weekday && this.weekday !== currentDayOfWeek) {
                        let diff = 0;
                        if (currentDayOfWeek <= this.weekday) {
                            diff = this.weekday - currentDayOfWeek;
                        } else {
                            diff = this.weekday - currentDayOfWeek + 7;
                        }
                        theDate.setDate(theDate.getDate() + diff);
                    }
                } else if (this.interval === 'B') {
                    theDate.setDate(theDate.getDate() + 14);
                } else {
                    theDate.setDate(theDate.getDate() + 7);
                }

                loopCounter++;
            }
        }
        return result;
    }

    get dto() {
        return this.toDTO();
    }

    toDTO(extraData) {
        return {
            showRightAnswers: this.showRightAnswers,
            // questions: this.selectedQuestionsIds,
            subjects: this.selectedVideosIds,
            name: this.name,
            id: this.id,
            introMessageEnabled: this.introMessageEnabled,
            userLists: this.selectedUserListsIds,
            sendOptions: { channel: this.channel },
            reminders_enabled: this.reminders_enabled,
            reminders_settings: {
                channel: this.reminders_channel,
                steps: this.reminders_steps,
            },
            scheduleType: this.scheduleType,
            weekday: this.weekday,
            frequency: `${this.interval}${this.subjectsPerInterval}`,
            timeOfDay: this.timeOfDay,
            templateCustomized: this.templateCustomized,
            status: this.status,
            ...extraData,
        };
    }
}

export default Automation;
