import React from 'react';
import { ThemedText } from '../../themed/ThemedComponents';
import sanitizeHtml from 'sanitize-html';

class NewsFeed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blogList: null,
            fetchingData: true,
        };
    }

    async componentDidMount() {
        let contentObject = await this.getBlogContent();
        if (contentObject) {
            this.setState({ blogList: contentObject, fetchingData: false });
        }
    }

    async getBlogContent() {
        const url =
            'https://www.awarego.com/wp-json/wp/v2/posts/?per_page=3&_embed';
        let res = await fetch(url);
        return await res.json();
    }

    render() {
        if (this.state.blogList) {
            return (
                <div className="block">
                    <h3>From our blog</h3>
                    {this.state.blogList.map((item, key) => (
                        <div className="item tutorial" key={key}>
                            {key === 0 &&
                                item._embedded &&
                                item._embedded['wp:featuredmedia'] &&
                                item._embedded['wp:featuredmedia']['0'] &&
                                item._embedded['wp:featuredmedia']['0']
                                    .media_details &&
                                item._embedded['wp:featuredmedia']['0']
                                    .media_details.sizes &&
                                item._embedded['wp:featuredmedia']['0']
                                    .media_details.sizes.medium &&
                                item._embedded['wp:featuredmedia']['0']
                                    .media_details.sizes.medium.source_url && (
                                    <a
                                        href={sanitizeHtml(item.link)}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <div className="image">
                                            <img
                                                src={sanitizeHtml(
                                                    item._embedded[
                                                        'wp:featuredmedia'
                                                    ]['0'].media_details.sizes
                                                        .medium.source_url
                                                )}
                                                alt=""
                                            />
                                        </div>
                                    </a>
                                )}
                            <h5>
                                <a
                                    href={sanitizeHtml(item.link)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: sanitizeHtml(
                                                item.title.rendered
                                            ),
                                        }}
                                    ></div>
                                </a>
                            </h5>

                            <div
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(item.excerpt.rendered),
                                }}
                            ></div>
                            <div className="cta">
                                <a
                                    href={sanitizeHtml(item.link)}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <ThemedText>Read more</ThemedText>
                                </a>
                            </div>
                            <hr />
                        </div>
                    ))}
                </div>
            );
        } else {
            if (this.state.fetchingData) {
                return (
                    <div className="block">
                        <h3>From our blog</h3>
                        <p>One moment</p>
                    </div>
                );
            } else {
                return null;
            }
        }
    }
}

export default NewsFeed;
