import { action, observable } from 'mobx';
import services from '../services';

class MailStore {
    @observable mailDetails = undefined;
    @observable loading = false;
    @observable error = null;

    @action
    async loadMailLog(storeUrl) {
        const result = await services.Mails.loadMailDetails(storeUrl);
        this.mailDetails = result;
    }
}

export default MailStore;
