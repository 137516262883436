import React, { Fragment, useState } from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Button, Label, Form, FormGroup } from 'reactstrap';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import { Table, Td, Th, Thead, Tr } from 'reactable';
import PrincipalChooser from './PrincipalChooser';

export default observer(({ acl, unique_id }) => {
    const { superStore, commonStore } = useStores();

    const [target, setTarget] = useState(null);
    const [targetName, setTargetName] = useState(null);
    const [requiredError, setRequiredError] = useState(null);
    const [ruleType, setRuleType] = useState(1);

    const { subjectsError, removingAclLines, creatingAclLine } = superStore;

    const removeAclLine = (aclLine) => {
        commonStore.showConfirm(
            `Remove access for ${aclLine.companyName || aclLine.partnerName}`,
            'Delete',
            'Delete access',
            async () => {
                await superStore.removeAclLine(aclLine.id, () => {
                    acl.records.remove(aclLine);
                });
            },
            true
        );
    };
    const createAclLine = () => {
        if (!target) {
            setRequiredError('Target Is Required');
            return;
        } else setRequiredError(null);

        commonStore.showConfirm(
            `Set ${
                ruleType === 1 ? "'Allow'" : "'Deny'"
            } permission for ${targetName}`,
            'Set',
            `Set permission `,
            async () => {
                let result = await superStore.createAclLine({
                    object_unique_id: unique_id,
                    principal_unique_id: target.unique_id,
                    rule: ruleType,
                });
                if (result) {
                    acl.records.push({
                        ...result,
                        companyName: target.type === 'company' && target.name,
                        partnerName: target.type === 'partner' && target.name,
                    });
                }
            }, 
            false
        );
    };

    const removePublicAccess = () => {
        commonStore.showConfirm(
            `Remove  Public access?`,
            'Revoke',
            'Revoke public access',
            async () => {
                await superStore.removeAclLine(acl.publicRecordId, () => {
                    acl.isPublic = false;
                    acl.publicRecordId = null;
                });
            },
            true
        );
    };

    const grantPublicAccess = () => {
        commonStore.showConfirm(
            `Grant Public access?`,
            'Grant',
            'Grant public access',
            async () => {
                let result = await superStore.createAclLine({
                    object_unique_id: unique_id,
                    principal_unique_id: 'public',
                    rule: 1,
                });
                acl.isPublic = true;
                acl.publicRecordId = result.id;
            },
            false
        );
    };

    return (
        <Fragment>
            <ErrorMessage error={subjectsError} />

            <Row>
                {acl && (
                    <Col md="12" xs="12" className="leftMenu">
                        <div className="block">
                            Public access:
                            {acl.isPublic ? (
                                <Fragment>
                                    <span
                                        className={'flag static-flag '}
                                        style={{
                                            backgroundImage: `url(/static/img/icon-completed.png)`,
                                        }}
                                    >
                                        &nbsp;
                                    </span>
                                    <span
                                        className="delete-link"
                                        onClick={() => removePublicAccess()}
                                    >
                                        <Button color="danger" size="sm">
                                            revoke public access
                                        </Button>
                                    </span>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <span
                                        className={'flag static-flag '}
                                        style={{
                                            backgroundImage: `url(/static/img/icon-close.png)`,
                                        }}
                                    >
                                        &nbsp;
                                    </span>
                                    <span
                                        className="delete-link"
                                        onClick={() => grantPublicAccess()}
                                    >
                                        <Button color="primary" size="sm">
                                            grant public access
                                        </Button>
                                    </span>
                                </Fragment>
                            )}
                        </div>

                        <Fragment>
                            <h5>Add new rule</h5>
                            <Form inline className="align-items-end">
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="Target" className="mr-sm-2">
                                        Company/Partner
                                    </Label>
                                    <PrincipalChooser
                                        value={targetName}
                                        onChange={(event, { newValue }) => {
                                            setTargetName(newValue);
                                        }}
                                        onSuggestionSelected={(
                                            event,
                                            { suggestion }
                                        ) => {
                                            setTarget(suggestion);
                                        }}
                                    />

                                    <div className="error">{requiredError}</div>
                                </FormGroup>
                                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                                    <Label for="ruleType" className="mr-sm-2">
                                        Type
                                    </Label>
                                    <select
                                        name="ruleType"
                                        className="bigInput"
                                        value={ruleType}
                                        onChange={(e) =>
                                            setRuleType(e.target.value)
                                        }
                                    >
                                        <option value={1} className="tl">
                                            Allow
                                        </option>
                                        <option value={0} className="tl">
                                            Denny
                                        </option>
                                    </select>
                                </FormGroup>
                                {creatingAclLine ? (
                                    <ThemedSpinner size={45} />
                                ) : (
                                    <Button
                                        className="btn btn-primary"
                                        onClick={() => createAclLine()}
                                    >
                                        Add
                                    </Button>
                                )}
                            </Form>
                        </Fragment>

                        <Table className="table" id="table">
                            <Thead>
                                <Th column="Id" className="">
                                    Id
                                </Th>
                                <Th column="Target" className="">
                                    Target
                                </Th>
                                <Th column="Rule" className="">
                                    Access
                                </Th>
                                <Th column="Action" className="">
                                    {' '}
                                </Th>
                            </Thead>
                            {acl.records.map((line, i) => (
                                <Tr key={`${i}-${line.id}`}>
                                    <Td
                                        column={'Id'}
                                        value={line.subject_id}
                                        className=""
                                    >
                                        {line.id}
                                    </Td>
                                    <Td
                                        column={'Target'}
                                        value={line.principal_unique_id}
                                    >
                                        <Fragment>
                                            <span className="acl-target-prefix">
                                                {line.companyName &&
                                                    'company: '}
                                                {line.partnerName &&
                                                    'partner: '}
                                            </span>
                                            [{line.companyId || line.partnerId}]{' '}
                                            {line.companyName ||
                                                line.partnerName}
                                        </Fragment>
                                    </Td>
                                    <Td column={'Rule'} value={line.rule}>
                                        <Fragment>
                                            {line.rule === 1 ? (
                                                <span
                                                    className={
                                                        'flag static-flag '
                                                    }
                                                    style={{
                                                        backgroundImage: `url(/static/img/icon-completed.png)`,
                                                    }}
                                                >
                                                    &nbsp;
                                                </span>
                                            ) : (
                                                <span
                                                    className={
                                                        'flag static-flag '
                                                    }
                                                    style={{
                                                        backgroundImage: `url(/static/img/icon-close.png)`,
                                                    }}
                                                >
                                                    &nbsp;
                                                </span>
                                            )}
                                        </Fragment>
                                    </Td>
                                    <Td column={'Action'}>
                                        <Fragment>
                                            {removingAclLines.includes(
                                                line.id
                                            ) ? (
                                                <ThemedSpinner size={45} />
                                            ) : (
                                                <span
                                                    className="delete-link"
                                                    onClick={() =>
                                                        removeAclLine(line)
                                                    }
                                                >
                                                    <Button
                                                        color="danger"
                                                        size="sm"
                                                    >
                                                        x
                                                    </Button>
                                                </span>
                                            )}
                                        </Fragment>
                                    </Td>
                                </Tr>
                            ))}
                        </Table>
                    </Col>
                )}
            </Row>
        </Fragment>
    );
});
