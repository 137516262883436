import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import {
    ThemedText,
    ThemedButton,
} from '../../components/themed/ThemedComponents';
import ErrorMessage from '../../components/errormessage';
import { Row, Col } from 'reactstrap';
import { useStores } from '../../hooks/use-stores';

import { FormControlLabel, Switch, Grid, Typography } from '@material-ui/core';
import IntegrationDetailSidebar from '../../components/company/integration-detail-sidebar';
import IntegrationDetailGroupSettings from '../../components/company/integration-detail-group-settings';
import IntegrationDetailPreviewModal from '../../components/company/integration-detail-preview-modal';

export default observer(({ companyId, parentUrl }) => {
    const { companyStore, commonStore, authStore, brandingStore } = useStores();
    const [previewData, setPreviewData] = useState(null);

    const [options, setOptions] = useState([]);
    const [includedOptions, setIncludedOptions] = useState([]);
    const [filteredIncludedOptions, setFilteredIncludedOptions] = useState([]);
    const [excludedOptions, setExcludedOptions] = useState([]);
    const [filter, setFilter] = useState('');
    const [included, setIncluded] = useState([]);
    const [excluded, setExcluded] = useState([]);

    const doLoadData = async () => {
        await companyStore.loadIntegrationData(companyId, 'google');
        const response = await companyStore.loadIntegrationGroups(
            companyId,
            'google'
        );
        setOptions(response);

        if (response && response.length > 0) {
            let clone1 = response.map((x) => {
                return { id: x.id, displayName: x.displayName, rule: x.rule };
            });

            setExcludedOptions(clone1);
            setExcluded(clone1.filter((x) => x.rule === 2));

            let clone2 = response.map((x) => {
                return { id: x.id, displayName: x.displayName, rule: x.rule };
            });
            setIncludedOptions(clone2);
            setIncluded(clone2.filter((x) => x.rule === 1));
        }
    };
    useEffect(() => {
        setFilteredIncludedOptions(
            includedOptions.filter((option) => {
                return (
                    (option.displayName || '').toLowerCase().indexOf(filter) >
                    -1
                );
            })
        );
    }, [filter, includedOptions]);

    useEffect(() => {
        doLoadData();
    }, [companyId]);

    const onToggleEnabled = async () => {
        if (companyStore.googleDataIndex[companyId].enabled === '1') {
            await companyStore.saveIntegrationData(companyId, 'google', {
                enabled: '0',
            });
        } else {
            await companyStore.saveIntegrationData(companyId, 'google', {
                enabled: '1',
            });
        }
    };

    const onToggleTestMode = async () => {
        if (companyStore.googleDataIndex[companyId].testMode === '1') {
            await companyStore.saveIntegrationData(companyId, 'google', {
                testMode: '0',
            });
        } else {
            await companyStore.saveIntegrationData(companyId, 'google', {
                testMode: '1',
            });
        }
    };

    const testIntegration = async () => {
        try {
            let result = await companyStore.testIntegration(
                companyId,
                'google'
            );
            if (result.success)
                commonStore.showMessage('Connection Successful');
            else
                commonStore.showMessage(
                    'Connection failed: ' + result.error,
                    'error'
                );
        } catch (e) {
            commonStore.showMessage('Connection failed: ' + e.message, 'error');
        }
    };

    const syncNow = async () => {
        try {
            let result = await companyStore.syncIntegration(
                companyId,
                'google'
            );
            if (result && !result.error)
                commonStore.showMessage('Sync Successful');
            else
                commonStore.showMessage(
                    'Sync failed: ' + result.error,
                    'error'
                );
        } catch (e) {
            commonStore.showMessage('Sync failed: ' + e.message, 'error');
        }
    };

    const previewNow = async () => {
        try {
            let result = await companyStore.syncIntegration(
                companyId,
                'google',
                { preview: true }
            );
            if (result && result.preview) setPreviewData(result.preview);
        } catch (e) {
            commonStore.showMessage('Sync failed: ' + e.message, 'error');
        }
    };

    const closePreviewModal = () => {
        setPreviewData(null);
    };

    const {
        savingGoogleData,
        testingGoogleData,
        loadingGoogleDatas,
        loadingGoogleGroups,
        googleError,
        syncRunning,
    } = companyStore;
    const { account_name, brandingLoaded } = brandingStore;
    let data = companyStore.googleDataIndex[companyId];

    let csvUrl;
    if (data && data.lastSyncId) {
        const { token } = authStore;
        csvUrl = `${process.env.REACT_APP_API_URL}/companies/${companyId}/google/logs/${data.lastSyncId}?token=${token}`;
    }

    const testMode = (
        <div className="button-with-description">
            <Typography component="div">
                <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item>Live Mode</Grid>
                    <Grid item>
                        <Switch
                            checked={Number(data && data.testMode) === 1}
                            onChange={onToggleTestMode}
                            name="testModeEnabled"
                            color="primary"
                        />
                    </Grid>
                    <Grid item>Test Mode</Grid>
                </Grid>
            </Typography>
        </div>
    );

    return loadingGoogleDatas.includes(companyId) || !data ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <Link to={parentUrl}>
                <div className={'navigation back-button'}>
                    Back to Integration overview
                </div>
            </Link>
            <Row>
                <Col lg="8" xs="12">
                    <div className="integration-detail-block">
                        {googleError && (
                            <ErrorMessage
                                error={googleError}
                                marginBottom={true}
                            />
                        )}
                        <img
                            src={`/static/img/logo-gsuite.svg`}
                            alt="Google logo"
                        />
                        <h2>
                            {(brandingLoaded && account_name) || 'AwareGO'} +
                            Google Suite
                        </h2>
                        <p className={!data.connected ? 'margin-bottom' : ''}>
                            Connect or sync user lists from Google Suite Users
                            Directory.
                        </p>
                        {!data.connected ? (
                            <Fragment>
                                <a href={data.connectUrl}>
                                    <ThemedButton primary>
                                        Connect Google Suite
                                    </ThemedButton>
                                </a>
                            </Fragment>
                        ) : (
                            ''
                        )}
                    </div>
                    {data.connected ? (
                        <Fragment>
                            <div className="integration-detail-block align-left">
                                <h1>Manage connection</h1>
                                <div className="button-with-description">
                                    <p>
                                        Click on the following button to test
                                        the connection with Google. This action
                                        will not send out any messages.
                                    </p>
                                    {testingGoogleData.includes(companyId) ? (
                                        <Spinner />
                                    ) : (
                                        <ThemedButton
                                            secondary
                                            small
                                            onClick={testIntegration}
                                        >
                                            Test Connection
                                        </ThemedButton>
                                    )}
                                </div>
                                <h3>Status</h3>
                                {syncRunning ? (
                                    <Spinner />
                                ) : data && data.testMode === '0' ? (
                                    <ThemedButton secondary onClick={syncNow}>
                                        Sync now
                                    </ThemedButton>
                                ) : (
                                    <ThemedButton
                                        secondary
                                        onClick={previewNow}
                                    >
                                        Preview
                                    </ThemedButton>
                                )}
                                <p>
                                    Last synced:{' '}
                                    {data && data.lastSync ? (
                                        <ThemedText
                                            primary
                                            as={'a'}
                                            href={csvUrl}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            {moment(data.lastSync).format('LL')}
                                        </ThemedText>
                                    ) : (
                                        'Never'
                                    )}
                                </p>
                                <h3>Import Google users & groups</h3>
                                <div className="button-with-description">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={
                                                    Number(
                                                        data && data.enabled
                                                    ) === 1
                                                }
                                                onChange={onToggleEnabled}
                                                name="importEnabled"
                                                color="primary"
                                            />
                                        }
                                        label={`All existing and new Google users will be automatically added to an ${
                                            (brandingLoaded && account_name) ||
                                            'AwareGO'
                                        } training list that you specify.`}
                                    />
                                </div>
                                {testMode}
                            </div>
                            <IntegrationDetailGroupSettings
                                data={data}
                                loadingGroups={loadingGoogleGroups}
                                companyId={companyId}
                                product={'google'}
                                savingData={savingGoogleData}
                                options={options}
                                filteredIncludedOptions={
                                    filteredIncludedOptions
                                }
                                excludedOptions={excludedOptions}
                                included={included}
                                setIncluded={setIncluded}
                                excluded={excluded}
                                setExcluded={setExcluded}
                                filter={filter}
                                setFilter={setFilter}
                            />
                        </Fragment>
                    ) : (
                        ''
                    )}
                </Col>
                <Col lg="4" xs="12">
                    <IntegrationDetailSidebar
                        href="https://help.awarego.com/google-suite"
                        product="Google Suite"
                    />
                </Col>
            </Row>
            <IntegrationDetailPreviewModal
                closePreviewModal={closePreviewModal}
                previewData={previewData}
            />
        </Fragment>
    );
});
