import React from 'react';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import './IntegrationElement.scss';

function IntegrationElement({ integration, supportEmail }) {
    return (
        <Col sm={12} md={12} lg={6} key={integration.id}>
            <div className="block block-shadow">
                {integration.beta && (
                    <div className="ribbon ribbon-top-left">
                        <span>Beta</span>
                    </div>
                )}
                <div className="integration-card-header">
                    <h3>{integration.name}</h3>
                    <img
                        src={`/static/img/${integration.logo}`}
                        alt={`${integration.name} integration`}
                    />
                </div>
                <p>{integration.description}</p>
                <div className="action-bar">
                    <div className="pull-right">
                        {integration.needsUpgrade ? (
                            <a href={`mailto:${supportEmail}`}>
                                <ThemedButton small primary>
                                    Contact to upgrade
                                </ThemedButton>
                            </a>
                        ) : integration.id === 'teams' ||
                          integration.id === 'azuresso' ||
                          integration.id === 'google' ? (
                            integration.enabled === '0' ? (
                                <Link
                                    to={`/settings/integrations/${integration.id}`}
                                >
                                    <ThemedButton small primary>
                                        Connect
                                    </ThemedButton>
                                </Link>
                            ) : (
                                <Link
                                    to={`/settings/integrations/${integration.id}`}
                                >
                                    <ThemedButton small secondary>
                                        Manage
                                    </ThemedButton>
                                </Link>
                            )
                        ) : integration.connected ? (
                            <Link
                                to={`/settings/integrations/${integration.id}`}
                            >
                                <ThemedButton small secondary>
                                    Manage
                                </ThemedButton>
                            </Link>
                        ) : (
                            <Link
                                to={`/settings/integrations/${integration.id}`}
                            >
                                <ThemedButton small primary>
                                    Connect
                                </ThemedButton>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </Col>
    );
}

export default IntegrationElement;
