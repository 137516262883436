import React from 'react';
import { Route, Redirect } from 'react-router';
import { inject, observer } from 'mobx-react';

function PrivateRoute({
    component: Component,
    notLoggedUrl,
    authStore,
    ...rest
}) {
    return (
        <Route
            {...rest}
            render={(props) => {
                //console.log(props.location.pathname)

                if (!notLoggedUrl)
                    notLoggedUrl = `/auth/signin?redirect=${props.location.pathname}${props.location.search}`;

                return authStore.isAuthenticated === true &&
                    authStore.token != null ? (
                    <Component {...props} {...rest} />
                ) : authStore.inProgress ? (
                    ''
                ) : (
                    <Redirect to={notLoggedUrl} />
                );
            }}
        />
    );
}

export default inject('authStore')(observer(PrivateRoute));
