import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container } from 'reactstrap';
import AutomationCreate from './AutomationCreate';
import AutomationList from './AutomationList';
import AutomationView from './AutomationView';
import AutomationEventView from './AutomationEventView';
import AutomationEventUserDetails from './AutomationEventUserDetails';
import AutomationUserDetails from './AutomationUserDetails';
import { Helmet } from 'react-helmet';

export default observer(({ companyId }) => {
    const match = useRouteMatch();

    return (
        <Container className="manager-content">
            <Helmet>
                <title>Training programs</title>
            </Helmet>
            <Switch>
                <Route
                    path={`${match.url}/create/copy/:fromId`}
                    render={(props) => (
                        <AutomationCreate
                            parentMatch={match}
                            companyId={companyId}
                            copyFrom={props.match.params.fromId}
                        />
                    )}
                />
                <Route
                    path={`${match.url}/create`}
                    render={() => (
                        <AutomationCreate
                            parentMatch={match}
                            companyId={companyId}
                        />
                    )}
                />
                <Route
                    path={`${match.url}/edit/:id`}
                    render={(props) => (
                        <AutomationCreate
                            parentMatch={match}
                            companyId={companyId}
                            editId={props.match.params.id}
                        />
                    )}
                />
                <Route
                    path={`${match.url}/:id/event/:eventId/user/:email`}
                    render={(props) => (
                        <AutomationEventUserDetails
                            match={match}
                            companyId={companyId}
                            automationId={props.match.params.id}
                            eventId={props.match.params.eventId}
                            email={props.match.params.email}
                        />
                    )}
                />
                <Route
                    path={`${match.url}/:id/user/:email`}
                    render={(props) => (
                        <AutomationUserDetails
                            match={match}
                            companyId={companyId}
                            automationId={props.match.params.id}
                            email={props.match.params.email}
                        />
                    )}
                />
                <Route
                    path={`${match.url}/:id/event/:eventId`}
                    render={(props) => (
                        <AutomationEventView
                            match={match}
                            companyId={companyId}
                            automationId={props.match.params.id}
                            eventId={props.match.params.eventId}
                        />
                    )}
                />
                <Route
                    path={`${match.url}/:id/view`}
                    render={(props) => (
                        <AutomationView
                            match={match}
                            companyId={companyId}
                            automationId={props.match.params.id}
                        />
                    )}
                />
                <Route
                    path={`${match.url}/active`}
                    exact
                    render={() => (
                        <AutomationList
                            tab="active"
                            parentMatch={match}
                            companyId={companyId}
                        />
                    )}
                />

                <Route
                    path={`${match.url}/completed`}
                    exact
                    render={() => (
                        <AutomationList
                            tab="completed"
                            parentMatch={match}
                            companyId={companyId}
                        />
                    )}
                />
                <Route
                    path={`${match.url}/draft`}
                    exact
                    render={() => (
                        <AutomationList
                            tab="draft"
                            parentMatch={match}
                            companyId={companyId}
                        />
                    )}
                />
                <Route
                    path={`${match.url}`}
                    render={() => (
                        <AutomationList
                            tab="all"
                            parentMatch={match}
                            companyId={companyId}
                        />
                    )}
                />
            </Switch>
        </Container>
    );
});
