import React from 'react';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import styled, { withTheme } from 'styled-components';

const ThemedCalendar = styled.div`
    display: inline-flex;
    vertical-align: bottom;
`;

class ThemedCalendarSVG extends React.Component {
    render() {
        return (
            <ThemedCalendar>
                <SvgLoader path="/static/img/icon-calendar.svg">
                    <SvgProxy selector="path" fill={this.props.theme.primary} />
                </SvgLoader>
            </ThemedCalendar>
        );
    }
}

export default withTheme(ThemedCalendarSVG);
