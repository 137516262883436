import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Container } from 'reactstrap';
import ListView from './ListView';
import ListOfLists from './ListOfLists';
import UserDetailsContainer from './UserDetails';
import { Helmet } from 'react-helmet';

@inject('store', 'userStore', 'listStore')
@observer
class Users extends React.Component {
    render() {
        const { match } = this.props;

        return (
            <Container className="manager-content">
                <Helmet>
                    <title>Employees</title>
                </Helmet>
                <Switch>
                    <Route
                        path={`${match.url}/list/:listId/user/:email`}
                        render={(props) => (
                            <UserDetailsContainer
                                match={match}
                                companyId={this.props.companyId}
                                listId={parseInt(props.match.params.listId)}
                                email={props.match.params.email}
                            />
                        )}
                    />

                    <Route
                        path={`${match.url}/list/:listId`}
                        render={(props) => (
                            <ListView
                                parentMatch={match}
                                listId={parseInt(props.match.params.listId)}
                                companyId={this.props.companyId}
                            />
                        )}
                    />

                    <Route
                        path={`${match.url}/`}
                        render={(props) => (
                            <ListOfLists
                                match={match}
                                companyId={this.props.companyId}
                            />
                        )}
                    />
                </Switch>
            </Container>
        );
    }
}

export default withRouter(Users);
