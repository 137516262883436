import React, { Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import ThemedSpinner from '../../themed/ThemedSpinner';
import { FlexDiv } from '../../design-system/containers';
import { Heading2 } from '../../design-system/headings';
import SubjectRow from './subject-row';

function SubjectSelect({
    catalog,
    onVideoSelected,
    selection = [],
    showPreviewSubjectModal,
    showPreviewScormModal,
    category,
    isTrial,
}) {
    const previewVideo = (subjectId, type) => {
        if (type === 1) showPreviewSubjectModal(subjectId);
        if (type === 2) showPreviewScormModal(subjectId);
    };

    const displayedVideos = useMemo(
        () =>
            category === 'all'
                ? []
                : catalog.find((c) => c.name === category).subjects,
        [category, catalog]
    );

    const catalogWithoutEmptyCategories = useMemo(
        () => catalog.filter((c) => c.subjects.length > 0),
        [catalog]
    );

    const subjectRow = (subject) => (
        <SubjectRow
            onVideoSelected={onVideoSelected}
            selection={selection}
            subject={subject}
            previewVideo={previewVideo}
            key={subject.id}
            isTrial={isTrial}
        />
    );

    return (
        <Fragment>
            {!catalog ? (
                <ThemedSpinner />
            ) : category === 'all' ? (
                catalogWithoutEmptyCategories.map((c) => (
                    <FlexDiv column key={c.id} mb={24}>
                        <Heading2 mb={16}>{c.name}</Heading2>
                        {c.subjects.map((subject) => subjectRow(subject))}
                    </FlexDiv>
                ))
            ) : displayedVideos.length === 0 ? (
                <div className="no-assessments-found">
                    <img
                        src="/static/img/icon-booklist.svg"
                        alt="No subjects found"
                    />
                    <div className="stats">
                        <h5>No subjects found</h5>
                        <p>
                            Try adjusting the filters to find what you were
                            looking for.
                        </p>
                    </div>
                </div>
            ) : (
                <FlexDiv column>
                    <Heading2 mb={16}>{category}</Heading2>
                    {displayedVideos.map((subject) => subjectRow(subject))}
                </FlexDiv>
            )}
        </Fragment>
    );
}

export default observer(SubjectSelect);
