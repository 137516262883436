import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import SubjectListEmbed from '../components/manager/embed/subject-list';
import services from '../services';

@inject('store')
@observer
class Embed extends React.Component {
    state = { error: '', loadedOrError: null };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState) return; // not yet initialized
        if (nextProps.companyId !== prevState.companyId) {
            return {
                companyId: nextProps.companyId,
                loadedOrError: null,
            };
        }

        // No state update necessary
        return null;
    }

    componentDidMount() {
        // It's preferable in most cases to wait until after mounting to load data.
        // See below for a bit more context...
        this.loadData();
    }

    componentDidUpdate() {
        /* LRT: should not be necessary (and calls load function 50 times)
   if (this.state.loadedOrError === null) {
       // At this point, we're in the "commit" phase, so it's safe to load the new data.
       this.loadData()
   }
   */
    }

    async loadData() {
        try {
            this.setState({ categories: [] });
            const result = await services.Categories.list();
            this.setState({ categories: result });
        } catch (e) {
        } finally {
            //this.loadingCategories = false
        }
        this.setState({ ...this.state, loadedOrError: true });
    }

    render() {
        const { match } = this.props;
        const { categories } = this.state;

        return (
            <Container className="manager-content">
                <Switch>
                    <Route
                        path={`${match.url}/`}
                        exact
                        render={(props) => (
                            <SubjectListEmbed
                                categories={categories}
                                match={props.match}
                            />
                        )}
                    />
                </Switch>
            </Container>
        );
    }
}

export default withRouter(Embed);
