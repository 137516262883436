import React from 'react';
import { observer } from 'mobx-react';

@observer
class CompanyEdit extends React.Component {
    render() {
        const { form } = this.props;

        const nameField = form.$('name');

        return (
            <div className="steps step2">
                <div className="forms">
                    <div className="form-header">
                        <h2>Company</h2>
                    </div>

                    <div className="form-holder">
                        <h3>{nameField.label}</h3>
                        <input
                            type="text"
                            name="companyName"
                            {...nameField.bind()}
                            className={
                                nameField.isValid
                                    ? 'bigInput valid'
                                    : 'bigInput'
                            }
                        />
                        <div className="error">{nameField.error}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyEdit;
