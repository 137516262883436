import { action, observable } from 'mobx';
import services from '../services';
import { extractErrorMessage } from '../utils/helpers';

class VideosStore {
    @observable videos = observable([]);
    @observable loadingVideos = false;
    @observable error = null;

    @action
    async loadVideos(tag) {
        if (this.loadingVideos) return;
        this.error = null;
        try {
            this.loadingVideos = true;
            this.videos = await services.Videos.fetch(tag);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingVideos = false;
        }
    }
}

export default VideosStore;
