import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

import ErrorMessage from '../../errormessage';
import { observer } from 'mobx-react';
import Popup from 'reactjs-popup';
import ReactJWPlayer from 'react-jw-player';

@observer
class SubjectListEmbed extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            previewId: 1,
            previewVideoLocation: '',
        };
    }

    render() {
        var lang = 'icelandic';
        var video = 'okKk7aIp';
        if (lang) {
            if (lang === 'icelandic') {
                video = 'L8hnWL4o';
            } else if (lang === 'danish') {
                video = 'C21DdmXo';
            } else if (lang === 'deutsch') {
                video = 'P7hYVCwK';
            } else if (lang === 'norwegian') {
                video = 'C21DdmXo';
            } else if (lang === 'swedish') {
                video = 'YmxfK7ps';
            } else if (lang === 'danish') {
                video = 'C21DdmXo';
            } else if (lang === 'spanish') {
                video = 'C21DdmXo';
            } else if (lang === 'italian') {
                video = 'C21DdmXo';
            } else if (lang === 'french') {
                video = 'C21DdmXo';
            }
        }

        const { categories, match } = this.props;

        const playlist = [
            {
                file:
                    'https://content.jwplatform.com/manifests/' +
                    video +
                    '.m3u8',
                image:
                    'http://content.jwplatform.com/thumbs/' +
                    video +
                    '-1920.jpg',
            },
        ];

        if (categories != null && categories.length > 0) {
            return (
                <div className="videos">
                    <h2>Videos to embed</h2>
                    <p>
                        Click on a video to get the embed code. All views
                        count...
                    </p>
                    <Popup
                        trigger={<button>Trigger</button>}
                        modal
                        closeOnDocumentClick
                    >
                        {(close) => (
                            <div>
                                <div className="embed-holder">
                                    <div className="embed">
                                        <div className="embed-container">
                                            <ReactJWPlayer
                                                className="video-item"
                                                playerId="Awarego"
                                                playerScript={
                                                    'https://content.jwplatform.com/libraries/GRKJwR4U.js'
                                                }
                                                licenseKey="tbMPLx6pAOjLHsSbrqGJdPVygT90S8O71WG28+3dDJA="
                                                playlist={playlist}
                                                width="100%"
                                                aspectratio="16:9"
                                                isAutoPlay={false}
                                                customProps={{
                                                    skin: { name: 'AwareGO' },
                                                }}
                                            />

                                            <script
                                                id="jwembed"
                                                src="https://content.jwplatform.com/players/okKk7aIp-GRKJwR4U.js"
                                            ></script>
                                        </div>
                                        <div className="language-chooser">
                                            <div className="selected flag icelandic">
                                                Icelandic
                                            </div>
                                            <div className="nav-item-dropdown">
                                                <a href="?lang=icelandic">
                                                    <div className="flag icelandic">
                                                        Icelandic
                                                    </div>
                                                </a>
                                                <a href="?lang=spanish">
                                                    <div className="flag spanish">
                                                        Español
                                                    </div>
                                                </a>
                                                <a href="?lang=italian">
                                                    <div className="flag italian">
                                                        Italiano
                                                    </div>
                                                </a>
                                                <a href="?lang=french">
                                                    <div className="flag french">
                                                        Français
                                                    </div>
                                                </a>
                                                <a href="?lang=deutsch">
                                                    <div className="flag deutsch">
                                                        Deutsch
                                                    </div>
                                                </a>
                                                <a href="?lang=danish">
                                                    <div className="flag danish">
                                                        Dansk
                                                    </div>
                                                </a>
                                                <a href="?lang=norwegian">
                                                    <div className="flag norwegian">
                                                        Norsk
                                                    </div>
                                                </a>
                                                <a href="?lang=swedish">
                                                    <div className="flag swedish">
                                                        Svenska
                                                    </div>
                                                </a>
                                                <a href="?lang=english-gb">
                                                    <div className="flag english-gb">
                                                        English UK
                                                    </div>
                                                </a>
                                                <a href="?lang=english">
                                                    <div className="flag english">
                                                        English
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="brand">
                                            <img
                                                src="/static/img/logo_embed.svg"
                                                alt="AwareGO logo"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div>Copy the code to your own LMS system</div>

                                <div className="embed-code">
                                    <pre>
                                        {' '}
                                        {
                                            '<iframe title="UNIQUE_ID" width="560" height="315" src="/embed/cmp767761AWDfdfe" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>'
                                        }
                                    </pre>
                                </div>

                                <a
                                    href="/embed"
                                    className="close"
                                    onClick={close}
                                >
                                    &times;
                                </a>
                            </div>
                        )}
                    </Popup>
                    {categories.map((subjects, i) => (
                        <div key={i}>
                            <Row>
                                <Col>
                                    <div className="listHeading">
                                        <h4>{subjects.name}</h4>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {subjects.subjects.map((subject, ii) => (
                                    <Col md="6" xs="12" key={ii}>
                                        <div className="listItem">
                                            <Link
                                                to={`${match.url}/${subject.id}`}
                                            >
                                                <Row>
                                                    <Col md="4" xs="12">
                                                        <div className="img">
                                                            <img
                                                                src={
                                                                    subject.thumb
                                                                }
                                                                alt=""
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col md="8" xs="12">
                                                        <h3>{subject.title}</h3>
                                                        <p>
                                                            CLICK HERE FOR EMBED
                                                            CODE
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </Link>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    ))}
                    <Row>
                        <Col md="12" xs="12" className="leftMenu">
                            <ErrorMessage error={this.state.error} />
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default SubjectListEmbed;
