import React, { Fragment } from 'react';
import {
    useTable,
    usePagination,
    useSortBy,
    useGlobalFilter,
} from 'react-table';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import './SimpleTable.scss';
import { observer } from 'mobx-react';
import TextWithTooltipIcon from './TextWithTooltipIcon';
import { Link } from 'react-router-dom';
import { TextTiny, TextLead } from './design-system/texts';
import { SearchInput } from './design-system/inputs';
import styled from 'styled-components';

const ScrollableGrid = styled(Grid)`
    overflow-x: auto;
    max-height: 100vh;
`;
const StickyTableHead = styled(TableHead)`
    position: sticky;
    top: 0;
    background: white;
    z-index: 1;
`;
const StickyTableFooter = styled(TableFooter)`
    position: sticky;
    bottom: 0;
    background: white;
    z-index: 1;
`;

function SimpleTable({
    columns,
    data,
    needsFilter,
    initialyLoaded = true,
    hidePagination,
    sum,
    indexOfSumColumn,
    useLink,
    disableSort = false,
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        setGlobalFilter,
        state: { pageIndex, pageSize, globalFilter },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 10,
            },
            disableSortBy: disableSort,
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    return (
        <ScrollableGrid
            container
            direction="row"
            justify="flex-start"
            align="center"
        >
            {needsFilter && (
                <Box mb={1} className="global-filter">
                    <SearchInput
                        searchValue={globalFilter || ''}
                        placeholder="Search"
                        onChange={(e) =>
                            setGlobalFilter(e.target.value || undefined)
                        }
                        onClear={() => setGlobalFilter('')}
                    />
                </Box>
            )}
            <Grid
                item
                xs={12}
                direction="row"
                justify="flex-start"
                align="center"
            >
                <MuiTable
                    {...getTableProps()}
                    className="simple-table no-margin"
                >
                    {data.length > 0 ? (
                        <Fragment>
                            <StickyTableHead>
                                {headerGroups.map((headerGroup, i) => (
                                    <TableRow
                                        {...headerGroup.getHeaderGroupProps()}
                                        key={i}
                                    >
                                        {headerGroup.headers.map(
                                            (column, j) => (
                                                <TableCell
                                                    {...column.getHeaderProps(
                                                        column.getSortByToggleProps(
                                                            // disables default tooltip
                                                            { title: undefined }
                                                        )
                                                    )}
                                                    style={{
                                                        minWidth:
                                                            column.minWidth,
                                                        top: 57,
                                                        cursor: !disableSort
                                                            ? 'pointer'
                                                            : 'auto',
                                                    }}
                                                    key={j}
                                                >
                                                    <TextTiny
                                                        themed={column.isSorted}
                                                        lighter={
                                                            !column.isSorted
                                                        }
                                                        bold
                                                        flex
                                                    >
                                                        {column.isSorted
                                                            ? column.isSortedDesc
                                                                ? '↓ '
                                                                : '↑ '
                                                            : ''}
                                                        {column.tooltip ? (
                                                            <TextWithTooltipIcon
                                                                tooltipText={
                                                                    column.tooltip
                                                                }
                                                                textComponent={
                                                                    column.Header !==
                                                                    ' '
                                                                        ? column.render(
                                                                              'Header'
                                                                          )
                                                                        : null
                                                                }
                                                            />
                                                        ) : column.Header !==
                                                          ' ' ? (
                                                            column.render(
                                                                'Header'
                                                            )
                                                        ) : null}
                                                    </TextTiny>
                                                    {column.Filter && (
                                                        <div>
                                                            {column.render(
                                                                'Filter'
                                                            )}
                                                        </div>
                                                    )}
                                                </TableCell>
                                            )
                                        )}
                                    </TableRow>
                                ))}
                            </StickyTableHead>
                            <TableBody {...getTableBodyProps()}>
                                {page.map((row, j) => {
                                    prepareRow(row);
                                    return (
                                        <TableRow
                                            {...row.getRowProps()}
                                            key={j}
                                            component={
                                                useLink ? Link : undefined
                                            }
                                            to={
                                                useLink && row.original.link
                                                    ? row.original.link
                                                    : undefined
                                            }
                                        >
                                            {row.cells.map((cell, k) => {
                                                return (
                                                    <TableCell
                                                        {...cell.getCellProps()}
                                                        key={k}
                                                    >
                                                        {cell.render('Cell')}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                                {sum && indexOfSumColumn && (
                                    <TableRow>
                                        {[...Array(indexOfSumColumn - 1)].map(
                                            (x, l) => (
                                                <TableCell key={l} />
                                            )
                                        )}
                                        <TableCell>
                                            <TextLead bold>Total</TextLead>
                                        </TableCell>
                                        <TableCell align="left">
                                            <TextLead bold>{sum}</TextLead>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            {!hidePagination && (
                                <StickyTableFooter>
                                    <TablePagination
                                        rowsPerPageOptions={[
                                            5, 10, 20, 30, 40, 50, 100,
                                        ]}
                                        count={data.length}
                                        className="simple-table-pagination"
                                        rowsPerPage={pageSize}
                                        page={pageIndex}
                                        onChangePage={(e, newPage) => {
                                            gotoPage(newPage);
                                        }}
                                        onChangeRowsPerPage={(e) => {
                                            setPageSize(
                                                Number(+e.target.value)
                                            );
                                        }}
                                        ActionsComponent={() => (
                                            <Fragment>
                                                <IconButton
                                                    onClick={() => gotoPage(0)}
                                                    disabled={!canPreviousPage}
                                                    aria-label="first page"
                                                >
                                                    <FirstPageIcon />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() =>
                                                        previousPage()
                                                    }
                                                    disabled={!canPreviousPage}
                                                    aria-label="previous page"
                                                >
                                                    <KeyboardArrowLeft />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => nextPage()}
                                                    disabled={!canNextPage}
                                                    aria-label="next page"
                                                >
                                                    <KeyboardArrowRight />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() =>
                                                        gotoPage(pageCount - 1)
                                                    }
                                                    disabled={!canNextPage}
                                                    aria-label="last page"
                                                >
                                                    <LastPageIcon />
                                                </IconButton>
                                            </Fragment>
                                        )}
                                    />
                                </StickyTableFooter>
                            )}
                        </Fragment>
                    ) : initialyLoaded ? (
                        'No data :('
                    ) : null}
                </MuiTable>
            </Grid>
        </ScrollableGrid>
    );
}

export default observer(SimpleTable);
