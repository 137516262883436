import { action, computed, observable } from 'mobx';
import sumBy from 'lodash/sumBy';
import uniq from 'lodash/uniq';
import { TRANSPORTS, STATUS } from '../../utils/constants';
import moment from 'moment';
import { defaultZero } from '../../utils/helpers';

const statuses = {
    [STATUS.DRAFT]: {
        label: 'Draft',
        icon: false,
        value: STATUS.DRAFT,
        color: 'dark',
    },
    [STATUS.SCHEDULED]: {
        label: 'Scheduled',
        icon: false,
        value: STATUS.SCHEDULED,
        color: 'primary',
    },
    [STATUS.ONGOING]: {
        label: 'Active',
        icon: false,
        value: STATUS.ONGOING,
        color: 'warning',
    },
    // {
    //     label: "Pending review",
    //     icon: false,
    //     value: STATUS.REVIEW,
    //     color: "danger",
    // },
    [STATUS.COMPLETED]: {
        label: '✓ Completed',
        icon: true,
        value: STATUS.COMPLETED,
        color: 'success',
    },
};

class Assessment {
    @observable selectedQuestionsIds = observable([]);
    @observable selectedUserListsIds = observable([]);
    @observable date = null;
    @observable name = 'Untitled Assessment';
    @observable threatAreas = [];
    @observable scheduleType = 1;
    @observable channel;
    @observable active = false;
    @observable introMessageEnabled = false;
    @observable due = null;
    @observable id = null;
    @observable templateCustomized = false;
    @observable reminders_enabled = false;
    @observable reminders_channel;
    @observable reminders_steps = [{ send: null }];
    @observable status;
    @observable companyName = '';
    @observable companyDomain = '';
    @observable emailHR = '';

    constructor(listStore, questionsIndex, assessmentData, companyId) {
        this.listStore = listStore;
        this.companyId = companyId;
        this.questionsIndex = questionsIndex;
        this.channel =
            window.localStorage.getItem(`assessmentChannel-${companyId}`) ||
            TRANSPORTS.EMAIL;
        this.reminders_channel =
            window.localStorage.getItem(
                `assessmentRemindersChannel-${companyId}`
            ) || TRANSPORTS.EMAIL;
        this.companyName =
            window.localStorage.getItem(`companyName-${companyId}`) || '';
        this.companyDomain =
            window.localStorage.getItem(`companyDomain-${companyId}`) || '';
        this.emailHR =
            window.localStorage.getItem(`emailHR-${companyId}`) || '';
        if (assessmentData) {
            this._rawData = assessmentData.id ? assessmentData : null;
            this.name = assessmentData.name;
            this.id = assessmentData.id;
            if (assessmentData.sendOptions.date)
                this.date = assessmentData.sendOptions.date;
            if (assessmentData.reminders_enabled)
                this.reminders_enabled = assessmentData.reminders_enabled;
            if (assessmentData.active !== undefined)
                this.active = assessmentData.active === 1;
            if (assessmentData.definition.questions)
                this.selectedQuestionsIds.replace(
                    assessmentData.definition.questions
                );
            if (assessmentData.definition.userLists)
                this.selectedUserListsIds.replace(
                    assessmentData.definition.userLists
                );
            if (assessmentData.sendOptions.channel)
                this.channel = assessmentData.sendOptions.channel;
            if (assessmentData.definition.personalizeData) {
                this.companyName =
                    assessmentData.definition.personalizeData.companyName;
                this.companyDomain =
                    assessmentData.definition.personalizeData.companyDomain;
                this.emailHR =
                    assessmentData.definition.personalizeData.emailHR;
            }
            this.scheduleType = assessmentData.sendOptions.scheduleType;
            this.due = assessmentData.due;
            this.reminders_channel = assessmentData.reminders_channel || 1;
            if (assessmentData.reminders_steps) {
                this.reminders_steps.replace(
                    assessmentData.reminders_steps.map((r) => ({
                        ...r,
                        alreadySet: true,
                    })) || [{ send: null }]
                );
            }
            this.threatAreas = assessmentData.areas;
            this.status = assessmentData.status;
        }
    }

    @computed get resilienceScore() {
        if (!this.threatAreas) return 0;
        return Math.round(
            this.threatAreas.reduce((acc, area) => {
                return acc + area.score || 0;
            }, 0) / this.threatAreas.length
        );
    }

    @action setName = (value) => {
        this.name = value;
    };

    @action setId = (value) => {
        this.id = value;
    };

    @action toggleIntroMessage = () => {
        this.introMessageEnabled = !this.introMessageEnabled;
    };

    @action setScheduleType = (type) => {
        this.scheduleType = type;
    };

    @action setChannel = (value, checked) => {
        let newChannel = this.channel;
        if (checked) {
            // add checked value to channel, this is called bitwise OR
            newChannel |= value;
        } else {
            // remove unchecked value from channel, this is called bitwise NOT
            newChannel &= ~value;
        }
        if (newChannel !== 0) {
            this.channel = newChannel;
            window.localStorage.setItem(
                `assessmentChannel-${this.companyId}`,
                this.channel
            );
            return true;
        } else {
            return false;
        }
    };

    @action setRemindersChannel = (value, checked) => {
        let newChannel = this.reminders_channel;
        if (checked) {
            // add checked value to channel, this is called bitwise OR
            newChannel |= value;
        } else {
            // remove unchecked value from channel, this is called bitwise NOT
            newChannel &= ~value;
        }
        if (newChannel !== 0) {
            this.reminders_channel = newChannel;
            window.localStorage.setItem(
                `assessmentRemindersChannel-${this.companyId}`,
                this.reminders_channel
            );
            return true;
        } else {
            return false;
        }
    };

    @action setDueDate = (date) => {
        this.due = date;
    };

    @action setDate = (date) => {
        this.date = date;
    };

    @action setRemindersEnabled = () => {
        if (!this.reminders_enabled && this.scheduleType === 0) {
            this.setReminder(
                moment(this.date).add(1, 'days').set('minute', 0),
                0
            );
        }
        this.reminders_enabled = !this.reminders_enabled;
    };

    @action setReminder = (date, i, reminder) => {
        if (reminder) {
            reminder.send = date;
        } else if (this.reminders_steps.length > 0) {
            this.reminders_steps[i].send = date;
        }
    };

    @action addReminderStep = () => {
        // if there are already some reminders, create a new reminder one day after the latest one and check, that it's not past due date, else create an empty reminder with date not set
        let dayAfterLatestReminder =
            this.reminders_steps.length > 0 &&
            moment(
                this.reminders_steps[this.reminders_steps.length - 1].send
            ).add(1, 'days');

        this.reminders_steps.length > 0 &&
        moment(dayAfterLatestReminder).isBefore(this.due)
            ? this.reminders_steps.push({
                  id: -1,
                  send: dayAfterLatestReminder,
              })
            : this.reminders_steps.push({ send: null });
    };

    @action deleteReminderStep = (reminder) => {
        this.reminders_steps.remove(reminder);
    };

    @action toggleQuestionSelected(questionId) {
        if (this.selectedQuestionsIds.includes(questionId))
            this.selectedQuestionsIds.remove(questionId);
        else this.selectedQuestionsIds.push(questionId);
        this.templateCustomized = true;
    }

    @action toggleUserListSelected(listId) {
        if (this.selectedUserListsIds.includes(listId))
            this.selectedUserListsIds.remove(listId);
        else this.selectedUserListsIds.push(listId);
    }

    @action
    reorderQuestionsList(startIndex, endIndex) {
        if (endIndex < 0) {
            endIndex = 0;
        }
        const [removed] = this.selectedQuestionsIds.splice(startIndex, 1);
        this.selectedQuestionsIds.splice(endIndex, 0, removed);
    }

    // TODO: Move to API loading  company info, should be available from anyway via companyStore.currentCompany
    @computed get isUserCountOk() {
        let usersAvailable = 0;
        let usersRegistered = 0;

        if (this.company) {
            if (this.company.users_available) {
                usersAvailable = this.company.users_available;
            }
            usersRegistered = this.getCurrentCompanyUserCount;
        }
        return usersAvailable >= usersRegistered;
    }

    @computed get statusInfo() {
        return statuses[this.status] || {};
    }

    @computed get hasUnavailableQuestions() {
        return (
            this.unavailableQuestionsSelected &&
            this.unavailableQuestionsSelected.length > 0
        );
    }

    @computed get link() {
        return `/assessment/${this.id}/view`;
    }

    @computed get questionsSelected() {
        return this.selectedQuestionsIds
            .map((x) => this.questionsIndex[x])
            .filter((x) => x != null);
    }

    @computed get unavailableQuestionsSelected() {
        return this.selectedQuestionsIds
            .map((x) => this.questionsIndex[x])
            .filter((x) => x.disabled);
    }

    @computed get maxByAreaAndBehaviourForSelectedQuestions() {
        let maxByAreaAndBehaviour = {};
        this.questionsSelected.forEach((q) => {
            Object.keys(q.maxScoresByBehaviour).forEach((b) => {
                if (!maxByAreaAndBehaviour[`${q.area}-${b}`])
                    maxByAreaAndBehaviour[`${q.area}-${b}`] = 0;
                maxByAreaAndBehaviour[`${q.area}-${b}`] +=
                    q.maxScoresByBehaviour[b];
            });
        });
        return maxByAreaAndBehaviour;
    }

    @computed get userListsSuffix() {
        return this.selectedUserListsIds.length === 1
            ? ` - ${this.userListsSelected[0].name}`
            : '';
    }

    @computed get userListsSelected() {
        return this.selectedUserListsIds.map(
            (x) => this.listStore.allListsIndex[x]
        );
    }

    @computed get selectedUserCount() {
        return sumBy(
            this.selectedUserListsIds.map(
                (x) => this.listStore.allListsIndex[x]
            ),
            'usersCount'
        );
    }

    @computed get personalizeData() {
        let data = {};
        if (this.companyName) {
            data.companyName = this.companyName;
        }
        if (this.companyDomain) {
            data.companyDomain = this.companyDomain;
        }
        if (this.emailHR) {
            data.emailHR = this.emailHR;
        }
        return data;
    }

    // @computed get listNames() {
    //     if (this.listStore !== null) {
    //         return this.listStore.listsWithUsers.filter((list) =>
    //             (this._rawData.definition.userLists.includes(list.id))
    //         )
    //     }
    //     return null
    // }

    @computed get canDelete() {
        return true;
        //
        // if (! this._rawData )
        //     return false
        // if (! this.active )
        //     return false
        // if (this._rawData.events.some( x=> x.status >0 ) ) return false
        //
        // return true
    }

    @computed get canCopy() {
        if (!this._rawData) return false;
        if (this.active) return false;
        if (this._rawData.events.every((x) => x.status > 0)) return false;

        return true;
    }

    @computed get canRemind() {
        if (!this._rawData) return false;
        if (!this.active) return false;
        return this.status === STATUS.ONGOING;
    }

    @computed get questions() {
        return this._rawData.definition && this._rawData.definition.questions;
    }

    @computed get personalizeEnabled() {
        return this.questionsSelected.find(
            (q) => 'personalizedKeysUsed' in q.definition
        ) !== undefined
            ? true
            : false;
    }

    @computed get personalizedKeys() {
        let keys = [];
        this.personalizeEnabled &&
            this.questionsSelected.map((q) =>
                q.definition.personalizedKeysUsed !== undefined
                    ? q.definition.personalizedKeysUsed.map((k) => keys.push(k))
                    : null
            );
        return uniq(keys);
    }

    @computed get deliveryDate() {
        return this._rawData.sendOptions.scheduleType === 1
            ? this.status === STATUS.DRAFT
                ? null
                : this._rawData.created
            : this._rawData.sendOptions.date;
    }

    @computed get attendance() {
        return defaultZero(
            Math.floor(
                (100 * (this._rawData.employeesFinished || 0)) /
                    this._rawData.totalEmployees
            )
        );
    }

    @computed get selectedQuestionsDuration() {
        return (
            Object.values(this.questionsSelected).reduce(
                (q, { duration }) => q + duration,
                0
            ) / 60
        );
    }

    toDTO(extraData) {
        return {
            questions: this.selectedQuestionsIds,
            name: this.name.replace('&nbsp;', ' '),
            id: this.id,
            userLists: this.selectedUserListsIds,
            channel: this.channel,
            scheduleType: this.scheduleType,
            date: this.date,
            timeOfDay: moment(this.date).hours(),
            due: this.due ? this.due : null,
            reminders_enabled: this.reminders_enabled,
            reminders_settings: {
                channel: this.reminders_channel,
                steps: this.reminders_steps,
            },
            templateCustomized: this.templateCustomized,
            introMessage: this.introMessageEnabled,
            personalizeEnabled: this.personalizeEnabled,
            threatAreas: this.threatAreas,
            status: this.status,
            ...extraData,
        };
    }
}

export default Assessment;
