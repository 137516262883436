import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'reactstrap';
import FormDef from '../../forms/billing';
import Billing from '../../components/forms/billing';
import Summary from './summary';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

@inject('subscriptionStore', 'authStore')
@observer
class BillingForm extends React.Component {
    constructor(props) {
        super(props);
        this._form = new FormDef(
            {},
            {
                hooks: {
                    onSuccess: this.onSuccess,
                },
            }
        );

        let name = this.props.authStore.currentUser.name || '';

        this._form.update(
            (this.props.company && this.props.company.billingInfo) || {
                email: this.props.authStore.currentUser.email,
                firstName: name.split(' ')[0],
                lastName: name.split(' ')[1],
            }
        );

        this._form.validate();

        this._form.observe({
            path: 'coupon',
            key: 'value', // can be any field property
            call: ({ form, field, change }) => {
                this.props.onCouponEnetered(field.value);
            },
        });

        this._form.observe({
            path: 'email',
            key: 'value', // can be any field property
            call: ({ form, field, change }) => {
                this._form.validate();
            },
        });

        this._form.observe({
            path: 'firstName',
            key: 'value', // can be any field property
            call: ({ form, field, change }) => {
                this._form.validate();
            },
        });

        this._form.observe({
            path: 'lastName',
            key: 'value', // can be any field property
            call: ({ form, field, change }) => {
                this._form.validate();
            },
        });
    }

    onSuccess = async () => {
        this.props.onComplete(this._form.values());
    };

    render() {
        const {
            estimating,
            couponError,
            price,
            futureCredit,
            nextPlanPrice,
            nextBilling,
            my,
            planPeriod,
            selectedTier,
        } = this.props.subscriptionStore;
        let canProceed = this._form.isValid && !estimating && !couponError;
        return (
            <div className={'wizard-fullscreen lightbox-like'}>
                <div className={'wizard-fullscreen-header'}>
                    <div
                        className={'wizard-fullscreen-close'}
                        onClick={this.props.onCancel}
                    >
                        <img src="/static/img/close.svg" alt="Close" />
                    </div>
                    <h3>Your Details</h3>
                </div>
                <div className={'wizard-fullscreen-body'}>
                    <div className="d-flex flex-fill align-items-center justify-content-center">
                        <Billing form={this._form} couponError={couponError} />
                    </div>
                </div>
                <div className={'wizard-fullscreen-footer'}>
                    <div className={'actions center'}>
                        <div className="d-flex flex-row align-items-center price">
                            <strong>Summary:</strong>
                            <div className="d-flex flex-column">
                                {estimating ? (
                                    <ThemedSpinner size={35} />
                                ) : (
                                    <Summary
                                        {...{
                                            price,
                                            futureCredit,
                                            nextPlanPrice,
                                            nextBilling,
                                            my,
                                            planPeriod,
                                        }}
                                    />
                                )}
                                {selectedTier && (
                                    <div className="price-description">
                                        {selectedTier.starting_unit}-
                                        {selectedTier.ending_unit || 500}{' '}
                                        employees
                                    </div>
                                )}
                            </div>
                        </div>
                        <Button
                            color="primary"
                            disabled={!canProceed}
                            onClick={this._form.onSubmit}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default BillingForm;
