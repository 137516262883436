import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import pluralize from 'pluralize';
import SectionHeader from '../../components/SectionHeader';
import SubjectsProgress from '../../components/manager/automation/SubjectsProgress';
import ListsProgress from '../../components/manager/automation/ListsProgress';
import UserDetails from '../../components/manager/users/userDetailsWithProgressBar';
import InlineProgressBar from '../../components/InlineProgressBar';
import {
    formatDate,
    querystring,
    trainingName,
    defaultZero,
    calculateColor,
} from '../../utils/helpers';
import { Heading3 } from '../../components/design-system/headings';

@inject(
    'automationStore',
    'companyStore',
    'listStore',
    'authStore',
    'store',
    'catalogStore',
    'commonStore'
)
@observer
class TrainingReportContainer extends React.Component {
    constructor(props) {
        super(props);
        const token = querystring('token');
        if (token) this.props.authStore.setToken(token);
    }

    componentDidMount() {
        this.props.automationStore.loadAutomation(
            this.props.match.params.companyId,
            this.props.match.params.trainingId
        );
    }

    render() {
        const { editingAutomation, loadingAutomation } =
            this.props.automationStore;
        let weeksFromStart, attendance, score;
        if (editingAutomation) {
            weeksFromStart =
                editingAutomation &&
                Math.max(
                    1,
                    moment().diff(
                        moment(editingAutomation._rawData.created),
                        'weeks'
                    )
                );
            attendance =
                editingAutomation &&
                (100 * editingAutomation._rawData.opened) /
                    editingAutomation._rawData.totalUsers;
            score =
                editingAutomation &&
                (100 * editingAutomation._rawData.score) /
                    editingAutomation._rawData.total;
        }

        return (
            <div className={'report-root'}>
                {!loadingAutomation && (
                    <Fragment>
                        {editingAutomation ? (
                            <Fragment>
                                <div className="report-header">
                                    <SectionHeader>
                                        <div className="d-flex flex-row">
                                            <div className={'section-header'}>
                                                <div className={'left padded'}>
                                                    {/* TODO: Fix the text Should be: 24 minutes delivered through 5 weeks · Training started on Apr 16, 2019 · Next lesson on 13 May, 2019*/}
                                                    <h3>
                                                        Training report for{' '}
                                                        {trainingName(
                                                            editingAutomation.name,
                                                            editingAutomation
                                                                ._rawData
                                                                .created
                                                        )}{' '}
                                                    </h3>
                                                    <p>
                                                        {
                                                            editingAutomation
                                                                ._rawData
                                                                .minutesSent
                                                        }{' '}
                                                        minutes delivered over{' '}
                                                        {weeksFromStart}{' '}
                                                        {pluralize(
                                                            'week',
                                                            weeksFromStart
                                                        )}{' '}
                                                        · Training started on{' '}
                                                        {moment(
                                                            editingAutomation
                                                                ._rawData
                                                                .created
                                                        ).format('LL')}
                                                        {editingAutomation
                                                            ._rawData.active &&
                                                            editingAutomation
                                                                ._rawData
                                                                .nextSubjectDate && (
                                                                <Fragment>
                                                                    · Next
                                                                    lesson on{' '}
                                                                    {formatDate(
                                                                        editingAutomation
                                                                            ._rawData
                                                                            .nextSubjectDate
                                                                    )}
                                                                </Fragment>
                                                            )}
                                                        {!editingAutomation
                                                            ._rawData.active &&
                                                            editingAutomation
                                                                ._rawData
                                                                .finished && (
                                                                <Fragment>
                                                                    · Finished
                                                                    on{' '}
                                                                    {moment(
                                                                        editingAutomation.finished
                                                                    ).format(
                                                                        'LL'
                                                                    )}
                                                                </Fragment>
                                                            )}
                                                    </p>
                                                    <p className="text-thin">
                                                        Report created on{' '}
                                                        {moment(
                                                            editingAutomation.created
                                                        ).format('LL')}
                                                    </p>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </SectionHeader>
                                </div>

                                <Row>
                                    <Col>
                                        <div className={'block'}>
                                            <h3>Average score</h3>
                                            <p className="mtb20">
                                                Your overall training programs'
                                                average score is{' '}
                                                <strong>
                                                    {defaultZero(score).toFixed(
                                                        0
                                                    )}
                                                    /100.{' '}
                                                </strong>
                                            </p>
                                            <Row className="progress-with-number">
                                                <Col md="10">
                                                    <InlineProgressBar
                                                        customcolor={calculateColor(
                                                            score
                                                        )}
                                                        value={score}
                                                        big="true"
                                                    />
                                                </Col>
                                                <Col md="2">
                                                    <Heading3
                                                        my={24}
                                                        color={calculateColor(
                                                            score
                                                        )}
                                                    >
                                                        {defaultZero(
                                                            score
                                                        ).toFixed(0)}
                                                        /100
                                                    </Heading3>
                                                </Col>
                                            </Row>
                                            <br />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className={'block'}>
                                            <h3>Attendance </h3>

                                            <p className="mtb20">
                                                <strong>
                                                    {
                                                        editingAutomation
                                                            ._rawData.opened
                                                    }{' '}
                                                    employees logged in
                                                </strong>{' '}
                                                out of{' '}
                                                {
                                                    editingAutomation._rawData
                                                        .totalUsers
                                                }
                                            </p>
                                            <Row>
                                                <Col md="10">
                                                    <InlineProgressBar
                                                        customcolor={calculateColor(
                                                            attendance
                                                        )}
                                                        value={attendance}
                                                        big="true"
                                                    />
                                                </Col>
                                                <Col md="2">
                                                    <Heading3
                                                        my={24}
                                                        color={calculateColor(
                                                            attendance
                                                        )}
                                                    >
                                                        {
                                                            editingAutomation
                                                                ._rawData.opened
                                                        }
                                                        /
                                                        {
                                                            editingAutomation
                                                                ._rawData
                                                                .totalUsers
                                                        }
                                                    </Heading3>
                                                </Col>
                                            </Row>

                                            <br />
                                        </div>
                                    </Col>
                                </Row>
                                <div className="block">
                                    <SubjectsProgress
                                        training={editingAutomation._rawData}
                                        subjects={
                                            editingAutomation._rawData.subjects
                                        }
                                        reminders={
                                            editingAutomation._rawData
                                                .reminders_steps
                                        }
                                        userCount={
                                            editingAutomation._rawData
                                                .totalUsers
                                        }
                                    />

                                    <ListsProgress
                                        lists={editingAutomation._rawData.lists}
                                    />

                                    <UserDetails
                                        actionsEnabled={false}
                                        title={'Employees report'}
                                        searchable={false}
                                        paginated={false}
                                        onDeleteUser={this.onDeleteUser}
                                        users={editingAutomation._rawData.users}
                                        training={editingAutomation}
                                        ssoEnabled={false}
                                        detailsRootUrl={`/trainings/${editingAutomation.id}/user/`}
                                    />
                                </div>
                            </Fragment>
                        ) : (
                            <div> Automation Not Found</div>
                        )}
                    </Fragment>
                )}
            </div>
        );
    }
}

export default withRouter(TrainingReportContainer);
