import React, { Fragment, useState, useEffect, useMemo } from 'react';
import {
    Link,
    Switch,
    Route,
    Redirect,
    matchPath,
    useHistory,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Container, Alert } from 'reactstrap';
import { observer } from 'mobx-react';
import '../css/index.scss';
import { useStores } from '../hooks/use-stores';
import { formatDate } from '../utils/helpers';
import classnames from 'classnames';
import {
    ThemedButton,
    ThemedNavItem,
    ThemedNavLink,
    ThemedNav,
} from '../components/themed/ThemedComponents';
import ThemedDropdownNavItem from '../components/themed/ThemedDropdownNavItem';
import {
    FlexDiv,
    SpaceBetweenDiv,
} from '../components/design-system/containers';
import { TextDefault, TextTiny } from '../components/design-system/texts';
import {
    Menu,
    MenuItem,
    Divider,
    Avatar,
    Drawer,
    IconButton,
} from '@material-ui/core';
import { colors } from '../components/design-system/colors';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuIcon from '@material-ui/icons/Menu';
import { rgba } from 'polished';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
    styledAvatar: {
        height: 32,
        width: 32,
        fontSize: 12,
        fontWeight: 800,
        color: rgba(colors.heavyMetal, 0.6),
        backgroundColor: colors.heavyMetalLight,
    },
    showOnSmallScreen: {
        display: 'none',
        ['@media screen and (max-width: 1200px)']: {
            display: 'flex',
        },
    },
    hideOnSmallScreen: {
        display: 'flex',
        ['@media screen and (max-width: 1200px)']: {
            display: 'none',
        },
    },
    header: {
        padding: '0 48px',
        ['@media screen and (max-width: 1200px)']: {
            padding: '0 8px',
        },
    },
    hideOnMobile: {
        display: 'flex',
        ['@media screen and (max-width: 440px)']: {
            display: 'none',
        },
    },
    softShadow: {
        '& .MuiPaper-elevation8': {
            boxShadow:
                '0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(29, 30, 29, 0.08)',
        },
    },
}));

const Logo = styled.div`
    img {
        max-height: 40px;
        max-width: 130px;
    }
    position: relative;
    margin-right: 32px;
    :before {
        position: absolute;
        content: 'Admin';
        top: 28px;
        padding: 0px;
        border-radius: 2px;
        background: rgba(#ffffff, 0.1);
        right: 0px;
        font-weight: 700;
        font-size: 12px;
        color: ${colors.heavyMetal};
        cursor: pointer;
    }
`;

function Layout({ children, handleSignout }) {
    const { store, authStore, brandingStore } = useStores();

    const partnerViewSelected = !!matchPath(pathname, '/partner');

    const {
        logo,
        favicon,
        account_name,
        name,
        helpUrl,
        privacyUrl,
        resourcesUrl,
        supportEmail,
        brandingLoaded,
        myDomain,
    } = brandingStore;
    const { isAuthenticated, currentUser, isPartnerAdmin } = authStore;
    const {
        serverData,
        currentCompany,
        isTrial,
        haveValidPackage,
        havePartnerSubscription,
        assessmentEnabled,
        trainingEnabled,
    } = store;

    const [tooManyUsersBannerVisible, setTooManyUsersBannerVisible] =
        useState(true);
    const [notification, setNotification] = useState(false);
    const [notificationType, setNotificationType] = useState('warning');
    const [notificationMessage, setNotificationMessage] =
        useState('All is good');

    let history = useHistory();
    const pathname = history.location.pathname;
    const classes = useStyles();

    const getCurrentPartnerId = () => {
        let split = pathname.split('/');
        const partnerIndex = split.indexOf('partner');
        const partnerId = partnerIndex > 0 ? split[partnerIndex + 1] : null;
        return partnerId;
    };

    const loadResources = () => {
        fetch('https://resources.awarego.com/notification-lms.json')
            .then((response) => response.json())
            .then((jsonData) => {
                setNotification(jsonData.status);
                setNotificationType(jsonData.type);
                setNotificationMessage(jsonData.message);
            })
            .catch((error) => {
                console.log('loaded notification failed');
                console.error(error);
            });
    };

    const selectedNavSection = () => {
        if (pathname.toLowerCase().startsWith('/users')) return 'users';
        else if (pathname.toLowerCase().startsWith('/trainings'))
            return 'trainings';
        else if (pathname.toLowerCase().startsWith('/catalog'))
            return 'catalog';
        else if (pathname.toLowerCase().startsWith('/settings'))
            // don't select any tab when on settings page as its not on the main menu
            return 5;
        else if (pathname.toLowerCase().startsWith('/resources'))
            return 'resources';
        else if (pathname.toLowerCase().startsWith('/assessment'))
            return 'assessment';
        else if (pathname.toLowerCase().startsWith('/reports'))
            return 'reports';
        return '';
    };

    const selectedPartnerNavSection = () => {
        if (pathname.toLowerCase().endsWith('/resources/')) return 'resources';
        return 'companies';
    };

    const onChangeCompany = async (id) => {
        handleUserMenuClose();
        await store.setCurrentCompany(id);

        //Route to '/' if in partner view
        if (partnerViewSelected) {
            history.push(`/`);
        }
    };

    const updateFavicon = (icon) => {
        const favicon = document.getElementById('favicon');
        favicon.href = icon;
        return true;
    };

    const getInitials = (name) => {
        if (!name) {
            return '';
        }
        return name.split(' ').map((n) => n[0]);
    };

    useEffect(() => {
        loadResources();
    }, []);

    const selectedSection = selectedNavSection();
    const partnerSelectedSection = selectedPartnerNavSection();
    const currentUserInitials = currentUser
        ? getInitials(currentUser.name)
        : '';
    const contentVerticallyCentered = !isAuthenticated;
    let date = new Date();
    const partnerIdSelected = getCurrentPartnerId();

    const defaultTabs = useMemo(
        () => [
            {
                code: '',
                title: 'Dashboard',
                enabled: true,
            },
            {
                code: 'trainings',
                title: 'Training programs',
                enabled: trainingEnabled,
            },
            {
                code: 'assessment',
                title: 'Assessments',
                enabled: assessmentEnabled,
            },
            {
                code: 'users',
                title: 'Employees',
                enabled: true,
            },
            {
                code: 'catalog',
                title: 'Subjects',
                enabled: trainingEnabled,
            },
            {
                code: 'reports',
                title: 'Reports',
                enabled: true,
            },
            {
                code: 'resources',
                title: 'Resources',
                enabled: true,
                customLink: resourcesUrl,
            },
            {
                code: 'help',
                title: 'Help',
                enabled: true,
                customLink: helpUrl,
            },
        ],
        [trainingEnabled, assessmentEnabled, resourcesUrl, helpUrl]
    );

    const partnerTabs = [
        {
            code: 'companies',
            title: 'Companies',
            to: '/partner/' + partnerIdSelected + '/companies',
        },
        {
            code: 'resources',
            title: 'Resources',
            to: '/partner/' + partnerIdSelected + '/resources',
        },
    ];

    const logoContainer = (
        <FlexDiv column alignEnd>
            {(brandingLoaded && account_name !== 'AwareGo') ||
            isAuthenticated ? (
                <Link to="/">
                    <img src={logo} alt={'My ' + account_name} />
                </Link>
            ) : (
                <a href="https://www.awarego.com">
                    <img src={logo} alt={account_name} />
                </a>
            )}
        </FlexDiv>
    );

    const [userMenuAnchor, setUserMenuAnchor] = useState(null);
    const handleUserMenuToggle = (event) => {
        setUserMenuAnchor(event.currentTarget);
    };
    const handleUserMenuClose = () => {
        setUserMenuAnchor(null);
    };

    const handleMobileMenuToggle = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const menuContainer = (isMobileMenu) =>
        isAuthenticated && (
            <Switch>
                <Route
                    path="/ppv"
                    render={() => (
                        <ThemedNav clean mobileMenu={isMobileMenu}>
                            <ThemedNavItem active mobileMenu={isMobileMenu}>
                                <ThemedNavLink
                                    top="top"
                                    as={Link}
                                    to="/ppv"
                                    mobileMenu={isMobileMenu}
                                >
                                    Embed
                                </ThemedNavLink>
                            </ThemedNavItem>
                        </ThemedNav>
                    )}
                />
                <Route
                    path="/partner"
                    render={() => (
                        <ThemedNav clean mobileMenu={isMobileMenu}>
                            {partnerTabs.map((t) => (
                                <ThemedNavItem
                                    active={partnerSelectedSection === t.code}
                                    key={t.code}
                                    mobileMenu={isMobileMenu}
                                >
                                    <ThemedNavLink
                                        to={t.to}
                                        top="top"
                                        as={Link}
                                        mobileMenu={isMobileMenu}
                                    >
                                        {t.title}
                                    </ThemedNavLink>
                                </ThemedNavItem>
                            ))}
                        </ThemedNav>
                    )}
                />
                <Route
                    render={() => {
                        return (
                            <ThemedNav clean mobileMenu={isMobileMenu}>
                                {defaultTabs.map((t) =>
                                    t.enabled ? (
                                        t.customLink === `/${t.code}` ||
                                        !t.customLink ? (
                                            <ThemedNavItem
                                                active={
                                                    selectedSection === t.code
                                                }
                                                key={t.code}
                                                mobileMenu={isMobileMenu}
                                            >
                                                <ThemedNavLink
                                                    to={`/${t.code}`}
                                                    top="top"
                                                    as={Link}
                                                    mobileMenu={isMobileMenu}
                                                >
                                                    {t.title}
                                                </ThemedNavLink>
                                            </ThemedNavItem>
                                        ) : (
                                            <ThemedNavItem
                                                key={t.code}
                                                mobileMenu={isMobileMenu}
                                            >
                                                <ThemedNavLink
                                                    href={t.customLink}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                    top="top"
                                                    mobileMenu={isMobileMenu}
                                                >
                                                    {t.title}
                                                </ThemedNavLink>
                                            </ThemedNavItem>
                                        )
                                    ) : null
                                )}
                            </ThemedNav>
                        );
                    }}
                />
            </Switch>
        );

    return (
        <Fragment>
            {brandingLoaded && updateFavicon(favicon) && (
                <Helmet
                    titleTemplate={'%s | ' + account_name + ' LMS'}
                    defaultTitle={`${account_name} LMS`}
                />
            )}
            <div
                className={classnames('layout_wrapper', {
                    ['full_height']: contentVerticallyCentered,
                })}
            >
                {!isAuthenticated ? (
                    <SpaceBetweenDiv padding={24}>
                        <Logo>{logoContainer}</Logo>
                    </SpaceBetweenDiv>
                ) : (
                    <SpaceBetweenDiv
                        backgroundColor={'white'}
                        minHeight={70}
                        className={classes.header}
                    >
                        <FlexDiv alignCenter>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleMobileMenuToggle}
                                className={classes.showOnSmallScreen}
                            >
                                <MenuIcon color="secondary" />
                            </IconButton>
                            <Logo>{logoContainer}</Logo>
                            {isPartnerAdmin &&
                                !currentCompany &&
                                !partnerViewSelected() && (
                                    <Redirect to={'/partner'} />
                                )}
                            <Drawer
                                variant="temporary"
                                open={mobileMenuOpen}
                                onClose={handleMobileMenuToggle}
                                ModalProps={{
                                    keepMounted: true,
                                }}
                                className={classes.showOnSmallScreen}
                            >
                                {menuContainer(true)}
                            </Drawer>
                            <FlexDiv className={classes.hideOnSmallScreen}>
                                {menuContainer(false)}
                            </FlexDiv>
                        </FlexDiv>
                        {currentCompany && (
                            <Fragment>
                                <FlexDiv
                                    onClick={handleUserMenuToggle}
                                    link
                                    alignCenter
                                >
                                    <FlexDiv
                                        column
                                        ml={16}
                                        className={classes.hideOnMobile}
                                        maxWidth={143}
                                        noWrap
                                    >
                                        <TextDefault bold cutLongText>
                                            {currentUser.name ||
                                                currentUser.email}
                                        </TextDefault>
                                        <TextTiny light cutLongText>
                                            <Switch>
                                                <Route
                                                    path="/partner"
                                                    render={() => account_name}
                                                />
                                                <Route
                                                    render={() =>
                                                        currentCompany.company_name
                                                    }
                                                />
                                            </Switch>
                                        </TextTiny>
                                    </FlexDiv>
                                    <FlexDiv ml={8} mr={4}>
                                        <Avatar
                                            className={classes.styledAvatar}
                                            alt="current user initials"
                                        >
                                            {currentUserInitials}
                                        </Avatar>
                                    </FlexDiv>

                                    <ArrowDropDownIcon
                                        fontSize="small"
                                        color="disabled"
                                    />
                                </FlexDiv>
                                <Menu
                                    anchorEl={userMenuAnchor}
                                    open={Boolean(userMenuAnchor)}
                                    onClick={handleUserMenuClose}
                                    className={classes.softShadow}
                                >
                                    {serverData &&
                                        serverData.map((comp) => (
                                            <MenuItem
                                                key={comp.company_id}
                                                onClick={() =>
                                                    onChangeCompany(
                                                        comp.company_id
                                                    )
                                                }
                                                selected={
                                                    !partnerViewSelected &&
                                                    currentCompany.company_id ===
                                                        comp.company_id
                                                }
                                            >
                                                <ThemedDropdownNavItem
                                                    selected={
                                                        !partnerViewSelected &&
                                                        currentCompany.company_id ===
                                                            comp.company_id
                                                    }
                                                    href={myDomain}
                                                    text={comp.company_name}
                                                />
                                            </MenuItem>
                                        ))}
                                    <Divider />
                                    {isPartnerAdmin &&
                                        currentUser.superForAccounts.map(
                                            (x) => (
                                                <MenuItem
                                                    key={x.id}
                                                    selected={
                                                        x.id ==
                                                        partnerIdSelected
                                                    }
                                                    component={Link}
                                                    to={`/partner/${x.id}`}
                                                >
                                                    <ThemedDropdownNavItem
                                                        selected={
                                                            x.id ==
                                                            partnerIdSelected
                                                        }
                                                        onClick={() => {
                                                            handleUserMenuClose();
                                                        }}
                                                        text={`${x.name} Companies`}
                                                    />
                                                </MenuItem>
                                            )
                                        )}
                                    {isPartnerAdmin && <Divider />}
                                    <MenuItem component={Link} to="/">
                                        <ThemedDropdownNavItem
                                            text="Admin view"
                                            selected={
                                                !pathname.includes('/ppv')
                                            }
                                        />
                                    </MenuItem>
                                    <MenuItem component="a" href={myDomain}>
                                        <ThemedDropdownNavItem text="Employee view" />
                                    </MenuItem>
                                    <MenuItem component={Link} to="/ppv">
                                        <ThemedDropdownNavItem
                                            text="Embed content"
                                            selected={pathname.includes('/ppv')}
                                        />
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem component={Link} to="/settings">
                                        <ThemedDropdownNavItem
                                            text="Settings"
                                            selected={pathname.includes(
                                                '/settings'
                                            )}
                                            isSettings
                                        />
                                    </MenuItem>
                                    <MenuItem
                                        component={Link}
                                        to="/"
                                        onClick={() => handleSignout()}
                                    >
                                        <ThemedDropdownNavItem
                                            text={
                                                <TextDefault error bold>
                                                    Sign out
                                                </TextDefault>
                                            }
                                            isSignOut
                                        />
                                    </MenuItem>
                                </Menu>
                            </Fragment>
                        )}
                    </SpaceBetweenDiv>
                )}
                {isAuthenticated && (
                    <Switch>
                        {/*Not show upgrade in ppv & partner routes*/}
                        <Route path="/ppv" render={() => {}} />
                        <Route path="/partner" render={() => {}} />
                        <Route
                            render={() => (
                                <Fragment>
                                    {!haveValidPackage && (
                                        <FlexDiv
                                            justifyCenter
                                            alignCenter
                                            backgroundColor={
                                                colors.contentYellow
                                            }
                                            padding={8}
                                        >
                                            {!havePartnerSubscription ? (
                                                <>
                                                    <TextDefault bold>
                                                        Your free trial has
                                                        expired.{' '}
                                                        {currentCompany.chargebeeEnabled && (
                                                            <Fragment>
                                                                Please{' '}
                                                                <Link to="/subscriptions/upgrade">
                                                                    upgrade{' '}
                                                                </Link>{' '}
                                                                your {name}{' '}
                                                                subscription.
                                                            </Fragment>
                                                        )}{' '}
                                                        Need more time?
                                                        Contact&nbsp;
                                                        <TextDefault
                                                            bold
                                                            underline
                                                        >
                                                            <a
                                                                href={`mailto:${supportEmail}`}
                                                            >
                                                                {supportEmail}
                                                            </a>
                                                        </TextDefault>
                                                    </TextDefault>
                                                </>
                                            ) : (
                                                <TextDefault bold>
                                                    Your subscription has
                                                    expired. Please
                                                    contact&nbsp;
                                                    <TextDefault bold underline>
                                                        <a
                                                            href={`mailto:${supportEmail}`}
                                                        >
                                                            {supportEmail}
                                                        </a>
                                                    </TextDefault>
                                                    &nbsp;to upgrade.
                                                </TextDefault>
                                            )}
                                        </FlexDiv>
                                    )}
                                    {currentCompany &&
                                        currentCompany.usersLeft < 0 && (
                                            <Alert
                                                color="warning"
                                                isOpen={
                                                    tooManyUsersBannerVisible
                                                }
                                                toggle={() =>
                                                    setTooManyUsersBannerVisible(
                                                        false
                                                    )
                                                }
                                            >
                                                <TextDefault bold>
                                                    You have registered more
                                                    employees (
                                                    {currentCompany.users_total}
                                                    ) than your subscription
                                                    package allows (
                                                    {
                                                        currentCompany.users_available
                                                    }
                                                    ).
                                                </TextDefault>
                                                <br />
                                                <TextDefault bold>
                                                    {currentCompany.chargebeeEnabled ? (
                                                        <Fragment>
                                                            <Link to="/settings/subscriptions">
                                                                Change
                                                                subscription
                                                            </Link>{' '}
                                                            or{' '}
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>
                                                            You can contact your
                                                            reseller at{' '}
                                                            <a
                                                                href={`mailto:${supportEmail}`}
                                                            >
                                                                {supportEmail}
                                                            </a>{' '}
                                                            or
                                                        </Fragment>
                                                    )}{' '}
                                                    <Link to="/users">
                                                        {' '}
                                                        Edit employee lists
                                                    </Link>
                                                </TextDefault>
                                            </Alert>
                                        )}
                                </Fragment>
                            )}
                        />
                    </Switch>
                )}
                {isAuthenticated && isTrial && currentCompany && (
                    <Switch>
                        {/*Don't show upgrade message in partner routes*/}
                        <Route
                            path="/partner"
                            render={() => {
                                return <Fragment />;
                            }}
                        />
                        <Route
                            path="/subscriptions/upgrade"
                            render={() => {
                                return <Fragment />;
                            }}
                        />
                        <Route
                            render={() => {
                                return (
                                    <FlexDiv
                                        justifyCenter
                                        alignCenter
                                        backgroundColor={colors.contentYellow}
                                        padding={6}
                                    >
                                        <TextDefault bold>
                                            Trial expires on{' '}
                                            {formatDate(
                                                currentCompany.trial_ends
                                            )}
                                            .
                                        </TextDefault>
                                        {currentCompany.chargebeeEnabled && (
                                            <ThemedButton
                                                black
                                                tiny
                                                noTextTransform
                                                airy
                                                as={Link}
                                                to={'/subscriptions/upgrade'}
                                            >
                                                Upgrade now
                                            </ThemedButton>
                                        )}
                                    </FlexDiv>
                                );
                            }}
                        />
                        )
                    </Switch>
                )}
                {notification && (
                    <Alert color={notificationType}>
                        {notificationMessage}
                    </Alert>
                )}
                <Container
                    className={classnames('', {
                        'd-flex': contentVerticallyCentered,
                    })}
                >
                    {children}
                </Container>
                <footer>
                    <FlexDiv justifyCenter my={24} alignCenter>
                        <TextTiny lighter>
                            <a
                                href={privacyUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </a>{' '}
                            &nbsp;&#183; © {date.getFullYear()}{' '}
                            {(brandingLoaded && account_name) || 'AwareGO'}
                        </TextTiny>
                    </FlexDiv>
                </footer>
            </div>
        </Fragment>
    );
}

export default observer(Layout);
