import React from 'react';
import { observer } from 'mobx-react';
import { FormGroup, Label, FormText } from 'reactstrap';
import HtmlField from '../../inputs/htmlField';

function IntroMessage({ form }) {
    if (!form) return;
    return (
        <div className="form-email">
            <FormGroup>
                <Label for="subject">Subject</Label>
                <input
                    type="text"
                    name="subject"
                    className={
                        form.$('subject').isValid
                            ? 'bigInput valid'
                            : 'bigInput dark'
                    }
                    {...form.$('subject').bind()}
                />
                <div className="error">{form.$('subject').error}</div>
            </FormGroup>
            <FormGroup>
                <Label for="message">
                    Message
                    <FormText>
                        Add a personal message, letting your recipients know why
                        they're receiving this training program so they don't
                        consider it as spam or phishing. An e-mail link will be
                        sent to each user, no password needed.
                    </FormText>
                </Label>
                <HtmlField
                    field={form.$('message')}
                    mrkdownField={form.$('mrkdwn')}
                />
                <div className="error">{form.$('message').error}</div>
            </FormGroup>
        </div>
    );
}

export default observer(IntroMessage);
