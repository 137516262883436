import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

@inject('superStore', 'store')
@observer
class PartnerResourcesContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resourcesTextContent: null,
        };
    }

    async componentDidMount() {
        /*let contentObject = await this.getTextContent();
if (contentObject) {
    this.setState({resourcesTextContent:contentObject.content.rendered, fetchingData: false});
}*/
    }

    async getTextContent() {
        const url = 'https://www.awarego.com/wp-json/wp/v2/pages/10060';
        let res = await fetch(url);
        return await res.json();
    }

    //This used to be pulled from a wordpress blog post, but set as static for now after syndis security audit flagged displaying wp content directly
    render() {
        return (
            <div className="row">
                <div className="block resource-content-partners">
                    <div>
                        <h1>Welcome to AwareGO’s partner page</h1>
                        <hr />
                        The purpose of this page is to provide you as our valued
                        partner with materials and information to support your
                        partnering work with us.
                        <hr />
                        <h2>Support</h2>
                        <ul>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://help.awarego.com/"
                                >
                                    Help Center
                                </a>
                            </li>
                            <li>
                                Contact support:{' '}
                                <a href="mailto:support@awarego.com">
                                    support@awarego.com
                                </a>
                            </li>
                        </ul>
                        <hr />
                        <h2>Videos</h2>
                        <ul>
                            <li>
                                AwareGO has numerous public videos on its{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.youtube.com/channel/UChtxTs0Bg57QK5IBdZTEeEw"
                                >
                                    Youtube channel
                                </a>{' '}
                                that you are welcome to use and embed into your
                                website
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.awarego.com/videos-2/"
                                >
                                    Access all our videos
                                </a>{' '}
                                for a quick overview.&nbsp; &nbsp;Please only
                                share this link with individual clients and not
                                on any public forums.
                            </li>
                        </ul>
                        <hr />
                        <h2>Onboarding</h2>
                        <ul>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://hubs.ly/H0DGdLt0"
                                >
                                    AwareGO Onboarding Guide
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://hubs.ly/H0DLHGY0"
                                >
                                    AwareGO Partner Admin Walkthrough
                                </a>
                            </li>
                        </ul>
                        <hr />
                        <h2>Sales materials</h2>
                        <ul>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://hubs.ly/H0mW_Tr0"
                                >
                                    Sample Sales Deck
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://hubs.ly/H0njlDs0"
                                >
                                    AwareGO Partners – Battle Card.pdf
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://hubs.ly/H0njlG40"
                                >
                                    AwareGO Partners - Discovery guide.pdf
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://hubs.ly/H0njlG50"
                                >
                                    AwareGO Partners – Business case.pdf
                                </a>
                            </li>
                        </ul>
                        <hr />
                        <h2>Marketing materials</h2>
                        <ul>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.awarego.com/wp-content/uploads/2020/01/AwareGO-SAT-platform-brochure.pdf"
                                >
                                    SAT platform brochure
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.awarego.com/wp-content/uploads/2020/01/Awarego-Content-brochure.pdf"
                                >
                                    Content brochure
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.figma.com/file/4hFlq2LwNpZBCW3F2qQK2Q/52-shades-of-cybersecurity-(shared)?node-id=0%3A1"
                                >
                                    52 shades of cybersecurity (Figma)
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.figma.com/file/wnQ2iF7SFSR2PTzjPL4EKb/AwareGO-Horoscope-(shared)?node-id=0%3A1"
                                >
                                    Horoscope (Figma)
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://cdn2.hubspot.net/hubfs/3950430/Threatlist-2019-1.pdf?utm_campaign=DPO%20-%20checklist&amp;utm_medium=email&amp;_hsenc=p2ANqtz-9pwGz7MmtcNHYiE5hQBc-9WGc_iQ0SLgGGkouCHaZcoYe_AIF-QLelR7Z714WwJGwj3w7KdLN_uyULFXXz4HFt1pRu3Q&amp;_hsmi=69919281&amp;utm_source=hs_automation&amp;utm_content=69919281&amp;hsCtaTracking=18c6df5c-0ea4-4438-985d-cea61c54832b%7Ce1a5525e-67e1-47e8-85ea-5f7857077f07"
                                >
                                    Cyber Security Threat List 2019
                                </a>
                            </li>
                            <li>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://lms.awarego.com/resources/"
                                >
                                    Security Awareness Posters
                                </a>
                            </li>
                            <li>
                                You are welcome to link to or repost{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.awarego.com/blog/"
                                >
                                    our blogs
                                </a>{' '}
                                on your website
                            </li>
                        </ul>
                        <hr />
                        <h2>Logo</h2>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.awarego.com/media-center/"
                        >
                            Here you can find all versions of our logo.
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PartnerResourcesContainer);
