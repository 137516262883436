import React, { Fragment } from 'react';
import pluralize from 'pluralize';
import { ThemedButton } from '../../themed/ThemedComponents';
import EmployeeListsOverview from './employee-lists-overview';
import SelectUsers from '../users/select-users-step';
import Modal from 'react-modal';

function SelectEmployees({
    itemString,
    item,
    lists,
    toggleUserListSelected,
    ongoing,
    addEmployeeModal,
    showAddEmployeeModal,
    hideAddEmployeeModal,
    recipientsModal,
    showRecipientsModal,
    hideRecipientsModal,
    error,
    loadingUsers,
    requestUsersForList,
    selectedLists,
    selectedUserCount,
    disabled,
    createRecipientsList,
    currentCompany,
}) {
    return (
        <Fragment>
            <div className="training-wizard-body-header">
                <div>
                    <h3>Select employees for this {itemString}</h3>
                    <p>
                        {' '}
                        {item.selectedUserCount}{' '}
                        {pluralize('employee', item.selectedUserCount)} selected
                    </p>
                </div>
                {item.selectedUserCount > 0 && (
                    <ThemedButton onClick={showAddEmployeeModal} secondary>
                        Select employees
                    </ThemedButton>
                )}
            </div>
            <div className="table">
                {lists.length > 0 ? (
                    item.selectedUserCount > 0 ? (
                        <EmployeeListsOverview
                            lists={item.userListsSelected}
                            onRemoveList={toggleUserListSelected}
                            item={item}
                            ongoing={ongoing}
                            itemString={itemString}
                            currentCompany={currentCompany}
                        />
                    ) : (
                        <div className="training-wizard-content center">
                            <img
                                src="/static/img/icon-user-list.svg"
                                alt="No employees selected yet"
                            />
                            <div className="stats">
                                <h5>No employees selected yet</h5>
                                <p>
                                    Select employees who you want to send this{' '}
                                    {itemString} to.
                                </p>
                            </div>
                            <ThemedButton
                                onClick={showAddEmployeeModal}
                                secondary
                            >
                                Select employees
                            </ThemedButton>
                        </div>
                    )
                ) : (
                    <div className="training-wizard-content center">
                        <img
                            src="/static/img/icon-user-list.svg"
                            alt="No employee lists yet"
                        />
                        <div className="stats">
                            <h5>No employee lists yet</h5>
                            <p>
                                Please create a list of employees who you want
                                to send this {itemString} to.
                            </p>
                        </div>
                        <ThemedButton onClick={showRecipientsModal} secondary>
                            Create new list
                        </ThemedButton>
                    </div>
                )}
            </div>
            <Modal
                isOpen={addEmployeeModal}
                onRequestClose={hideAddEmployeeModal}
                className="Modal schedule"
            >
                <SelectUsers
                    lists={lists}
                    showRecipientsModal={showRecipientsModal}
                    recipientsModal={recipientsModal}
                    hideRecipientsModal={hideRecipientsModal}
                    createRecipientsList={createRecipientsList}
                    item={item}
                    error={error}
                    loadingUsers={loadingUsers}
                    requestUsersForList={requestUsersForList}
                    selectedLists={selectedLists}
                    selectedUserCount={selectedUserCount}
                    onUserListSelected={toggleUserListSelected}
                    hideAddEmployeeModal={hideAddEmployeeModal}
                    disabled={disabled}
                    ongoing={ongoing}
                    currentCompany={currentCompany}
                />
            </Modal>
        </Fragment>
    );
}

export default SelectEmployees;
