import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import SubjectLanguage from './SubjectLanguage';
import { Link } from 'react-router-dom';
import { enforceSlash } from '../../utils/helpers';
import ACLEditor from './ACLEditor';

export default observer(({ subjectId, language, parentMatch }) => {
    const { superStore } = useStores();

    useEffect(() => {
        (async () => {
            await superStore.loadSubject(subjectId);
            await superStore.loadSubjectLanguages(subjectId);
        })();
    }, [subjectId]);
    const { editingSubject, subjectsError, editingSubjectLanguages } =
        superStore;
    language = language || 'en';

    return !editingSubject ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <ErrorMessage error={subjectsError} />

            <div className="top-guide">
                <div className="block new-settings-block">
                    <div className="main-content">
                        <h2>{editingSubject.episode_number}</h2>
                        <div className="padded-container">
                            <img
                                src={editingSubject.thumb}
                                style={{ maxWidth: 120, margin: 4 }}
                                alt="thumb"
                            />
                            <h5>Templates:</h5>
                            <p>{editingSubject.templates.join()}</p>
                            <h5>Categories:</h5>
                            <p>{editingSubject.categories.join()}</p>
                        </div>
                    </div>

                    <div className="main-content">
                        <h3>Available Languages:</h3>
                        <div className="padded-container">
                            {editingSubjectLanguages &&
                                editingSubjectLanguages.languages && (
                                    <div>
                                        <Nav tabs>
                                            {Object.keys(
                                                editingSubjectLanguages.languages
                                            ).map((x) => (
                                                <NavItem
                                                    key={x}
                                                    active={x === language}
                                                >
                                                    {x === language ? (
                                                        <NavLink>{x}</NavLink>
                                                    ) : (
                                                        <NavLink
                                                            tag={Link}
                                                            to={`${enforceSlash(
                                                                parentMatch.url
                                                            )}subjects/${subjectId}/${x}`}
                                                        >
                                                            {x}
                                                        </NavLink>
                                                    )}
                                                </NavItem>
                                            ))}
                                        </Nav>
                                        <SubjectLanguage
                                            subject={
                                                editingSubjectLanguages
                                                    .languages[language]
                                            }
                                            question={
                                                editingSubjectLanguages
                                                    .questions[language]
                                            }
                                            content={
                                                editingSubjectLanguages
                                                    .contents[language]
                                            }
                                        />
                                    </div>
                                )}
                        </div>
                    </div>

                    <div className="main-content">
                        <Row>
                            <Col md="12" xs="12" className="leftMenu">
                                <h3>Access control</h3>
                                <div className="padded-container">
                                    <ACLEditor
                                        acl={editingSubject.acl}
                                        unique_id={editingSubject.unique_id}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </Fragment>
    );
});
