import React, { Fragment, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import CompanyAppearanceEditor from './CompanyAppearanceEditor';
import EditLogo from './edit-logo';
import EditColor from './edit-color';
import { TextLead, TextDefault } from '../design-system/texts';
import { BigModal } from '../design-system/modals';
import { ThemedButton } from '../themed/ThemedComponents';
import { colors } from '../design-system/colors';
import { useStores } from '../../hooks/use-stores';
import ThemedSpinner from '../themed/ThemedSpinner';

function CustomizeAppearance({
    company,
    saveColor,
    resetToDefaults,
    importLogo,
    removeLogo,
    uploadingLogo,
}) {
    const [showEditLogoModal, setShowEditLogoModal] = useState(false);
    const [showEditColorModal, setShowEditColorModal] = useState(false);
    const [primaryColor, setPrimaryColor] = useState(colors.aspargus);

    const { commonStore } = useStores();

    useEffect(() => {
        setPrimaryColor(company.color_main || colors.aspargus);
    }, [company]);

    const doSaveColor = () => {
        setShowEditColorModal(false);
        saveColor(primaryColor);
    };

    const showResetToDefaultsModal = () => {
        commonStore.showConfirm(
            <TextLead>
                You will lose all entered data and we will not be able to
                recover it.
            </TextLead>,
            'Yes, reset',
            'Reset company appearance to defaults',
            async () => {
                const result = await resetToDefaults();
                if (result) commonStore.success('Appearance has been reset!');
            },
            true
        );
    };

    return !company ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <CompanyAppearanceEditor
                company={company}
                onEditLogo={() => setShowEditLogoModal(true)}
                onEditColor={() => setShowEditColorModal(true)}
                onResetToDefaults={() => showResetToDefaultsModal()}
            />
            <BigModal
                isOpen={showEditLogoModal}
                onRequestClose={() => setShowEditLogoModal(false)}
                heading="Change logo"
                modalContent={
                    <EditLogo
                        company={company}
                        companyId={company.company_id}
                        onSaved={() => setShowEditLogoModal(false)}
                        onRequestClose={() => setShowEditLogoModal(false)}
                        importLogo={importLogo}
                        removeLogo={removeLogo}
                        uploadingLogo={uploadingLogo}
                    />
                }
                modalFooterContent={
                    uploadingLogo ? (
                        <ThemedSpinner />
                    ) : (
                        <ThemedButton
                            primary
                            airy
                            onClick={() => setShowEditLogoModal(false)}
                        >
                            Done
                        </ThemedButton>
                    )
                }
            />
            <BigModal
                isOpen={showEditColorModal}
                onRequestClose={() => setShowEditColorModal(false)}
                heading="Change color"
                modalContent={
                    <EditColor
                        primaryColor={primaryColor}
                        setPrimaryColor={setPrimaryColor}
                    />
                }
                modalFooterContent={
                    <Fragment>
                        <TextDefault
                            uppercase
                            extraBold
                            link
                            onClick={() => setShowEditColorModal(false)}
                        >
                            Cancel
                        </TextDefault>
                        <ThemedButton
                            primary
                            airy
                            onClick={() => doSaveColor()}
                        >
                            Save
                        </ThemedButton>
                    </Fragment>
                }
            />
        </Fragment>
    );
}

export default observer(CustomizeAppearance);
