import { action, observable, computed } from 'mobx';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import intersection from 'lodash/intersection';
import flatten from 'lodash/flatten';
import pick from 'lodash/pick';
import services from '../services';
import Automation from './models/automation';
import { extractErrorMessage } from '../utils/helpers';
import { AUTOMATION_WIZARD_STEPS, STATUS } from '../utils/constants';
import moment from 'moment';

const activeStatuses = { [STATUS.SCHEDULED]: 1, [STATUS.ONGOING]: 1 };
const completedStatuses = { [STATUS.COMPLETED]: 1 };

class AutomationStore {
    @observable automations = observable([]);
    @observable loadingAutomations = false;
    @observable loadingAutomationsInBackground = false;
    @observable loadingAutomation = false;
    @observable loadingAutomationStatus = false;
    @observable loadingAutomationUserDetails = false;
    @observable loadingCompanyData = false;
    @observable loadingReminderCounter = false;

    @observable reminderCounter = 0;
    @observable lastReminders = observable([]);
    @observable sendAutomationEmails = observable([]);
    @observable perUserDetails = observable([]);
    @observable questions = observable([]);
    @observable templates = observable([]);
    @observable creatingAutomation = false;
    @observable updatingAutomation = false;
    @observable sendError = null;
    @observable sendingPreview = false;
    @observable automation = undefined;
    @observable automationStatus = undefined;
    @observable automationUserStatus = undefined;
    @observable editingAutomation = undefined;
    @observable loading = false;
    @observable step = AUTOMATION_WIZARD_STEPS.GOAL;
    @observable selectedTemplates = observable([]);
    @observable templateCustomized = false;
    @observable allowedSteps = observable([]);
    @observable confirmedSteps = observable([]);
    @observable error = '';
    @observable dismissedFirstTraining = false;
    @observable perUserDetailsLoaded = false;
    @observable slackConnected = false;
    @observable teamsConnected = false;
    @observable searchValue = '';
    @observable subjectFilter = [];
    @observable employeeFilter = [];
    @observable fromEndDateFilter = null;
    @observable toEndDateFilter = null;
    @observable toStartDateFilter = null;
    @observable fromStartDateFilter = null;
    @observable sortBy = 'updated';

    constructor(listStore, catalogStore, mainStore, commonStore, companyStore) {
        this.listStore = listStore;
        this.catalogStore = catalogStore;
        this.mainStore = mainStore;
        this.commonStore = commonStore;
        this.companyStore = companyStore;
        this.dismissedFirstTraining = !!window.localStorage.getItem(
            'dismissedFirstTraining'
        );
    }

    @computed get filteredAutomations() {
        return orderBy(this.automations, [this.sortBy], ['desc']).filter(
            (x) => {
                // anyone has a suggestion how to write these filters better? :D
                if (
                    this.subjectFilter.length > 0 &&
                    intersection(
                        Object.keys(keyBy(x.subjectsNames, 'id')).map(Number),
                        this.subjectFilter
                    ).length === 0
                )
                    return false;

                if (
                    this.employeeFilter.length > 0 &&
                    x.lists !== null &&
                    intersection(x._rawData.lists, this.employeeFilter)
                        .length === 0
                )
                    return false;

                if (
                    this.searchValue.length > 0 &&
                    x.name !== null &&
                    !x.name
                        .toLowerCase()
                        .includes(this.searchValue.toLowerCase())
                )
                    return false;

                if (
                    this.fromStartDateFilter !== null &&
                    this.toStartDateFilter !== null &&
                    x._rawData.firstBatch !== null &&
                    !moment(x._rawData.firstBatch).isBetween(
                        this.fromStartDateFilter,
                        this.toStartDateFilter
                    )
                ) {
                    return false;
                }

                if (
                    this.fromEndDateFilter !== null &&
                    this.toEndDateFilter !== null &&
                    x._rawData.lastBatch !== null &&
                    !moment(x._rawData.lastBatch).isBetween(
                        this.fromEndDateFilter,
                        this.toEndDateFilter
                    )
                ) {
                    return false;
                }

                return true;
            }
        );
    }

    @computed get allTab() {
        return this.filteredAutomations;
    }

    @computed get activeTab() {
        return this.filteredAutomations.filter((x) => {
            return activeStatuses[x.status];
        });
    }

    @computed get completedTab() {
        return this.filteredAutomations.filter((x) => {
            return completedStatuses[x.status];
        });
    }

    @computed get draftTab() {
        return this.filteredAutomations.filter((x) => {
            return x.status === STATUS.DRAFT;
        });
    }

    @computed get steps() {
        return [
            {
                number: AUTOMATION_WIZARD_STEPS.GOAL,
                title:
                    this.automation && this.automation.status === STATUS.ONGOING
                        ? 'Template'
                        : 'Choose template',
            },
            {
                number: AUTOMATION_WIZARD_STEPS.CONTENT,
                title: 'Review Subjects',
            },
            {
                number: AUTOMATION_WIZARD_STEPS.EMPLOYEES,
                title: 'Select Employees',
            },
            { number: AUTOMATION_WIZARD_STEPS.SCHEDULE, title: 'Schedule' },
            { number: AUTOMATION_WIZARD_STEPS.REMINDERS, title: 'Reminders' },
            {
                number: AUTOMATION_WIZARD_STEPS.INTROMESSAGE,
                title: 'Intro Message',
            },
            { number: AUTOMATION_WIZARD_STEPS.FINALIZE, title: 'Summary' },
        ];
    }

    @action setSubjectFilter(subjectFilter) {
        this.subjectFilter = subjectFilter;
    }

    @action setSearchValue(searchValue) {
        this.searchValue = searchValue;
    }

    @action setEmployeeFilter(employeeFilter) {
        this.employeeFilter = employeeFilter;
    }

    @action setFromEndDateFilter(fromEndDateFilter) {
        this.fromEndDateFilter = fromEndDateFilter;
    }

    @action setToEndDateFilter(toEndDateFilter) {
        this.toEndDateFilter = toEndDateFilter;
    }

    @action setFromStartDateFilter(fromStartDateFilter) {
        this.fromStartDateFilter = fromStartDateFilter;
    }

    @action setToStartDateFilter(toStartDateFilter) {
        this.toStartDateFilter = toStartDateFilter;
    }

    @action setSortBy(sortBy) {
        this.sortBy = sortBy;
    }

    @action setStep(step) {
        this.error = '';
        this.step = step;
        this.commonStore.analyticsEvent(
            'Training-wizard',
            'step',
            `step-${step}`
        );
    }

    @action dismissFirstTraining() {
        this.dismissedFirstTraining = true;
        window.localStorage.setItem('dismissedFirstTraining', true);
    }

    @action setTemplate(templateId) {
        this.selectedTemplates = [templateId];
        let template = this.templates.find((x) => x.id === templateId);
        if (template) {
            this.automation.templateCustomized = false;
            this.commonStore.analyticsEvent(
                'Training-wizard',
                'choose-template',
                `template-${template.title}`
            );
            //A template contains both subjects and disabledSubjects
            this.automation.selectedVideosIds.replace(
                template.subjects.concat(template.disabledSubjects)
            );
            this.automation.setFrequency(template.frequency);
            this.automation.templateTip = template.tip;
        }

        if (this.selectedTemplates.length > 0) {
            if (!this.allowedSteps.includes(AUTOMATION_WIZARD_STEPS.CONTENT)) {
                this.allowedSteps.push(AUTOMATION_WIZARD_STEPS.CONTENT);
            }
        } else {
            this.allowedSteps.remove(AUTOMATION_WIZARD_STEPS.CONTENT);
        }
    }

    @action toggleFinalStep(form) {
        const enabled = this.automation && this.automation.introMessageEnabled;
        const messageEmpty =
            form &&
            (form.$('message').$value === '<p><br></p>' ||
                form.$('message').$value === '' ||
                form.$('subject').$value === '');

        if (
            this.allowedSteps.includes(AUTOMATION_WIZARD_STEPS.INTROMESSAGE) &&
            (!enabled || !messageEmpty)
        ) {
            if (!this.allowedSteps.includes(AUTOMATION_WIZARD_STEPS.FINALIZE))
                this.allowedSteps.push(AUTOMATION_WIZARD_STEPS.FINALIZE);
        } else {
            this.allowedSteps.remove(AUTOMATION_WIZARD_STEPS.FINALIZE);
        }
    }

    @action toggleRemindersStep() {
        if (
            (this.automation.scheduleType === 2 ||
                this.automation.channel !== 0) &&
            this.allowedSteps.includes(AUTOMATION_WIZARD_STEPS.SCHEDULE)
        ) {
            if (!this.allowedSteps.includes(AUTOMATION_WIZARD_STEPS.REMINDERS))
                this.allowedSteps.push(AUTOMATION_WIZARD_STEPS.REMINDERS);
        } else {
            this.allowedSteps.remove(AUTOMATION_WIZARD_STEPS.REMINDERS);
        }
    }

    @computed get reminderStepValid() {
        if (!this.automation) return false;

        if (!this.automation.reminders_enabled) return true;

        return (
            this.automation.reminders_steps.length > 0 &&
            this.automation.reminders_channel !== 0
        );
    }

    @action toggleIntroMessageStep() {
        if (
            this.reminderStepValid &&
            this.allowedSteps.includes(AUTOMATION_WIZARD_STEPS.REMINDERS)
        ) {
            if (
                !this.allowedSteps.includes(
                    AUTOMATION_WIZARD_STEPS.INTROMESSAGE
                )
            ) {
                this.allowedSteps.push(AUTOMATION_WIZARD_STEPS.INTROMESSAGE);
            }
        } else {
            this.allowedSteps.remove(AUTOMATION_WIZARD_STEPS.INTROMESSAGE);
        }
    }

    @action toggleRecipientsStep() {
        if (this.automation && this.automation.selectedVideosIds.length > 0) {
            if (!this.allowedSteps.includes(AUTOMATION_WIZARD_STEPS.EMPLOYEES))
                this.allowedSteps.push(AUTOMATION_WIZARD_STEPS.EMPLOYEES);
        }

        if (
            !this.automation ||
            this.automation.selectedVideosIds.length === 0
        ) {
            this.allowedSteps.remove(AUTOMATION_WIZARD_STEPS.EMPLOYEES);
        }
    }

    @action toggleScheduleStep() {
        if (
            this.automation &&
            this.automation.selectedUserCount > 0 &&
            !this.allowedSteps.includes(AUTOMATION_WIZARD_STEPS.SCHEDULE)
        )
            this.allowedSteps.push(AUTOMATION_WIZARD_STEPS.SCHEDULE);

        if (!this.automation || this.automation.selectedUserCount === 0) {
            this.allowedSteps.remove(AUTOMATION_WIZARD_STEPS.SCHEDULE);
        }
    }

    @action
    async sendPreview(
        companyId,
        targetEmail,
        previewType,
        selectedEvents,
        introMessage
    ) {
        this.sendingPreview = true;
        this.sendError = '';
        try {
            const data = { targetEmail };
            if (previewType === 3) {
                data.introductoryData = introMessage;
            } else if (previewType === 1) {
                data.previewSubjects = [
                    flatten(this.automation.result.map((x) => x.subjects)).map(
                        (x) => x.id
                    ),
                ];
            } else {
                data.previewSubjects = this.automation.result
                    .filter((x, i) => selectedEvents.includes(i))
                    .map((x) => x.subjects.map((s) => s.id));
            }

            await services.Companies.automationsService(companyId).sendPreview(
                data
            );
            this.commonStore.analyticsEvent(
                'Training-wizard',
                'preview-sent',
                `company-${companyId}`
            );
            return true;
        } catch (e) {
            this.sendError = extractErrorMessage(e);
        } finally {
            this.sendingPreview = false;
        }
    }

    @action
    async loadRemindersCounter(companyId, automationId, reminderOption) {
        if (this.loadingReminderCounter) return;
        this.loadingReminderCounter = true;
        this.reminderCounter = null;
        this.lastReminders.replace([]);
        try {
            let result = await services.Companies.automationsService(
                companyId
            ).loadingRemindersCounter(automationId, reminderOption);
            this.reminderCounter = result.remindersCount;
            this.lastReminders.replace(result.lastReminders);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingReminderCounter = false;
        }
    }

    @action
    async sendReminders(companyId, automationId, reminderOption) {
        try {
            return await services.Companies.automationsService(
                companyId
            ).sendReminders(automationId, reminderOption);
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    @action
    async prepareWizard(companyId, copyFrom, editId) {
        if (this.loadingCompanyData) return;
        this.loadingCompanyData = true;
        this.error = null;
        this.automations.clear();
        this.automation = null;
        this.selectedTemplates = [];
        try {
            await this.catalogStore.loadCatalog(companyId);
            let templates = await services.Companies.templatesService(
                companyId
            ).list();
            this.templates.replace(templates);
            let questions = await services.Companies.questionsService(
                companyId
            ).list();
            this.questions.replace(questions);

            await this.listStore.loadCompanyLists(companyId);
            await this.companyStore.loadIntegrationData(companyId, 'slack');
            await this.companyStore.loadIntegrationData(companyId, 'teams');
            let fromData;

            this.slackConnected =
                this.companyStore.slackDataIndex[companyId] &&
                this.companyStore.slackDataIndex[companyId].connected;
            this.teamsConnected =
                this.companyStore.teamsDataIndex[companyId] &&
                this.companyStore.teamsDataIndex[companyId].connected &&
                this.companyStore.teamsDataIndex[companyId].enabled === '1';

            if (copyFrom) {
                let fromAutomation =
                    await services.Companies.automationsService(
                        companyId
                    ).fetch(copyFrom);

                fromData = {
                    ...pick(
                        fromAutomation,
                        'definition',
                        'frequency',
                        'lists',
                        'events',
                        'reminders_enabled',
                        'reminders_settings',
                        'reminders_steps',
                        'scheduleType',
                        'subjects',
                        'weekday',
                        'timeofday',
                        'template_id'
                    ),
                    name: fromAutomation.name + ' Copy',
                    channel:
                        fromAutomation.sendOptions === null
                            ? 0
                            : fromAutomation.sendOptions.channel || 0,
                    reminders_channel:
                        fromAutomation.reminders_settings === null
                            ? 0
                            : fromAutomation.reminders_settings.channel || 0,
                    timeOfDay: fromAutomation.timeofday,
                    id: null,
                };

                this.setStep(AUTOMATION_WIZARD_STEPS.CONTENT);
                this.allowedSteps.replace([
                    AUTOMATION_WIZARD_STEPS.GOAL,
                    AUTOMATION_WIZARD_STEPS.CONTENT,
                    AUTOMATION_WIZARD_STEPS.EMPLOYEES,
                    AUTOMATION_WIZARD_STEPS.SCHEDULE,
                    AUTOMATION_WIZARD_STEPS.REMINDERS,
                    AUTOMATION_WIZARD_STEPS.INTROMESSAGE,
                    AUTOMATION_WIZARD_STEPS.FINALIZE,
                ]);
                this.selectedTemplates.replace([fromData.template_id]);
            } else if (editId) {
                fromData = await services.Companies.automationsService(
                    companyId
                ).fetch(editId);

                fromData.timeOfDay = fromData.timeofday;

                this.setStep(AUTOMATION_WIZARD_STEPS.GOAL);
                this.allowedSteps.replace([
                    AUTOMATION_WIZARD_STEPS.GOAL,
                    AUTOMATION_WIZARD_STEPS.CONTENT,
                    AUTOMATION_WIZARD_STEPS.EMPLOYEES,
                    AUTOMATION_WIZARD_STEPS.SCHEDULE,
                    AUTOMATION_WIZARD_STEPS.REMINDERS,
                    AUTOMATION_WIZARD_STEPS.INTROMESSAGE,
                    AUTOMATION_WIZARD_STEPS.FINALIZE,
                ]);
                this.selectedTemplates.replace([fromData.template_id]);
            } else {
                this.setStep(AUTOMATION_WIZARD_STEPS.GOAL);
                this.allowedSteps.replace([AUTOMATION_WIZARD_STEPS.GOAL]);
            }
            this.automation = new Automation(
                this.listStore,
                this.catalogStore,
                this.questionsIndex,
                fromData,
                this.mainStore.isTrial,
                this.mainStore.havePartnerSubscription,
                companyId
            );
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingCompanyData = false;
        }
    }

    // d = show drafts (boolean), p = page number, n = number of rows per page
    @action
    async loadAutomations(companyId, d, p, n) {
        if (this.loadingAutomations || this.loadingAutomationsInBackground)
            return;
        if (this.automations.length > 0)
            this.loadingAutomationsInBackground = true;
        else this.loadingAutomations = true;
        try {
            const result = await services.Companies.automationsService(
                companyId
            ).list({ d, p, n });
            this.automations.replace(
                result.map(
                    (x) =>
                        new Automation(
                            this.listStore,
                            this.catalogStore,
                            this.questionsIndex,
                            x,
                            this.mainStore.isTrial,
                            companyId
                        )
                )
            );
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingAutomations = false;
            this.loadingAutomationsInBackground = false;
        }
    }

    @action
    async loadAutomation(companyId, automationId) {
        if (this.loadingAutomation) return;
        this.loadingAutomation = true;
        this.error = null;
        let companyChanged;
        try {
            await this.catalogStore.loadCatalog(companyId);

            let questions = await services.Companies.questionsService(
                companyId
            ).list();
            this.questions.replace(questions);

            await this.listStore.loadCompanyLists(companyId);

            const result = await services.Companies.automationsService(
                companyId
            ).fetch(automationId);

            this.commonStore.analyticsEvent(
                'Training',
                'load',
                `company-${companyId}-automation-${automationId}`
            );

            this.editingAutomation = new Automation(
                this.listStore,
                this.catalogStore,
                this.questionsIndex,
                result,
                this.mainStore.isTrial,
                companyId
            );
            this.perUserDetails.replace([]);
            this.perUserDetailsLoaded = false;
            companyChanged = result.company_id !== companyId;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingAutomation = false;
        }
        return companyChanged;
    }

    @action
    async loadUsersDetails(companyId, automationId) {
        try {
            const result = await services.Companies.automationsService(
                companyId
            ).loadUsersDetails(automationId);
            this.perUserDetails.replace(result);
            this.perUserDetailsLoaded = true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    @action
    async loadAutomationEventStatus(companyId, automationId, eventId) {
        if (this.loadingAutomationStatus) return;
        this.loadingAutomationStatus = true;
        try {
            this.automationStatus = await services.Companies.automationsService(
                companyId
            ).eventStatus(automationId, eventId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingAutomationStatus = false;
        }
    }

    @action
    async resendEventMail(companyId, automationId, eventId, email) {
        if (this.sendAutomationEmails.includes(email)) return;
        this.sendAutomationEmails.push(email);
        try {
            await services.Companies.automationsService(
                companyId
            ).resendEventMail(automationId, eventId, email);
            this.commonStore.analyticsEvent(
                'Training',
                'resend-email',
                `company-${companyId}-automation-${automationId}`
            );
            let user =
                this.automationStatus &&
                this.automationStatus.users &&
                this.automationStatus.users.find((x) => x.email === email);
            if (user) user.sent = true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.sendAutomationEmails.remove(email);
        }
    }

    @action
    async loadEventUserDetails(companyId, automationId, eventId, email) {
        if (this.loadingAutomationUserDetails) return;
        this.loadingAutomationUserDetails = true;
        try {
            this.automationUserStatus =
                await services.Companies.automationsService(
                    companyId
                ).eventUserStatus(automationId, eventId, email);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingAutomationUserDetails = false;
        }
    }

    @action
    async loadUserDetails(companyId, trainingId, email) {
        if (this.loadingAutomationUserDetails) return;
        this.loadingAutomationUserDetails = true;
        try {
            this.automationUserStatus =
                await services.Companies.automationsService(
                    companyId
                ).userStatus(trainingId, email);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingAutomationUserDetails = false;
        }
    }

    @action
    async delete(companyId, automationId) {
        if (this.deletingAutomation) return;
        this.deletingAutomation = true;
        try {
            await services.Companies.automationsService(companyId).delete(
                automationId
            );
            this.editingAutomation = null;
            this.automation = null;
            this.automations.replace(
                this.automations.filter((x) => x.id !== automationId)
            );
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.deletingAutomation = false;
        }
    }

    @action
    async startAutomation(companyId, extraData) {
        if (this.creatingAutomation) return;
        this.creatingAutomation = true;

        try {
            let result;
            if (this.automation.id) {
                result = await services.Companies.automationsService(
                    companyId
                ).update(
                    this.automation.toDTO({
                        ...extraData,
                        templateId: this.selectedTemplates,
                    })
                );
            } else {
                result = await services.Companies.automationsService(
                    companyId
                ).create(
                    this.automation.toDTO({
                        ...extraData,
                        templateId: this.selectedTemplates,
                    })
                );
            }
            this.commonStore.analyticsEvent(
                'trainings',
                'create',
                `company-${companyId}`
            );
            return result.automation;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.creatingAutomation = false;
        }
    }

    @action
    async saveDraft(companyId, extraData) {
        if (this.savingDraft) return;
        this.savingDraft = true;

        try {
            if (this.automation.id) {
                await services.Companies.automationsService(
                    companyId
                ).updateDraft(
                    this.automation.toDTO({
                        ...extraData,
                        templateId: this.selectedTemplates,
                    })
                );
            } else {
                let result = await services.Companies.automationsService(
                    companyId
                ).saveDraft(
                    this.automation.toDTO({
                        ...extraData,
                        templateId: this.selectedTemplates,
                    })
                );
                if (result.success) this.automation.setId(result.automation.id);
            }
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingDraft = false;
        }
    }

    @action
    async pauseAutomation(companyId, automationId) {
        if (this.updatingAutomation) return;
        this.updatingAutomation = true;

        try {
            await services.Companies.automationsService(
                companyId
            ).pauseAutomation(automationId);
            let respectiveAutomation = this.automations.find(
                (x) => x.id === automationId
            );
            respectiveAutomation.status = STATUS.DRAFT;
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.updatingAutomation = false;
        }
    }

    @computed get perUserDetailsIndex() {
        if (!this.perUserDetailsLoaded) return null;

        return keyBy(this.perUserDetails, 'email');
    }

    @computed get questionsIndex() {
        return keyBy(this.questions, 'question_id');
    }
}

export default AutomationStore;
