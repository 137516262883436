import React from 'react';
import LaunchIcon from '@material-ui/icons/Launch';
import { ThemedText } from '../themed/ThemedComponents';

function IntegrationDetailSidebar({ href, product }) {
    return (
        <div className="integration-detail-sidebar">
            <div className="d-flex align-items-center">
                <a href={href} target="_blank" rel="noopener noreferrer">
                    <ThemedText primary bold>
                        Online documentation{' '}
                    </ThemedText>
                    <LaunchIcon fontSize="small" color="primary" />
                </a>
            </div>
            <p>Learn more on how to use AwareGO with {product}.</p>
        </div>
    );
}

export default IntegrationDetailSidebar;
