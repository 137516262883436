import React, { Fragment, useEffect, useState, createRef } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import {
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    TabContent,
    TabPane,
} from 'reactstrap';
import ErrorMessage from '../../components/errormessage';
import ThreatAreas from '../../components/manager/assessment/report-threat-areas';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import ThemedProgressPeople from '../../components/themed/ThemedProgressPeople';
import {
    ThemedButton,
    ThemedDropdownItem,
    ThemedNav,
    ThemedNavItem,
    ThemedNavLink,
} from '../../components/themed/ThemedComponents';
import { Select, MenuItem, FormControl, Dialog } from '@material-ui/core';
import { useStores } from '../../hooks/use-stores';
import { Row, Col } from 'reactstrap';
import SemiCircleProgress from '../../components/manager/assessment/semi-circle-progress';
import { useHistory } from 'react-router';
import Snackbar from '../../components/Snackbar';
import Modal from 'react-modal';
import ModalReminders from '../automations/modals/ModalReminders';
import AssessmentEmployeeTable from './AssessmentEmployeesTable';
import AssessmentQuestionsTable from './AssessmentQuestionsTable';
import StatusBadge from '../../components/StatusBadge';
import './AssessmentView.scss';
import moment from 'moment';
import { STATUS } from '../../utils/constants';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import PreviewQuestion from '../../components/manager/assessment/preview-question';

function AssessmentView({ companyId, assessmentId, parentMatch }) {
    const [activeTab, setActiveTab] = useState('1');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [remindersModal, setRemindersModal] = useState(false);

    const [message, setMesage] = useState(null);
    const [messageShown, setMessageShown] = useState(false);
    const [messageType, setMessageType] = useState(null);

    const [reviewQuestionsModal, setReviewQuestionsModal] = useState(false);
    const [reviewedQuestion, setReviewedQuestion] = useState(null);

    const { assessmentStore } = useStores();
    const history = useHistory();
    const {
        commonStore,
        editingAssessment,
        loadingAssessment,
        lastReminders,
        reminderCounter,
        loadingReminderCounter,
        error,
        platform,
        questionsReportList,
    } = assessmentStore;

    const threatAreasRef = createRef();

    useEffect(() => {
        assessmentStore.loadAssessment(companyId, assessmentId);
        assessmentStore.loadAssessmentEmployeesReport(companyId, assessmentId);
        assessmentStore.loadAssessmentQuestionsReport(companyId, assessmentId);
    }, [companyId, assessmentId]);

    const toggleExtraActions = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const showRemindersModal = () => {
        setRemindersModal(true);
    };

    const loadRemindersCount = async (reminderOption) => {
        await assessmentStore.loadRemindersCounter(
            companyId,
            assessmentId,
            reminderOption
        );
    };

    const hideRemindersModal = () => {
        setRemindersModal(false);
    };

    const sendReminders = async (reminderOption) => {
        const result = await assessmentStore.sendReminders(
            companyId,
            assessmentId,
            reminderOption
        );
        const messageType = result && result.success ? 'success' : 'danger';
        const message =
            result && result.success
                ? 'Reminders sent!'
                : result && result.reason
                ? result.reason
                : 'Reminders were not sent!';

        if (result) showSnackBar(message, messageType);

        hideRemindersModal();
    };

    const showSnackBar = (message, messageType = 'success') => {
        setMesage(message);
        setMessageType(messageType);
        setMessageShown(true);
    };

    const closeSnackbar = () => {
        setMesage(null);
        setMessageType(null);
        setMessageShown(false);
    };

    const doDelete = async () => {
        if (await assessmentStore.delete(companyId, assessmentId))
            return history.push(
                (parentMatch && parentMatch.url) || '/assessment'
            );
    };

    const onDelete = () => {
        commonStore.showConfirm(
            'Are you sure you want to delete this assessment?',
            'Delete',
            'Delete Assessment',
            async () => {
                await doDelete();
            },
            true
        );
    };

    const threatAreaWidth = 258;

    const handleThreatAreasScroll = (direction) => {
        return direction === 'left'
            ? threatAreasRef
                ? (threatAreasRef.current.scrollLeft -= 4 * threatAreaWidth)
                : null
            : threatAreasRef
            ? (threatAreasRef.current.scrollLeft += 4 * threatAreaWidth)
            : null;
    };

    const showReviewQuestionsModal = (questionId) => {
        setReviewQuestionsModal(true);
        setReviewedQuestion(questionId);
    };

    const hideReviewQuestionsModal = () => {
        setReviewQuestionsModal(false);
        setReviewedQuestion(null);
    };

    return (
        <Fragment>
            <Link to={`/assessment`}>
                <div className={'navigation back-button'}>Back</div>
            </Link>
            <ErrorMessage error={error} />
            {loadingAssessment ? (
                <ThemedSpinner />
            ) : editingAssessment ? (
                <Fragment>
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        autoHideDuration={5000}
                        variant={messageType}
                        message={message || ''}
                        open={messageShown}
                        onClose={closeSnackbar}
                    />
                    <Row>
                        <Col xs="12">
                            <div className="block section-header assessment-view-header">
                                <div className={'d-flex flex-column'}>
                                    <h3>{editingAssessment.name}</h3>
                                    <div className="header-description">
                                        <StatusBadge
                                            color={
                                                editingAssessment.statusInfo
                                                    .color
                                            }
                                            text={
                                                editingAssessment.statusInfo
                                                    .label
                                            }
                                        />
                                        <span style={{ margin: '0 5px' }}>
                                            Delivery{' '}
                                            {editingAssessment.deliveryDate
                                                ? moment(
                                                      editingAssessment.deliveryDate
                                                  ).format('ll LT [GMT]')
                                                : 'not set'}
                                        </span>
                                        <span>
                                            · Due date{' '}
                                            {editingAssessment.due
                                                ? moment(
                                                      editingAssessment.due
                                                  ).format('ll LT [GMT]')
                                                : 'not set'}
                                        </span>
                                    </div>
                                </div>
                                <div className={'btns-right-align'}>
                                    {editingAssessment.status !==
                                        STATUS.COMPLETED && (
                                        <Link
                                            to={`/assessment/edit/${editingAssessment.id}`}
                                        >
                                            <ThemedButton primary>
                                                Edit
                                            </ThemedButton>
                                        </Link>
                                    )}
                                    <Dropdown
                                        isOpen={dropdownOpen}
                                        toggle={toggleExtraActions}
                                    >
                                        <DropdownToggle
                                            onClick={() => toggleExtraActions}
                                            className="btn btn-three-dots"
                                        ></DropdownToggle>
                                        <DropdownMenu>
                                            {editingAssessment.canRemind && (
                                                <ThemedDropdownItem
                                                    onClick={showRemindersModal}
                                                >
                                                    Send reminders
                                                </ThemedDropdownItem>
                                            )}
                                            {editingAssessment.canDelete && (
                                                <ThemedDropdownItem
                                                    onClick={onDelete}
                                                >
                                                    Delete assessment
                                                </ThemedDropdownItem>
                                            )}
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/* <div className="assessment-show-results-for">
                        <p>Show results for</p>
                        <FormControl variant="outlined">
                            <Select
                                name="platform"
                                color="primary"
                                value={platform}
                                onChange={(e) => {
                                    assessmentStore.setPlatform(e.target.value);
                                }}
                            >
                                <MenuItem value="all_platforms">
                                    All platforms
                                </MenuItem>
                                <MenuItem value="mobile">Mobile</MenuItem>
                                <MenuItem value="desktop">Desktop</MenuItem>
                            </Select>
                        </FormControl>
                    </div> */}

                    <div className="report-header block no-margin-top">
                        <div className={'section-header'}>
                            <div className={'left padded'}>
                                <h3>General overview</h3>
                            </div>
                        </div>
                        <Row className="flex-cards dashboard-blocks">
                            <Col>
                                <div
                                    className="d-flex"
                                    style={{ height: '200px' }}
                                >
                                    <div className="resilliance-score progress-semicircle">
                                        <SemiCircleProgress
                                            percentage={
                                                editingAssessment.resilienceScore
                                            }
                                            size="medium"
                                            className="medium"
                                        />
                                        <h5>
                                            {editingAssessment.status ===
                                                STATUS.COMPLETED ||
                                            editingAssessment.status ===
                                                STATUS.ONGOING
                                                ? 'Resilliance score'
                                                : 'No score available'}
                                        </h5>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center">
                                    {editingAssessment.status ===
                                        STATUS.COMPLETED ||
                                    editingAssessment.status ===
                                        STATUS.ONGOING ? (
                                        <ThemedButton
                                            secondary
                                            noTextTransform
                                            className="assessment-view-review-answer"
                                            onClick={() =>
                                                showReviewQuestionsModal(
                                                    editingAssessment
                                                        .questions[0]
                                                )
                                            }
                                        >
                                            Review answers
                                        </ThemedButton>
                                    ) : (
                                        <div className="d-flex flex-column justify-content-center">
                                            <span className="additional-text">
                                                {editingAssessment.status ===
                                                STATUS.SCHEDULED
                                                    ? `This assessessment has been scheduled to be sent on ${moment(
                                                          editingAssessment.deliveryDate
                                                      ).format(
                                                          'll [at] LT [GMT]'
                                                      )}`
                                                    : 'This assessment has not been sent yet. To send it, please click Edit and complete the assessment setup wizard.'}
                                            </span>
                                            <Link
                                                style={{ margin: '10px auto' }}
                                                to={`/assessment/edit/${editingAssessment.id}`}
                                            >
                                                <ThemedButton secondary>
                                                    Edit Assessment
                                                </ThemedButton>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </Col>
                            {(editingAssessment.status === STATUS.COMPLETED ||
                                editingAssessment.status ===
                                    STATUS.ONGOING) && (
                                <Fragment>
                                    <Col>
                                        <div
                                            className="d-flex justify-content-center align-items-center progress-people"
                                            style={{ height: '140px' }}
                                        >
                                            <div className="img">
                                                <ThemedProgressPeople
                                                    progress={
                                                        (editingAssessment
                                                            ._rawData
                                                            .usersStarted /
                                                            editingAssessment
                                                                ._rawData
                                                                .usersTotal) *
                                                        100
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <h5>Attendance score</h5>
                                            <p>
                                                {
                                                    editingAssessment._rawData
                                                        .usersStarted
                                                }{' '}
                                                out of{' '}
                                                {
                                                    editingAssessment._rawData
                                                        .usersTotal
                                                }{' '}
                                                employees have started the
                                                assessment.
                                            </p>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div style={{ height: '140px' }}>
                                            <div className="completion-rate progress-semicircle">
                                                {editingAssessment._rawData
                                                    .usersFinished > 0 && (
                                                    <SemiCircleProgress
                                                        percentage={Math.round(
                                                            (100 *
                                                                editingAssessment
                                                                    ._rawData
                                                                    .usersFinished) /
                                                                editingAssessment
                                                                    ._rawData
                                                                    .usersTotal
                                                        )}
                                                        size="medium"
                                                        className="medium"
                                                    />
                                                )}
                                                {!editingAssessment._rawData
                                                    .usersFinished > 0 && (
                                                    <SemiCircleProgress
                                                        percentage={0}
                                                        size="medium"
                                                        className="medium"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div>
                                            <h5>Completion rate</h5>
                                            <p>
                                                {
                                                    editingAssessment._rawData
                                                        .usersFinished
                                                }{' '}
                                                out of{' '}
                                                {
                                                    editingAssessment._rawData
                                                        .usersTotal
                                                }{' '}
                                                employees have completed the
                                                assessment.
                                            </p>
                                        </div>
                                    </Col>
                                </Fragment>
                            )}
                        </Row>
                    </div>

                    {(editingAssessment.status === STATUS.COMPLETED ||
                        editingAssessment.status === STATUS.ONGOING) && (
                        <div className="report-header block">
                            <div className={'section-header'}>
                                <div className={'left padded mb-16'}>
                                    <h3>Threat areas</h3>
                                </div>
                                {editingAssessment.threatAreas.length > 3 && (
                                    <div className="d-flex align-items-center">
                                        <ArrowLeft
                                            fontSize="large"
                                            className="show-more-arrow"
                                            onClick={() =>
                                                handleThreatAreasScroll('left')
                                            }
                                        />
                                        <ArrowRight
                                            fontSize="large"
                                            className="show-more-arrow"
                                            onClick={() =>
                                                handleThreatAreasScroll('right')
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                            <ThreatAreas
                                areaData={editingAssessment.threatAreas}
                                assessment={editingAssessment}
                                companyId={companyId}
                                threatAreasRef={threatAreasRef}
                                firstQuestionId={editingAssessment.questions[0]}
                                showReviewQuestionsModal={
                                    showReviewQuestionsModal
                                }
                            />
                        </div>
                    )}
                    <Row>
                        <Col xs="12">
                            <div className="block">
                                <ThemedNav table>
                                    <ThemedNavItem active={activeTab === '1'}>
                                        <ThemedNavLink
                                            table
                                            onClick={() => {
                                                setActiveTab('1');
                                            }}
                                        >
                                            Employees (
                                            {
                                                editingAssessment._rawData
                                                    .usersTotal
                                            }
                                            )
                                        </ThemedNavLink>
                                    </ThemedNavItem>
                                    <ThemedNavItem active={activeTab === '2'}>
                                        <ThemedNavLink
                                            table
                                            onClick={() => {
                                                setActiveTab('2');
                                            }}
                                        >
                                            Questions (
                                            {
                                                editingAssessment._rawData
                                                    .definition.questions.length
                                            }
                                            )
                                        </ThemedNavLink>
                                    </ThemedNavItem>
                                </ThemedNav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <AssessmentEmployeeTable
                                            companyId={companyId}
                                            assessmentId={assessmentId}
                                        />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <AssessmentQuestionsTable
                                            companyId={companyId}
                                            assessmentId={assessmentId}
                                            threatAreas={
                                                editingAssessment.threatAreas
                                            }
                                            showReviewQuestionsModal={
                                                showReviewQuestionsModal
                                            }
                                        />
                                    </TabPane>
                                </TabContent>
                            </div>
                        </Col>
                    </Row>
                    <Modal
                        isOpen={remindersModal}
                        onRequestClose={hideRemindersModal}
                        className="Modal schedule"
                    >
                        <ModalReminders
                            target={editingAssessment}
                            lastReminders={lastReminders}
                            reminderCounter={reminderCounter}
                            loadingReminderCounter={loadingReminderCounter}
                            onLoadRemindersCount={loadRemindersCount}
                            onRequestClose={hideRemindersModal}
                            onSendReminders={sendReminders}
                            remindOnlyUnfinished={true}
                        />
                    </Modal>
                    <Dialog
                        fullScreen
                        open={reviewQuestionsModal}
                        onClose={hideReviewQuestionsModal}
                        className="review-questions-modal"
                    >
                        <PreviewQuestion
                            questionId={reviewedQuestion}
                            onRequestClose={hideReviewQuestionsModal}
                            type={'questionReview'}
                            companyId={companyId}
                            hasMoreQuestions={true}
                            assessmentId={editingAssessment.id}
                            questionsSelected={editingAssessment.questions}
                            questionsReportList={questionsReportList}
                        />
                    </Dialog>
                </Fragment>
            ) : (
                <Row>
                    <Col xs="12">
                        <div className="block no-assessments-found">
                            <img
                                src="/static/img/icon-booklist.svg"
                                alt="Assessment not found"
                            />
                            <div className="stats">
                                <h5>Assessment not found :(</h5>
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
        </Fragment>
    );
}

export default observer(AssessmentView);
