import React from 'react';
import { observer } from 'mobx-react';
import { ThemedButton } from '../themed/ThemedComponents';
import {
    FlexDiv,
    SpaceBetweenDiv,
    Block,
    Image,
} from '../design-system/containers';
import { TextDefault, TextLead } from '../design-system/texts';
import { Heading2 } from '../design-system/headings';
import { colors } from '../design-system/colors';

function CompanyAppearanceEditor({
    onEditLogo,
    onEditColor,
    onResetToDefaults,
    company_id,
    company,
}) {
    return (
        <FlexDiv>
            <Block>
                <FlexDiv column>
                    <Heading2>
                        Customize appearance
                    </Heading2>
                    <TextDefault>
                        Change the appearance of {company.company_name} for
                        employees (employee view).
                    </TextDefault>
                </FlexDiv>
                <SpaceBetweenDiv bottomBorder py={16} mt={8}>
                    <FlexDiv column mr={16}>
                        <TextLead bold>Company Logo</TextLead>
                        <TextDefault>
                            Set a custom logo that will be shown on employee
                            view when they sign in.
                        </TextDefault>
                    </FlexDiv>
                    <FlexDiv
                        column
                        link
                        onClick={() => onEditLogo(company_id)}
                        alignCenter
                    >
                        {company.logo_name ? (
                            <Image
                                width={150}
                                src={
                                    process.env.REACT_APP_RESOURCES_URL +
                                    '/' +
                                    process.env
                                        .REACT_APP_COMPANY_RESOURCES_DIR +
                                    '/' +
                                    company.logo_name
                                }
                                alt="Your logo"
                            />
                        ) : (
                            <Image
                                width={50}
                                src="/static/img/logo-image.svg"
                                alt="Add your logo"
                            />
                        )}
                        <TextDefault themed bold>
                            Change
                        </TextDefault>
                    </FlexDiv>
                </SpaceBetweenDiv>
                <SpaceBetweenDiv bottomBorder py={16} mb={24}>
                    <FlexDiv column mr={16}>
                        <TextLead bold>Color</TextLead>
                        <TextDefault>
                            Change the color of main buttons and links to your
                            brand color.
                        </TextDefault>
                    </FlexDiv>
                    <FlexDiv
                        column
                        link
                        onClick={() => onEditColor(company_id)}
                        alignCenter
                    >
                        <FlexDiv
                            backgroundColor={
                                company.color_main || colors.aspargus
                            }
                            width={32}
                            minHeight={32}
                            border
                        />
                        <TextDefault themed bold>
                            Change
                        </TextDefault>
                    </FlexDiv>
                </SpaceBetweenDiv>
                <TextDefault
                    error
                    bold
                    onClick={() => onResetToDefaults(company_id)}
                    link
                >
                    Reset to default
                </TextDefault>
            </Block>
            <Block ml={20}>
                <Heading2>Preview</Heading2>
                <TextDefault>
                    This is how buttons will look in the employee view:
                </TextDefault>
                <FlexDiv marginAuto column>
                    <FlexDiv mb={8}>
                        <ThemedButton primary color={company.color_main}>
                            Button
                        </ThemedButton>
                    </FlexDiv>
                    <ThemedButton secondary color={company.color_main}>
                        Button
                    </ThemedButton>
                </FlexDiv>
            </Block>
        </FlexDiv>
    );
}

export default observer(CompanyAppearanceEditor);