import React from 'react';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { Table, Td, Th, Thead, Tr } from 'reactable';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';

export default observer(({ companyId }) => {
    const { authStore } = useStores();
    const { token } = authStore;

    return (
        <Container className="manager-content">
            <Helmet>
                <title>Reports</title>
            </Helmet>

            <div className="simple-list">
                <div className="section-header">
                    <div className={'left padded'}>
                        <h1>Reports</h1>
                    </div>
                </div>

                <Table className="table" id="table">
                    <Thead>
                        <Th column="Name" className="title" />
                        <Th column="Get" className="title" />
                    </Thead>
                    <Tr>
                        <Td column="Name" value={''} className="user-title">
                            General overview
                        </Td>

                        <Td column="Get" className="title">
                            <a
                                href={`${process.env.REACT_APP_API_URL}/companies/${companyId}/overview/report?token=${token}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                PDF
                            </a>
                        </Td>
                    </Tr>
                </Table>
            </div>
        </Container>
    );
});
