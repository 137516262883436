import React from "react";
import {observer} from "mobx-react";
import { Bar } from 'react-chartjs-2';
import {Col} from "reactstrap";
import "./key-behaviour-chart.scss"
import ChartDataLabels from 'chartjs-plugin-datalabels';


function KeyBehaviourChart({index, componentData}) {

    const generateBarData = () => {
        const labels = componentData.values.map((value) => [value.name, value.from + ' - ' + value.to])
        const data = componentData.values.map((value) => value.value > 0 ? value.value : null)
        const backgroundColor = componentData.values.map((value) => value.color);
        return {
            labels,
            datasets: [{
                data,
                backgroundColor,
                datalabels: {
                    anchor: 'end',
                    align: 'start',
                },
                borderRadius: "5"
            }],
            
        }
    };

    const getParticipantCount = (componentData) => {
        return componentData.values.reduce( function(a, b){
            return a + b.value;
        }, 0);
    }

    return (
        <Col md="6">
            <span className="key-behaviour-chart-header">
                <div className="count-in-line">{index+1}</div>
                {componentData.name}
            </span>
            <div className="bar-chart-assessment">
                <Bar
                    data={generateBarData}
                    width={100}
                    height={70}
                    plugins={[ChartDataLabels]}
                    options={{
                        scales: {
                            y: {
                                position: "right",
                                max: Math.ceil(getParticipantCount(componentData) / 5) * 5,
                                min: 0,
                                ticks: {
                                    precision: 0,
                                },
                                grid: {drawBorder: false},
                                maxBarThickness: 100,
                            },
                            y1: {
                                position: "left",
                                title: {
                                    display: true,
                                    text: "No. of people",
                                },
                                grid: {drawBorder: false},
                                ticks: {
                                    display: false,
                                    precision: 0
                                },
                                beginAtZero: true,
                                maxBarThickness: 100,
                            },
                            x: {
                                grid: {display: false},
                                maxBarThickness: 100,
                                ticks: {
                                    fontSize: 10,
                                    autoSkip: false,
                                    maxRotation: 0,
                                }
                            }
                        },
                        plugins: {
                            datalabels: {
                                color: "white",
                                font: {
                                    weight: 800,
                                    size: 12
                                },
                                padding: 6,
                            },
                            legend: {display: false},
                            tooltip: {enabled: false}
                        },
                    }}
                />
            </div>
        </Col>
    )
}

export default observer(KeyBehaviourChart)