import React, { Fragment, useMemo } from 'react';
import Setting from './Setting';
import { observer } from 'mobx-react';
import moment from 'moment';
import { useStores } from '../../hooks/use-stores';
import { useHistory } from 'react-router';

export default observer(() => {
    const { store, companyStore, brandingStore } = useStores();
    const { currentCompany, isTrial, subscriptionBillingPeriod } = store;
    const { supportEmail } = brandingStore;

    const activePackage = currentCompany.subscription;
    const isChargeBeeEnabled = currentCompany.chargebeeEnabled;

    let history = useHistory();

    const onUpgradePricingPlan = () => {
        let path = '/subscriptions/upgrade/';
        history.push(path);
    };

    const openCBportal = async () => {
        let chargebeeInstance = window.Chargebee.getInstance();

        let portalSession = await companyStore.openManageSession(
            currentCompany.company_id
        );

        chargebeeInstance.setPortalSession(portalSession);

        let cbPortal = chargebeeInstance.createChargebeePortal();
        cbPortal.open({
            close() {
                chargebeeInstance.logout();
            },
        });
    };

    const description = useMemo(() => {
        let result = activePackage
            ? ` ${currentCompany.users_total}/${activePackage.package_users} seats used`
            : '';
        if (isTrial) {
            result += ` · Expires on (${moment(
                currentCompany.trial_ends
            ).format('LL')})`;
        } else {
            result += ` · ${subscriptionBillingPeriod} billing `;
            if (activePackage)
                result += `· (Renews on ${moment(
                    activePackage.package_valid_to
                ).format('LL')})`;
        }
        return result;
    }, [activePackage, currentCompany, subscriptionBillingPeriod]);

    return (
        <div className="top-guide">
            <div className="block new-settings-block">
                <div className="row">
                    <div className="col-md-8 ">
                        <h3>Subscription</h3>
                        <p className="text-thin">
                            Manage subscription plans and payment options
                        </p>
                    </div>
                </div>

                {activePackage ? (
                    <Setting
                        name="Your plan"
                        value={
                            activePackage.package_trial
                                ? 'Trial'
                                : activePackage.package_name
                        }
                        subtext={description}
                        actionName={isTrial ? 'Upgrade' : 'Manage'}
                        action={onUpgradePricingPlan}
                    />
                ) : (
                    <Setting
                        name="Your plan"
                        value={`Expired`}
                        subtext={`To re-activate your account please select a plan`}
                        actionName="Select plan"
                        action={onUpgradePricingPlan}
                    />
                )}
                {isChargeBeeEnabled && (
                    <Fragment>
                        <Setting
                            name="Payment settings"
                            actionName="Edit"
                            action={openCBportal}
                            isLast={true}
                        />
                    </Fragment>
                )}
            </div>
        </div>
    );
});
