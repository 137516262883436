import styled from 'styled-components';
import ReactClipboard from 'react-clipboard.js';
import { colors } from './colors';

export const Clipboard = styled(ReactClipboard)`
    background: none;
    border: none;
    color: ${colors.success};
    border-radius: 5px;
    margin 0px;
    font-size: 0.9em;
    transition: ease 0.2s all;
    padding-left: 0;
`;
