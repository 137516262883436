import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Container } from 'reactstrap';
import ChoosePlan from './ChoosePlan2';

@observer
class Subscriptions extends React.Component {
    render() {
        const { match, companyId } = this.props;

        return (
            <Switch>
                <Route
                    path={`${match.url}/`}
                    exact
                    render={(props) => (
                        <Container className="manager-content campaigns"></Container>
                    )}
                />

                <Route
                    path={`${match.url}/upgrade`}
                    render={(props) => (
                        <Container className="manager-content campaigns white-bg">
                            <ChoosePlan companyId={companyId} />
                        </Container>
                    )}
                />
            </Switch>
        );
    }
}

export default withRouter(Subscriptions);
