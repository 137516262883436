import React from 'react';
import { observer } from 'mobx-react';
import { ThemedLink } from '../../themed/ThemedComponents';
import HelpIcon from '@material-ui/icons/Help';
import LightTooltip from '../../LightTooltip';

@observer
class UsersImport extends React.Component {
    fileRef = React.createRef();

    handleFileSelect = async (evt) => {
        this.props.handleFilesSelect(evt);
    };

    handleDragOver = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        evt.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
    };

    chooseFile = (e) => {
        this.fileRef.current.click();
    };

    render() {
        return (
            <div
                className="d-flex flex-center flex-fill flex-column"
                onDragOver={this.handleDragOver}
                onDrop={this.handleFileSelect}
            >
                <div className="company-logo-drop-zone">
                    <img
                        src="/static/img/drop-icon.svg"
                        alt="drop files icon"
                    />
                    <div className="drop_zone_title">Drag and drop</div>
                    <div className="drop_zone_description">
                        or&nbsp;
                        <ThemedLink
                            style={{ textDecoration: 'underline' }}
                            onClick={this.chooseFile}
                        >
                            browse
                        </ThemedLink>
                        &nbsp;for a .csv file
                        <LightTooltip
                            title="Expected .csv format is following [email], [full name (optional)], [department (optional)]."
                            placement="bottom-start"
                            className="drop_zone_tooltip"
                        >
                            <HelpIcon />
                        </LightTooltip>
                    </div>
                    <input
                        className="hidden"
                        type="file"
                        multiple={false}
                        accept=".txt, .csv, text/csv, text/plain, text.csv, text.plain"
                        onChange={this.handleFileSelect}
                        ref={this.fileRef}
                    />
                </div>
            </div>
        );
    }
}

export default UsersImport;
