import React from 'react';
import { observer } from 'mobx-react';

@observer
class CompanyEdit extends React.Component {
    render() {
        const { form } = this.props;

        const nameField = form.$('name');
        const themeField = form.$('theme');

        return (
            <div className="steps step2">
                <div className="forms">
                    <div className="form-header">
                        <h2>Company</h2>
                    </div>

                    <div className="form-holder">
                        <h3>{nameField.label}</h3>
                        <input
                            type="text"
                            name="companyName"
                            {...nameField.bind()}
                            className={
                                nameField.isValid
                                    ? 'bigInput valid'
                                    : 'bigInput'
                            }
                        />
                        <div className="error">{nameField.error}</div>
                    </div>
                    <div className="form-holder">
                        <p>{themeField.label}</p>
                        <select
                            name="theme"
                            className="bigInput"
                            value={themeField.value}
                            {...themeField.bind()}
                        >
                            {themeField.extra.map((o, i) => {
                                return (
                                    <option
                                        key={i}
                                        className="tl"
                                        value={o.value}
                                    >
                                        {o.label}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyEdit;
