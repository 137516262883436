import React, { Fragment, useMemo } from 'react';
import SimpleTable from '../../SimpleTable';
import { Tooltip } from '@material-ui/core';
import { defaultZero, calculateColor } from '../../../utils/helpers';
import Summary from './summary';
import { Heading2, Heading3 } from '../../design-system/headings';
import { FlexDiv } from '../../design-system/containers';
import StatusBadge from '../../StatusBadge';
import { TextDefault } from '../../design-system/texts';
import ProgressWithPercentage from '../../ProgressWithPercentage';
import { ThemedButton } from '../../themed/ThemedComponents';
import { Block } from '../../design-system/containers';
import { FullWidthLink } from '../../design-system/miscellaneous';
import NoItemsYet from '../no-items-yet';
import Upgrade from './upgrade';
import { STATUS } from '../../../utils/constants';
import AssessmentUpgrade from '../assessment/AssessmentUpgrade';
import TrainingUpgrade from '../automation/TrainingUpgrade';

function AutomationDashboard({
    dashboardData,
    trainings,
    trainingProgramsUnavailable,
    upgradeAllowed,
    supportEmail,
}) {
    const columns = useMemo(
        () => [
            {
                Header: 'Training program',
                accessor: 'name',
                Cell: (x) => (
                    <Fragment>
                        <div>
                            {(x.value || '').length > 35 ? (
                                <Tooltip title={x.value || ''}>
                                    <span>
                                        <TextDefault bold>
                                            {(x.value || '').substring(0, 35)}
                                            ...
                                        </TextDefault>
                                    </span>
                                </Tooltip>
                            ) : (
                                <TextDefault bold>{x.value}</TextDefault>
                            )}
                        </div>
                        <StatusBadge
                            color={x.row.original.statusInfo.color}
                            text={
                                <Fragment>
                                    {x.row.original.statusInfo.label}{' '}
                                    {x.row.original.status === STATUS.ONGOING
                                        ? `(${x.row.original._rawData.subjectsSent}/${x.row.original._rawData.totalSubjects} delivered)`
                                        : ''}
                                </Fragment>
                            }
                        />
                    </Fragment>
                ),
            },
            {
                Header: '# Employees',
                accessor: '_rawData.totalUsers',
                Cell: (x) => {
                    return <TextDefault>{x.value}</TextDefault>;
                },
            },
            {
                Header: 'Attendance',
                accessor: 'attendance',
                Cell: (x) => {
                    return (
                        <TextDefault bold color={calculateColor(x.value)}>
                            {defaultZero(x.value)}%
                        </TextDefault>
                    );
                },
                tooltip:
                    'Percentage of employees who have started to watch at least one lesson in a training.',
            },
            {
                Header: 'Awareness score',
                accessor: 'awarenessScore',
                Cell: (x) => (
                    <ProgressWithPercentage value={x.value} thin="true" />
                ),
                tooltip: 'Average employee score across all training lessons.',
            },
        ],
        []
    );

    if (trainingProgramsUnavailable) {
        return (
            <TrainingUpgrade
                upgradeAllowed={upgradeAllowed}
                supportEmail={supportEmail}
            />
        );
    }

    return trainings.length > 0 ? (
        <Block>
            <Heading2 mb={16}>Training programs</Heading2>
            <FlexDiv justifyCenter alignCenter noWrap>
                <Summary
                    mainNumberText={'Active'}
                    mainNumber={dashboardData.active.value}
                    secondaryNumberText={'Currently enrolled'}
                    secondaryNumber={dashboardData.active.users}
                    iconUrl={'/static/img/icon-dashboard-repeat.svg'}
                    iconAlt={'arrow in circle'}
                    item="trainings"
                />
                <Summary
                    mainNumberText={'Completed'}
                    mainNumber={dashboardData.completed.value}
                    secondaryNumberText={'Completed training'}
                    secondaryNumber={dashboardData.completed.users}
                    iconUrl={'/static/img/icon-dashboard-completed.svg'}
                    iconAlt={'circle with tick inside'}
                    item="trainings"
                />
            </FlexDiv>
            <Heading3 my={24}>Training performance</Heading3>
            <SimpleTable
                columns={columns}
                data={trainings}
                hidePagination
                useLink
                disableSort
            />
            <FullWidthLink to={`/trainings`}>
                <ThemedButton secondary fullWidth>
                    All training programs
                </ThemedButton>
            </FullWidthLink>
        </Block>
    ) : (
        <NoItemsYet
            img={'/static/img/training-empty.svg'}
            imgAlt={'sneak peak into a training program'}
            title={'No training programs yet'}
            text={
                'Build security culture and keep security awareness high with our behavior-changing training programs.'
            }
            heading="Training programs"
            buttons={
                <FullWidthLink to={`/trainings/create`}>
                    <ThemedButton primary fullWidth>
                        Create training program
                    </ThemedButton>
                </FullWidthLink>
            }
        />
    );
}

export default AutomationDashboard;
