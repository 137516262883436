import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import SemiCircleProgress from '../../components/manager/assessment/semi-circle-progress';
import SimpleTable from '../../components/SimpleTable';
import { Box } from '@material-ui/core';

const { formatDate } = require('../../utils/helpers');

function AssessmentEmployeesTable({ companyId, assessmentId }) {
    const { assessmentStore } = useStores();
    const { loadingEmployeesReportList, employeesReportList } = assessmentStore;

    useEffect(() => {
        assessmentStore.loadAssessmentEmployeesReport(companyId, assessmentId);
    }, [companyId, assessmentId]);

    const columns = useMemo(() => [
        {
            Header: 'Employee',
            accessor: 'name',
            Cell: (x) => {
                return <div className="row-title">{x.value}</div>;
            },
        },
        {
            Header: 'Team',
            accessor: 'listName',
        },
        {
            Header: 'Completion date',
            accessor: 'completion_date',
            Cell: (x) => (x.value ? formatDate(x.value) : '-'),
        },
        {
            Header: 'Score',
            accessor: 'score',
            Cell: (x) => (
                <SemiCircleProgress
                    percentage={x.value}
                    size="small"
                    smallFont={true}
                    inTable={true}
                />
            ),
        },
        // hiding this as we don't have a functionality yet to review answers of individual employee yet
        // {
        //     id: "review_answers",
        //     Cell: () => (
        //         "Review answers"
        //     )
        // },
    ], [])

    return loadingEmployeesReportList ? (
        <ThemedSpinner />
    ) : (
        <Box mt={2}>
            <SimpleTable columns={columns} data={employeesReportList} />
        </Box>
    );
}

export default observer(AssessmentEmployeesTable);
