import { action, observable, computed, reaction } from 'mobx';
import sumBy from 'lodash/sumBy';
import groupBy from 'lodash/groupBy';
import moment from 'moment';
import services from '../services';
import { extractErrorMessage } from '../utils/helpers';
import { generateLoadList, generateUpdateEntity } from '../utils/mobx';
import AddCompanyDef from '../forms/add-company';

const debounceFn = require('debounce-fn');

class SubscriptionsStore {
    legacyKeyFacts = [
        'Full access to all training material',
        'New videos monthly to cover new risks',
        'Certificate at completion',
        'Track user progress',
        'Employee reminders',
        'Only {user_price} per user per month',
    ];
    @observable basePlans = observable([]);
    @observable subscriptionPlans = observable([]);
    @observable loadingPlans = false;
    @observable estimationLoadingId = 0;
    @observable estimationLoading = false;
    @observable loadingSubscriptionsPlans = false;
    @observable deactivatingCompanySubscription = false;
    @observable updatingCompanySubscription = false;
    @observable payInProcess = false;
    @observable error = null;
    @observable estimationError = null;
    @observable deactivatingError = null;
    @observable subscriptionPlansError = null;
    @observable partnerEstimate = null;
    @observable couponError = null;
    @observable selectedPlan = null;
    @observable selectedSeats = null;
    @observable planPeriod = null;
    @observable my = null;
    @observable coupon = null;
    @observable estimateResult = null;
    @observable estimating = null;
    @observable billingInfo = null;
    @observable companyForm = null;
    @observable canReduceSeatsLoading = false;
    @observable canBeRemovedLoading = false;

    constructor(commonStore) {
        this.enterprise_units =
            Number(process.env.REACT_APP_ENTERPRISE_UNITS) || 500;
        this.commonStore = commonStore;
        this.estimateDebounced = debounceFn(this.estimate, { wait: 500 });
        this.estimatePartner = debounceFn(this.loadEstimation, { wait: 500 });
    }
    @action setError(error, type = 'generic') {
        error = extractErrorMessage(error);
        this.error = type === 'generic' ? error : null;
        this.deactivatingError =
            type === 'deactivateCompanySubscription' ? error : null;
        this.subscriptionPlansError =
            type === 'subscriptionPlans' ? error : null;
    }

    @action
    initCompanyForm(partnerId, onSuccess, initials) {
        this.partnerEstimate = null;
        let companyForm = new AddCompanyDef(initials, {
            onError() {
                console.log("it's not working!!! :(");
            },
            onSuccess() {
                onSuccess(companyForm, initials);
            },
        });

        const handleEstimation = () => {
            const subscription = companyForm.$('subscription').value;
            const seats = companyForm.$('seats').value;
            if (subscription && seats)
                this.estimatePartner(
                    partnerId,
                    subscription,
                    seats,
                    companyForm.$('companyId').value
                );
        };

        initials &&
            initials.subscription.package_valid === 0 &&
            handleEstimation();

        companyForm.observe({
            path: 'subscription',
            key: 'value',
            call: (form) => {
                handleEstimation();
                if (
                    this.subscriptionPlans.filter(
                        (s) => s.code === form.change.newValue
                    )[0].isFree
                ) {
                    companyForm.$('seats').value = 10;
                    companyForm.$('valid').value = '1200';
                }
            },
        });

        companyForm.observe({
            path: 'seats',
            key: 'value',
            call: () => {
                handleEstimation();
            },
        });
        this.companyForm = companyForm;
    }

    @action
    selectPlanDuration(baseId, yearly) {
        const item = this.basePlans.find((item) => item.baseId === baseId);
        if (item) {
            item.selected = yearly ? item.yearly : item.monthly;
            this.selectSeats(baseId, item.selectedSeats);
        }
    }

    @action
    selectSeats(baseId, seats) {
        const item = this.basePlans.find((item) => item.baseId === baseId);
        if (item) {
            item.selectedSeats = seats;
            if (item.selected) {
                item.selectedTier = item.selected.tiers.find(
                    (x) => x.ending_unit === seats
                );
                if (!item.selectedTier)
                    item.selectedTier = item.selected.tiers.find(
                        (x) => x.starting_unit <= seats
                    );

                item.perUserPrice = (
                    item.selectedTier.price /
                    (item.selected.period_unit === 'year' ? 12 : 1) /
                    item.selectedTier.ending_unit /
                    100
                ).toFixed(2);
            }
        }
    }
    @action
    setPlan(plan) {
        this.selectedPlan = plan;
        this.planPeriod = this.selectedPlan && this.selectedPlan.period_unit;
    }

    @action
    setTier(seats) {
        this.selectedSeats = seats;
    }

    @computed
    get selectedTier() {
        if (!this.selectedPlan || !this.selectedPlan.tiers) return null;

        return this.selectedPlan.tiers.find((tier) => {
            return tier.ending_unit === this.selectedSeats;
        });
    }

    @action
    async loadToken(companyId) {
        let result = await services.Companies.subscriptionsService(
            companyId
        ).braintreetoken();
        return result;
    }

    @action setBillingInfo(billingInfo) {
        this.billingInfo = billingInfo;
    }

    @action setCoupon(coupon) {
        this.coupon = coupon;
        this.estimateResult = null;
    }

    @action
    async loadPlans(companyId) {
        if (this.loadingPlans) return;
        this.loadingPlans = true;
        this.error = null;
        try {
            let result = await services.Companies.subscriptionsService(
                companyId
            ).plans();
            result.forEach((x) => {
                x.yearly = x.id.endsWith('-yearly');
                x.monthly = x.id.endsWith('-monthly');
                x.baseId = x.id.replace(/-yearly|-monthly/, '');
            });
            const groupedByBase = groupBy(result, 'baseId');
            const basePlans = [];
            Object.keys(groupedByBase).forEach((baseId) => {
                const base = {
                    baseId,
                    name:
                        groupedByBase[baseId][0] &&
                        groupedByBase[baseId][0].name,
                    yearly: groupedByBase[baseId].find((x) => x.yearly),
                    monthly: groupedByBase[baseId].find((x) => x.monthly),
                };

                base.selected = base.yearly || base.monthly;
                this.selectSeats(
                    base.baseId,
                    base.selected.tiers[0].ending_unit
                );
                base.selectedSeats = base.selected.tiers[0].ending_unit;
                base.selectedTier = base.selected.tiers[0];
                base.perUserPrice = (
                    base.selectedTier.price /
                    (base.selected.period_unit === 'year' ? 12 : 1) /
                    base.selectedSeats /
                    100
                ).toFixed(2);
                basePlans.push(base);
            });
            this.basePlans.replace(basePlans);

            let my = await services.Companies.subscriptionsService(
                companyId
            ).list();
            if (!my || my.status !== 'active') {
                this.my = null;
            }
            if (my && my.status === 'active') {
                this.my = { planId: my.plan_id, seats: my.plan_quantity };
            }
        } catch (e) {
            console.error(e);
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingPlans = false;
        }
    }

    @action
    async cancelSubscription(companyId) {
        try {
            this.estimateResult = await services.Companies.subscriptionsService(
                companyId
            ).cancel();
            return 'ok';
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    @action
    async estimate(companyId, planId, seats) {
        let key = `${companyId}-${planId}-${seats}`;
        this.couponError = null;
        if (this.estimating === key) return;
        this.estimateResult = null;
        this.estimating = key;
        try {
            this.estimateResult = await services.Companies.subscriptionsService(
                companyId
            ).create({
                planId,
                coupon: this.coupon,
                seats,
                estimate: true,
            });
        } catch (e) {
            let error = extractErrorMessage(e);
            if (error.startsWith('coupon'))
                this.couponError = 'Invalid coupon code';
            else this.error = error;
        } finally {
            if (this.estimating === key) this.estimating = null;
        }
    }

    @action
    async create(companyId, planId, seats, token) {
        if (this.payInProcess) return;
        this.payInProcess = true;
        this.error = null;
        try {
            let result = await services.Companies.subscriptionsService(
                companyId
            ).create({
                planId,
                coupon: this.coupon,
                seats,
                token,
                ...this.billingInfo,
            });
            this.commonStore.postAffTrackerSale(
                this.price,
                result.subscription.id,
                planId
            );
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.payInProcess = false;
        }
    }

    @computed get futureCredit() {
        if (
            this.estimateResult &&
            this.estimateResult.estimate &&
            this.estimateResult.estimate.credit_note_estimates
        ) {
            return (
                sumBy(
                    this.estimateResult.estimate.credit_note_estimates,
                    'amount_available'
                ) || 0
            );
        }

        return 0;
    }

    @computed get nextBilling() {
        if (this.estimateResult && this.estimateResult.estimate)
            return moment
                .unix(
                    this.estimateResult.estimate.subscription_estimate
                        .next_billing_at
                )
                .format('L');

        return '';
    }

    @computed get price() {
        if (!this.selectedTier) return 0;
        if (this.estimateResult && this.estimateResult.estimate) {
            if (this.estimateResult.estimate.invoice_estimate) {
                let total = this.estimateResult.estimate.invoice_estimate.total;
                let credit =
                    this.estimateResult.estimate.invoice_estimate
                        .credits_applied || 0;

                return ((total - credit) / 100).toFixed(0);
            }
            if (
                this.estimateResult.estimate &&
                this.estimateResult.estimate.next_invoice_estimate
            )
                return (
                    this.estimateResult.estimate.next_invoice_estimate.total /
                    100
                ).toFixed(0);
        }

        return (this.selectedTier.price / 100).toFixed(0);
    }

    @computed get nextPlanPrice() {
        if (!this.selectedTier) return 0;
        return (
            Math.max(0, this.selectedTier.price - this.futureCredit) / 100
        ).toFixed(0);
    }

    planCode(ending_unit) {
        if (!this.selectedTier || !this.selectedPlan) return '';

        return `${this.selectedPlan.id}-${
            ending_unit || this.enterprise_units
        }`;
    }

    // new subscriptions plans code:

    loadSubscriptionPlans = generateLoadList(
        'subscriptionPlans',
        this,
        'loadingSubscriptionsPlans',
        async (partnerId) => {
            return services.Partners.subscriptionsService(partnerId).plans();
        },
        'subscriptionPlans'
    );

    deactivateCompanySubscription = generateUpdateEntity(
        'deactivateCompanySubscription',
        this,
        'deactivatingCompanySubscription',
        async (partnerId, companyId) => {
            return services.Partners.companiesService(partnerId).deactivate(
                companyId
            );
        }
    );

    updateCompanySubscription = generateUpdateEntity(
        'updateCompanySubscription',
        this,
        'updatingCompanySubscription',
        async (partnerId, companyId, body) => {
            return services.Partners.companiesService(
                partnerId
            ).updateSubscription(companyId, body);
        }
    );

    @action setPartnerEstimate(estimate) {
        this.partnerEstimate = estimate;
    }

    @action
    async loadEstimation(partnerId, subscription, seats, companyId) {
        this.estimationLoadingId++;
        const fetchId = this.estimationLoadingId;
        this.estimationError = null;
        this.estimationLoading = true;
        try {
            let service = companyId
                ? await services.Partners.companiesService(
                      partnerId
                  ).subscriptionsService(companyId)
                : services.Partners.subscriptionsService(partnerId);
            let result = await service.estimate({ subscription, seats });
            this.setPartnerEstimate(result);
            return true;
        } catch (e) {
            let error = extractErrorMessage(e);
            this.estimationError = error;
        } finally {
            if (this.estimationLoadingId === fetchId)
                this.estimationLoading = false;
        }
    }
    @action
    async canBeRemoved(partnerId, companyId) {
        this.canBeRemovedLoading = true;
        try {
            let result = await services.Partners.companiesService(
                partnerId
            ).canBeRemoved(companyId);
            return result;
        } catch (e) {
            let error = extractErrorMessage(e);
            this.error = error;
        } finally {
            this.canBeRemovedLoading = false;
        }
    }

    @action
    async canReduceSeats(partnerId, companyId, seats) {
        this.canReduceSeatsLoading = true;
        try {
            let result = await services.Partners.companiesService(
                partnerId
            ).canReduceSeats(companyId, { newSeats: seats });
            return result;
        } catch (e) {
            let error = extractErrorMessage(e);
            this.error = error;
        } finally {
            this.canReduceSeatsLoading = false;
        }
    }
}

export default SubscriptionsStore;
