import React, { useMemo } from 'react';
import moment from 'moment';
import { formatDate } from '../../../utils/helpers';
import Box from '@material-ui/core/Box';
import SimpleTable from '../../SimpleTable';
import ProgressWithPercentage from '../../ProgressWithPercentage';

function RemindersV2({ events, subject }) {
    let event = useMemo(() => {
        return events && events.find((x) => x.id === subject.eventId);
    }, [subject, events]);

    return event && event.reminders ? (
        <div>
            {event.reminders &&
                event.reminders.map((reminder, j) => (
                    <Box key={j}>{moment(reminder.send).format('MMMM D')}</Box>
                ))}
        </div>
    ) : null;
}

function SubjectsProgress({ training, subjects, reminders, userCount }) {
    const columns = useMemo(
        () => [
            {
                Header: 'Delivery date',
                accessor: 'send',
                Cell: (x) => <span>{formatDate(x.value)}</span>,
            },
            {
                Header: 'Name',
                accessor: 'name',
                Cell: (x) => <div className="row-title">{x.value}</div>,
            },
            {
                Header: 'Average score',
                accessor: 'score',
                Cell: (x) =>
                    x.row.original.type === 3 ? (
                        <ProgressWithPercentage
                            value={
                                x.row.original.questions_count
                                    ? Math.round(
                                          (100 * x.row.original.correctsStats) /
                                              (x.row.original.questions_count *
                                                  userCount)
                                      )
                                    : 0
                            }
                            medium="true"
                            thin="true"
                        />
                    ) : (
                        <ProgressWithPercentage
                            value={
                                x.row.original.total
                                    ? Math.round(
                                          (100 * x.value) / x.row.original.total
                                      )
                                    : 0
                            }
                            medium="true"
                            thin="true"
                        />
                    ),
            },
            {
                Header: 'Reminders',
                Cell: (x) => (
                    <div>
                        {training && training.version === 1 ? (
                            <div>
                                {reminders &&
                                    reminders.map((reminder, j) => (
                                        <Box key={j}>
                                            {moment(x.row.original.send)
                                                .add(
                                                    reminder.delay,
                                                    reminder.delayType === 3
                                                        ? 'days'
                                                        : reminder.delayType ===
                                                          2
                                                        ? 'hours'
                                                        : 'minutes'
                                                )
                                                .format('MMMM D')}
                                        </Box>
                                    ))}
                            </div>
                        ) : (
                            <RemindersV2
                                subject={x.row.original}
                                events={training && training.events}
                            />
                        )}
                    </div>
                ),
                disableGlobalFilter: true,
            },
        ],
        []
    );

    return <SimpleTable columns={columns} data={subjects} needsFilter={true} />;
}

export default SubjectsProgress;
