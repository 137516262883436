import React, { Fragment } from 'react';
import pluralize from 'pluralize';
import moment from 'moment';
import {
    Typography,
    Input,
    Checkbox,
    InputLabel,
    ListItemText,
    Select,
    MenuItem,
    FormControl,
} from '@material-ui/core';
import { ThemedButton } from '../themed/ThemedComponents';
import './filter-modal.scss';
import { DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { observer } from 'mobx-react';

function FilterModal({
    onRequestClose,
    items,
    nameFilter1,
    filter1,
    setFilter1,
    nameFilter2,
    filter2,
    setFilter2,
    nameDateFilter1,
    startDateFilter1,
    setStartDateFilter1,
    endDateFilter1,
    setEndDateFilter1,
    nameDateFilter2,
    startDateFilter2,
    setStartDateFilter2,
    endDateFilter2,
    setEndDateFilter2,
    filterCount,
    clearFilters,
    array1,
    array2,
    isAreaFilter1,
}) {
    return (
        <Fragment>
            <div className={'modal__header'}>
                <h3 className="modal__heading">Filters</h3>
                <img
                    src="/static/img/close-dark.svg"
                    alt="Close"
                    onClick={onRequestClose}
                />
            </div>
            <div className={'modalContent'}>
                {/* filter1 */}
                <FormControl
                    variant="outlined"
                    color="primary"
                    className={filter2.length > 0 ? 'threat-areas-filter' : ''}
                >
                    <InputLabel>{nameFilter1}</InputLabel>
                    {isAreaFilter1 ? (
                        <Select
                            multiple
                            value={filter1}
                            onChange={(e) => setFilter1(e.target.value)}
                            input={<Input />}
                            color="primary"
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                variant: 'menu',
                                getContentAnchorEl: null,
                            }}
                        >
                            {array1.sort().map((area) => (
                                <MenuItem key={area} value={area}>
                                    <Checkbox
                                        checked={filter1.indexOf(area) > -1}
                                        color="primary"
                                    />
                                    <ListItemText primary={area} />
                                </MenuItem>
                            ))}
                        </Select>
                    ) : (
                        <Select
                            multiple
                            value={filter1}
                            onChange={(e) => setFilter1(e.target.value)}
                            input={<Input />}
                            color="primary"
                            renderValue={(selected) =>
                                array1
                                    .filter((list) =>
                                        selected.includes(list.id)
                                    )
                                    .map((list, i, arr) => (
                                        <span key={list.id}>
                                            {/* don't put comma after last list  */}
                                            {list.title}
                                            {i !== arr.length - 1 ? ', ' : ''}
                                        </span>
                                    ))
                            }
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                variant: 'menu',
                                getContentAnchorEl: null,
                            }}
                        >
                            {array1.map((list) => (
                                <MenuItem key={list.title} value={list.id}>
                                    <Checkbox
                                        checked={filter1.indexOf(list.id) > -1}
                                        color="primary"
                                    />
                                    <ListItemText primary={list.title} />
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </FormControl>

                {/* filter2 */}
                <FormControl variant="outlined" color="primary">
                    <InputLabel>{nameFilter2}</InputLabel>
                    <Select
                        multiple
                        value={filter2}
                        onChange={(e) => setFilter2(e.target.value)}
                        input={<Input />}
                        color="primary"
                        renderValue={(selected) =>
                            array2
                                .filter((list) => selected.includes(list.id))
                                .map((list, i, arr) => (
                                    <span key={list.id}>
                                        {/* don't put comma after last list  */}
                                        {list.name}
                                        {i !== arr.length - 1 ? ', ' : ''}
                                    </span>
                                ))
                        }
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            variant: 'menu',
                            getContentAnchorEl: null,
                        }}
                    >
                        {array2
                            .sort((a, b) => a.name - b.name)
                            .map((list) => (
                                <MenuItem key={list.name} value={list.id}>
                                    <Checkbox
                                        checked={filter2.indexOf(list.id) > -1}
                                        color="primary"
                                    />
                                    <ListItemText primary={list.name} />
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>

                <MuiPickersUtilsProvider utils={MomentUtils}>
                    {/* date filter1 */}
                    <InputLabel className="filter-label">
                        {nameDateFilter1}
                    </InputLabel>
                    <div className="date-pickers">
                        <DatePicker
                            value={startDateFilter1}
                            onChange={(date) => setStartDateFilter1(date)}
                            variant="dialog"
                            inputVariant="outlined"
                            color="primary"
                            format="L"
                            allowKeyboardControl
                            autoOk
                            maxDate={
                                endDateFilter1 !== null
                                    ? moment(endDateFilter1)
                                    : undefined
                            }
                            maxDateMessage={`Set date earlier than (${moment(
                                endDateFilter1
                            ).format('L')})`}
                            emptyLabel="From"
                            showTodayButton
                            clearable
                            cancelLabel=""
                            className={
                                startDateFilter1 === null
                                    ? 'filter-empty-label'
                                    : ''
                            }
                        />
                        <DatePicker
                            value={endDateFilter1}
                            onChange={(date) => setEndDateFilter1(date)}
                            variant="dialog"
                            inputVariant="outlined"
                            color="primary"
                            format="L"
                            autoOk
                            minDate={
                                startDateFilter1 !== null
                                    ? moment(startDateFilter1)
                                    : undefined
                            }
                            minDateMessage={`Set date later than (${moment(
                                endDateFilter1
                            ).format('L')})`}
                            emptyLabel="To"
                            showTodayButton
                            clearable
                            cancelLabel=""
                            className={
                                endDateFilter1 === null
                                    ? 'filter-empty-label'
                                    : ''
                            }
                        />
                    </div>

                    {/* date filter2 */}
                    <InputLabel className="filter-label">
                        {nameDateFilter2}
                    </InputLabel>
                    <div className="date-pickers">
                        <DatePicker
                            value={startDateFilter2}
                            onChange={(date) => setStartDateFilter2(date)}
                            variant="dialog"
                            inputVariant="outlined"
                            color="primary"
                            format="L"
                            allowKeyboardControl
                            autoOk
                            maxDate={
                                endDateFilter2 !== null
                                    ? moment(endDateFilter2)
                                    : undefined
                            }
                            maxDateMessage={`Set date earlier than (${moment(
                                endDateFilter2
                            ).format('L')})`}
                            emptyLabel="From"
                            showTodayButton
                            clearable
                            cancelLabel=""
                            className={
                                startDateFilter2 === null
                                    ? 'filter-empty-label'
                                    : ''
                            }
                        />
                        <DatePicker
                            value={endDateFilter2}
                            onChange={(date) => setEndDateFilter2(date)}
                            variant="dialog"
                            inputVariant="outlined"
                            color="primary"
                            format="L"
                            autoOk
                            minDate={
                                startDateFilter2 !== null
                                    ? moment(startDateFilter2)
                                    : undefined
                            }
                            minDateMessage={`Set date later than (${moment(
                                endDateFilter2
                            ).format('L')})`}
                            emptyLabel="To"
                            showTodayButton
                            clearable
                            cancelLabel=""
                            className={
                                endDateFilter2 === null
                                    ? 'filter-empty-label'
                                    : ''
                            }
                        />
                    </div>
                </MuiPickersUtilsProvider>
            </div>
            <div className={'modalFooter'}>
                <div className="d-flex flex-justify-between flex-fill">
                    <div className="d-flex flex-fill flex-column">
                        <Typography
                            color="primary"
                            style={
                                filterCount === 0
                                    ? { fontWeight: '600' }
                                    : { fontWeight: '800' }
                            }
                        >
                            {filterCount} {pluralize('filter', filterCount)}{' '}
                            added
                        </Typography>
                        <Typography>
                            {filterCount > 0
                                ? `${items.length} ${pluralize(
                                      'result',
                                      items.length
                                  )} will be shown`
                                : ''}
                        </Typography>
                    </div>
                    {filterCount > 0 && (
                        <ThemedButton
                            secondary
                            onClick={clearFilters}
                            style={{ marginRight: '16px' }}
                        >
                            Clear
                        </ThemedButton>
                    )}
                </div>
                <ThemedButton primary onClick={onRequestClose}>
                    Apply filters
                </ThemedButton>
            </div>
        </Fragment>
    );
}

export default observer(FilterModal);
