import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import BaseCompanyDependentChildContainer from '../common/BaseCompanyDependentChildContainer';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

@inject('automationStore', 'authStore')
@observer
class AutomationEventViewContainer extends BaseCompanyDependentChildContainer {
    state = {
        error: '',
        loadedOrError: null,
    };

    async doLoadData() {
        await this.props.automationStore.loadAutomationEventStatus(
            this.props.companyId,
            this.props.automationId,
            this.props.eventId
        );
    }

    resendEmail = async (email) => {
        await this.props.automationStore.resendEventMail(
            this.props.companyId,
            this.props.automationId,
            this.props.eventId,
            email
        );
    };

    render() {
        const {
            loadingAutomationStatus,
            automationStatus,
            sendAutomationEmails,
            error,
        } = this.props.automationStore;
        const { anonymousTracking } = this.props.authStore;
        let firstRow, titleSubjects;
        if (automationStatus) {
            firstRow =
                automationStatus.users.length > 0
                    ? automationStatus.users[0]
                    : [];
            titleSubjects = firstRow.subjects ? firstRow.subjects : [];
        }

        return (
            <Fragment>
                <ErrorMessage error={error || this.state.loadedOrError} />
                {loadingAutomationStatus ? (
                    <ThemedSpinner />
                ) : (
                    <Fragment>
                        {automationStatus ? (
                            <div>
                                <h3>Event Details schedule</h3>

                            </div>
                        ) : (
                            <div> Automation Not Found</div>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default withRouter(AutomationEventViewContainer);
