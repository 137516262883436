import React from 'react';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import styled, { withTheme } from 'styled-components';

const ThemedCheckmark = styled.div`
    position: absolute;
    left: 0px;
    width: 14px;
    height: 22px;
`;

class ThemedCheckmarkSVG extends React.Component {
    render() {
        return (
            <ThemedCheckmark>
                <SvgLoader path="/static/img/check-green.svg">
                    <SvgProxy selector="path" fill={this.props.theme.primary} />
                </SvgLoader>
            </ThemedCheckmark>
        );
    }
}

export default withTheme(ThemedCheckmarkSVG);
