import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { enforceSlash } from '../../utils/helpers';
import { Table, Tr, Td, Th, Thead } from 'reactable';

@inject('superStore')
@observer
class TemplatesContainer extends React.Component {
    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        await this.props.superStore.loadTemplates();
    }

    render() {
        const { loadingTemplates, templates, templatesError } =
            this.props.superStore;
        const { match } = this.props;

        return loadingTemplates ? (
            <ThemedSpinner />
        ) : (
            <Fragment>
                <ErrorMessage error={templatesError} />

                <Row>
                    {templates && templates.length > 0 && (
                        <Col md="12" xs="12" className="leftMenu">
                            <Table className="table" id="table">
                                <Thead>
                                    <Th column="Id" className="">
                                        Id
                                    </Th>
                                    <Th column="Name" className="">
                                        Native Name
                                    </Th>
                                    <Th column="Rules" className="">
                                        # of Rules
                                    </Th>
                                    <Th column="public" className="">
                                        Public{' '}
                                    </Th>
                                    <Th column="Subjects" className="">
                                        # of Subjects
                                    </Th>
                                    <Th column="Order" className="">
                                        order
                                    </Th>
                                </Thead>
                                {templates.map((template, i) => (
                                    <Tr key={template.id}>
                                        <Td
                                            column={'Id'}
                                            value={template.id}
                                            className=""
                                        >
                                            <Link
                                                to={`${enforceSlash(
                                                    match.url
                                                )}${template.id}`}
                                            >
                                                {template.id}
                                            </Link>
                                        </Td>
                                        <Td
                                            column={'Name'}
                                            value={template.name}
                                        >
                                            <Link
                                                to={`${enforceSlash(
                                                    match.url
                                                )}${template.id}`}
                                            >
                                                {template.title}
                                            </Link>
                                        </Td>
                                        <Td
                                            column={'Rules'}
                                            value={template.rulesCount}
                                        >
                                            {template.rulesCount}
                                        </Td>
                                        <Td
                                            column={'public'}
                                            value={template.name}
                                        >
                                            <Fragment>
                                                {template.isPublic > 0 ? (
                                                    <span
                                                        className={
                                                            'flag static-flag '
                                                        }
                                                        style={{
                                                            backgroundImage: `url(/static/img/icon-completed.png)`,
                                                        }}
                                                    >
                                                        &nbsp;
                                                    </span>
                                                ) : (
                                                    <span
                                                        className={
                                                            'flag static-flag '
                                                        }
                                                        style={{
                                                            backgroundImage: `url(/static/img/icon-close.png)`,
                                                        }}
                                                    >
                                                        &nbsp;
                                                    </span>
                                                )}
                                            </Fragment>
                                        </Td>
                                        <Td
                                            column={'Subjects'}
                                            value={template.templates}
                                        >
                                            {template.subjects}
                                        </Td>
                                        <Td
                                            column={'Order'}
                                            value={template.order}
                                        >
                                            {template.order_number}
                                        </Td>
                                    </Tr>
                                ))}
                            </Table>
                        </Col>
                    )}
                </Row>
            </Fragment>
        );
    }
}

export default withRouter(TemplatesContainer);
