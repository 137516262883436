import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Waiting from '../../components/waiting';
import { querystring } from '../../utils/helpers';

@inject('authStore')
@observer
class SignIn extends React.Component {
    componentDidMount() {
        let { token } = this.props.match.params;
        this.props.authStore.setToken(token);

        let redirect = querystring('redirect') || '/';
        if (!redirect.startsWith('/')) redirect = `/${redirect}`;
        this.props.history.push(redirect);
    }

    render() {
        return <Waiting waitingActive={true} fullScreen={true} />;
    }
}

export default withRouter(SignIn);
