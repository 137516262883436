import React from 'react';
import { Form, FormGroup, Label, Button, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import moment from 'moment';
import ThemedSpinner from '../themed/ThemedSpinner';

@observer
class AddPackage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            packageUsers: '',
            packageValidUntil: '',
            packageValidFrom: '',
            packageValidTo: '',
            packageTrial: '0',
            freemiumPackage: '',
            packageUserError: '',
            packageValidUntilError: '',
            error: '',
        };
    }

    onChangeUsers(event) {
        this.setState({ packageUsers: event.target.value });
        if (event.target.value.length > 0) {
            this.setState({ packageUserError: '' });
        } else {
            // too short
            this.setState({ packageUserError: 'Must select number of users' });
        }
    }

    onChangeTrial(event) {
        this.setState({ packageTrial: event.target.value });
    }

    changePackageValidFrom = (event) => {
        this.setState({
            packageValidFrom: event.target.value,
            packageValidUntil: '-1',
        });
    };

    changePackageValidTo = (event) => {
        this.setState({
            packageValidTo: event.target.value,
            packageValidUntil: '-1',
        });
    };

    onChangeFreemium(event) {
        this.setState({ freemiumPackage: event.target.value });
    }

    onChangeValidUntil(event) {
        this.setState({ packageValidUntil: event.target.value });

        if (event.target.value.length > 0) {
            this.setState({ packageValidUntilError: '' });
            if (event.target.value !== '-1') {
                let valid_from = new Date();
                let valid_to;
                if (event.target.value === '0') {
                    valid_to = moment(valid_from).add(2, 'weeks');
                } else if (event.target.value === '1200') {
                    //Special 'Eternal' case where we don't expect this package to expire
                    valid_to = new Date('2200-1-1');
                } else {
                    valid_to = moment(valid_from).add(
                        parseInt(event.target.value),
                        'months'
                    );
                }
                console.log(valid_to);
                this.setState({
                    packageValidFrom: moment(valid_from).format('YYYY-MM-DD'),
                    packageValidTo: moment(valid_to).format('YYYY-MM-DD'),
                });
            }
        } else {
            // too short
            this.setState({
                packageValidUntilError: 'Must select valid until',
            });
        }
    }

    async savePackage() {
        let inputOk = true;

        if (this.state.packageUsers.length > 0) {
            this.setState({ packageUserError: '' });
        } else {
            this.setState({ packageUserError: 'Must select number of users' });
            inputOk = false;
        }

        if (this.state.packageValidUntil === '-1') {
        }
        if (this.state.packageValidUntil.length > 0) {
            this.setState({ packageValidUntilError: '' });
        } else {
            this.setState({
                packageValidUntilError: 'Must select valid until',
            });
            inputOk = false;
        }

        if (inputOk) {
            let valid_from = new Date(this.state.packageValidFrom);
            let valid_to = new Date(this.state.packageValidTo);

            this.props.savePackage(
                this.state.packageUsers,
                valid_from,
                valid_to,
                this.state.packageTrial,
                this.state.freemiumPackage
            );
        }
    }

    render() {
        return (
            <div>
                <h4>Add new package</h4>
                <Form inline>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label for="packageUsers" className="mr-sm-2">
                            Users
                        </Label>
                        <select
                            name="packageUsers"
                            className="bigInput"
                            value={this.state.packageUsers}
                            onChange={(e) => this.onChangeUsers(e)}
                        >
                            <option value="">Select max number of users</option>
                            <option value="5" className="tl">
                                5
                            </option>
                            <option value="10" className="tl">
                                10
                            </option>
                            <option value="25" className="tl">
                                25
                            </option>
                            <option value="50" className="tl">
                                50
                            </option>
                            <option value="100" className="tl">
                                100
                            </option>
                            <option value="150" className="tl">
                                150
                            </option>
                            <option value="200" className="tl">
                                200
                            </option>
                            <option value="300" className="tl">
                                300
                            </option>
                            <option value="400" className="tl">
                                400
                            </option>
                            <option value="500" className="tl">
                                500
                            </option>
                            <option value="750" className="tl">
                                750
                            </option>
                            <option value="1000" className="tl">
                                1000
                            </option>
                            <option value="1500" className="tl">
                                1500
                            </option>
                            <option value="2000" className="tl">
                                2000
                            </option>
                            <option value="3000" className="tl">
                                3000
                            </option>
                            <option value="5000" className="tl">
                                5000
                            </option>
                            <option value="10000" className="tl">
                                10000
                            </option>
                        </select>
                        <div className="error">
                            {this.state.packageUserError}
                        </div>
                    </FormGroup>

                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label for="packageTrial" className="mr-sm-2">
                            Trial
                        </Label>
                        <select
                            name="packageTrial"
                            className="bigInput"
                            value={this.state.packageTrial}
                            onChange={(e) => this.onChangeTrial(e)}
                        >
                            <option value="0">No</option>
                            <option value="1" className="tl">
                                Yes
                            </option>
                        </select>
                        <div className="error"></div>
                    </FormGroup>
                    {this.props.freemiums && this.props.freemiums.length > 0 && (
                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <Label for="packageTrial" className="mr-sm-2">
                                Freemium
                            </Label>
                            <select
                                name="packageTrial"
                                className="bigInput"
                                value={this.state.freemiumPackage}
                                onChange={(e) => this.onChangeFreemium(e)}
                            >
                                <option value="">No</option>
                                {this.props.freemiums.map((f) => (
                                    <option key={f.code} value={f.code}>
                                        {f.code}
                                    </option>
                                ))}
                            </select>
                            <div className="error"></div>
                        </FormGroup>
                    )}
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label for="packageValidUntil" className="mr-sm-2">
                            Valid
                        </Label>
                        <select
                            name="packageValidUntil"
                            className="bigInput"
                            value={this.state.packageValidUntil}
                            onChange={(e) => this.onChangeValidUntil(e)}
                        >
                            <option value="">Select timespan</option>
                            <option value="0" className="tl">
                                2 weeks
                            </option>
                            <option value="1" className="tl">
                                Month
                            </option>
                            <option value="3" className="tl">
                                3 months
                            </option>
                            <option value="6" className="tl">
                                6 months
                            </option>
                            <option value="12" className="tl">
                                Year
                            </option>
                            <option value="18" className="tl">
                                18 months
                            </option>
                            <option value="24" className="tl">
                                2 years
                            </option>
                            <option value="60" className="tl">
                                5 years
                            </option>
                            <option value="120" className="tl">
                                10 years
                            </option>
                            <option value="1200" className="tl">
                                Eternal
                            </option>
                            <option value="-1" className="tl">
                                Custom
                            </option>
                        </select>
                        <br />
                        <Row>
                            <Col>
                                <input
                                    className="bigInput"
                                    type="date"
                                    name="validFrom"
                                    value={this.state.packageValidFrom}
                                    onChange={this.changePackageValidFrom}
                                />
                            </Col>
                            <Col>
                                <input
                                    className="bigInput"
                                    type="date"
                                    name="validTo"
                                    value={this.state.packageValidTo}
                                    onChange={this.changePackageValidTo}
                                />
                            </Col>
                        </Row>
                        <div className="error">
                            {this.state.packageValidUntilError}
                        </div>
                    </FormGroup>

                    {this.props.addingPackage ? (
                        <ThemedSpinner size={45} />
                    ) : (
                        <Button
                            className="btn btn-primary"
                            onClick={() => this.savePackage()}
                        >
                            Add
                        </Button>
                    )}
                </Form>

                {this.state.error && (
                    <div className="danger">
                        <strong>{this.state.error}</strong>
                    </div>
                )}
            </div>
        );
    }
}

AddPackage.propTypes = {
    savePackage: PropTypes.func,
};

export default AddPackage;
