import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import { inject, observer } from 'mobx-react';
import SubjectList from '../components/manager/catalog/subject-list';
import Subject from './Subject';
import services from '../services';
import { Helmet } from 'react-helmet';

@inject('store')
@observer
class Catalog extends React.Component {
    state = { error: '', loadedOrError: null };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState) return; // not yet initialized
        if (nextProps.companyId !== prevState.companyId) {
            return {
                companyId: nextProps.companyId,
                loadedOrError: null,
            };
        }

        // No state update necessary
        return null;
    }

    componentDidMount() {
        // It's preferable in most cases to wait until after mounting to load data.
        // See below for a bit more context...
        this.loadData();
    }

    componentDidUpdate() {
        /* LRT: should not be necessary (and calls load function 50 times)
   if (this.state.loadedOrError === null) {
       // At this point, we're in the "commit" phase, so it's safe to load the new data.
       this.loadData()
   }
   */
    }

    async loadData() {
        try {
            this.setState({ categories: [] });
            let result;
            if (this.props.companyId)
                result = await services.Companies.categoryService(
                    this.props.companyId
                ).list();
            //else
            //    result = await services.Categories.list();

            this.setState({ categories: result });
        } catch (e) {
        } finally {
            //this.loadingCategories = false
        }
        this.setState({ ...this.state, loadedOrError: true });
    }

    render() {
        const { match } = this.props;
        const { categories } = this.state;

        return (
            <Container className="manager-content">
                <Helmet>
                    <title>Subjects</title>
                </Helmet>
                <Switch>
                    <Route
                        path={`${match.url}/:category?`}
                        exact
                        render={(props) => (
                            <SubjectList
                                parentMatch={match}
                                categories={categories}
                                match={props.match}
                            />
                        )}
                    />

                    <Route
                        path={`${match.url}/subject/:id`}
                        render={(props) => (
                            <Subject
                                companyId={this.props.companyId}
                                id={parseInt(props.match.params.id)}
                                categories={categories}
                            />
                        )}
                    />
                </Switch>
            </Container>
        );
    }
}

export default withRouter(Catalog);
