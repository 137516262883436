import React, { useEffect, Fragment, useState } from 'react';
import { observer } from 'mobx-react';
import AssessmentListTable from '../../components/manager/assessment/assessment-list-table';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import FilterIcon from '@material-ui/icons/FilterList';
import Modal from 'react-modal';
import FilterModal from '../../components/manager/filter-modal';
import { Link, useHistory } from 'react-router-dom';
import {
    ThemedButton,
    ThemedNav,
    ThemedNavItem,
    ThemedNavLink,
} from '../../components/themed/ThemedComponents';
import { MenuItem } from '@material-ui/core';
import './AssessmentList.scss';
import pluralize from 'pluralize';
import CancelIcon from '@material-ui/icons/Cancel';
import Snackbar from '../../components/Snackbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import behaviours from '../../data/behaviours.json';
import mapValues from 'lodash/mapValues';
import ErrorMessage from '../../components/errormessage';
import NoItemsYet from '../../components/manager/no-items-yet';
import {
    Block,
    FlexDiv,
    SpaceBetweenDiv,
} from '../../components/design-system/containers';
import { Heading2 } from '../../components/design-system/headings';
import { TextTiny, TextDefault } from '../../components/design-system/texts';
import { SearchInput, TextInput } from '../../components/design-system/inputs';
import { FullWidthLink } from '../../components/design-system/miscellaneous';

function AssessmentList({ companyId, parentMatch, tab = 'all' }) {
    const { assessmentStore, store, listStore, commonStore, brandingStore } =
        useStores();
    const [filterModal, setFilterModal] = useState(false);

    const {
        loadingAssessments,
        loadingAssessmentsInBackground,
        assessments,
        searchValue,
        areaFilter,
        employeeFilter,
        sortBy,
        filteredAssessments,
        startDueDateFilter,
        endDueDateFilter,
        startDeliveryDateFilter,
        endDeliveryDateFilter,
        error,
    } = assessmentStore;
    const { currentCompany } = store;
    const { listsWithUsers: lists } = listStore;

    const [message, setMesage] = useState(null);
    const [messageShown, setMessageShown] = useState(false);
    const [messageType, setMessageType] = useState(null);
    let history = useHistory();

    useEffect(() => {
        assessmentStore.loadAssessments(companyId);
        listStore.loadCompanyLists(companyId);
    }, [companyId]);

    const filterCount = [
        areaFilter.length ? 1 : 0,
        employeeFilter.length ? 1 : 0,
        startDueDateFilter !== null && endDueDateFilter !== null ? 1 : 0,
        startDeliveryDateFilter !== null && endDeliveryDateFilter !== null
            ? 1
            : 0,
    ].reduce((a, b) => a + b);

    const clearFilters = () => {
        assessmentStore.setAreaFilter([]);
        assessmentStore.setEmployeeFilter([]);
        assessmentStore.setStartDueDateFilter(null);
        assessmentStore.setEndDueDateFilter(null);
        assessmentStore.setStartDeliveryDateFilter(null);
        assessmentStore.setEndDeliveryDateFilter(null);
    };

    const hideFilterModal = () => {
        setFilterModal(false);
    };

    const showFilterModal = () => {
        setFilterModal(true);
    };

    const onDelete = (id) => {
        commonStore.showConfirm(
            'Assessment will be deleted forever.',
            'Yes, delete',
            'Delete this assessment?',
            async () => {
                await assessmentStore.delete(companyId, id);
                showSnackBar('Assessment deleted!');
            },
            true
        );
    };

    const onPause = (id) => {
        commonStore.showConfirm(
            'Are you sure you want to stop this assessment?',
            'Stop',
            'Stop assessment',
            async () => {
                await pauseAssessment(id);
                showSnackBar('Assessment paused!');
            },
            false
        );
    };

    const pauseAssessment = async (id) => {
        await assessmentStore.pauseAssessment(companyId, id);
    };

    const completeAssessment = async (id) => {
        await assessmentStore.completeAssessment(companyId, id);
    };

    const showSnackBar = (message, messageType = 'success') => {
        setMesage(message);
        setMessageType(messageType);
        setMessageShown(true);
    };

    const closeSnackbar = () => {
        setMessageShown(false);
        setMesage(null);
        setMessageType(null);
    };

    const tabs = [
        {
            code: 'all',
            title: 'All',
        },
        {
            code: 'active',
            title: 'Active',
        },
        {
            code: 'completed',
            title: 'Completed',
        },
        {
            code: 'draft',
            title: 'Draft',
        },
    ];

    const sortOptions = [
        {
            value: 'updated',
            text: 'Last updated',
        },
        {
            value: 'deliveryDate',
            text: 'Delivery date',
        },
        {
            value: 'due',
            text: 'Due date',
        },
    ];

    return loadingAssessments ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <ErrorMessage error={error} />
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={5000}
                variant={messageType}
                message={message || ''}
                open={messageShown}
                onClose={closeSnackbar}
            />
            {assessments.length > 0 && (
                <Fragment>
                    <Block>
                        <SpaceBetweenDiv>
                            <Heading2>Assessments</Heading2>
                            <FlexDiv alignCenter>
                                <SearchInput
                                    searchValue={searchValue || ''}
                                    placeholder="Search by name"
                                    onChange={(e) =>
                                        assessmentStore.setSearchValue(
                                            e.target.value
                                        )
                                    }
                                    onClear={() =>
                                        assessmentStore.setSearchValue('')
                                    }
                                />
                                <div
                                    className="assessment-filter-input"
                                    onClick={showFilterModal}
                                >
                                    <TextTiny
                                        bold
                                        lighter={filterCount === 0}
                                        link
                                    >
                                        {filterCount > 0
                                            ? `${filterCount} ${pluralize(
                                                  'Filter',
                                                  filterCount
                                              )}`
                                            : 'Filters'}
                                    </TextTiny>
                                    <div>
                                        {filterCount > 0 && (
                                            <CancelIcon
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    clearFilters();
                                                }}
                                                fontSize="small"
                                                style={{
                                                    cursor: 'pointer ',
                                                }}
                                            />
                                        )}
                                        <FilterIcon fontSize="small" />
                                    </div>
                                </div>
                                <Link to="/assessment/create">
                                    <ThemedButton primary airy>
                                        Create new
                                    </ThemedButton>
                                </Link>
                            </FlexDiv>
                        </SpaceBetweenDiv>
                    </Block>
                    <Block>
                        <SpaceBetweenDiv positionRelative>
                            {loadingAssessmentsInBackground && (
                                <CircularProgress
                                    size={15}
                                    className={'background-loader'}
                                    color="primary"
                                />
                            )}
                            <ThemedNav clean>
                                {tabs.map((t) => (
                                    <ThemedNavItem
                                        active={tab === t.code}
                                        key={t.code}
                                    >
                                        <ThemedNavLink
                                            onClick={() => {
                                                history.push(
                                                    `${parentMatch.path}/${t.code}`
                                                );
                                            }}
                                        >
                                            {t.title} (
                                            {
                                                assessmentStore[`${t.code}Tab`]
                                                    .length
                                            }
                                            )
                                        </ThemedNavLink>
                                    </ThemedNavItem>
                                ))}
                            </ThemedNav>
                            {filteredAssessments.length > 0 && (
                                <FlexDiv alignCenter>
                                    <TextDefault lighter mr={8}>
                                        Sort by:
                                    </TextDefault>
                                    <TextInput
                                        select
                                        inline
                                        value={sortBy}
                                        onChange={(e) => {
                                            assessmentStore.setSortBy(
                                                e.target.value
                                            );
                                        }}
                                    >
                                        {sortOptions.map((p) => (
                                            <MenuItem
                                                key={p.value}
                                                value={p.value}
                                            >
                                                {p.text}
                                            </MenuItem>
                                        ))}
                                    </TextInput>
                                </FlexDiv>
                            )}
                        </SpaceBetweenDiv>
                        {assessmentStore[`${tab}Tab`].length > 0 && (
                            <AssessmentListTable
                                currentCompany={currentCompany}
                                assessments={[...assessmentStore[`${tab}Tab`]]}
                                parentUrl={parentMatch.url}
                                onDelete={onDelete}
                                pauseAssessment={onPause}
                                completeAssessment={completeAssessment}
                                lists={lists}
                            />
                        )}
                        {assessmentStore[`${tab}Tab`].length === 0 && (
                            <div className="no-assessments-found">
                                <img
                                    src="/static/img/icon-booklist.svg"
                                    alt="No assessments found"
                                />
                                <div className="stats">
                                    <h5>No assessments found</h5>
                                    <p>
                                        Try adjusting your search or filter to
                                        find what you were looking for.
                                    </p>
                                </div>
                            </div>
                        )}
                    </Block>
                </Fragment>
            )}
            {assessments.length === 0 && (
                <NoItemsYet
                    img={'/static/img/assessment-empty.svg'}
                    imgAlt={'sneak peak into assessment'}
                    title={'No assessments yet'}
                    text={
                        'Create a new assessment to measure and track employees’ cybersecurity knowledge and behavior.'
                    }
                    heading={'Assessments'}
                    buttons={
                        <FullWidthLink to={`/assessment/create`}>
                            <ThemedButton primary fullWidth>
                                Create assessment
                            </ThemedButton>
                        </FullWidthLink>
                    }
                />
            )}
            {lists && lists.length > 0 && (
                <Modal
                    isOpen={filterModal}
                    onRequestClose={hideFilterModal}
                    className="filter-modal"
                    ariaHideApp={false}
                >
                    <FilterModal
                        onRequestClose={hideFilterModal}
                        items={assessmentStore[`${tab}Tab`]}
                        // filter1
                        nameFilter1={'Threat areas'}
                        array1={Object.values(mapValues(behaviours, 'name'))}
                        filter1={areaFilter.slice(0)}
                        setFilter1={(value) => {
                            assessmentStore.setAreaFilter(value);
                        }}
                        isAreaFilter1={true}
                        // filter2
                        nameFilter2={'Employee lists'}
                        array2={lists}
                        filter2={employeeFilter.slice(0)}
                        setFilter2={(value) => {
                            assessmentStore.setEmployeeFilter(value);
                        }}
                        nameDateFilter1={'Due date'}
                        startDateFilter1={startDueDateFilter}
                        setStartDateFilter1={(value) =>
                            assessmentStore.setStartDueDateFilter(value)
                        }
                        endDateFilter1={endDueDateFilter}
                        setEndDateFilter1={(value) =>
                            assessmentStore.setEndDueDateFilter(value)
                        }
                        nameDateFilter2={'Delivery date'}
                        startDateFilter2={startDeliveryDateFilter}
                        setStartDateFilter2={(value) =>
                            assessmentStore.setStartDeliveryDateFilter(value)
                        }
                        endDateFilter2={endDeliveryDateFilter}
                        setEndDateFilter2={(value) =>
                            assessmentStore.setEndDeliveryDateFilter(value)
                        }
                        filterCount={filterCount}
                        clearFilters={clearFilters}
                    />
                </Modal>
            )}
        </Fragment>
    );
}

export default observer(AssessmentList);
