import styled, { css } from 'styled-components';
import { colors } from './colors';

export const FlexDiv = styled.div`
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    ${(props) =>
        props.backgroundColor &&
        css`
            background-color: ${props.backgroundColor};
        `}
    ${(props) =>
        props.dropShadowBelow &&
        css`
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04),
                0px 4px 24px rgba(29, 30, 29, 0.04);
        `}
    ${(props) =>
        props.dropShadowAbove &&
        css`
            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08),
                0px 4px 24px rgba(29, 30, 29, 0.08);
        `}
    ${(props) =>
        props.noWrap &&
        css`
            flex-wrap: nowrap;
        `}
    ${(props) =>
        props.padding &&
        css`
            padding: ${props.padding}px;
        `}
    ${(props) =>
        props.py &&
        css`
            padding-top: ${props.py}px;
            padding-bottom: ${props.py}px;
        `}
    ${(props) =>
        props.px &&
        css`
            padding-left: ${props.px}px;
            padding-right: ${props.px}px;
        `}
    ${(props) =>
        props.pt &&
        css`
            padding-top: ${props.pt}px;
        `}
    ${(props) =>
        props.pr &&
        css`
            padding-right: ${props.pr}px;
        `}
    ${(props) =>
        props.pb &&
        css`
            padding-bottom: ${props.pb}px;
        `}
    ${(props) =>
        props.pl &&
        css`
            padding-left: ${props.pl}px;
        `}
    ${(props) =>
        props.margin &&
        css`
            margin: ${props.margin}px;
        `}
    ${(props) =>
        props.my &&
        css`
            margin-top: ${props.my}px;
            margin-bottom: ${props.my}px;
        `}
    ${(props) =>
        props.mx &&
        css`
            margin-left: ${props.mx}px;
            margin-right: ${props.mx}px;
        `}
    ${(props) =>
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
    ${(props) =>
        props.mr &&
        css`
            margin-right: ${props.mr}px;
        `}
    ${(props) =>
        props.mb &&
        css`
            margin-bottom: ${props.mb}px;
        `}
    ${(props) =>
        props.ml &&
        css`
            margin-left: ${props.ml}px;
        `}
    ${(props) =>
        props.marginAuto &&
        css`
            margin: auto;
        `}
    
    ${(props) =>
        props.alignCenter &&
        css`
            align-items: center;
        `}
    ${(props) =>
        props.justifyCenter &&
        css`
            justify-content: center;
        `}
    
    ${(props) =>
        props.alignStart &&
        css`
            align-items: start !important;
        `}
    ${(props) =>
        props.alignEnd &&
        css`
            align-items: end;
        `}
    ${(props) =>
        props.justifyEnd &&
        css`
            justify-content: end;
        `}
    
    ${(props) =>
        props.column &&
        css`
            flex-direction: column;
        `}
    
    ${(props) =>
        props.width &&
        css`
            width: ${props.width}px;
        `}
    ${(props) =>
        props.maxWidth &&
        css`
            max-width: ${props.maxWidth}px;
        `}
    
    ${(props) =>
        props.maxHeight &&
        css`
            max-height: ${props.maxHeight}px;
        `}
        
    ${(props) =>
        props.minHeight &&
        css`
            min-height: ${props.minHeight}px;
        `}
    
    ${(props) =>
        props.fullWidth &&
        css`
            width: 100%;
        `}
    ${(props) =>
        props.flexGrow &&
        css`
            flex: ${props.flexGrow};
        `}
    ${(props) =>
        props.borderBottom &&
        css`
            border-bottom: 1px solid ${colors.heavyMetalLight};
        `}
        
    ${(props) =>
        props.border &&
        css`
            border: 1px solid ${colors.heavyMetalLight};
            border-radius: 2px;
        `}
    ${(props) =>
        props.borderRadius &&
        css`
            border-radius: ${props.borderRadius}px;
        `}
    ${(props) =>
        props.overflowYauto &&
        css`
            overflow-y: auto;
        `}

    ${(props) =>
        props.link &&
        css`
            cursor: pointer;
        `}
    ${(props) =>
        props.positionRelative &&
        css`
            position: relative;
        `}
    ${(props) =>
        props.wrapper &&
        css`
            width: 100%;
            min-height: 100%;
        `}
    ${(props) =>
        props.flipColumn &&
        css`
            flex-direction: column-reverse;
        `}
    ${(props) =>
        props.ignoreParentPadding &&
        css`
            margin: -${props.ignoreParentPadding}px;
            max-width: initial;
        `}
`;

export const Block = styled(FlexDiv)`
    background: white;
    border-radius: 2px;
    padding: 32px;
    margin-top: 20px;
    flex-direction: column;
    ${(props) =>
        props.paddingTopOnly &&
        css`
            padding: 32px 0 0 0;
        `}
`;

export const SpaceBetweenDiv = styled(FlexDiv)`
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const Image = styled.img`
    ${(props) =>
        props.height &&
        css`
            height: ${props.height}px;
        `}
    ${(props) =>
        props.width &&
        css`
            width: ${props.width}px;
        `}
    ${(props) =>
        props.link &&
        css`
            cursor: pointer;
        `}
`;
