import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter, Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Table, Tr, Td, Th, Thead } from 'reactable';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

@inject('videosStore')
@observer
class VideosListSuperContainer extends React.Component {
    state = { tags: 'S01E01' };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState) return; // not yet initialized
        if (nextProps.tags !== prevState.tags) {
            return {
                tags: nextProps.tags,
                loadedOrError: null,
            };
        }

        // No state update necessary
        return null;
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        await this.props.videosStore.loadVideos(this.state.tags);
    }

    render() {
        const { loadingVideos, videos } = this.props.videosStore;

        return (
            <Fragment>
                <Row>
                    <Col md="9" xs="9" className="leftMenu">
                        <h2>Video assets</h2>
                    </Col>
                    <Col md="12" xs="12">
                        To search you just change the last item in the path up
                        there in the browser's address bar.
                        <br />
                        You can search for any tag used for our videos, using
                        comma to separate tags.
                        <br />
                        Examples:{' '}
                        <Link to={'/super/videos/english'}>
                            /english
                        </Link> | <Link to={'/super/videos/en-uk'}>/en-uk</Link>{' '}
                        | <Link to={'/super/videos/íslenska'}>/íslenska</Link> |{' '}
                        <Link to={'/super/videos/S01E01'}>/S01E01</Link> |{' '}
                        <Link to={'/super/videos/S01E01,norwegian'}>
                            /S01E01,norwegian
                        </Link>
                    </Col>
                </Row>
                {loadingVideos ? (
                    <ThemedSpinner />
                ) : (
                    <Row>
                        {videos && videos.length > 0 && (
                            <Col md="12" xs="12" className="leftMenu">
                                <Table
                                    className="table slim"
                                    id="table"
                                    itemsPerPage={500}
                                    pageButtonLimit={5}
                                    sortable={[
                                        {
                                            column: 'Video',
                                            sortFunction: function (a, b) {
                                                try {
                                                    return (a || '')
                                                        .toLowerCase()
                                                        .localeCompare(
                                                            (
                                                                b || ''
                                                            ).toLowerCase()
                                                        );
                                                } catch (e) {
                                                    debugger;
                                                    console.log(e);
                                                }
                                            },
                                        },
                                        'Tags',
                                        'Subtitles',
                                    ]}
                                    defaultSort="Video"
                                    filterable={['Video']}
                                    filterPlaceholder="Search video"
                                >
                                    <Thead>
                                        <Th column="Video" className="">
                                            Video
                                        </Th>
                                        <Th column="Tags" className="">
                                            Tags
                                        </Th>
                                        <Th column="Key" className="">
                                            Key
                                        </Th>
                                        <Th column="Subtitles" className="">
                                            Subtitles
                                        </Th>
                                        <Th column="Link" className="">
                                            Link
                                        </Th>
                                    </Thead>
                                    {videos.map((video, i) => (
                                        <Tr key={i}>
                                            <Td
                                                column={'Video'}
                                                value={video.title}
                                                className=""
                                            >
                                                {video.title}
                                            </Td>
                                            <Td
                                                column={'Tags'}
                                                value={video.tags}
                                            >
                                                {video.tags}
                                            </Td>
                                            <Td
                                                column={'Key'}
                                                value={video.key}
                                            >
                                                {video.key}
                                            </Td>
                                            <Td
                                                column={'Subtitles'}
                                                value={video.subtitles}
                                            >
                                                {video.subtitles}
                                            </Td>
                                            <Td column={'Link'}>
                                                <a
                                                    href={video.link}
                                                    target={'_blank'}
                                                >
                                                    {' '}
                                                    Go
                                                </a>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Table>
                            </Col>
                        )}
                    </Row>
                )}
            </Fragment>
        );
    }
}

export default withRouter(VideosListSuperContainer);
