import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { ThemedButton } from '../../themed/ThemedComponents';

function NoStats() {
    let match = useRouteMatch();

    return (
        <div className={'top-guide statistics'}>
            <div className={'block center'}>
                <h3>Assessments</h3>
                <img
                    src="/static/img/icon-booklist.svg"
                    className="icon-small"
                    alt="No stats available"
                />
                <div className="stats">
                    <h5>No assessments</h5>
                    <p>You haven’t created any assessments yet.</p>
                </div>
                <Link to={`${match.url}create/`}>
                    <ThemedButton primary>Create new</ThemedButton>
                </Link>
            </div>
        </div>
    );
}

export default NoStats;
