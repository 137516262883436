import React from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from 'reactstrap';

@inject('subscriptionStore')
@observer
class PaymentConfirm extends React.Component {
    render() {
        const { price, billingInfo } = this.props.subscriptionStore;

        return (
            <div className={'wizard-fullscreen'}>
                <div className={'wizard-fullscreen-header'}>
                    <h3>Payment Successful</h3>
                </div>
                <div className="wizard-fullscreen-body align-items-center justify-content-center flex-column">
                    <img src="/static/img/receipt.svg" alt="Email sent" />

                    <br />
                    <h4>
                        <strong>Thank you!</strong>
                    </h4>
                    <br />
                    <p>
                        Your account was charged{' '}
                        <strong className="green">${price}</strong>. A
                        confirmation and receipt was also sent to{' '}
                        <strong className="green">{billingInfo.email}</strong>
                    </p>
                </div>

                <div className={'wizard-fullscreen-footer'}>
                    <div
                        className={'actions center d-flex justify-content-end'}
                    >
                        <Button
                            color={'primary'}
                            onClick={this.props.onComplete}
                        >
                            OK
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentConfirm;
