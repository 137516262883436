import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import Managers from '../../components/company/managers';

function CompanyManagers({ company }) {
    const { superStore, authStore } = useStores();

    const { removingManagers, addingManager, addManagerError } = superStore;

    const addManager = async (form) => {
        await superStore.addManager(company.company_id, form.values());
    };

    const removeManager = async (companyId, email) => {
        await superStore.removeManager(companyId, email);
    };

    const sendLoginInfo = async (managerEmail, companyId) => {
        await authStore.signin(
            managerEmail,
            companyId,
            null,
            null,
            '/',
            'login-info'
        );
    };

    return (
        <Managers
            company={company}
            removingManagers={removingManagers}
            addingManager={addingManager}
            addManagerError={addManagerError}
            addManager={addManager}
            removeManager={removeManager}
            sendLoginInfo={sendLoginInfo}
        />
    );
}

export default observer(CompanyManagers);
