import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';

import { Row, Col, Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import AddFreemium from '../../components/super/add-freemium';
import SelectSubjects from '../../components/manager/automation/subject-select2';
import Modal from 'react-modal';
import moment from 'moment';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

@inject('superStore', 'catalogStore')
@observer
class CompaniesViewSuperContainer extends React.Component {
    state = { selectedVideosIds: [], editFreemium: null };

    componentDidMount() {
        // It's preferable in most cases to wait until after mounting to load data.
        // See below for a bit more context...
        this.loadData();
    }

    async loadData() {
        await this.props.catalogStore.loadCatalog();
        await this.props.superStore.listFreemiums();
    }

    addFreemium = (code, validity, limit, users) => {
        this.props.superStore.addFreemium(code, validity, limit, users);
    };

    removeFreemium = (code) => {
        this.props.superStore.removeFreemium(code);
    };

    resetCounter = (code) => {
        this.props.superStore.resetCounter(code);
    };

    hideSubjectsModal = () => {
        this.setState({ selectedVideosIds: [], editFreemium: null });
    };

    applyVideos = () => {
        this.props.superStore.updateFreemiumVideos(
            this.state.editFreemium,
            this.state.selectedVideosIds
        );
        this.hideSubjectsModal();
    };

    showSubjectsModal = (freemium) => {
        this.setState({
            editFreemium: freemium.code,
            selectedVideosIds: observable((freemium.videos || []).slice(0)),
        });
    };

    toggleVideoSelected = (videoId) => {
        if (this.state.selectedVideosIds.includes(videoId))
            this.state.selectedVideosIds.remove(videoId);
        else this.state.selectedVideosIds.push(videoId);
    };

    render() {
        const { catalog, allSubjectsIndex } = this.props.catalogStore;
        const { freemiums, error, addingFreemium, removingFreemiums } =
            this.props.superStore;

        return (
            <Fragment>
                <div className="block">
                    <Row className={'super-company-root'}>
                        <Col md="12" xs="12">
                            {
                                <Fragment>
                                    <h3>Managers</h3>
                                    <div className="table-white-bg">
                                        <table className="table2">
                                            <thead>
                                                <tr>
                                                    <th>Code</th>
                                                    <th>Validity</th>
                                                    <th>Limit</th>
                                                    <th>Registrations</th>
                                                    <th>Users</th>
                                                    <th>Videos</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {freemiums.map((freemium) => (
                                                    <tr key={freemium.code}>
                                                        <td>{freemium.code}</td>
                                                        <td>
                                                            {moment()
                                                                .add(
                                                                    freemium.validity,
                                                                    'days'
                                                                )
                                                                .format('LL')}
                                                        </td>
                                                        <td>
                                                            {freemium.limit ||
                                                                'Unlimited'}
                                                        </td>
                                                        <td>
                                                            {freemium.count}
                                                        </td>
                                                        <td>
                                                            {freemium.users}
                                                        </td>
                                                        <td>
                                                            <span>
                                                                {
                                                                    (
                                                                        freemium.videos ||
                                                                        []
                                                                    ).length
                                                                }{' '}
                                                                videos
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <Button
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    this.showSubjectsModal(
                                                                        freemium
                                                                    );
                                                                }}
                                                            >
                                                                Edit
                                                            </Button>
                                                        </td>

                                                        {removingFreemiums.includes(
                                                            freemium.code
                                                        ) ? (
                                                            <ThemedSpinner
                                                                size={45}
                                                            />
                                                        ) : (
                                                            <Fragment>
                                                                <td className="text-center">
                                                                    <span
                                                                        className="delete-link"
                                                                        onClick={() =>
                                                                            this.removeFreemium(
                                                                                freemium.code
                                                                            )
                                                                        }
                                                                    >
                                                                        <Button
                                                                            color="danger"
                                                                            size="sm"
                                                                        >
                                                                            x
                                                                        </Button>
                                                                    </span>
                                                                </td>

                                                                <td className="text-center">
                                                                    <span
                                                                        className=""
                                                                        onClick={() =>
                                                                            this.resetCounter(
                                                                                freemium.code
                                                                            )
                                                                        }
                                                                    >
                                                                        <Button
                                                                            color="danger"
                                                                            size="sm"
                                                                        >
                                                                            Reset
                                                                            Counter
                                                                        </Button>
                                                                    </span>
                                                                </td>
                                                            </Fragment>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="padded-container">
                                        <AddFreemium
                                            addingFreemium={addingFreemium}
                                            addFreemium={this.addFreemium}
                                        />
                                    </div>
                                </Fragment>
                            }
                            <ErrorMessage error={error} />
                        </Col>

                        <Modal
                            isOpen={this.state.editFreemium !== null}
                            onAfterOpen={this.afterOpenModal}
                            onRequestClose={this.hideSubjectsModal}
                            className="Modal subjects"
                            overlayClassName={'super-modal-root'}
                        >
                            <SelectSubjects
                                selection={this.state.selectedVideosIds}
                                catalog={catalog}
                                catalogIndex={allSubjectsIndex}
                                onRequestClose={this.hideSubjectsModal}
                                onVideoSelected={this.toggleVideoSelected}
                                onConfirm={this.applyVideos}
                            />
                        </Modal>
                    </Row>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(CompaniesViewSuperContainer);
