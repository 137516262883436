import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'reactstrap';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import ACLEditor from './ACLEditor';
import { Table, Td, Th, Thead, Tr } from 'reactable';
import { Link } from 'react-router-dom';
import { enforceSlash } from '../../utils/helpers';

export default observer(({ categoryId, parentMatch }) => {
    const { superStore } = useStores();

    useEffect(() => {
        (async () => {
            await superStore.loadCategory(categoryId);
            await superStore.loadCategorySubjects(categoryId);
        })();
    }, [categoryId]);

    const {
        loadingCategory,
        editingCategory,
        categoriesErrorError,
        editingCategorySubjects,
    } = superStore;

    return loadingCategory || !editingCategory ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <ErrorMessage error={categoriesErrorError} />

            {editingCategory && (
                <Fragment>
                    <div class="block">
                        <h2>{editingCategory.name}</h2>
                        <div className="main-content">
                            <h3>Subjects:</h3>
                            <Table className="table" id="table">
                                <Thead>
                                    <Th column="Id" className="">
                                        ID
                                    </Th>
                                    <Th column="Title" className="">
                                        Title
                                    </Th>
                                    <Th column="Episode number" className="">
                                        Episode number
                                    </Th>
                                </Thead>
                                {editingCategorySubjects.map((subject, i) => (
                                    <Tr key={subject.subject_id}>
                                        <Td
                                            column={'Id'}
                                            value={subject.subject_id}
                                            className=""
                                        >
                                            <Link
                                                to={`${enforceSlash(
                                                    parentMatch.url
                                                )}subjects/${
                                                    subject.subject_id
                                                }`}
                                            >
                                                {subject.subject_id}
                                            </Link>
                                        </Td>
                                        <Td
                                            column={'Title'}
                                            value={subject.title}
                                        >
                                            <Link
                                                to={`${enforceSlash(
                                                    parentMatch.url
                                                )}subjects/${
                                                    subject.subject_id
                                                }`}
                                            >
                                                {subject.title}
                                            </Link>
                                        </Td>
                                        <Td
                                            column={'Episode number'}
                                            value={subject.episode_number}
                                        >
                                            {subject.episode_number}
                                        </Td>
                                    </Tr>
                                ))}
                            </Table>
                        </div>
                    </div>
                    <div class="block">
                        <Row>
                            <Col md="12" xs="12" className="leftMenu">
                                <h3>Access control</h3>
                                <div className="padded-container">
                                    <ACLEditor
                                        acl={editingCategory.acl}
                                        unique_id={editingCategory.unique_id}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
});
