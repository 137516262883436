import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'mobx-react';
import stores from './stores';
import { Router } from 'react-router-dom';
import includes from 'array-includes';
import 'whatwg-fetch';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import { Route, Switch } from 'react-router';
import Testing from './containers/testing';

const history = createBrowserHistory();
if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        autoSessionTracking: true,
        // beforeSend(event, hint) {
        //     // Check if it is an exception, and if so, show the report dialog
        //     if (event.exception) {
        //         Sentry.showReportDialog({ eventId: event.event_id });
        //     }
        //     return event;
        // },
        integrations: [
            new Integrations.BrowserTracing({
                // Can also use reactRouterV4Instrumentation
                routingInstrumentation:
                    Sentry.reactRouterV5Instrumentation(history),
            }),
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0.0,
    });
}

includes.shim();
ReactGA.initialize('UA-6397241-1');

// eslint-disable-next-line no-undef

try {
    // eslint-disable-next-line no-undef
    window.PostAffTracker &&
        window.PostAffTracker.setAccountId &&
        window.PostAffTracker.setAccountId('default1');
} catch (e) {}

//to window only for debug
window.__stores = stores;

ReactDOM.render(
    <Provider {...stores}>
        <Router history={history}>
            <Switch>
                {process.env.NODE_ENV === 'development' && (
                    <Route path="/testing" component={Testing} />
                )}
                <Route
                    render={() => {
                        return <App />;
                    }}
                />
            </Switch>
        </Router>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
