import React from 'react';
import { Form, FormGroup, Label, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Spinner from '../Spinner';

@observer
class AddSetting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            value: '',
        };
    }

    changeName = (event) => {
        this.setState({ name: event.target.value });
    };

    changeValue = (event) => {
        this.setState({ value: event.target.value });
    };

    async save() {
        let inputOk = true;

        if (inputOk) {
            this.props.saveSetting(this.state.name, this.state.value);
        }
    }

    render() {
        return (
            <div>
                <h4>Add new Setting</h4>
                <Form inline>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label for="packageUsers" className="mr-sm-2">
                            Name
                        </Label>
                        <input
                            type="text"
                            name="Name"
                            className="bigInput"
                            value={this.state.name}
                            onChange={this.changeName}
                        />
                    </FormGroup>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label for="packageUsers" className="mr-sm-2">
                            Value
                        </Label>
                        <input
                            type="text"
                            name="Value"
                            className="bigInput"
                            value={this.state.value}
                            onChange={this.changeValue}
                        />
                    </FormGroup>

                    {this.props.addingSetting ? (
                        <Spinner size={45} />
                    ) : (
                        <Button
                            className="btn btn-primary"
                            onClick={() => this.save()}
                        >
                            Add
                        </Button>
                    )}
                </Form>

                {this.state.error && (
                    <div className="danger">
                        <strong>{this.state.error}</strong>
                    </div>
                )}
            </div>
        );
    }
}

AddSetting.propTypes = {
    saveSetting: PropTypes.func,
};

export default AddSetting;
