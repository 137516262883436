import Form from 'mobx-react-form';
import * as validatorjs from 'validatorjs';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

const plugins = { dvr: validatorjs };

const fields = {
    companyName: {
        name: 'companyName',
        label: 'Company name',
        rules: 'required|string',
        placeholder: 'Enter company name',
    },
    companyId: {
        rules: 'numeric',
    },
    subscription: {
        name: 'subscription',
        label: 'Subscription',
        rules: 'required|string',
    },
    seats: {
        name: 'seats',
        label: 'Number of seats',
        rules: 'required|numeric|min:1',
        placeholder: 'Enter number of seats',
    },
    valid: {
        name: 'valid',
        label: 'Valid',
        rules: 'required|string',
    },
    validTill: {
        name: 'validTill',
        label: 'Valid until',
        rules: 'date',
        value: moment().add(1, 'years'),
    },
};

const options = {
    showErrorsOnReset: false,
};

class AddCompanyForm extends Form {
    constructor(initials, hooks) {
        let f = cloneDeep(fields);
        if (initials && initials.subscription) {
            f.companyName.value = initials.company_name;
            f.companyId.value = initials.company_id;
            f.seats.value = initials.subscription.package_users;
            f.subscription.value = initials.subscription.subscription_code;
            f.valid.value =
                initials.subscription.package_valid === 0 ? '12' : '-1';
            f.validTill.value = new Date(
                initials.subscription.package_valid_to
            );
        }

        super({ fields: f }, { plugins, hooks, options });
    }
}

export default AddCompanyForm;
