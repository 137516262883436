import React from 'react'
import LightTooltip from './LightTooltip'
import styled from 'styled-components';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const AlignCenter = styled.span`
    display: flex;
    align-items: center;
`;

const Icon = styled(InfoOutlinedIcon)`
    margin-left: 4px;
`;

function TextWithTooltipIcon({tooltipText, textComponent}) {
    return (
        <LightTooltip title={tooltipText} placement="bottom">
            <AlignCenter>
                {textComponent}
                <Icon fontSize="small" color="action" />
            </AlignCenter>
        </LightTooltip>
    );
}

export default TextWithTooltipIcon
