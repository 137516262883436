import React, { Fragment, useMemo, useState } from 'react';
import StatusBadge from '../../StatusBadge';
import moment from 'moment';
import LightTooltip from '../../LightTooltip';
import { MenuItem, IconButton, Menu, Tooltip } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import { STATUS } from '../../../utils/constants';
import { useHistory } from 'react-router';
import { calculateColor } from '../../../utils/helpers';
import ProgressWithPercentage from '../../ProgressWithPercentage';
import SimpleTable from '../../SimpleTable';
import { TextTiny, TextDefault } from '../../design-system/texts';
import { FlexDiv } from '../../design-system/containers';

function AutomationListTable({
    automations,
    onDelete,
    pauseAutomation,
    lists,
}) {
    let history = useHistory();

    const dateFormat = (date) => (
        <Fragment>
            <div>
                <TextTiny bold lighter>
                    {moment(date).format('ll')}
                </TextTiny>
            </div>
            <TextTiny lighter>{moment(date).format('LT')}</TextTiny>
        </Fragment>
    );

    const [anchorEl, setAnchorEl] = useState(null);
    const [automation, setOpenedAutomation] = useState(null);

    const handleMenuOpen = (e, automation) => {
        setOpenedAutomation(automation);
        setAnchorEl(e.currentTarget);
        e.stopPropagation();
    };

    const handleMenuClose = (e) => {
        setOpenedAutomation(null);
        setAnchorEl(null);
        e.stopPropagation();
    };

    const moreActionsMenu = (automation) => (
        <IconButton
            color="primary"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={(e) => {
                handleMenuOpen(e, automation);
                e.preventDefault();
            }}
            id={automation.id}
        >
            <MoreIcon />
        </IconButton>
    );

    const maxVisibleLength = 30;

    const columns = useMemo(
        () => [
            {
                Header: 'Training',
                accessor: 'name',
                Cell: (x) => {
                    const subjects = x.row.original.subjectsNames;
                    return (
                        <Fragment>
                            <div>
                                {(x.value || '').length > maxVisibleLength ? (
                                    <Tooltip title={x.value || ''}>
                                        <span>
                                            <TextDefault bold>
                                                {(x.value || '').substring(
                                                    0,
                                                    maxVisibleLength
                                                )}
                                                ...
                                            </TextDefault>
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <TextDefault bold>{x.value}</TextDefault>
                                )}
                            </div>
                            {(subjects || []).length ? (
                                <Fragment>
                                    <TextTiny lighter>
                                        {subjects[0].title}
                                    </TextTiny>
                                    &nbsp;
                                    {subjects.length > 1 && (
                                        <LightTooltip
                                            title={subjects
                                                .slice(1, subjects.length)
                                                .map((subject, i, arr) =>
                                                    subjects.length > 19 ? (
                                                        <span key={subject.id}>
                                                            {/* don't put comma after last subject  */}
                                                            {subject.title}
                                                            {i !==
                                                            arr.length - 1
                                                                ? ', '
                                                                : ''}
                                                        </span>
                                                    ) : (
                                                        <div key={subject.id}>
                                                            {subject.title}
                                                        </div>
                                                    )
                                                )}
                                        >
                                            <TextTiny lighter underline>
                                                +
                                                {
                                                    subjects.slice(
                                                        1,
                                                        subjects.length
                                                    ).length
                                                }{' '}
                                                more
                                            </TextTiny>
                                        </LightTooltip>
                                    )}
                                </Fragment>
                            ) : (
                                ''
                            )}
                        </Fragment>
                    );
                },
            },
            {
                Header: 'Status',
                accessor: '_rawData.status',
                Cell: (x) => (
                    <StatusBadge
                        color={x.row.original.statusInfo.color}
                        text={x.row.original.statusInfo.label}
                    />
                ),
            },
            {
                Header: '# Employees',
                accessor: '_rawData.totalUsers',
                Cell: (x) => {
                    const listNames = lists.filter((list) =>
                        x.row.original._rawData.lists.includes(list.id)
                    );
                    return listNames.length > 0 ? (
                        <LightTooltip
                            title={listNames.map((list) => (
                                <div key={list.id}>{list.name}</div>
                            ))}
                        >
                            <TextTiny underline lighter>
                                {x.value}
                            </TextTiny>
                        </LightTooltip>
                    ) : (
                        <TextTiny lighter>0</TextTiny>
                    );
                },
            },
            {
                Header: 'Start date',
                accessor: '_rawData.firstBatch',

                Cell: (x) => (x.value ? dateFormat(x.value) : '-'),
                minWidth: 112,
            },
            {
                Header: 'End date',
                accessor: '_rawData.lastBatch',

                Cell: (x) => (x.value ? dateFormat(x.value) : '-'),
                minWidth: 112,
            },
            {
                Header: 'Attendance',
                accessor: 'attendance',
                Cell: (x) =>
                    x.row.original.status === STATUS.ONGOING ||
                    x.row.original.status === STATUS.COMPLETED ||
                    x.row.original._rawData.opened === 1 ? (
                        <Fragment>
                            <div>
                                <TextTiny bold color={calculateColor(x.value)}>
                                    {x.value}%
                                </TextTiny>
                            </div>
                            <TextTiny lighter>
                                {x.row.original._rawData.opened}/
                                {x.row.original._rawData.totalUsers}
                            </TextTiny>
                        </Fragment>
                    ) : (
                        ''
                    ),
                tooltip:
                    'Percentage of employees who have started to watch at least one lesson in a training.',
            },
            {
                Header: 'Awareness score',
                accessor: 'awarenessScore',
                Cell: (x) =>
                    x.row.original.status === STATUS.ONGOING ||
                    x.row.original.status === STATUS.COMPLETED ||
                    x.row.original._rawData.opened === 1 ? (
                        <ProgressWithPercentage value={x.value} thin="true" />
                    ) : (
                        ''
                    ),
                tooltip: 'Average employee score across all training lessons.',
            },
            {
                Header: ' ',
                Cell: (x) => moreActionsMenu(x.row.original),
            },
        ],
        []
    );

    return (
        <FlexDiv mt={32}>
            <SimpleTable data={automations} columns={columns} useLink />
            {automation && (
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={automation != null}
                    onClose={handleMenuClose}
                    elevation={1}
                >
                    <MenuItem
                        onClick={(e) => {
                            history.push(automation.link);
                            handleMenuClose(e);
                        }}
                    >
                        Open
                    </MenuItem>
                    <MenuItem
                        onClick={(e) => {
                            history.push(
                                `/trainings/create/copy/${automation.id}`
                            );
                            handleMenuClose(e);
                        }}
                    >
                        Duplicate
                    </MenuItem>
                    {(automation.status === STATUS.DRAFT ||
                        automation.status === STATUS.SCHEDULED) && (
                        <MenuItem
                            onClick={(e) => {
                                history.push(
                                    `/trainings/edit/${automation.id}`
                                );
                                handleMenuClose(e);
                            }}
                        >
                            Edit
                        </MenuItem>
                    )}
                    {automation.status === STATUS.SCHEDULED && (
                        <MenuItem
                            onClick={(e) => {
                                pauseAutomation(automation.id);
                                handleMenuClose(e);
                            }}
                        >
                            Stop
                        </MenuItem>
                    )}
                    {(automation.status === STATUS.DRAFT ||
                        automation.status === STATUS.SCHEDULED) && (
                        <MenuItem
                            onClick={(e) => {
                                onDelete(automation.id);
                                handleMenuClose(e);
                            }}
                        >
                            <span style={{ color: 'red' }}>Delete</span>
                        </MenuItem>
                    )}
                </Menu>
            )}
        </FlexDiv>
    );
}

export default AutomationListTable;
