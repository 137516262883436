import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
    InputAdornment,
    Checkbox,
    FormControlLabel,
    Tooltip,
    IconButton,
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment';
import { ThemedText } from '../../themed/ThemedComponents';
import { makeStyles } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import './reminders.scss';
import SelectChannel from '../wizard/select-channel';

const useStyles = makeStyles(() => ({
    dayWithDotContainer: {
        position: 'relative',
    },
    dayWithDot: {
        position: 'absolute',
        height: 0,
        width: 0,
        border: '2px solid',
        borderRadius: 4,
        borderColor: 'gray',
        right: '50%',
        transform: 'translateX(1px)',
        top: '80%',
    },
}));

// making sure week on calendar starts on Monday
moment.updateLocale('en', {
    week: {
        dow: 1,
    },
});

export default observer(
    ({
        scheduledDate,
        dueDate,
        reminders_enabled,
        onChangeRemindersEnabled,
        reminders_steps,
        onChangeReminder,
        onAddReminderStep,
        onDeleteReminderStep,
        channel,
        slackAvailable,
        teamsAvailable,
        slackConnected,
        teamsConnected,
        onChangeChannel,
    }) => {
        const classes = useStyles();

        return (
            <Fragment>
                <div className={'training-schedule'}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={reminders_enabled}
                                onChange={onChangeRemindersEnabled}
                                name="sendReminders"
                                color="primary"
                            />
                        }
                        label="Send reminders"
                    />
                    {reminders_enabled ? (
                        <Fragment>
                            {reminders_steps.length > 0 && (
                                <h5 className="reminder-row-label">
                                    Remind on
                                </h5>
                            )}
                            {reminders_steps.map((x, i) => (
                                <div
                                    className={'assessment-reminder-row'}
                                    key={`reminder-option-${i}`}
                                >
                                    <div className="reminder-row-count">
                                        {i + 1}.
                                    </div>
                                    {x.alreadySet &&
                                    moment(x.send).isBefore(moment()) ? (
                                        <span className="reminder-row-count">
                                            {moment(x.send).format(
                                                'L HH:mm [GMT]'
                                            )}
                                        </span>
                                    ) : (
                                        <MuiPickersUtilsProvider
                                            utils={MomentUtils}
                                        >
                                            <DateTimePicker
                                                value={x.send}
                                                ampm={false}
                                                onChange={(date) => {
                                                    onChangeReminder(
                                                        date,
                                                        i,
                                                        x
                                                    );
                                                }}
                                                variant="inline"
                                                inputVariant="outlined"
                                                color="primary"
                                                disablePast
                                                format="L HH:mm [GMT]"
                                                allowKeyboardControl
                                                autoOk
                                                initialFocusedDate={moment(
                                                    new Date()
                                                )
                                                    .set('minute', 0)
                                                    .add(1, 'days')}
                                                minDate={
                                                    scheduledDate !== null
                                                        ? moment(scheduledDate)
                                                        : undefined
                                                }
                                                minDateMessage={
                                                    scheduledDate != null
                                                        ? `Reminder must be set after the delivery time (${moment(
                                                              scheduledDate
                                                          ).format(
                                                              'L HH:mm [GMT]'
                                                          )})`
                                                        : 'Reminder must be set to later than now.'
                                                }
                                                maxDate={
                                                    dueDate !== null
                                                        ? moment(dueDate)
                                                        : undefined
                                                }
                                                maxDateMessage={`Reminder must be set before the due date (${moment(
                                                    dueDate
                                                ).format('L HH:mm [GMT]')})`}
                                                strictCompareDates
                                                style={{ width: '210px' }}
                                                emptyLabel="Select date"
                                                views={['date', 'hours']}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="start">
                                                            <CalendarTodayIcon
                                                                color="secondary"
                                                                style={{
                                                                    width: '20px',
                                                                    color: '#c4c4c4',
                                                                }}
                                                            />
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                renderDay={(
                                                    date,
                                                    selectedDate,
                                                    dayInCurrentMonth,
                                                    dayComponent
                                                ) => {
                                                    if (dayInCurrentMonth) {
                                                        if (
                                                            moment(date).isSame(
                                                                dueDate,
                                                                'day'
                                                            )
                                                        ) {
                                                            return (
                                                                <div
                                                                    className={
                                                                        classes.dayWithDotContainer
                                                                    }
                                                                >
                                                                    {
                                                                        dayComponent
                                                                    }
                                                                    <Tooltip
                                                                        title={`Reminder must be set before the due date (${moment(
                                                                            dueDate
                                                                        ).format(
                                                                            'L HH:mm [GMT]'
                                                                        )})`}
                                                                    >
                                                                        <div
                                                                            className={
                                                                                classes.dayWithDot
                                                                            }
                                                                        />
                                                                    </Tooltip>
                                                                </div>
                                                            );
                                                        }
                                                        if (
                                                            moment(date).isSame(
                                                                scheduledDate,
                                                                'day'
                                                            )
                                                        ) {
                                                            return (
                                                                <div
                                                                    className={
                                                                        classes.dayWithDotContainer
                                                                    }
                                                                >
                                                                    {
                                                                        dayComponent
                                                                    }
                                                                    <Tooltip
                                                                        title={`Reminder must be set after the delivery time (${moment(
                                                                            scheduledDate
                                                                        ).format(
                                                                            'L HH:mm [GMT]'
                                                                        )})`}
                                                                    >
                                                                        <div
                                                                            className={
                                                                                classes.dayWithDot
                                                                            }
                                                                        />
                                                                    </Tooltip>
                                                                </div>
                                                            );
                                                        }
                                                    }
                                                    return dayComponent;
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    )}
                                    {!(
                                        x.alreadySet &&
                                        moment(x.send).isBefore(moment())
                                    ) && (
                                        <div>
                                            <IconButton
                                                aria-label="delete"
                                                onClick={(e) => {
                                                    onDeleteReminderStep(x);
                                                }}
                                                color="secondary"
                                            >
                                                <DeleteForeverIcon fontSize="small" />
                                            </IconButton>
                                        </div>
                                    )}
                                </div>
                            ))}
                            {reminders_steps.length === 0 ? <br /> : ''}
                            {reminders_steps.length < 5 && (
                                <ThemedText
                                    className="add-new-reminder"
                                    onClick={onAddReminderStep}
                                >
                                    {' '}
                                    + Add new reminder
                                </ThemedText>
                            )}
                            <SelectChannel
                                channel={channel}
                                slackAvailable={slackAvailable}
                                teamsAvailable={teamsAvailable}
                                slackConnected={slackConnected}
                                teamsConnected={teamsConnected}
                                onChangeChannel={onChangeChannel}
                                isReminders={true}
                            />
                        </Fragment>
                    ) : (
                        ''
                    )}
                </div>
            </Fragment>
        );
    }
);
