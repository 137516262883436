import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import pluralize from 'pluralize';
import SectionHeader from '../../components/SectionHeader';
import UserDetails from '../../components/manager/users/userDetailsWithProgressBar';
import { formatDate, querystring, trainingName } from '../../utils/helpers';

@inject(
    'automationStore',
    'companyStore',
    'listStore',
    'authStore',
    'store',
    'catalogStore',
    'commonStore'
)
@observer
class UsersTrainingReportContainer extends React.Component {
    constructor(props) {
        super(props);
        const token = querystring('token');
        if (token) this.props.authStore.setToken(token);
    }

    async componentDidMount() {
        await this.props.automationStore.loadAutomation(
            this.props.match.params.companyId,
            this.props.match.params.trainingId
        );
        await this.props.automationStore.loadUsersDetails(
            this.props.match.params.companyId,
            this.props.match.params.trainingId
        );
    }

    render() {
        const { editingAutomation, loadingAutomation, perUserDetailsIndex } =
            this.props.automationStore;
        let weeksFromStart;
        if (editingAutomation) {
            weeksFromStart =
                editingAutomation &&
                Math.max(
                    1,
                    moment().diff(
                        moment(editingAutomation._rawData.created),
                        'weeks'
                    )
                );
        }

        return (
            <div className={'report-root report-root-users'}>
                {!loadingAutomation && (
                    <Fragment>
                        {editingAutomation ? (
                            <Fragment>
                                <div className="report-header">
                                    <SectionHeader>
                                        <div className="d-flex flex-row">
                                            <div className={'section-header'}>
                                                <div className={'left padded'}>
                                                    {/* TODO: Fix the text Should be: 24 minutes delivered through 5 weeks · Training started on Apr 16, 2019 · Next lesson on 13 May, 2019*/}
                                                    <h3>
                                                        User detailed Training
                                                        report for{' '}
                                                        {trainingName(
                                                            editingAutomation.name,
                                                            editingAutomation
                                                                ._rawData
                                                                .created
                                                        )}{' '}
                                                    </h3>
                                                    <p>
                                                        {
                                                            editingAutomation
                                                                ._rawData
                                                                .minutesSent
                                                        }{' '}
                                                        minutes delivered over{' '}
                                                        {weeksFromStart}{' '}
                                                        {pluralize(
                                                            'week',
                                                            weeksFromStart
                                                        )}{' '}
                                                        · Training started on{' '}
                                                        {moment(
                                                            editingAutomation
                                                                ._rawData
                                                                .created
                                                        ).format('LL')}
                                                        {editingAutomation
                                                            ._rawData.active &&
                                                            editingAutomation
                                                                ._rawData
                                                                .nextSubjectDate && (
                                                                <Fragment>
                                                                    · Next
                                                                    lesson on{' '}
                                                                    {formatDate(
                                                                        editingAutomation
                                                                            ._rawData
                                                                            .nextSubjectDate
                                                                    )}
                                                                </Fragment>
                                                            )}
                                                        {!editingAutomation
                                                            ._rawData.active &&
                                                            editingAutomation
                                                                ._rawData
                                                                .finished && (
                                                                <Fragment>
                                                                    · Finished
                                                                    on{' '}
                                                                    {moment(
                                                                        editingAutomation.finished
                                                                    ).format(
                                                                        'LL'
                                                                    )}
                                                                </Fragment>
                                                            )}
                                                    </p>
                                                    <p className="text-thin">
                                                        Report created on{' '}
                                                        {moment(
                                                            editingAutomation.created
                                                        ).format('LL')}
                                                    </p>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </SectionHeader>
                                </div>

                                <div className="block">
                                    <UserDetails
                                        actionsEnabled={false}
                                        title={'Employees report'}
                                        perUserDetails={perUserDetailsIndex}
                                        searchable={false}
                                        paginated={false}
                                        onDeleteUser={this.onDeleteUser}
                                        users={editingAutomation._rawData.users}
                                        training={editingAutomation}
                                        detailsRootUrl={`/trainings/${editingAutomation.id}/user/`}
                                    />
                                </div>
                            </Fragment>
                        ) : (
                            <div> Automation Not Found</div>
                        )}
                    </Fragment>
                )}
            </div>
        );
    }
}

export default withRouter(UsersTrainingReportContainer);
