import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { ThemedButton, ThemedText } from '../../themed/ThemedComponents';
import { Checkbox, FormControlLabel, Box, IconButton } from '@material-ui/core';
import SelectChannel from '../wizard/select-channel';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

function AutomationReminders({
    reminders_enabled,
    reminders_channel,
    reminders_steps,
    slackAvailable,
    teamsAvailable,
    slackConnected,
    teamsConnected,
    onChangeRemindersEnabled,
    onChangeRemindersChannel,
    onAddReminderStep,
    onDeleteReminderStep,
    scheduleType,
    showScheduleModal,
}) {
    return (
        <Fragment>
            <div className={'training-schedule'}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={reminders_enabled}
                            onChange={onChangeRemindersEnabled}
                            name="sendReminders"
                            color="primary"
                        />
                    }
                    label="Send reminders"
                />
                {reminders_enabled && (
                    <Fragment>
                        {reminders_steps.length > 0 && (
                            <h5 className="reminder-row-label">
                                After receiving lessons, remind users in
                            </h5>
                        )}
                        {reminders_steps.map((x, i) => (
                            <div
                                className={'assessment-reminder-row'}
                                key={`reminder-option-${i}`}
                            >
                                <div className="reminder-row-count">
                                    {i + 1}. delay:
                                </div>
                                <input
                                    type="text"
                                    className="reminder-training-input bigInput"
                                    value={x.delay}
                                    onChange={(e) => {
                                        x.delay = !isNaN(e.target.value)
                                            ? Number(e.target.value)
                                            : 0;
                                    }}
                                />
                                <select
                                    name="delayType"
                                    className="bigInput reminder-training-input"
                                    value={x.delayType}
                                    onChange={(e) => {
                                        x.delayType = Number(e.target.value);
                                    }}
                                >
                                    <option className="tl" value={1}>
                                        {x.delay === 1 ? 'minute' : 'minutes'}
                                    </option>
                                    <option className="tl" value={2}>
                                        {x.delay === 1 ? 'hour' : 'hours'}
                                    </option>
                                    <option className="tl" value={3}>
                                        {x.delay === 1 ? 'day' : 'days'}
                                    </option>
                                </select>
                                {x.delayType === 3 ? (
                                    <select
                                        className="bigInput reminder-training-input wide-input"
                                        value={x.timeType}
                                        onChange={(e) => {
                                            x.timeType = Number(e.target.value);
                                        }}
                                    >
                                        <option value={1}>Specific time</option>
                                        <option value={2}>
                                            Same time as trainings
                                        </option>
                                    </select>
                                ) : (
                                    ''
                                )}
                                {x.timeType === 1 && x.delayType === 3 ? (
                                    <select
                                        disabled={x.timeType === 2}
                                        className="bigInput reminder-training-input"
                                        value={x.sendTime}
                                        onChange={(e) => {
                                            x.sendTime = Number(e.target.value);
                                        }}
                                    >
                                        <option value="0">0:00 GMT</option>
                                        <option value="1">1:00 GMT</option>
                                        <option value="2">2:00 GMT</option>
                                        <option value="3">3:00 GMT</option>
                                        <option value="4">4:00 GMT</option>
                                        <option value="5">5:00 GMT</option>
                                        <option value="6">6:00 GMT</option>
                                        <option value="7">7:00 GMT</option>
                                        <option value="8">8:00 GMT</option>
                                        <option value="9">9:00 GMT</option>
                                        <option value="10">10:00 GMT</option>
                                        <option value="11">11:00 GMT</option>
                                        <option value="12">12:00 GMT</option>
                                        <option value="13">13:00 GMT</option>
                                        <option value="14">14:00 GMT</option>
                                        <option value="15">15:00 GMT</option>
                                        <option value="16">16:00 GMT</option>
                                        <option value="17">17:00 GMT</option>
                                        <option value="18">18:00 GMT</option>
                                        <option value="19">19:00 GMT</option>
                                        <option value="20">20:00 GMT</option>
                                        <option value="21">21:00 GMT</option>
                                        <option value="22">22:00 GMT</option>
                                        <option value="23">23:00 GMT</option>
                                    </select>
                                ) : (
                                    ''
                                )}
                                {i > 0 && (
                                    <div>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={(e) => {
                                                onDeleteReminderStep(x);
                                            }}
                                            color="secondary"
                                        >
                                            <DeleteForeverIcon fontSize="small" />
                                        </IconButton>
                                    </div>
                                )}
                            </div>
                        ))}
                        {reminders_steps.length === 0 ? <br /> : ''}
                        {reminders_steps.length < 5 && (
                            <ThemedText
                                className="add-new-reminder"
                                onClick={onAddReminderStep}
                            >
                                + Add new reminder
                            </ThemedText>
                        )}
                        <br />
                        {scheduleType === 0 ? (
                            <Box my={2}>
                                <ThemedButton
                                    secondary
                                    onClick={showScheduleModal}
                                >
                                    {`See Schedule${
                                        reminders_enabled &&
                                        reminders_steps &&
                                        reminders_steps.length > 0
                                            ? ' with Reminders'
                                            : ''
                                    }`}
                                </ThemedButton>
                            </Box>
                        ) : (
                            ''
                        )}
                        {reminders_enabled &&
                        reminders_steps &&
                        reminders_steps.length > 0
                            ? scheduleType > 0 && (
                                  <Box my={2}>
                                      <ThemedButton
                                          secondary
                                          onClick={showScheduleModal}
                                      >
                                          See reminders
                                      </ThemedButton>
                                  </Box>
                              )
                            : ''}
                        <br />
                        <SelectChannel
                            channel={reminders_channel}
                            slackAvailable={slackAvailable}
                            teamsAvailable={teamsAvailable}
                            slackConnected={slackConnected}
                            teamsConnected={teamsConnected}
                            onChangeChannel={onChangeRemindersChannel}
                            isReminders={true}
                        />
                    </Fragment>
                )}
            </div>
        </Fragment>
    );
}

export default observer(AutomationReminders);
