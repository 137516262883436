import styled from 'styled-components';
import { colors } from './colors';
import MuiFileCopy from '@material-ui/icons/FileCopy';
import MuiEdit from '@material-ui/icons/Edit';
import MuiDelete from '@material-ui/icons/Delete';
import MuiOpenInNew from '@material-ui/icons/OpenInNew';

export const SmallFileCopy = styled(MuiFileCopy)`
    font-size: 1em !important;
`;

export const SmallEdit = styled(MuiEdit)`
    font-size: 1em !important;
`;

export const SmallDelete = styled(MuiDelete)`
    font-size: 1em !important;
`;

export const PrimaryOpenInNew = styled(MuiOpenInNew)`
    color: ${colors.success};
`;
