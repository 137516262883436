import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Alert } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import pluralize from 'pluralize';
import FormDef from '../../forms/users';
import UsersFormDef from '../../forms/list-users';
import BaseCompanyDependentChildContainer from '../common/BaseCompanyDependentChildContainer';
import ErrorMessage from '../../components/errormessage';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import AddOrImport from './AddOrImport';
import range from 'lodash/range';

@inject('store', 'listStore', 'userStore')
@observer
class UsersAddContainer extends BaseCompanyDependentChildContainer {
    constructor(props) {
        super(props);
        this._usersForm = new UsersFormDef(
            {},
            {
                hooks: {
                    onSuccess: this.onSuccess,
                    onError: (e) => {
                        console.error(e);
                    },
                },
            }
        );

        if (this.props.list) {
            this._usersForm.update({ name: props.list.name });
            range(1).forEach((x) => {
                this._usersForm.$('users').onAdd({
                    preventDefault: () => {},
                });
            });
        }

        this._form = new FormDef({}, {});
        this.state = {
            batchMode: !this.props.list,
            deleteObsolete: false,
            files: [],
            encoding: 'utf-8',
            showEncodingSelect: false,
            existingUsersSelected: [],
        };
    }

    showEncodingOptions = (e) => {
        this.setState({ showEncodingSelect: true });
    };

    changeEncoding = (e) => {
        this.setState({ encoding: e.target.value });
        this.doHandleFileSelect(this.state.files, e.target.value);
    };

    loadAsPromise = (f, encoding) => {
        return new Promise(async (resolve, reject) => {
            let reader = new FileReader();

            reader.onload = function (e) {
                let text = reader.result;
                resolve(text);
            };
            reader.onabort = function () {
                reject();
            };

            reader.readAsText(f, encoding);
        });
    };

    doHandleFileSelect = async (files, encoding) => {
        let results = [];
        let acceptedFiles = [];
        for (let i = 0; i < files.length; i++) {
            let f = files[i];

            if (
                f.type &&
                f.size > 0 &&
                !f.name.endsWith('txt') &&
                !f.name.endsWith('csv')
            ) {
                continue;
            }
            acceptedFiles.push(f);

            let text = await this.loadAsPromise(f, encoding);
            results.push(text);
        }
        this.importFiles(results);
        return acceptedFiles;
    };

    handleFilesSelect = async (evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        let files = evt.target.files || evt.dataTransfer.files;

        let acceptedFiles = await this.doHandleFileSelect(
            files,
            this.state.encoding
        );

        this.setState({ files: acceptedFiles });
    };

    switchMode = () => {
        this.props.userStore.resetUploadData();
        this.setState({ batchMode: !this.state.batchMode });
    };

    toggleDeleteObsolete = () => {
        this.setState({ deleteObsolete: !this.state.deleteObsolete });
    };

    async doLoadData() {}

    onSuccess = async () => {
        let result = await this.props.userStore.saveUsers(
            [
                ...this._usersForm.values().users,
                ...this.state.existingUsersSelected.map((x) => {
                    return {
                        name: x.user_name,
                        email: x.email,
                    };
                }),
            ],
            this.props.companyId,
            this.props.list && this.props.list.id,
            false
        );
        if (result) this.props.onRequestClose();
    };

    componentDidMount() {
        return this.props.userStore.cleanEditor();
    }

    importUsers = () => {
        this._usersForm.submit();
    };

    reUpload = async () => {
        this.props.userStore.resetUploadData();
    };

    onSelectExistingUsers = (values) => {
        this.setState({ existingUsersSelected: values });
    };

    confirmImportFiles = async () => {
        let result = await this.props.userStore.saveUsers(
            this.props.userStore.processedRecords &&
                this.props.userStore.processedRecords.records,
            this.props.companyId,
            this.props.list && this.props.list.id,
            false,
            false,
            this.state.deleteObsolete
        );
        if (result) this.props.onRequestClose();
    };

    importFiles = (files) => {
        files.forEach(async (fileContent) => {
            await this.props.userStore.saveUsers(
                fileContent,
                this.props.companyId,
                this.props.list && this.props.list.id,
                false,
                true
            );
        });
    };

    render() {
        const { processedRecords, importError } = this.props.userStore;
        const { onRequestClose, isImportingLists } = this.props;

        console.log(processedRecords);

        return (
            <Fragment>
                <div className={'modalHeader'}>
                    <h3>
                        {isImportingLists
                            ? 'Import employee lists'
                            : 'Add employees'}
                    </h3>

                    <img
                        className={'modal-close-right'}
                        src="/static/img/close.svg"
                        alt="Close"
                        onClick={onRequestClose}
                    />
                </div>
                <div className={'modalContent d-flex flex-column'}>
                    {this.state.batchMode &&
                        processedRecords &&
                        processedRecords.newLists &&
                        processedRecords.newLists.length > 0 && (
                            <>
                                <Alert
                                    color="success"
                                    className="justify-content-center"
                                >
                                    {processedRecords.newLists.length} new{' '}
                                    {pluralize(
                                        'list',
                                        processedRecords.newLists.length
                                    )}{' '}
                                    will be created from .csv file
                                </Alert>
                                <div className="users-to-add">
                                    {processedRecords.newLists.map((x) => {
                                        return (
                                            <div className={'user-record'}>
                                                {' '}
                                                {x}
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        )}
                    {this.state.batchMode &&
                        processedRecords &&
                        processedRecords.newUsers &&
                        processedRecords.newUsers.length > 0 && (
                            <Alert
                                color="success"
                                className="justify-content-center"
                            >
                                {processedRecords.newUsers.length} new{' '}
                                {pluralize(
                                    'employee',
                                    processedRecords.newUsers.length
                                )}{' '}
                                found in .csv file
                            </Alert>
                        )}
                    {this.state.batchMode &&
                        processedRecords &&
                        processedRecords.obsolete > 0 && (
                            <div className={'container-checkbox small'}>
                                <label htmlFor={'addToCampaignsEmail'}>
                                    <input
                                        type="checkbox"
                                        className="checkmark"
                                        id={'addToCampaignsEmail'}
                                        checked={this.state.deleteObsolete}
                                        onChange={this.toggleDeleteObsolete}
                                    />
                                    Delete missing employees (
                                    {processedRecords.obsolete}{' '}
                                    {pluralize(
                                        'employee',
                                        processedRecords.obsolete
                                    )}{' '}
                                    no longer in the list )
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                        )}
                    <AddOrImport
                        onSelectExistingUsers={this.onSelectExistingUsers}
                        companyId={this.props.companyId}
                        batchMode={this.state.batchMode}
                        processedRecords={processedRecords}
                        deleteObsolete={this.state.deleteObsolete}
                        showEncodingSelect={this.state.showEncodingSelect}
                        showEncodingOptions={this.showEncodingOptions}
                        encoding={this.state.encoding}
                        changeEncoding={this.changeEncoding}
                        importFiles={this.importFiles}
                        handleFilesSelect={this.handleFilesSelect}
                        usersForm={this._usersForm}
                        isImportingLists={isImportingLists}
                    />
                    <ErrorMessage error={importError} />
                </div>
                <div className={'modalFooter'}>
                    <div className="d-flex flex-justify-between flex-fill">
                        {this.props.list && (
                            <ThemedButton
                                secondary
                                className="no-wrap"
                                onClick={this.switchMode}
                            >
                                {this.state.batchMode
                                    ? 'Switch to manual mode'
                                    : 'Switch to import mode'}
                            </ThemedButton>
                        )}

                        {!this.state.batchMode && (
                            <ThemedButton primary onClick={this.importUsers}>
                                Done
                            </ThemedButton>
                        )}
                        {this.state.batchMode && processedRecords && (
                            <Fragment>
                                <ThemedButton primary onClick={this.reUpload}>
                                    Re-Upload
                                </ThemedButton>
                                <ThemedButton
                                    primary
                                    onClick={this.confirmImportFiles}
                                    disabled={
                                        !(
                                            processedRecords.newUsers.length >
                                                0 ||
                                            (this.state.deleteObsolete &&
                                                processedRecords.obsolete > 0)
                                        )
                                    }
                                >
                                    Confirm
                                </ThemedButton>
                            </Fragment>
                        )}
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(UsersAddContainer);
