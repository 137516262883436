import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#FFFFFF',
        color: '#1D1E1D',
        boxShadow: theme.shadows[1],
        fontSize: 13,
        padding: '1.5em',
        borderRadius: '6px',
        fontWeight: 600,
        lineHeight: '26px',
    },
}))(Tooltip);

export default LightTooltip;
