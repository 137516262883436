import CommonStore from './commonStore';
import UserStore from './usersStore';
import SubscriptionsStore from './subscriptionsStore';
import ListStore from './listsStore';
import MainStore from './viewer';
import CompanyStore from './companyStore';
import AutomationStore from './automationStore';
import AssessmentStore from './assessmentStore';
import SuperStore from './superStore';
import AuthStore from './authStore';
import CatalogStore from './catalogStore';
import ResourcesStore from './resourcesStore';
import MailStore from './mailStore';
import BrandingStore from './brandingStore';
import PPVStore from './ppvStore';
import VideosStore from './videosStore';
import QuestionStore from './questions';

const commonStore = new CommonStore();

const userStore = new UserStore();
const store = new MainStore(commonStore);
const subscriptionStore = new SubscriptionsStore(commonStore);
const companyStore = new CompanyStore(store, subscriptionStore, commonStore);
const brandingStore = new BrandingStore(store, companyStore);
const listStore = new ListStore(userStore, commonStore);
const catalogStore = new CatalogStore();
const questionStore = new QuestionStore(companyStore, store);
const stores = {
    authStore: new AuthStore(commonStore, store, brandingStore),
    store,
    brandingStore,
    commonStore,
    userStore,
    listStore,
    companyStore,
    superStore: new SuperStore(),
    resourcesStore: new ResourcesStore(),
    subscriptionStore,
    catalogStore,
    mailStore: new MailStore(),
    ppvStore: new PPVStore(),
    automationStore: new AutomationStore(
        listStore,
        catalogStore,
        store,
        commonStore,
        companyStore
    ),
    assessmentStore: new AssessmentStore(
        listStore,
        store,
        commonStore,
        companyStore
    ),
    videosStore: new VideosStore(),
    questionStore,
};

store.setAuthStore(stores.authStore);
store.setSuperStore(stores.superStore);

export default stores;
