import React from 'react';
//import { observer} from 'mobx-react';

// Use this if need  just reload list on company change
//@observer
class BaseCompanyDependentContainer extends React.Component {
    state = { error: '', loadedOrError: null };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState) return; // not yet initialized
        if (nextProps.companyId !== prevState.companyId) {
            return {
                companyId: nextProps.companyId,
                loadedOrError: null,
            };
        }

        // No state update necessary
        return null;
    }

    componentDidMount() {
        // It's preferable in most cases to wait until after mounting to load data.
        // See below for a bit more context...
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loadedOrError === null) {
            // At this point, we're in the "commit" phase, so it's safe to load the new data.
            this.loadData();
        }
    }

    async doLoadData() {
        throw new Error('Not Implemented');
    }

    async loadData() {
        // console.log("load data", this.props.companyId)
        if (this.props.companyId) {
            try {
                await this.doLoadData();
                this.setState({ ...this.state, loadedOrError: true });
            } catch (e) {
                this.setState({ ...this.state, loadedOrError: e.message });
            }
        }
    }

    render() {
        return null;
    }
}

export default BaseCompanyDependentContainer;
