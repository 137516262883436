import React, { Fragment } from 'react';
import { calculateColor, defaultZero } from '../utils/helpers';
import InlineProgressBar from './InlineProgressBar';
import { TextDefault } from './design-system/texts';

function ProgressWithPercentage({ value, ...other }) {
    return (
        <Fragment>
            <InlineProgressBar
                value={defaultZero(value)}
                customcolor={calculateColor(value)}
                {...other}
            />
            <TextDefault bold color={calculateColor(value)}>
                {defaultZero(value)}%
            </TextDefault>
        </Fragment>
    );
}

export default ProgressWithPercentage;
