import React, {Fragment} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom'
import {Col, Row} from 'reactstrap';
import ProfileSettingsContainer from "./ProfileSettings";
import IntegrationsSettingsContainer from "./IntegrationsSettings";
import CompanySettingsContainer from "./CompanySettings";
import AdminsSettingsContainer from "./AdminsSettings";
import EmailSettingsContainer from "./EmailSettings";
import SubscriptionsSettingsContainer from "./SubscriptionSettings";
import ThemedLinkStep from "../../components/themed/ThemedLinkStep";
import CompanySlack from "../companies/CompanySlack";
import CompanyAzureAd from "../companies/CompanyAzureAd";
import CompanyAzureSSO from "../companies/CompanyAzureSSO";
import CompanyGoogle from "../companies/CompanyGoogle";
import {Helmet} from "react-helmet";
import classes from 'classnames';
import CompanyTeams from "../companies/CompanyTeams";
import {useStores} from "../../hooks/use-stores";
import APIKeys from "./APIKeys";

export default ({companyId}) => {
    const location = useLocation()
    const {store} = useStores();
    const {currentCompany} = store;
    const [showMobileMenu, setShowMobileMenu] = React.useState(false)

    const showLessonMenu = (itemID)=> {
        setShowMobileMenu( true );
    }
    const hideLessonMenu = (itemID) => {
        setShowMobileMenu( false );
    }

    return (
        <Fragment>
            <Helmet>
                <title>Settings</title>
            </Helmet>
            
            <div className="d-flex">
                <div className={classes("sidebar-mobile-menu ", { "show": (!showMobileMenu )})}  onClick={() => showLessonMenu()}></div>
                <div className={classes("sidebar-mobile-menu close", { "show": (showMobileMenu )})}  onClick={() => hideLessonMenu()}></div>

                <div className={classes("block sidebar-steps sidebar sidebar-links ", { "open": (showMobileMenu )})}  onClick={() => hideLessonMenu()}>
                    <div className="sidebar-inner">
                    <h3 style={{paddingLeft: '30px'}}>Settings</h3>
                    <ThemedLinkStep
                        active={(location.pathname === '/settings/' || location.pathname === '/settings/profile/') ? 'active' : 0}
                        to='/settings'
                        text='My profile'/>
                    <hr/>
                    <ThemedLinkStep
                        active={location.pathname === '/settings/company/' ? 'active' : 0}
                        to='/settings/company'
                        text='Company settings'/>
                    <ThemedLinkStep
                        active={location.pathname === '/settings/subscriptions/' ? 'active' : 0}
                        to='/settings/subscriptions'
                        text='Subscription'/>
                    <ThemedLinkStep
                        active={location.pathname === '/settings/admins/' ? 'active' : 0}
                        to='/settings/admins'
                        text='Admins'/>
                    <ThemedLinkStep
                        active={location.pathname.startsWith( '/settings/email/' ) ? 'active' : 0}
                        to='/settings/email'
                        text='Email Settings'/>
                    <ThemedLinkStep
                        active={location.pathname.startsWith( '/settings/integrations/' ) ? 'active' : 0}
                        to='/settings/integrations'
                        text='Integrations'/>


                    { currentCompany && currentCompany.settings["ui.api_allowed"] && <ThemedLinkStep
                        active={location.pathname.startsWith( '/settings/apikeys' ) ? 'active' : 0}
                        to='/settings/apikeys'
                        text='API Keys'/>}
                    </div>
                </div>
                <div className="d-flex flex-column fullwidth">
                    <div>
                        <Row>
                            <Col md="12" xs="12">
                                <Switch>
                                    { currentCompany && currentCompany.settings["ui.api_allowed"]
                                        && <Route path='/settings/apikeys' exact component={APIKeys}/>  }
                                    <Route path='/settings' exact component={ProfileSettingsContainer}/>
                                    <Route path='/settings/company' component={CompanySettingsContainer}/>
                                    <Route path='/settings/subscriptions' component={SubscriptionsSettingsContainer}/>
                                    <Route path='/settings/admins' component={AdminsSettingsContainer}/>
                                    <Route path='/settings/email'>
                                        <EmailSettingsContainer companyId={companyId}/>
                                    </Route>
                                    <Route path='/settings/integrations' exact >
                                        <IntegrationsSettingsContainer  companyId={companyId}/>
                                    </Route>
                                    <Route path={`/settings/integrations/slack`}>
                                        <CompanySlack parentUrl="/settings/integrations" companyId={companyId}/>
                                    </Route>
                                    <Route path={`/settings/integrations/teams`}>
                                        <CompanyTeams parentUrl="/settings/integrations" companyId={companyId}/>
                                    </Route>
                                    <Route path={`/settings/integrations/google`}>
                                        <CompanyGoogle parentUrl="/settings/integrations" companyId={companyId}/>
                                    </Route>
                                    <Route path={`/settings/integrations/azure`}>
                                        <CompanyAzureAd parentUrl="/settings/integrations" companyId={companyId}/>
                                    </Route>
                                    <Route path={`/settings/integrations/azuresso`}>
                                        <CompanyAzureSSO parentUrl="/settings/integrations" companyId={companyId}/>
                                    </Route>
                                </Switch>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </Fragment>

    );
}
