import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import VideoPreviewContainer from '../../../containers/videoPreview';
import SCORMPreviewContainer from '../../../containers/scormPreview';
import SubjectDetails from './details';
import { ThemedButton } from '../../themed/ThemedComponents';
import ThemedNotePlaceholder from '../../themed/ThemedNotePlaceholder';
import { FlexDiv } from '../../design-system/containers';
import ThemedSpinner from '../../themed/ThemedSpinner';
import sanitizeHtml from 'sanitize-html';
import pluralize from 'pluralize';
import SubjectNoteEditor from '../../manager/catalog/subjectNoteEditor';

const renderSubjectDetails = (
    subject,
    noteValue,
    editNoteId,
    rteEditorRef,
    onAddNote,
    onEditNote,
    onDeleteNote,
    cancelEditNote,
    onSaveNote
) => {
    return (
        <Fragment>
            <SubjectDetails subject={subject} />
            <br />
            {subject.notes.map((note) => {
                return (
                    <div className="subject-optional-note" key={note.id}>
                        <div className="subject-optional-note-actions">
                            <div
                                className="btn edit"
                                onClick={() => {
                                    onEditNote(note.id);
                                }}
                            ></div>
                            <div
                                className="btn delete-bin"
                                onClick={() => {
                                    onDeleteNote(subject, note.id);
                                }}
                            ></div>
                        </div>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(note.content),
                            }}
                        ></div>
                    </div>
                );
            })}
            <br />
            {editNoteId != null ? (
                <Fragment>
                    <SubjectNoteEditor
                        ref={rteEditorRef}
                        initialValue={noteValue}
                    />
                    <FlexDiv my={16} justifyEnd>
                        <ThemedButton secondary onClick={cancelEditNote}>
                            Cancel
                        </ThemedButton>
                        <ThemedButton
                            primary
                            airy
                            onClick={() => {
                                onSaveNote(subject);
                            }}
                        >
                            Save
                        </ThemedButton>
                    </FlexDiv>
                </Fragment>
            ) : (
                <ThemedNotePlaceholder
                    title="Add additional information"
                    description="Additional instructions for your employees, links to relevant resources etc."
                    onClick={onAddNote}
                />
            )}

            <hr />
            <h3>Quiz</h3>
            <div>
                {(subject.questions || []).map((x, i) => {
                    return (
                        <div key={i}>
                            <h5>
                                Question {i + 1}/{subject.questions.length}
                            </h5>
                            <p>{x.question}</p>
                            <ul>
                                <li>{x.correct_answer} (correct)</li>
                                <li>{x.wrong_answer1} </li>
                                <li>{x.wrong_answer2} </li>
                                <li>{x.wrong_answer3} </li>
                            </ul>
                        </div>
                    );
                })}
            </div>
        </Fragment>
    );
};
const renderVideo = (
    subject,
    noteValue,
    editNoteId,
    rteEditorRef,
    onAddNote,
    onEditNote,
    onDeleteNote,
    cancelEditNote,
    onSaveNote
) => {
    return (
        <div className="videos video block">
            <Row>
                <Col md="12" xs="12">
                    <h3>{subject.title}</h3>
                    <p>{subject.description}</p>
                    {/*<p className="description">Watch the video and then answer the question below</p>*/}
                    <div id="video" className="video">
                        {subject.subject_slug}
                        <VideoPreviewContainer
                            previewVideoLocation={subject.id}
                            modal={false}
                            isOpen={true}
                        />
                    </div>
                    {renderSubjectDetails(
                        subject,
                        noteValue,
                        editNoteId,
                        rteEditorRef,
                        onAddNote,
                        onEditNote,
                        onDeleteNote,
                        cancelEditNote,
                        onSaveNote
                    )}
                </Col>
            </Row>
        </div>
    );
};

const renderSCORM = (
    subject,
    noteValue,
    editNoteId,
    rteEditorRef,
    onAddNote,
    onEditNote,
    onDeleteNote,
    cancelEditNote,
    onSaveNote
) => {
    return (
        <div className="scorm-container block">
            <Row>
                <Col md="12" xs="12">
                    <h3>{subject.title}</h3>
                    <p>{subject.description}</p>
                    {/*<p className="description">Watch the video and then answer the question below</p>*/}
                    <div id="scorm" className="scorm">
                        {subject.subject_slug}
                        <SCORMPreviewContainer
                            previewScormLocation={subject.id}
                            modal={false}
                        />
                    </div>
                    {renderSubjectDetails(
                        subject,
                        noteValue,
                        editNoteId,
                        rteEditorRef,
                        onAddNote,
                        onEditNote,
                        onDeleteNote,
                        cancelEditNote,
                        onSaveNote
                    )}
                </Col>
            </Row>
        </div>
    );
};

function Subject({
    subject,
    showBackLink = true,
    editNoteId,
    rteEditorRef,
    onAddNote,
    onEditNote,
    onDeleteNote,
    cancelEditNote,
    onSaveNote,
}) {
    let note = subject && subject.notes.find((x) => x.id === editNoteId);
    let noteValue = note && note.content;

    if (subject) {
        return (
            <div>
                {showBackLink && (
                    <Link to={`/catalog/`}>
                        <div className={'navigation back-button'}>
                            Back to subjects
                        </div>
                    </Link>
                )}

                {subject.trainings.length > 0 && (
                    <div className="block">
                        <p>Note:</p>
                        <h5>
                            This subject is already included in the following{' '}
                            {pluralize('training', subject.trainings.length)}:
                        </h5>
                        <ul>
                            {subject.trainings.map((training, i) => {
                                return (
                                    <li key={i}>
                                        <Link
                                            to={`/trainings/${training.id}/view`}
                                        >
                                            {training.name}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}

                {subject.type === 1 &&
                    renderVideo(
                        subject,
                        noteValue,
                        editNoteId,
                        rteEditorRef,
                        onAddNote,
                        onEditNote,
                        onDeleteNote,
                        cancelEditNote,
                        onSaveNote
                    )}
                {subject.type === 2 &&
                    renderSCORM(
                        subject,
                        noteValue,
                        editNoteId,
                        rteEditorRef,
                        onAddNote,
                        onEditNote,
                        onDeleteNote,
                        cancelEditNote,
                        onSaveNote
                    )}
            </div>
        );
    } else {
        return (
            <div className="videos">
                <Row>
                    <Col md="12" xs="12" className="leftMenu">
                        <ThemedSpinner />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default observer(Subject);
