import React from 'react';
import { observer } from 'mobx-react';
import { Row, Col, Alert } from 'reactstrap';
import { Table, Tr, Td, Th, Thead } from 'reactable';
import { formatDate } from '../../../utils/helpers';
import classnames from 'classnames';
import { ThemedLinkLink } from '../../themed/ThemedComponents';
import ProgressWithPercentage from '../../ProgressWithPercentage';

@observer
class UserCampaignDetails extends React.Component {
    state = {
        dropdownOpen: false,
    };

    toggleExtraActions = () => {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    };

    render() {
        const {
            userDetails,
            showTrainings = false,
            anonymousTracking = false,
        } = this.props;

        const subjects = userDetails.subjects ? userDetails.subjects : [];
        const score = userDetails.score || 0;
        return (
            <Row>
                <Col md="12" xs="12" className="content">
                    <div className="block d-flex flex-row">
                        <div className={'section-header'}>
                            <div className={'left padded'}>
                                <h1>{this.props.email}</h1>
                                {console.log('ud:' + userDetails.started)}

                                {userDetails.started !== undefined && (
                                    <div>
                                        <p>
                                            Started on{' '}
                                            {formatDate(userDetails.started)}
                                        </p>
                                        <ProgressWithPercentage
                                            value={score}
                                            big="true"
                                            thin="true"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="block no-top-padd list">
                        {/* Show Alert instead of table if company has company.anonymousTracking

                    */}
                        {anonymousTracking ? (
                            <Alert color="warning">
                                Usertracking is disabled{' '}
                            </Alert>
                        ) : (
                            <Table
                                className="table"
                                id="table"
                                itemsPerPage={50}
                                pageButtonLimit={5}
                                sortable={[
                                    {
                                        column: 'Lesson',
                                        sortFunction: function (a, b) {
                                            return a
                                                .toLowerCase()
                                                .localeCompare(b.toLowerCase());
                                        },
                                    },
                                    'Delivery',
                                    'Views',
                                ]}
                                defaultSort="Delivery"
                                filterable={['Lesson']}
                                filterPlaceholder="Search Subjects"
                            >
                                <Thead>
                                    <Th column="Lesson" className="">
                                        {' '}
                                        Lesson
                                    </Th>
                                    {showTrainings && (
                                        <Th column="Trainings" className="">
                                            Trainings
                                        </Th>
                                    )}
                                    <Th column="Delivery" className="center">
                                        Delivery date
                                    </Th>
                                    <Th
                                        column="Viewed"
                                        align="center"
                                        className="center"
                                    >
                                        Viewed
                                    </Th>
                                    <Th column="Correctly" className="center">
                                        Correctly answered
                                    </Th>
                                    <Th column="Incorrectly" className="center">
                                        Incorrect count
                                    </Th>
                                    {!showTrainings && (
                                        <Th column="Remind" className="">
                                            Remind
                                        </Th>
                                    )}
                                </Thead>
                                {subjects.length > 0 &&
                                    subjects.map((subject, i) => (
                                        <Tr
                                            key={i}
                                            className={classnames(
                                                'status-row',
                                                {
                                                    disabled:
                                                        subject.status === 0,
                                                }
                                            )}
                                        >
                                            <Td
                                                column={'Lesson'}
                                                value={subject.title}
                                                className="list-name-column"
                                            >
                                                {subject.title}
                                            </Td>
                                            {showTrainings && (
                                                <Th
                                                    column="Trainings"
                                                    className=""
                                                >
                                                    <span className="d-flex flex-column">
                                                        {subject.training_ids.map(
                                                            (x) => (
                                                                <ThemedLinkLink
                                                                    key={x}
                                                                    to={`/trainings/${x}/view`}
                                                                >
                                                                    {' '}
                                                                    {
                                                                        userDetails
                                                                            .trainings[
                                                                            x
                                                                        ].name
                                                                    }{' '}
                                                                </ThemedLinkLink>
                                                            )
                                                        )}
                                                    </span>
                                                </Th>
                                            )}
                                            <Td
                                                column={'Delivery'}
                                                className="center"
                                                value={subject.delivered}
                                            >
                                                {formatDate(subject.delivered)}
                                            </Td>
                                            <Td
                                                column={'Viewed'}
                                                className="center"
                                            >
                                                {subject.viewed > 0 ? (
                                                    <img
                                                        className="check-mark"
                                                        alt="icon"
                                                        title="Viewed"
                                                        src="/static/img/icon-checked.svg"
                                                    />
                                                ) : (
                                                    <img
                                                        className="check-mark not-checked"
                                                        alt="icon"
                                                        title="Not viewed"
                                                        src="/static/img/icon-not-checked.svg"
                                                    />
                                                )}
                                            </Td>
                                            <Td
                                                column={'Correctly'}
                                                className="center"
                                            >
                                                {subject.correct > 0 ? (
                                                    <img
                                                        className="check-mark"
                                                        alt="icon"
                                                        title="Answered correctly"
                                                        src="/static/img/icon-checked.svg"
                                                    />
                                                ) : (
                                                    <img
                                                        className="check-mark not-checked"
                                                        alt="icon"
                                                        title="Not answered"
                                                        src="/static/img/icon-not-checked.svg"
                                                    />
                                                )}
                                            </Td>
                                            <Td
                                                column={'Incorrectly'}
                                                className="center"
                                            >
                                                {subject.incorrect}
                                            </Td>
                                            {!showTrainings && (
                                                <Td column={'Remind'}>
                                                    {subject.status ? (
                                                        <div
                                                            className="btn remind"
                                                            onClick={(e) => {
                                                                this.props.onSendReminder(
                                                                    subject
                                                                );
                                                            }}
                                                        >
                                                            Remind
                                                        </div>
                                                    ) : null}
                                                </Td>
                                            )}
                                        </Tr>
                                    ))}
                            </Table>
                        )}
                    </div>
                </Col>
            </Row>
        );
    }
}

export default UserCampaignDetails;
