import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import CompaniesAppearance from './CompanyAppearance';
import CompanySubscription from './CompanySubscription';
import CompaniesManagersContainer from './CompanyManagers';
import SettingSelect from '../settings/SettingSelect';
import { useStores } from '../../hooks/use-stores';
import {
    FlexDiv,
    SpaceBetweenDiv,
    Block,
} from '../../components/design-system/containers';
import { Heading2 } from '../../components/design-system/headings';
import { TextTiny } from '../../components/design-system/texts';
import pluralize from 'pluralize';
import EditIcon from '@material-ui/icons/Edit';
import { BigModal } from '../../components/design-system/modals';
import CompanyForm from '../../components/partner/CompanyForm';
import moment from 'moment';

function CompanyDetails() {
    const { store, superStore, companyStore, subscriptionStore, commonStore } =
        useStores();
    const [showRenameModal, setShowRenameModal] = useState(false);

    let history = useHistory();

    const { editingCompany } = superStore;
    const { languages } = store;
    const { companyForm } = subscriptionStore;

    const handleRenameCompany = async () => {
        subscriptionStore.initCompanyForm(
            match.params.partnerId,
            handleSubmitCompanyRename,
            editingCompany
        );
        setShowRenameModal(true);
    };

    let match = useRouteMatch();

    const handleSubmitCompanyRename = async () => {
        closeRenameModal();
        const newName = subscriptionStore.companyForm.values().companyName;
        const result = await companyStore.save(editingCompany.company_id, {
            name: newName,
        });
        if (result) {
            commonStore.success('Company renamed.');
            superStore.editingCompany.company_name = newName;
        } else {
            if (companyStore.error) {
                commonStore.error(companyStore.error);
            }
        }
    };

    const loadData = useCallback(async () => {
        const companyId = match.params.company_id;
        try {
            await superStore.loadCompany(companyId);
        } catch (e) {
            console.log(e);
        }
    }, [match.params.company_id, superStore]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const visitDashboard = async () => {
        await store.setCurrentCompany(editingCompany.company_id);
        await history.push(`/`);
    };

    const closeRenameModal = () => {
        setShowRenameModal(false);
    };

    const createOptionsArray = (languages) => {
        let optionsArray = [];
        for (let i = 0; i < languages.length; i++) {
            optionsArray.push({
                key: languages[i].code,
                value: languages[i].code,
                name: languages[i].english_name,
            });
        }
        return optionsArray;
    };

    const onChangeLanguage = async (languageKey) => {
        editingCompany.defaultLanguage = languageKey;
        await superStore.update(editingCompany.company_id, {
            ...{ defaultLanguage: languageKey },
        });
    };

    return !editingCompany ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <Link to={`/partner/${editingCompany && editingCompany.partnerId}`}>
                <div className={'navigation back-button'}>
                    Back to Companies
                </div>
            </Link>
            <Block>
                <SpaceBetweenDiv>
                    <FlexDiv column>
                        <FlexDiv alignCenter mb={4}>
                            <Heading2>{editingCompany.company_name}</Heading2>
                            <FlexDiv pl={8} onClick={handleRenameCompany} link>
                                <EditIcon color="primary" alt="pencil" />
                            </FlexDiv>
                        </FlexDiv>
                        <TextTiny lighter>
                            Joined on{' '}
                            {moment(editingCompany.company_created).format(
                                'LL'
                            )}
                            {' · '}
                            {editingCompany.users_total}{' '}
                            {pluralize('employee', editingCompany.users_total)}
                            {' · '}{' '}
                            {editingCompany.status.trainings.active.value}{' '}
                            active training{' '}
                            {pluralize(
                                'program',
                                editingCompany.status.trainings.active.value
                            )}
                            {' · '}
                            {
                                editingCompany.status.assessments.active.value
                            }{' '}
                            active{' '}
                            {pluralize(
                                'assessment',
                                editingCompany.status.assessments.active.value
                            )}
                        </TextTiny>
                    </FlexDiv>
                    <ThemedButton primary onClick={() => visitDashboard()}>
                        Go to dashboard
                    </ThemedButton>
                </SpaceBetweenDiv>
            </Block>
            <CompaniesManagersContainer company={editingCompany} />
            <CompanySubscription
                company={editingCompany}
                partnerId={editingCompany.partnerId}
                loadCompany={loadData}
            />

            <Block>
                <FlexDiv column>
                    <Heading2>Company Settings</Heading2>
                    <SettingSelect
                        name="Default language for employees"
                        options={createOptionsArray(languages)}
                        defaultValue={editingCompany.defaultLanguage || 'en'}
                        action={onChangeLanguage}
                        isPartnerCompanyDetails
                        isLast
                    />
                </FlexDiv>
            </Block>

            <CompaniesAppearance company={editingCompany} />

            <BigModal
                isOpen={showRenameModal}
                onRequestClose={closeRenameModal}
                heading="Rename company"
                modalContent={<CompanyForm companyForm={companyForm} />}
                modalFooterContent={
                    <Fragment>
                        <ThemedButton
                            secondary
                            onClick={() => closeRenameModal()}
                        >
                            Cancel
                        </ThemedButton>
                        <ThemedButton
                            primary
                            airy
                            onClick={() => companyForm.submit()}
                        >
                            Save
                        </ThemedButton>
                    </Fragment>
                }
            />
        </Fragment>
    );
}

export default observer(CompanyDetails);
