import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '../../hooks/use-stores';
import CustomizeAppearance from '../../components/company/customize-appearance';

function CompanyAppearance({ company }) {
    const { superStore } = useStores();

    const saveColor = async (primaryColor) => {
        await superStore.update(company.company_id, {
            ...{ primaryColor },
        });
    };

    const resetToDefaults = async () => {
        await superStore.update(company.company_id, {
            ...{
                primaryColor: null,
                logoName: null,
            },
        });
    };

    const importLogo = async formData => {
        await superStore.uploadLogoAsPartnerAdmin(
            company.company_id,
            formData
        );
    };

    const removeLogo = async () => {
        await superStore.removeLogoAsPartnerAdmin(
            company.company_id
        );
    };

    return (
        <CustomizeAppearance
            company={company}
            saveColor={saveColor}
            resetToDefaults={resetToDefaults}
            importLogo={importLogo}
            removeLogo={removeLogo}
            uploadingLogo={superStore.uploadingLogoAsPartner}
        />
    );
}

export default observer(CompanyAppearance);
