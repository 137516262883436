import React from 'react';
import { inject, observer } from 'mobx-react';
import Subject from '../components/manager/catalog/subject';
import { fixHtml } from '../utils/helpers';

@inject('catalogStore', 'commonStore')
@observer
class SubjectContainer extends React.Component {
    state = { editNoteId: null };

    onAddNote = () => {
        this.setState({ ...this.state, editNoteId: -1 });
    };

    onEditNote = (editNoteId) => {
        this.setState({ ...this.state, editNoteId });
    };
    onSaveNote = async (subject) => {
        let value = this.rteEditorRef.current.getValue();
        let result = await this.props.catalogStore.saveNote(
            this.props.companyId,
            subject.id,
            this.state.editNoteId,
            fixHtml(value),
            subject
        );
        if (result) {
            this.props.commonStore.success('Note saved.');
            this.setState({ ...this.state, editNoteId: null });
        } else {
            if (this.props.catalogStore.error) {
                this.props.commonStore.error(this.props.catalogStore.error);
            }
        }
    };

    onDeleteNote = async (subject, id) => {
        this.props.commonStore.showConfirm(
            'Are you sure you want to delete this note?',
            'Delete',
            'Delete note',
            async () => {
                let result = await this.props.catalogStore.deleteNote(
                    this.props.companyId,
                    this.props.subject.id,
                    id,
                    subject
                );
                if (result) {
                    this.props.commonStore.success('Note deleted.');
                    this.setState({ ...this.state, updated: new Date() });
                } else {
                    if (this.props.catalogStore.error) {
                        this.props.commonStore.error(
                            this.props.catalogStore.error
                        );
                    }
                }
            },
            true
        );
    };

    cancelEditNote = (editNoteId) => {
        this.setState({ ...this.state, editNoteId: null });
    };

    getSubject() {
        let subject = null;
        if (this.props.subject) return this.props.subject;
        if (this.props.categories && this.props.categories.length > 0) {
            for (let i = 0; i < this.props.categories.length && !subject; i++) {
                if (
                    this.props.categories[i].subjects &&
                    this.props.categories[i].subjects.length > 0
                ) {
                    for (
                        let a = 0;
                        a < this.props.categories[i].subjects.length;
                        a++
                    ) {
                        if (
                            this.props.categories[i].subjects[a].id ===
                            this.props.id
                        ) {
                            return this.props.categories[i].subjects[a];
                        }
                    }
                }
            }
        }
        return null;
    }

    rteEditorRef = React.createRef();

    render() {
        const { showBackLink = true } = this.props;
        const subject = this.getSubject();

        return (
            <Subject
                upated={this.state.updated}
                editNoteId={this.state.editNoteId}
                rteEditorRef={this.rteEditorRef}
                onNoteChange={this.onNoteChange}
                showBackLink={showBackLink}
                onAddNote={this.onAddNote}
                onEditNote={this.onEditNote}
                onDeleteNote={this.onDeleteNote}
                onSaveNote={this.onSaveNote}
                cancelEditNote={this.cancelEditNote}
                subject={subject}
            />
        );
    }
}

export default SubjectContainer;
