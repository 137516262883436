import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import ThemedSpinner from '../themed/ThemedSpinner';
import { observer } from 'mobx-react';

const Utils = require('../../utils/helpers');

@observer
class PackageTable extends React.Component {
    render() {
        const {
            packages,
            onChangePackageType,
            onEditVideos,
            fullInfo = true,
        } = this.props;

        if (!packages) {
            return null;
        } else {
            if (packages.length > 0) {
                return (
                    <div className="table-white-bg">
                        <table className="table2">
                            <thead>
                                <tr>
                                    {fullInfo && <th>Name</th>}
                                    <th>Created</th>
                                    <th>Valid from</th>
                                    <th>Valid to</th>
                                    <th>User count</th>
                                    {fullInfo && (
                                        <Fragment>
                                            <th>Package Type</th>
                                            <th>Videos</th>
                                            <th />
                                            <th />
                                        </Fragment>
                                    )}
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {packages.map((pack) => (
                                    <tr
                                        key={pack.package_id}
                                        className={!pack.valid ? 'invalid' : ''}
                                    >
                                        {fullInfo && (
                                            <td>{pack.package_name} </td>
                                        )}
                                        <td>
                                            {Utils.formatDate(pack.created)}{' '}
                                        </td>
                                        <td>
                                            {Utils.formatDate(pack.valid_from)}
                                        </td>
                                        <td>
                                            {Utils.formatDate(pack.valid_to)}
                                        </td>
                                        <td>{pack.users}</td>
                                        {fullInfo && (
                                            <Fragment>
                                                <td>
                                                    <select
                                                        name="packageType"
                                                        className="bigInput"
                                                        value={
                                                            pack.full_package
                                                        }
                                                        onChange={(e) =>
                                                            onChangePackageType(
                                                                pack.package_id,
                                                                Number(
                                                                    e.target
                                                                        .value
                                                                )
                                                            )
                                                        }
                                                    >
                                                        <option value="0">
                                                            Partial
                                                        </option>
                                                        <option
                                                            value="1"
                                                            className="tl"
                                                        >
                                                            Full
                                                        </option>
                                                    </select>
                                                </td>
                                                <td>
                                                    {pack.full_package ? (
                                                        'All'
                                                    ) : (
                                                        <span>
                                                            {
                                                                (
                                                                    pack.videos ||
                                                                    []
                                                                ).length
                                                            }{' '}
                                                            videos{' '}
                                                            <Button
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    onEditVideos(
                                                                        pack
                                                                    );
                                                                }}
                                                            >
                                                                Edit
                                                            </Button>
                                                        </span>
                                                    )}
                                                </td>
                                            </Fragment>
                                        )}
                                        <td>
                                            {!!pack.trial && (
                                                <strong>TRIAL</strong>
                                            )}
                                        </td>
                                        {fullInfo && (
                                            <Fragment>
                                                <td>
                                                    {!!pack.valid && (
                                                        <strong>VALID</strong>
                                                    )}
                                                    {!pack.valid && (
                                                        <strong>EXPIRED</strong>
                                                    )}
                                                </td>
                                            </Fragment>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            } else {
                return null;
            }
        }
    }
}

export default PackageTable;
