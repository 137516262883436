import React from 'react';
import { observer } from 'mobx-react';
import TextField from '../inputs/textField';

@observer
class LanguageEdit extends React.Component {
    render() {
        const { form } = this.props;

        return (
            <div className="steps step2">
                <div className="forms">
                    <div className="form-header">
                        <h2>Language</h2>
                    </div>

                    <div className="form-holder">
                        <TextField field={form.$('name')} />
                    </div>
                    <div className="form-holder">
                        <TextField field={form.$('english_name')} />
                    </div>
                    <div className="form-holder">
                        <TextField field={form.$('code')} />
                    </div>
                    <div className="form-holder">
                        <TextField field={form.$('version')} />
                    </div>
                    <div className="form-holder">
                        <TextField field={form.$('icon')} />
                    </div>
                    <div className="form-holder">
                        <TextField field={form.$('flag_image')} />
                    </div>
                </div>
            </div>
        );
    }
}

export default LanguageEdit;
