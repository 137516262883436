import { action, observable } from 'mobx';
import services from '../services';
import { extractErrorMessage } from '../utils/helpers';
import {
    generateLoadList,
    generateLoadEntity,
    generateDeleteEntity,
    generateCreateEntity,
    generateUpdateEntity,
} from '../utils/mobx';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import _each from 'lodash/each';
import { computed } from 'mobx/lib/mobx';

class SuperStore {
    @observable managerTokens = observable([]);
    @observable mailHooks = observable([]);
    @observable ppvLog = observable([]);
    @observable ppvTransactions = observable([]);
    @observable companies = observable([]);
    @observable partners = observable([]);
    @observable freemiums = observable([]);
    @observable updatingPackages = observable([]);
    @observable updatingFreemiums = observable([]);
    @observable removingPartnerSettings = observable([]);
    @observable removingCompanySettings = observable([]);
    @observable removingPackages = observable([]);
    @observable removingManagers = observable([]);
    @observable removingCompanies = observable([]);
    @observable removingPartnerManagers = observable([]);
    @observable removingFreemiums = observable([]);
    @observable loadingMailHooks = false;
    @observable loadingCompanies = false;
    @observable loadingPartnerCompanies = null;
    @observable loadingPartners = false;
    @observable loadingPartnerSubscriptions = false;
    @observable loadingPartnerSubscriptionsOverview = false;
    @observable collectingPayment = false;
    @observable savingPPVApiKey = false;
    @observable loadingPPVLog = false;
    @observable loadingPPVTransactions = false;
    @observable savingPPV = false;
    @observable savingCompany = false;
    @observable savingPartner = false;
    @observable loadingCompany = false;
    @observable loadingPartner = false;
    @observable uploadingLogoAsPartner = false;
    @observable addingPackage = false;
    @observable addingManager = false;
    @observable addingPartnerManager = false;
    @observable addingPartnerSetting = false;
    @observable addingCompanySetting = false;
    @observable addingFreemium = false;
    @observable partnerSubscription = null;
    @observable partnerSubscriptionsOverview = null;
    @observable editingCompany = null;
    @observable editingPartner = null;
    @observable currentPartner = null;
    @observable company = null;
    @observable error = null;
    @observable languageError = null;
    @observable subjectsError = null;
    @observable templatesError = null;
    @observable categoriesError = null;
    @observable aclError = null;

    @observable savingLanguage = false;
    @observable loadingLanguage = false;
    @observable loadingLanguages = false;
    @observable creatingLanguage = false;
    @observable languages = [];
    @observable editingLanguage = null;

    @observable loadingSubjects = false;
    @observable loadingSubject = false;
    @observable savingSubject = false;
    @observable editingSubject = null;
    @observable editingSubjectLanguages = [];
    @observable removingAclLines = [];

    @observable updatingQuestions = [];
    @observable removingQuestions = [];
    @observable editingQuestion = null;
    @observable loadingQuestions = false;
    @observable loadingQuestion = false;
    @observable creatingQuestion = false;

    @observable loadingTemplates = false;
    @observable loadingTemplate = false;
    @observable editingTemplate = null;
    @observable loadingTemplateSubjects = false;
    @observable editingTemplateSubjects = observable([]);

    @observable loadingCategories = false;
    @observable loadingCategory = false;
    @observable editingCategory = null;
    @observable loadingCategorySubjects = false;
    @observable editingCategorySubjects = observable([]);

    @observable creatingAclLine = false;

    @observable companySearchValue = '';

    @action setCompanySearchValue(companySearchValue) {
        this.companySearchValue = companySearchValue;
    }

    @action setError(error, name) {
        switch (name) {
            case 'acl':
                this.aclError = error;
                break;

            case 'templates':
                this.templatesError = error;
                break;
            case 'categories':
                this.categoriesError = error;
                break;
            case 'subjects':
                this.subjectsError = error;
                break;
            case 'languages':
                this.languageError = error;
                break;
            default:
                this.error = error;
        }
    }

    @action
    async update(companyId, values) {
        this.savingCompany = true;
        this.error = null;
        try {
            if (companyId) {
                let result = await services.Companies.update({
                    id: companyId,
                    ...values,
                });
                Object.assign(this.editingCompany, result, values);
            } else {
                await services.Companies.super().create({ ...values }, true);
                await this.loadCompanies();
                return true;
            }

            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingCompany = false;
        }
    }

    @action
    async updateLanguage(languageId, values) {
        this.savingLanguage = true;
        this.error = null;
        try {
            if (languageId) {
                let result = await services.Languages.update({
                    id: languageId,
                    ...values,
                });
                if (this.editingLanguage)
                    Object.assign(this.editingLanguage, result, values);
                let lang = this.languages.find((x) => x.id === languageId);
                if (lang) Object.assign(lang, result, values);
            } else {
                await services.Languages.create({ ...values }, true);
                await this.loadLanguages();
                return true;
            }
            return true;
        } catch (e) {
            this.languageError = extractErrorMessage(e);
        } finally {
            this.savingLanguage = false;
        }
    }

    @action
    async updatePartner(partnerId, values) {
        this.savingPartner = true;
        this.error = null;
        try {
            if (partnerId) {
                let result = await services.Partners.update({
                    id: partnerId,
                    ...values,
                });
                Object.assign(this.editingPartner, result, values);
            } else {
                let partner = await services.Partners.create(
                    { ...values },
                    true
                );
                await this.loadPartners();
                return partner;
            }

            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingPartner = false;
        }
    }

    @action
    clearEditingCompany() {
        this.editingCompany = null;
    }

    @action
    clearEditingPartner() {
        this.editingPartner = null;
    }

    @action
    async loadCompany(companyId) {
        if (this.loadingCompany) return;
        this.error = null;
        if (this.editingCompany != null) {
            if (this.editingCompany.id === companyId) return;
            else this.editingCompany = null;
        }
        try {
            this.loadingCompany = true;
            this.editingCompany = await services.Companies.fetch(companyId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingCompany = false;
        }
    }

    @action
    async loadPartner(partnerId) {
        if (this.loadingPartner) return;
        this.error = null;
        if (this.editingPartner != null) {
            if (this.editingPartner.id === partnerId) return;
            else this.editingPartner = null;
        }
        try {
            this.loadingPartner = true;
            this.editingPartner = await services.Partners.fetch(partnerId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingPartner = false;
        }
    }

    @action
    async addPackage(companyId, data) {
        this.addingPackage = true;
        this.error = null;
        try {
            await services.Companies.packagesService(companyId).create(data);
            this.editingCompany = await services.Companies.fetch(companyId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.addingPackage = false;
        }
    }

    @action
    async removePackage(companyId, packageId) {
        this.removingPackages.push(packageId);
        this.error = null;
        try {
            await services.Companies.packagesService(companyId).delete(
                packageId
            );
            this.editingCompany = await services.Companies.fetch(companyId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.removingPackages.remove(packageId);
        }
    }

    @action
    async updatePackage(companyId, packageId, values) {
        this.updatingPackages.push(packageId);
        this.error = null;
        try {
            await services.Companies.packagesService(companyId).update(
                values,
                packageId
            );
            this.editingCompany = await services.Companies.fetch(companyId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.updatingPackages.remove(packageId);
        }
    }

    @action
    async updatePackageVideos(companyId, packageId, videos) {
        this.updatingPackages.push(packageId);
        this.error = null;
        try {
            await services.Companies.packagesService(companyId).updateVideos(
                packageId,
                videos
            );
            this.editingCompany = await services.Companies.fetch(companyId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.updatingPackages.remove(packageId);
        }
    }

    @action
    async listFreemiums() {
        try {
            let result = await services.Freemiums.list();
            this.freemiums.replace(result);
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    @action
    async addFreemium(code, validity, limit, users) {
        this.addingFreemium = true;
        this.error = null;
        try {
            await services.Freemiums.create({ code, validity, limit, users });
            await this.listFreemiums();
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.addingFreemium = false;
        }
    }

    @action
    async updateFreemiumVideos(code, videos) {
        this.updatingFreemiums.push(code);
        this.error = null;
        try {
            await services.Freemiums.updateVideos(code, videos);
            await this.listFreemiums();
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.updatingFreemiums.remove(code);
        }
    }

    @action
    async removeFreemium(code) {
        this.removingFreemiums.push(code);
        this.error = null;
        try {
            await services.Freemiums.delete(code);
            await this.listFreemiums();
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.removingFreemiums.remove(code);
        }
    }

    @action
    async resetCounter(code) {
        this.removingFreemiums.push(code);
        this.error = null;
        try {
            await services.Freemiums.resetCounter(code);
            await this.listFreemiums();
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.removingFreemiums.remove(code);
        }
    }

    @action
    async addManager(companyId, data) {
        this.addingManager = true;
        this.error = null;
        try {
            await services.Companies.managersService(companyId).create(data);
            this.editingCompany = await services.Companies.fetch(companyId);
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.addingManager = false;
        }
    }

    @action
    async setBillingPerson(partnerId, data) {
        this.addingPartnerManager = true;
        this.error = null;
        try {
            await services.Partners.managersService(
                partnerId
            ).setBillingManager(data);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.addingPartnerManager = false;
        }
    }

    @action
    async addPartnerManager(partnerId, data) {
        this.addingPartnerManager = true;
        this.error = null;
        try {
            await services.Partners.managersService(partnerId).create(data);
            this.editingPartner = await services.Partners.fetch(partnerId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.addingPartnerManager = false;
        }
    }

    @action
    async removeManager(companyId, email) {
        this.removingManagers.push(email);
        this.error = null;
        try {
            await services.Companies.managersService(companyId).delete(email);
            this.editingCompany = await services.Companies.fetch(companyId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.removingManagers.remove(email);
        }
    }

    @action
    async removePartnerManager(partnerId, rowId) {
        this.removingPartnerManagers.push(rowId);
        this.error = null;
        try {
            await services.Partners.managersService(partnerId).delete(rowId);
            this.editingPartner = await services.Partners.fetch(partnerId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.removingPartnerManagers.remove(rowId);
        }
    }

    @action
    async impersonate(email) {
        try {
            let access_link = await services.System.impersonate(email);
            this.removeToken(email);
            this.managerTokens.push({ email, access_link });
            return access_link;
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    @action
    removeToken(email) {
        let existing = this.managerTokens.find((x) => x.email === email);
        if (existing) this.managerTokens.remove(existing);
    }

    @computed
    get managerTokensIndex() {
        return keyBy(this.managerTokens, 'email');
    }

    @action
    async addPartnerSetting(partnerId, data) {
        this.addingPartnerSetting = true;
        this.error = null;
        try {
            await services.Partners.settingsService(partnerId).create(data);
            this.editingPartner = await services.Partners.fetch(partnerId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.addingPartnerSetting = false;
        }
    }

    @action
    async updatePartnerSetting(partnerId, id, value) {
        this.removingPartnerSettings.push(id);
        this.error = null;
        try {
            let s = this.editingPartner.settings.find((x) => x.id === id);
            s.value = value;
            await services.Partners.settingsService(partnerId).update(
                { value },
                id
            );
            //this.editingPartner = await services.Partners.fetch(partnerId)
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.removingPartnerSettings.remove(id);
        }
    }

    @action
    async removePartnerSetting(partnerId, id) {
        this.removingPartnerSettings.push(id);
        this.error = null;
        try {
            await services.Partners.settingsService(partnerId).delete(id);
            this.editingPartner = await services.Partners.fetch(partnerId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.removingPartnerSettings.remove(id);
        }
    }

    // ----- COMPANY SETTINGS
    @action
    async addCompanySetting(companyId, data) {
        this.addingCompanySetting = true;
        this.error = null;
        try {
            await services.Companies.settingsService(companyId).create(data);
            this.editingCompany = await services.Companies.fetch(companyId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.addingCompanySetting = false;
        }
    }

    @action
    async updateCompanySetting(companyId, id, value) {
        this.removingCompanySettings.push(id);
        this.error = null;
        try {
            let s = this.editingCompany.settings.find((x) => x.id === id);
            s.value = value;
            await services.Companies.settingsService(companyId).update(
                { value },
                id
            );
            //this.editingPartner = await services.Partners.fetch(partnerId)
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.removingCompanySettings.remove(id);
        }
    }

    @action
    async removeCompanySetting(companyId, id) {
        this.removingCompanySettings.push(id);
        this.error = null;
        try {
            await services.Companies.settingsService(companyId).delete(id);
            this.editingCompany = await services.Companies.fetch(companyId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.removingCompanySettings.remove(id);
        }
    }

    @action
    async togglePartner(partnerId, enabled) {
        this.error = null;
        try {
            await services.Partners.togglePartner(partnerId, enabled);
            this.editingPartner = await services.Partners.fetch(partnerId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    @action
    async createPPV(companyId) {
        this.savingPPV = true;
        this.error = null;
        try {
            let ppv = await services.Companies.createPPV(companyId);
            this.editingCompany.ppv_package = ppv;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingPPV = false;
        }
    }

    @action
    async addViews(companyId, views, notes) {
        if (!views) return;
        this.savingPPV = true;
        this.error = null;
        try {
            let ppv = await services.Companies.addViews(companyId, {
                views,
                notes,
            });
            this.editingCompany.ppv_package = ppv;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingPPV = false;
        }
    }

    @action
    async loadPPVLog(companyId) {
        this.loadingPPVLog = true;
        this.error = null;
        try {
            let ppvLog = await services.Companies.ppvLog(companyId);
            this.ppvLog.replace(ppvLog);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingPPVLog = false;
        }
    }

    @action
    async loadPPVTransactions(companyId) {
        this.loadingPPVTransactions = true;
        this.error = null;
        try {
            let ppvTransactions = await services.Companies.ppvTransactions(
                companyId
            );
            this.ppvTransactions.replace(ppvTransactions);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingPPVTransactions = false;
        }
    }

    @action
    async newPPVApiKey(companyId) {
        this.savingPPVApiKey = true;
        this.error = null;
        try {
            let ppv = await services.Companies.newPPVApiKey(companyId);
            this.editingCompany.ppv_package = ppv;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingPPVApiKey = false;
        }
    }

    @action
    async uploadLogoAsPartnerAdmin(companyId, formData) {
        this.uploadingLogoAsPartner = true;
        try {
            let result = await services.Resources.addLogo(companyId, formData);
            this.editingCompany = await services.Companies.fetch(companyId);
            if (this.editingCompany) {
                Object.assign(this.editingCompany, result, {
                    logo_name: result.logo_name,
                });
            }
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.uploadingLogoAsPartner = false;
        }
    }

    @action
    async removeLogoAsPartnerAdmin(companyId) {
        try {
            let result = await services.Resources.removeLogo(companyId);
            this.editingCompany = await services.Companies.fetch(companyId);
            if (this.editingCompany) {
                Object.assign(this.editingCompany, result, { logo_name: null });
            }
            //await services.Resources.removeLogo(companyId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    @action
    async loadCompanies() {
        this.loadingCompanies = true;
        this.error = null;
        this.companies.clear();
        try {
            const result = await services.Companies.list();
            this.companies.replace(result);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingCompanies = false;
        }
    }

    @action
    async deleteCompany(partnerId, companyId) {
        this.removingCompanies.push(companyId);
        this.error = null;
        try {
            await services.Partners.companiesService(partnerId).delete(
                companyId
            );
            let existing = this.companies.find((x) => x.id === companyId);
            if (existing) this.companies.remove(existing);
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.removingCompanies.remove(companyId);
        }
    }

    @action
    async loadPartnerCompanies(partnerId) {
        this.loadingPartnerCompanies = true;
        this.error = null;
        this.companies.clear();
        try {
            const result = await services.Partners.companiesService(
                partnerId
            ).list();
            result.forEach(
                (c) => (c.link = `/partner/${partnerId}/companies/${c.id}`)
            );
            this.companies.replace(result);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingPartnerCompanies = false;
        }
    }

    @computed get filteredPartnerCompanies() {
        return this.companies
            .filter(
                (x) =>
                    !(
                        this.companySearchValue.length > 0 &&
                        x.name !== null &&
                        !x.name
                            .toLowerCase()
                            .includes(this.companySearchValue.toLowerCase())
                    )
            )
            .sort(
                (companyA, companyB) =>
                    new Date(companyB.created) - new Date(companyA.created)
            );
    }

    @action
    async loadPartnerSubscriptionsOverview(partnerId) {
        this.loadingPartnerSubscriptionsOverview = true;
        this.error = null;

        try {
            this.partnerSubscriptionsOverview =
                await services.Partners.subscriptionsService(
                    partnerId
                ).overview();
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingPartnerSubscriptionsOverview = false;
        }
    }

    @action
    async collectPartnerPayment(partnerId) {
        this.collectingPayment = true;
        this.error = null;
        try {
            await services.Partners.subscriptionsService(partnerId).collect();
            if (this.this.partnerSubscriptionsOverview)
                this.this.partnerSubscriptionsOverview.collected = true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.collectingPayment = false;
        }
    }

    @action
    async loadPartners() {
        this.loadingPartners = true;
        this.error = null;
        this.partners.clear();
        try {
            const result = await services.Partners.list();
            this.partners.replace(result);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingPartners = false;
        }
    }

    @action
    async loadMailHooks() {
        this.loadingMailHooks = true;
        this.error = null;
        this.mailHooks.clear();
        try {
            const result = await services.System.listHooks();
            this.mailHooks.replace(result);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingMailHooks = false;
        }
    }

    @action
    async createMailHooks() {
        this.loadingMailHooks = true;
        this.error = null;
        this.mailHooks.clear();
        try {
            const result = await services.System.createHooks();
            this.mailHooks.replace(result);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingMailHooks = false;
        }
    }

    @action
    async openManagePartnerSession(partnerId) {
        return services.Partners.openManageSession(partnerId);
    }

    loadQuestions = generateLoadList(
        'questions',
        this,
        'loadingQuestions',
        async (options) => {
            return services.Questions.list(options);
        },
        'questions'
    );

    loadQuestion = generateLoadEntity(
        'questions',
        this,
        'loadingQuestion',
        async (id) => {
            return services.Questions.fetch(id);
        },
        'editingQuestion'
    );

    createQuestion = generateCreateEntity(
        'question',
        this,
        'creatingQuestion',
        async (question) => {
            return services.Questions.create(question);
        }
    );

    updateQuestion = generateUpdateEntity(
        'question',
        this,
        'updatingQuestions',
        async (question) => {
            return services.Questions.update(question, question.id);
        }
    );

    loadTemplates = generateLoadList(
        'templates',
        this,
        'loadingTemplates',
        async (options) => {
            return services.Templates.all(options);
        },
        'templates'
    );

    loadTemplate = generateLoadEntity(
        'templates',
        this,
        'loadingTemplate',
        async (id) => {
            return services.Templates.superGet(id);
        },
        'editingTemplate'
    );

    loadTemplateSubjects = generateLoadList(
        'template_subjects',
        this,
        'loadingTemplateSubjects',
        async (id) => {
            return services.Templates.superGetSubjects(id);
        },
        'editingTemplateSubjects'
    );

    createTemplate = generateCreateEntity(
        'template',
        this,
        'creatingTemplate',
        async (template) => {
            return services.Templates.create(template);
        }
    );

    loadCategories = generateLoadList(
        'categories',
        this,
        'loadingCategories',
        async (options) => {
            return services.Categories.all(options);
        },
        'categories'
    );

    loadCategory = generateLoadEntity(
        'categories',
        this,
        'loadingCategory',
        async (id) => {
            return services.Categories.superGet(id);
        },
        'editingCategory'
    );

    loadCategorySubjects = generateLoadList(
        'category_subjects',
        this,
        'loadingCategorySubjects',
        async (id) => {
            return services.Categories.superGetSubjects(id);
        },
        'editingCategorySubjects'
    );

    loadSubjects = generateLoadList(
        'subjects',
        this,
        'loadingSubjects',
        async (options) => {
            return services.Subjects.all(options);
        },
        'subjects'
    );

    loadSubject = generateLoadEntity(
        'subjects',
        this,
        'loadingSubject',
        async (id) => {
            return services.Subjects.superGet(id);
        },
        'editingSubject'
    );

    loadSubjectLanguages = generateLoadEntity(
        'subjectLanguages',
        this,
        'loadingSubjectLanguages',
        async (id) => {
            return services.Subjects.superGetLanguages(id);
        },
        'editingSubjectLanguages'
    );

    @action
    async editSubjectLanguageContent(subjectId, lang, content) {
        this.error = null;
        try {
            await services.Subjects.updateSubjectLanguageContent(
                subjectId,
                lang,
                content
            );
            this.editingSubjectLanguages.contents[lang].content = content;
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    @action
    async editSubjectLanguageTranslation(subjectId, lang, title, description) {
        this.error = null;
        try {
            await services.Subjects.updateSubjectLanguageTranslation(
                subjectId,
                lang,
                title,
                description
            );
            this.editingSubjectLanguages.subject[lang].title = title;
            this.editingSubjectLanguages.subject[lang].description =
                description;
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    @action
    async editSubjectLanguageQuestion(
        subjectId,
        lang,
        question,
        correct,
        wrong1,
        wrong2,
        wrong3
    ) {
        this.error = null;
        try {
            await services.Subjects.updateSubjectLanguageQuestion(
                subjectId,
                lang,
                question,
                correct,
                wrong1,
                wrong2,
                wrong3
            );
            this.editingSubjectLanguages.question[lang].question = question;
            this.editingSubjectLanguages.question[lang].correct_answer =
                correct;
            this.editingSubjectLanguages.question[lang].wrong_answer1 = wrong1;
            this.editingSubjectLanguages.question[lang].wrong_answer2 = wrong2;
            this.editingSubjectLanguages.question[lang].wrong_answer3 = wrong3;
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    /*editSubjectLanguageContent = generateUpdateEntity( "subjectLanguageContent", this, "updatingSubjectLanguageContent",
async (subjectId, lang, content)=>{
    return services.Subjects.updateSubjectLanguageContent(subjectId, lang, content)
}, )*/

    /*editSubjectLanguageTranslation = generateUpdateEntity( "subjectLanguageTranslation", this, "updatingSubjectLanguageTranslation",
async (subjectId, lang, title, description)=>{
    return services.Subjects.updateSubjectLanguageTranslation(subjectId, lang, title, description)
}, )*/

    /*editSubjectLanguageQuestion = generateUpdateEntity( "subjectLanguageQuestion", this, "updatingSubjectLanguageQuestion",
async (subjectId, lang, question, correct, wrong1, wrong2, wrong3)=>{
    return services.Subjects.updateSubjectLanguageQuestion(subjectId, lang, question, correct, wrong1, wrong2, wrong3)
}, )*/

    removeAclLine = generateDeleteEntity(
        'acl',
        this,
        'removingAclLines',
        (id) => {
            return services.ACL.delete(id);
        }
    );

    createAclLine = generateCreateEntity(
        'acl',
        this,
        'creatingAclLine',
        async (line) => {
            return services.ACL.create(line);
        }
    );

    loadLanguages = generateLoadList(
        'languages',
        this,
        'loadingLanguages',
        async (options) => {
            return services.Languages.all(options);
        },
        'languages'
    );

    loadLanguage = generateLoadEntity(
        'languages',
        this,
        'loadingLanguage',
        async (id) => {
            return services.Languages.fetch(id);
        },
        'editingLanguage'
    );

    @computed get sortedlanguages() {
        return orderBy(this.languages, 'order');
    }

    @computed get languageResources() {
        if (!this.editingLanguage) return [];
        if (!this.editingLanguage.resources) return [];
        let result = [];

        _each(this.editingLanguage.resources, (value, key) => {
            result.push({ key, value });
        });

        return result;
    }

    @computed get emptyLanguageResources() {
        return this.languageResources.filter((x) => !x.value);
    }

    @computed get emptyLanguageSubjects() {
        return this.languageSubjects.filter((x) => !x.title);
    }

    @computed get languageSubjects() {
        if (!this.editingLanguage) return [];
        if (!this.editingLanguage.subjects) return [];
        return this.editingLanguage.subjects;
    }

    isCompanyValid(company) {
        const validTo = company.valid_to;
        return validTo && new Date(validTo) > new Date();
    }

    isCompanyInvalid(company) {
        return !this.isCompanyValid(company);
    }

    @computed get activeCompaniesCount() {
        return this.companies.filter((x) => this.isCompanyValid(x)).length;
    }

    @computed get inactiveCompaniesCount() {
        return this.companies.filter((x) => this.isCompanyInvalid(x)).length;
    }
}

export default SuperStore;
