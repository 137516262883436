import React from 'react';

class NewCourse extends React.Component {
    render() {
        return (
            <div className="block">
                <h3>New course</h3>
                <div className="item course">
                    <div
                        className="image no-hover "
                        style={{
                            backgroundImage:
                                'url(/static/img/subjects/small/ransomware.jpg)',
                        }}
                    ></div>
                    <h5>Ransomware</h5>
                    <p>Ransomware is a serious threat to any organization.</p>
                </div>
            </div>
        );
    }
}

export default NewCourse;
