import { action, observable } from 'mobx';
import services from '../services';

class QuestionsStore {
    @observable loadingData = false;
    @observable error = null;
    @observable question = null;
    @observable questionAnswers = null;

    constructor(companyStore, mainStore) {
        this.companyStore = companyStore;
        this.mainStore = mainStore;
    }

    @action setError = (error) => {
        this.error = error;
    };

    @action
    async loadQuestionPreview(companyId, id, background) {
        if (!background) {
            if (this.loadingData === id) return;
            this.loadingData = id;
        }
        this.setError(null);
        try {
            this.question = await services.Companies.questionsService(
                companyId
            ).fetchPreview(id);
            if (
                !this.mainStore.assessmentEnabled ||
                (this.mainStore.currentCompany.subscription
                    .subscription_definition.assessmentQuestions !== 'all' &&
                    this.mainStore.currentCompany.subscription.subscription_definition.assessmentQuestions.indexOf(
                        id
                    ) < 0)
            ) {
                this.question.disabled = true;
            }
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this.loadingData = null;
        }
    }

    @action
    async loadQuestionReview(companyId, id, assessmentId, background) {
        if (!background) {
            if (this.loadingData === id) return;
            this.loadingData = id;
        }
        this.setError(null);
        try {
            this.question = await services.Companies.assessmentsService(
                companyId
            )
                .questionsService(assessmentId)
                .fetch(id);
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this.loadingData = null;
        }
    }

    @action
    async loadQuestionAnswer(companyId, assessmentId, questionId, background) {
        if (!background) {
            if (this.loadingData === questionId) return;
            this.loadingData = questionId;
        }
        this.setError(null);
        try {
            console.log('in loadQuestionAnswer');
            this.questionAnswers = await services.Companies.questionsService(
                companyId
            ).getAnswers(assessmentId, questionId);
        } catch (e) {
            this.setError(
                (e.response && e.response.data && e.response.data.error) ||
                    e.message
            );
        } finally {
            this.loadingData = null;
        }
    }
}

export default QuestionsStore;
