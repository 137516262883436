import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Waiting from '../../components/waiting';
import { querystring } from '../../utils/helpers';

@inject('authStore')
@observer
class SignIn extends React.Component {
    componentDidMount() {
        let { token } = this.props.match.params;
        this.props.authStore.signinFromEmail(token).then((result) => {
            let redirect = querystring('redirect') || '/';
            if (!redirect.startsWith('/')) redirect = `/${redirect}`;
            this.props.history.push(result ? redirect : '/auth/error/email');
        });
    }

    render() {
        return <Waiting waitingActive={true} fullScreen={true} />;
    }
}

export default withRouter(SignIn);
