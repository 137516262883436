import RestService from './RestService';
import Questions from './QuestionsService';

export default class Assessments extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/assessments`);
        } else super('/assessments');
    }

    loadingRemindersCounter(assessmentId, reminderOption) {
        return this.post(`${this.baseUrl}/${assessmentId}/count-reminders`, {
            reminderOption,
        });
    }

    employeesReportList(assessmentId) {
        return this.get(`${this.baseUrl}/${assessmentId}/employees`);
    }

    questionsReportList(assessmentId) {
        return this.get(`${this.baseUrl}/${assessmentId}/questions`);
    }

    pauseAssessment(assessmentId) {
        return this.post(`${this.baseUrl}/${assessmentId}/pauseAssessment`);
    }

    saveDraft(data) {
        return this.post(`${this.baseUrl}/draft`, data);
    }

    updateDraft(data) {
        return this.put(`${this.baseUrl}/draft/${data.id}`, data);
    }

    sendPreview(data) {
        return this.post(`${this.baseUrl}/sendPreview`, data);
    }

    sendReminders(assessmentId, reminderOption) {
        return this.post(`${this.baseUrl}/${assessmentId}/reminders`, {
            reminderOption,
        });
    }

    questionsService(assessmentId) {
        return new Questions(this, assessmentId);
    }
}
