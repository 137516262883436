import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import FormDef from '../../forms/language';
import LanguageForm from '../../components/super/languageEdit';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

@inject('superStore')
@observer
class LanguageEditSuperContainer extends React.Component {
    state = { error: '', loadedOrError: null };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState) return; // not yet initialized
        if (nextProps.languageId !== prevState.languageId) {
            if (prevState.languageId)
                return {
                    languageChanged: true,
                    loadedOrError: null,
                };
            else {
                return {
                    languageId: nextProps.languageId,
                    loadedOrError: null,
                };
            }
        }

        // No state update necessary
        return null;
    }

    componentDidMount() {
        // It's preferable in most cases to wait until after mounting to load data.
        // See below for a bit more context...
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loadedOrError === null) {
            // At this point, we're in the "commit" phase, so it's safe to load the new data.
            this.loadData();
        }
    }

    constructor(props) {
        super(props);
        this._form = new FormDef(
            {},
            {
                hooks: {
                    onSuccess: this.onSuccess,
                },
            }
        );
    }

    async loadData() {
        if (this.state.languageId) {
            await this.props.superStore.loadLanguage(this.state.languageId);
            this._form.update(
                this.props.superStore.editingLanguage
                    ? { ...this.props.superStore.editingLanguage }
                    : {}
            );
        }
    }

    onSuccess = async () => {
        console.log('saving');
        let result = await this.props.superStore.updateLanguage(
            this.props.languageId,
            this._form.values()
        );
        if (result) {
            if (this.props.languageId)
                return this.props.history.push(
                    `/super/languages/${this.props.languageId}`
                );
            else return this.props.history.push('/super/languages');
        }
    };

    render() {
        const { savingLanguage, languageError } = this.props.superStore;

        return (
            <Fragment>
                <Link to={`/super/languages`}>
                    <div className={'navigation back-button'}>
                        Back to Languages
                    </div>
                </Link>
                <div className="steps">
                    {
                        <Fragment>
                            <LanguageForm form={this._form} />
                            {savingLanguage ? (
                                <ThemedSpinner size={45} />
                            ) : (
                                <div
                                    className="btn btn-primary"
                                    onClick={(e) => this._form.onSubmit(e)}
                                >
                                    Save
                                </div>
                            )}
                        </Fragment>
                    }
                    <ErrorMessage error={languageError} />
                </div>
            </Fragment>
        );
    }
}

export default withRouter(LanguageEditSuperContainer);
