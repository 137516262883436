/* eslint-disable no-undef */
import { observable, action, computed, isObservableArray } from 'mobx';
import services from '../services';
import { awareGoTheme as styledComponentsThemeFile } from '../theme';
import { AwareGOTheme as muiThemeFile } from '../theme-mui';
import { reaction } from 'mobx/lib/mobx';
import { backOffDelay } from '../utils/helpers';
import defaults from 'lodash/defaults';

const { REACT_APP_MY_URL: myUrl } = process.env;
const pattern = /^((http|https):\/\/)/;

class BrandingStore {
    @observable brandingLoaded;
    @observable branding;

    grayColor = '#B4B4B4'; //Used for 'irrelevant' answers
    redColor = '#EB5757';
    orangeColor = '#FF8440';
    yellowColor = '#F2C94C';
    lightgreenColor = '#8CBD00';
    greenColor = '#20A26B';

    constructor(mainStore, companyStore) {
        this.mainStore = mainStore;
        this.companyStore = companyStore;

        reaction(
            () => this.mainStore.currentCompany,
            (company) => {
                if (company) {
                    this.loadBranding(company.company_id);
                } else this.loadBranding();
            }
        );
        reaction(
            () => this.mainStore.serverData,
            (data) => {
                if (data && data.length === 0) {
                    this.loadBranding();
                }
            }
        );
    }

    @action
    async loadBranding(companyId, counter = 0) {
        if (counter === 0 && this.reloadTimeout) {
            // new request for branding until loading previous one
            try {
                clearTimeout(this.reloadTimeout);
            } catch (e) {}
            this.reloadTimeout = null;
        }
        this.brandingLoaded = false;
        try {
            let result = companyId
                ? await services.Companies.BrandingService(companyId).list()
                : await services.Branding.list();
            this.branding = result;
            this.brandingLoaded = true;
        } catch (e) {
            if (counter < 10)
                this.reloadTimeout = setTimeout(async () => {
                    await this.loadBranding(companyId, counter + 1);
                }, backOffDelay(1500, counter));
        }
    }

    @computed get logo() {
        let logoPath = '/static/img/logo_simple.png';

        if (
            this.companyStore.currentCompany &&
            this.companyStore.currentCompany.logo_name
        ) {
            logoPath =
                process.env.REACT_APP_RESOURCES_URL +
                '/' +
                process.env.REACT_APP_COMPANY_RESOURCES_DIR +
                '/' +
                this.companyStore.currentCompany.logo_name;
        } else if (this.branding && this.branding['ui.logo']) {
            logoPath = this.branding['ui.logo'];
        }
        return logoPath;
    }

    @computed get favicon() {
        if (!this.brandingLoaded) return null;
        return (
            (this.branding && this.branding['ui.favicon']) ||
            'favicon_generic.ico'
        );
    }

    @computed get ad_enabled() {
        if (!this.brandingLoaded) return null;
        return (this.branding && this.branding['ui.ad_enabled']) || false;
    }

    @computed get name() {
        if (!this.brandingLoaded) return null;
        return (this.branding && this.branding['name']) || 'AwareGO';
    }

    @computed get account_name() {
        if (!this.brandingLoaded) return null;
        return (this.branding && this.branding['account_name']) || 'AwareGO';
    }

    @computed get helpUrl() {
        const defaultHelpUrl = 'https://help.awarego.com/';
        if (!this.brandingLoaded) return defaultHelpUrl;
        return (
            (this.branding && this.branding['ui.help_url']) || defaultHelpUrl
        );
    }
    @computed get resourcesUrl() {
        const defaultResourcesUrl = '/resources';
        if (!this.brandingLoaded) return defaultResourcesUrl;
        return (
            (this.branding && this.branding['ui.resources_url']) ||
            defaultResourcesUrl
        );
    }

    @computed get termsUrl() {
        const defaultTermsUrl = 'https://www.awarego.com/terms/';
        if (!this.brandingLoaded) return defaultTermsUrl;
        return (
            (this.branding && this.branding['ui.terms_url']) || defaultTermsUrl
        );
    }

    @computed get privacyUrl() {
        const defaultPrivacyUrl = 'https://www.awarego.com/privacy';
        if (!this.brandingLoaded) return defaultPrivacyUrl;
        return (
            (this.branding && this.branding['ui.privacy_url']) ||
            defaultPrivacyUrl
        );
    }

    @computed get checkEmailUrl() {
        const defaultTermsUrl =
            'https://www.awarego.com/how-to-whitelist-emails/';
        if (!this.brandingLoaded) return defaultTermsUrl;
        return (
            (this.branding && this.branding['ui.check_email_url']) ||
            defaultTermsUrl
        );
    }

    @computed get supportEmail() {
        if (!this.brandingLoaded) return null;
        return (
            (this.branding && this.branding['contact.support']) ||
            'support@awarego.com'
        );
    }

    @computed get lmsDomain() {
        const defaultLmsDomain = 'lms.awarego.com';
        if (!this.brandingLoaded) return defaultLmsDomain;
        if (this.branding && this.branding['lms_domain']) {
            if (
                Array.isArray(this.branding['lms_domain']) ||
                isObservableArray(this.branding['lms_domain'])
            )
                return this.branding['lms_domain'][0];
            return this.branding['lms_domain'];
        }

        return defaultLmsDomain;
    }

    @computed get myDomain() {
        if (!this.brandingLoaded) return myUrl;
        if (this.branding && this.branding['my_domain']) {
            let url = this.branding['my_domain'];
            if (!pattern.test(url)) {
                url = 'https://' + url;
            }
            return url;
        }
        return myUrl;
    }

    @computed get theme() {
        let perPartner = {};

        if (this.brandingLoaded) {
            if (this.branding['ui.colors.primary'])
                perPartner.primary = this.branding['ui.colors.primary'];
            if (this.branding['ui.colors.secondary'])
                perPartner.secondary = this.branding['ui.colors.secondary'];
        }

        let theme = defaults({}, perPartner, styledComponentsThemeFile);
        return theme;
    }

    @computed get muiTheme() {
        const theme = muiThemeFile;

        if (this.brandingLoaded) {
            if (this.branding['ui.colors.primary'])
                theme.palette.primary = theme.palette.augmentColor({
                    main: this.branding['ui.colors.primary'],
                });
            if (this.branding['ui.colors.secondary'])
                theme.palette.secondary = theme.palette.augmentColor({
                    main: this.branding['ui.colors.secondary'],
                });
        }

        return theme;
    }

    getScoreDefinition(percentage) {
        let roundedPercentage = Math.floor(percentage);
        if (this.brandingLoaded && this.branding.assessmentScoreRanges) {
            let def = this.branding.assessmentScoreRanges.company.find(
                (x) =>
                    roundedPercentage >= x.from_score &&
                    roundedPercentage <= x.to_score
            );
            if (!def)
                def = this.branding.assessmentScoreRanges.global.find(
                    (x) =>
                        roundedPercentage >= x.from_score &&
                        roundedPercentage <= x.to_score
                );

            if (def && !def.color) {
                switch (def.category) {
                    case 'error':
                        def.color = this.redColor;
                        break;
                    case 'warn':
                        def.color = this.yellowColor;
                        break;
                    case 'info':
                    case 'success':
                    default:
                        def.color = this.greenColor;
                }
            }
            return def;
        }
    }
}

export default BrandingStore;
