import React, { Fragment, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Link, useHistory } from 'react-router-dom';
import {
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    TabContent,
    TabPane,
    Popover,
    PopoverBody,
} from 'reactstrap';
import ErrorMessage from '../../components/errormessage';
import moment from 'moment';
import pluralize from 'pluralize';
import SubjectsProgress from '../../components/manager/automation/SubjectsProgress';
import ListsProgress from '../../components/manager/automation/ListsProgress';
import UserDetails from '../../components/manager/users/userDetailsWithProgressBar';
import {
    defaultZero,
    formatDate,
    calculateColor,
    trainingName,
} from '../../utils/helpers';
import Modal from 'react-modal';
import ModalReminders from './modals/ModalReminders';
import Snackbar from '../../components/Snackbar';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import {
    ThemedLink,
    ThemedDropdownItem,
    ThemedNav,
    ThemedNavItem,
    ThemedNavLink,
} from '../../components/themed/ThemedComponents';
import { useStores } from '../../hooks/use-stores';
import InlineProgressBar from '../../components/InlineProgressBar';
import { Heading2, Heading1 } from '../../components/design-system/headings';
import {
    Block,
    FlexDiv,
    SpaceBetweenDiv,
} from '../../components/design-system/containers';
import { TextTiny } from '../../components/design-system/texts';
import { STATUS } from '../../utils/constants';
import StatusBadge from '../../components/StatusBadge';

function AutomationView({ companyId, automationId, parentMatch }) {
    const { automationStore, userStore, authStore, store, commonStore } =
        useStores();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [lastTrainingsOpen, setLastTrainingsOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('1');
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [popoverAttendance, setPopoverAttendance] = useState(false);
    const [remindersModal, setRemindersModal] = useState(false);
    const [message, setMessage] = useState(null);
    const [messageShown, setMessageShown] = useState(false);
    const [messageType, setMessageType] = useState(null);
    let history = useHistory();

    const doLoadData = async () => {
        const companyChanged = await automationStore.loadAutomation(
            companyId,
            automationId
        );
        if (companyChanged) {
            return history.push('/trainings');
        }
    };

    useEffect(() => {
        doLoadData();
    }, [companyId, automationId]);

    const loadUsersDetails = () => {
        if (automationStore.perUserDetailsLoaded)
            automationStore.perUserDetailsLoaded = false;
        else automationStore.loadUsersDetails(companyId, automationId);
    };

    const doDelete = async () => {
        if (await automationStore.delete(companyId, automationId))
            return history.push(
                (parentMatch && parentMatch.url) || '/trainings'
            );
    };

    const onDelete = () => {
        commonStore.showConfirm(
            'Are you sure you want to delete this training?',
            'Delete',
            'Delete Training',
            async () => {
                await doDelete();
            },
            true
        );
    };

    const doDeleteUserFromCompany = async (email, lists) => {
        await userStore.delete(companyId, lists, email);
    };

    const onDeleteUser = async (user) => {
        let lists = user.lists_ids.split(',');
        commonStore.showConfirm(
            `By doing this user '${
                user.email
            }' will be removed from ${pluralize('list', lists.length)}: ${
                user.lists_names
            }  and from  all training programs associated with this lists?`,
            'Delete',
            'Delete User',
            () => {
                doDeleteUserFromCompany(user.email, lists);
            },
            true,
            true
        );
    };

    // const onCopy = () => {
    //     return history.push( `/automations/create/copy/${automationId}`)
    // }

    const pauseAutomation = async () => {
        await automationStore.pauseAutomation(companyId, automationId);
    };

    const toggleExtraActions = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const toggleLastTrainings = () => {
        setLastTrainingsOpen(!lastTrainingsOpen);
    };

    const toggleHelp = (item) => {
        if (item === 'avgscore') {
            setPopoverOpen(!popoverOpen);
        }
        if (item === 'attendance') {
            setPopoverAttendance(!popoverAttendance);
        }
    };

    const tabsChange = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const showRemindersModal = () => {
        setRemindersModal(true);
    };

    const hideRemindersModal = () => {
        setRemindersModal(false);
    };

    const loadRemindersCount = async (reminderOption) => {
        automationStore.loadRemindersCounter(
            companyId,
            automationId,
            reminderOption
        );
    };

    const sendReminders = async (reminderOption) => {
        let result = await automationStore.sendReminders(
            companyId,
            automationId,
            reminderOption
        );
        if (result) {
            showSnackBar('Reminders sent!');
        }
        hideRemindersModal();
    };

    const showSnackBar = (messageParam, messageTypeParam = 'success') => {
        setMessage(messageParam);
        setMessageShown(true);
        setMessageType(messageTypeParam);
    };

    const closeSnackbar = () => {
        setMessage(null);
        setMessageShown(false);
        setMessageType(null);
    };

    const {
        editingAutomation,
        loadingAutomation,
        perUserDetailsIndex,
        lastReminders,
        reminderCounter,
        loadingReminderCounter,
        error,
    } = automationStore;

    const { token } = authStore;

    let weeksInTotal,
        attendance,
        score,
        reportUrl,
        reportUsersUrl,
        signInLinksUrl,
        numAutomations,
        usersOverviewReportUrl,
        detailedUsersReportUrl,
        minutesInTotal;
    if (editingAutomation) {
        weeksInTotal =
            editingAutomation &&
            moment(
                editingAutomation._rawData.events[
                    editingAutomation._rawData.events.length - 1
                ].send
            ).diff(moment(editingAutomation._rawData.events[0].send), 'weeks');
        minutesInTotal = Math.round(
            moment
                .duration(editingAutomation.videosSelectedDuration, 'seconds')
                .asMinutes()
        );
        attendance =
            editingAutomation &&
            (100 * editingAutomation._rawData.opened) /
                editingAutomation._rawData.totalUsers;
        score =
            editingAutomation &&
            (100 * editingAutomation._rawData.score) /
                editingAutomation._rawData.total;
        reportUrl = `${process.env.REACT_APP_API_URL}/companies/${companyId}/trainings/${editingAutomation.id}/report?token=${token}`;
        reportUsersUrl = `${process.env.REACT_APP_API_URL}/companies/${companyId}/trainings/${editingAutomation.id}/report?users_details=1&token=${token}`;
        usersOverviewReportUrl = `${process.env.REACT_APP_API_URL}/companies/${companyId}/trainings/${editingAutomation.id}/users_report/csv?token=${token}`;
        detailedUsersReportUrl = `${process.env.REACT_APP_API_URL}/companies/${companyId}/trainings/${editingAutomation.id}/users_detailed_report/csv?token=${token}`;
        signInLinksUrl = `${process.env.REACT_APP_API_URL}/companies/${companyId}/trainings/${editingAutomation.id}/users/csv?token=${token}`;
        numAutomations = editingAutomation._rawData.lastTrainings.length;
    }

    const { anonymousTracking } = authStore;
    const ssoEnabled =
        store.currentCompany &&
        store.currentCompany.settings['ui.sso.configured'];

    const displayPdfReport =
        editingAutomation && editingAutomation._rawData.totalUsers < 1000;

    const now = new Date();

    ////////////////////////////////////////////////////
    // State of trainings, defined here for cleaner code

    const isScheduledTraining =
        editingAutomation && editingAutomation._rawData.scheduleType === 0;

    const isScheduledTrainingWithSubjects =
        isScheduledTraining &&
        editingAutomation._rawData.subjects &&
        editingAutomation._rawData.subjects[0];

    /* Scheduled training that hasn't started*/
    const isNotStartedScheduledTraining =
        isScheduledTrainingWithSubjects &&
        new Date(editingAutomation._rawData.subjects[0].send) > now;

    /* Scheduled training that has started*/
    const isStartedScheduledTraining =
        isScheduledTrainingWithSubjects &&
        new Date(editingAutomation._rawData.subjects[0].send) < now;

    /* Scheduled training with an upcoming lesson */
    const isOngoingScheduledTraining =
        isStartedScheduledTraining &&
        editingAutomation._rawData.active &&
        editingAutomation._rawData.nextSubjectDate;

    /* Scheduled training with no upcoming lessons */
    const isFinishedScheduledTraining =
        isScheduledTraining &&
        editingAutomation._rawData.active &&
        !editingAutomation._rawData.nextSubjectDate;

    const isSendAllTraining =
        editingAutomation && editingAutomation._rawData.scheduleType === 1;

    /* 'Send everything now' training that has finished sending everything */
    const isDoneSendAllTraining =
        isSendAllTraining && editingAutomation._rawData.minutesSent;

    /* 'Send everything now' training that is still in the progress of sending everything */
    const isSendingSendAllTraining =
        isSendAllTraining && !editingAutomation._rawData.minutesSent;

    return (
        <Fragment>
            <Link to={`/trainings/`}>
                <div className={'navigation back-button'}>
                    Back to training overview
                </div>
            </Link>
            <ErrorMessage error={error} />
            {loadingAutomation ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    {editingAutomation ? (
                        <Fragment>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                autoHideDuration={5000}
                                variant={messageType}
                                message={message || ''}
                                open={messageShown}
                                onClose={closeSnackbar}
                            />
                            <Block>
                                <SpaceBetweenDiv>
                                    <FlexDiv column>
                                        <FlexDiv alignCenter mb={8}>
                                            <Heading1>
                                                {trainingName(
                                                    editingAutomation.name,
                                                    editingAutomation._rawData
                                                        .created
                                                )}
                                            </Heading1>
                                            <FlexDiv ml={4} mr={8} link>
                                                {numAutomations > 1 && (
                                                    <Dropdown
                                                        isOpen={
                                                            lastTrainingsOpen
                                                        }
                                                        toggle={
                                                            toggleLastTrainings
                                                        }
                                                    >
                                                        <DropdownToggle
                                                            caret
                                                            tag="span"
                                                        />
                                                        <DropdownMenu>
                                                            {editingAutomation._rawData.lastTrainings.map(
                                                                (x, i) => {
                                                                    if (
                                                                        x.id !==
                                                                        editingAutomation.id
                                                                    )
                                                                        return (
                                                                            <ThemedDropdownItem
                                                                                key={`${i}-${x.id}`}
                                                                            >
                                                                                <Link
                                                                                    to={`/trainings/${x.id}/view`}
                                                                                >
                                                                                    {
                                                                                        x.name
                                                                                    }
                                                                                </Link>
                                                                            </ThemedDropdownItem>
                                                                        );
                                                                    return (
                                                                        <ThemedDropdownItem
                                                                            key={`${i}-${x.id}`}
                                                                        >
                                                                            {
                                                                                x.name
                                                                            }
                                                                        </ThemedDropdownItem>
                                                                    );
                                                                }
                                                            )}
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                )}
                                            </FlexDiv>
                                            <StatusBadge
                                                color={
                                                    editingAutomation.statusInfo
                                                        .color
                                                }
                                                text={
                                                    editingAutomation.statusInfo
                                                        .label
                                                }
                                            />
                                        </FlexDiv>
                                        {editingAutomation.status !==
                                        STATUS.DRAFT ? (
                                            <TextTiny lighter>
                                                {isScheduledTraining &&
                                                minutesInTotal > 0 ? (
                                                    <>
                                                        {minutesInTotal}{' '}
                                                        {pluralize(
                                                            'minute',
                                                            minutesInTotal
                                                        )}{' '}
                                                        {isNotStartedScheduledTraining &&
                                                            'to be '}
                                                        delivered
                                                        {weeksInTotal > 0 &&
                                                            ' over ' +
                                                                weeksInTotal +
                                                                ' ' +
                                                                pluralize(
                                                                    'week',
                                                                    weeksInTotal
                                                                )}{' '}
                                                        ·{' '}
                                                    </>
                                                ) : null}
                                                {isNotStartedScheduledTraining && (
                                                    <>
                                                        Training starts on{' '}
                                                        {moment(
                                                            editingAutomation
                                                                ._rawData
                                                                .subjects[0]
                                                                .send
                                                        ).format(
                                                            'LL [at] HH:mm [GMT]'
                                                        )}
                                                    </>
                                                )}
                                                {isStartedScheduledTraining && (
                                                    <>
                                                        Training started on{' '}
                                                        {moment(
                                                            editingAutomation
                                                                ._rawData
                                                                .subjects[0]
                                                                .send
                                                        ).format('LL')}
                                                    </>
                                                )}
                                                {isOngoingScheduledTraining && (
                                                    <>
                                                        {' '}
                                                        · Next lesson on{' '}
                                                        {formatDate(
                                                            editingAutomation
                                                                ._rawData
                                                                .nextSubjectDate
                                                        )}
                                                    </>
                                                )}
                                                {isFinishedScheduledTraining && (
                                                    <>
                                                        {' '}
                                                        · All lessons
                                                        successfully sent.
                                                    </>
                                                )}
                                                {isDoneSendAllTraining && (
                                                    <>
                                                        Delivered immediately ·
                                                        All lessons successfully
                                                        sent.
                                                    </>
                                                )}
                                                {isSendingSendAllTraining && (
                                                    <>
                                                        Delivering all lessons
                                                        right now.
                                                    </>
                                                )}
                                                {!editingAutomation._rawData
                                                    .active &&
                                                    editingAutomation._rawData
                                                        .finished && (
                                                        <>
                                                            {' '}
                                                            · Finished on{' '}
                                                            {moment(
                                                                editingAutomation.finished
                                                            ).format('LL')}
                                                        </>
                                                    )}
                                            </TextTiny>
                                        ) : null}
                                        <TextTiny lighter mt={4}>
                                            Created{' '}
                                            {moment(
                                                editingAutomation._rawData
                                                    .created
                                            ).fromNow()}
                                        </TextTiny>
                                    </FlexDiv>
                                    <Dropdown
                                        isOpen={dropdownOpen}
                                        toggle={toggleExtraActions}
                                    >
                                        <DropdownToggle
                                            onClick={() => toggleExtraActions}
                                            className="btn btn-three-dots"
                                        >
                                            {''}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {editingAutomation.canRemind && (
                                                <ThemedDropdownItem
                                                    onClick={showRemindersModal}
                                                >
                                                    Send reminders
                                                </ThemedDropdownItem>
                                            )}
                                            <ThemedDropdownItem>
                                                <a
                                                    href={reportUrl}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    Report (.pdf)
                                                </a>
                                            </ThemedDropdownItem>
                                            {displayPdfReport && (
                                                <ThemedDropdownItem>
                                                    <a
                                                        href={reportUsersUrl}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    >
                                                        Employee report (.pdf)
                                                    </a>
                                                </ThemedDropdownItem>
                                            )}
                                            <ThemedDropdownItem>
                                                <a
                                                    href={
                                                        usersOverviewReportUrl
                                                    }
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    Employee report (.csv)
                                                </a>
                                            </ThemedDropdownItem>
                                            <ThemedDropdownItem>
                                                <a
                                                    href={
                                                        detailedUsersReportUrl
                                                    }
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    Detailed employee report
                                                    (.csv)
                                                </a>
                                            </ThemedDropdownItem>
                                            <ThemedDropdownItem>
                                                <a
                                                    href={signInLinksUrl}
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                >
                                                    Sign in links (.csv)
                                                </a>
                                            </ThemedDropdownItem>
                                            {editingAutomation.canCopy && (
                                                <ThemedDropdownItem>
                                                    <Link
                                                        to={`/trainings/create/copy/${editingAutomation.id}`}
                                                    >
                                                        Copy training
                                                    </Link>
                                                </ThemedDropdownItem>
                                            )}
                                            {editingAutomation.canStop && (
                                                <ThemedDropdownItem
                                                    onClick={pauseAutomation}
                                                >
                                                    Stop training
                                                </ThemedDropdownItem>
                                            )}
                                            {editingAutomation.canDelete && (
                                                <ThemedDropdownItem
                                                    onClick={onDelete}
                                                >
                                                    Delete training
                                                </ThemedDropdownItem>
                                            )}
                                        </DropdownMenu>
                                    </Dropdown>
                                </SpaceBetweenDiv>
                            </Block>
                            <Row>
                                <Col>
                                    <Block>
                                        <Heading2>
                                            Average score{' '}
                                            <span
                                                className="help-questionmark"
                                                id={'Popover1'}
                                                onClick={() => {
                                                    toggleHelp('avgscore');
                                                }}
                                            ></span>
                                        </Heading2>

                                        <Popover
                                            placement="bottom"
                                            isOpen={popoverOpen}
                                            target={'Popover1'}
                                            toggle={toggleHelp}
                                        >
                                            <PopoverBody>
                                                Scores get improved once your
                                                employees watch the assigned
                                                training videos and successfully
                                                complete the training quizzes.
                                            </PopoverBody>
                                        </Popover>
                                        <p className="mtb20">
                                            Average score is{' '}
                                            <strong>
                                                {defaultZero(score).toFixed(0)}
                                                /100
                                            </strong>{' '}
                                            for this training program.
                                        </p>
                                        <div className={'btn-top-right'}>
                                            <ThemedLink
                                                onClick={showRemindersModal}
                                            >
                                                Send reminders
                                            </ThemedLink>
                                        </div>
                                        <FlexDiv noWrap>
                                            <FlexDiv fullWidth alignCenter>
                                                <InlineProgressBar
                                                    customcolor={calculateColor(
                                                        score
                                                    )}
                                                    value={score}
                                                    fill="true"
                                                />
                                            </FlexDiv>

                                            <FlexDiv flexGrow={1}>
                                                <Heading2
                                                    color={calculateColor(
                                                        score
                                                    )}
                                                >
                                                    {defaultZero(score).toFixed(
                                                        0
                                                    )}
                                                    /100
                                                </Heading2>
                                            </FlexDiv>
                                        </FlexDiv>
                                    </Block>
                                </Col>
                                <Col>
                                    <Block>
                                        <Heading2>
                                            Attendance{' '}
                                            <span
                                                className="help-questionmark"
                                                id={'Popover2'}
                                                onClick={() => {
                                                    toggleHelp('attendance');
                                                }}
                                            ></span>
                                        </Heading2>

                                        <Popover
                                            placement="bottom"
                                            isOpen={popoverAttendance}
                                            target={'Popover2'}
                                            toggle={toggleHelp}
                                        >
                                            <PopoverBody>
                                                This is the number of everyone
                                                who has logged in, regardless if
                                                they finished a lesson.
                                            </PopoverBody>
                                        </Popover>

                                        <p className="mtb20">
                                            <strong>
                                                {editingAutomation._rawData
                                                    .opened || '0'}{' '}
                                                employees logged in
                                            </strong>{' '}
                                            out of{' '}
                                            {
                                                editingAutomation._rawData
                                                    .totalUsers
                                            }
                                        </p>
                                        <FlexDiv noWrap>
                                            <FlexDiv fullWidth alignCenter>
                                                <InlineProgressBar
                                                    customcolor={calculateColor(
                                                        attendance
                                                    )}
                                                    value={attendance}
                                                    fill="true"
                                                />
                                            </FlexDiv>

                                            <FlexDiv flexGrow={1}>
                                                <Heading2
                                                    color={calculateColor(
                                                        attendance
                                                    )}
                                                >
                                                    {editingAutomation._rawData
                                                        .opened || '0'}
                                                    /
                                                    {
                                                        editingAutomation
                                                            ._rawData.totalUsers
                                                    }
                                                </Heading2>
                                            </FlexDiv>
                                        </FlexDiv>
                                    </Block>
                                </Col>
                            </Row>
                            <div className="block">
                                <ThemedNav training>
                                    <ThemedNavItem active={activeTab === '1'}>
                                        <ThemedNavLink
                                            onClick={() => {
                                                tabsChange('1');
                                            }}
                                        >
                                            Lessons
                                        </ThemedNavLink>
                                    </ThemedNavItem>
                                    <ThemedNavItem active={activeTab === '2'}>
                                        <ThemedNavLink
                                            onClick={() => {
                                                tabsChange('2');
                                            }}
                                        >
                                            Lists
                                        </ThemedNavLink>
                                    </ThemedNavItem>
                                    <ThemedNavItem active={activeTab === '3'}>
                                        <ThemedNavLink
                                            onClick={() => {
                                                tabsChange('3');
                                            }}
                                        >
                                            Employees
                                        </ThemedNavLink>
                                    </ThemedNavItem>
                                </ThemedNav>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <SubjectsProgress
                                            training={
                                                editingAutomation._rawData
                                            }
                                            subjects={
                                                editingAutomation._rawData
                                                    .subjects
                                            }
                                            reminders={
                                                editingAutomation._rawData
                                                    .reminders_steps
                                            }
                                            userCount={
                                                editingAutomation._rawData
                                                    .totalUsers
                                            }
                                        />
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <ListsProgress
                                            lists={
                                                editingAutomation._rawData.lists
                                            }
                                        />
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <UserDetails
                                            onDeleteUser={onDeleteUser}
                                            onToggleDetails={loadUsersDetails}
                                            perUserDetails={perUserDetailsIndex}
                                            reportUsersUrl={reportUsersUrl}
                                            ssoEnabled={ssoEnabled}
                                            perUserDetailsCsvUrl={
                                                usersOverviewReportUrl
                                            }
                                            perUserDetailedDetailsCsvUrl={
                                                detailedUsersReportUrl
                                            }
                                            anonymousTracking={
                                                anonymousTracking
                                            }
                                            users={
                                                editingAutomation._rawData.users
                                            }
                                            training={editingAutomation}
                                            detailsRootUrl={`/trainings/${editingAutomation.id}/user/`}
                                        />
                                    </TabPane>
                                </TabContent>
                            </div>
                            <Modal
                                isOpen={remindersModal}
                                onRequestClose={hideRemindersModal}
                                className="Modal schedule"
                            >
                                <ModalReminders
                                    target={editingAutomation}
                                    lastReminders={lastReminders}
                                    reminderCounter={reminderCounter}
                                    loadingReminderCounter={
                                        loadingReminderCounter
                                    }
                                    onLoadRemindersCount={loadRemindersCount}
                                    onRequestClose={hideRemindersModal}
                                    onSendReminders={sendReminders}
                                />
                            </Modal>
                        </Fragment>
                    ) : (
                        <div> Automation Not Found</div>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
}

export default observer(AutomationView);
