import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { Row, Col, Input, Button, Label, Form, FormGroup } from 'reactstrap';
import RichTextEditor from 'react-rte';

@inject('superStore')
@observer
class SubjectLanguage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.content
                ? RichTextEditor.createValueFromString(
                      props.content.content,
                      'html'
                  )
                : RichTextEditor.createEmptyValue(),
            translationTitle: props.subject ? props.subject.title : '',
            translationDescription: props.subject
                ? props.subject.description
                : '',
            questionText: props.question ? props.question.question : '',
            questionAnswer: props.question ? props.question.correct_answer : '',
            questionWrong1: props.question ? props.question.wrong_answer1 : '',
            questionWrong2: props.question ? props.question.wrong_answer2 : '',
            questionWrong3: props.question ? props.question.wrong_answer3 : '',
        };

        this.onChangeTranslationTitle =
            this.onChangeTranslationTitle.bind(this);
        this.onChangeTranslationDescription =
            this.onChangeTranslationDescription.bind(this);
        this.onChangeQuestionText = this.onChangeQuestionText.bind(this);
        this.onChangeQuestionAnswer = this.onChangeQuestionAnswer.bind(this);
        this.onChangeQuestionWrong1 = this.onChangeQuestionWrong1.bind(this);
        this.onChangeQuestionWrong2 = this.onChangeQuestionWrong2.bind(this);
        this.onChangeQuestionWrong3 = this.onChangeQuestionWrong3.bind(this);
    }

    state = {
        value: RichTextEditor.createEmptyValue(),
        translationEdited: false,
        translationTitle: '',
        translationDescription: '',
        questionEdited: false,
        questionText: '',
        questionAnswer: '',
        questionWrong1: '',
        questionWrong2: '',
        questionWrong3: '',
        contentEdited: false,
    };

    getValue = () => {
        return this.state.value.toString('html');
    };

    onChangeTranslationTitle(event) {
        this.setState({
            translationEdited: true,
            translationTitle: event.target.value,
        });
    }

    onChangeTranslationDescription(event) {
        this.setState({
            translationEdited: true,
            translationDescription: event.target.value,
        });
    }

    onChangeQuestionText(event) {
        this.setState({
            questionEdited: true,
            questionText: event.target.value,
        });
    }

    onChangeQuestionAnswer(event) {
        this.setState({
            questionEdited: true,
            questionAnswer: event.target.value,
        });
    }

    onChangeQuestionWrong1(event) {
        this.setState({
            questionEdited: true,
            questionWrong1: event.target.value,
        });
    }

    onChangeQuestionWrong2(event) {
        this.setState({
            questionEdited: true,
            questionWrong2: event.target.value,
        });
    }

    onChangeQuestionWrong3(event) {
        this.setState({
            questionEdited: true,
            questionWrong3: event.target.value,
        });
    }

    onChange = (value) => {
        this.setState({
            contentEdited: true,
            value: value,
        });
    };

    onSave = async (value) => {
        if (this.state.translationEdited) {
            await this.props.superStore.editSubjectLanguageTranslation(
                this.props.subject.subject_id,
                this.props.subject.language,
                this.state.translationTitle,
                this.state.translationDescription
            );
            this.setState({ translationEdited: false });
        }

        if (this.state.questionEdited) {
            console.log('answer:', this.state.questionAnswer);
            await this.props.superStore.editSubjectLanguageQuestion(
                this.props.subject.subject_id,
                this.props.subject.language,
                this.state.questionText,
                this.state.questionAnswer,
                this.state.questionWrong1,
                this.state.questionWrong2,
                this.state.questionWrong3
            );
            this.setState({ questionEdited: false });
        }

        if (this.state.contentEdited) {
            await this.props.superStore.editSubjectLanguageContent(
                this.props.subject.subject_id,
                this.props.subject.language,
                this.state.value.toString('html')
            );
            this.setState({ contentEdited: false });
        }
    };

    render() {
        const { subject } = this.props;

        return !subject ? (
            <ThemedSpinner />
        ) : (
            <Fragment>
                <div style={{ padding: '5px' }}>
                    <div>
                        <Form>
                            <div className="block">
                                <Row>
                                    <FormGroup className="col-12 col-md-12">
                                        <Label for="subjectTitle">Title</Label>
                                        <Input
                                            type="text"
                                            name="subjectTitle"
                                            id="subjectTitle"
                                            value={this.state.translationTitle}
                                            onChange={
                                                this.onChangeTranslationTitle
                                            }
                                        />
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup className="col-12 col-md-12">
                                        <Label for="description">
                                            Description
                                        </Label>
                                        <Input
                                            type="textarea"
                                            name="description"
                                            id="description"
                                            value={
                                                this.state
                                                    .translationDescription
                                            }
                                            onChange={
                                                this
                                                    .onChangeTranslationDescription
                                            }
                                        />
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup className="col-12 col-md-12">
                                        <Label for="question">Question</Label>
                                        <Input
                                            type="text"
                                            name="question"
                                            id="question"
                                            value={this.state.questionText}
                                            onChange={this.onChangeQuestionText}
                                        />
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup className="col-12 col-md-12">
                                        <Label for="answer">Answer</Label>
                                        <Input
                                            type="text"
                                            name="title"
                                            id="title"
                                            value={this.state.questionAnswer}
                                            onChange={
                                                this.onChangeQuestionAnswer
                                            }
                                        />
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup className="col-12 col-md-12">
                                        <Label for="wrong1">Wrong1</Label>
                                        <Input
                                            type="text"
                                            name="wrong1"
                                            id="wrong1"
                                            value={this.state.questionWrong1}
                                            onChange={
                                                this.onChangeQuestionWrong1
                                            }
                                        />
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup className="col-12 col-md-12">
                                        <Label for="wrong2">Wrong2</Label>
                                        <Input
                                            type="text"
                                            name="wrong2"
                                            id="wrong2"
                                            value={this.state.questionWrong2}
                                            onChange={
                                                this.onChangeQuestionWrong2
                                            }
                                        />
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup className="col-12 col-md-12">
                                        <Label for="wrong3">Wrong3</Label>
                                        <Input
                                            type="text"
                                            name="wrong3"
                                            id="wrong3"
                                            value={this.state.questionWrong3}
                                            onChange={
                                                this.onChangeQuestionWrong3
                                            }
                                        />
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <FormGroup className="col-12 col-md-12">
                                        <Label for="content">Content</Label>
                                        <RichTextEditor
                                            value={this.state.value}
                                            onChange={this.onChange}
                                        />
                                    </FormGroup>
                                </Row>
                                <Row>
                                    <Col md="12" xs="12">
                                        <Button
                                            color="primary"
                                            size="sm"
                                            onClick={this.onSave}
                                        >
                                            Save
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default SubjectLanguage;
