import React from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'reactstrap';
import pluralize from 'pluralize';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import './employee-lists-overview.scss';

function EmployeeListsOverview({
    lists,
    item,
    ongoing,
    onRemoveList,
    itemString,
}) {
    const previousEmployees =
        ongoing && itemString === 'assessment'
            ? item._rawData && item._rawData.definition.userLists
            : undefined;

    return lists.map((list, i) => (
        <Row
            className={`line list-overview ${
                itemString === 'assessment' &&
                ongoing &&
                previousEmployees.includes(list.id)
                    ? 'employee-list-disabled'
                    : ''
            }`}
            key={i}
        >
            <Col md="6">
                <strong>{list.name}</strong>
            </Col>
            <Col md="3" className="text-center">
                {list.usersCount} {pluralize('employee', list.usersCount)}
            </Col>
            {(!ongoing || !previousEmployees.includes(list.id)) && (
                <Col md="3" className="text-right">
                    <IconButton
                        color="secondary"
                        onClick={() => {
                            onRemoveList(list.id);
                        }}
                        aria-label="remove list"
                    >
                        <ClearIcon />
                    </IconButton>
                </Col>
            )}
        </Row>
    ));
}

export default observer(EmployeeListsOverview);
