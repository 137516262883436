import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Alert } from 'reactstrap';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { enforceSlash } from '../../utils/helpers';
import moment from 'moment';
import { Table, Tr, Td, Th, Thead } from 'reactable';

@inject('superStore')
@observer
class MailHooksContainer extends React.Component {
    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        await this.props.superStore.loadLanguages();
    }

    changeOrder = async (lang, value) => {
        let newOrder = Number(value);

        if (value !== '' && !isNaN(newOrder))
            await this.props.superStore.updateLanguage(lang.id, {
                order: newOrder,
            });
        else lang.order = value;
    };

    render() {
        const {
            loadingLanguages,
            sortedlanguages: languages,
            languageError,
        } = this.props.superStore;
        const { match } = this.props;

        return loadingLanguages ? (
            <ThemedSpinner />
        ) : (
            <Fragment>
                <div className="block">
                    <ErrorMessage error={languageError} />
                    <Row>
                        <Col md="9" xs="9" className="leftMenu">
                            <h2>Languages</h2>
                        </Col>
                        <Col md="3" xs="3">
                            <div className="pull-right">
                                <Link to={`${enforceSlash(match.url)}create`}>
                                    <div className="btn btn-primary ">
                                        Create Language
                                    </div>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {languages && languages.length > 0 && (
                            <Col md="12" xs="12" className="leftMenu">
                                <Table
                                    className="table"
                                    id="table"
                                    itemsPerPage={500}
                                    pageButtonLimit={5}
                                >
                                    <Thead>
                                        <Th column="Code" className="">
                                            Code
                                        </Th>
                                        <Th column="Name" className="">
                                            Native Name
                                        </Th>
                                        <Th column="EnglishName" className="">
                                            English Name
                                        </Th>
                                        <Th column="Version" className="">
                                            version
                                        </Th>
                                        <Th column="Icon" className="">
                                            icon
                                        </Th>
                                        <Th column="Flag" className="">
                                            Flag
                                        </Th>
                                        <Th column="Published" className="">
                                            Published
                                        </Th>
                                        <Th column="Resources" className="">
                                            Resources
                                        </Th>
                                        <Th column="Last Uploaded" className="">
                                            Last Uploaded
                                        </Th>
                                        <Th
                                            column="Last PO Edited"
                                            className=""
                                        >
                                            Last PO Edited
                                        </Th>
                                        <Th column="Subjects" className="">
                                            Subjects
                                        </Th>
                                        <Th column="Notes" className="">
                                            notes
                                        </Th>
                                        <Th column="Reorder" className="">
                                            {' '}
                                        </Th>
                                    </Thead>
                                    {languages.map((lang, i) => (
                                        <Tr key={lang.id}>
                                            <Td
                                                column={'Code'}
                                                value={lang.code}
                                                className=""
                                            >
                                                <Link
                                                    to={`${enforceSlash(
                                                        match.url
                                                    )}${lang.id}`}
                                                >
                                                    {lang.code}
                                                </Link>
                                            </Td>
                                            <Td
                                                column={'Name'}
                                                value={lang.name}
                                            >
                                                {lang.name}
                                            </Td>
                                            <Td
                                                column={'EnglishName'}
                                                value={lang.english_name}
                                            >
                                                {lang.english_name}
                                            </Td>
                                            <Td
                                                column={'Version'}
                                                value={lang.version}
                                            >
                                                {lang.version}
                                            </Td>
                                            <Td
                                                column={'Icon'}
                                                value={lang.icon}
                                            >
                                                {lang.icon}
                                            </Td>
                                            <Td
                                                column={'Flag'}
                                                value={lang.flag_image}
                                            >
                                                <span
                                                    className={
                                                        'flag static-flag ' +
                                                        lang.icon
                                                    }
                                                    style={{
                                                        backgroundImage: `url(${lang.flag_image})`,
                                                    }}
                                                >
                                                    &nbsp;
                                                </span>
                                            </Td>
                                            <Td
                                                column={'Published'}
                                                value={lang.published}
                                            >
                                                {lang.published ? (
                                                    <span
                                                        className={
                                                            'flag static-flag ' +
                                                            lang.icon
                                                        }
                                                        style={{
                                                            backgroundImage: `url(/static/img/icon-completed.png)`,
                                                        }}
                                                    >
                                                        &nbsp;
                                                    </span>
                                                ) : (
                                                    <span
                                                        className={
                                                            'flag static-flag ' +
                                                            lang.icon
                                                        }
                                                        style={{
                                                            backgroundImage: `url(/static/img/icon-close.png)`,
                                                        }}
                                                    >
                                                        &nbsp;
                                                    </span>
                                                )}
                                            </Td>
                                            <Td
                                                column={'Resources'}
                                                value={lang.resourcesCount}
                                            >
                                                {lang.resourcesCount}
                                            </Td>
                                            <Td
                                                column="Last Uploaded"
                                                className=""
                                            >
                                                {moment(
                                                    lang.lastImported
                                                ).format('L')}
                                            </Td>
                                            <Td
                                                column="Last PO Edited"
                                                className=""
                                            >
                                                {lang.lastUpdatedInPOEditor &&
                                                    moment(
                                                        lang.lastUpdatedInPOEditor
                                                    ).format('L')}
                                            </Td>
                                            <Td
                                                column={'Subjects'}
                                                value={lang.subjectsCount}
                                            >
                                                {lang.subjectsCount}
                                            </Td>
                                            <Td
                                                column={'Notes'}
                                                value={lang.notes}
                                            >
                                                <Fragment>
                                                    {lang.note && (
                                                        <Alert
                                                            color={
                                                                lang.noteType
                                                            }
                                                        >
                                                            {lang.note}
                                                        </Alert>
                                                    )}
                                                </Fragment>
                                            </Td>
                                            <Td column={'Reorder'}>
                                                <Fragment>
                                                    <input
                                                        style={{
                                                            width: '50px',
                                                        }}
                                                        type="number"
                                                        onChange={(e) => {
                                                            this.changeOrder(
                                                                lang,
                                                                e.target.value
                                                            );
                                                        }}
                                                        value={lang.order}
                                                    />
                                                </Fragment>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Table>
                            </Col>
                        )}
                        {languages && languages.length === 0 && (
                            <Col md="12" xs="12" className="leftMenu">
                                <ErrorMessage
                                    error="No languages found"
                                    errorType="info"
                                />
                                <Link to={`${enforceSlash(match.url)}create`}>
                                    <div className="btn btn-primary ">
                                        Create Language
                                    </div>
                                </Link>
                            </Col>
                        )}
                    </Row>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(MailHooksContainer);
