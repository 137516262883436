import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import range from 'lodash/range';
import { reaction } from 'mobx/lib/mobx';
import ListFormDef from '../../forms/list';
import UsersFormDef from '../../forms/list-users';
import ErrorMessage from '../../components/errormessage';
import {
    ThemedButton,
    ThemedLink,
} from '../../components/themed/ThemedComponents';
import AddOrImport from './AddOrImport';
import './modalListEditor.scss';

@inject('store', 'listStore', 'userStore')
@observer
class ModalUsersListEditContainer extends React.Component {
    constructor(props) {
        super(props);
        this._list_form = new ListFormDef({}, {});
        this._usersForm = new UsersFormDef({}, {});

        reaction(
            () => this.props.listStore.editingList,
            (list) => {
                this._list_form.update(list ? { name: list.name } : {});
            }
        );
        if (!this.props.listStore.editingList) {
            range(3).forEach((x) => {
                this._usersForm.$('users').onAdd({
                    preventDefault: () => {},
                });
            });
        }
        this.state = {
            batchMode: false,
            deleteObsolete: false,
            files: [],
            encoding: 'utf-8',
            showEncodingSelect: false,
            isNameTab: true,
        };
    }

    switchMode = () => {
        this.props.userStore.resetUploadData();
        this.setState({ batchMode: !this.state.batchMode });
    };

    reUpload = async () => {
        this.props.userStore.resetUploadData();
    };

    showEncodingOptions = (e) => {
        this.setState({ showEncodingSelect: true });
    };

    changeEncoding = (e) => {
        this.setState({ encoding: e.target.value });
        this.doHandleFileSelect(this.state.files, e.target.value);
    };

    onCreate = () => {
        let usersValid = this.state.batchMode
            ? this.props.userStore.processedRecords &&
              this.props.userStore.processedRecords.newUsers &&
              this.props.userStore.processedRecords.newUsers.length > 0
            : this._usersForm.isValid;

        if (this._list_form.isValid && usersValid) {
            this.props.createRecipientsList({
                ...this._list_form.values(),
                ...(this.state.batchMode
                    ? { users: this.props.userStore.processedRecords.newUsers }
                    : this._usersForm.values()),
            });
        }
    };

    loadAsPromise = (f, encoding) => {
        return new Promise(async (resolve, reject) => {
            let reader = new FileReader();

            reader.onload = function (e) {
                let text = reader.result;
                resolve(text);
            };
            reader.onabort = function () {
                reject();
            };

            reader.readAsText(f, encoding);
        });
    };

    doHandleFileSelect = async (files, encoding) => {
        let results = [];
        let acceptedFiles = [];
        for (let i = 0; i < files.length; i++) {
            let f = files[i];

            if (
                f.type &&
                f.size > 0 &&
                !f.name.endsWith('txt') &&
                !f.name.endsWith('csv')
            ) {
                continue;
            }
            acceptedFiles.push(f);

            let text = await this.loadAsPromise(f, encoding);
            results.push(text);
        }
        this.importFiles(results);
        return acceptedFiles;
    };

    handleFilesSelect = async (evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        let files = evt.target.files || evt.dataTransfer.files;

        let acceptedFiles = await this.doHandleFileSelect(
            files,
            this.state.encoding
        );

        this.setState({ files: acceptedFiles });
    };

    importFiles = (files) => {
        files.forEach(async (fileContent) => {
            await this.props.userStore.saveUsers(
                fileContent,
                this.props.companyId,
                null,
                true
            );
        });
    };

    render() {
        const { result, onRequestClose } = this.props;
        const { processedRecords, importError } = this.props.userStore;

        if (result) {
            return (
                <Fragment>
                    <div className={'modalHeader'}>
                        <h1>
                            {this.state.isNameTab
                                ? 'Create employee list'
                                : `Add employees to list: ${
                                      this._list_form.$('name').value
                                  }`}
                        </h1>
                        <img
                            className={'modal-close-right'}
                            src="/static/img/close.svg"
                            alt="Close"
                            onClick={onRequestClose}
                        />
                    </div>
                    {this.state.isNameTab ? (
                        <Fragment>
                            <div className={'modalContent form'}>
                                <div className="form-holder">
                                    <p>
                                        <strong>Name</strong>
                                    </p>
                                    <input
                                        type="text"
                                        name="listName"
                                        className={
                                            this._list_form.$('name').isValid
                                                ? 'bigInput valid'
                                                : 'bigInput dark'
                                        }
                                        {...this._list_form.$('name').bind()}
                                    />
                                    <div className="error">
                                        {this._list_form.$('name').error}
                                    </div>
                                </div>
                                <ErrorMessage
                                    error={this.props.error || importError}
                                />
                            </div>
                            <div className={'modalFooter'}>
                                <ThemedButton
                                    primary
                                    disabled={
                                        this._list_form.$('name').value === ''
                                    }
                                    onClick={() =>
                                        this.setState({ isNameTab: false })
                                    }
                                >
                                    Create
                                </ThemedButton>
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className={'modalContent form'}>
                                <AddOrImport
                                    showHeader={true}
                                    companyId={this.props.companyId}
                                    batchMode={this.state.batchMode}
                                    processedRecords={processedRecords}
                                    deleteObsolete={this.state.deleteObsolete}
                                    showEncodingSelect={
                                        this.state.showEncodingSelect
                                    }
                                    showEncodingOptions={
                                        this.showEncodingOptions
                                    }
                                    encoding={this.state.encoding}
                                    changeEncoding={this.changeEncoding}
                                    importFiles={this.importFiles}
                                    handleFilesSelect={this.handleFilesSelect}
                                    usersForm={this._usersForm}
                                    hideListName={true}
                                    isImportingLists={true}
                                />
                                <br />
                                <ErrorMessage
                                    error={this.props.error || importError}
                                />
                            </div>
                            <div className={'modalFooter'}>
                                <div className="d-flex flex-justify-between flex-fill">
                                    <ThemedLink
                                        className="no-wrap switch-mode"
                                        onClick={this.switchMode}
                                    >
                                        {this.state.batchMode
                                            ? 'Switch to manual mode'
                                            : 'Switch to import mode'}
                                    </ThemedLink>
                                    {this.state.batchMode && processedRecords && (
                                        <Fragment>
                                            <ThemedButton
                                                secondary
                                                onClick={this.reUpload}
                                                style={{ marginRight: '16px' }}
                                            >
                                                Reupload
                                            </ThemedButton>
                                        </Fragment>
                                    )}
                                </div>
                                <ThemedButton
                                    primary
                                    onClick={this.onCreate}
                                    disabled={
                                        this.state.batchMode
                                            ? processedRecords === null
                                            : !this._usersForm.values().users[0]
                                                  .email.length > 0 ||
                                              this._usersForm.errors().users[0]
                                                  .email !== null
                                    }
                                >
                                    Done
                                </ThemedButton>
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            );
        } else {
            return null;
        }
    }
}

export default withRouter(ModalUsersListEditContainer);
