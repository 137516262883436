import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import services from '../../services';
import styled from 'styled-components';
import { useTable, usePagination, useFilters } from 'react-table';

const Styles = styled.div`
    padding: 1rem;

    table {
        border-spacing: 0;
        border: 1px solid black;
        display: block;

        tr {
            :last-child {
                td {
                    border-bottom: 0;
                }
            }
        }

        th,
        td {
            margin: 0;
            padding: 0.5rem;
            border-bottom: 1px solid black;
            border-right: 1px solid black;

            :last-child {
                border-right: 0;
            }
        }
    }

    .pagination {
        padding: 0.5rem;
    }
`;

function SelectColumnFilter({ column: { filterValue, setFilter } }) {
    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">All</option>
            <option value="certificate">Certificate</option>
            <option value="manual_reminder">Manual Reminder</option>
            <option value="manual_assessment_reminder">
                Manual Assessment Reminder
            </option>
            <option value="assessment_schedule">Assessment Schedule</option>
            <option value="auto_reminder">Auto Reminder</option>
            <option value="my_login">MY Login</option>
            <option value="lms_login">LMS Login</option>
            <option value="event_preview">Preview</option>
            <option value="training_schedule">Schedule</option>
            <option value="introductory_email">Introductory</option>
            <option value="new_employee_enrolment_email">
                New Employee Enrolment
            </option>
        </select>
    );
}

function CompanyColumnFilter({ column: { filterValue, setFilter } }) {
    // Render a multi-select box
    return (
        <input
            type="text"
            value={filterValue}
            onChange={(e) => {
                setFilter(Number(e.target.value) || undefined);
            }}
        ></input>
    );
}

// Let's add a fetchData method to our Table component that will be used to fetch
// new data when pagination state changes
// We can also add a loading state to let our table know it's loading new data
function Table({
    columns,
    data,
    fetchData,
    loading,
    pageCount: controlledPageCount,
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // Get the state from the instance
        state: { pageIndex, pageSize, filters },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 }, // Pass our hoisted table state
            manualPagination: true, // Tell the usePagination
            manualFilters: true,
            // hook that we'll handle our own data fetching
            // This means we'll also have to provide our own
            // pageCount.
            pageCount: controlledPageCount,
        },
        useFilters,
        usePagination
    );

    // Listen for changes in pagination and use the state to fetch our new data
    React.useEffect(() => {
        fetchData({ pageIndex, pageSize, filters });
    }, [fetchData, pageIndex, pageSize, filters]);

    // Render the UI for your table
    return (
        <>
            <table
                className="table react-table slim"
                id="table"
                {...getTableProps()}
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                    <div>
                                        {column.Filter
                                            ? column.render('Filter')
                                            : null}
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                    <tr>
                        {loading ? (
                            // Use our custom loading state to show a loading indicator
                            <td colSpan="10000">Loading...</td>
                        ) : (
                            <td colSpan="10000">
                                Showing {page.length} of ~
                                {controlledPageCount * pageSize} results
                            </td>
                        )}
                    </tr>
                </tbody>
                <tfoot>
                    <div className="pagination">
                        <button
                            className="first"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        >
                            {'<<'}
                        </button>{' '}
                        <button
                            className="prev"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            {'<'}
                        </button>{' '}
                        <button
                            className="next"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        >
                            {'>'}
                        </button>{' '}
                        <button
                            className="last"
                            onClick={() => gotoPage(pageCount - 1)}
                            disabled={!canNextPage}
                        >
                            {'>>'}
                        </button>{' '}
                        <div>
                            Page{' '}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}{' '}
                            </strong>{' '}
                        </div>
                        <div className="ml5 mr5">
                            | Go to page:{' '}
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                    const page = e.target.value
                                        ? Number(e.target.value) - 1
                                        : 0;
                                    gotoPage(page);
                                }}
                                style={{ width: '100px' }}
                            />
                        </div>{' '}
                        <div>
                            <select
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                            >
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </tfoot>
            </table>
        </>
    );
}

function App() {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Created',
                accessor: (x) => {
                    return (
                        <Link to={`${x.reason_id}`}>
                            {moment(x.created).format('llll')}
                        </Link>
                    );
                },
            },
            {
                Header: 'Reason',
                accessor: 'reason',
                Filter: SelectColumnFilter,
            },
            {
                Header: 'Initiated By',
                accessor: 'initiator',
            },
            {
                Header: 'Company',
                id: 'company_id',
                accessor: (x) => {
                    return `[${x.company_id}]-${x.company_name}`;
                },
                Filter: CompanyColumnFilter,
            },
            {
                Header: 'Account',
                accessor: (x) => {
                    return `[${x.account_id}]-${x.account_name}`;
                },
            },
            {
                Header: 'Total targets',
                accessor: 'notifications_count',
            },
            {
                Header: 'Sent',
                accessor: 'sent',
            },
            {
                Header: 'Delivered',
                accessor: 'delivered',
            },
            {
                Header: 'Opened',
                accessor: 'opened',
            },
        ],
        []
    );

    // We'll start our table without any data
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const fetchIdRef = React.useRef(0);

    const fetchData = React.useCallback(
        async ({ pageSize, pageIndex, filters }) => {
            // This will get called when the table needs new data
            // You could fetch your data from literally anywhere,
            // even a server. But for this example, we'll just fake it.

            console.log(filters);
            // Give this fetch an ID
            const fetchId = ++fetchIdRef.current;
            setLoading(true);
            let filtersObject = {};
            filters.forEach((x) => {
                filtersObject[x.id] = x.value;
            });

            let result = await services.Notifications.list({
                p: pageIndex,
                n: pageSize,
                s: null,
                f: null,
                fetchId,
                ...filtersObject,
            });

            if (fetchId === fetchIdRef.current) {
                setData(result.rows);

                setPageCount(result.pages);

                setLoading(false);
            }
        },
        []
    );

    return (
        <Styles>
            <Table
                columns={columns}
                data={data}
                fetchData={fetchData}
                loading={loading}
                pageCount={pageCount}
            />
        </Styles>
    );
}

export default App;
