import React from 'react';
import { Heading4 } from '../../design-system/headings';
import { TextLead } from '../../design-system/texts';
import {
    SpaceBetweenDiv,
    Block,
    FlexDiv,
} from '../../design-system/containers';
import { ThemedButton } from '../../themed/ThemedComponents';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LinkIfNeed from '../../LinkIfNeed';

const ExpandImage = styled.div`
    margin: -32px;
    margin-left: 0;
`;

function Upgrade({ heading, text, img, imgAlt, upgradeLink, learnMoreLink }) {
    return (
        <Block>
            <SpaceBetweenDiv noWrap>
                <FlexDiv column>
                    <Heading4>{heading}</Heading4>
                    <TextLead bold mt={8} mb={16} wrap>
                        {text}
                    </TextLead>
                    <FlexDiv>
                        <LinkIfNeed link={upgradeLink}>
                            <ThemedButton primary noTextTransform tiny>
                                Upgrade
                            </ThemedButton>
                        </LinkIfNeed>
                        <LinkIfNeed link={learnMoreLink}>
                            <ThemedButton secondary noTextTransform airy tiny>
                                Learn more
                            </ThemedButton>
                        </LinkIfNeed>
                    </FlexDiv>
                </FlexDiv>
                <ExpandImage>
                    <img src={img} alt={imgAlt} />
                </ExpandImage>
            </SpaceBetweenDiv>
        </Block>
    );
}

export default Upgrade;
