import React from 'react';

class NewsSubjects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blogList: null,
            fetchingData: true,
        };
    }

    async componentDidMount() {
        let contentObject = await this.getBlogContent();
        if (contentObject) {
            this.setState({ blogList: contentObject, fetchingData: false });
        }
    }

    async getBlogContent() {
        const url =
            'https://www.awarego.com/wp-json/wp/v2/posts/?per_page=3&_embed';
        let res = await fetch(url);
        return await res.json();
    }

    render() {
        return (
            <div className="block">
                <h3>New subjects</h3>
                <div className="item video">
                    <a href="/catalog/subject/663/" rel="noopener noreferrer">
                        <div
                            className="image"
                            style={{
                                backgroundImage:
                                    'url(https://thumbs.awarego.com/S04E02.jpg)',
                            }}
                        />
                    </a>
                    <h5>
                        <a href="/catalog/subject/663/">Spear phishing II</a>
                    </h5>
                    <div className="time"> 1.11 min</div>
                    <p>
                        Be mindful of what you share on social media.
                        Cybercriminals can use our interests and hobbies to
                        create targeted phishing emails that look legitimate.
                    </p>
                    <hr />
                </div>
                <div className="item video">
                    <a href="/catalog/subject/666/">
                        <div
                            className="image"
                            style={{
                                backgroundImage:
                                    'url(https://thumbs.awarego.com/S04E05.jpg)',
                            }}
                        />
                    </a>

                    <h5>
                        <a href="catalog/subject/666/">Password sharing</a>
                    </h5>
                    <div className="time"> 1.32 min</div>

                    <p>Some things are simply not meant to be shared.</p>
                </div>
            </div>
        );
    }
}

export default NewsSubjects;
