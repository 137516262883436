import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import FormDef from '../../forms/company';
import NameForm from '../../components/super/companyEdit';
import BaseCompanyDependentChildContainer from '../common/BaseCompanyDependentChildContainer';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

@inject('superStore')
@observer
class CompaniesViewSuperContainer extends BaseCompanyDependentChildContainer {
    constructor(props) {
        super(props);
        this._form = new FormDef(
            {},
            {
                hooks: {
                    onSuccess: this.onSuccess,
                },
            }
        );
    }

    async doLoadData() {
        if (this.state.companyId) {
            await this.props.superStore.loadCompany(this.state.companyId);
            this._form.update(
                this.props.superStore.editingCompany
                    ? {
                          name: this.props.superStore.editingCompany
                              .company_name,
                          theme: this.props.superStore.editingCompany.theme,
                      }
                    : {}
            );
        }
    }

    onSuccess = async () => {
        let result = await this.props.superStore.update(
            this.props.companyId,
            this._form.values()
        );
        if (result) {
            if (this.props.companyId)
                return this.props.history.push(
                    `/super/companies/${this.props.companyId}`
                );
            else return this.props.history.push('/super/companies');
        }
    };

    render() {
        const { savingCompany, error } = this.props.superStore;

        return (
            <Fragment>
                <div className="steps">
                    {
                        <Fragment>
                            <NameForm form={this._form} />
                            {savingCompany ? (
                                <ThemedSpinner size={45} />
                            ) : (
                                <div
                                    className="btn btn-primary"
                                    onClick={(e) => this._form.onSubmit(e)}
                                >
                                    Save
                                </div>
                            )}
                        </Fragment>
                    }
                    <ErrorMessage error={error} />
                </div>
            </Fragment>
        );
    }
}

export default withRouter(CompaniesViewSuperContainer);
