import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import SimpleTable from '../../components/SimpleTable';
import pluralize from 'pluralize';
import LightTooltip from '../../components/LightTooltip';
import SemiCircleProgress from '../../components/manager/assessment/semi-circle-progress';
import NumberChip from '../../components/NumberChip';
import { ThemedLink } from '../../components/themed/ThemedComponents';
import { Box } from '@material-ui/core';

function AssessmentQuestionsTable({
    companyId,
    assessmentId,
    threatAreas,
    showReviewQuestionsModal,
}) {
    const { assessmentStore } = useStores();
    const { loadingQuestionsReportList, questionsReportList } = assessmentStore;

    useEffect(() => {
        assessmentStore.loadAssessmentQuestionsReport(companyId, assessmentId);
    }, [companyId, assessmentId]);

    const columns = useMemo(
        () => [
            {
                Header: 'Question',
                accessor: 'name',
                Cell: x => {
                    return (
                        <div className="row-title">
                            <NumberChip
                                size="small"
                                label={x.row.original.number}
                            />
                            {x.value}
                        </div>
                    );
                },
            },
            {
                Header: 'Threat area',
                accessor: 'area',
                Cell: x => <span>{x.value}</span>,
            },
            {
                Header: '# Key behaviours',
                accessor: 'key_behaviour_count',
                Cell: x => (
                    <LightTooltip
                        title={x.row.original.components
                            .split(',')
                            .map((d, i) => (
                                <div key={i}>
                                    {
                                        (
                                            (
                                                (
                                                    threatAreas.find(
                                                        area =>
                                                            area.name ===
                                                            x.row.original.area
                                                    ) || {}
                                                ).components || []
                                            ).find(
                                                c => c.code === d.toLowerCase()
                                            ) || {}
                                        ).name
                                    }
                                </div>
                            ))}
                    >
                        <span className="underline">
                            {x.value} {pluralize('Behaviours', x.value)}
                        </span>
                    </LightTooltip>
                ),
            },
            {
                Header: 'Resilience score',
                accessor: 'average_score_percentage',
                Cell: x => (
                    <SemiCircleProgress
                        percentage={x.value}
                        size="small"
                        smallFont={true}
                        inTable={true}
                    />
                ),
            },
            {
                id: 'review_answers',
                Cell: x => (
                    <ThemedLink
                        bold
                        onClick={() =>
                            showReviewQuestionsModal(x.row.original.id)
                        }
                    >
                        Review answers
                    </ThemedLink>
                ),
            },
        ],
        []
    );

    return loadingQuestionsReportList ? (
        <ThemedSpinner />
    ) : (
        <Box mt={2}>
            <SimpleTable columns={columns} data={questionsReportList} />
        </Box>
    );
}

export default observer(AssessmentQuestionsTable);
