import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Table } from 'reactstrap';
import { formatDate } from '../../utils/helpers';
import Modal from 'react-modal';
import sanitizeHtml from 'sanitize-html';

@inject('mailStore')
@observer
class MailLog extends React.Component {
    state = { modal: false };

    showDetails = async (storage_url) => {
        await this.props.mailStore.loadMailLog(storage_url);
        this.setState({ modal: true });
    };

    closePreview = () => {
        this.setState({ modal: false });
    };

    render() {
        const { records } = this.props;
        const { mailDetails } = this.props.mailStore;

        return (
            <Fragment>
                <Table className="mail-log">
                    <tbody>
                        {records.map((record) => {
                            return (
                                <tr key={record.id}>
                                    <tr>
                                        <br></br>
                                        <h3>
                                            Mail Logs -{' '}
                                            <span className="green">
                                                {formatDate(record.created)}
                                            </span>
                                        </h3>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h4>Action</h4>
                                            <pre>
                                                {JSON.stringify(
                                                    record.actions,
                                                    null,
                                                    2
                                                )}
                                            </pre>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h4>Message</h4>
                                            <pre>
                                                {JSON.stringify(
                                                    record.message,
                                                    null,
                                                    2
                                                )}
                                            </pre>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h4>Status</h4>
                                            <pre>
                                                {JSON.stringify(
                                                    record.delivery_status,
                                                    null,
                                                    2
                                                )}
                                            </pre>
                                        </td>
                                    </tr>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>

                {this.state.modal && (
                    <Modal
                        ariaHideApp={false}
                        onRequestClose={this.closePreview}
                        isOpen={true}
                    >
                        {mailDetails && (
                            <Fragment>
                                <h3>{mailDetails.Subject}</h3>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizeHtml(
                                            mailDetails['body-html']
                                        ),
                                    }}
                                ></div>
                            </Fragment>
                        )}
                    </Modal>
                )}
            </Fragment>
        );
    }
}

export default withRouter(MailLog);
