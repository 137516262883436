import React from 'react';
import Upgrade from '../dashboard/upgrade';
import NoItemsYet from '../no-items-yet';
import { ThemedButton } from '../../themed/ThemedComponents';

function TrainingUpgrade({ upgradeAllowed, supportEmail }) {
    return upgradeAllowed ? (
        <Upgrade
            heading={'Cybersecurity Awareness Training'}
            text={
                'Build security culture with behavior-changing training programs'
            }
            img={'/static/img/training-upgrade.svg'}
            imgAlt={'sneak peak into a training program'}
            upgradeLink={'/subscriptions/upgrade'}
            learnMoreLink={'https://awarego.com/security-awareness-training/'}
        />
    ) : (
        <NoItemsYet
            img={'/static/img/training-locked.svg'}
            imgAlt={'training programs are unavailable'}
            title={'Training programs are unavailable in your current plan'}
            text={
                'Please upgrade your subscription to access the training programs.'
            }
            heading={'Training programs'}
            buttons={
                <a href={`mailto:${supportEmail}`}>
                    <ThemedButton primary fullWidth>
                        Contact to upgrade
                    </ThemedButton>
                </a>
            }
        />
    );
}

export default TrainingUpgrade;
