import React from 'react';
import { observer } from 'mobx-react';
import "./simple-report.scss"
import {SocialPost} from "@awarego/awarego-components"
import {BlockAnswer} from "@awarego/awarego-components"
import AnswersRow from './answers-row'

function SimpleReport({reviewData, totalParticipants}) {

    return (
        <div className="report-block">
            {reviewData.condition &&
              <div className="condition" style={{marginTop: 0}}>
                <span className="condition-heading">This sub-question was shown to a sub-audience who have selected:</span>
                <span className="condition-value">{reviewData.condition.text}</span>
              </div>
            }
            {reviewData.title &&
                <div className="header" style={{marginTop: 0}}>
                    <span className="header-left">Question:</span>
                </div>
            }
            {reviewData.pretitle
                && <div className="pretitle">
                    {reviewData.pretitle}
                </div>
            }
            <div className="title">
                {reviewData.title}
            </div>
            {reviewData.socialpost &&
                <div>
                    <SocialPost socialPost={reviewData.socialpost} handleImageClick={null}/>
                </div>
            }
            <div className="header">
                <span className="header-left">{reviewData.title ? 'Answers' : 'Responses'}</span>
              {(reviewData.blockParticipantCount || reviewData.blockParticipantCount === 0)
                ? <span
                  className="header-right">Participants ({reviewData.blockParticipantCount}/{totalParticipants})</span>
                : <span className="header-right">Participants ({totalParticipants})</span>
              }
            </div>
            {reviewData.report && reviewData.report.map( (dataRow, i) => (
              <div className="answers" key={i}>
                  {"subtitle" in dataRow
                    ? <div className="answers-subtitle">
                      {dataRow.subtitle}
                    </div>
                    : ""
                  }
                {dataRow.question ? //A report rows can have an extra 'question' parent object above the 'answers' (e.g. Decision blocks)
                  <>
                    <div className="header">
                      <span className="header-left">{dataRow.question}</span>
                    </div>
                    {dataRow.options.map( (option, j) => (<AnswersRow data={option} totalParticipants={totalParticipants}/>))}
                    </>
                  : <AnswersRow data={dataRow} totalParticipants={totalParticipants}/>}
                </div>
            ))}
            {reviewData.actionsReport &&
                <div className="header">
                    <span className="header-left">{'Actions'}</span>
                </div>
            }
            {reviewData.actionsReport && reviewData.actionsReport.map( (dataRow,i) => (
              <div className="answers" key={i}>
                <AnswersRow data={dataRow} totalParticipants={totalParticipants}/>
              </div>
            ))}
            {reviewData.answer 
                && <BlockAnswer 
                    blockAnswers={reviewData.answer} 
                    hideBlock={false} 
                    question={null} 
                    calculateColor={null}
                    legendStepCount={reviewData.answer.reviewLegendStepCount}
                />
            }
        </div>
    )
}

export default observer(SimpleReport)