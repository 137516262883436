/* global fetch */
import 'isomorphic-unfetch';

/**
 * Fetch translation file(s).
 * @function getTranslation
 * @param {string} lang - Language to fetch.
 * @param {array} files - Translation files to fetch.
 * @param {string} baseUrl - Locale location.
 * @return {object} Fetched translation files.
 */
export async function getTranslation(code, file) {
    let translation = {};
    if (!code) {
        code = 'en';
    }
    const response = await fetch(file);
    translation['common'] = await response.json();

    return { [code]: translation };
}
