module.exports = {
    EVENTS: {
        LOGIN: 1,
        LOGOUT: 2,
        SUBJECT_OPEN: 3,
        SUBJECT_CLOSE: 4,
        ANSWARE_CORRECT: 5,
        ANSWARE_INCORRECT: 6,
        VIDEO_START: 7,
        VIDEO_25: 8,
        VIDEO_50: 9,
        VIDEO_75: 10,
        VIDEO_95: 11,
        VIDEO_100: 12,
        CAMPAIGN_EMAIL: 13,
    },

    USERS_VIEW: {
        LISTS: 1,
        EDIT: 2,
        ADD_USERS: 3,
        LIST_USERS: 4,
        LIST_VIEW: 5,
    },

    TRANSPORTS: {
        EMAIL: 1,
        SLACK: 2,
        TEAMS: 4,
    },

    AUTOMATION_WIZARD_STEPS: {
        GOAL: 1,
        CONTENT: 2,
        EMPLOYEES: 3,
        SCHEDULE: 4,
        REMINDERS: 5,
        INTROMESSAGE: 6,
        FINALIZE: 7,
        START: 8,
    },

    ASSESSMENT_WIZARD_STEPS: {
        GOAL: 1,
        CONTENT: 2,
        PERSONALIZE: 3,
        EMPLOYEES: 4,
        SCHEDULE: 5,
        REMINDERS: 6,
        // INTROMESSAGE:7,
        FINALIZE: 7,
        START: 8,
    },

    STATUS: {
        DRAFT: -1,
        SCHEDULED: 1,
        ONGOING: 2,
        // REVIEW: 3,
        COMPLETED: 4,
    },
};
