import React, { Fragment } from 'react';
import { ThemedLink } from '../../components/themed/ThemedComponents';
import { Popover, PopoverBody } from 'reactstrap';
import { FlexDiv } from '../../components/design-system/containers';
import { TextLead, TextTiny } from '../../components/design-system/texts';
import { observer } from 'mobx-react';

export default observer(
    ({
        name,
        value,
        subtext,
        actionName,
        action,
        helpText,
        helpId,
        isLast,
    }) => {
        const [showHelp, doShowHelp] = React.useState(false);

        const toggleHelp = () => {
            doShowHelp(!showHelp);
        };

        return (
            <FlexDiv pt={20} pb={20} borderBottom={!isLast}>
                <FlexDiv flexGrow={1}>
                    <TextLead bold lighter>
                        {name}
                        {helpId ? (
                            <Fragment>
                                <span
                                    className="help-questionmark"
                                    id={helpId}
                                    onClick={() => {
                                        toggleHelp();
                                    }}
                                />
                                <Popover
                                    placement="bottom"
                                    isOpen={showHelp}
                                    target={helpId}
                                    toggle={toggleHelp}
                                >
                                    <PopoverBody>{helpText}</PopoverBody>
                                </Popover>
                            </Fragment>
                        ) : null}
                    </TextLead>
                </FlexDiv>
                <FlexDiv column flexGrow={2} ml={32}>
                    <TextTiny bold>{value}</TextTiny>
                    <TextTiny>{subtext}</TextTiny>
                </FlexDiv>
                <FlexDiv justifyEnd flexGrow={1}>
                    <ThemedLink onClick={action}>
                        <TextLead themed bold>
                            {actionName}
                        </TextLead>
                    </ThemedLink>
                </FlexDiv>
            </FlexDiv>
        );
    }
);
