import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import pluralize from 'pluralize';
import Alert from '../../components/Alert';
import UsersImport from '../../components/manager/users/users-import';
import BaseCompanyDependentChildContainer from '../common/BaseCompanyDependentChildContainer';
import EditableUsersTable from '../../components/manager/users/EditableUsersTable';
import { InputLabel, Select, MenuItem, FormControl } from '@material-ui/core';

@observer
class AddOrImport extends BaseCompanyDependentChildContainer {
    render() {
        const {
            batchMode,
            processedRecords,
            hideListName = false,
            deleteObsolete,
            showEncodingSelect,
            showEncodingOptions,
            encoding,
            changeEncoding,
            importFiles,
            handleFilesSelect,
            usersForm,
            showHeader,
            companyId,
            onSelectExistingUsers,
            isImportingLists,
        } = this.props;

        return batchMode ? (
            <Fragment>
                {processedRecords ? (
                    <Fragment>
                        {processedRecords.newUsers.length > 0 && (
                            <Fragment>
                                <Alert severity="success">
                                    {processedRecords.newUsers.length} employees
                                    added from .csv file
                                </Alert>
                                <div className="users-to-add">
                                    {processedRecords.newUsers.map((x) => (
                                        <div
                                            key={x.email}
                                            className={'user-record'}
                                        >
                                            {x.email}, {x.name}{' '}
                                            {!hideListName &&
                                                x.listName &&
                                                `, ${x.listName}`}
                                        </div>
                                    ))}
                                </div>
                            </Fragment>
                        )}
                        {processedRecords.newUsers.length === 0 &&
                            processedRecords.obsolete > 0 && (
                                <div className="d-flex flex-center flex-fill flex-column">
                                    No new user found,
                                    {deleteObsolete
                                        ? `${
                                              processedRecords.obsolete
                                          } ${pluralize(
                                              'employee',
                                              processedRecords.obsolete
                                          )} will be deleted`
                                        : `Tick checkbox above to remove obsolete users`}
                                </div>
                            )}
                        {processedRecords.newUsers.length === 0 &&
                            processedRecords.obsolete === 0 && (
                                <div className="d-flex flex-center flex-fill flex-column">
                                    No changes found
                                </div>
                            )}
                        {processedRecords.count_invalid > 0 && (
                            <div className="users-to-add">
                                <h5>Found errors:</h5>
                                {processedRecords.data
                                    .filter((x) => x.error)
                                    .map((x) => (
                                        <div
                                            key={x.lineNumber}
                                            className="user-record"
                                        >
                                            {x.lineNumber}: {x.original}
                                            <div
                                                className={'user-record-error'}
                                            >
                                                {x.error}
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        )}
                        <div className="encoding-option">
                            {!showEncodingSelect && (
                                <div
                                    className="show-filetype"
                                    onClick={showEncodingOptions}
                                >
                                    Select file encoding
                                </div>
                            )}
                            {showEncodingSelect && (
                                <FormControl variant="outlined">
                                    <InputLabel>File type</InputLabel>
                                    <Select
                                        name="select-encoding"
                                        color="primary"
                                        value={encoding}
                                        onChange={changeEncoding}
                                    >
                                        <MenuItem value="utf-8">UTF-8</MenuItem>
                                        <MenuItem value="iso-8859-1">
                                            ISO 8859-1 (Icelandic)
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                        </div>
                    </Fragment>
                ) : (
                    <UsersImport
                        handleFilesSelect={handleFilesSelect}
                        onFilesSelected={importFiles}
                        isImportingLists={isImportingLists}
                    />
                )}
            </Fragment>
        ) : (
            <EditableUsersTable
                onSelectExistingUsers={onSelectExistingUsers}
                form={usersForm}
                showHeader={showHeader}
                companyId={companyId}
            />
        );
    }
}

export default withRouter(AddOrImport);
