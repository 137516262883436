import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';
import UserCampaignDetails from '../../components/manager/users/UserCampaignDetails';
import BaseCompanyDependentChildContainer from '../common/BaseCompanyDependentChildContainer';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

@inject('userStore', 'commonStore', 'automationStore', 'authStore')
@observer
class UserDetailsContainer extends BaseCompanyDependentChildContainer {
    async doLoadData() {
        return await this.props.userStore.loadUserDetails(
            this.props.companyId,
            this.props.email
        );
    }

    onSendReminder = async (subject) => {
        this.props.commonStore.showConfirm(
            `Remind user to view ${subject.title}`,
            'Proceed',
            'Remind',
            async () => {
                return this.props.automationStore.resendEventMail(
                    this.props.companyId,
                    null,
                    subject.eventId,
                    this.props.email
                );
            },
            false
        );
    };

    render() {
        const { automationUserStatus, loadingAutomationUserDetails } =
            this.props.userStore;
        const { anonymousTracking } = this.props.authStore;

        return !loadingAutomationUserDetails && automationUserStatus ? (
            <Fragment>
                <Link to={`/users/list/${this.props.listId}/user`}>
                    <div className={'navigation back-button'}>Back to list</div>
                </Link>
                <UserCampaignDetails
                    userDetails={automationUserStatus}
                    anonymousTracking={anonymousTracking}
                    showTrainings={true}
                    onSendReminder={this.onSendReminder}
                    email={this.props.email}
                />
            </Fragment>
        ) : (
            <ThemedSpinner />
        );
    }
}

export default withRouter(UserDetailsContainer);
