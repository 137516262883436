import styled, { css } from 'styled-components';
import { colors } from './colors';

const Heading = styled.span`
    color: ${colors.heavyMetal};
    line-height: normal;
    padding: 0;
    margin: 0;
    font-weight: 700;

    ${(props) =>
        props.themed &&
        css`
            color: ${(props) => props.theme.primary};
        `}
    ${(props) =>
        props.color &&
        css`
            color: ${(props) => props.color};
        `}
    ${(props) =>
        props.lighter &&
        css`
            opacity: 0.6;
        `}
    ${(props) =>
        props.margin &&
        css`
            margin: ${props.margin}px;
        `}
    ${(props) =>
        props.my &&
        css`
            margin-top: ${props.my}px;
            margin-bottom: ${props.my}px;
        `}
    ${(props) =>
        props.mx &&
        css`
            margin-left: ${props.mx}px;
            margin-right: ${props.mx}px;
        `}
    ${(props) =>
        props.mt &&
        css`
            margin-top: ${props.mt}px;
        `}
    ${(props) =>
        props.mr &&
        css`
            margin-right: ${props.mr}px;
        `}

    ${(props) =>
        props.mb &&
        css`
            margin-bottom: ${props.mb}px;
        `}
    ${(props) =>
        props.ml &&
        css`
            margin-left: ${props.ml}px;
        `}
    
    ${(props) =>
        props.link &&
        css`
            cursor: pointer;
        `}
`;

// titles only
export const Heading1 = styled(Heading)`
    font-size: 24px;
`;

// card titles
export const Heading2 = styled(Heading)`
    font-size: 20px;
    font-weight: 800;
`;

// inside cards
export const Heading3 = styled(Heading)`
    font-size: 16px;
`;

// smallest heading
export const Heading4 = styled(Heading)`
    font-size: 14px;
`;
