import fetch from 'isomorphic-fetch';

const Utils = require('../../utils/helpers');

export default class {
    static async assignPackageToCompany(req, body, csrfToken) {
        body._csrf = csrfToken;
        const bodyJson = JSON.stringify(body);
        const options = {
            method: 'POST',
            credentials: 'include', // for client side requests
            headers: new Headers({
                'Content-Type': 'application/json',
                Accept: 'application/json',
                cookie: req ? req.headers.cookie : '',
            }),
            body: bodyJson,
        };
        let url = Utils.host() + '/data/manager/assignpackage';
        let res = await fetch(url, options);
        let data = await res.json();
        return data;
    }
}
