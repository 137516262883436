import { action, observable } from 'mobx';

import services from '../services';
import { extractErrorMessage } from '../utils/helpers';

class ResourcesStore {
    @observable posters = observable([]);
    @observable loadingPosters = false;
    @observable error = null;

    @action
    async loadPosters() {
        if (this.loadingPosters) return;
        this.loadingPosters = true;
        this.error = null;
        try {
            const result = await services.Resources.posters();
            this.posters.replace(result.posters);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingPosters = false;
        }
    }
}

export default ResourcesStore;
