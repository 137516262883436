import React from 'react';
import { SvgLoader, SvgProxy } from 'react-svgmt';
import styled, { withTheme } from 'styled-components';

const ThemedReminder = styled.div`
    display: inline-flex;
    vertical-align: bottom;
`;

class ThemedReminderSVG extends React.Component {
    render() {
        return (
            <ThemedReminder>
                <SvgLoader path="/static/img/icon-remind.svg">
                    <SvgProxy selector="path" fill={this.props.theme.primary} />
                </SvgLoader>
            </ThemedReminder>
        );
    }
}

export default withTheme(ThemedReminderSVG);
