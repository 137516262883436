import React from 'react';
import moment from 'moment';
import { observer } from 'mobx-react';
import Setting from './Setting';
import { useStores } from '../../hooks/use-stores';

export default observer(() => {
    const { authStore } = useStores();
    const { currentUser } = authStore;

    return (
        <div className="top-guide">
            <div className="block new-settings-block">
                <h3>My profile</h3>
                <p className="text-thin">
                    Joined on {moment(currentUser.created).format('LL')}
                </p>
                <Setting name="Name" value={currentUser.name} />
                <Setting name="Email" value={currentUser.email} isLast={true} />
            </div>
        </div>
    );
});
