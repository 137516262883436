import React from 'react';
import { observer } from 'mobx-react';

@observer
class CompanyAppearanceLogoDisplay extends React.Component {
    //<!--div className="remove-logo-link"><img src="/static/img/icon-remove-circle.svg"/> remove</div-->

    handleRemoveLogo = async () => {
        this.props.onRemoveLogo();
    };

    render() {
        return (
            <div className="company-logo-display">
                <div
                    className="d-flex flex-center flex-fill flex-column"
                    style={{ height: '300px' }}
                >
                    <div style={{ position: 'relative', minWidth: '128px' }}>
                        <img
                            style={{ width: '100%' }}
                            src={
                                process.env.REACT_APP_RESOURCES_URL +
                                '/' +
                                process.env.REACT_APP_COMPANY_RESOURCES_DIR +
                                '/' +
                                this.props.logo
                            }
                            alt="Your logo"
                        />
                        <div
                            className="remove-logo-link"
                            onClick={this.handleRemoveLogo}
                        >
                            <span className="remove-logo-icon" /> remove
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanyAppearanceLogoDisplay;
