import React, { Fragment, useState, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import {
    FlexDiv,
    Block,
    SpaceBetweenDiv,
} from '../../components/design-system/containers';
import { BigModal } from '../../components/design-system/modals';
import {
    TextTiny,
    TextLead,
    TextDefault,
} from '../../components/design-system/texts';
import { Heading2 } from '../../components/design-system/headings';
import { colors } from '../../components/design-system/colors';
import { useStores } from '../../hooks/use-stores';
import PackageForm from '../../components/partner/PackageForm';
import moment from 'moment';
import SimpleTable from '../../components/SimpleTable';
import StatusBadge from '../../components/StatusBadge';
import { Spinner } from 'reactstrap';
import RemoveSubscriptionConfirm from '../../components/partner/RemoveSubscriptionConfirm';
import StopCampaignsConfirm from '../../components/partner/StopCampaignsConfirm';
import EditSubscriptionConfirm from '../../components/partner/EditSubscriptionConfirm';

function CompanySubscription({ company, partnerId, loadCompany }) {
    const { superStore, commonStore, subscriptionStore } = useStores();

    const [showEditPackageModal, setShowEditPackageModal] = useState(false);
    const [isDeactivating, setIsDeactivating] = useState(false);

    const { addingPackage } = superStore;

    const {
        subscriptionPlans,
        loadingSubscriptionsPlans,
        companyForm,
        partnerEstimate,
        estimationLoading,
    } = subscriptionStore;

    useEffect(() => {
        subscriptionStore.loadSubscriptionPlans(partnerId);
    }, [partnerId, subscriptionStore]);

    const isInactive =
        company.subscription && company.subscription.package_valid === 0;

    const initiateEditForm = async () => {
        subscriptionStore.initCompanyForm(
            partnerId,
            handleSubmitEditPackage,
            company
        );
        setShowEditPackageModal(true);
    };

    const confirmAndExecuteEditSubscription = (form) => {
        setShowEditPackageModal(false);
        commonStore.showConfirm(
            <EditSubscriptionConfirm
                isInactive={isInactive}
                companyForm={form}
                partnerEstimate={subscriptionStore.partnerEstimate}
                validTo={company.subscription.package_valid_to}
                seats={company.subscription.package_users}
            />,
            'Confirm',
            'Confirm subscription changes',
            async () => {
                let result = await subscriptionStore.updateCompanySubscription(
                    partnerId,
                    company.company_id,
                    {
                        companyName: form.values().companyName,
                        subscriptionCode: form.values().subscription,
                        seats: form.values().seats,
                        validTo: form.values().valid,
                        validTill: form.values().validTill,
                    }
                );
                if (result) {
                    loadCompany();
                    commonStore.success('Subscription updated.');
                } else {
                    if (subscriptionStore.error) {
                        commonStore.error(subscriptionStore.error);
                    }
                }
            },
            false
        );
    };

    const handleSubmitEditPackage = async (form, initials) => {
        if (form.values().seats < initials.subscription.package_users) {
            let canReduceSeatsResult = await subscriptionStore.canReduceSeats(
                partnerId,
                company.company_id,
                parseInt(form.values().seats)
            );
            if (canReduceSeatsResult && canReduceSeatsResult.canReduceSeats) {
                confirmAndExecuteEditSubscription(form);
            } else {
                setShowEditPackageModal(false);
                commonStore.showConfirm(
                    <TextLead>
                        In order to reduce the number of seats, please delete at
                        least {canReduceSeatsResult.removeEmployeesCount}{' '}
                        employees through the client's Employees page. Keep in
                        mind that employees you remove from the list will not be
                        able to receive any training or assessment.
                    </TextLead>,
                    'Ok',
                    'Unable to change number of seats',
                    () => {},
                    false,
                    false
                );
            }
        } else {
            confirmAndExecuteEditSubscription(form);
        }
    };

    const onDeactivate = async () => {
        setIsDeactivating(true);
        let canBeRemovedResult = await subscriptionStore.canBeRemoved(
            partnerId,
            company.company_id
        );

        // attempt to load estimation only for non free subscriptions, this would be the first parameter of commonStore.showConfirm()

        // async () => {
        //             if (
        //                 subscriptionPlans.filter(
        //     (s) =>
        //         s.code ===
        //         company.subscription.subscription_code
        // )[0].isFree
        //             ) {
        //                 return (
        //                     <RemoveSubscriptionConfirm
        //                         company={company}
        //                         verb={'deactivate'}
        //                         subject={'subscription for'}
        //                         isFree
        //                     />
        //                 );
        //             } else {
        //                 let estimationResult =
        //                     await subscriptionStore.loadEstimation(
        //                         partnerId,
        //                         company.subscription.subscription_code,
        //                         0,
        //                         company.company_id
        //                     );
        //                 estimationResult && (
        //                     <RemoveSubscriptionConfirm
        //                         company={company}
        //                         partnerEstimate={
        //                             subscriptionStore.partnerEstimate
        //                         }
        //                         verb={'deactivate'}
        //                         subject={'subscription for'}
        //                         action={'deactivating'}
        //                         subscriptionName={
        //                             company.subscription.subscription_name
        //                         }
        //                     />
        //                 );
        //             }
        //         },

        if (canBeRemovedResult && canBeRemovedResult.canBeRemoved) {
            let estimationResult = await subscriptionStore.loadEstimation(
                partnerId,
                company.subscription.subscription_code,
                0,
                company.company_id
            );
            if (estimationResult) {
                setShowEditPackageModal(false);
                commonStore.showConfirm(
                    <RemoveSubscriptionConfirm
                        company={company}
                        partnerEstimate={subscriptionStore.partnerEstimate}
                        verb={'deactivate'}
                        subject={'subscription for'}
                        action={'deactivating'}
                        isFree={
                            subscriptionPlans.filter(
                                (s) =>
                                    s.code ===
                                    company.subscription.subscription_code
                            )[0].isFree
                        }
                        subscriptionName={
                            company.subscription.subscription_name
                        }
                    />,
                    'Yes, deactivate',
                    'Deactivate subscription',
                    async () => {
                        let deactivationResult =
                            await subscriptionStore.deactivateCompanySubscription(
                                partnerId,
                                company.company_id
                            );
                        if (deactivationResult) {
                            loadCompany();
                            commonStore.success('Subscription deactivated.');
                        } else {
                            if (subscriptionStore.deactivatingError) {
                                commonStore.error(
                                    subscriptionStore.deactivatingError
                                );
                            }
                        }
                    },
                    true
                );
            } else {
                if (subscriptionStore.estimationError) {
                    commonStore.error(subscriptionStore.estimationError);
                }
            }
        } else {
            setShowEditPackageModal(false);
            commonStore.showConfirm(
                <StopCampaignsConfirm
                    action={'deactivate this subscription'}
                    canBeRemovedResult={canBeRemovedResult}
                />,
                'Ok',
                'Unable to deactivate subscription',
                () => {},
                false,
                false
            );
        }
        setIsDeactivating(false);
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Plan',
                accessor: 'subscription_name',
            },
            {
                Header: 'Seats',
                accessor: 'package_users',
            },
            {
                Header: 'Status',
                accessor: 'package_valid',
                Cell: (x) => {
                    return x.value ? (
                        <StatusBadge color={'success'} text={'Active'} />
                    ) : (
                        <StatusBadge color={'danger'} text={'Inactive'} />
                    );
                },
            },
            {
                Header: 'Active from',
                accessor: 'package_valid_from',
                Cell: (x) => moment(x.value).format('ll'),
            },
            {
                Header: 'Active until',
                accessor: 'package_valid_to',
                Cell: (x) => moment(x.value).format('ll'),
            },
            {
                Header: 'Monthly cost',
                accessor: 'price',
                Cell: (x) => `$${x.value.toFixed(2)}`,
                tooltip:
                    'Your monthly cost will vary depending on the total number of assigned seats across all companies.',
            },
        ],
        []
    );

    const priceComponent = (price) => (
        <FlexDiv>
            <TextLead bold>${price.toFixed(2)}</TextLead>
            <TextLead lighter bold>
                /month
            </TextLead>
        </FlexDiv>
    );

    return !company ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <Block>
                <SpaceBetweenDiv>
                    <Heading2>Subscription</Heading2>
                    <ThemedButton secondary onClick={initiateEditForm}>
                        {isInactive ? 'Reactivate' : 'Edit subscription'}
                    </ThemedButton>
                </SpaceBetweenDiv>
                <FlexDiv mt={32} justifyCenter>
                    {subscriptionStore.updatingCompanySubscription ||
                    subscriptionStore.deactivatingCompanySubscription ? (
                        <ThemedSpinner />
                    ) : (
                        company.subscription && (
                            <SimpleTable
                                data={[company.subscription]}
                                columns={columns}
                                disableSortBy
                                hidePagination
                            />
                        )
                    )}
                </FlexDiv>
            </Block>
            <BigModal
                isOpen={showEditPackageModal}
                onRequestClose={() => setShowEditPackageModal(false)}
                heading={
                    isInactive ? 'Reactivate subscription' : 'Edit subscription'
                }
                extraHeaderContent={
                    <Fragment>
                        {isInactive ? (
                            <FlexDiv pt={8}>
                                <TextDefault>
                                    {company.company_name} was previously on the
                                </TextDefault>
                                <TextDefault bold ml={4}>
                                    {company.subscription.subscription_name}{' '}
                                    plan
                                </TextDefault>
                            </FlexDiv>
                        ) : (
                            <FlexDiv pt={8} alignCenter>
                                <TextDefault>
                                    {company.company_name} is currently on{' '}
                                    {company.subscription.subscription_name}{' '}
                                    plan ({company.subscription.package_users}{' '}
                                    seats) ∙ $
                                    {company.subscription.price.toFixed(2)}
                                    /month
                                </TextDefault>
                                <FlexDiv ml={12}>
                                    {isDeactivating ? (
                                        <Spinner />
                                    ) : (
                                        <TextDefault
                                            color={colors.error}
                                            bold
                                            onClick={() => onDeactivate()}
                                            link
                                        >
                                            Deactivate
                                        </TextDefault>
                                    )}
                                </FlexDiv>
                            </FlexDiv>
                        )}
                    </Fragment>
                }
                modalContent={
                    <PackageForm
                        companyForm={companyForm}
                        isEdit
                        subscription={company.subscription}
                        subscriptionPlans={subscriptionPlans}
                        loadingSubscriptionsPlans={loadingSubscriptionsPlans}
                        partnerEstimate={subscriptionStore.partnerEstimate}
                        estimationLoading={estimationLoading && !isDeactivating}
                    />
                }
                modalFooterContent={
                    addingPackage ? (
                        <ThemedSpinner size={45} />
                    ) : (
                        <SpaceBetweenDiv>
                            <FlexDiv column>
                                <TextTiny lighter bold>
                                    Total
                                </TextTiny>
                                <FlexDiv>
                                    {((!estimationLoading &&
                                        !partnerEstimate) ||
                                        isDeactivating) &&
                                        priceComponent(
                                            company.subscription.price
                                        )}
                                    {estimationLoading && !isDeactivating ? (
                                        <Spinner />
                                    ) : (
                                        partnerEstimate &&
                                        priceComponent(
                                            partnerEstimate.newCompanyTotal
                                        )
                                    )}
                                </FlexDiv>
                            </FlexDiv>
                            <ThemedButton
                                primary
                                airy
                                onClick={() => {
                                    companyForm.submit();
                                }}
                                disabled={
                                    (companyForm && !companyForm.changed) ||
                                    estimationLoading ||
                                    isDeactivating
                                }
                            >
                                Review and confirm
                            </ThemedButton>
                        </SpaceBetweenDiv>
                    )
                }
            />
        </Fragment>
    );
}

export default observer(CompanySubscription);
