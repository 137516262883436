import React from 'react';
import { observer } from 'mobx-react';
import { TwitterPicker } from 'react-color';
import {
    FlexDiv,
    SpaceBetweenDiv,
} from '../design-system/containers';
import { TextDefault, TextLead } from '../design-system/texts';
import styled from 'styled-components';

const ColorPicker = styled(TwitterPicker)`
    width: 205px !important;
`;

function EditColor({ primaryColor, setPrimaryColor }) {
    return (
        <SpaceBetweenDiv>
            <FlexDiv column>
                <TextLead bold>Button and links color</TextLead>
                <TextDefault>
                    This color is used across the app for various buttons and
                    links.
                </TextDefault>
            </FlexDiv>
            <ColorPicker
                triangle="hide"
                color={primaryColor}
                onChange={color => setPrimaryColor(color.hex)}
                colors={[primaryColor]}
            />
        </SpaceBetweenDiv>
    );
}

export default observer(EditColor);
