import React, { Fragment } from 'react';
import { Row, Col } from 'reactstrap';
import { observer } from 'mobx-react';
import RadioButton from '../../../components/RadioButton';
import { DateTimePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { InputAdornment, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import './distribution.scss';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SelectChannel from '../wizard/select-channel';

const useStyles = makeStyles(() => ({
    dayWithDotContainer: {
        position: 'relative',
    },
    dayWithDot: {
        position: 'absolute',
        height: 0,
        width: 0,
        border: '2px solid',
        borderRadius: 4,
        borderColor: 'gray',
        right: '50%',
        transform: 'translateX(1px)',
        top: '80%',
    },
}));

// making sure week on calendar starts on Monday
moment.updateLocale('en', {
    week: {
        dow: 1,
    },
});

export default observer(
    ({
        scheduleType,
        scheduledDate,
        dueDate,
        channel,
        slackAvailable,
        slackConnected,
        teamsAvailable,
        teamsConnected,
        onChangeScheduleType,
        onChangeChannel,
        onChangeDate,
        onChangeDueDate,
        ongoing,
    }) => {
        const classes = useStyles();

        return (
            <Fragment>
                <div className={'training-schedule'}>
                    <br />
                    <h4 className={'subheader'}>Set delivery</h4>
                    <h5>Type of delivery</h5>
                    <div className={'training-schedule-options'}>
                        {!ongoing ? (
                            <Fragment>
                                <RadioButton
                                    id="typeSend2"
                                    label="Now"
                                    value={1}
                                    groupValue={scheduleType}
                                    onClick={onChangeScheduleType}
                                />
                                <RadioButton
                                    id="typeSend1"
                                    label="Schedule"
                                    value={0}
                                    groupValue={scheduleType}
                                    onClick={onChangeScheduleType}
                                />
                            </Fragment>
                        ) : scheduleType === 1 ? (
                            <span className="ongoing-text">Now</span>
                        ) : (
                            <div className="flex flex-column">
                                <span className="ongoing-text">
                                    Scheduled - Sent on{' '}
                                    {moment(scheduledDate).format(
                                        'L (LT) [GMT]'
                                    )}
                                </span>
                                <div className="assessment-wizard-info-message extra-message">
                                    <InfoOutlinedIcon fontSize="small" />
                                    <span style={{ maxWidth: '90%' }}>
                                        In case you're adding employees to this
                                        assessment, they will receive this
                                        assessment immediately after you confirm
                                        your edits.
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                    {scheduleType === 0 && !ongoing && (
                        <Row className={'training-schedule-frequency'}>
                            <Col xs="4">
                                <div className="form-holder">
                                    <h5>Date & time</h5>
                                    <MuiPickersUtilsProvider
                                        utils={MomentUtils}
                                    >
                                        <DateTimePicker
                                            value={scheduledDate}
                                            ampm={false}
                                            onChange={onChangeDate}
                                            variant="inline"
                                            inputVariant="outlined"
                                            color="primary"
                                            disablePast
                                            format="L HH:mm [GMT]"
                                            allowKeyboardControl
                                            initialFocusedDate={moment(
                                                new Date()
                                            ).set('minute', 0)}
                                            autoOk
                                            maxDate={
                                                dueDate !== null &&
                                                scheduleType === 0
                                                    ? moment(dueDate)
                                                    : undefined
                                            }
                                            maxDateMessage={`Delivery date must be set before the due date (${moment(
                                                dueDate
                                            ).format('L HH:mm [GMT]')})`}
                                            minDateMessage="Date cannot be set in the past."
                                            strictCompareDates
                                            emptyLabel="Select date"
                                            views={['date', 'hours']}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        <CalendarTodayIcon
                                                            color="primary"
                                                            style={{
                                                                width: '20px',
                                                                color: '#c4c4c4',
                                                            }}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            renderDay={(
                                                date,
                                                selectedDate,
                                                dayInCurrentMonth,
                                                dayComponent
                                            ) => {
                                                if (
                                                    moment(date).isSame(
                                                        dueDate,
                                                        'day'
                                                    ) &&
                                                    dayInCurrentMonth
                                                ) {
                                                    return (
                                                        <div
                                                            className={
                                                                classes.dayWithDotContainer
                                                            }
                                                        >
                                                            {dayComponent}
                                                            <Tooltip
                                                                title={`Delivery date must be set before the due date (${moment(
                                                                    dueDate
                                                                ).format(
                                                                    'L HH:mm [GMT]'
                                                                )})`}
                                                            >
                                                                <div
                                                                    className={
                                                                        classes.dayWithDot
                                                                    }
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                    );
                                                }
                                                return dayComponent;
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                            </Col>
                        </Row>
                    )}
                    {(scheduleType === 0 || scheduleType === 1) && (
                        <SelectChannel
                            channel={channel}
                            slackAvailable={slackAvailable}
                            slackConnected={slackConnected}
                            teamsAvailable={teamsAvailable}
                            teamsConnected={teamsConnected}
                            onChangeChannel={onChangeChannel}
                        />
                    )}
                    <Row className={'training-schedule-frequency'}>
                        <Col xs="4">
                            <h4 className={'subheader'}>Set due date*</h4>
                            <div className="form-holder">
                                <h5>Date & time</h5>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DateTimePicker
                                        value={dueDate}
                                        onChange={onChangeDueDate}
                                        ampm={false}
                                        variant="inline"
                                        inputVariant="outlined"
                                        color="primary"
                                        disablePast
                                        format="L HH:mm [GMT]"
                                        allowKeyboardControl
                                        autoOk
                                        initialFocusedDate={
                                            !scheduledDate
                                                ? moment(new Date()).set(
                                                      'minute',
                                                      0
                                                  )
                                                : moment(scheduledDate).add(
                                                      1,
                                                      'day'
                                                  )
                                        }
                                        minDate={
                                            scheduledDate !== null &&
                                            scheduleType === 0
                                                ? moment(scheduledDate)
                                                : undefined
                                        }
                                        emptyLabel="Select date"
                                        minDateMessage={`Due date must be set after the delivery time (${moment(
                                            scheduledDate
                                        ).format('L HH:mm [GMT]')})`}
                                        views={['date', 'hours']}
                                        strictCompareDates
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <CalendarTodayIcon
                                                        color="primary"
                                                        style={{
                                                            width: '20px',
                                                            color: '#c4c4c4',
                                                        }}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        renderDay={(
                                            date,
                                            selectedDate,
                                            dayInCurrentMonth,
                                            dayComponent
                                        ) => {
                                            if (
                                                moment(date).isSame(
                                                    scheduledDate,
                                                    'day'
                                                ) &&
                                                dayInCurrentMonth
                                            ) {
                                                return (
                                                    <div
                                                        className={
                                                            classes.dayWithDotContainer
                                                        }
                                                    >
                                                        {dayComponent}
                                                        <Tooltip
                                                            title={`Due date must be set after the delivery time (${moment(
                                                                scheduledDate
                                                            ).format(
                                                                'L HH:mm [GMT]'
                                                            )})`}
                                                        >
                                                            <div
                                                                className={
                                                                    classes.dayWithDot
                                                                }
                                                            />
                                                        </Tooltip>
                                                    </div>
                                                );
                                            }
                                            return dayComponent;
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
    }
);
