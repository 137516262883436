import React, { Fragment, useEffect, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import pick from 'lodash/pick';
import every from 'lodash/every';
import { Link } from 'react-router-dom';
import AzureFormDef from '../../forms/azure-ad';
import { Spinner } from 'reactstrap';
import moment from 'moment';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import {
    ThemedText,
    ThemedButton,
} from '../../components/themed/ThemedComponents';
import TextField from '../../components/inputs/textField';
import ErrorMessage from '../../components/errormessage';
import { Row, Col } from 'reactstrap';
import { useStores } from '../../hooks/use-stores';
import { FormControlLabel, Switch, Grid, Typography } from '@material-ui/core';
import IntegrationDetailSidebar from '../../components/company/integration-detail-sidebar';
import IntegrationDetailGroupSettings from '../../components/company/integration-detail-group-settings';
import IntegrationDetailPreviewModal from '../../components/company/integration-detail-preview-modal';

function CompanyAzureAd({ companyId, parentUrl }) {
    const { companyStore, commonStore, authStore, brandingStore } = useStores();
    const [previewData, setPreviewData] = useState(null);

    const [options, setOptions] = useState([]);
    const [includedOptions, setIncludedOptions] = useState([]);
    const [filteredIncludedOptions, setFilteredIncludedOptions] = useState([]);
    const [excludedOptions, setExcludedOptions] = useState([]);
    const [filter, setFilter] = useState('');
    const [included, setIncluded] = useState([]);
    const [excluded, setExcluded] = useState([]);

    const store = useLocalStore(() => ({
        _form: new AzureFormDef(
            {},
            {
                hooks: {
                    onSuccess: async () => {
                        await companyStore.saveIntegrationData(
                            companyId,
                            'azure',
                            store._form.values()
                        );
                        await companyStore.saveIntegrationData(
                            companyId,
                            'azure',
                            { enabled: '1' }
                        );
                    },
                },
            }
        ),
        update(values) {
            store._form.update(values);
        },
    }));

    const doLoadData = async () => {
        await companyStore.loadIntegrationData(companyId, 'azure');

        let obj = companyStore.azureDataIndex[companyId];
        if (obj) {
            //  console.log("got data")
            store.update(pick(obj, 'tenant', 'clientId', 'clientSecret'));
        }
        (async () => {
            const response = await companyStore.loadIntegrationGroups(
                companyId,
                'azure'
            );
            setOptions(response);

            if (response && response.length > 0) {
                let clone1 = response.map((x) => {
                    return {
                        id: x.id,
                        displayName: x.displayName,
                        rule: x.rule,
                    };
                });

                setExcludedOptions(clone1);
                setExcluded(clone1.filter((x) => x.rule === 2));

                let clone2 = response.map((x) => {
                    return {
                        id: x.id,
                        displayName: x.displayName,
                        rule: x.rule,
                    };
                });
                setIncludedOptions(clone2);
                setIncluded(clone2.filter((x) => x.rule === 1));
            }
        })();
    };

    useEffect(() => {
        doLoadData();
    }, [companyId]);

    useEffect(() => {
        setFilteredIncludedOptions(
            includedOptions.filter((option) => {
                return (
                    (option.displayName || '').toLowerCase().indexOf(filter) >
                    -1
                );
            })
        );
    }, [filter, includedOptions]);

    const onToggleEnabled = async () => {
        if (companyStore.azureDataIndex[companyId].enabled === '1') {
            await companyStore.saveIntegrationData(companyId, 'azure', {
                enabled: '0',
            });
        } else {
            await companyStore.saveIntegrationData(companyId, 'azure', {
                enabled: '1',
            });
        }
    };

    const onToggleTestMode = async () => {
        if (companyStore.azureDataIndex[companyId].testMode === '1') {
            await companyStore.saveIntegrationData(companyId, 'azure', {
                testMode: '0',
            });
        } else {
            await companyStore.saveIntegrationData(companyId, 'azure', {
                testMode: '1',
            });
        }
    };

    const testIntegration = async () => {
        try {
            let result = await companyStore.testIntegration(
                companyId,
                'azure',
                store._form.values()
            );
            if (result.success)
                commonStore.showMessage('Connection Successful');
            else
                commonStore.showMessage(
                    'Connection failed: ' + result.error,
                    'error'
                );
        } catch (e) {
            commonStore.showMessage('Connection failed: ' + e.message, 'error');
        }
    };

    const syncNow = async () => {
        try {
            let result = await companyStore.syncIntegration(companyId, 'azure');
            if (result && !result.error)
                commonStore.showMessage('Sync Successful');
            else
                commonStore.showMessage(
                    'Sync failed: ' + result.error,
                    'error'
                );
        } catch (e) {
            commonStore.showMessage('Sync failed: ' + e.message, 'error');
        }
    };

    const previewNow = async () => {
        try {
            let result = await companyStore.syncIntegration(
                companyId,
                'azure',
                { preview: true }
            );
            if (result && result.preview) setPreviewData(result.preview);
        } catch (e) {
            commonStore.showMessage('Sync failed: ' + e.message, 'error');
        }
    };

    const closePreviewModal = () => {
        setPreviewData(null);
    };

    const {
        savingAzureData,
        testingAzureData,
        loadingAzureDatas,
        loadingAzureGroups,
        azureError,
        syncRunning,
    } = companyStore;
    const { account_name, brandingLoaded } = brandingStore;
    let data = companyStore.azureDataIndex[companyId];

    let csvUrl;
    if (data && data.lastSyncId) {
        const { token } = authStore;
        csvUrl = `${process.env.REACT_APP_API_URL}/companies/${companyId}/azure/logs/${data.lastSyncId}?token=${token}`;
    }

    const testMode = (
        <div className="button-with-description">
            <Typography component="div">
                <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                >
                    <Grid item>Live Mode</Grid>
                    <Grid item>
                        <Switch
                            checked={Number(data && data.testMode) === 1}
                            onChange={onToggleTestMode}
                            name="testModeEnabled"
                            color="primary"
                        />
                    </Grid>
                    <Grid item>Test Mode</Grid>
                </Grid>
            </Typography>
        </div>
    );

    return loadingAzureDatas.includes(companyId) || !data ? (
        <ThemedSpinner />
    ) : (
        <Fragment>
            <Link to={parentUrl}>
                <div className={'navigation back-button'}>
                    Back to Integration overview
                </div>
            </Link>
            <Row>
                <Col lg="8" xs="12">
                    <div className="integration-detail-block">
                        {azureError && (
                            <ErrorMessage
                                error={azureError}
                                marginBottom={true}
                            />
                        )}
                        <img
                            src={`/static/img/logo-azure-ad.svg`}
                            alt="Azure logo"
                        />
                        <h2>
                            {(brandingLoaded && account_name) || 'AwareGO'} +
                            Azure Active Directory
                        </h2>
                        <p
                            className={
                                data.enabled === '0' ? 'margin-bottom' : ''
                            }
                        >
                            Azure Active Directory integration is used to sync
                            users from Azure Active Directory to user lists.
                            Configure which groups will be synced, which groups
                            will be included or which groups will be excluded.
                        </p>
                        {data.enabled === '0' ? (
                            <Fragment>
                                <TextField
                                    field={store._form.$('tenant')}
                                    minWidth={'350px'}
                                />
                                <TextField
                                    field={store._form.$('clientId')}
                                    minWidth={'350px'}
                                />
                                <TextField
                                    field={store._form.$('clientSecret')}
                                    minWidth={'350px'}
                                />
                                {testMode}
                                {savingAzureData.includes(companyId) ? (
                                    <Spinner />
                                ) : (
                                    <ThemedButton
                                        primary
                                        onClick={store._form.onSubmit}
                                    >
                                        Connect Azure AD
                                    </ThemedButton>
                                )}
                            </Fragment>
                        ) : (
                            ''
                        )}
                    </div>
                    {data.enabled === '1' ? (
                        <Fragment>
                            <div className="integration-detail-block align-left">
                                <h1>Manage connection</h1>
                                <div className="button-with-description">
                                    <p>
                                        Click on the following button to test
                                        the connection with Azure AD. This
                                        action will not send out any messages.
                                    </p>
                                    {testingAzureData.includes(companyId) ? (
                                        <Spinner />
                                    ) : (
                                        <ThemedButton
                                            secondary
                                            small
                                            disabled={
                                                !every(
                                                    Object.values(
                                                        store._form.values()
                                                    ),
                                                    (x) => x
                                                )
                                            }
                                            title={
                                                !every(
                                                    Object.values(
                                                        store._form.values()
                                                    ),
                                                    (x) => x
                                                ) || store._form.isDirty
                                                    ? 'Save Before Test'
                                                    : ''
                                            }
                                            onClick={testIntegration}
                                        >
                                            Test Connection
                                        </ThemedButton>
                                    )}
                                </div>
                                <h3>Test mode</h3>
                                <p>
                                    By enabling Test mode you will be able to
                                    preview which users will be imported. When
                                    you are satisfied with all groups selected,
                                    switch over to Live mode.
                                </p>
                                {testMode}
                                <h3>Status</h3>
                                {syncRunning ||
                                (data && data.latestRunningSyncId) ? (
                                    <Spinner />
                                ) : data && data.testMode === '0' ? (
                                    <ThemedButton secondary onClick={syncNow}>
                                        Sync now
                                    </ThemedButton>
                                ) : (
                                    <ThemedButton
                                        secondary
                                        onClick={previewNow}
                                    >
                                        Preview
                                    </ThemedButton>
                                )}
                                <p>
                                    Last synced:{' '}
                                    {data && data.lastSync ? (
                                        <ThemedText
                                            primary
                                            as={'a'}
                                            href={csvUrl}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            {moment(data.lastSync).format('LL')}
                                        </ThemedText>
                                    ) : (
                                        'Never'
                                    )}
                                </p>
                                <h3>Import Azure AD users & groups</h3>
                                <div className="button-with-description">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={
                                                    Number(
                                                        data && data.enabled
                                                    ) === 1
                                                }
                                                onChange={onToggleEnabled}
                                                name="importEnabled"
                                                color="primary"
                                            />
                                        }
                                        label={`All existing and new Azure AD users will be automatically added to an ${
                                            (brandingLoaded && account_name) ||
                                            'AwareGO'
                                        } training list that you specify.`}
                                    />
                                </div>
                            </div>
                            <IntegrationDetailGroupSettings
                                data={data}
                                loadingGroups={loadingAzureGroups}
                                companyId={companyId}
                                product={'azure'}
                                savingData={savingAzureData}
                                options={options}
                                filteredIncludedOptions={
                                    filteredIncludedOptions
                                }
                                excludedOptions={excludedOptions}
                                included={included}
                                setIncluded={setIncluded}
                                excluded={excluded}
                                setExcluded={setExcluded}
                                filter={filter}
                                setFilter={setFilter}
                            />
                        </Fragment>
                    ) : (
                        ''
                    )}
                </Col>
                <Col lg="4" xs="12">
                    <IntegrationDetailSidebar
                        href="https://help.awarego.com/azure-ad"
                        product="Azure AD"
                    />
                </Col>
            </Row>
            <IntegrationDetailPreviewModal
                closePreviewModal={closePreviewModal}
                previewData={previewData}
            />
        </Fragment>
    );
}

export default observer(CompanyAzureAd);
