import React, { useEffect, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Row, Col } from 'reactstrap';
import ErrorMessage from '../components/errormessage';
import { ThemedButton } from '../components/themed/ThemedComponents';
import ThemedSpinner from '../components/themed/ThemedSpinner';
import { Helmet } from 'react-helmet';
import {
    Image,
    FlexDiv,
    SpaceBetweenDiv,
    Block,
} from '../components/design-system/containers';
import { TextTiny, TextDefault } from '../components/design-system/texts';
import { Heading1, Heading3 } from '../components/design-system/headings';
import { useStores } from '../hooks/use-stores';
import StarsIcon from '@material-ui/icons/Stars';

function Resources() {
    const { resourcesStore } = useStores();

    const { posters, loadingPosters, error } = resourcesStore;

    useEffect(() => {
        doLoadData();
    }, []);

    const doLoadData = async () => {
        await resourcesStore.loadPosters();
    };

    //https://resources.awarego.com/posters.json
    const renderPoster = poster => (
        <FlexDiv column key={poster.id} mx={16} my={16} maxWidth={160}>
            <a href={poster.pdf} target="_blank" rel="noopener noreferrer">
                <Image src={poster.image} alt={poster.title} width={160} />
                <FlexDiv mx={5}>
                    <TextTiny my={8}>{poster.title}</TextTiny>
                    <ThemedButton secondary tiny>
                        PDF
                    </ThemedButton>
                </FlexDiv>
            </a>
        </FlexDiv>
    );

    if (loadingPosters) return <ThemedSpinner />;

    return (
        <Fragment>
            <Helmet>
                <title>Resources</title>
            </Helmet>
            <Row>
                <Col md="8" xs="12">
                    <Block>
                        <ErrorMessage error={error} />
                        <Heading1>Resources</Heading1>
                        <Heading3 my={24}>Posters</Heading3>
                        <FlexDiv justifyCenter>
                            {posters.map(item => renderPoster(item))}
                        </FlexDiv>
                    </Block>
                </Col>
                <Col md="4" xs="12">
                    <Block>
                        <SpaceBetweenDiv alignCenter mb={24}>
                            <Heading3>Pro tip</Heading3>
                            <StarsIcon />
                        </SpaceBetweenDiv>
                        <TextDefault lighter>
                            Print out the posters you want to use and hang them
                            in places where people will see them, such as by
                            doors, in the bathroom and in the break room or
                            lunch hall. Change the posters out every few weeks.
                        </TextDefault>
                        <TextDefault lighter>
                            Use your company‘s internal social media platforms
                            to share the posters even further and use them as a
                            bonus material in you emails to your co-workers.
                        </TextDefault>
                        <TextDefault lighter>
                            If you have an ongoing training program in place,
                            use a corresponding poster to drive home the latest
                            subject or subjects sent out.{' '}
                        </TextDefault>
                    </Block>
                </Col>
            </Row>
        </Fragment>
    );
}

export default observer(Resources);