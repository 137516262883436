import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { Link, withRouter } from 'react-router-dom';

import { extractErrorMessage, formatDate } from '../../utils/helpers';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import SectionHeader from '../../components/SectionHeader';

@inject('superStore', 'authStore')
@observer
class CompanyPPVLogView extends React.Component {
    state = { companyId: null, loadedOrError: null };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!prevState) return; // not yet initialized
        if (nextProps.companyId !== prevState.companyId) {
            return {
                companyId: nextProps.companyId,
                loadedOrError: null,
            };
        }

        // No state update necessary
        return null;
    }

    componentDidMount() {
        // It's preferable in most cases to wait until after mounting to load data.
        // See below for a bit more context...
        this.loadData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.loadedOrError === null) {
            // At this point, we're in the "commit" phase, so it's safe to load the new data.
            this.loadData();
        }
    }

    async loadData() {
        try {
            this.props.superStore.loadPPVLog(this.state.companyId);
            this.setState({ loadedOrError: true });
        } catch (e) {
            this.setState({ loadedOrError: extractErrorMessage(e) });
        }
    }

    eventType = (t) => {
        switch (t) {
            case 1:
                return 'Signature';
            case 2:
                return 'View';
            case 3:
                return 'Error';
            default:
                return 'Other';
        }
    };

    render() {
        const { loadingPPVLog, ppvLog } = this.props.superStore;
        const { token } = this.props.authStore;
        const csvUrl = `${process.env.REACT_APP_API_URL}/companies/${this.state.companyId}/ppv/export/?token=${token}`;

        return loadingPPVLog ? (
            <ThemedSpinner />
        ) : (
            <Fragment>
                <Link to={`/ppv/`}>
                    <div className={'navigation back-button'}>
                        Back to PPV view
                    </div>
                </Link>
                <div className="block">
                    <SectionHeader>
                        <br />
                        <div className=" d-flex flex-row">
                            <div className={'section-header'}>
                                <div className={'left padded'}>
                                    <h3>PPV views usage</h3>
                                </div>

                                <div className="pull-right">
                                    <ThemedButton
                                        secondary
                                        as={'a'}
                                        href={csvUrl}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Export
                                    </ThemedButton>
                                </div>
                            </div>
                        </div>
                    </SectionHeader>

                    <table className="simple-table">
                        <thead>
                            <tr>
                                <th>Created</th>
                                <th>EventType</th>
                                <th>Subject</th>
                                <th>Client Ip</th>
                                <th>JW Video ID</th>
                                <th>Location</th>
                                <th>Uid</th>
                                <th>ApiKey</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ppvLog.map((r) => (
                                <tr key={r.id}>
                                    <td>{formatDate(r.timestamp)} </td>
                                    <td>{this.eventType(r.event_type)}</td>
                                    <td>{r.subject_title}</td>
                                    <td>{r.client_ip}</td>
                                    <td>{r.js_video_id}</td>
                                    <td>{r.locaction}</td>
                                    <td>{r.uid}</td>
                                    <td>{r.api_key}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Fragment>
        );
    }
}

export default withRouter(CompanyPPVLogView);
