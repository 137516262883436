import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { querystring } from '../utils/helpers';

export default inject('authStore')(
    observer(({ component: C, authStore, ...rest }) => {
        let redirect = querystring('redirect') || '/';
        if (!redirect.startsWith('/')) redirect = `/${redirect}`;

        return (
            <Route
                {...rest}
                render={(props) =>
                    !authStore.isAuthenticated ? (
                        <C {...props} {...rest} />
                    ) : (
                        <Redirect
                            to={
                                redirect === '' || redirect === null
                                    ? '/'
                                    : redirect
                            }
                        />
                    )
                }
            />
        );
    })
);
