import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import FormDef from '../../forms/company';
import CompanyEdit from '../../components/company/company-edit';
import ThemedSpinner from '../../components/themed/ThemedSpinner';

@inject('companyStore', 'authStore', 'store')
@observer
class CompanyEditContainer extends React.Component {
    constructor(props) {
        super(props);
        this._form = new FormDef(
            {},
            {
                hooks: {
                    onSuccess: this.onSuccess,
                },
            }
        );
        this._form.update(
            this.props.company ? { name: this.props.company.company_name } : {}
        );
    }

    onSuccess = async () => {
        if (this.props.fromNewSettings) {
            await this.props.store.saveCurrentCompany({
                ...this._form.values(),
            });
            this.props.onSaved(this._form.values().name);
        } else {
            this.props.company
                ? await this.props.companyStore.save(this.props.companyId, {
                      ...this._form.values(),
                  })
                : await this.props.companyStore.create(
                      {
                          companyName: this._form.values().name,
                          managerEmail: this.props.currentUser.email,
                          managerName: this.props.currentUser.name,
                          accountId: this.props.accountId,
                      },
                      true
                  );

            this.props.onSaved(this._form.values().name);
        }
    };

    render() {
        const { error, savingCompany } = this.props.companyStore;
        const { trialError } = this.props.authStore;
        const { onRequestClose } = this.props;
        const isNew = !this.props.company;
        return (
            <Fragment>
                <div className={'modalHeader'}>
                    {isNew ? <h3>Add New Company</h3> : <h3>Rename company</h3>}
                    <img
                        className={'modal-close-right'}
                        src="/static/img/close.svg"
                        alt="Close"
                        onClick={onRequestClose}
                    />
                </div>
                <div className={'modalContent'}>
                    <CompanyEdit
                        form={this._form}
                        error={error || trialError}
                        email={
                            this.props.currentUser &&
                            this.props.currentUser.email
                        }
                        emailEditable={false}
                    />
                </div>
                <div className={'modalFooter'}>
                    {savingCompany ? (
                        <ThemedSpinner size={50} />
                    ) : (
                        <Fragment>
                            <ThemedButton
                                secondary
                                onClick={this._form.onSubmit}
                            >
                                {this.props.currentUser ? 'Continue' : 'Done'}
                            </ThemedButton>
                        </Fragment>
                    )}
                </div>
            </Fragment>
        );
    }
}

export default withRouter(CompanyEditContainer);
