import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer, useLocalStore } from 'mobx-react';
import { ThemedButton } from '../../components/themed/ThemedComponents';
import { useStores } from '../../hooks/use-stores';
import { Block, FlexDiv } from '../../components/design-system/containers';
import { Heading1 } from '../../components/design-system/headings';
import { TextDefault, TextTiny } from '../../components/design-system/texts';
import { TextInput } from '../../components/design-system/inputs';
import ErrorMessage from '../../components/errormessage';
import SignUpFormDef from '../../forms/auth';
import { querystring } from '../../utils/helpers';
import TermsOfService from '../../components/TermsOfService';

function SignIn({ company_id, campaign_id, subject_id }) {
    const { authStore, brandingStore } = useStores();
    let history = useHistory();

    const localStore = useLocalStore(() => {
        let signInForm = new SignUpFormDef(
            {},
            {
                hooks: {
                    onSuccess() {
                        handleSubmit();
                    },
                },
            }
        );
        signInForm.observe({
            path: 'managerEmail',
            key: 'value',
            call: ({ form }) => {
                form.validate();
            },
        });

        return { signInForm };
    });

    useEffect(() => {
        authStore.suggestTrial = false;
    }, []);

    const handleSubmit = async () => {
        let redirect = querystring('redirect') || '/';
        authStore.setEmail(localStore.signInForm.$('managerEmail').value);
        authStore.setExtraMessage('Please verify your email.');
        const result = await authStore.signin(
            localStore.signInForm.$('managerEmail').value,
            company_id,
            campaign_id,
            subject_id,
            redirect
        );
        if (result === 'redirect') return;
        if (result) history.push('/auth/check-email');
    };

    const { loginError, suggestTrial } = authStore;
    const { account_name, termsUrl } = brandingStore;

    const inputHasError =
        localStore.signInForm.$('managerEmail').error &&
        localStore.signInForm.$('managerEmail').error.length > 0;

    return (
        <FlexDiv justifyCenter alignCenter wrapper column>
            {loginError && loginError.length > 0 && (
                <ErrorMessage error={loginError} />
            )}
            <Block width={570}>
                <Heading1>Sign in</Heading1>
                <FlexDiv mb={24}>
                    <TextDefault lighter mr={4}>
                        Don't have an account?
                    </TextDefault>
                    <TextDefault themed bold>
                        <Link to={`/auth/signup`}>Sign up</Link>
                    </TextDefault>
                </FlexDiv>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        localStore.signInForm.submit();
                    }}
                >
                    <FlexDiv column>
                        <TextInput
                            error={inputHasError}
                            helperText={
                                inputHasError ? (
                                    'Please enter your work email'
                                ) : suggestTrial ? (
                                    <TextTiny themed>
                                        The email is not registered in our
                                        system. Would you like to{' '}
                                        <Link to={'/auth/signup'}>
                                            <TextTiny themed bold underline>
                                                sign up
                                            </TextTiny>
                                        </Link>
                                        ?
                                    </TextTiny>
                                ) : (
                                    undefined
                                )
                            }
                            stretched
                            {...localStore.signInForm.$('managerEmail').bind()}
                        />
                        {
                            <ThemedButton primary fullWidth type="submit">
                                Continue
                            </ThemedButton>
                        }
                        <TermsOfService
                            accountName={account_name}
                            termsUrl={termsUrl}
                        />
                    </FlexDiv>
                </form>
            </Block>
        </FlexDiv>
    );
}

export default observer(SignIn);
