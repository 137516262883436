import React from 'react';
import { Row, Col } from 'reactstrap';
import ThemedSpinner from './themed/ThemedSpinner';

function Waiting({ waitingActive = false, fullScreen = true }) {
    if (!waitingActive) {
        return null;
    } else {
        if (!fullScreen) {
            return (
                <Row>
                    <Col md="12" xs="12" className="content">
                        <ThemedSpinner
                            message={'One moment. We are doing some stuff...'}
                        />
                    </Col>
                </Row>
            );
        } else {
            return (
                <div className="loading-fullscreen">
                    <ThemedSpinner
                        fullscreen
                        message={'One moment. We are doing some stuff...'}
                    />
                </div>
            );
        }
    }
}

export default Waiting;
