import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import VirtualListBox from './VirtualListBox';

export default function VirtualAutocomplete(params) {
    return (
        <Autocomplete
            {...params}
            disableListWrap
            ListboxComponent={VirtualListBox}
        />
    );
}
