import React, { useRef } from 'react';
import InlineEditName from 'react-contenteditable';
import { useHistory } from 'react-router-dom';
import StatusBadge from '../../StatusBadge';
import EditIcon from '@material-ui/icons/EditOutlined';
import { ThemedText } from '../../themed/ThemedComponents';
import capitalize from 'lodash/capitalize';

function WizardHeader({
    selectedTemplate,
    isStartStep,
    openNameChangeModal,
    openSaveChangesModal,
    item,
    onChangeName,
    itemString,
    saveDraft,
    isNameUntitled,
}) {
    const history = useHistory();
    const editName = useRef('editName');

    return (
        <div className={'training-wizard-header'}>
            <img
                src="/static/img/close.svg"
                alt="Close"
                className={'training-wizard-close'}
                onClick={() =>
                    selectedTemplate &&
                    selectedTemplate.length > 0 &&
                    !isStartStep
                        ? openSaveChangesModal()
                        : history.push(
                              itemString === 'training'
                                  ? '/trainings'
                                  : '/assessment'
                          )
                }
            />
            <div className="assessment-wizard-header">
                <div className="d-flex">
                    <InlineEditName
                        html={item.name}
                        onChange={onChangeName}
                        ref={editName}
                        tagName="h3"
                        className={!isNameUntitled ? 'name-black' : ''}
                        onBlur={() => {
                            if (item.name === '') {
                                item.name = `Untitled ${capitalize(
                                    itemString
                                )}`;
                            }
                        }}
                        disabled={isStartStep}
                    />
                    {!isStartStep && (
                        <EditIcon fontSize="small" className="edit-icon" />
                    )}
                </div>
                {item.id !== null ? (
                    <StatusBadge
                        color={item.statusInfo.color}
                        text={item.statusInfo.label}
                    />
                ) : (
                    <p>Creating new {itemString}</p>
                )}
            </div>
            {!isStartStep && (
                <ThemedText
                    className="right"
                    style={{
                        cursor:
                            selectedTemplate && selectedTemplate.length > 0
                                ? 'pointer'
                                : 'default',
                    }}
                    disabled={selectedTemplate && selectedTemplate.length === 0}
                    onClick={() =>
                        selectedTemplate && selectedTemplate.length > 0
                            ? isNameUntitled
                                ? openNameChangeModal(true)
                                : saveDraft()
                            : undefined
                    }
                >
                    {item.id !== null ? 'Save and update' : 'Save draft'}
                </ThemedText>
            )}
        </div>
    );
}

export default WizardHeader;
