import React from 'react';
import { Button } from '@awarego/awarego-components';

function testpage() {
    return (
        <div>
            <Button otherText="hello">TEST</Button>
        </div>
    );
}

export default testpage;
